/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import PropTypes from "prop-types";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const SelectInput = ({
    options= [{id: 0, name: "title"}],
    selectedInitial = 0,
    className= "",
    label= "",
    value=null,
    required= false,
    onChange = (value) => console.log("onChange", value)
}) => {

    SelectInput.propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.any.isRequired,
            name: PropTypes.string.isRequired
        })).isRequired,
        label: PropTypes.string,
        selectedInitial: PropTypes.shape({
            id: PropTypes.any.isRequired,
            name: PropTypes.string.isRequired
        }),
        className: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.shape({
            id: PropTypes.any.isRequired,
            name: PropTypes.string.isRequired
        })
    }

    const [selected, setSelected] = useState(selectedInitial)

    useEffect(() => {
        setSelected(value)
    }, [value])

    const handleOnChange = (value) => {
        setSelected(value)
        onChange(value)
    }

    return (
        <div className={className}>
            <Listbox value={selected} onChange={handleOnChange} >
                {({ open }) => (
                    <>
                        { label &&<Listbox.Label className="block text-sm font-medium text-gray-700">{label}{required && <span className="text-red-500"> *</span>}</Listbox.Label> }
                        <div className="relative">
                            <Listbox.Button className={"relative w-full bg-white pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm shadow-sm border rounded-md "+ ( required && !selected?.name ? ' border-red-400' : ' border-gray-400 ')}>
                                <span className="block truncate">{selected?.name ?  selected.name : <p className="mb-5"></p>  }</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options
                                    static
                                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                    {options.map((person) => (
                                        <Listbox.Option
                                            key={person.id+person.name}
                                            className={({ active }) =>
                                                classNames(
                                                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                    'cursor-default select-none relative py-2 pl-8 pr-2'
                                                )
                                            }
                                            value={person}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                      {person.name}
                                                    </span>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-white' : 'text-indigo-600',
                                                                'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                                            )}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        </div>
    )
}