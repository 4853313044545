import { useEffect, useState, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmDialog } from "../../../../../components";
import { ALL_AVAILABLE_ACTION_OPTIONS, AVAILABLE_PRODUCT_INSERT_OPTIONS } from "../../utils/businessRuleActionUtils";
import { BusinessRuleStepCard, ProductSelector } from "../../components";
import ActionOptions from "./ActionOptions";
import ProductInsertOptions from "./ProductInsertOptions";
import useRuleActionSettingsValidator from "./useRuleActionSettingsValidator";
import { Button } from "../../../../../components";
import { UserContext } from "../../../../../hooks/UserContext";

const BusinessRuleAction = ({actionSettings, onUpdateActionSettings, isPreviousStepCompleted, isCurrentStep, onCancelGeneralSettings, isEditing, onEdit, isCompleted}) => {
    const { t } = useTranslation()
    const { user } = useContext(UserContext)
    const [resetNextSteps, setResetNextSteps] = useState(false)
    const [localActionSettings, setLocalActionSettings] = useState(actionSettings)
    const [confirmEditStatementDialog, setConfirmEditStatementDialog] = useState({open: false})
    const areRuleActionSettingsValid = useRuleActionSettingsValidator(localActionSettings, user.current_store.warehouses)

    useEffect(() => {
        setLocalActionSettings({...actionSettings})
        setResetNextSteps(false)
    }, [actionSettings])

    const completedStepDescription = useMemo(() => {
        let description = ""

        switch(actionSettings.actionOption){
            case ALL_AVAILABLE_ACTION_OPTIONS.CANCEL_ORDER_OPTION:
            case ALL_AVAILABLE_ACTION_OPTIONS.PAUSE_ORDER_OPTION:
                description = t(`settings.business_rule_creator.business_rule_action_settings.completed_step.${actionSettings.actionOption}`)
                break

            case ALL_AVAILABLE_ACTION_OPTIONS.ADD_SHIPPING_PACKAGE_OPTION:
            case ALL_AVAILABLE_ACTION_OPTIONS.ADD_INSERT_OPTION:
            case ALL_AVAILABLE_ACTION_OPTIONS.ADD_PRODUCT_OPTION:
                {
                    description = t(`settings.business_rule_creator.business_rule_action_settings.completed_step.${actionSettings.actionOption}.description_start`)

                    if([ALL_AVAILABLE_ACTION_OPTIONS.ADD_PRODUCT_OPTION, ALL_AVAILABLE_ACTION_OPTIONS.ADD_INSERT_OPTION].includes(actionSettings.actionOption)){
                        description += t(`settings.business_rule_creator.business_rule_action_settings.completed_step.${actionSettings.actionOption}.insert_options.${actionSettings.productInsertOption}`)
                    }

                    const productText = t(`settings.business_rule_creator.business_rule_action_settings.completed_step.${actionSettings.actionOption}.product`)

                    actionSettings.productsToInsert.forEach((product, index, {length}) => {
                        const currentProductText = productText.replace("{QUANTITY}", product.quantity.toString()).replace("{SKU}", product.product_codes[0].sku)
                        let productJoinText = ""

                        if(index === (length - 1) && index > 0){
                            productJoinText = t(`settings.business_rule_creator.business_rule_action_settings.completed_step.${actionSettings.actionOption}.last_product_join`)
                        }
                        else if(index > 0){
                            productJoinText = ", "
                        }

                        description += (productJoinText + currentProductText)
                    })

                    if([ALL_AVAILABLE_ACTION_OPTIONS.ADD_PRODUCT_OPTION, ALL_AVAILABLE_ACTION_OPTIONS.ADD_INSERT_OPTION].includes(actionSettings.actionOption)){
                        description += t(`settings.business_rule_creator.business_rule_action_settings.completed_step.${actionSettings.actionOption}.description_end`)
                    }
                    break
                }

            default:
                break
        }

        return description
    }, [actionSettings, t])

    const handleOnSubmitRuleAction = () => {
        if(areRuleActionSettingsValid){
            onUpdateActionSettings({...localActionSettings}, resetNextSteps)
        }
        setConfirmEditStatementDialog({open: false})
    }

    const handleOnCancelRuleActionSettings = () => {
        setResetNextSteps(false)
        setLocalActionSettings({...actionSettings})
        onCancelGeneralSettings()
    }

    const handleOnSelectedActionOption = (selectedActionOption) => {
        setResetNextSteps(true)
        setLocalActionSettings({actionOption: selectedActionOption, productInsertOption: null, productsToInsert: []})

        switch(selectedActionOption){
            case ALL_AVAILABLE_ACTION_OPTIONS.ADD_SHIPPING_PACKAGE_OPTION:
                window.analytics.track("Business Rules - Selects Action - Use packing")
            break

            case ALL_AVAILABLE_ACTION_OPTIONS.ADD_PRODUCT_OPTION:
                window.analytics.track("Business Rules - Selects Action - Add to order")
            break

            case ALL_AVAILABLE_ACTION_OPTIONS.PAUSE_ORDER_OPTION:
                window.analytics.track("Business Rules - Selects Action - Pause order")
            break

            case ALL_AVAILABLE_ACTION_OPTIONS.CANCEL_ORDER_OPTION:
                window.analytics.track("Business Rules - Selects Action - Cancel order")
            break

            default:
            break
        }
    }

    const handleOnSelectedProductInsertOption = (selectedProductInsertOption) => {
        setResetNextSteps(true)
        setLocalActionSettings({...localActionSettings, productInsertOption: selectedProductInsertOption, productsToInsert: []})

        switch(selectedProductInsertOption){
            case AVAILABLE_PRODUCT_INSERT_OPTIONS.ENSURE_PRODUCT:
                window.analytics.track("Business Rules - Defines Rule Type - ENSURE")
            break

            case AVAILABLE_PRODUCT_INSERT_OPTIONS.INCLUDE_PRODUCT:
                window.analytics.track("Business Rules - Defines Rule Type - INCLUDE")
            break

            default:
                break
        }
    }

    const handleOnSelectProducts = (selectedProducts) => {
        setResetNextSteps(true)
        setLocalActionSettings({...localActionSettings, productsToInsert: selectedProducts})
    }

    const handleOnConfirmEdit = () => {
        if(resetNextSteps){
            setConfirmEditStatementDialog({open: true})
        } else{
            handleOnSubmitRuleAction()
        }
    }

    return (
        <div>
            <BusinessRuleStepCard
                title={t("settings.business_rule_creator.business_rule_action_settings.title")}
                description={!isCompleted ? t("settings.business_rule_creator.business_rule_action_settings.description") : ""}
                isCompleted={isCompleted}
                isValid={areRuleActionSettingsValid}
                isEditing={isEditing}
                isCurrentStep={isCurrentStep}
                isPreviousStepCompleted={isPreviousStepCompleted}
                onEdit={onEdit}
                completedDescription={completedStepDescription}
            >
                {(isEditing || isCurrentStep) && (
                    <div className="flex flex-col gap-5">
                        <ActionOptions
                            selectedActionOption={localActionSettings.actionOption}
                            onSelectedActionOption={handleOnSelectedActionOption}
                        />

                        {localActionSettings.actionOption === ALL_AVAILABLE_ACTION_OPTIONS.ADD_PRODUCT_OPTION && (
                            <>
                                <ProductInsertOptions
                                    productInsertOption={localActionSettings.productInsertOption}
                                    onSelectedProductInsertOption={handleOnSelectedProductInsertOption}
                                />

                                {!!localActionSettings.productInsertOption && (
                                    <ProductSelector
                                        selectedProducts={localActionSettings.productsToInsert}
                                        onSelectProducts={handleOnSelectProducts}
                                        title={t("settings.business_rule_creator.business_rule_action_settings.product_selector.ADD_PRODUCT_OPTION.title")}
                                        description={t("settings.business_rule_creator.business_rule_action_settings.product_selector.ADD_PRODUCT_OPTION.description")}
                                        addProductText={t("settings.business_rule_creator.business_rule_action_settings.product_selector.ADD_PRODUCT_OPTION.add_product_button")}
                                        addAnotherProductText={t("settings.business_rule_creator.business_rule_action_settings.product_selector.ADD_PRODUCT_OPTION.add_another_product_button")}
                                    />
                                )}
                            </>
                        )}

                        {localActionSettings.actionOption === ALL_AVAILABLE_ACTION_OPTIONS.ADD_INSERT_OPTION && (
                            <>
                                <ProductInsertOptions
                                    productInsertOption={localActionSettings.productInsertOption}
                                    onSelectedProductInsertOption={handleOnSelectedProductInsertOption}
                                />
                            
                                {!!localActionSettings.productInsertOption && (
                                    <ProductSelector
                                        selectedProducts={localActionSettings.productsToInsert}
                                        onSelectProducts={handleOnSelectProducts}
                                        title={t("settings.business_rule_creator.business_rule_action_settings.product_selector.ADD_INSERT_OPTION.title")}
                                        description={t("settings.business_rule_creator.business_rule_action_settings.product_selector.ADD_INSERT_OPTION.description")}
                                        addProductText={t("settings.business_rule_creator.business_rule_action_settings.product_selector.ADD_INSERT_OPTION.add_product_button")}
                                        addAnotherProductText={t("settings.business_rule_creator.business_rule_action_settings.product_selector.ADD_INSERT_OPTION.add_another_product_button")}
                                        productsQueryExtraParams={{is_scannable: false}}
                                    />
                                )}
                            </>
                        )}

                        {localActionSettings.actionOption === ALL_AVAILABLE_ACTION_OPTIONS.ADD_SHIPPING_PACKAGE_OPTION && (
                            <ProductSelector
                                selectedProducts={localActionSettings.productsToInsert}
                                onSelectProducts={handleOnSelectProducts}
                                title={t("settings.business_rule_creator.business_rule_action_settings.product_selector.ADD_SHIPPING_PACKAGE_OPTION.title")}
                                addProductText={t("settings.business_rule_creator.business_rule_action_settings.product_selector.ADD_SHIPPING_PACKAGE_OPTION.add_product_button")}
                                addAnotherProductText={t("settings.business_rule_creator.business_rule_action_settings.product_selector.ADD_SHIPPING_PACKAGE_OPTION.add_another_product_button")}
                                productsQueryExtraParams={{is_scannable: false}}
                            />
                        )}

                        {!isCompleted ? (
                            <Button
                                type="primary"
                                className={`m-5 ${areRuleActionSettingsValid ? "" : "cursor-not-allowed"}`}
                                onClick={handleOnSubmitRuleAction}
                                disabled={!areRuleActionSettingsValid}
                            >
                                {!localActionSettings.actionOption && (
                                    t("settings.business_rule_creator.business_rule_action_settings.confirmation_button_unselected_action")
                                )}
                                {localActionSettings.actionOption === ALL_AVAILABLE_ACTION_OPTIONS.ADD_SHIPPING_PACKAGE_OPTION && localActionSettings.productsToInsert.length <= 0 && (
                                    t("settings.business_rule_creator.business_rule_action_settings.product_selector.ADD_SHIPPING_PACKAGE_OPTION.confirmation_button_missing_product")
                                )}
                                {localActionSettings.actionOption === ALL_AVAILABLE_ACTION_OPTIONS.ADD_SHIPPING_PACKAGE_OPTION && localActionSettings.productsToInsert.length > 0 && (
                                    t("settings.business_rule_creator.business_rule_action_settings.product_selector.ADD_SHIPPING_PACKAGE_OPTION.confirmation_button")
                                )}
                                {!!localActionSettings.actionOption && localActionSettings.actionOption !== ALL_AVAILABLE_ACTION_OPTIONS.ADD_SHIPPING_PACKAGE_OPTION && (
                                    t("settings.business_rule_creator.business_rule_action_settings.confirmation_button")
                                )}
                            </Button>
                        ) : (
                            <div className="flex gap-5 p-5">
                                <Button
                                    type="cancel"
                                    className="w-full"
                                    onClick={handleOnCancelRuleActionSettings}
                                >
                                    {t("settings.business_rule_creator.business_rule_action_settings.cancel_edit_button")}
                                </Button>

                                <Button
                                    type="primary"
                                    className={`w-full ${areRuleActionSettingsValid ? "" : "cursor-not-allowed"}`}
                                    onClick={handleOnConfirmEdit}
                                    disabled={!areRuleActionSettingsValid}
                                >
                                    {t("settings.business_rule_creator.business_rule_action_settings.confirm_edit_button")}
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </BusinessRuleStepCard>

            <ConfirmDialog
                open={confirmEditStatementDialog.open}
                setOpen={(isOpen) => setConfirmEditStatementDialog({open: isOpen})}
                title={t("settings.business_rule_creator.business_rule_action_settings.confirm_edit_dialog.title")}
                description={t("settings.business_rule_creator.business_rule_action_settings.confirm_edit_dialog.description")}
                confirmLabel={t("settings.business_rule_creator.business_rule_action_settings.confirm_edit_dialog.confirm_button")}
                cancelLabel={t("settings.business_rule_creator.business_rule_action_settings.confirm_edit_dialog.cancel_button")}
                onConfirm={handleOnSubmitRuleAction}
            />
        </div>
    )
}

export default BusinessRuleAction