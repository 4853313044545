import { Tooltip } from "antd"
import { useMemo, useRef } from "react"
import { DownloadIcon } from "./Icons/DownloadIcon"

export const IconButton = ({ icon = <DownloadIcon className="w-4 h-4" />, buttonClass = null, tooltipTitle = null, onClick = () => {}, disabled = false }) => {
    const containerRef = useRef(null)

    const _buttonClass = useMemo(() => {
        let _buttonClass = "ml-2 p-2 border rounded shadow hover:shadow-lg "
        if (buttonClass) _buttonClass += buttonClass
        if (disabled) {
            _buttonClass += " opacity-50"
        } else {
            _buttonClass += " cursor-pointer"
        }
        // console.log(_buttonClass)
        return _buttonClass
    }, [disabled, buttonClass])

    const handleOnClick = () => {
        if (disabled) return
        onClick()
    }

    const _enableTooltip = tooltipTitle && !disabled

    return (
        <div ref={containerRef}>
            {_enableTooltip ? (
                <Tooltip title={tooltipTitle} getPopupContainer={() => containerRef?.current}>
                    <div onClick={() => handleOnClick()} className={_buttonClass}>
                        {icon}
                    </div>
                </Tooltip>
            ) : (
                <div onClick={() => handleOnClick()} className={_buttonClass}>
                    {icon}
                </div>
            )}
        </div>
    )
}
