import { BarChart as TremorBarChart } from "@tremor/react"
import { useMemo } from "react"
import { ChartSkeleton } from "./ChartSkeleton"

const COLORLIST = ["lime", "violet", "yellow", "rose", "cyan", "zinc", "emerald", "orange", "blue", "fuchsia"]

export const BarChart = ({
    categories = [],
    data = [],
    dataIndex = "name",
    valueFormatter = (v) => v.toString(),
    barChartProps = {},
    isLoading = false,
    colors = COLORLIST,
}) => {
    const chartProps = useMemo(() => {
        if (barChartProps.colors) {
            return barChartProps
        } else {
            return { ...barChartProps, colors: colors }
        }
    }, [colors, barChartProps])

    return (
        <ChartSkeleton active={isLoading}>
            <TremorBarChart data={data} index={dataIndex} categories={categories} valueFormatter={valueFormatter} {...chartProps} />
        </ChartSkeleton>
    )
}