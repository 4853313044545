import { SearchInput } from "../../SearchInput"
import { FetchingIndicator } from "./FetchingIndicator"
import { useTranslation } from 'react-i18next';

export const TableHeader = ({headerGroups, sortable = false, filterable = false, showDataStatus = false, isFetching = false, selectable = false}) => {
   const getSortingProps = (column) => {
      return sortable ? {...column.getSortByToggleProps()} : null
   }

   const { t, i18n } = useTranslation();

   return (
       headerGroups.map((headerGroup)=>(
           <tr {...headerGroup.getHeaderGroupProps()}>
               {headerGroup.headers.map((column, index)=>(
                   <th
                       className={"bg-gray-50 sticky top-0 px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider h-10"+ (column.shrinkToContent || (selectable && index === 0) ? " w-0" : "")}
                       {...column.getHeaderProps()}
                   >
                       {/* <pre>{JSON.stringify(column)}</pre> */}
                       <div className="flex items-center py-2 whitespace-nowrap" {...getSortingProps(column)}>
                           {column.selectable ? column.render('Header') : column.fetchingIndicator ? <FetchingIndicator isFetching={isFetching}/> : i18n.t(column.render('Header')) }

                           {/*column.fetchingIndicator ? <FetchingIndicator isFetching={isFetching}/> : i18n.t(column.render('Header'))/*column.render('Header')*/}
                           {sortable && column.canSort &&
                               <span className="pl-2 inline-block">
                                   {column.isSorted
                                   ? column.isSortedDesc
                                       ? <svg className="h-4 w-4" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>
                                       : <svg className="h-4 w-4" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></svg>
                                   : <svg className="h-4 w-4" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path></svg>
                                   }
                               </span>
                           }
                       </div>
                       <div className="focus-within:text-gray-600 relative text-gray-400">
                           {filterable && (!selectable || selectable && index > 0) ? 
                              column.canFilter ? 
                                 column.render('Filter')
                                 : !column.fetchingIndicator && <SearchInput className="opacity-0 pointer-events-none w-0" debounce={300}/>
                              : null
                           }
                        </div>
                   </th>
               ))}
                {/* { showDataStatus &&
                    <th className="relative">
                        <FetchingIndicator/>
                    </th>
                } */}
           </tr>
       ))
   )
}