import {createSlice} from "@reduxjs/toolkit";

const initialState = {
   

    replenishment: {
        shippingDate: "",
        shippingArrivedDate: "",
        notes: "",
        products_count: 0,
        referenceNumber: "",
    },
    hasOptions: false,
    products: [
        /*
        {
            id,
            name,
            quantity,
            skus
        }
        * */
    ]
}

export const createReplenishmentSlice = createSlice({
    name: 'crateReplenishment',
    initialState,
    reducers: {
        setReplenishmentData: (state, action) => {
            state.replenishment = action.payload
        },
        addProducts: (state, action) => {
            if(Array.isArray(action.payload)){
                action.payload.forEach(item => {
                    state.products.push(item)
                })
            }
            else {
                state.products.push(action.payload)
            }
        },
        setHasOptions: (state, action) => {
            state.hasOptions = action.payload
        },
        deleteProduct: (state, action) => {
            state.products = state.products.filter(item => item.key!==action.payload)
        },
        modifyProduct: (state, action) => {
            state.products = state.products.map(item => {
                if(item.key===action.payload.key)
                    return action.payload
                return item
            })
        },
        resetData: (state, action) => {
            state.replenishment = initialState.replenishment
            state.products = initialState.products
        },
        setData: (state, action) => {
            state.replenishment = action.payload.replenishment
            state.products = action.payload.products
            state.hasOptions = action.payload.hasOptions
        }
    }
})

export const {
    setReplenishmentData,
    addProducts,
    deleteProduct,
    modifyProduct,
    setHasOptions,
    setData,
    resetData
} = createReplenishmentSlice.actions

export const replenishmentData = (state) => state.createReplenishment.replenishment
export const productHasOptions = (state) => state.createReplenishment.hasOptions
export const replenishmentProducts = (state) => state.createReplenishment.products
export const orderProducts = (state) => state.createOrder.products

export default createReplenishmentSlice.reducer
