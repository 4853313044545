import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { DEFAULT_QUERY_STALE_TIME, PercentageValue, replaceCategories } from "../chartDataUtils"
import { getOrdersDeliveredOnTimeByStates } from "../../../../services/storeMetricsService"
import { formatPercentage } from "../../../../utils/StringUtils"
import { BarChart } from "../../../../components/MetricsCards/BarChart"

export const OrdersDeliveredOnTimeByStates = ({ storeId = null, dateFilter = null, shippingMethodIds = null }) => {
    const { i18n, t } = useTranslation()

    const [formattedData, setFormattedData] = useState([])

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(
        ["orders_delivered_ontime_states", storeId, dateFilter, shippingMethodIds],
        () => getOrdersDeliveredOnTimeByStates(storeId, dateFilter, shippingMethodIds),
        {
            enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
            refetchOnWindowFocus: true,
            staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
            // cacheTime: 1000 * 60 * 60 * 24, // 24h
        }
    )

    useEffect(() => {
        const formatData = async (data) => {
            if (!data) return null
            const dataAsPercentage = data.map((item) => {
                const ordersOnTime = item.met_delivery_sla ? item.met_delivery_sla : 0
                const ordersLate = item.not_met_delivery_sla ? item.not_met_delivery_sla : 0
                const total = ordersOnTime + ordersLate
                let newItem = { ...item }
                newItem.met_delivery_sla = new PercentageValue(ordersOnTime, total)
                newItem.not_met_delivery_sla = new PercentageValue(ordersLate, total)
                return newItem
            })
            const dataReplacedCategories = replaceCategories(dataAsPercentage, {
                met_delivery_sla: t("metrics.collected_to_door.on_time"),
                not_met_delivery_sla: t("metrics.collected_to_door.late"),
            })
            setFormattedData(dataReplacedCategories)
            return null
        }
        formatData(data).catch((error) => {
            console.error(error)
        })
    }, [data, i18n.language])

    return (
        <BarChart
            categories={[t("metrics.collected_to_door.on_time"), t("metrics.collected_to_door.late")]}
            data={formattedData}
            dataIndex="state_name"
            valueFormatter={(percentageValue) =>
                `${formatPercentage(percentageValue.getPercentage())} (${percentageValue.getValue()} ${t("metrics.generic.orders")})`
            }
            barChartProps={{
                stack: true,
                relative: true,
                showGridLines: false,
                className: "h-60",
            }}
            isLoading={isLoading}
            colors={["green", "slate"]}
        />
    )
}
