import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { NoticeBanner } from "../../components/NoticeBanner"
import { capitalizeFirstLetter } from "../../utils/StringUtils"
import { INVOICE_CNABS } from "../../navigation/constants"

export const ORDER_ERRORS = {
    // general
    ORDER_NUMBER_NOT_UNIQUE: "ORDER_NUMBER_NOT_UNIQUE",
    MISSING_SHIPPING_FIELD: "MISSING_SHIPPING_FIELD",
    MISSING_TAX_FIELD: "MISSING_TAX_FIELD",
    MISSING_COD_VALUE: "MISSING_COD_VALUE",
    // order_lines
    UNIDENTIFIED_PRODUCT: "UNIDENTIFIED_PRODUCT",
    DUPLICATE_STORE_SKU: "DUPLICATE_STORE_SKU",
    MISSING_PRODUCT_SKU: "MISSING_PRODUCT_SKU",
    // shipping info
    UNRECOGNIZED_COUNTRY: "UNRECOGNIZED_COUNTRY",
    INVALID_ZIPCODE: "INVALID_ZIPCODE",
    UNRECOGNIZED_CITY_OR_STATE: "UNRECOGNIZED_CITY_OR_STATE",
    MELI_ORDER_NOT_READY_TO_SHIP: "MELI_ORDER_NOT_READY_TO_SHIP",
    // integration shipping method
    NO_MATCHING_INTEGRATION_SHIPPING_METHOD: "NO_MATCHING_INTEGRATION_SHIPPING_METHOD",
    // shipping groups
    NO_SHIPPING_GROUP_ASSIGNED: "NO_SHIPPING_GROUP_ASSIGNED",
    // shipping method
    MISSING_SHIPPING_METHOD: "MISSING_SHIPPING_METHOD",
    INTERNATIONAL_SHIPPING_METHOD_EXPECTED: "INTERNATIONAL_SHIPPING_METHOD_EXPECTED",
    COD_SHIPPING_METHOD_EXPECTED: "COD_SHIPPING_METHOD_EXPECTED",
    MISSING_EXTERNAL_LABEL: "MISSING_EXTERNAL_LABEL",
    SHIPPING_METHOD_HAS_NO_COVERAGE: "SHIPPING_METHOD_HAS_NO_COVERAGE",
    UNKNOWN_ORDER_WEIGHT: "UNKNOWN_ORDER_WEIGHT",
    SHIPPING_COST_UNAVAILABLE: "SHIPPING_COST_UNAVAILABLE",
    BLACKLISTED_ZIP_CODE: "BLACKLISTED_ZIP_CODE",
    // taxing info
    BILLING_TAX_ID_WRONG_LENGTH: "BILLING_TAX_ID_WRONG_LENGTH",
    INVALID_PRODUCT_INVOICING_PRICE: "INVALID_PRODUCT_INVOICING_PRICE",
    INVALID_PRODUCT_NCM: "INVALID_PRODUCT_NCM",
    TOTAL_ORDER_VALUE_MISMATCH: "TOTAL_ORDER_VALUE_MISMATCH",
    MISSING_DIFAL_PAYMENT_RECEIPT: "MISSING_DIFAL_PAYMENT_RECEIPT",
    INVALID_TOTAL_ORDER_VALUE: "INVALID_TOTAL_ORDER_VALUE",
    NO_INVOICEABLE_PRODUCTS: "NO_INVOICEABLE_PRODUCTS",
    WEBMANIA_ERROR: "WEBMANIA_ERROR"
}

/*
    Available actions on Error:
        - create_product: Show "Create Product" button
        - edit_order: Show "Edit Order" button
        - duplicate_order: Show "Duplicate Order" button
        - cancel_order: Show "Cancel Order" button
        - pause_order: Show "Pause Order" button
        - edit_order_remove_stock: Show "Edit Order Remove Stock" button (has same text name as "edit_order")
    Example of values:
        - Show "Create Product" and "Edit Order" buttons: ["create_product", "edit_order"]
        - Show "Edit Order" button: ["edit_order"]
        - This error has no action:  null
 */
export const ORDER_ERRORS_ACTIONS = {
        // general
        ORDER_NUMBER_NOT_UNIQUE: null,
        MISSING_SHIPPING_FIELD: ["edit_order"],
        MISSING_TAX_FIELD: ["edit_order"],
        MISSING_COD_VALUE: ["edit_order"],
        // order_lines
        UNIDENTIFIED_PRODUCT:  ["create_product", "edit_order"],
        DUPLICATE_STORE_SKU: null,
        MISSING_PRODUCT_SKU: ["edit_order"],
        // shipping info
        UNRECOGNIZED_COUNTRY: ["edit_order"],
        INVALID_ZIPCODE: ["edit_order"],
        UNRECOGNIZED_CITY_OR_STATE: ["edit_order"],
        MELI_ORDER_NOT_READY_TO_SHIP: null,
        // integration shipping method
        NO_MATCHING_INTEGRATION_SHIPPING_METHOD: ["config_shipping_method"],
        NO_MATCHING_INTEGRATION_SHIPPING_METHOD_NO_NAME: ["edit_order"],
        // shipping groups
        NO_SHIPPING_GROUP_ASSIGNED: ["config_shipping_method"],
        // shipping method
        MISSING_SHIPPING_METHOD: ["edit_order"],
        INTERNATIONAL_SHIPPING_METHOD_EXPECTED: ["edit_order"],
        COD_SHIPPING_METHOD_EXPECTED: ["edit_order"],
        MISSING_EXTERNAL_LABEL: ["edit_order"],
        SHIPPING_METHOD_HAS_NO_COVERAGE: ["edit_order"],
        UNKNOWN_ORDER_WEIGHT: ["edit_order"],
        SHIPPING_COST_UNAVAILABLE: null,
        BLACKLISTED_ZIP_CODE: null,
        // taxing info
        BILLING_TAX_ID_WRONG_LENGTH: ["edit_order"],
        INVALID_PRODUCT_INVOICING_PRICE: ["edit_order"],
        INVALID_PRODUCT_NCM: null,
        INVALID_TOTAL_ORDER_VALUE: ["edit_order"],
        NO_INVOICEABLE_PRODUCTS: ["edit_order"],
}

export const ORDER_ERROR_ACTION_LIST = {
    DUPLICATE_ORDER: "duplicate_order",
    CANCEL_ORDER: "cancel_order",
    EDIT_ORDER: "edit_order",
    PAUSE_ORDER: "pause_order",
    EDIT_ORDER_REMOVE_STOCK: "edit_order_remove_stock",
    CREATE_PRODUCT: "create_product",
    CONFIG_SHIPPING_METHOD: "config_shipping_method"
}

export const orderHasErrorCodes = (orderErrors, codesToCheck) => {
    if (!orderErrors) return false
    if (!Array.isArray(codesToCheck)) return false
    return orderErrors.some((orderError) => codesToCheck.some((codeToCheck) => orderError.code === codeToCheck))
}

export const filterOrderErrorsByCodes = (orderErrors, codesToFilter) => {
    if (!orderErrors) return []
    if (!Array.isArray(codesToFilter)) return []
    return orderErrors.filter((orderError) => codesToFilter.some((codeToFilter) => orderError.code === codeToFilter))
}

export const filterOrderErrorsByFields = (orderErrors, fieldsToFilter) => {
    if (!orderErrors) return []
    if (!Array.isArray(fieldsToFilter)) return []
    return orderErrors.filter((orderError) => fieldsToFilter.some((fieldToFilter) => orderError.field === fieldToFilter))
}

export const orderHasErrorInFields = (orderErrors, fieldsToCheck) => {
    if (!orderErrors) return false
    if (!Array.isArray(fieldsToCheck)) return false
    return orderErrors.some((orderError) => fieldsToCheck.some((fieldToCheck) => orderError.field === fieldToCheck))
}

export const buildSolutionForOrderError = (errorObject, translationHook, storePath, specificTranslation = false) => {
    if (!errorObject?.code) return null
    let translationCode = "orders.orderErrorsSolutions." + errorObject.code
    if (specificTranslation) translationCode += "_SPECIFIC"
    let message = translationHook(translationCode)
    switch (errorObject.code) {
        case ORDER_ERRORS.UNKNOWN_ORDER_WEIGHT:
            message = message.replace("{sku}", errorObject.additional_info?.sku)
            break
        case ORDER_ERRORS.NO_MATCHING_INTEGRATION_SHIPPING_METHOD:
            if(!errorObject.additional_info?.integration_shipping_name){
                message = translationHook(translationCode + "_NO_NAME")
            }
            break
        case ORDER_ERRORS.MISSING_DIFAL_PAYMENT_RECEIPT:
            message = <>
                        {translationHook(translationCode)}
                    </>
            break 
        default:
            break
    }
    return message
}

export const getErrorActions = (errorObject) => {
    let errorActionCode = errorObject?.code

    if(!ORDER_ERRORS_ACTIONS?.hasOwnProperty(errorObject?.code)) { return [] }

    switch (errorObject?.code) {
        case ORDER_ERRORS.NO_MATCHING_INTEGRATION_SHIPPING_METHOD:
            if(!errorObject.additional_info?.integration_shipping_name){
                errorActionCode += "_NO_NAME"
            }
        break

        default:
            break
    }

    return ORDER_ERRORS_ACTIONS[errorActionCode]
}

export const buildMessageForOrderError = (errorObject, translationHook, specificTranslation = false) => {
    if (!errorObject?.code) return null
    let translationCode = "orders.orderErrors." + errorObject.code
    if (specificTranslation) translationCode += "_SPECIFIC"
    let message = translationHook(translationCode)
    switch (errorObject.code) {
        case ORDER_ERRORS.NO_MATCHING_INTEGRATION_SHIPPING_METHOD:
            if(errorObject.additional_info?.integration_shipping_name){
                message = message.replace("{integration_shipping_method_name}", errorObject.additional_info?.integration_shipping_name)
                message = message.replace("{integration_type}", capitalizeFirstLetter(errorObject.additional_info?.channel_integration_type))
            } else {
                message = translationHook(translationCode + "_NO_NAME")
                message = message.replace("{integration_type}", capitalizeFirstLetter(errorObject.additional_info?.channel_integration_type))
            }
            break

        case ORDER_ERRORS.ORDER_NUMBER_NOT_UNIQUE:
            message = message.replace("{order_number}", errorObject.additional_info?.order_number)
            break
        
        case ORDER_ERRORS.INVALID_PRODUCT_NCM:
        case ORDER_ERRORS.INVALID_PRODUCT_INVOICING_PRICE:
        case ORDER_ERRORS.UNIDENTIFIED_PRODUCT:
        case ORDER_ERRORS.DUPLICATE_STORE_SKU:
        case ORDER_ERRORS.MISSING_PRODUCT_SKU:
            message = message.replace("{sku}", errorObject.additional_info?.sku)
            break
        case ORDER_ERRORS.TOTAL_ORDER_VALUE_MISMATCH:
            message = message.replace("{expected_total_price}", "$"+errorObject.additional_info?.expected_total_price)
            message = message.replace("{calculated_total_price}", "$"+errorObject.additional_info?.calculated_total_price)
            if (errorObject.additional_info?.channel_integration_type) {
                message = message.replace("{channel_integration_type}", capitalizeFirstLetter(errorObject.additional_info?.channel_integration_type))
            }
            else {
                message = message.replace("{channel_integration_type}", translationHook("orders.orderHistory.by_integration"))
            }
            break

        default:
            break
    }
    return message
}

export const buildMessageForOrderHistoryError = (metadataError, t, i18n) => {
    if(!metadataError) return null

    const {code: errorCode, additional_info: additionalInfo} = metadataError
    const translationCode = "orders.orderHistory.timelineElements.error.codes." + errorCode

    if(!i18n.exists(translationCode)) return null

    let message = t(translationCode)

    switch (errorCode) {
        case ORDER_ERRORS.NO_MATCHING_INTEGRATION_SHIPPING_METHOD:
            if(additionalInfo?.integration_shipping_name){
                message = message.replace("{integration_shipping_method_name}", additionalInfo?.integration_shipping_name)
                message = message.replace("{integration_type}", capitalizeFirstLetter(additionalInfo?.channel_integration_type))
            } else {
                message = t(translationCode + "_NO_NAME")
                message = message.replace("{integration_type}", capitalizeFirstLetter(additionalInfo?.channel_integration_type))
            }
            break

        case ORDER_ERRORS.ORDER_NUMBER_NOT_UNIQUE:
            message = message.replace("{order_number}", additionalInfo?.order_number)
            break
        case ORDER_ERRORS.INVALID_PRODUCT_NCM:
        case ORDER_ERRORS.INVALID_PRODUCT_INVOICING_PRICE:
        case ORDER_ERRORS.UNIDENTIFIED_PRODUCT:
        case ORDER_ERRORS.DUPLICATE_STORE_SKU:
        case ORDER_ERRORS.MISSING_PRODUCT_SKU:
            message = message.replace("{sku}", additionalInfo?.sku)
            break
        case ORDER_ERRORS.TOTAL_ORDER_VALUE_MISMATCH:
            message = message.replace("{expected_total_price}", "$"+additionalInfo?.expected_total_price)
            message = message.replace("{calculated_total_price}", "$"+additionalInfo?.calculated_total_price)
            if (additionalInfo?.channel_integration_type) {
                message = message.replace("{channel_integration_type}", capitalizeFirstLetter(additionalInfo?.channel_integration_type))
            }
            else {
                message = message.replace("{channel_integration_type}", t("orders.orderHistory.by_integration"))
            }
            break

        case ORDER_ERRORS.WEBMANIA_ERROR:
            message = message.replace("{error}", additionalInfo)
        default:
            break
    }

    return message
}

export const OrderErrorBanner = ({ errorObject }) => {
    const { t } = useTranslation()

    const errorMessage = useMemo(() => buildMessageForOrderError(errorObject, t), [errorObject, t])

    return <NoticeBanner text={errorMessage} className="mb-2" />
}

export const OrderFieldErrorBanner = ({ errorObject, specificTranslation = false }) => {
    const { t } = useTranslation()

    const errorMessage = useMemo(() => buildMessageForOrderError(errorObject, t, specificTranslation), [errorObject, t, specificTranslation])

    const callToAction = useMemo(() => {
        if (errorObject.callToAction) {
            return <Link to={errorObject.callToAction.link} className="pl-1 text-current hover:underline">{errorObject.callToAction.text}</Link>
        }
        return <></>
    }, [errorObject])

    return (
        <div key={errorObject.code}>
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">{errorMessage}.{callToAction}</span>
        </div>
    )
}
