import { MetricPercentage } from "../../../../components/MetricsCards/MetricPercentage"
import { numberToString } from "../../../../helpers/internationalization"
import { useTranslation } from "react-i18next"
import { useEffect, useMemo, useState } from "react"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"

export const StuckOrdersPercentage = ({ data = [], categories = []}) => {
    const { i18n, t } = useTranslation()
    const [totalStuckOrders, setTotalStuckOrders] = useState(0)
    const [totalOrders, setTotalOrders] = useState(0)
    const isLoading = useMemo(() => {
        return !data
    }, [data])

    useEffect(() => {
        if (!data){
            setTotalStuckOrders(0)
            setTotalOrders(0)
            return
        }
        const total = data.filter((d) => d["longest_duration_status"] != "UNKNOWN")?.reduce((acc, curr) => acc + curr.count, 0) || 0
        let totalStuckOrders = 0;

        categories.forEach((category) => {
            const item = data.find((d) => d["longest_duration_status"] === category)
            totalStuckOrders += item?.count || 0    
        })
        setTotalStuckOrders(totalStuckOrders)
        setTotalOrders(total)
    }, [data])


    return (
        <div>
            <MetricTitle title={t('metrics.stuck_orders.title')} className={"mb-1"}/>
            <MetricPercentage
                value={totalStuckOrders}
                total={totalOrders}
                showLoader={isLoading}
                valueFormatter={(n) => numberToString(n, i18n.language)}
                totalLabel={t("metrics.generic.shipped")}
            />
        </div>
    )
}
