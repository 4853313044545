export const getStatusDefinition = (status = "", statuses = []) => {
  let foundIndex = statuses.findIndex(def => def.status === status)
  if (foundIndex === -1) {
    return statuses[0]
  }
  else {
    return statuses[foundIndex]
  }
}


export const ORDER_STATUSES = [

  {
    status: "unknown",
    label: "statuses.order_statuses.unknown",
    classes: "",
    filterable: false
  },
  {
    status: "initial",
    label: "statuses.order_statuses.initial",
    classes: "border-red-400 text-red-400",
  },
  {
    status: "empty",
    label: "statuses.order_statuses.empty",
    classes: "border-red-400 text-red-400",
  },
  {
    status: "pending",
    label: "statuses.order_statuses.pending",
    classes: "border-yellow-400 text-yellow-400",
  },
  {
    status: "processing",
    label: "statuses.order_statuses.processing",
    classes: "border-gray-500 text-gray-500",
  },
  {
    status: "packing",
    label: "statuses.order_statuses.processing",
    classes: "border-gray-500 text-gray-500",
    filterable: false
  },
  {
    status: "picking",
    label: "statuses.order_statuses.processing",
    classes: "border-gray-500 text-gray-500",
    filterable: false
  },
  {
    status: "waiting_pick_up",
    label: "statuses.order_statuses.waiting_pick_up",
    classes: "border-blue-500 text-blue-500",
    filterable: true
  },
  // Only for pick and ship 
  {
    status: "awaiting_pick_up",
    label: "statuses.order_statuses.awaiting_pick_up",
    classes: "border-blue-500 text-blue-500",
    filterable: false
  },
  {
    status: "picked_up",
    label: "statuses.order_statuses.picked_up",
    classes: "border-blue-500 text-blue-500",
    filterable: false
  },
  {
    status: "in_warehouse",
    label: "statuses.order_statuses.in_warehouse",
    classes: "border-blue-500 text-blue-500",
    filterable: false
  },
  {
    status: "packed",
    label: "statuses.order_statuses.processing",
    classes: "border-gray-500 text-gray-500",
    filterable: false
  },
  {
    status: "shipped",
    label: "statuses.order_statuses.shipped",
    classes: "border-green-500 text-green-500",
  },
  {
    status: "returning",
    label: "statuses.order_statuses.returning",
    classes: "border-purple-500 text-purple-500",
  },
  {
    status: "return_arrived",
    label: "statuses.order_statuses.return_arrived",
    classes: "border-pink-500 text-ponk-500",
  },
  {
    status: "returned",
    label: "statuses.order_statuses.returned",
    classes: "border-green-700 text-green-700",
  },
  {
    status: "canceled",
    label: "statuses.order_statuses.canceled",
    classes: "border-red-500 text-red-500",
  },
  {
    status: "error",
    label: "statuses.order_statuses.error",
    classes: "border-red-500 text-red-500",
  },
  {
    status: "entirely_dropshipping",
    label: "statuses.order_statuses.entirely_dropshipping",
    classes: "border-red-500 text-red-500",
  },
  {
    status: "backorder",
    label: "statuses.order_statuses.backorder",
    classes: "border-red-400 text-red-400",
  },
  {
    status: "unpaid",
    label: "statuses.order_statuses.unpaid",
    classes: "border-pink-500 text-pink-500",
  },
  {
    status: "cancelation_window",
    label: "statuses.order_statuses.cancelation_window",
    classes: "border-blue-500 text-blue-500",
  },
  {
    status: "holded",
    label: "statuses.order_statuses.hold",
    classes: "border-blue-500 text-blue-500"
  },
  {
    status: "interrupted",
    label: "statuses.order_statuses.interrupted",
    classes: "border-yellow-700 text-yellow-700"
  }
];

export const CLAIMS_STATUSES = [
  {
    status: "unknown",
    label: "statuses.claims_statuses.unknow",
    classes: "",
    filterable: false
  },
  {
    status: "PENDING",
    label: "statuses.claims_statuses.pending",
    classes: "border-yellow-400 text-yellow-400",
  },
  {
    status: "REJECTED",
    label: "statuses.claims_statuses.rejected",
    classes: "border-red-500 text-red-500",
  },
  {
    status: "ACCEPTED",
    label: "statuses.claims_statuses.accepted",
    classes: "border-green-500 text-green-500",
  }
];

export const RETURNS_STATUSES = [
  {
    status: "unknown",
    label: "statuses.returns_statuses.unknow",
    classes: "",
    filterable: false
  },
  {
    status: "REQUESTED",
    label: "statuses.returns_statuses.requested",
    classes: "border-blue-500 text-blue-500",
  },
  {
    status: "PENDING",
    label: "statuses.returns_statuses.pending",
    classes: "border-red-500 text-red-500",
  },
  {
    status: "CANCELLED",
    label: "statuses.returns_statuses.cancelled",
    classes: "border-red-400 text-red-400",
  },
  {
    status: "ORDERED",
    label: "statuses.returns_statuses.ordered",
    classes: "border-green-500 text-green-500",
  },
  {
    status: "RECEIVED",
    label: "statuses.returns_statuses.received",
    classes: "border-green-500 text-green-500",
  }
];

export const TRACKING_STATUSES_DEFINITIONS = [
  {
    status: "unknown",
    label: "statuses.tracking_statuses.unknown",
    classes: "",
    filterable: false
  },
  {
    status: "shipment_not_defined",
    label: "statuses.tracking_statuses.unknown",
    classes: "",
    filterable: false
  },
  {
    status: "shipment_created",
    label: "statuses.tracking_statuses.in_transit",
    // label: "Guía generada",
    classes: "border-grey-500 text-grey-500",
    filterable: false
  },
  {
    status: "shipment_collected",
    label: "statuses.tracking_statuses.in_transit",
    // label: "Recolectado",
    classes: "border-grey-500 text-grey-500",
    filterable: false
  },
  {
    status: "shipment_in_transit",
    label: "statuses.tracking_statuses.in_transit",
    classes: "border-grey-500 text-grey-500",
  },
  {
    status: "shipment_delivered",
    label: "statuses.tracking_statuses.shipment_delivered",
    classes: "border-green-400 text-green-400",
  },
  {
    status: "shipment_canceled",
    label: "statuses.tracking_statuses.shipment_canceled",
    classes: "border-red-400 text-red-400"
  },
  {
    status: "shipment_out_for_delivery",
    label: "statuses.tracking_statuses.shipment_out_for_delivery",
    classes: "border-blue-500 text-blue-500"
  },
  {
    status: "shipment_to_be_self_collected",
    label: "statuses.tracking_statuses.shipment_to_be_self_collected",
    classes: "border-yellow-400 text-yellow-400"
  },
  {
    status: "shipment_returned",
    label: "statuses.tracking_statuses.in_transit",
    // label: "Retornado por paqueteria",
    classes: "border-grey-500 text-grey-500",
    filterable: false
  },
  {
    status: "shipment_with_incident",
    label: "statuses.tracking_statuses.shipment_with_incident",
    classes: "border-yellow-400 text-yellow-400"
  }
];
