const ConditionTypeOption = ({optionTitle, optionDescription, onSelectedOption}) => {
    return (
        <div className="flex flex-col bg-white hover:bg-gray-100 py-2 pr-2 pl-5" onClick={onSelectedOption}>
            <div className="font-semibold">
                {optionTitle}
            </div>

            <div className="text-sm">
                {optionDescription}
            </div>
        </div>
    )
}

export default ConditionTypeOption