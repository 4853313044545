import {
    LOGIN,
    FORGOT_PASSWORD,
    ROOT,
    STORES,
    SHOPIFY_ONBOARDING,
    SIGNUP,
    BLING
} from "./constants"
import { Redirect, Route, Switch } from "react-router-dom"
import { Layout1, TopMenu } from "../components"
import { Login } from "../pages/Login"
import { ForgotPassword } from "../pages/Login"
import { SignUp } from "../pages/Login"
import PrivateRoute from "./Auth/PrivateRoute"
import PublicRoute from "./Auth/PublicRoute"
import { RedirectToPreferredStore, StoreRouter } from "./StoreRouter"
import { ViewShopifyOnboarding } from "../pages/Onboardings"
import { BlingPublicPage } from "../pages/integrations"

export const RouterConfig = () => {
    return (
        <Switch>
            <PublicRoute restricted={true} exact path={LOGIN} component={Login} />
            <PublicRoute restricted={true} exact path={SIGNUP} component={SignUp} />
            <PublicRoute restricted={true} exact path={FORGOT_PASSWORD} component={ForgotPassword} />
            <PublicRoute restricted={true} exact path={BLING} component={BlingPublicPage} />
            <PrivateRoute path={SHOPIFY_ONBOARDING}>
                <ViewShopifyOnboarding />
            </PrivateRoute>

            <Layout1 menu={<TopMenu />}>
                <Switch>
                    {/* on root, redirect to preferred store root */}
                    <PrivateRoute path={ROOT} exact>
                        <RedirectToPreferredStore />
                    </PrivateRoute>

                    <PrivateRoute path={STORES}>
                        <StoreRouter />
                    </PrivateRoute>

                    {/* default to store index */}
                    <Route>
                        <Redirect to={STORES} />
                    </Route>
                </Switch>
            </Layout1>


        </Switch>
    )
}
