import React, { useEffect, useState } from "react"

const VALID_NUMERIC_DECIMAL_REGEX = /^\d+$|^\d+\.$|^\d+\.\d{0,2}$/
const VALID_NUMERIC_REGEX = /^\d+$/

export const NumericInputField = React.forwardRef(({value, onChange, title=null, disabled = false, labelElement=null, placeholder, required=false, readOnly=false, inputClassName="", allowDecimals = true, ...params}, ref) => {
    const { className } = params

    const [inputValue, setInputValue] = useState("")

    useEffect(() => {
        if (!value){
            setInputValue("")
        }
        else {
            setInputValue(value)
        }
    }, [value])
    

    const handleChange = (newValue) => {
        if (newValue === null) {
            return null
        }
        if (allowDecimals) {
            if (newValue === '' || VALID_NUMERIC_DECIMAL_REGEX.test(newValue)) {
                onChange(newValue)
                setInputValue(newValue)
            }
        }
        else {
            if (newValue === '' || VALID_NUMERIC_REGEX.test(newValue)) {
                onChange(newValue)
                setInputValue(newValue)
            } 
        }
    }

    return (
        <div className={className}>
            { title && <label className="block text-sm font-medium text-gray-700">
                {title}{required && <span className="text-red-500"> *</span>}
            </label>}
            {labelElement}
            <div className="mt-1">
                <input
                    ref={ref}
                    type="text"
                    className={"shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"+" "+inputClassName}
                    placeholder={placeholder}
                    onChange={(event) => {handleChange(event.target.value)} }
                    value={inputValue}
                    readOnly={readOnly}
                    disabled={disabled}
                />
            </div>
        </div>
    )
})