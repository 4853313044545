import { SearchInput } from "../../SearchInput"
import { Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { findIndex } from "lodash";

const { RangePicker } = DatePicker;

const defaultDateTimeRangeFilterOptions = {
    size: "default",
    placeholder: ['Start', 'End'],
    showTime: { format: 'HH:mm' },
    format: "YYYY-MM-DD HH:mm",
    discardTime: true
}

export const DateTimeRangeFilter =
    ({
        column: {
            setFilter,
            filterOptions = {}
        }
    }) => {

    const options = {...defaultDateTimeRangeFilterOptions, ...filterOptions}

    const handleChange = function (value) {
        if (value === null) {
            setFilter(null)

            return true
        }

        const from = new Date(value[0])
        const to = new Date(value[1])

        if (options.discardTime) {
            from.setHours(0, 0, 0);
            to.setHours(23, 59, 59, 999);
        }

        setFilter({ from: from.toISOString(), to: to.toISOString() })
    }

    return (
        <div className="bg-white py-1 border border-gray-300 rounded-md ">
            <RangePicker
                bordered={false}
                format={options.format}
                size={options.size}
                placeholder={options.placeholder}
                showTime={options.showTime}
                onChange={value => handleChange(value)}
            />
        </div>
    )

}

export function TextColumnFilter({
   column: { filterValue, setFilter, numericOnlyFilter = false }
}) {
   return (
       <SearchInput
           inputValue = {filterValue}
           onChange = { value => { setFilter(value || undefined) } }
           debounce = {250}
           numericOnly = {numericOnlyFilter}
       ></SearchInput>
   )
}

export function SelectColumnFilter({
   column: { filterValue, setFilter, selectFilterOptions },
}) {
   const { t, i18n} = useTranslation();
   return (
      <div className="w-full">
         <select
            value={filterValue}
            className="font-normal block w-full bg-white py-2 border border-gray-300 rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
            onChange={e => {
                  setFilter(e.target.value || undefined)
            }}
         >
            <option value="">Todos</option>
            {
               selectFilterOptions.map((option, index) => {
                  return <option key={index} value={option.id}> {i18n.t(option.label)} </option>
               })
            }
         </select>
      </div>
   )
}
export function SelectColumnMultiFilter({
   column: { filterValue, setFilter, selectFilterOptions },
   
}) {
   const children = [];
   const { Option } = Select;
   const { t, i18n } = useTranslation();
   const [search, setSearch] = useState(null)
   const [values, setValues] = useState([])

   function handleChange(value) {
      let status = values
      let foundIndex = values.findIndex((val) => val === value)
      if(foundIndex == -1)status.push(value)
      else status.splice(foundIndex,1)
      setValues(status)
      setFilter(values || undefined)
      setSearch(null)
   }

   const onSearch = (value) => {
      setSearch(value?.toLowerCase())
   }

   return (
      <div className="w-full ">
          <Select
               mode="multiple"
               allowClear
               //className="block w-full bg-white py-2 border border-gray-300 rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
               size="small"
               className=""
               style={{ width: '100%', fontSize:"x-small"}}
               placeholder={i18n.t("inputPlaceholder.select")}
               defaultValue={filterValue}
               onSelect={handleChange}
               onDeselect={handleChange}
               onClear={() => {onSearch(null)}}
               onSearch={(v) => {onSearch(v)}}
               filterOption={false}
               >
                  {
                     selectFilterOptions.filter(option => search ? i18n.t(option.label).toLowerCase().includes(search) : true).map((option, index) => {
                        return <Option key={index} value={option.id}> {i18n.t(option.label)} </Option>
                     })
                  }
            </Select>
      </div>
   )
}