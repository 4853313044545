import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { DEFAULT_QUERY_STALE_TIME, PercentageValue, replaceCategories } from "../chartDataUtils"
import { getOrdersDeliveredOnTimeByCarrier } from "../../../../services/storeMetricsService"
import { capitalize, formatPercentage } from "../../../../utils/StringUtils"
import { BarChart } from "../../../../components/MetricsCards/BarChart"

export const OrdersDeliveredOnTimeByCarrier = ({ storeId = null, dateFilter = null, stateName = null }) => {
    const { i18n, t } = useTranslation()

    const [formattedData, setFormattedData] = useState([])

    const {
        data: ordersDeliveredOnTimeByCarrierData,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(
        ["orders_delivered_ontime_carriers", storeId, dateFilter, stateName],
        () => getOrdersDeliveredOnTimeByCarrier(storeId, dateFilter, stateName),
        {
            enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
            refetchOnWindowFocus: true,
            staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
            // cacheTime: 1000 * 60 * 60 * 24, // 24h
        }
    )

    useEffect(() => {
        const formatData = async (data) => {
            if (!data) return null
            const dataMergedCarriers = data.reduce((acc, item) => {
                const index = acc.findIndex((i) => i.carrier_name === item.carrier_name)
                if (index === -1) {
                    acc.push({ ...item })
                } else {
                    acc[index].met_delivery_sla = (acc[index].met_delivery_sla || 0) + (item.met_delivery_sla || 0)
                    acc[index].not_met_delivery_sla = (acc[index].not_met_delivery_sla || 0) + (item.not_met_delivery_sla || 0)
                }
                return acc
            }, [])
            const dataAsPercentage = dataMergedCarriers.map((item) => {
                const ordersOnTime = item.met_delivery_sla ? item.met_delivery_sla : 0
                const ordersLate = item.not_met_delivery_sla ? item.not_met_delivery_sla : 0
                const total = ordersOnTime + ordersLate
                let newItem = { ...item, capitalizedName: capitalize(item.carrier_name) }
                newItem.met_delivery_sla = new PercentageValue(ordersOnTime, total)
                newItem.not_met_delivery_sla = new PercentageValue(ordersLate, total)
                return newItem
            })
            const dataReplacedCategories = replaceCategories(dataAsPercentage, {
                met_delivery_sla: t("metrics.collected_to_door.on_time"),
                not_met_delivery_sla: t("metrics.collected_to_door.late"),
            })
            dataReplacedCategories.sort((a, b) => {
                if (a.capitalizedName < b.capitalizedName) return -1
                if (a.capitalizedName > b.capitalizedName) return 1
                return 0
            })
            setFormattedData(dataReplacedCategories)
            return null
        }
        formatData(ordersDeliveredOnTimeByCarrierData).catch((error) => {
            console.error(error)
        })
    }, [ordersDeliveredOnTimeByCarrierData, i18n.language])

    return (
        <BarChart
            categories={[t("metrics.collected_to_door.on_time"), t("metrics.collected_to_door.late")]}
            data={formattedData}
            dataIndex="capitalizedName"
            valueFormatter={(percentageValue) =>
                `${formatPercentage(percentageValue.getPercentage())} (${percentageValue.getValue()} ${t("metrics.generic.orders")})`
            }
            barChartProps={{
                stack: true,
                relative: true,
                showGridLines: false,
                className: "h-60",
            }}
            isLoading={isLoading}
            colors={["green", "slate"]}
        />
    )
}
