import { useMemo } from "react";

import { CONDITION_VALIDATION_TYPES, CUSTOM_STATEMENT_VALIDATIONS, CUSTOM_STATEMENT_QUANTITIES } from "../../../utils/businessRuleStatementUtils";

const useConditionValidator = (validationType, ruleStatement, allSkuExists) => {
    const isStatementValid = useMemo(() => {
        let isValid = true

        switch (validationType) {
            case CONDITION_VALIDATION_TYPES.FOR_EVERY_PRODUCT:
                {
                    if(!Number.isInteger(ruleStatement.for_every_quantity) || ruleStatement.for_every_quantity <= 0){
                        isValid = false
                    }
                    else if(!Array.isArray(ruleStatement.forEveryProduct) && ruleStatement.forEveryProduct !== null){
                        isValid = false
                    }
                    else if(Array.isArray(ruleStatement.forEveryProduct) && ruleStatement.forEveryProduct.length <= 0){
                        isValid = false
                    }
                    else if(typeof ruleStatement.withoutMaxMultipliedQuantity !== "boolean"){
                        isValid = false
                    }
                    else if(!ruleStatement.withoutMaxMultipliedQuantity && !Number.isInteger(ruleStatement.maxMultipliedQuantity)){
                        isValid = false
                    }
                    else if(!ruleStatement.withoutMaxMultipliedQuantity && ruleStatement.maxMultipliedQuantity <= 0){
                        isValid = false
                    }
                    else if(!ruleStatement.anyProduct && (!ruleStatement.forEveryProduct || ruleStatement.forEveryProduct.length <= 0 || !allSkuExists)){
                        isValid = false
                    }

                    break
                }
            case CONDITION_VALIDATION_TYPES.CHANNEL_TYPE_NAME:
                break
            default:
                {
                    if(!Array.isArray(ruleStatement.products) && ruleStatement.products !== null){
                        isValid = false
                    }
                    else if(Array.isArray(ruleStatement.products) && ruleStatement.products.length <= 0){
                        isValid = false
                    }
                    else if(!ruleStatement.selectedValidationOption || !ruleStatement.selectedQuantityOption){
                        isValid = false
                    }
                    else if(!Object.values(CUSTOM_STATEMENT_VALIDATIONS).some((validOption) => validOption === ruleStatement.selectedValidationOption)){
                        isValid = false
                    }
                    else if(!Object.values(CUSTOM_STATEMENT_QUANTITIES).some((validOption) => validOption === ruleStatement.selectedQuantityOption)){
                        isValid = false
                    }
                    else if(typeof ruleStatement.exclusive !== "boolean"){
                        isValid = false
                    }
                    else if(!Number.isInteger(ruleStatement.minQuantity) && ruleStatement.minQuantity !== null){
                        isValid = false
                    }
                    else if(!Number.isInteger(ruleStatement.maxQuantity) && ruleStatement.maxQuantity !== null){
                        isValid = false
                    }
                    else if(!Number.isInteger(ruleStatement.exactQuantity) && ruleStatement.exactQuantity !== null){
                        isValid = false
                    }
                    else if(ruleStatement.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MINIMUM && (!ruleStatement.minQuantity || ruleStatement.minQuantity <= 0)){
                        isValid = false
                    }
                    else if(ruleStatement.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MAXIMUM && (!ruleStatement.maxQuantity || ruleStatement.maxQuantity <= 0)){
                        isValid = false
                    }
                    else if(ruleStatement.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.EXACTLY && (!ruleStatement.exactQuantity || ruleStatement.exactQuantity <= 0)){
                        isValid = false
                    }
                    else if(ruleStatement.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MINIMUM_AND_MAXIMUM && (!ruleStatement.minQuantity || !ruleStatement.maxQuantity)){
                        isValid = false
                    }
                    else if(ruleStatement.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MINIMUM_AND_MAXIMUM && (ruleStatement.minQuantity <= 0 || ruleStatement.maxQuantity <= 0)){
                        isValid = false
                    }
                    else if(ruleStatement.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MINIMUM_AND_MAXIMUM && (ruleStatement.minQuantity >= ruleStatement.maxQuantity)){
                        isValid = false
                    }
                    else if(!ruleStatement.anyProduct && (!ruleStatement.products || ruleStatement.products.length <= 0 || !allSkuExists)){
                        isValid = false
                    }

                    break
                }
        }

        return isValid
    }, [validationType, ruleStatement, allSkuExists])

    return isStatementValid
}

export default useConditionValidator