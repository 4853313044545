import { Grid, Col } from "@tremor/react"
import { BaseCard } from "../../../../components/MetricsCards/BaseCard"
import { MetricsCardHeader } from "../../../../components/MetricsCards/MetricsCardHeader"
import { INTERVAL_DAY } from "../chartDataUtils"
import { PickingAccuracyPercentage } from "./PickingAccuracyPercentage"
import { OrdersPackedOnTime } from "./OrdersPackedOnTime"
import { OrdersPackedOnTimeHistoric } from "./OrdersPackedOnTimeHistoric"
import { useTranslation } from "react-i18next"
import { ClickToPackedAverage } from "./ClickToPackedAverage"
import useOnScreen from "../../../../hooks/useOnScreen"
import { useEffect, useRef } from "react"

export const ClickToPacked = ({ storeId = null, dateFilter = null, interval = INTERVAL_DAY }) => {
    const { t } = useTranslation()

    const cardRef = useRef(null)
    const cardIsOnScreen = useOnScreen(cardRef)
    useEffect(() => {
      if (cardIsOnScreen) window.analytics.track("Metrics - Views ClickToPacked")
    }, [cardIsOnScreen])

    return (
        <BaseCard className="my-6" ref={cardRef}>
            <MetricsCardHeader title={t("metrics.click_to_packed.title")} subtitle={t("metrics.click_to_packed.subtitle")} informationCircleText={t("metrics.click_to_packed.information_circle_text")} />
            <ClickToPackedAverage storeId={storeId} dateFilter={dateFilter} />
            <Grid numItemsSm={1} numItemsLg={3} className="w-full gap-10 lg:gap-x-20 py-5">
                <Col numColSpan={1} className="flex flex-col lg:gap-16 gap-10">
                    <OrdersPackedOnTime storeId={storeId} dateFilter={dateFilter} />
                    <PickingAccuracyPercentage storeId={storeId} dateFilter={dateFilter} />
                </Col>
                <Col numColSpan={1} numColSpanLg={2}>
                    <OrdersPackedOnTimeHistoric storeId={storeId} dateFilter={dateFilter} interval={interval} />
                </Col>
            </Grid>
        </BaseCard>
    )
}
