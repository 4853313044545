import { TextWithTags } from "../TextWithTags";

const ConditionTemplateOption = ({optionTextWithTags, onSelectedOption}) => {
    return (
        <div className="flex flex-col bg-white hover:bg-gray-100 py-2 pr-2 pl-5" onClick={onSelectedOption}>
            <TextWithTags
                textWithTags={optionTextWithTags}
            />
        </div>
    )
}

export default ConditionTemplateOption