export const OrderContainsSkuIcon = ({ className = null }) => {
    return (
        <svg className={className} viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M69.2472 92.7173H88.7173M88.7173 92.7173H108.188M88.7173 92.7173V73.2472M88.7173 92.7173V112.188M25.9801 55.9403H38.9602C42.4028 55.9403 45.7043 54.5728 48.1386 52.1386C50.5728 49.7043 51.9403 46.4028 51.9403 42.9602V29.9801C51.9403 26.5376 50.5728 23.236 48.1386 20.8018C45.7043 18.3675 42.4028 17 38.9602 17H25.9801C22.5376 17 19.236 18.3675 16.8018 20.8018C14.3675 23.236 13 26.5376 13 29.9801V42.9602C13 46.4028 14.3675 49.7043 16.8018 52.1386C19.236 54.5728 22.5376 55.9403 25.9801 55.9403ZM25.9801 112.188H38.9602C42.4028 112.188 45.7043 110.82 48.1386 108.386C50.5728 105.951 51.9403 102.65 51.9403 99.2074V86.2273C51.9403 82.7847 50.5728 79.4832 48.1386 77.049C45.7043 74.6147 42.4028 73.2472 38.9602 73.2472H25.9801C22.5376 73.2472 19.236 74.6147 16.8018 77.049C14.3675 79.4832 13 82.7847 13 86.2273V99.2074C13 102.65 14.3675 105.951 16.8018 108.386C19.236 110.82 22.5376 112.188 25.9801 112.188ZM82.2273 55.9403H95.2074C98.6499 55.9403 101.951 54.5728 104.386 52.1386C106.82 49.7043 108.188 46.4028 108.188 42.9602V29.9801C108.188 26.5376 106.82 23.236 104.386 20.8018C101.951 18.3675 98.6499 17 95.2074 17H82.2273C78.7847 17 75.4832 18.3675 73.049 20.8018C70.6147 23.236 69.2472 26.5376 69.2472 29.9801V42.9602C69.2472 46.4028 70.6147 49.7043 73.049 52.1386C75.4832 54.5728 78.7847 55.9403 82.2273 55.9403Z"
                stroke="currentColor"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
