import axiosInstance from "../helpers/axiosInstance"

export const exportProductStockHistories = async (store_id, params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/product-stock-histories/store/${store_id}/generate-csv`, {
        params: params
    })
    return res.data
}

export const fetchAvailableProductStockHistoryDates = async (store_id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/product-stock-histories/store/${store_id}/available-dates`)
    return res.data
}
