import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './searchSlice';
import pageSizeRangeReducer from './pageSizeSlice';
import validSettingsFormReducer from './validSettingsFormSlice';
import importProductsReducer from './importProductsSlice'
import createProductReducer from './createProductSlice'
import createOrderReducer from './createOrderSlice'
import createReplenishmentReducer from './createReplenishmentSlice'
import userReducer from './userSlice'
import asyncTaskReducer from './asyncTaskSlice'

export const store = configureStore({
    reducer: {
        search: searchReducer,
        user: userReducer,
        pageSize: pageSizeRangeReducer,
        importProducts: importProductsReducer,
        createProducts: createProductReducer,
        createOrder: createOrderReducer,
        createReplenishment: createReplenishmentReducer,
        asyncTask: asyncTaskReducer,
        validSettingsForm: validSettingsFormReducer
    },
});
