import { useState, useContext, useRef, useEffect } from "react"
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom"
import { useQuery } from "react-query"
import {
  ActionMenu,
  BasicTable,
  Button,
  ButtonLoader,
  ConfirmDialog,
  DialogView,
  FormattedDate,
  PageTopBar,
  PageView,
  SlidePanel,
} from "../../components"
import {
  RETURNS, REVIEW_EXTERNAL_RETURN,
  SHOW_ORDER_RETURNS,
  SHOW_RETURN,
  STORE_PATH,
} from "../../navigation/constants"
import { RETURNS_STATUSES } from '../../components/NewTable/StatusHighlightedDefinitions';
import { StatusHighlighted } from '../../components/NewTable/StatusHighlighted';
import { Loader } from "../../components/Loader"
import { SelectColumnFilter } from "../../components/BasicTable/modules/ColumnFiltersUI"
import { UserContext } from "../../hooks/UserContext"
import { fetchReturns, fetchCountPendingReturns } from "../../services/returnServices"
import { ShowOrderContainer } from "./../Orders/ShowOrderContainer"
import { ShowReturnContainer } from "./ShowReturnContainer"
import { getReturnTypeDefinition, RETURN_TYPES } from "./ReturnTypes"
import { getReturnNewOrderLabel } from "./ReturnNewOrderLabels"
import {ReviewExternalReturnContainer} from "./ReviewExternalReturnContainer";
import {returnNeedsReview} from "../../utils/returnsUtils";
import { useTranslation } from "react-i18next";
import { setReturnsListPageSize } from '../../redux/pageSizeSlice';
import {useDispatch, useSelector} from "react-redux";


export const ListReturnContainer = () => {
  const { t, i18n} = useTranslation();
  const { user } = useContext(UserContext)
  const history = useHistory()
  const pageSize = useSelector((state) => state.pageSize.returnsList);
  const dispatch = useDispatch()
  const { url:storePath } = useRouteMatch({ path: STORE_PATH })
  const [searchParams, setSearchParams] = useState({
    page: 1,
    store_id: user.current_store?.id,
    per_page: pageSize
  })
  const [selectedReturn, setSelectedReturn] = useState(null)

  const ShowReturnSlidePanelRef = useRef(null)

  const reviewExternalReturnContainerRef = useRef(null)

  const tableRef = useRef()

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(
    ["returns_index", searchParams],
    () => fetchReturns(searchParams),
    {
      keepPreviousData: true,
    }
  )

   const {
    isLoading: isLoadingSummary,
    isError: isErrorSummary,
    error:  errorSummary,
    data : dataSummary,
    isFetching: isFetchingSummary,
    isPreviousData:   isPreviousDataSummary,
    refetch: refetchSummary,
  } = useQuery(
    ["returns_pending_count", user.current_store?.id],
    () => fetchCountPendingReturns({ store_id: user.current_store?.id }),
    {
      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: 60000,
      enabled: user.current_store ? true : false,
    }
  )
  useEffect(() => {
    let newSearchParams = {...searchParams, store_id: user.current_store?.id}
    setSearchParams(newSearchParams)
  }, [user.current_store?.id])

  useEffect(() => {
    window.analytics.page('Returns', 'List')
  }, [])

  const columns = [
    {
      Header: "returns.container.order_number",
      accessor: "returned_order_number", // accessor is the "key" in the data
      searchAs: "returned_order_number",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "returns.container.new_order",
      accessor: "created_order_number", // accessor is the "key" in the data
      searchAs: "created_order_number",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "returns.container.type",
      accessor: "return_type", // accessor is the "key" in the data
      Filter: SelectColumnFilter,
      selectFilterOptions: RETURN_TYPES.filter(
        (status) => status.filterable !== false
      ).map((status) => {
        return status.filterable === false
          ? null
          : { id: status.type, label: status.label }
      }),
      searchAs: "return_type",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "returns.container.status",
      accessor: "status", // accessor is the "key" in the data
      Filter: SelectColumnFilter,
      selectFilterOptions: RETURNS_STATUSES.filter(
        (status) => status.filterable !== false
      ).map((status) => {
        return status.filterable === false
          ? null
          : { id: status.status, label: status.label }
      }),
      searchAs: "status",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "returns.container.reserved_parts",
      accessor: "reserved_stock_units_count", // accessor is the "key" in the data
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "returns.container.creation_date", 
      accessor: "created_at", // accessor is the "key" in the data
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "",
      accessor: "actions", // accessor is the "key" in the data
      disableFilters: true,
      disableSortBy: true,
      fetchingIndicator: true,
      shrinkToContent: true,
    },
  ]


  const showOrderDetail = (order) => {
    history.push(storePath + SHOW_ORDER_RETURNS.replace(":id", order.id))
  }

  const showReturnDetail = (_return) => {
    if (returnNeedsReview(_return)) {
      showReviewExternalReturnContainer(_return)
    }
    else {
      history.push(storePath + SHOW_RETURN.replace(":id", _return.id))
    }
  }

  const showReviewExternalReturnContainer = (_return) => {
    setSelectedReturn(_return)
    history.push(storePath + REVIEW_EXTERNAL_RETURN.replace(':return_id', _return.id))
  }

  const onSortChange = (orderBy) => {
    if (orderBy.length > 0) {
      setSearchParams({
        ...searchParams,
        order_by: orderBy[0].id,
        order: orderBy[0].desc ? "desc" : "asc",
        page: 1
      })
    } else {
      if ("order_by" in searchParams) {
        delete searchParams.order_by
        delete searchParams.order
        setSearchParams({ ...searchParams, page: 1 })
      }
    }
    tableRef.current.resetPagination()
  }

  const onFilterChange = (activeFilters) => {
    console.log("activeFilters",activeFilters)
    columns.forEach((col) => {
      if (searchParams[col.searchAs]) {
        delete searchParams[col.searchAs]
      }
    })
    let filters = []
    activeFilters.forEach((filter) => {
      let column = columns.find((col) => col.accessor === filter.id)
      filters[column.searchAs] = filter.value
    })

    tableRef.current.resetPagination()
    setSearchParams({ ...searchParams, ...filters, page: 1 })
    // console.log("searchParams",searchParams)
  }

  const getMenuOptions = (_return) => {
    const needsReview = returnNeedsReview(_return);

    const options = [
      {
        title: !needsReview ?  i18n.t("returns.container.return_details")  : i18n.t("returns.container.review_return") ,
        clickHandler: () =>
          !needsReview ?
              history.push(storePath + SHOW_RETURN.replace(":id", _return.id))
              :
              showReviewExternalReturnContainer(_return),
      },
    ]
    if (_return.returned_order) {
      options.push({
        title: i18n.t("returns.container.see_returned_order"),
        clickHandler: () =>
        history.push(
          storePath + SHOW_ORDER_RETURNS.replace(":id", _return.returned_order.id)
        ),
    })
  } 
    if (_return.created_order) {
      options.push({
        title: i18n.t("returns.container.see_order_created"),
        clickHandler: () =>
          history.push(
            storePath + SHOW_ORDER_RETURNS.replace(":id", _return.created_order.id)
          ),
      })
    }
    return options
  }

  const getTableData = () => {
    return data.returns.map((_return) => {
      return {
        returned_order_number: (
          <div
            className="hover:underline cursor-pointer "
            onClick={() => showReturnDetail(_return)}
          >
             {_return.returned_order?.order_number ? (
              <>
              #{String(_return.returned_order?.order_number)}
              </>)
              :
              (<>
                  <i>{i18n.t("returns.returnContainer.unidentified")}</i>
                </>)
              }
          </div>
        ),
        return_type: <div>{i18n.t(getReturnTypeDefinition(_return.return_type).label)}</div>,
        created_order_number: _return.created_order ? (
          <div
            className="hover:underline cursor-pointer "
            onClick={() => showReturnDetail(_return)}
          >
            #{String(_return.created_order.order_number)}
          </div>
        ) : (
          <div><i>{i18n.t(getReturnNewOrderLabel(_return.return_type, _return.status))}</i></div>
        ),
        status: (
          <>
          <StatusHighlighted
                className=""
                status={_return.status}
                statuses={RETURNS_STATUSES}
            />
            <div className="mt-2 ml-0.5 text-gray-400 text-sm">
                        <div className="inline mr-1">{i18n.t('orders.list.since')}</div>
                        <FormattedDate date={_return.updated_at} shortDate={true}/>
                    </div>
          </>
        ),
        reserved_stock_units_count: (
          <div
            className="font-style: italic "
            
          >
            <div> {
              (_return.return_type === 'SIMPLE_RETURN' || _return.return_type === 'IMMEDIATE_EXCHANGE' || _return.status === 'RECEIVED') ?
              i18n.t("returns.container.not_applicable") :
                (_return.reserved_stock_units_count <= 0) ? 
                i18n.t("returns.container.no_reserved_parts") : 
                  <div className="not-italic">
                    {_return.reserved_stock_units_count}
                  </div>
            } </div>
            
          </div>
        ),
        created_at: (
          <FormattedDate date={_return.created_at} shortDate={true} />
        ),
        actions: (
          <ActionMenu className="float-right" items={getMenuOptions(_return)} />
        ),
      }
    })
  }

  const handleReviewExternalReturnSuccess = function () {
    history.push(storePath + RETURNS);
    refetch();
  }

  return (
    <>
      <PageView
        topMenu={
          <PageTopBar>
            <div className="text-lg font-semibold">
              <span className="text-gray-400">
                {user.current_store
                  ? user.current_store.name
                  : i18n.t("returns.container.all_stores")}{" "}
                &rarr;{" "}
              </span>
              {i18n.t("returns.container.returns")} 
            </div>
            {/* <div>
              {user.current_store && (
                <Button
                  className="whitespace-nowrap ml-3"
                  onClick={() => {
                    dispatch(resetData())
                    history.push(storePath + ADD_ORDER)
                  }}
                >
                  Crear Orden
                </Button>
              )}
            </div> */}
          </PageTopBar>
        }
        childrenFullWidth={true}
        topMenuFullWidth={true}
      >
         <div className="flex flex-col">
            <nav className='flex relative rounded-lg border bg-white py-2 mb-2 text-gray-700'>

                        <div className="flex flex-col lg:flex-row justify-start border-l-2 lg:border-0 ml-5 lg:ml-0">
                          <div className="lg:max-w-min ml-5 my-1 lg:my-2 text-base leading-4 py-2 pl-0">
                          Requieren monitoreo
                          </div>
                          <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                              
                              <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none"
                                  onClick={() => onFilterChange( [{id: 'status', value: 'PENDING'}])}>
                                      <StatusHighlighted status="delayed" statuses={[{
                                          status: "pending",
                                          label: t('statuses.returns_statuses.pending'),
                                          classes: "bg-red-50 text-red-500 border-red-50"
                                      }]}/> 
                                       <span className='ml-4 text-base font-bold'>{dataSummary?.count || 0} </span> <span className='lowercase font-normal text-base'>Pedidos</span>
                                      {/* <span className='ml-4 text-base font-bold'>{dataSummary?.orders_data?.delayed_with_incident_orders || 0}</span> <span className='lowercase font-normal text-base'>{i18n.t("orders.incidents_bar.orders")}</span> */}
                              </button>
                          </div>
                        </div>
            </nav>
          </div>
        {!isPreviousData && isLoading ? (
          <>
            <Loader show={true}></Loader>
          </>
        ) : isError ? (
          <>Error: {error.message}</>
        ) : (
          <BasicTable
            labelToPaginator={i18n.t("returns.container.returns")}
            showHeader
            pagesSize = {searchParams.per_page}
            showLoader={isFetching && isPreviousData}
            columns={columns}
            filterable
            sortable
            showPaginator
            data={getTableData()}
            onFilterChange={onFilterChange}
            onSortChange={onSortChange}
            paginationMeta={data?.meta}
            showCount={true}
            onPaginationChange={(requestedPage) => {
              setSearchParams({ ...searchParams, page: requestedPage })
            }}
            onPageSizeChange= { (pageSize) => {
              setSearchParams({...searchParams, per_page: pageSize, page: 1})
              dispatch(setReturnsListPageSize(pageSize))
              tableRef.current.resetPagination()
            }}
            showDataStatus
            isFetching={isFetching}
            emptyTableText={i18n.t("returns.container.no_returns")} 
            ref={tableRef}
          />
        )}
      </PageView>
      <Switch>
        <Route exact path={STORE_PATH +SHOW_ORDER_RETURNS}>
          <SlidePanel title={i18n.t("returns.container.see_order")}referrer={storePath + RETURNS}>
            <ShowOrderContainer />
          </SlidePanel>
        </Route>
        <Route exact path={STORE_PATH + SHOW_RETURN}>
          <SlidePanel
            title= {i18n.t("returns.container.return_detail")} 
            referrer={storePath + RETURNS}
            ref={ShowReturnSlidePanelRef}
            onClose={() => refetch()}
          >
            <ShowReturnContainer
            //   onCancel={() => ShowReturnSlidePanelRef.current.closePanel()}

            />
          </SlidePanel>
        </Route>
        <Route exact path={STORE_PATH + REVIEW_EXTERNAL_RETURN}>
          <SlidePanel
            title= {i18n.t("returns.container.review_return")} 
            referrer={storePath + RETURNS}
            ref={reviewExternalReturnContainerRef}
            onClose={() => refetch()}
          >
            <ReviewExternalReturnContainer onSuccess={() => handleReviewExternalReturnSuccess()} />
          </SlidePanel>
        </Route>
      </Switch>
    </>
  )
}
