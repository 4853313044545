import { DateRangePicker, DateRangePickerItem } from "@tremor/react"
import { es, enUS, pt } from "date-fns/locale"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

export const DateRangePickerIntl = ({ value, onValueChange = (v) => {}, language = "en", ...otherProps }) => {
    const { t } = useTranslation()

    const datePickerLocale = useMemo(() => {
        if (language === "es") {
            return es
        } else if (language === "pt") {
            return pt
        } else {
            return enUS
        }
    }, [language])

    return (
        <DateRangePicker
            {...otherProps}
            value={value}
            onValueChange={onValueChange}
            locale={datePickerLocale}
            selectPlaceholder={t("date_picker.select_placeholder")}
        >
            <DateRangePickerItem key="l7d" value="l7d" from={date7DaysAgo()}>
                {t("date_picker.relative_ranges.last_7_days")}
            </DateRangePickerItem>
            <DateRangePickerItem key="l30d" value="l30d" from={date30DaysAgo()}>
                {t("date_picker.relative_ranges.last_30_days")}
            </DateRangePickerItem>
            <DateRangePickerItem key="lm" value="lm" from={dateLastMonth()}>
                {t("date_picker.relative_ranges.last_month")}
            </DateRangePickerItem>
            <DateRangePickerItem key="ly" value="ly" from={dateLastYear()}>
                {t("date_picker.relative_ranges.last_year")}
            </DateRangePickerItem>
        </DateRangePicker>
    )
}

export const date7DaysAgo = () => {
    const today = new Date()
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
    return date
}

export const date30DaysAgo = () => {
    const today = new Date()
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30)
    return date
}

export const dateLastMonth = () => {
    const today = new Date()
    const date = new Date(today.getFullYear(), today.getMonth(), 1)
    return date
}

export const dateLastYear = () => {
    const today = new Date()
    const date = new Date(today.getFullYear(), 0, 1)
    return date
}

export const date3YearsAgo = () => {
    const today = new Date()
    const date = new Date(today.getFullYear() - 3, today.getMonth(), today.getDate())
    return date
}
