export const OPERATION_TYPES = [
    {
        type: 'ENTRADA',
        label: 'invoices.operation_types.entry'
    },
    {
        type: 'SAIDA',
        label: 'invoices.operation_types.egress'
    }
]

export const STATUS_TYPES = [
    {
        type: 'APPROVED',
        label: 'invoices.status_types.approved'
    },
    {
        type: 'CANCELLED',
        label: 'invoices.status_types.cancelled'
    }
]

export const ORDER_PURPOSE_TYPES = [
    {
        type: 'SALE_INVOICE',
        label: 'settings.types_invoices.sale'
    },
    {
        type: 'SYMBOLIC_RETURN_INVOICE',
        label: 'settings.types_invoices.symbolic_return'
    },
    {
        type: 'DONATION_INVOICE',
        label: 'settings.types_invoices.donation'
    },
    {
        type: 'REMOVE_STOCK_INVOICE',
        label: 'settings.types_invoices.remove_stock'
    }
]

export const REPLENISHMENT_PURPOSE_TYPES = [
    {
        type: 'REPLENISHMENT_INVOICE',
        label: 'settings.types_invoices.replenishment'
    },
    {
        type: 'REPLENISHMENT_ADJUSTMENT_INVOICE',
        label: 'settings.types_invoices.replenishment_adjustment'
    }
]

export const RETURN_PURPOSE_TYPES = [
    {
        type: 'RETURN_INVOICE',
        label: 'settings.types_invoices.return'
    },
    {
        type: 'REPLENISHMENT_INVOICE',
        label: 'settings.types_invoices.replenishment'
    },
]

export const INVOICES_TYPES = {
    orders: 'ORDERS',
    replenishments: 'REPLENISHMENTS',
    returns: 'RETURNS'
}