import { capitalize } from "../../utils/StringUtils"

export const ShowSelectedFilters = ({ label, selectedArray, isCapitalized = true }) => {
    if (!isCapitalized) {
        selectedArray = selectedArray?.map((element) => {
            return capitalize(element);
        });
    }
    return (
        <>
        {selectedArray && selectedArray?.length !== 0 && 
        <div className="mt-2 text-sm text-gray-600">
            <span>{label}</span>
            <span className="ml-1">
                {selectedArray?.join(', ')}
            </span>
        </div>
        }
        </> 
    )
}