import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";

import StatementOptions from "./StatementOptions";
import StatementContent from "./StatementContent";
import { BusinessRuleStepCard } from "../../components";
import { ALL_AVAILABLE_ACTION_OPTIONS } from "../../utils/businessRuleActionUtils";
import { AVAILABLE_RULE_STATEMENTS_FOR_ADD_SHIPPING_PACKAGE_OPTION, AVAILABLE_RULE_STATEMENTS_FOR_ADD_PRODUCT_OPTION, AVAILABLE_RULE_STATEMENTS_FOR_ADD_INSERT_OPTION, AVAILABLE_RULE_STATEMENTS_FOR_CANCEL_ORDER_OPTION, AVAILABLE_RULE_STATEMENTS_FOR_PAUSE_ORDER_OPTION, AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_ADD_SHIPPING_PACKAGE_OPTION, AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_ADD_PRODUCT_OPTION, AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_ADD_INSERT_OPTION, AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_CANCEL_ORDER_OPTION, AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_PAUSE_ORDER_OPTION } from "../../utils/businessRuleStatementUtils";

const BusinessRuleStatement = ({statementSettings, onUpdateStatementSettings, actionOption, isPreviousStepCompleted, isCurrentStep, channelIntegrationsData, onCompleted}) => {
    const { t } = useTranslation()
    const [localStatementSettings, setLocalStatementSettings] = useState(statementSettings)
    const [areConditionsValid, setAreConditionsValid] = useState(false)

    useEffect(() => {
        setLocalStatementSettings(statementSettings)
    }, [statementSettings])

    const availableOptions = useMemo(() => {
        switch (actionOption) {
            case ALL_AVAILABLE_ACTION_OPTIONS.ADD_SHIPPING_PACKAGE_OPTION:
                return {
                    initialOptions: {...AVAILABLE_RULE_STATEMENTS_FOR_ADD_SHIPPING_PACKAGE_OPTION},
                    additionalOptions: {...AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_ADD_SHIPPING_PACKAGE_OPTION}
                }
            case ALL_AVAILABLE_ACTION_OPTIONS.ADD_PRODUCT_OPTION:
                return {
                    initialOptions: {...AVAILABLE_RULE_STATEMENTS_FOR_ADD_PRODUCT_OPTION},
                    additionalOptions: {...AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_ADD_PRODUCT_OPTION}
                }
            case ALL_AVAILABLE_ACTION_OPTIONS.ADD_INSERT_OPTION:
                return {
                    initialOptions: {...AVAILABLE_RULE_STATEMENTS_FOR_ADD_INSERT_OPTION},
                    additionalOptions: {...AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_ADD_INSERT_OPTION}
                }
            case ALL_AVAILABLE_ACTION_OPTIONS.CANCEL_ORDER_OPTION:
                return {
                    initialOptions: {...AVAILABLE_RULE_STATEMENTS_FOR_CANCEL_ORDER_OPTION},
                    additionalOptions: {...AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_CANCEL_ORDER_OPTION}
                }
            case ALL_AVAILABLE_ACTION_OPTIONS.PAUSE_ORDER_OPTION:
                return {
                    initialOptions: {...AVAILABLE_RULE_STATEMENTS_FOR_PAUSE_ORDER_OPTION},
                    additionalOptions: {...AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_PAUSE_ORDER_OPTION}
                }
            default:
                return null
        }
    },[actionOption])

    const handleOnUpdateStatementOption = (statementOption) => {
        onUpdateStatementSettings({...localStatementSettings, ...statementOption})
    }

    const handleOnUpdateStatement = (statementData) => {
        const updatedSelectedStatement = statementData.conditions.length <= 0 ? null : statementData.statement

        onUpdateStatementSettings({...localStatementSettings, ...statementData, statement: updatedSelectedStatement})
    }

    const handleOnUpdateConditionsAreValid = (areValid) => {
        setAreConditionsValid(areValid)
        onCompleted(areValid)
    }

    return (
        <BusinessRuleStepCard
            title={t("settings.business_rule_creator.business_rule_statement_settings.title")}
            description={t("settings.business_rule_creator.business_rule_statement_settings.description")}
            isCompleted={false}
            isValid={areConditionsValid}
            isEditing={false}
            isCurrentStep={isCurrentStep}
            isPreviousStepCompleted={isPreviousStepCompleted}
            onEdit={() => {}}
        >
            <StatementOptions
                availableOptions={availableOptions?.initialOptions}
                selectedStatementOption={localStatementSettings}
                onSelectStatementOption={handleOnUpdateStatementOption}
            />

            <StatementContent
                availableAdditionalOptions={availableOptions?.additionalOptions}
                selectedStatementOption={localStatementSettings}
                onUpdateSelectedStatementOption={handleOnUpdateStatement}
                onUpdateConditionsAreValid={handleOnUpdateConditionsAreValid}
                areConditionsValid={areConditionsValid}
                channelIntegrationsData={channelIntegrationsData}
            />

        </BusinessRuleStepCard>
    )
}

export default BusinessRuleStatement