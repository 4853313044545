import {
    ActionMenu,
    BasicTable,
    FilterMenu,
    Notification,
    PageView,
    SlidePanel,
    FormattedDate
} from "../../components";
import {useQuery, useQueryClient} from "react-query"
import {useContext, useRef, useState, useMemo} from "react";
import { Loader } from "../../components/Loader";
import {UserContext} from "../../hooks/UserContext";
import { fetchStores, updateStoreAccount } from "../../services/storeServices";
import { updateAccountType } from "../../services/accountService";
import { ORDERS, STORES, STORE_DETAIL, DASHBOARD, STORE_PATH } from "../../navigation/constants";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import { StoreDetailContainer } from "./StoreDetailContainer";
import { setUserPreferences } from "../../services/userService";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import {
    setStoresListPageSize
} from '../../redux/pageSizeSlice';
import {useDispatch, useSelector} from "react-redux";


export const ListStoresContainer = () => {

    const { t, i18n} = useTranslation();
    const history = useHistory()
    const {user, setUser} = useContext(UserContext)
    const pageSize = useSelector((state) => state.pageSize.storesList);
    const dispatch = useDispatch()
    const [showDefaultStoreSetNotif, setShowDefaultStoreSetNotif] = useState(false)

    if(user && !user.permissions?.read_all_stores && user.all_stores_ids?.length<2) {
        history.replace('/')
    }

    const queryClient = useQueryClient()

    // omit store_ids param if user can real all stores
    const [searchParams, setSearchParams] = useState({
        page: 1,
        count_products: true,
        per_page: pageSize,
        ...!user?.permissions?.read_all_stores && {store_ids: user?.all_stores_ids}
    })

    // console.log(user?.permissions)
    const tableRef = useRef()

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
        refetch
    } = useQuery(['stores', searchParams], () => fetchStores(searchParams), { keepPreviousData: true }) 

    const columns = [
        {
            accessor: 'name',
            Header: "stores.store",
            disableFilters: false,
            disableSortBy: false,
            searchAs: 'name'
        },
        {
            accessor: 'product_count',
            Header: "stores.products",
            disableFilters: true,
            disableSortBy: true,
            shrinkToContent: true
        },
        {
            accessor: 'order_count',
            Header: "stores.orders",
            disableFilters: true,
            disableSortBy: true,
            shrinkToContent: true
        },
        {
            accessor: 'account_name',
            Header: "stores.account",
            disableFilters: false,
            disableSortBy: true,
            searchAs: 'account_name'
        },
        {
            accessor: 'created_at',
            Header: "stores.created",
            disableFilters: true,
            disableSortBy: false,
        },
        {
            accessor: 'default_store',
            Header: "stores.favorite",
            disableFilters: true,
            disableSortBy: true,
            shrinkToContent: true
        },
        {
            accessor: 'status_store',
            Header: "stores.status",
            disableFilters: true,
            disableSortBy: true,
            shrinkToContent: true
        },
        {
            accessor: 'actions',
            Header: '',
            fetchingIndicator : true,
            disableFilters: true,
            disableSortBy: true,
            shrinkToContent: true
        },
    ]

    const onSortChange = (orderBy) => {
        if (orderBy.length > 0) {
            setSearchParams({
                ...searchParams,
                order_by: orderBy[0].id, 
                order: orderBy[0].desc ? 'desc' : 'asc',
                page:1
            })
        }
        else {
            if ('order_by' in searchParams) {
                delete searchParams.order_by
                delete searchParams.order
                setSearchParams({...searchParams, page:1})
            }
        }
        tableRef.current.resetPagination()
    }

    const onFilterChange = (activeFilters) => {
        columns.forEach(col => {
            if (searchParams[col.searchAs]) {
                delete searchParams[col.searchAs]
            }
        })
        let filters = []
        activeFilters.forEach(filter => {
            let column = columns.find((col)=>col.accessor === filter.id)
            filters[column.searchAs] = filter.value
        })
        
        tableRef.current.resetPagination()
        setSearchParams({...searchParams, ...filters, page:1})
        // console.log("searchParams",searchParams)
    }

    const setUpdateAccountType = async (account, account_type) => {
        try {
            account_type =  account_type == "PROSPECT_ACCOUNT" ? "CUSTOMER_ACCOUNT" : "PROSPECT_ACCOUNT"
            await updateAccountType(account.id, {account_type: account_type})
            refetch()
        } catch (error) {
            console.log(error)
            window.alert(error)
        }

    }

    const setAsDefaultStore = async (store) => {
        try {
            await setUserPreferences(user.id, {default_store_id: store.id})
            setUser({...user, default_store: store})
            setShowDefaultStoreSetNotif(true)
        } catch (error) {
            console.log(error)
            window.alert(error)
        }

    }

    const getMenuOptions = (store) => {
        let options = [
            {
                title: i18n.t("stores.go_store"),
                clickHandler: () => {
                    onOpenStoreClick({...store})
                }
            },
            {
                title: i18n.t("stores.details"),
                clickHandler: () => {
                    onDetailClick(store.id)
                }
            },
        ]
        if (user?.default_store?.id != store.id) {
            options.push(
                {
                    title: i18n.t("stores.select_as_favorite"),
                    clickHandler: () => {
                        setAsDefaultStore(store)
                    }
                },
            )
        }
        if (user?.permissions?.update_account_type) {
            options.push(
                {
                    title: store.account.account_type === "PROSPECT_ACCOUNT" ? i18n.t("stores.enable_store"):i18n.t("stores.disable_store") ,
                    clickHandler: () => {
                        setUpdateAccountType(store.account, store.account.account_type)
                    }
                },
            )
        }
        return options
    }

    const getTableData = () => {
        return data.stores.map(store => {
            return {
                ...store,
                name: <div className="hover:underline cursor-pointer " onClick={()=>onOpenStoreClick({...store})}>{String(store.name)+(user?.current_store?.id === store.id ? '*' : '')}</div>,
                account_name: store.account.name,
                default_store: <div className="text-center">{user?.default_store?.id === store.id ? `${i18n.t("stores.default_store")}` : '-'}</div>,
                status_store: <div>{store?.account?.account_type  == "CUSTOMER_ACCOUNT" ? <div className="circle bg-green-400 m-auto"> </div> : <div className="circle bg-yellow-400 m-auto"> </div> }</div>,
                created_at: <FormattedDate date={store.created_at} shortDate />,
                actions: (
                    <ActionMenu className="float-right" items={getMenuOptions(store)}/>
                )
            }
        })
    }

    const onDetailClick = (storeId) => {
        history.push(STORE_DETAIL.replace(':id', storeId))
    }

    const onOpenStoreClick = (store) => {
        queryClient.removeQueries('orders')
        queryClient.removeQueries('returns_index')
        queryClient.removeQueries('replenishments')
        queryClient.removeQueries('channels')
        queryClient.removeQueries('products')
        history.push(STORE_PATH.replace(':storeId', store.id) + DASHBOARD)
    }

    return (
        <>
            <Notification show={showDefaultStoreSetNotif} setShow={setShowDefaultStoreSetNotif} type="success" title={i18n.t("stores.save")} description={i18n.t("stores.save_as_favorite")}/>
            <PageView
                topMenu={null}
            >
                {!isPreviousData && isLoading ? (
                    <><Loader show={true}></Loader></>
                ) : isError ? ( 
                    <>Error: {error.message}</>
                ) : (
                    <BasicTable
                        labelToPaginator={i18n.t("stores.stores")}
                        onFilterChange={onFilterChange}
                        onSortChange={onSortChange}
                        columns={columns}
                        showPaginator
                        showHeader
                        pagesSize = {searchParams.per_page}
                        filterable
                        sortable
                        paginationMeta={data?.meta}
                        showCount = {true}
                        onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
                        onPageSizeChange= { (pageSize) => {
                            setSearchParams({...searchParams, per_page: pageSize, page:1})
                            dispatch(setStoresListPageSize(pageSize))
                            tableRef.current.resetPagination()
                        } }
                        showLoader={isFetching && isPreviousData}
                        isFetching = {isFetching}
                        data = {getTableData()}
                        emptyTableText={i18n.t("stores.nothing_to_show")}
                        ref = {tableRef}
                    />
                )}
            </PageView>
            <Switch>
                <Route exact path={STORE_DETAIL}>
                    <SlidePanel title={i18n.t("stores.store_detail")} referrer={STORES}>
                        <StoreDetailContainer onGoToStore={onOpenStoreClick}/>
                    </SlidePanel>
                </Route>
            </Switch>
        </>
    )
}