import { AVAILABLE_RULE_STATEMENTS, DEFAULT_STATEMENT_CONDITION_CONTENT, CONDITION_VALIDATION_TYPES, CUSTOM_STATEMENT_VALIDATIONS, CUSTOM_STATEMENT_QUANTITIES } from "./businessRuleStatementUtils"

const statementTemplateGenerator = (statementOption, customContent={}) => {
    switch (statementOption) {

        case AVAILABLE_RULE_STATEMENTS.HAS_FRAGILE_ITEMS:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.HAS_FRAGILE_ITEMS,
                    isEditable: false,
                    isCompleted: true,
                    templateStatementOption: statementOption,
                    ...customContent
                }
            ]
        
        case AVAILABLE_RULE_STATEMENTS.CHANNEL_TYPE_NAME:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.CHANNEL_TYPE_NAME,
                    templateStatementOption: statementOption,
                    selectedValidationOption: "",
                    selectedQuantityOption: "",
                    ...customContent
                }
            ]

        case AVAILABLE_RULE_STATEMENTS.IS_FIRST_TIME_BUYER:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.IS_FIRST_TIME_BUYER,
                    isEditable: false,
                    isCompleted: true,
                    templateStatementOption: statementOption,
                    ...customContent
                }
            ]

        case AVAILABLE_RULE_STATEMENTS.FRAUD_ORDER_DETECTED:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.IS_POTENTIAL_FRAUD,
                    isEditable: false,
                    isCompleted: true,
                    templateStatementOption: statementOption,
                    ...customContent
                }
            ]

        case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_WITH_MIN_QUANTITY:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.VALIDATE_PRODUCT,
                    selectedValidationOption: CUSTOM_STATEMENT_VALIDATIONS.INCLUDE_PRODUCT,
                    selectedQuantityOption: CUSTOM_STATEMENT_QUANTITIES.MINIMUM,
                    templateStatementOption: statementOption,
                    ...customContent
                }
            ]

        case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.VALIDATE_PRODUCT,
                    selectedValidationOption: CUSTOM_STATEMENT_VALIDATIONS.INCLUDE_PRODUCT,
                    selectedQuantityOption: CUSTOM_STATEMENT_QUANTITIES.MINIMUM_AND_MAXIMUM,
                    templateStatementOption: statementOption,
                    ...customContent
                }
            ]

        case AVAILABLE_RULE_STATEMENTS.NOT_INCLUDING_SPECIFIED_PRODUCT:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.VALIDATE_PRODUCT,
                    selectedValidationOption: CUSTOM_STATEMENT_VALIDATIONS.NOT_INCLUDE_PRODUCT,
                    selectedQuantityOption: CUSTOM_STATEMENT_QUANTITIES.MINIMUM,
                    templateStatementOption: statementOption,
                    ...customContent
                }
            ]

        case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.VALIDATE_PRODUCT,
                    selectedValidationOption: CUSTOM_STATEMENT_VALIDATIONS.INCLUDE_PRODUCT,
                    selectedQuantityOption: CUSTOM_STATEMENT_QUANTITIES.MINIMUM,
                    templateStatementOption: AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_WITH_MIN_QUANTITY,
                    ...customContent
                },
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.VALIDATE_PRODUCT,
                    selectedValidationOption: CUSTOM_STATEMENT_VALIDATIONS.NOT_INCLUDE_PRODUCT,
                    selectedQuantityOption: CUSTOM_STATEMENT_QUANTITIES.MINIMUM,
                    templateStatementOption: AVAILABLE_RULE_STATEMENTS.NOT_INCLUDING_SPECIFIED_PRODUCT,
                    ...customContent
                }
            ]

        case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.VALIDATE_PRODUCT,
                    selectedValidationOption: CUSTOM_STATEMENT_VALIDATIONS.INCLUDE_PRODUCT,
                    selectedQuantityOption: CUSTOM_STATEMENT_QUANTITIES.EXACTLY,
                    exclusive: true,
                    templateStatementOption: statementOption,
                    ...customContent
                }
            ]

        case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_EXACT_QUANTITY:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.VALIDATE_PRODUCT,
                    selectedValidationOption: CUSTOM_STATEMENT_VALIDATIONS.INCLUDE_PRODUCT,
                    selectedQuantityOption: CUSTOM_STATEMENT_QUANTITIES.EXACTLY,
                    templateStatementOption: statementOption,
                    ...customContent
                }
            ]

        case AVAILABLE_RULE_STATEMENTS.FOR_EVERY_PRODUCT:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.FOR_EVERY_PRODUCT,
                    templateStatementOption: statementOption,
                    ...customContent
                }
            ]

        case AVAILABLE_RULE_STATEMENTS.FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.FOR_EVERY_PRODUCT,
                    templateStatementOption: AVAILABLE_RULE_STATEMENTS.FOR_EVERY_PRODUCT,
                    ...customContent
                },
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.VALIDATE_PRODUCT,
                    selectedValidationOption: CUSTOM_STATEMENT_VALIDATIONS.NOT_INCLUDE_PRODUCT,
                    selectedQuantityOption: CUSTOM_STATEMENT_QUANTITIES.MINIMUM,
                    templateStatementOption: AVAILABLE_RULE_STATEMENTS.NOT_INCLUDING_SPECIFIED_PRODUCT,
                    ...customContent
                }
            ]

        case AVAILABLE_RULE_STATEMENTS.ALL_ORDERS:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.VALIDATE_PRODUCT,
                    anyProduct: true,
                    minQuantity: 1,
                    isCompleted: true,
                    templateStatementOption: statementOption,
                    ...customContent
                }
            ]

        case AVAILABLE_RULE_STATEMENTS.CUSTOM_STATEMENT:
            return [
                {
                    ...DEFAULT_STATEMENT_CONDITION_CONTENT,
                    validationType: CONDITION_VALIDATION_TYPES.VALIDATE_PRODUCT,
                    templateStatementOption: statementOption,
                    selectedValidationOption: "",
                    selectedQuantityOption: "",
                    ...customContent
                }
            ]

        default:
            return null
    }
}

export default statementTemplateGenerator