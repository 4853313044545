import {
    Button,
    ConfirmDialog,
    DialogView,
    InputField, Notification,
    SelectInputShippingMethods,
    SelectInput
} from "../../../components";
import { useMemo } from "react"
import {SwitchPanel} from "../../../components/SwitchPanel";
import {TrashIcon, InformationCircleIcon, TagIcon} from "@heroicons/react/outline"
import {FilePdfOutlined} from '@ant-design/icons';
import {FileInputField, fileToBase64} from "../../../components/FileInputField"
import SelectProductDialog from "../../../components/SelectProductDialog/SelectProductDialog";
import {AddressView} from "./AddressView";
import {ProductOrderTable} from "./ProductOrderTable";
import {useDispatch, useSelector} from "react-redux";
import { Collapse, notification } from 'antd';
import {
    addProducts,
    modifyProduct,
    deleteProduct,
    orderBillingAddress,
    orderData, orderProducts,
    orderShippingAddress, resetData, setBillingAddress, setData,
    setOrderData,
    setShippingAddress
} from "../../../redux/createOrderSlice";
import {fetchOrder, saveOrder, updateOrder, saveDocuments, deleteDocuments, fetchOrderShippingDocumentAsFile} from "../../../services/orderServices";
import {setLogger, useQuery, useQueryClient} from "react-query";
import {useHistory, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import Order from "./Order";
import {useContext, useEffect, useState} from "react";
import {fetchCubboShippingMethods, fetchStoreCubboShippingMethods} from "../../../services";
import {Loader} from "../../../components/Loader";
import { message } from 'antd';
import Product from "../../Inventory/AddProduct/Product";
import {UserContext} from "../../../hooks/UserContext";
import { CSVBoxButton } from '@csvbox/react'
import { fetchSearchProducts } from "../../../services/productServices";
import { FlatfileButton } from "@flatfile/react";
import  i18n from "../../../translations/index";
import { useTranslation } from 'react-i18next';
import { NumericInputField } from "../../../components/NumericInputField";
import NumberFormat from 'react-number-format';
import { CurrencyInputField } from "../../../components/CurrencyInputField";
import { buildMessageForOrderError, filterOrderErrorsByFields, OrderErrorBanner } from "../OrderErrorUtils"
import { uniqBy } from "lodash"
import { NoticeBanner } from "../../../components/NoticeBanner"
import { calculateKitPrice } from "../../../components/utils/productUtils";
import { validatePriceAndDiscountPerItem, fixOrderPrices, validatePricePerItem, fixOrderPricesForNonScannable, checkIfAllProductsAreNonScannable } from '../../../components/utils/orderUtils'

const shortid = require('shortid')
const { Panel } = Collapse
const optionsShippingMethod = [
    { id: 'Dhl premium', name: 'Dhl premium' },
    { id: 'Estafeta economy', name: 'Estafeta economy' },
    { id: 'Cdmx local', name: 'Cdmx local' },
]
const currencyOptions = [
        { id: "MXN", name: 'MXN', country: "MX"},
        { id: "COP", name: 'COP', country: "CO"},
        { id: "BRL", name: 'BRL', country: "BR"},
        { id: "USD", name: 'USD', country: "US"}
    ]

const primaryButton = {
    backgroundColor: "#327cfb",
    color: "white",
    border: "1px solid #00c6ff",
    padding: "6px 16px",
    fontSize: "15px",
    borderRadius: "8px",
    ":hover": {
      backgroundColor: "#0D93BA",
      border: "1px solid #0D93BA"
    }
}

function AddressPanel({address}) {
    return <>
        {address.status == null ? i18n.t("orders.addContainer.no_address"): <>
            <div>{address.firstName}</div>
            <div>{address.lastName}</div>
            <div>{address.phone}</div>
            <div>{address.email}</div>
            <div>{address.billing_tax_id}</div>
            <div>{address.billing_state_registration}</div>
            <div>{address.address}</div>
            <div>{address.billing_number}</div>
            <div>{address.billing_neighborhood}</div>
            <div>{address.country.label}</div>
            <div>{address.city}</div>
            <div>{address.province}</div>
            <div>{address.zipCode}</div>
        </>}
    </>;
}

function BillingAddress({billingAddress}) {
    return <>
        {billingAddress.status === "same" ? i18n.t("orders.addContainer.same_address"): <>
            <div>{billingAddress.firstName}</div>
            <div>{billingAddress.lastName}</div>
            <div>{billingAddress.email}</div>
            <div>{billingAddress.billing_tax_id}</div>
            <div>{billingAddress.billing_state_registration}</div>
            <div>{billingAddress.phone}</div>
            <div>{billingAddress.address}</div>
            <div>{billingAddress.billing_number}</div>
            <div>{billingAddress.billing_neighborhood}</div>
            <div>{billingAddress.country.name}</div>
            <div>{billingAddress.city}</div>
            <div>{billingAddress.province}</div>
            <div>{billingAddress.zipCode}</div>
        </>}
    </>;
}


export const AddOrderContainer = ({edit=false, onCancel = () => {}}) => {

    const { t, i18n } = useTranslation();

    AddOrderContainer.propTypes = {
        edit: PropTypes.bool
    }
    const [fileError, setFilesError] = useState(null)
    const [files, setFiles] = useState([])
    const {user} = useContext(UserContext)
    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])
    const queryClient = useQueryClient()
    const history = useHistory()
    const {id} = useParams()

    const [openDialogProducts, setOpenDialogProducts] = useState(false)
    const [openDialogShippingAddress, setOpenDialogShippingAddress] = useState(false)
    const [openDialogBillingAddress, setOpenDialogBillingAddress] = useState(false)

    const shippingAddress = useSelector(orderShippingAddress)
    const billingAddress = useSelector(orderBillingAddress)
    const order = useSelector(orderData)
    const products = useSelector(orderProducts)

    const [confirmLoading, setConfirmLoading] = useState(false)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [loadingDocuments, setLoadingDocuments] = useState(false)

    const [saveDisabled, setSaveDisabled] = useState(true)

    const [deletedProducts, setDeletedProducts] = useState([])

    const [errorShippingMethod, setErrorShippingMethod] = useState(null)

    const [shippingPrice, setShippingPrice] = useState(0);
    const [totalDiscount, setTotalDiscount]  = useState(0);

    const [initialOrderErrors, setInitialOrderErrors] = useState([])
    const [saveOrderErrors, setSaveOrderErrors] = useState([])
    const [unexpectedErrorMessage, setUnexpectedErrorMessage] = useState(null)

    const dispatch = useDispatch()

    const onConfirmAddOrderHandler = async () => {
        setConfirmLoading(true)
        setInitialOrderErrors([])
        setSaveOrderErrors([])
        setUnexpectedErrorMessage(null)
        let order_id = null
        
        try{
            setConfirmLoading(true)
            let shippingDocuments = []
            if (files.length > 0) {
                for (const file of files) {
                    shippingDocuments.push({
                        filename: file.name,
                        base64_data: await fileToBase64(file),
                        content_type: file.type,
                        printing_type: file.printing_type ? file.printing_type : "label",
                        document_type: "file"
                    })
                }
            }
            if(edit===false) {
                let response = await saveOrder(
                    order,
                    shippingAddress,
                    billingAddress,
                    products,
                    user.current_store?.id,
                    shippingDocuments
                )
                order_id = response.id
            } else {
                order_id = id
                await updateOrder(id, order, shippingAddress,billingAddress, Order.getProductsForUpdate(products), deletedProducts, shippingDocuments)
            }
            await queryClient.invalidateQueries('orders')
            history.goBack()

        } catch (e){
            //alert("Error al guardar")
            setConfirmLoading(false)
            setOpenConfirmDialog(false)
            let responseOrderErrors = e.response?.data?.errors
            if (responseOrderErrors && Array.isArray(responseOrderErrors)) {
                setSaveOrderErrors(responseOrderErrors)
                message.error(buildMessageForOrderError(responseOrderErrors[0], t))
            }
            else if (e.response?.data?.error) {
                setUnexpectedErrorMessage(e.response?.data?.error)
                message.error(e.response?.data?.error)
            }
            console.error(e)
        }
    }
    const totalQuantityUnits = useMemo(() => {
        let quantity_units = 0
        products.forEach((product) => {
            quantity_units += parseInt(product.quantity)
        })
        return quantity_units
    }, [products])

    const totalItemsOrderValue = useMemo(() => {
        let orderValue = 0
        products.forEach((product) => {
            // If product does not exists
            if(!product?.product){
                const price_per_item = product?.price_per_item ? product?.price_per_item : 0
                const quantity = product?.quantity ? product?.quantity : 1
                orderValue += price_per_item * quantity
            }
            else if(product.product.is_scannable){

                // if(warehouse?.country === 'BR'){
                //     orderValue += product.product.is_kit ? calculateKitPrice(product.product.kit_childs, product.quantity) : (product.price_per_item * product.quantity)
                // } else {
                    orderValue += (product.price_per_item * product.quantity)
                // }
            }
        })
        let roundedOrderValue = Math.round(orderValue * 100) / 100
        return roundedOrderValue
    }, [products, warehouse?.country])

    const totalPrice = useMemo(() => {
        if(order?.is_cod){
            return parseFloat(order?.total_price)
        }

        let buyer_shipping_price = parseFloat(order.buyer_shipping_price)

        buyer_shipping_price = isNaN(buyer_shipping_price) ? 0 : buyer_shipping_price

        let totalPrice = (buyer_shipping_price + totalItemsOrderValue) - parseFloat(totalDiscount) + parseFloat(order?.extra_fees_price)
        totalPrice = Math.round(totalPrice * 100) / 100
        return totalPrice
    }
    , [totalItemsOrderValue, order, totalDiscount])

    const isValidContent = useMemo(() => {
        if(order?.is_cod){
            const total_price = parseFloat(order?.total_price)

            if(isNaN(total_price) || total_price <= 0) {
                return false
            }

            return true
        }

        else if(order?.shippingMethod?.shipping_type === 'international' && warehouse?.country !== 'BR') {
            return validatePricePerItem(products)
        }

        else if(warehouse?.country === 'BR') {
            const isPriceAndDiscountValid = validatePriceAndDiscountPerItem(products, true)
            const isShippingPriceValid = order?.buyer_shipping_price !== null && order?.buyer_shipping_price !== undefined &&  order?.buyer_shipping_price !== ""

            const areAllProductsNonScannable = checkIfAllProductsAreNonScannable(products)
            const total_price = parseFloat(order?.total_price)
            const discount_price = parseFloat(order?.discount_price)
            const totalItemsPrice = parseFloat(totalItemsOrderValue)

            if(areAllProductsNonScannable){
                return isPriceAndDiscountValid && isShippingPriceValid && !isNaN(total_price) && !isNaN(discount_price)
            }

            const isTotalItemsPriceValid = !isNaN(totalItemsPrice) && totalItemsPrice >= discount_price
            const isTotalPriceValid = !isNaN(total_price) && !isNaN(discount_price)

            return isPriceAndDiscountValid && isShippingPriceValid && isTotalPriceValid && isTotalItemsPriceValid
        }

        return true
    }, [order, products, warehouse, totalItemsOrderValue])

    const handleOnAddProduct = (addedProducts) => {
        addedProducts = addedProducts.filter(product => !product.is_dropshipping)

        if(addedProducts.length === 0) {
            return
        }

        addedProducts = addedProducts.filter((p)=>!products.find((stateProd)=>stateProd.product_id===p.id))
        dispatch(addProducts(addedProducts.map(product => {
            let {sku, ...noSkuProduct} = product
            const isKit = product.hasOwnProperty('isKit') ? product.isKit : product.is_kit
            const productPrice = !product.is_scannable ? "0.0" : (isKit && warehouse?.country === 'BR') ? calculateKitPrice(product.kit_childs, 1) : product.price

            return {
                quantity: product.order_quantity ? parseInt(product.order_quantity) : 1,
                // name: product.name,
                //name: product.parentName ? product.parentName.length > 20 ? `${product.name} (${product.parentName.substring(0, 15)}...)` : `${product.name} (${product.parentName})` : `${product.name}`,
                product_id: product.id,
                name: product.full_name,
                product: noSkuProduct,
                product_codes: product.product_codes,
                hasError: false,
                parent: product.parent,
                isKit: isKit,
                type: Product.getType(product.parent, product.isKit),//"kit|single|variants",//|,
                key: shortid.generate(),
                price_per_item: productPrice,
                discount_per_item: 0,
                is_scannable: product.is_scannable,
                is_dropshipping: product.is_dropshipping,
            }
        })))
        setOpenDialogProducts(false)
    }

    const handleOnAddProductThroughImport = async (addedRows) => {
        

        // addedProducts = addedProducts.filter((p)=>!products.find((stateProd)=>stateProd.product_id===p.id))
        let productsToAdd = []
        let notFoundProducts = []

        let storeProducts = await fetchSearchProducts(user.current_store?.id, addedRows.map(a => a.sku))

        //console.log(storeProducts)

        addedRows.forEach((addedRow) => {
            let found = false
            storeProducts.products.forEach((storeProduct) => { 

                let foundProducts = storeProduct.product_codes.filter(product_code => product_code.sku === addedRow.sku);
                if (foundProducts.length > 0) {
                    storeProduct.order_quantity = addedRow.quantity
                    productsToAdd.push(storeProduct)
                    found = true
                }
            })

            if (!found) notFoundProducts.push(addedRow.sku)
        })

        // productsToAdd = productsToAdd.filter((p)=>!products.find((stateProd)=>stateProd.product_id===p.id))
        //console.log(productsToAdd)
        //console.log(notFoundProducts)
        handleOnAddProduct(productsToAdd)

    }

    const handleSaveShippingAddress = (address) => {
        console.log(address)
        let billing = billingAddress
        dispatch(setShippingAddress({
            ...address,
            status: "edited"
        }))
        if (billingAddress.status == 'same')
            billing = address
        dispatch(setBillingAddress({
            ...billing,
            status: "edited",
            tax_id: address.tax_id,
            tax_regime: address.tax_regime,
            state_registration: address.state_registration
        }))
        setOpenDialogShippingAddress(false)
    }

    const handleSaveBillingAddress = (address) => {
        dispatch(setBillingAddress({
            ...address,
            status: "edited"
        }))
        dispatch(setShippingAddress({
            ...shippingAddress,
            status: "edited",
            tax_id: address.tax_id,
            tax_regime: address.tax_regime,
            state_registration: address.state_registration
        }))
        setOpenDialogBillingAddress(false)
    }

    const canEditProductQuantities = () =>{
        if (order.status != "processing" &&  order.status != "picking" && order.status != "packing") {
            return false
        }
        return true
    }
    const handleUpdateQuantity = (index, id, value) => {
        if(value>=1){
            dispatch(modifyProduct({
                ...products[index],
                quantity: value,
                edited: true,
            }))
        } else {
            if(edit){
                setDeletedProducts([
                    ...deletedProducts,
                    products[index]
                    ])
            }
            if(value<1)
                dispatch(deleteProduct(products[index].key))
        }
    }

    const handleUpdatePrice = (index, value) => {
        dispatch(modifyProduct({
            ...products[index],
            product:{
                ...products[index].product,
                price: value
            },
            price_per_item: value
        }))
    }

    const handleUpdateDiscount = (index, value) => {
        dispatch(modifyProduct({
            ...products[index],
            discount_per_item: value
        }))
    }

    const handleSaveOrder = () => {
        if(Object.keys(order.shippingMethod).length === 0){
            setErrorShippingMethod (i18n.t("orders.addContainer.shipping_method")) 
            return
        }
        setConfirmLoading(false)
        setOpenConfirmDialog(true)
    }

    const {
        isLoading,
        isError,
        data,
        error,
        isFetchedAfterMount,
    } = useQuery(`order-${id}`, ()=>fetchOrder(id), {
        enabled: edit,
        onSuccess: (info) => {
            if(isFetchedAfterMount===false && edit){
                setEditOrderData(info)
            }
        }
    })

    const cubboShippingMethods = useQuery(['cubbo_shipping', user.current_store?.id],() => fetchStoreCubboShippingMethods(user.current_store?.id))

    useEffect(() => {

        if (products.length > 0 &&
            shippingAddress.firstName && shippingAddress.firstName.trim() !== '' &&
            order.orderNumber && order.orderNumber.trim() !== ''
        ) {
            
            setSaveDisabled(false)
            if ((order.shippingMethod.carrier_name == i18n.t("orders.addContainer.external") || order.shippingMethod.carrier_name == i18n.t("orders.addContainer.shipping_market")) && files.length == 0) {
                setSaveDisabled(true)
            }
            if(warehouse.country === 'BR' && !order.buyer_shipping_price || order.buyer_shipping_price === "") {
                setSaveDisabled(true)
            }
            
            if(warehouse.country === 'BR') {
                products.forEach(item => {
                    if(item.price_per_item === "" || item.price_per_item === null || item.price_per_item === undefined ) {
                        setSaveDisabled(true)
                    } else {
                        setSaveDisabled(false)
                    }
                })
            }
            
        }    
        else {
            setSaveDisabled(true)
        }


    }, [products, shippingAddress, order,files])


    useEffect(() => {
        if(products && products.length > 0){
            const calculatedTotalDiscount = products.reduce((acc, {discount_per_item, quantity}) => {
                const itemDiscount = parseFloat(discount_per_item)
                const itemQuantity = parseFloat(quantity)
    
                return (isNaN(itemDiscount) || isNaN(itemQuantity)) ? acc : acc + (itemDiscount * itemQuantity)
            }, 0.0).toFixed(2)

            setTotalDiscount(calculatedTotalDiscount)
        }
    },[products])

    useEffect(() => {
        const newTotalDiscount = parseFloat(totalDiscount)

        if(!isNaN(newTotalDiscount) && totalDiscount !== order.discount_price) {
            dispatch(setOrderData({
                ...order,
                discount_price: totalDiscount
            }))
        }
    },[dispatch, order, totalDiscount])

    useEffect(() => {
        if(!order?.is_cod) {
            const newTotalPrice = parseFloat(totalPrice)

            if(!isNaN(newTotalPrice) && totalPrice !== order.total_price) {
                dispatch(setOrderData({
                    ...order,
                    total_price: totalPrice
                }))
            }
        }
    },[dispatch, order, totalPrice])

    const setEditOrderData = (info) => {
        let orderLineErrors = filterOrderErrorsByFields(info?.errors, ['order_lines'])

        let products = info.order_lines.map(item => {
            let itemErrorObject = orderLineErrors.find(errorObject => errorObject.additional_info?.order_line_id === item.id)
            return {
                product_id: item.product?.id,
                id: item.id,
                name: item.name===null? item.product?.name: item.name,
                quantity: item.quantity,
                edited: false,
                product_codes: item.product ? item.product.product_codes : [],
                hasError: itemErrorObject ? true : false,
                error: buildMessageForOrderError(itemErrorObject, t, true),
                key: item.id,
                product: item.product,
                parent: item.product?.parent,
                isKit: item.product?.is_kit,
                type: Product.getType(item.product?.parent, item.product?.is_kit),
                generated: item.generated,
                price_per_item: item.price_per_item || item.product?.price,
                discount_per_item: item.discount_per_item
            }
        })
        products = products.filter(orderLine => !orderLine.generated)  

        // Fix prices
        if(warehouse.country !== 'BR'){
            products = fixOrderPricesForNonScannable(products) // Fix not scannable products only
        } else{
            products = fixOrderPrices(products) // Fix kits and not scannable products
        }

        let currency =  currencyOptions.find(option => option.name === info.currency) ||  currencyOptions.find(option => option.country === warehouse.country) 
        dispatch(setData({
            order: {
                id: info.id,
                orderNumber: info.order_number,
                status: info.status,
                shippingMethod: info.shipping_method == null ? {} : info.shipping_method,
                shipping_documents: info.shipping_documents || [],
                is_cod: info.is_cod || false,
                currency: currency.id,
                total_price: info.total_price,
                order_type: info.order_type,
                buyer_shipping_price: info.buyer_shipping_price,
                discount_price: info.discount_price,
                extra_fees_price: info.extra_fees_price,
            },
            shippingAddress: {
                status: "editing",
                firstName: info.shipping.first_name || "",
                lastName: info.shipping.last_name || "",
                phone: info.shipping.phone || "",
                email: info.shipping.email || "",
                tax_id: info.billing.tax_id || "",
                company: info.billing.company || "",
                tax_regime: info.billing.tax_regime || "",
                state_registration: info.billing.state_registration || "",
                address: info.shipping.address_1 || "",
                address2: info.shipping.address_2 || "",
                number: info.shipping.number ||  "",
                neighborhood: info.shipping.neighborhood ||  "",
                country: info.shipping.country || "",
                city: info.shipping.city || "",
                province: info.shipping.state || "",
                zipCode: info.shipping.zip_code || "",
            },
            billingAddress: {
                status: "editing",
                firstName: info.billing.first_name || "",
                lastName: info.billing.last_name || "",
                email: info.shipping.email || "",
                phone: info.billing.phone || "",
                tax_id: info.billing.tax_id || "",
                company: info.billing.company || "",
                tax_regime: info.billing.tax_regime || "" ,
                state_registration: info.billing.state_registration || "",
                address: info.billing.address_1 || "",
                number: info.billing.number ||  "",
                neighborhood: info.billing.neighborhood ||  "",
                country: info.shipping.country || "",
                city: info.billing.city || "",
                province: info.billing.state || "",
                zipCode: info.billing.zip_code || "",
            },
            products: products
        }))

        fetchShippingDocuments(info)

        setSaveDisabled(false)
        setInitialOrderErrors(info.errors)
    }

    const fetchShippingDocuments = async (orderData) => {
        setLoadingDocuments(true)
        let shippingDocumentFiles = []
        if (orderData.shipping_documents && orderData.shipping_documents.length > 0) {
            for (const shippingDocument of orderData.shipping_documents) {
                let file = await fetchOrderShippingDocumentAsFile(shippingDocument.file.path, shippingDocument.file.filename, shippingDocument.file.content_type)
                shippingDocumentFiles.push(file)
            }
            setFiles([...shippingDocumentFiles])
        }
        setLoadingDocuments(false)
    }
    
    const topPageOrderErrors = useMemo( () => {
        if (!initialOrderErrors) return []
        // filter unique error codes only
        let uniqueErrors = uniqBy(initialOrderErrors, 'code')
        return uniqueErrors
    }, [initialOrderErrors])

    const bottomPageOrderErrors = useMemo( () => {
        if (!saveOrderErrors) return []
        // filter unique error codes only
        let uniqueErrors = uniqBy(saveOrderErrors, 'code')
        return uniqueErrors
    }, [saveOrderErrors])

    const deliveryType = useMemo(() => {
        if (!order.shippingMethod) return
        return order.shippingMethod.shipping_type

    }, [order])

    if(isLoading && edit){
        return <div className="mt-4"><Loader show={true} />Loading</div>
    }
    if(isError && edit){
        return <div className="mt-4">{error.message}</div>
    }

    const filesChangeHandler = (pdfs) => {
        if (pdfs.length > 0) {
            setFilesError(null)
            setFiles([...files, ...pdfs])
        }
     //console.log("files:", files)
    }

    const removeFiles = (id) => {
        setFiles(files.filter((document) => (document.id != id && document.document_id != id)))
    }

    return (
        <div className="divide-y divide-gray-200">


            <SelectProductDialog
                open={openDialogProducts}
                setOpen={setOpenDialogProducts}
                onAddProducts={handleOnAddProduct}
                queryExtraParams={{is_dropshipping: false}}
            />


            <ConfirmDialog
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                title= { edit == true ? i18n.t("orders.addContainer.edit_order") : i18n.t("orders.addContainer.create_order")}
                description={edit == true ? i18n.t("orders.addContainer.keep_order") : i18n.t("orders.addContainer.create_orders")}
                confirmLabel="Si"
                onConfirm={onConfirmAddOrderHandler}
                loading={confirmLoading}
            />

            <DialogView
                setOpen={setOpenDialogShippingAddress}
                open={openDialogShippingAddress}
            >
                <AddressView
                    title= {i18n.t("orders.addContainer.shipping_address")}
                    initialData={shippingAddress}
                    onSave={handleSaveShippingAddress}
                    onCancel={() => setOpenDialogShippingAddress(false)}
                />
            </DialogView>


            <DialogView setOpen={setOpenDialogBillingAddress} open={openDialogBillingAddress}>
                <AddressView
                    title= {i18n.t("orders.addContainer.edit_invoicing")}
                    initialData={billingAddress.status === "same" ? shippingAddress : billingAddress}
                    onSave={handleSaveBillingAddress}
                    onCancel={() => setOpenDialogBillingAddress(false)}
                />
            </DialogView>

            <div className="">
                <div>
                    { topPageOrderErrors.map( error => <OrderErrorBanner key={error.code} errorObject={error} /> ) }
                </div>
                <div className="flex pt-2 items-stretch">
                    <div className="flex-grow">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">{i18n.t("orders.addContainer.details")}</h3>
                        <div className="mt-3 grid grid-cols-2 bg-gray-100  shadow rounded-lg p-5">

                            {!cubboShippingMethods.isPreviousData && cubboShippingMethods.isLoading ? (
                                
                                <><Loader show={true}></Loader></>
                            ) : cubboShippingMethods.isError ? (
                                <>Error: {cubboShippingMethods.error.message}</>
                            ) : (

                                <div>                                    
                                    <SelectInputShippingMethods
                                        options={cubboShippingMethods.data}
                                        selectedInitial={order.shippingMethod}
                                        // selectedInitial={order.shippingMethod ? order.shippingMethod : {id: null, shipping_name:'none'}}
                                        className="m-2"
                                        label= {i18n.t("orders.addContainer.method_of_shipment")} 
                                        error={errorShippingMethod}
                                        onChange={(value) => {
                                            setErrorShippingMethod(null)
                                            dispatch(setOrderData({
                                                ...order,
                                                shippingMethod: value
                                            }))
                                        }}
                                        required
                                    />

                                    <p className="ml-3 text-sm text-red-600">{(Object.keys(order.shippingMethod).length === 0) && i18n.t("orders.addresView.required_field")}</p>
                                </div>
                            )}

                            

                            <div className="m-2">
                                {edit === false ?
                                    <div className="sm:col-span-6">
                                        <InputField
                                            title= {i18n.t("orders.addContainer.order")} 
                                            value={order.orderNumber}
                                            onChange={(value) => {                                    
                                            let re = /^[^\W_]+[_-]?([^\W_][_-]?)*$/;
                                            if (value === '' || re.test(value)) {
                                                dispatch(setOrderData({
                                                    ...order,
                                                    orderNumber: value
                                                }))
                                            }}
                                            } 
                                            
                                            required
                                        />

                                        <p className="mt-2 ml-1 text-sm text-red-600">{order.orderNumber === '' && i18n.t("orders.addresView.required_field")}</p>

                                    </div>
                                :   <div>
                                        <label htmlFor="country"
                                            className="block text-sm font-medium text-gray-700 flex justify-between">
                                            <div>{i18n.t("orders.addContainer.order")}</div>
                                        </label>
                                        <div className="mt-1 text-gray-400">
                                            {order.orderNumber}
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="m-2 mt-2">
                                <label htmlFor="country"
                                       className="block text-sm font-medium text-gray-700 flex justify-between">
                                    <div>{i18n.t("orders.addContainer.customer_address")}<span className="text-red-500"> *</span></div>
                                    <a className="cursor-pointer"
                                       onClick={() => setOpenDialogShippingAddress(true)}>{i18n.t("orders.addContainer.edit")}</a>
                                </label>
                                <div className="mt-1 text-gray-400">
                                    <AddressPanel address={shippingAddress}/>
                                </div>
                            </div>

                            <div className="m-2 mt-2">
                                <label htmlFor="country"
                                       className="block text-sm font-medium text-gray-700 flex justify-between">
                            
                                    {(warehouse.country ==='BR') ?
                                        <div>{i18n.t("orders.addContainer.billing_address")}<span className="text-red-500"> *</span></div>
                                    :
                                        <div>{i18n.t("orders.addContainer.billing_address")}</div>
                                    }

                                    <a className="cursor-pointer"
                                       onClick={() => setOpenDialogBillingAddress(true)}>{i18n.t("orders.addContainer.edit")}</a>
                                </label>
                                <div className="mt-1 text-gray-400">
                                    <BillingAddress billingAddress={billingAddress}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="pt-4 mt-8">
                    <div className="pb-3 sm:flex sm:items-center sm:justify-between">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">{i18n.t("orders.addContainer.products")}<span className="text-red-500"> *</span></h3>
                        <div className="mt-3 sm:mt-0 sm:ml-4">
                           
                            {!canEditProductQuantities() ? (

                                <>
                                
                                <FlatfileButton
                                    licenseKey="8096b433-6f21-43de-ab84-c07472944581"
                                    customer={{ userId: "12345" }}
                                    settings={{
                                    type: "Import Order Lines",
                                    fields: [
                                        { label: "SKU", key: "sku", validators: [
                                            { validate: "required" },
                                            { validate: "unique" }
                                        ] },
                                        { label: i18n.t("orders.addContainer.quantity"), key: "quantity", validators: [
                                            {
                                            validate: "regex_matches",
                                            regex: "^[0-9]+$",
                                            error:
                                               i18n.t("orders.addContainer.only_numbers")
                                            }
                                        ] }
                                    ],
                                    theme: {
                                        buttons: {
                                            primary: primaryButton
                                        }
                                        // other keys below
                                    },
                                    managed: true,
                                    autoDetectHeaders: true,
                                    title: i18n.t("orders.addContainer.imports_products"),
                                    devMode: false
                                    }}
                                    onData={async (results) => {
                                    // do something with the results
                                    // use data instead of alldata for valid data
                                        await handleOnAddProductThroughImport(results.allData)
                                    return i18n.t("orders.addContainer.imported");
                                    }}
                                    render={(importer, launch) => {
                                        return <button className="bg-white mr-5 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={launch}>{i18n.t("orders.addContainer.import")}</button>;
                                    }}
                                >
                                Import Contacts
                                </FlatfileButton>
                                <button
                                    type="button"
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => setOpenDialogProducts(true)}
                                >
                                    {i18n.t("orders.addContainer.add_product")}
                                </button>
                                </>
                            ):(
                                <div className="text-gray-600 pt-2">
                                <i>{i18n.t("orders.addContainer.is_being")}<b> {i18n.t("orders.addContainer.processing")} </b>{i18n.t("orders.addContainer.cannot_be_modified")}</i>
                                </div>
                            )

                            }
                        </div>
                    </div>
                    
                    <p className="text-sm text-gray-500">{order.status != "processing" &&  order.status != "picking" && order.status != "packing" && i18n.t("orders.addContainer.product_select")}</p>
                    <ProductOrderTable products={products} updateData={handleUpdateQuantity} updatePrice={handleUpdatePrice} disabled={canEditProductQuantities()} shipping_type={deliveryType} updateDiscount={handleUpdateDiscount}/>

                    {(warehouse.country ==='BR') &&
                        <div className='mt-7'>
                            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">{i18n.t("orders.addContainer.prices")}<span className="text-red-500"> *</span></h3>
                            <p className="text-sm text-gray-500">{order.status != "processing" &&  order.status != "picking" && order.status != "packing" && i18n.t("orders.addContainer.mandatory_field")}</p>
                           
                            <div className="sm:flex mt-2 sm:items-center sm:justify-between mb-2">
                                <p className='ml-2 text-base font-medium text-gray-500 mt-2 inline-block align-middle'> {i18n.t("orders.addContainer.total_per_item")}</p>                                
                                <p className='font-medium mt-2 w-32 align-middle'><NumberFormat value={totalItemsOrderValue} displayType={'text'} thousandSeparator={true} prefix={data?.currency === "USD" ? "$" : "R$"} decimalScale={2} fixedDecimalScale={2}/></p> 
                                
                            </div>
{/* 
                            <div className="p-2 sm:flex sm:items-center sm:justify-between  bg-gray-100 shadow rounded-lg">
                                <p className='text-base font-medium text-gray-400 pt-3 font-bold'> {i18n.t("orders.addContainer.discount_price")} <span className="text-red-500"> *</span></p> 
                                <div>
                                    <CurrencyInputField 
                                        icon= {<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>}
                                        className="w-32"
                                        min={0} 
                                        value={order.discount_price}
                                        onChange={
                                            (value) => {
                                                dispatch(setOrderData({
                                                    ...order,
                                                    discount_price: value
                                                }))
                                            }
                                        } 
                                        required
                                        keyboard={true}
                                    />
                                </div>                                
                            </div>
 */}

                            <div className="p-2 mt-2 sm:flex sm:items-center sm:justify-between  bg-gray-50 shadow rounded-lg">
                                <p className='text-base font-medium text-gray-500'> {i18n.t("orders.pricesContainer.extra_fees_price")}</p> 
                                <div>
                                    <CurrencyInputField 
                                        icon= {<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>}
                                        className="w-32"
                                        min={0} 
                                        value={order.extra_fees_price}
                                        onChange={(v) => {dispatch(setOrderData({ ...order, extra_fees_price: v }))}} 
                                        required
                                        keyboard={true}
                                    />
                                </div>                                
                            </div>
                            
                            <div className="p-2 mt-2 sm:flex sm:items-center sm:justify-between  bg-gray-50 shadow rounded-lg">
                                <p className='text-base font-medium text-gray-500'> {i18n.t("orders.addContainer.shipping_price")} <span className="text-red-500"> *</span></p> 
                                <div>
                                    <CurrencyInputField 
                                        icon= {<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>}
                                        className="w-32"
                                        min={0} 
                                        value={order.buyer_shipping_price}
                                        onChange={
                                            (value) => {
                                                dispatch(setOrderData({
                                                    ...order,
                                                    buyer_shipping_price: value
                                                }))
                                            }
                                        } 
                                        required
                                        keyboard={true}
                                    />
                                </div>                                
                            </div>

                            <div className="p-2 mt-2 sm:flex sm:items-center sm:justify-between  bg-gray-50 shadow rounded-lg">
                                <p className='text-base text-gray-500 font-medium'>{i18n.t("orders.addContainer.discount_price")}</p>
                                <div>
                                    <CurrencyInputField
                                        icon= {<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>}
                                        className="w-32"
                                        min={0}
                                        value={totalDiscount}
                                        required
                                        isDisabled={true}
                                        inputClassName="shadow-none border-0 bg-transparent"
                                    />
                                </div>
                            </div>

                            <div className="sm:flex sm:items-center sm:justify-between">
                                <p className='ml-2 text-base font-medium text-gray-500 mt-3 inline-block align-middle font-bold'> {i18n.t("orders.addContainer.total_price")}</p>                                
                                <p className='font-medium mt-3 w-32 align-middle'><NumberFormat value={totalPrice} displayType={'text'} thousandSeparator={true} prefix={data?.currency === "USD" ? "$" : "R$"} decimalScale={2} fixedDecimalScale={2}/></p>
                                
                            </div>

                        </div>
                        
                    }

                </div>
                
                {order && (
                    <>
                        <div className="pt-4">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">{i18n.t("orders.addContainer.attachments")}</h3>
                                { (order.shippingMethod.carrier_name == "Externo" || order.shippingMethod.carrier_name == i18n.t("orders.addContainer.shipping_market")) &&
                                    <div className="text-gray-600 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-yellow-100 text-yellow-800">
                                        <InformationCircleIcon className="h-5 w-5" /> <i> {i18n.t("orders.addContainer.attach_document")}</i>
                                    </div>
                                }
                            </div>

                            <div className="m-2 relative">
                                {loadingDocuments && <Loader show={true}/> }
                                <FileInputField
                                label= {i18n.t("orders.addContainer.shipping_documents")} 
                                onChange={(files) => filesChangeHandler(files)}
                                onError={(error, file, message) => setFilesError(message)}
                                />
                                {fileError && (
                                    <div className="text-red-500 text-center italic mt-2">
                                        {fileError}
                                    </div>
                                )}
                                <div className="flex flex-wrap justify-center flex-col">
                                    {files.map((file,x) => (
                                        <div className="mt-3 grid grid-cols-4 border-b border-gray-200" key={String(file.id || file.document_id)}>
                                            
                                            <p className="col-span-2"><FilePdfOutlined className="text-center text-2xl w-8"/>{file.name || file.filename}</p>
                                            <div>
                                            <select
                                                value= {file.printing_type}
                                                className="block w-full bg-white py-2 border border-gray-300 rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm mb-3"
                                                onChange={e => {
                                                    file.printing_type = e.target.value
                                                }}
                                                disabled={file.path}
                                            >
                                                
                                                <option value="label">{i18n.t("orders.addContainer.Label")}</option>
                                                <option value="document">{i18n.t("orders.addContainer.document")}</option>
                                                
                                            </select>
                                            
                                            </div>
                                            <div  onClick={() => removeFiles(file.id || file.document_id)}
                                                className="bg-white color-red cursor-pointer justify-self-center">
                                                <TrashIcon className="h-5 w-5 text-red-500" />
                                            </div>
                                        

                                        </div>
                                        
                                    ))}
                                </div>
                            </div>
                        </div>
                        {(warehouse.country === 'CO' || warehouse.country === 'MX') &&
                            <div className="mt-8 mb-4">
                                <div className="">
                                    <Collapse 
                                        bordered={false} 
                                        ghost
                                        activeKey={!order.is_cod ? '' : 'cod_panel'}
                                    >
                                        <Panel key='cod_panel' className="" showArrow={false} header=
                                            {
                                                <div className="sm:col-span-2" style={{margin: '-12px'}}>
                                                    <SwitchPanel
                                                        title= {i18n.t("orders.addContainer.cod")}
                                                        description= {i18n.t("orders.addContainer.paqs_cod")}
                                                        value={order.is_cod}
                                                        onChange={(value) => {
                                                            dispatch(setOrderData({
                                                                ...order,
                                                                is_cod: value
                                                            }))
                                                        }}
                                                        disabled={false}
                                                        clickableLabel={true}
                                                    />
                                                </div>
                                            }>
                                            <div className="relative" style={{margin: '-16px'}}>
                                                <div className="mt-3 grid grid-cols-2 bg-gray-100  shadow rounded-lg p-5 pt-2">
                                                    <div className="m-2">
                                                        <NumericInputField
                                                            className="sm:col-span-6"
                                                            title= {i18n.t("orders.addContainer.total_price_cod")}
                                                            value={order.total_price}
                                                            onChange={(value) => {
                                                                dispatch(setOrderData({
                                                                    ...order,
                                                                    total_price: value
                                                                }))
                                                            }
                                                            } 
                                                            
                                                            required
                                                        />
                                                    </div>
                                                    <div className="m-2">
                                                        
                                                        <SelectInput
                                                            options={currencyOptions}
                                                            selectedInitial={currencyOptions.find(option => option.country === warehouse.country)}
                                                            className="sm:col-span-3"
                                                            label= {i18n.t("orders.addContainer.coin")}
                                                            value={currencyOptions.find(option => option.id === order.currency) || currencyOptions.find(option => option.country === warehouse.country)}
                                                            onChange={(value) => {
                                                                dispatch(setOrderData({
                                                                    ...order,
                                                                    currency: value.id
                                                                }))
                                                            }}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </div>

                            </div>
                        }  
                        {(warehouse.country ==='BR') &&
                            <div className="mt-8 mb-8">
                                <div className="">
                                    <div className="sm:col-span-2" style={{margin: '-12px'}}>
                                        <SwitchPanel
                                            title={<div className='ml-5'> {i18n.t("orders.addContainer.change_donation")} </div>}
                                            description= {<div className='ml-5'> {i18n.t("orders.addContainer.donation_description")} </div>}
                                            value={order.order_type == 'SALE' ? false : true}
                                            onChange={(value) => {
                                                let type = value ? 'DONATION' : 'SALE'
                                                dispatch(setOrderData({
                                                    ...order,
                                                    order_type: type
                                                }))
                                            }}
                                            disabled={false}
                                            clickableLabel={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        }    
                    </>
                )}
            </div>

            { (bottomPageOrderErrors.length > 0 || unexpectedErrorMessage) &&
                <div className="mt-5 pt-2">
                    { bottomPageOrderErrors.map( error => <OrderErrorBanner key={error.code} errorObject={error} /> ) }
                    { unexpectedErrorMessage && <NoticeBanner text={unexpectedErrorMessage} className="mb-2" /> }
                </div>
            }

            <div className="pt-5 pb-5">
                <div className="flex justify-end">
                    <Button type="secondary" className="w-full" onClick={() => {
                        onCancel()
                    }}>{i18n.t("orders.addContainer.cancel")}</Button>
                    <Button className="ml-3 w-full" onClick={handleSaveOrder} disabled={saveDisabled || !isValidContent}>{i18n.t("orders.addContainer.save")}</Button>
                </div>
            </div>
        </div>
    )
}
