import { useTranslation } from 'react-i18next';
import {UnpaidOrderCancellationCard} from "./UnpaidOrderCancellationCard";
import {CarrierReturnsCancellationCard} from "./CarrierReturnsCancellationCard";

export const AutomationSettingsContainer = function () {
    const { i18n } = useTranslation();

    return (
        <div className='relative p-10'>
              <div className="space-y-1">
                  <p className='text-3xl mb-0 font-bold text-blue-gray-900 '>{i18n.t("settings.automation_settings.title")}</p>
                  <p className = "mt-5 text-lg text-gray-500">{i18n.t("settings.automation_settings.sub_title")}</p>

                <UnpaidOrderCancellationCard/>

                <CarrierReturnsCancellationCard/>
            </div>
        </div>
    )
}