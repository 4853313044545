import { useQuery } from "react-query";
import { fetchOrder } from "../../services";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import {DialogView,BasicTable, Button, ButtonLoader, InputField} from "../../components";

import { Loader } from '../../components/Loader';
import {EDIT_ORDER,SHIPPING_METHODS, STORE_PATH, ORDERS} from "../../navigation/constants";
import {useDispatch} from "react-redux";
import { Progress } from 'antd';
import {useState, useContext, useMemo} from "react";
import { fetchOrderCreateBulkTracker } from "../../services/orderServices";
import { useTranslation } from "react-i18next";
import NumberFormat from 'react-number-format';
import {UserContext} from "../../hooks/UserContext";
import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline"
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
  import 'react-circular-progressbar/dist/styles.css';



export const ShowCreateOrderBulkTracker = () => {

    const { t, i18n} = useTranslation();
    let { id } = useParams()
    const { url:storePath } = useRouteMatch({ path: STORE_PATH })
    const history = useHistory()
    const dispatch = useDispatch()
    const {user} = useContext(UserContext)
    const [openDialogShippingMatch, setOpenDialogShippingMatch] = useState(false)
    const [loadingLabel, setLoadingLabel] = useState(false)
    const [trackingNumber, setTrackingNumber] = useState(null)

    const {
        isLoading,
        isFetching,
        isPreviousData,
        isError,
        error,
        data,
        refetch
    } = useQuery(['order-create-bulk-tracker', id], ()=>fetchOrderCreateBulkTracker(id),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: 500
    })

    const handleSaveShippingMatch = (data) => {
        setOpenDialogShippingMatch(false)
        setTimeout(() => {
            refetch()
        }, 1000);
        
    }

    const _isBrStore = user.current_store?.warehouses[0].country === "BR"

    
    return (
        <>
            {(!isPreviousData && isLoading) ? (
                <><Loader show={true}></Loader></>
            ) :
            isError ? (
                <>Error: {error.message}</>
            ) : (
                    <>
                    <div className="relative bg-white py-16 sm:py-24 lg:py-10 h-full">
                        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                            {
                                data.is_complete ? 
                                <>
                                    {
                                    data.failures > 0 ? 
                                    
                                    <>
                                        {
                                            data.failures === data.total ? 
                                            <>
                                                 <XCircleIcon
                                                className="mx-auto h-10 w-10 text-red-500"
                                                aria-hidden="true"
                                                />
                                                <h3 className="mt-5 text-2xl leading-6 font-medium text-gray-900">{i18n.t("orders.create_bulk.was_a_problem")} </h3>
                                                
                                                <div className="block mt-5">
                                                    <span className="block">{i18n.t("orders.create_bulk.zero_orders_imported")}</span>

                                                    <Button className="mt-10" onClick={()=>{
                                                        history.push(storePath + ORDERS)
                                                    }}>{i18n.t("orders.create_bulk.ok")}</Button>
                                                </div>
                                            </> 
                                            : 
                                            <>
                                                <ExclamationCircleIcon
                                                className="mx-auto h-10 w-10 text-gray-500"
                                                aria-hidden="true"
                                                />
                                                <h3 className="mt-5 text-2xl leading-6 font-medium text-gray-900"> {i18n.t('orders.create_bulk.not_all_orders_imported', {imported_orders_number: data.total-data.failures, total_orders: data.total})} </h3>
                                                
                                                <div className="block mt-5">
                                                    <span className="block">{i18n.t("orders.create_bulk.zero_orders_imported")}</span>

                                                    <Button className="mt-10" onClick={()=>{
                                                        history.push(storePath + ORDERS)
                                                    }}>{i18n.t("orders.create_bulk.ok")}</Button>
                                                </div>
                                            </>
                                        }
                                    
                                    </> 
                                    
                                    : 
                                    
                                    <>
                                        <CheckCircleIcon
                                            className="mx-auto h-10 w-10 text-indigo-600"
                                            aria-hidden="true"
                                        />
                                        <h3 className="mt-5 text-2xl leading-6 font-medium text-gray-900">{i18n.t('orders.create_bulk.orders_imported', {orders_number: data.total})} </h3>
                                        
                                        <div className="block mt-5">
                                            <span className="block">{i18n.t("orders.create_bulk.thanks_for_waiting")}</span>

                                            <Button className="mt-10" onClick={()=>{
                                                history.push(storePath + ORDERS)
                                            }}>{i18n.t("orders.create_bulk.view_orders")}</Button>
                                        </div>
                                    </>
                                    
                                    }
                                    
                                        
                                
                                </> 
                                : 
                                <>
                                    <h3 className="text-2xl leading-6 font-medium text-gray-900">{i18n.t('orders.create_bulk.we_are_importing', {orders_number: data.total})}</h3>
                                    <span className="block italic text-indigo-600">{i18n.t("orders.create_bulk.error_orders_reminder")}</span>
                                    <div className="mt-10 mx-auto" style={{ width: 225, height: 225 }}>
                                        <CircularProgressbarWithChildren value={data.total-data.pending} maxValue={data.total} strokeWidth={5} styles={buildStyles({
                                            // Rotation of path and trail, in number of turns (0-1)
        

                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                            strokeLinecap: 'round',

                                            // Text size
                                            textSize: '16px',

                                            // How long animation takes to go from one percentage to another, in seconds
                                            pathTransitionDuration: 0.5,

                                            // Can specify path transition in more detail, or remove it entirely
                                            // pathTransition: 'none',

                                            // Colors
                                            pathColor: '#337dfb',
                                            textColor: '##337dfb',
                                            trailColor: '#eaeaeb',
                                            backgroundColor: '#337dfb',
                                        })}>

                                            <div>
                                            <div className="text-6xl font-bold text-indigo-600">{data.total-data.pending}</div>
                                            <div className="mt-2 font-semibold text-gray-400">{i18n.t("orders.create_bulk.orders")}<br></br> {i18n.t("orders.create_bulk.imported")}</div>
                                            </div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                
                                </>
                            }
                        </div>
                    </div>
                        
                    </>
            )}
        </>
    )
};