import {BasicTable, getSkus} from "../../../components";
import {EditableCell} from "../../../components/BasicTable/EditableCell";
import {EDIT_KIT, EDIT_PRODUCT, STORE_PATH} from "../../../navigation/constants";
import {Link, useRouteMatch} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../hooks/UserContext";
import { useContext, useState, useMemo } from "react";
import { TagIcon } from "@heroicons/react/outline";
import { CurrencyInputField, ComparisonTypes } from "../../../components/CurrencyInputField";
import { calculateKitPrice } from "../../../components/utils/productUtils";
import { Tooltip } from "antd";



export const ProductOrderTable = ({products, updateData=null, updatePrice=null, disabled=false, shipping_type, footer_totals=false, updateDiscount=null, hideDiscount=false, limitDiscountToPrice=true, limitPriceToDiscount=true, emptyTableText=null}) => {

    const { t, i18n} = useTranslation();
    const {user} = useContext(UserContext)
    const { url:storePath } = useRouteMatch({ path: STORE_PATH })
    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])

    const getTableOptions = () => {
        let columns = [
            {
                Header: "orders.productTable.name",
                accessor: 'name',
                disableWrap: true
            },
            {
              Header: "orders.productTable.sku",
              shrinkToContent: false,
              accessor: 'sku'
            },
            {
                Header: "orders.productTable.quantity",
                accessor: 'quantity',
                shrinkToContent: true,
                Cell: !disabled ? EditableCell : ({ value, row }) => value
            }
        ]
        if(warehouse.country === 'BR'){
            
            columns.push(
                {
                    Header: "orders.productTable.price_per_item",
                    shrinkToContent: true,
                    accessor: 'price',
                    disableWrap: true
                })
            if(!hideDiscount){
                columns.push(
                    {
                        Header: "orders.productTable.discount_per_item",
                        accessor: 'discount',
                        disableWrap: true
                    })
            }
        }
        if(warehouse.country === 'MX' && shipping_type == 'international'){
            columns.push(
                {
                    Header: "orders.productTable.price_per_item",
                    shrinkToContent: true,
                    accessor: 'price',
                    disableWrap: true
                })
        } 
        return columns
    }
    	  
    const memoizedColumns = useMemo(() => getTableOptions(), [shipping_type])


    // if(warehouse.country === 'BR' || shipping_type === 'international')  {
    //     columns.splice(columns.length -1, 1);
    // }

    // if(shipping_type !== 'international')  {
    //     columns.splice(1,0, {
    //         Header: "orders.productTable.price_per_item",
    //         accessor: 'price'
    //     })
    // }

    const showProductName = (product) => {
        
        if(product.product===null){
            return product.name
        } else {
            let link = storePath + EDIT_PRODUCT.replace(':id', product.product.id)
            if(product.type==="variants"){
                link = storePath + EDIT_PRODUCT.replace(':id', product.parent.id)
            }
            if(product.type==="kit"){
                link = storePath + EDIT_KIT.replace(':id', product.product.id)
            }
            return (
                <div className="flex flex-col gap-2 max-w-max">
                    <Tooltip title= {t("inventory.listProducts.edit_product")}>
                        <Link to={link} className="focus:outline-none">
                            {product.product.full_name ? product.product.full_name: product.product.name}
                        </Link>
                    </Tooltip>

                    {product.isKit && (
                        <span className={"m-0 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-50 text-green-500 max-w-max"}>
                            {i18n.t("inventory.listProducts.kit")}
                        </span>
                    )}
                </div>
            )
        }
    }

    const calculateProductPrice = (product) => {
        let price

        // if(product?.product?.is_kit && warehouse.country === 'BR'){
        //     price = calculateKitPrice(product.product.kit_childs, 1)
        // } else {
            price = product?.price_per_item || product?.product?.price || 0.0
            price = parseFloat(price)
        // }

        return price
    }

    return (
        <>
        <BasicTable
            showHeader
            showTotalsFooter={footer_totals ? true: false}
            footerTotalsOptions={footer_totals}
            columns={memoizedColumns}
            data={products.map((product, i) => {
                let minPriceInput = null
                let maxDiscountInput = null
                let initialPriceValue
                let initialDiscountValue
                let priceValue = calculateProductPrice(product)

                if(limitPriceToDiscount){
                    const discount =  parseFloat(product?.discount_per_item)
                    minPriceInput = isNaN(discount) ? null : parseFloat((discount).toFixed(2))
                }

                if(limitDiscountToPrice){
                    maxDiscountInput = isNaN(priceValue) ? null : (priceValue) < 0 ? 0.0 : parseFloat((priceValue).toFixed(2))
                    maxDiscountInput = maxDiscountInput < 0 ? null : maxDiscountInput
                }

                const discountValue = product?.discount_per_item || 0.0

                const isPriceDisabled = !product?.product?.is_scannable
                const isDiscountDisabled = product?.product ? !product?.product?.is_scannable : false

                if(product?.product?.is_scannable) {
                    initialPriceValue = priceValue
                    initialDiscountValue = discountValue
                } else{
                    initialPriceValue = 0
                    initialDiscountValue = 0
                    minPriceInput = null
                    maxDiscountInput = null
                }

                const minInputComparisonType = !isPriceDisabled && minPriceInput === 0 && maxDiscountInput === 0 ? ComparisonTypes.GREATER_THAN : ComparisonTypes.GREATER_THAN_OR_EQUAL_TO
                const maxDiscountComparisonType = isPriceDisabled && maxDiscountInput === 0 ? ComparisonTypes.LESS_THAN_OR_EQUAL_TO : maxDiscountInput === 0 ? ComparisonTypes.LESS_THAN : ComparisonTypes.LESS_THAN_OR_EQUAL_TO

                return {
                    name: showProductName(product),
                    sku: product.hasError==false ? getSkus(product.product_codes, true) : <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">{product.error}</span>,
                    quantity: product.quantity,
                    price: 
                    <div className="m-2 w-28"> 
                            <CurrencyInputField 
                            icon= {<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>}
                            min={0} 
                            value={initialPriceValue} 
                            onChange={(v) => {
                                updatePrice(i, v);
                            }}
                            isDisabled={isPriceDisabled}
                            inputClassName={isPriceDisabled ? "shadow-none border-0 bg-transparent" : ""}
                            scale={2}
                            minInputValue={minPriceInput}
                            minInputComparisonType={minInputComparisonType}
                            permanentError={true}
                            customMinInputMessage={" "}
                            /> 
                        </div>,
                    discount: (
                        <div className="m-2 w-28">
                            <CurrencyInputField
                                icon= {<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>}
                                min={0}
                                value={initialDiscountValue}
                                onChange={(v) => {
                                    updateDiscount(i, v)
                                }}
                                isDisabled={isDiscountDisabled}
                                inputClassName={isDiscountDisabled ? "shadow-none border-0 bg-transparent" : ""}
                                scale={2}
                                maxInputValue={maxDiscountInput}
                                maxInputComparisonType={maxDiscountComparisonType}
                                permanentError={true}
                                customMaxInputMessage={i18n.t("orders.productTable.invalid_discount")}
                                mirrorVerticalPadding={true}
                            />
                        </div>
                    ),
                    }

                }
            )}
            selectable={false}
            editable={true}
            updateData={updateData}
            emptyTableText= {emptyTableText ? emptyTableText : i18n.t("orders.productTable.no_products")}
        />
        </>
    )
}