import axiosInstance from "../helpers/axiosInstance";

export const getStatements = async function (params = {}) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store_billing_statements`, { params: params })
    return res.data
}

export const getStatementServiceSummary = async function (storeBillingStatementId, params = {}) {
    const statementId = storeBillingStatementId === null ? "" : storeBillingStatementId
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-statements/${statementId}/attachment/service_summary_csv`, {
        params: params,
        responseType: 'blob'
    })
    return res
}

export const getStatementSummaryPDF = async function (storeBillingStatementId) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-statements/${storeBillingStatementId}/attachment/pdf_summary`, {
        responseType: 'blob'
    })
    return res
}

export const getOrderDetail = async function (storeBillingStatementId, params = {}) {
    const statementId = storeBillingStatementId === null ? "" : storeBillingStatementId
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-statements/${statementId}/attachment/orders_detail_csv`, {
        params: params,
        responseType: 'blob'
    })
    return res
}

export const getStatementOrdersDetail = async function (params) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-statements/orders-detail`, {params: params})
    return res.data
}

export const getBillingTotals = async function (storeBillingStatementId, params) {
    const statementId = storeBillingStatementId === null ? "" : storeBillingStatementId
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-statements/${statementId}/totals`, {params: params})
    return res.data
}


export const getReturnDetail = async function (storeBillingStatementId, params = {}) {
    const statementId = storeBillingStatementId === null ? "" : storeBillingStatementId
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-statements/${statementId}/attachment/returns_detail_csv`, {
        params: params,
        responseType: 'blob'
    })
    return res
}

export const getClaimDetail = async function (storeBillingStatementId, params = {}) {
    const statementId = storeBillingStatementId === null ? "" : storeBillingStatementId
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-statements/${statementId}/attachment/claims_detail_csv`, {
        params: params,
        responseType: 'blob'
    })
    return res
}

export const getBillingServiceTotals = async function (storeBillingStatementId, params) {
    const statementId = storeBillingStatementId === null ? "" : storeBillingStatementId
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-statements/${statementId}/service-totals`, {params: params})
    return res.data
}
