import {useContext, useEffect, useRef, useState, Fragment, useMemo} from "react";
import {Button, ConfirmDialog, InputField, TextareaField, SelectInput} from "../../../components";
import {SwitchPanel} from "../../../components/SwitchPanel";
import "@pathofdev/react-tag-input/build/index.css";
import {OptionRow} from "./OptionRow";
import {useDispatch, useSelector} from "react-redux";
import { fetchNcms} from "../../../services/ncmsServices"
import { Tooltip } from 'antd';
import {
    productOptions,
    addOption,
    productHasOptions,
    setHasOptions,
    productVariants,
    productData,
    setProductData,
    setVariants,
    productsKit,
    addKitProducts,
    modifyKitProduct, setData, resetData, deleteKitProduct, addVariant,
} from "../../../redux/createProductSlice";
import ReactTagInput from "@pathofdev/react-tag-input";
import {VariantRow} from "./VariantRow";
import {getVariants} from "../../../utils/getVariants";
import PropTypes, {instanceOf} from "prop-types";
import AddNcmDialog from "../../../components/AddNCMDialog/AddNCMDialog";
import SelectProductDialog from "../../../components/SelectProductDialog/SelectProductDialog";
import {
    fetchProduct,
    fetchProductImageFile,
    saveKit,
    saveProduct, updateKit,
    updateParentProductWithVariants,
    updateSingleProduct,
    searchByTaxId
} from "../../../services/productServices";
import {useQuery, useQueryClient} from "react-query";
import {useHistory, useParams} from "react-router-dom";
import {ProductKitsTable} from "./ProductKitsTable";
import {ProductsReadTable} from "./ProductsReadTable"
import Product from "./Product";
import {UserContext} from "../../../hooks/UserContext";
import { Collapse, notification } from 'antd';
import {useForm, Controller} from "react-hook-form";
import InputText from "../../../components/Forms/InputText";
import CreatableSelect from 'react-select/creatable';
import { ImageInputField } from "../../../components/ImageInputField";
import { TagIcon, TrashIcon, ScaleIcon, ChevronDownIcon, CheckIcon } from "@heroicons/react/outline";
import { Loader } from "../../../components/Loader";
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { CurrencyInputField } from "../../../components/CurrencyInputField";
import { Combobox } from '@headlessui/react'
import { NumericInputField } from "../../../components/NumericInputField";
import {isBlank} from "../../../utils/StringUtils";

import { ORIGIN_PRODUCT,getOrigin } from '../../../components/TaxDefinitions';
import { isNull } from "lodash";

const components = {
    DropdownIndicator: null,
};

const createOption = (label/*: string*/) => ({
    label,
    value: label,
});

const shortid = require('shortid');

const { Panel } = Collapse

export const AddProductContainer = ({type, edit=false}) => {

    AddProductContainer.propTypes = {
        type: PropTypes.oneOf(["kit", "product"]),
        edit: PropTypes.bool
    }

    const { t, i18n } = useTranslation(); 
    const {user} = useContext(UserContext)
    const queryClient = useQueryClient()
    const history = useHistory()
    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])

    const {id} = useParams()

    const options = useSelector(productOptions)
    const variants = useSelector(productVariants)
    const hasOptions = useSelector(productHasOptions)
    const product = useSelector(productData)
    const products = useSelector(productsKit)
    const dispatch = useDispatch();

    const [openDialogProducts, setOpenDialogProducts] = useState(false)
    const [messageScannableProdcut, SetMessageScannableProdcut] = useState(false)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const [imageError, setImageError] = useState(null)
    const [images, setImages] = useState([])
    const [loadingImage, setLoadingImage] = useState(false)
    const [totalOrderValue, setTotalOrderValue] = useState(0);
    const [openDialogAddNCM, setOpenDialogAddNCM] = useState(false)
    const {register, handleSubmit, control, formState: { errors }, setValue, reset} = useForm()
    const [skuInput, setSkuInput] = useState("")

    const _deletedKitProducts = useRef([])
    const _deletedVariants = useRef([])
    
    const {
        isLoading,
        isError,
        error,
        isFetchedAfterMount,
        data
    } = useQuery(`product-${id}`, ()=>fetchProduct(id), {
        onSuccess: (info) => {
            if (isFetchedAfterMount === false && edit){
                setEditProductData(info)
                setSelectedTax(info?.ncm_configuration)
                setTaxCodeSearch(info?.ncm_configuration?.ncm)
            }

        }
    })

    const [taxesList, setTaxesList] = useState([])
    const [selectedTax, setSelectedTax] = useState("")
    const [fetchingTaxCodes, setFetchingTaxCodes] = useState(false)
    const [taxCodeSearch, setTaxCodeSearch] = useState("")

    useEffect(() => {
      if (!taxCodeSearch || taxCodeSearch === "") {
        setTaxesList([])
        return
      }
        searchTaxFunc(taxCodeSearch.trim())
    }, [taxCodeSearch])


  const renderTaxesList =  useMemo(() => {
      return  taxesList.slice(0, 10)
  },[taxesList])
    
    const searchTaxFunc = async (search_code) => {
        setFetchingTaxCodes(true)
        try {
            let listTaxes = await fetchNcms({store_id: user.current_store?.id,  ncm: search_code})
            setTaxesList(listTaxes.ncm_configurations)
        } catch (error) {
            console.log(error)
            setTaxesList([])
        }
        setFetchingTaxCodes(false)
    }
    

    useEffect(() => {
        if(options.filter((option)=>option.isValid===false).length===0 && edit===false){
            const optionsTemp = options.map(option => {
                return option.tags.map(tag => {
                    return {
                        name: option.name,
                        value: tag
                    }
                })
            })

            const variants = getVariants(optionsTemp).map(item => {
                return {
                    key: shortid.generate(),
                    name: (item instanceof Array)? item: [item],
                    skus: [],
                    barCode: null
                }
            })

            dispatch(setVariants(variants))
        }
    }, [options])


    const handlerAddOption = () => {
        dispatch(addOption({
            key: shortid.generate(),
            name: '',
            tags: []
        }))
    }
    const handlerAddNCM = () => {
       setOpenDialogAddNCM(true)
    }

    const handlerAddVariant = () => {
        dispatch(addVariant({
            key: shortid.generate(),
            name: "",
            skus: [],
            barCode: null,
            edited: false,
            type: "add"
        }))
    }

    const onDeleteVariantHandler = (variant) => {
        if(edit){
            if(variant.type!=="add"){
                _deletedVariants.current.push({
                    ...variant
                })
            }
        }
    }

    const fileToBase64 = async (file) => {
        const reader = new FileReader()
        return new Promise((resolve, reject) => {
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = error => reject(error)
            reader.readAsDataURL(file)
        })
    }

    const onSubmit = (data) => {
        if (!product.isScannable && images.length < 1) {
            setImageError (i18n.t("addProduct.container.required_field")) 
            return
        }
        dispatch(setProductData({
            ...product,
            // barCode: data.barCode!=""?data.barCode: null,
            // name: data.name,
            billing_name: data.billing_name
        }))
        setOpenConfirmDialog(true)
    }

    const onConfirmAddProductHandler = async () => {
        setLoading(true)
        let productImage = null

        if (images?.length > 0) {
            productImage = {
                filename: images[0].name,
                base64_data: await fileToBase64(images[0]),
                content_type: images[0].type
            }
        }
        try {
            if (edit === false) {
                if (type === "product") {
                    const res = await saveProduct(hasOptions, product, variants, options, user.current_store?.id, productImage, selectedTax)
                } else {
                    const res = await saveKit(product, products, user.current_store?.id, totalOrderValue)
                }
            } else {
                if (type === "product") {
                    if (hasOptions === false) {
                    
                        const res = await updateSingleProduct(id, product, Product.getSkusForEdit(product.skus, data.product_codes), productImage, selectedTax)
                    } else {
                        const res = await updateParentProductWithVariants(id, product, Product.getVariantsForUpdate(variants), _deletedVariants.current)
                    }
                } else {
                    await updateKit(id, product, Product.getSkusForEdit(product.skus, data.product_codes), Product.getProductsForUpdate(products), _deletedKitProducts.current)
                }
            }
            await queryClient.invalidateQueries('products')
            setOpenConfirmDialog(false)
            setLoading(false)
            history.goBack()
        } catch (e) {
            setOpenConfirmDialog(false)
            setLoading(false)
            
            notification.open({
                message: 'Error',
                description: e.response.data.error,
                duration: 3,
                rtl: true
            })
        }
    }

    useEffect(() => {
        let orderValue = 0;
        products.forEach((product) => {
            orderValue += (product.price * product.quantity)
        });
        setTotalOrderValue(orderValue);
    });

    const handleOnAddProducts = (addedProducts) => {
        addedProducts = addedProducts.filter((p)=>!products.find((stateProd)=>stateProd.product_id===p.id))
        dispatch(addKitProducts(addedProducts.map(product => {
            const productPrice = !product.is_scannable ? "0.0" : product.price

            return {
                quantity: 1,
                name: product.parentName ? product.parentName.length > 20 ? `${product.name} (${product.parentName.substring(0, 15)}...)` : `${product.name} (${product.parentName})` : `${product.name}`,
                product_id: product.id,
                price: productPrice,
                key: shortid.generate(),
                sku: product.product_codes[0].sku,
                is_scannable: product.is_scannable
            }
        })))
        setOpenDialogProducts(false)
    }

    const handleUpdateQuantity = (index, id, value) => {
        if (value < 1){
            if(edit){
                _deletedKitProducts.current.push({
                    ...products[index]
                })
            }
            if (value < 1)
                dispatch(deleteKitProduct({
                    ...products[index],
                }))
        }   else {
            dispatch(modifyKitProduct({
                ...products[index],
                quantity: value
            }))
        }
    }

    const handleUpdatePrice = (index, value) => {
        dispatch(modifyKitProduct({
            ...products[index],
            price: value
        }))
    }

    const setEditImageData = async (imagePathData) => {
        setLoadingImage(true)
        let imageFile = await fetchProductImageFile(imagePathData.path, imagePathData.filename, imagePathData.content_type)
        // console.log("imageFile:",imageFile)
        setImages([imageFile])
        setLoadingImage(false)
    }

    const setEditProductData = (info) => {
        const skus = info.product_codes.map(item => {
            return {label: item.sku, value: item.sku}
        })
        const values = {
            name: info.name,
            barCode: info.bar_code,
            skus: skus,
        }
        
        reset(values)
        if (info.product_image_path) {
            setEditImageData(info.product_image_path)
        }
        dispatch(setData({
            product: {
                name: info.name,
                price: info.price,
                weight: info.weight,
                billing_name: info.billing_name,
                description: info.description,
                skus: skus,
                barCode: info.bar_code,
                isDropshipping: info.is_dropshipping,
                isFragile: info.is_fragile,
                hasExpiration: info.has_expiration,
                freshness: info.freshness,
                bufferStock: info.buffer_stock,
                type: "", // product, kit
                isScannable: info.is_scannable,
                origin: info.origin,
                is_packageless: info.is_packageless,
                has_imei: info.has_imei,

            },
            hasOptions: false,
            options: [],
            products: info.kit_childs.map(item=>{
                return {
                    id: item.id,
                    key: item.id,
                    product_id: item.child_product.id,
                    //id: item.child_product.id,
                    name: item.child_product.name,
                    quantity: item.quantity,
                    sku: item.child_product.product_codes[0].sku,
                    price_per_item: item.price_per_item,
                    is_packageless: info.is_packageless,
                    has_imei: info.has_imei,
                }
            })
        }))
    }

    const handleKeyDown = (event/*: SyntheticKeyboardEvent<HTMLElement>*/) => {

        if (!skuInput) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                console.group('Value Added');
                console.groupEnd();
                setSkuInput("")
                dispatch(setProductData({
                    ...product,
                    skus: [...product.skus, createOption(skuInput)]
                }))
                setValue("skus", [...product.skus, createOption(skuInput)])
                event.preventDefault();
        }
    }

    const renderSkuInputs = () => {
        return (
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                    <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                        SKU <span className="text-red-500"> *</span>
                    </label>
                    <div className="mt-1">
                        <Controller
                            name="skus"
                            control={control}
                            rules={{ required: true }}
                            //rules={{validate: (val)=> product.skus.length>0}}

                            render={({field}) => {
                                    return (
                                        <CreatableSelect
                                            {...field}
                                            components={components}
                                            inputValue={skuInput}
                                            isClearable
                                            isMulti
                                            menuIsOpen={false}
                                            onChange={(tags) => {
                                                dispatch(setProductData({
                                                    ...product,
                                                    skus: tags
                                                }))
                                                setValue("skus", tags)
                                            }}
                                            onInputChange={(val)=>setSkuInput(val)}
                                            onKeyDown={handleKeyDown}
                                            placeholder={i18n.t("addProduct.container.type_sth")}
                                            value={product.skus}
                                        />
                                    )
                                }
                            }
                        />
                        <p className="text-sm text-red-600">{(Object.keys(product.skus).length === 0)  && i18n.t("addProduct.container.required_field_sku")}</p>

                    </div>
                </div>

                <div className="sm:col-span-3">
                    <InputField
                        title= {i18n.t("addProduct.container.barCode")}
                        value={product.barCode}
                        onChange={(value) => {
                            {dispatch(setProductData({
                                ...product,
                                barCode: value
                            }))}
                        }} 
                        required
                    />

                    {isBlank(product.barCode) && <p className="text-sm text-red-600">{i18n.t("addProduct.container.required_field")}</p>}
                </div>
            </div>
        )
    }

    const renderSwitchOptions = () => {
        if(edit===true && hasOptions===false)
            return <></>

        return <SwitchPanel
            title= {i18n.t("addProduct.container.variants")} 
            description={!product.isScannable ?  i18n.t("addProduct.container.without_variants") : i18n.t("addProduct.container.multiple_options") }
            value={hasOptions}
            onChange={(value) => dispatch(setHasOptions(value))}
            disabled={edit || !product.isScannable}
            clickableLabel={true}
        />
    }

    const filesChangeHandler = (files) => {
        if (files.length > 0) {
            setImageError(null)
            setImages([...images, ...files])
        }
    }

    const removeImage = (id) => {
        setImages(images.filter((img) => img.id != id))
    }

    const checkRequiredFields = () => {
        if (product.hasExpiration && (isNaN(product.freshness) || !product.freshness)) {
            return false
        }
        if (product.name && product.name !== '') {
            return true
        }
        return false
    }

    const checkRequiredFieldsBR = () => {
        if (product.price && product.price !== '' &&
            product.weight && product.weight !== '' && selectedTax !== '' &&  selectedTax !== null) {
            return true
        }
        return false
    }

    const checkRequiredFieldsBRKit = () => {
        if( !products || products?.length === 0 ) {return false}

        const pricesAreValid = products?.every( addedProduct => {
            const price = parseFloat(addedProduct?.price)

            return addedProduct?.is_scannable === false ? true : isNaN(price) ? false : (price > 0)
        })

        if(!pricesAreValid){return false}

        if (product.name && product.name !== '' &&
            !isBlank(product.barCode)) {
            return true
        }
        return false
    }
    
    if(isLoading && edit){
        return <><Loader show={true}></Loader></>
    }
    if(isError && edit){
        return <div className="mt-4">{error.message}</div>
    }

    if(type==="kit" && edit){
        <br></br>
        return (
        <>
        <div className="bg-red-100 text-red-800 text-center text-xl font-style: italic mx-8 h-full mt-64"> {i18n.t("addProduct.container.operational_errors")}</div>
        <ProductsReadTable products={products} updateData={handleUpdateQuantity}/>
        </>
        )
    }
    
    return (
        <>
            <AddNcmDialog
                open={openDialogAddNCM}
                setOpen={setOpenDialogAddNCM}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
            

                
                {hasOptions==false && type=="product" && <div className="sm:col-span-2 mb-4 flex flex-col gap-4">
                    {product.isScannable && (
                        <>
                            <SwitchPanel
                                title= {i18n.t("addProduct.container.packageless_title")}
                                description= {i18n.t("addProduct.container.packageless_description")}
                                value={product.is_packageless}
                                onChange={(value) => dispatch(setProductData({...product, is_packageless: value}))}
                                disabled={false}
                                clickableLabel={true}
                            />
                            <div className="flex flex-col gap-2">
                                <SwitchPanel
                                    title= {i18n.t("addProduct.container.has_imei_title")}
                                    description= {i18n.t("addProduct.container.has_imei_description")}
                                    value={product.has_imei}
                                    onChange={(value) => dispatch(setProductData({...product, has_imei: value}))}
                                    disabled={false}
                                    clickableLabel={true}
                                />
                                {product.has_imei && (
                                    <div className="bg-red-100 text-red-500 font-medium py-0.5 px-2 rounded-lg">
                                        {`${i18n.t("addProduct.container.important")}: `}
                                        <span className="font-normal">
                                            {i18n.t("addProduct.container.has_imei_warning")}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    <SwitchPanel
                        title= {i18n.t("addProduct.container.Dropshipping")} 
                        description= {i18n.t("addProduct.container.unmanaged")}
                        value={product.isDropshipping}
                        onChange={(value) => dispatch(setProductData({...product, isDropshipping: value}))}
                        disabled={false}
                        clickableLabel={true}
                    />
                </div>}
                <div className="sm:col-span-2 mb-4">
                    <SwitchPanel
                        title= {i18n.t("addProduct.container.fragile_product")}
                        description= {i18n.t("addProduct.container.special_care")} 
                        value={product.isFragile}
                        onChange={(value) => dispatch(setProductData({...product, isFragile: value}))}
                        disabled={false}
                        clickableLabel={true}
                    />
                </div>
                <div className="sm:col-span-2 mb-4">
                    {type!=="kit" && <SwitchPanel
                        title= {i18n.t("addProduct.container.product_has_expiration")}
                        description= {i18n.t("addProduct.container.product_expiration")} 
                        value={product.hasExpiration}
                        onChange={(value) => dispatch(setProductData({...product, hasExpiration: value}))}
                        disabled={false}
                        clickableLabel={true}
                    />}
                    {(product.hasExpiration && <NumericInputField
                        title= {i18n.t("addProduct.container.freshness")}
                        value={product.freshness}
                        onChange={(value) => {
                            {dispatch(setProductData({
                                ...product,
                                freshness: value
                            }))}
                        }} 
                        required={product.hasExpiration}
                    />)}
                    {false && type!=="kit" && <div className="sm:col-span-2 mt-4">
                        <NumericInputField
                            title= {i18n.t("addProduct.container.buffer_stock")}
                            labelElement= {i18n.t("addProduct.container.buffer_stock_description")} 
                            value={product.bufferStock}
                            min={0}
                            onChange={(value) => {
                                {dispatch(setProductData({
                                    ...product,
                                    bufferStock: value
                                }))}
                            }}
                        />
                    </div> }
                </div>

                { type === "product" && !product.is_packageless && !product.has_imei &&
                    <div className="sm:col-span-2 mb-4">
                        <Tooltip
                            open={messageScannableProdcut}
                            placement="bottomRight"
                            title={i18n.t("addProduct.container.no_change_product")}
                            getPopupContainer={trigger => trigger.parentElement}
                        >
                            <div onBlur={() => SetMessageScannableProdcut(false)}>
                                <SwitchPanel
                                    title = {i18n.t("addProduct.container.promotional_product")}
                                    description = {i18n.t("addProduct.container.no_barcode_scanning")}
                                    value = {!product.isScannable}
                                    onChange = { value => {
                                        if(edit && warehouse.country === 'BR'){
                                            SetMessageScannableProdcut(messageScannableProdcut ? false : true)
                                        }
                                        else{
                                            SetMessageScannableProdcut(false)
                                            dispatch(setProductData({...product, isScannable: !value}))
                                            dispatch(setHasOptions(false))
                                        }
                                    }}
                                    disabled = {false}
                                    clickableLabel = {true}
                                />
                            </div>
                        </Tooltip>
                    </div>
                }

                { type === "product" &&
                    <div className="sm:col-span-2">
                        <Loader show={loadingImage} />
                        {
                            images.length < 1 ?
                            <>
                                <ImageInputField
                                    containerClassName='mt-2'
                                    label= {i18n.t("addProduct.container.product_image")}
                                    onChange={(files) => filesChangeHandler(files)}
                                    onError={(error, file, message) => setImageError(message)}
                                    multiple={false}
                                    maxFiles={1}
                                    required={!product.isScannable}
                                    placeholder = {i18n.t("addProduct.container.drag_image")}
                                    help={<div className="text-center"> {i18n.t("addProduct.container.required_image")}</div>}
                                />
                                {imageError && (
                                    <div className="text-red-500 text-center italic mt-2">{imageError}</div>
                                )}
                            </>
                            :
                            <>
                                <div className="mt-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        {i18n.t("addProduct.container.product_image")}
                                        {!product.isScannable && <span className="text-red-500"> *</span>}
                                    </label>

                                    <div className="flex flex-wrap">
                                        {images.map(image => (
                                            <div
                                                className="w-32 h-32 m-2 bg-cover bg-no-repeat bg-center rounded shadow relative"
                                                style={{
                                                    backgroundImage: `url('${image.preview?.url}')`,
                                                }}
                                                key={String(image.id)}
                                            >
                                                <div
                                                    onClick={() => removeImage(image.id)}
                                                    className="absolute -right-1 -top-1 rounded-full bg-white border border-gray-600 p-1 cursor-pointer"
                                                >
                                                    <TrashIcon className="h-4 w-4 text-gray-600" />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }

                <div className="space-y-8 divide-y divide-gray-200">

                    <SelectProductDialog
                        open={openDialogProducts}
                        setOpen={setOpenDialogProducts}
                        onAddProducts={handleOnAddProducts}
                        queryExtraParams={{is_kit: false}}
                    />
                    <ConfirmDialog
                        open={openConfirmDialog}
                        setOpen={setOpenConfirmDialog}
                        title= {i18n.t("addProduct.container.Save_product")}
                        description= {i18n.t("addProduct.container.add_product")} 
                        confirmLabel="Si"
                        onConfirm={onConfirmAddProductHandler}
                        loading={loading}
                    />

                

                    <div className="space-y-8 divide-y divide-gray-200">

                        <div>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                            <div className="sm:col-span-6">
                                    <InputField
                                        title= {i18n.t("addProduct.container.name")}
                                        value={product.name}
                                        onChange={(value) => {
                                            {dispatch(setProductData({
                                                ...product,
                                                name: value
                                            }))}
                                        }} 
                                        required
                                    />
                                    
                                    <p className="text-sm text-red-600">{product.name !== '' || product.name !== null && i18n.t("addProduct.container.required_field")}</p>

                                </div>


                                {(warehouse.country ==='BR' && type === "product" && product.isScannable) && 
                                    <>
                                    <div className='sm:col-span-3'>
                                        <CurrencyInputField
                                        icon= {<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>}
                                        title= {i18n.t("addProduct.container.price")} 
                                        value={product.price}
                                        onChange={(value) => {
                                            {dispatch(setProductData({
                                                ...product,
                                                price: value
                                            }))}
                                        }} 
                                        
                                        required
                                        />

                                        <p className="text-sm text-red-600">{product.price !== '' || product.price !== null && i18n.t("addProduct.container.required_field")}</p>

                                    </div>
                                    <div className='sm:col-span-3'>
                                        <CurrencyInputField
                                            icon = {<ScaleIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>}
                                            title= {i18n.t("addProduct.container.weight")}
                                            value={product.weight}
                                            onChange={(value) => {
                                                {dispatch(setProductData({
                                                    ...product,
                                                    weight: value
                                                }))}
                                            }} 
                                            
                                            required
                                        />
                                        
                                        <p className="text-sm text-red-600">{product.weight !== '' || product.weight !== null && i18n.t("addProduct.container.required_field")}</p>
                                        
                                    </div>

                                    <div className="sm:col-span-6">
                                        <label className='block text-sm font-medium text-gray-700 mb-1'> { <div> {i18n.t("addProduct.container.ncm_code")} <span className="text-red-500"> *</span> </div>} </label>
                                            <Combobox value={selectedTax} onChange={setSelectedTax} >
                                                <div className="relative">
                                                    <div className="appearance-none block w-full px-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                        
                                                        <Combobox.Input 
                                                        className="w-full border-none text-sm leading-5 text-gray-900 focus:ring-0"
                                                        displayValue={(_tax) => {
                                                            return _tax ? _tax.ncm : ""
                                                        }}
                                                        onChange={(evt) => {setTaxCodeSearch(evt.target.value)}} 
                                                        />
                                                        <Combobox.Button className="absolute right-1 mt-2 items-center pr-2">
                                                        <ChevronDownIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                        </Combobox.Button>
                                                    </div>
                                                    <Combobox.Options
                                                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {taxesList.length === 0 && !fetchingTaxCodes && taxCodeSearch != "" ? (
                                                            <>
                                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            {i18n.t("addProduct.container.no_exist_ncm")}
                                                            </div>

                                                            <div className="relative cursor-pointer select-none py-2 pl-10 pr-2" onClick={handlerAddNCM}>
                                                            {i18n.t("addProduct.container.add_ncm")}
                                                            </div>
                                                            </>
                                                        ) : (
                                                        taxesList.map((tax) => (
                                                            <Combobox.Option 
                                                            key={tax.ncm+tax.description}
                                                            className={({ active }) =>`relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-gray-800' : 'text-gray-900'}`}
                                                            value={tax}>
                                                                {({ selected, active }) => (
                                                                    <>
                                                                        <span
                                                                        className={`block truncate ${
                                                                            selected ? 'font-bold' : 'font-normal'
                                                                        }`}
                                                                        >
                                                                        {tax.ncm} - {tax.description}
                                                                        </span>
                                                                        {selected ? (
                                                                        <span
                                                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                                            active ? 'text-teal-500' : 'text-teal-600'
                                                                            }`}
                                                                        >
                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </span>
                                                                        ) : null}
                                                                    </>
                                                                )}

                                                            </Combobox.Option>
                                                        )))
                                                        }
                                                    </Combobox.Options>
                                                </div>
                                            </Combobox>

                                        <p className="text-sm text-red-600">{selectedTax !== '' || selectedTax !== null && i18n.t("addProduct.container.required_field")}</p>

                                    </div>

                                    <div className="sm:col-span-6">
                                            <SelectInput
                                                options={ORIGIN_PRODUCT}
                                                selectedInitial={getOrigin(product.origin)}
                                                className="sm:col-span-3"
                                                label= {i18n.t("settings.ncm_configurations.origin")}
                                                onChange={(value) => {dispatch(setProductData({
                                                    ...product,
                                                    origin: value
                                                }))}}
                                                value={getOrigin(product.origin)}
                                                required
                                                />
                                        <p className="text-sm text-red-600">{getOrigin(product.origin) !== '' || getOrigin(product.origin) !== null && i18n.t("addProduct.container.required_field")}</p>

                                    </div>
                                
                                    </>
                                }
                             


                                <InputText
                                    className="sm:col-span-6"
                                    label={i18n.t("addProduct.container.billing_name")}
                                    name="billing_name"
                                    register={register}
                                    defaultValue={product.billing_name ? product.billing_name: ""}
                                />

                                <TextareaField
                                    className="sm:col-span-6"
                                    label= {i18n.t("addProduct.container.Description")}
                                    value={product.description}
                                    onChange={(value) => {dispatch(setProductData({
                                        ...product,
                                        description: value
                                    }))}}
                                />

                            </div>
                        </div>

                        {type==="product" && <div className="pt-8">
                            {renderSwitchOptions()}

                            { hasOptions ? <div className="">

                                <div className="divide-y divide-gray-200">

                                    {options.map(option => {
                                        return (
                                            <OptionRow
                                                option={option}
                                                key={option.key}
                                                control={control}
                                                errors={errors}
                                                setValue={setValue}
                                            />
                                        )
                                    })}

                                    {edit===false && <div className="py-4 flex w-full">
                                        <Button
                                            type="secondary"
                                            onClick={handlerAddOption}
                                        >{i18n.t("addProduct.container.Add_attribute")}</Button>
                                    </div>}

                                    {variants.map(variant => <VariantRow
                                        variant={variant}
                                        key={variant.key}
                                        control={control}
                                        setValue={setValue}
                                        errors={errors}
                                        register={register}
                                        onDelete={onDeleteVariantHandler}/>)}

                                    {edit===true && <div className="py-4 flex w-full">
                                        <Button
                                            type="secondary"
                                            onClick={handlerAddVariant}
                                        >{i18n.t("addProduct.container.Add_variant")}</Button>
                                    </div>}
                                </div>

                            </div> : renderSkuInputs() }
                        </div>}

                        {type==="kit" && <div className="pt-8">

                            <div className="pt-4">
                                <div className="pb-3 sm:flex sm:items-center sm:justify-between">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">{i18n.t("addProduct.container.products")}</h3>
                                    <div className="mt-3 sm:mt-0 sm:ml-4">
                                        <button
                                            type="button"
                                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => setOpenDialogProducts(true)}
                                        >
                                            {i18n.t("addProduct.container.Add_new_product")}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <p className="text-sm text-gray-500">{i18n.t("addProduct.container.product_selected")}</p>           
                            <ProductKitsTable products={products} updateData={handleUpdateQuantity} updatePrice={handleUpdatePrice}/>

                            {(warehouse.country ==='BR') && 
                                <div>
                                    <p className='text-right block text-lg font-medium text-gray-700 mt-2'> {i18n.t("addProduct.container.total")} <NumberFormat value={totalOrderValue} displayType={'text'} thousandSeparator={true} prefix={'$'}/> </p>
                                </div>
                            }
                            

                            {renderSkuInputs()}
                        </div>}

                    </div>


                    <div className="pt-5 pb-5">
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
                                onClick={() => history.goBack()}
                            >
                                {i18n.t("addProduct.container.Cancel")}
                            </button>

                            {warehouse.country ==='BR' && type === "product" && product.isScannable &&
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full disabled:opacity-50"
                                    disabled={!checkRequiredFieldsBR()}
                                >
                                {i18n.t("addProduct.container.Save")} 
                                </button>
                            }

                            {warehouse.country ==='BR' && type === "product" && !product.isScannable &&
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full disabled:opacity-50"
                                    disabled={!checkRequiredFields()}
                                >
                                {i18n.t("addProduct.container.Save")} 
                                </button>
                            }

                            {warehouse.country !=='BR' && type === "product" && 
                                <button
                                type="submit"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full disabled:opacity-50"
                                disabled={!checkRequiredFields()}
                                >
                                {i18n.t("addProduct.container.Save")} 
                                </button>    
                            }

                            {warehouse.country ==='BR' && type === "kit" &&
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full disabled:opacity-50"
                                    disabled={!checkRequiredFieldsBRKit()}
                                >
                                {i18n.t("addProduct.container.Save")} 
                                </button>
                            }

                            {warehouse.country !=='BR' && type === "kit" && 
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full disabled:opacity-50"
                                    disabled={!checkRequiredFields()}
                                >
                                    {i18n.t("addProduct.container.Save")} 
                                </button> 
                            }


                            
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}