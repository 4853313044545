import i18n from "../../translations";
import {Button} from "../../components";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import {ADD_ORDER, DUPLICATE_ORDER, STORE_PATH} from "../../navigation/constants";

const TextSection = function (
    {
        title,
        subtitle,
        text,
        textList
    }) {
    return (
        <div>
            <div className="text-lg font-medium text-gray-900">{title}</div>
            {subtitle && <div className="text-base text-gray-700">{subtitle}</div>}
            {text && <div className="text-base text-gray-700">{text}</div>}
            {textList &&
                <div className="text-base text-gray-700 mt-2 pl-4">
                    <ul className="list-disc space-y-1">
                        {textList.map(listElement => <li key={listElement}>{listElement}</li>)}
                    </ul>
                </div>
            }
        </div>
    )
}

const StaleClaimSuccess = () =>
    <div className="space-y-10 ">
        <TextSection
            title={i18n.t('claims.create.delivery_delay.review_window.title')}
            subtitle={i18n.t('claims.create.delivery_delay.review_window.subtitle')}
        />
        <TextSection
            title={i18n.t('claims.create.delivery_delay.refund_details.title')}
            subtitle={i18n.t('claims.create.delivery_delay.refund_details.subtitle')}
            textList={i18n.t('claims.create.delivery_delay.refund_details.refund_elements', { returnObjects: true })}
        />
        <TextSection
            title={i18n.t('claims.create.delivery_delay.advice.title')}
            subtitle={i18n.t('claims.create.delivery_delay.advice.subtitle')}
            text={i18n.t('claims.create.delivery_delay.advice.text')}
        />
    </div>
const NotReceivedClaimSuccess = () =>
    <div className="space-y-10 ">
        <TextSection
            title={i18n.t('claims.create.false_delivery.review_window.title')}
            subtitle={i18n.t('claims.create.false_delivery.review_window.subtitle')}
        />
        <TextSection
            title={i18n.t('claims.create.false_delivery.refund_details.title')}
            subtitle={i18n.t('claims.create.false_delivery.refund_details.subtitle')}
            textList={i18n.t('claims.create.false_delivery.refund_details.refund_elements', { returnObjects: true })}
        />
        <TextSection
            title={i18n.t('claims.create.false_delivery.advice.title')}
            subtitle={i18n.t('claims.create.false_delivery.advice.subtitle')}
            text={i18n.t('claims.create.false_delivery.advice.text')}
        />
    </div>

const LostClaimSuccess = (
    {
        duplicateOrder
    }) =>
    <div className="space-y-10 ">
        <TextSection
            title={i18n.t('claims.create.package_lost.review_window.title')}
            subtitle={i18n.t('claims.create.package_lost.review_window.subtitle')}
        />
        <TextSection
            title={i18n.t('claims.create.package_lost.refund_details.title')}
            subtitle={i18n.t('claims.create.package_lost.refund_details.subtitle')}
            textList={i18n.t('claims.create.package_lost.refund_details.refund_elements', { returnObjects: true })}
        />
        <TextSection
            title={i18n.t('claims.create.package_lost.advice.title')}
            subtitle={i18n.t('claims.create.package_lost.advice.subtitle')}
        />
        <Button onClick={() => duplicateOrder()} type="primary">{i18n.t("claims.create.duplicate_order_button")}</Button>
    </div>

const DamagedClaimSuccess = (
    {
        duplicateOrder
    }) =>
    <div className="space-y-10 ">
        <TextSection
            title={i18n.t('claims.create.damaged_product.review_window.title')}
            subtitle={i18n.t('claims.create.damaged_product.review_window.subtitle')}
        />
        <TextSection
            title={i18n.t('claims.create.damaged_product.refund_details.title')}
            subtitle={i18n.t('claims.create.damaged_product.refund_details.subtitle')}
            textList={i18n.t('claims.create.damaged_product.refund_details.refund_elements', { returnObjects: true })}
        />
        <TextSection
            title={i18n.t('claims.create.damaged_product.advice.title')}
            subtitle={i18n.t('claims.create.damaged_product.advice.subtitle')}
        />
        <Button onClick={() => duplicateOrder()} type="primary">{i18n.t("claims.create.duplicate_order_button")}</Button>
    </div>

const MissingProductSuccess = (
    {
        duplicateOrder
    }) =>
    <div className="space-y-10 ">
        <TextSection
            title={i18n.t('claims.create.incorrect_content.missing_products.paid_product.review_window.title')}
            subtitle={i18n.t('claims.create.incorrect_content.missing_products.paid_product.review_window.subtitle')}
        />
        <TextSection
            title={i18n.t('claims.create.incorrect_content.missing_products.paid_product.refund_details.title')}
            subtitle={i18n.t('claims.create.incorrect_content.missing_products.paid_product.refund_details.subtitle')}
            textList={i18n.t('claims.create.incorrect_content.missing_products.paid_product.refund_details.refund_elements', { returnObjects: true })}
        />
        <TextSection
            title={i18n.t('claims.create.incorrect_content.missing_products.paid_product.advice.title')}
            subtitle={i18n.t('claims.create.incorrect_content.missing_products.paid_product.advice.subtitle')}
        />
        <Button onClick={() => duplicateOrder()} type="primary">{i18n.t("claims.create.duplicate_order_button")}</Button>
    </div>

const UnwantedProductClaimSuccess = (
    {
        duplicateOrder
    }) =>
    <div className="space-y-10 ">
        <TextSection
            title={i18n.t('claims.create.incorrect_content.unwanted_products.review_window.title')}
            subtitle={i18n.t('claims.create.incorrect_content.unwanted_products.review_window.subtitle')}
        />
        <TextSection
            title={i18n.t('claims.create.incorrect_content.unwanted_products.refund_details.title')}
            subtitle={i18n.t('claims.create.incorrect_content.unwanted_products.refund_details.subtitle')}
            textList={i18n.t('claims.create.incorrect_content.unwanted_products.refund_details.refund_elements', { returnObjects: true })}
        />
        <TextSection
            title={i18n.t('claims.create.incorrect_content.unwanted_products.advice.title')}
            subtitle={i18n.t('claims.create.incorrect_content.unwanted_products.advice.subtitle')}
        />
        <Button onClick={() => duplicateOrder()} type="primary">{i18n.t("claims.create.duplicate_order_button")}</Button>
    </div>

const WrongOrderClaimSuccess = (
    {
        duplicateOrder
    }) =>
    <div className="space-y-10 ">
        <TextSection
            title={i18n.t('claims.create.incorrect_content.wrong_order.review_window.title')}
            subtitle={i18n.t('claims.create.incorrect_content.wrong_order.review_window.subtitle')}
        />
        <TextSection
            title={i18n.t('claims.create.incorrect_content.wrong_order.refund_details.title')}
            subtitle={i18n.t('claims.create.incorrect_content.wrong_order.refund_details.subtitle')}
            textList={i18n.t('claims.create.incorrect_content.wrong_order.refund_details.refund_elements', { returnObjects: true })}
        />
        <TextSection
            title={i18n.t('claims.create.incorrect_content.wrong_order.advice.title')}
            subtitle={i18n.t('claims.create.incorrect_content.wrong_order.advice.subtitle')}
        />
        <Button onClick={() => duplicateOrder()} type="primary">{i18n.t("claims.create.duplicate_order_button")}</Button>
    </div>

export const ClaimCreatedContainer = function (
    {
        motive
    }) {
    const { order_id } = useParams()
    const history = useHistory()
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })

    const goToDuplicateOrder = () => history.push(storePath + DUPLICATE_ORDER.replace(':id', order_id))

    return (
        <>
            <div className="space-y-8 divide-y divide-gray-200">
                {motive === 'DELIVERY_DELAY' && <StaleClaimSuccess/>}
                {motive === 'FALSE_DELIVERY' && <NotReceivedClaimSuccess/>}
                {motive === 'PACKAGE_LOST' && <LostClaimSuccess duplicateOrder={goToDuplicateOrder} />}
                {motive === 'DAMAGED_PRODUCT' && <DamagedClaimSuccess duplicateOrder={goToDuplicateOrder}/>}
                {motive === 'MISSING_PRODUCTS' && <MissingProductSuccess duplicateOrder={goToDuplicateOrder}/>}
                {motive === 'UNWANTED_PRODUCTS' && <UnwantedProductClaimSuccess duplicateOrder={goToDuplicateOrder}/>}
                {motive === 'WRONG_ORDER' && <WrongOrderClaimSuccess duplicateOrder={goToDuplicateOrder} />}
            </div>
        </>
    )
}