export const ShippingMethodIcon = ({ className = null }) => {
    return (
        <svg className={className} viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M41.3912 92.6324C41.3912 94.7138 40.5643 96.7099 39.0926 98.1816C37.6209 99.6534 35.6248 100.48 33.5434 100.48C31.462 100.48 29.4659 99.6534 27.9942 98.1816C26.5224 96.7099 25.6956 94.7138 25.6956 92.6324M41.3912 92.6324C41.3912 90.551 40.5643 88.5549 39.0926 87.0832C37.6209 85.6115 35.6248 84.7846 33.5434 84.7846C31.462 84.7846 29.4659 85.6115 27.9942 87.0832C26.5224 88.5549 25.6956 90.551 25.6956 92.6324M41.3912 92.6324H72.7822M25.6956 92.6324H15.8859C14.3249 92.6324 12.8278 92.0123 11.724 90.9085C10.6202 89.8047 10.0001 88.3076 10.0001 86.7466V69.0891M72.7822 92.6324H84.5539M72.7822 92.6324V69.0891M10.0001 69.0891V29.144C9.99178 27.7113 10.5152 26.3266 11.4691 25.2576C12.423 24.1887 13.7396 23.5117 15.1639 23.3576C32.6019 21.5475 50.1804 21.5475 67.6184 23.3576C70.5744 23.661 72.7822 26.1723 72.7822 29.144V34.1561M10.0001 69.0891H72.7822M100.249 92.6324C100.249 94.7138 99.4226 96.7099 97.9509 98.1816C96.4791 99.6534 94.483 100.48 92.4016 100.48C90.3203 100.48 88.3242 99.6534 86.8524 98.1816C85.3807 96.7099 84.5539 94.7138 84.5539 92.6324M100.249 92.6324C100.249 90.551 99.4226 88.5549 97.9509 87.0832C96.4791 85.6115 94.483 84.7846 92.4016 84.7846C90.3203 84.7846 88.3242 85.6115 86.8524 87.0832C85.3807 88.5549 84.5539 90.551 84.5539 92.6324M100.249 92.6324H106.135C109.384 92.6324 112.042 89.9956 111.838 86.7518C110.787 69.4744 104.968 52.8263 95.028 38.6555C94.0813 37.328 92.8451 36.2332 91.4131 35.4537C89.981 34.6743 88.3905 34.2305 86.7617 34.1561H72.7822M72.7822 34.1561V69.0891"
                stroke="currentColor"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
