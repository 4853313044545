import { useQuery } from "react-query"
import { MetricPercentage } from "../../../../components/MetricsCards/MetricPercentage"
import { getFirstDeliveryAttemptOnTimeTotal } from "../../../../services/storeMetricsService"
import { numberToString } from "../../../../helpers/internationalization"
import { useTranslation } from "react-i18next"
import { useMemo } from "react"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"
import { DEFAULT_QUERY_STALE_TIME } from "../chartDataUtils"

export const CollectedToDoorFirstDeliveryAttemptPercentage = ({ storeId = null, dateFilter = null }) => {
    const { i18n, t } = useTranslation()

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["first_delivery_attempt_on_time_total", storeId, dateFilter], () => getFirstDeliveryAttemptOnTimeTotal(storeId, dateFilter), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refech if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    const slaDeliveryMet = useMemo(() => {
        if (!data) return 0
        const { sla_delivery_met } = data
        return sla_delivery_met
    }, [data])

    const slaDeliveryNotMet = useMemo(() => {
        if (!data) return 0
        const { sla_delivery_not_met } = data
        return sla_delivery_not_met
    }, [data])

    const totalOrders = useMemo(() => {
        const total = slaDeliveryMet + slaDeliveryNotMet
        return total
    }, [slaDeliveryMet, slaDeliveryNotMet])

    return (
        <div>
            <MetricTitle title={t('metrics.collected_to_door.sla_first_attempt_met_total_title')} className={"mb-1"}/>
            <MetricPercentage
                value={slaDeliveryMet}
                total={totalOrders}
                showLoader={isLoading}
                valueFormatter={(n) => numberToString(n, i18n.language)}
                totalLabel={t("metrics.generic.orders_with_delivery_attempt")}
            />
        </div>
    )
}
