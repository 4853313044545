import { StoreBusinessRuleCard } from "../../../../components/StoreBusinessRules/StoreBusinessRuleCard";

const BusinessRulePreview = ({businessRuleToPreview}) => {
    return (
        <StoreBusinessRuleCard
            rule_data={businessRuleToPreview}
        />
    )
}

export default BusinessRulePreview