/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Button } from "../../../components"
import { ReturnsTable } from "../../Returns/ReturnsTable";
import  i18n from "../../../translations/index";


export const ReservedProductsDialog = ({
  open,
  units,
  onConfirm = (selectedProducts) => {},
  onCancel = () => {},
  storeData,
  loading = false,
  statusStarted
}) => {
  const tableRef = useRef()

  const [selectedProducts, setSelectedProducts] = useState([])
  const [searchParams, setSearchParams] = useState({
    page: 1,
    store_id: storeData?.id
  })

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={() => onCancel()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-md sm:w-full sm:p-6">
              <div className="text-lg font-bold text-center pb-2">
              {i18n.t("return.productsDialog.stock_release")}
              </div>
              <div className='text-center'>
              {i18n.t("return.productsDialog.release_the_products")}
              <br></br>
              {i18n.t("return.productsDialog.confirmation")}
              </div>
              <br></br>
              <div>
               <ReturnsTable units={units}/>
              </div>
              <br></br>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <Button
                  disabled={loading}
                  onClick={() => onConfirm(selectedProducts)}
                >

                  {(loading) ? i18n.t("return.productsDialog.loading") : i18n.t("return.productsDialog.confirm")} 

                </Button>
                <Button 
                type="secondary" 
                disabled={loading}
                onClick={() => onCancel()}>

                  {(statusStarted <= 1) ? i18n.t("return.productsDialog.cancel") : i18n.t("return.productsDialog.close")}

                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
