export const OrderStatusIcon = ({ className = null }) => {
    return (
        <svg viewBox="0 0 105 115" role="img" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M51.1708 5.97718C53.5158 4.67428 56.3674 4.67428 58.7128 5.97714L87.8864 22.1849C89.1191 22.8696 89.8835 24.1688 89.8835 25.5787V57.5496C89.8835 60.3694 88.3546 62.9679 85.8897 64.3373L58.7128 79.4356C56.3674 80.7385 53.5158 80.7385 51.1708 79.4356L23.9939 64.3373C21.5288 62.9679 20 60.3694 20 57.5496V25.5787C20 24.1688 20.7644 22.8696 21.997 22.1849L51.1708 5.97718Z"
                stroke="currentColor"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.1172 23.2556L55.059 42.6677M55.059 42.6677L90.0007 23.2556M55.059 42.6677V79.5507"
                stroke="currentColor"
                stroke-width="6"
                stroke-linejoin="round"
            />
            <path d="M37.4707 33.001L72.4125 13.5889" stroke="currentColor" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M31.6479 43.9807L43.2952 50.4717" stroke="currentColor" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
            <circle cx="12.5" cy="102.5" r="9.5" fill="currentColor" stroke="currentColor" stroke-width="6" />
            <circle cx="52.5" cy="102.5" r="9.5" stroke="currentColor" stroke-width="6" />
            <circle cx="92.5" cy="102.5" r="9.5" stroke="currentColor" stroke-width="6" />
            <line x1="20" y1="102" x2="45" y2="102" stroke="currentColor" stroke-width="6" />
            <line x1="60" y1="102" x2="85" y2="102" stroke="currentColor" stroke-width="6" />
        </svg>
    )
}
