import { useContext, useState } from 'react'
import { useHistory, useRouteMatch } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { PageView, Notification } from "../../components"
import { UserContext } from "../../hooks/UserContext"
import TopMenu from "./components/TopMenu"
import { STORE_PATH, WORK_ORDERS } from '../../navigation/constants'
import { notificationTypes } from '../../components/Notification'
import { WorkOrderCreator } from '.'
import { useForm } from './hooks/useForm'
import { CARD_STEPS, MATERIAL_IN_TRANSIT_TO_WAREHOUSE } from './utils/constants'
import { createWorkOrder } from '../../services/workOrderServices'
import { fileToBase64 } from '../../components/FileInputField'

export const WorkOrderCreatorContainer = () => {
    const { t } = useTranslation()
    const { user } = useContext(UserContext)
    const history = useHistory()
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })
    const [notificationMessage, setNotificationMessage] = useState({ show: false, type: "", title: "" })

    const handleOnCancelWorkOrderCreation = () => {
        window.analytics.track("WorkOrders - Discard Creation")

        history.push(storePath + WORK_ORDERS)
        setNotificationMessage({ show: true, type: notificationTypes.ERROR, title: t("work_orders.cancel_creation.on_cancel") })
    }
    const initialState = {
        section1: CARD_STEPS.EDITING,
        section2: CARD_STEPS.HIDDEN,
        subsection2_1: CARD_STEPS.EDITING,
        subsection2_2: CARD_STEPS.HIDDEN,
        section3: CARD_STEPS.HIDDEN,
        section4: CARD_STEPS.HIDDEN,
        workOrderType: null,
        requiredMaterial: null,
        remOrderId: '',
        replenishmentId: '',
        notes: '',
        files: []
    }

    const { formState, handleChange } = useForm({ initialState })

    const handleOnCreateWorkOrder = async () => {
        let processedFiles = []
        if (formState.files.length > 0) {
            for (const file of formState.files) {
                processedFiles.push({
                    filename: file.name,
                    base64_data: await fileToBase64(file),
                    content_type: file.type
                })
            }
        }
        const params = {
            warehouse_id: user.current_store?.warehouses[0].id,
            store_id: user.current_store?.id,
            work_order_type: formState.workOrderType,
            product_origin: formState.requiredMaterial,
            replenishment_references: formState.replenishmentId,
            order_references: formState.requiredMaterial === MATERIAL_IN_TRANSIT_TO_WAREHOUSE ? null : formState.remOrderId,
            notes: formState.notes,
            files: processedFiles
        }

        window.analytics.track("Work Order - Save Work Order")

        try {
            await createWorkOrder(params)
            setNotificationMessage({ show: true, type: notificationTypes.SUCCESS, title: t("work_orders.create_work_order.on_success") })
            history.push(storePath + WORK_ORDERS, {reload: true})
        } catch (error) {
            setNotificationMessage({ show: true, type: notificationTypes.ERROR, title: t("work_orders.create_work_order.on_error") })
        }
    }

    return (
        <div>
            <PageView
                topMenu={<TopMenu store={user.current_store} />}
                childrenFullWidth={true}
            >
                <div className='rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 w-full p-10'>
                    <WorkOrderCreator
                        formState={formState}
                        handleChange={handleChange}
                        onCreateWorkOrder={handleOnCreateWorkOrder}
                        onCancelWorkOrderCreation={handleOnCancelWorkOrderCreation}
                    />
                </div>
            </PageView>

            <Notification
                show={notificationMessage.show}
                setShow={(show) => setNotificationMessage({ ...notificationMessage, show: show })}
                type={notificationMessage.type}
                title={notificationMessage.title}
            />
        </div>
    )
}
