/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useContext, useRef, useState} from 'react'
import { ConfirmDialog} from "../../components"

import { Dialog, Transition } from '@headlessui/react'
import {ArrowDownIcon, ArrowRightIcon, CheckIcon} from '@heroicons/react/outline'
import {useQuery} from "react-query";
import {fetchProducts} from "../../services/productServices";
import {BasicTable} from "../BasicTable/BasicTable";
import {getSkus} from "../utils/productUtils";
import { Loader } from '../Loader';
import { SearchInput } from '../SearchInput';
import { ExpandibleRow } from '../BasicTable/ExpandibleRow';
import {UserContext} from "../../hooks/UserContext";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { setProductListPageSize } from "../../redux/pageSizeSlice"
import { CreateNCMsContainer } from '../../pages/StoreSettings/CreateNCMsContainer';
import {
    setValidSettingsForm
} from '../../redux/validSettingsFormSlice';

export default function AddNCMDialog({open, setOpen}) {
    const { t, i18n} = useTranslation();
    const validSettingsForm = useSelector((state) => state.validSettingsForm.form);
    const {user} = useContext(UserContext)
    const cancelButtonRef = useRef(null)
    const dispatch = useDispatch()
    const [dialog, setDialog] = useState({
        loading: false,
        open: false,
        title: "",
        description: "",
        errorMessage: "",
        type: null
    })


    const tableRef = useRef()


    const handleClickOnCloseDialog = () => {
        if(validSettingsForm.change){
            setDialog({...dialog, loading: false, open: true})
        }else{
            setOpen(false)
        }

     }

    const handleClickOnConfirm = () => {
        setDialog({...dialog, loading: false, open: false})
        setOpen(false)
     }

    const handleClickOnCancelCreateNCM = () => {
        dispatch(setValidSettingsForm({id:"4",change:false,selectedkeys:["4"] }))
        setOpen(false)
    }
    return (
        <>
            <ConfirmDialog
                open={dialog.open}
                setOpen={(value) => setDialog({...dialog, open: value}) }
                title={i18n.t("settings.validate_changes.title")}
                description={i18n.t("settings.validate_changes.question")}
                confirmLabel="Si"
                onConfirm={handleClickOnConfirm}
                loading={dialog.loading}
            />
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={open}
                    dismissable={true}
                    onClose={handleClickOnCloseDialog}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"> &#8203;</span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                            <CreateNCMsContainer onCancel={handleClickOnCancelCreateNCM} edit={false} /> 
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>

            </Transition.Root>
        </>
    )
}