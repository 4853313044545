import { useState, useEffect } from 'react';
import { getAllStoreIdsFromAccounts } from '../helpers/userHelper';
import isLogged from "../services/isLogged";
import {getUser} from "../services/loginServices";


export default function useFindUser() {

    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        async function findUser() {
            try{
                const response = await isLogged()
                if(response){
                    const res = await getUser()
                    setUser({
                        ...res,
                        isLogged: true,
                        all_stores_ids: getAllStoreIdsFromAccounts(res)
                    })
                } else {
                    setUser({
                        isLogged: false
                    })
                }
                setIsLoading(false)
            } catch (e){
                setIsLoading(false)
            }
        }
        findUser();
        return () => {
            setUser({})
        }
    }, [])

    return {
        user,
        setUser,
        isLoading
    }
}