import { useTranslation } from 'react-i18next';

export const StatusPill = ({
  status = "unknown",
  className = "",
  statuses = [],
  description,
  ...props
}) => {
  const { i18n } = useTranslation();

  const statusDef = statuses.find((item) => item.status === status) || statuses[0]

    // We should remove this once we start showing the returned status.
  if (!description || status === 'shipment_returned') {
      return (
          <span
              className={`rounded-full py-1.5 px-3 font-bold border-2 ${statusDef.classes} ${className}`}
              {...props}
          >
              {i18n.t(statusDef.label)}
          </span>
      );
  } else {
      const classes = statusDef.classes.split(' ')
      const textClasses = classes.filter(class_entry => class_entry.includes('text'));
      const otherClasses = classes.filter(class_entry => !class_entry.includes('text'));

      return (
          <div className='flex'>
              <div className={`py-2 px-3 border-2 ${otherClasses.join(' ')}`} style={{'borderRadius': '1.2rem'}}>
              <span
                  className={`font-bold text-sm ${textClasses.join(' ')}`}
                  {...props}
              >
                  {i18n.t(statusDef.label)}
              </span>

                  <br/>

                  <span className="italic text-xs">{i18n.t(`statuses.tracking_descriptions.${description}`)}</span>
              </div>
          </div>
      );
  }

};
