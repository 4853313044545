import { useTranslation } from "react-i18next"
import moment from "moment-timezone";
import { ConfirmDialog, FormattedDate } from ".."
import { Collapse } from 'antd'
import { StoreBusinessRuleStatement } from "./StoreBusinessRuleStatement"
import { StoreBusinessRuleAction } from "./StoreBusinessRuleAction"
import { SwitchToggleButton } from "../SwitchToggleButton"
import { useEffect, useState, useContext } from "react"
import { updateBusinessRule } from "../../services/storeServices"
import { TableImage } from "../TableImage"
import { UserContext } from "../../hooks/UserContext"

export const StoreBusinessRuleCard = ({
    rule_data,
    store_id
}) => {
    const { t, i18n } = useTranslation()
    const [openEnableDialog, setOpenEnableDialog] = useState(false)
    const [openDisableDialog, setOpenDisableDialog] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [enabled, setEnabled] = useState(rule_data?.enabled)
    const { user } = useContext(UserContext)

    const verifyIsSpecialRule = (rule_action_parameters) => {
        const parametersArray = Array.isArray(rule_action_parameters) ? rule_action_parameters : [rule_action_parameters]
        const hasForEveryProductId = parametersArray.some((rule_action_parameter) => {
            return rule_action_parameter && 'quantity_multiplier' in rule_action_parameter && rule_action_parameter.quantity_multiplier !== null
        })
        return hasForEveryProductId
    }

    const [isSpecialRule, setIsSepecialRule] = useState(() => verifyIsSpecialRule(rule_data?.rule_action_parameters))

    useEffect(() => {
        setIsSepecialRule(verifyIsSpecialRule(rule_data?.rule_action_parameters));
    }, [rule_data])

    const convertDateWithTimezone = (dateToConvert, timeZone) => {
        const dateToConvertParsed = new Date(dateToConvert)
        const formattedDate = dateToConvertParsed.toISOString()

        let parsedDate = moment(formattedDate).tz(timeZone)
        parsedDate = parsedDate.format("YYYY-MM-DD HH:mm:ss")

        const convertedDate = new Date(parsedDate)

        return convertedDate
    }

    const RuleActionTitle = ({ rule_alias, created_at }) => {
        const timeZone = user.current_store.warehouses[0].time_zone

        let convertedCreatedAt = null

        if(created_at){
            convertedCreatedAt = convertDateWithTimezone(created_at, timeZone)
        }

        if (!rule_alias) {
            const createdAt = convertedCreatedAt ? new Date(convertedCreatedAt).toLocaleString(i18n.language, { year: "2-digit", month: "numeric", day: "2-digit" }) : null
            return (
                <div className='text-gray-800 font-bold text-lg'>{`${t("settings.store_business_container.rule_without_name")} ${createdAt}`}</div>
            )
        }
        return (
            <div className='text-gray-800 font-bold text-lg'>{rule_alias}</div>
        )
    }

    const RuleActionTimeValidity = ({ start_date, end_date, updated_at, created_at }) => {
        const timeZone = user.current_store.warehouses[0].time_zone

        let formattedTodayDate = moment().tz(timeZone)
        formattedTodayDate = formattedTodayDate.format("YYYY-MM-DD HH:mm:ss")

        let convertedStartDate = null
        let convertedEndDate = null
        let convertedUpdatedAt = null
        let convertedCreatedAt = null

        if(start_date){
            convertedStartDate = convertDateWithTimezone(start_date, timeZone)
        }
        if(end_date){
            convertedEndDate = convertDateWithTimezone(end_date, timeZone)
        }
        if(updated_at){
            convertedUpdatedAt = convertDateWithTimezone(updated_at, timeZone)
        }
        if(created_at){
            convertedCreatedAt = convertDateWithTimezone(created_at, timeZone)
        }

        const now = new Date(formattedTodayDate)

        let timeValidity = ''
        let message = ''

        const isExpired = convertedEndDate && new Date(convertedEndDate) < now

        const startDate = convertedStartDate ? new Date(convertedStartDate).toLocaleString(i18n.language, { year: "2-digit", month: "numeric", day: "2-digit" }) : null
        const endDate = convertedEndDate ? new Date(convertedEndDate).toLocaleString(i18n.language, { year: "2-digit", month: "numeric", day: "2-digit" }) : null
        const createdAt = convertedCreatedAt ? new Date(convertedCreatedAt).toLocaleString(i18n.language, { year: "2-digit", month: "numeric", day: "2-digit" }) : null
        const updatedAt = convertedUpdatedAt ? new Date(convertedUpdatedAt).toLocaleString(i18n.language, { year: "2-digit", month: "numeric", day: "2-digit", hour: "numeric", minute: "numeric", second: "numeric" }) : null

        if (!startDate && !endDate) {
            message = `${t("settings.store_business_container.time_validity.from_date")} ${createdAt}, ${t("settings.store_business_container.time_validity.no_end_date")}`
        } else if (!startDate && endDate) {
            message = `${t("settings.store_business_container.time_validity.from_date")} ${createdAt}, ${t("settings.store_business_container.time_validity.to_date")} ${endDate}`
        } else if (startDate && !endDate) {
            message = `${t("settings.store_business_container.time_validity.from_date")} ${startDate}, ${t("settings.store_business_container.time_validity.no_end_date")}`
        } else {
            message = `${t("settings.store_business_container.time_validity.from_date")} ${startDate} ${t("settings.store_business_container.time_validity.to_date")} ${endDate}`
        }

        if (isExpired) {
            timeValidity = `${t("settings.store_business_container.time_validity.is_expired")}:`
            return (
                <>
                    <div className={`${enabled ? 'text-red-500' : 'text-gray-500'} italic font-normal text-base`}>{timeValidity} {message}</div>
                    {updatedAt && (
                        <p className={"italic font-normal text-gray-500 text-sm"}>{`${t("settings.store_business_container.time_validity.last_update")}: ${updatedAt}`}</p>
                    )}
                </>
            )
        } else {
            timeValidity = `${t("settings.store_business_container.time_validity.is_not_expired")}:`
            return (
                <>
                    <div className={`${enabled ? 'text-green-500' : 'text-gray-500'} italic font-normal text-base`}>{timeValidity} {message}</div>
                    {updatedAt && (
                        <p className={"italic font-normal text-gray-500 text-sm"}>{`${t("settings.store_business_container.time_validity.last_update")}: ${updatedAt}`}</p>
                    )}
                </>
            )
        }
    }

    useEffect(() => {
        setIsLoading(false)
        enabled ? setOpenEnableDialog(false) : setOpenDisableDialog(false)
    }, [enabled])

    const handleConfirmRuleToggle = (new_value) => {
        setIsLoading(true)

        updateBusinessRule({
            store_id: store_id,
            id: rule_data.id,
            enabled: new_value
        }).then((res) => {
            setEnabled(new_value)
            setIsLoading(false)
        }).catch((error) => {
            const code = error.response?.data?.code
            setErrorMessage(t(`settings.store_business_container.confirm_dialog.error.${code}`))
            setIsLoading(false)
        })
    }

    const buildCardBorderStyle = (rule_action, rule_type) => {
        if (rule_type === 'PACKAGE_RULE_TYPE') return 'border-l-8 border-purple-500'
        else if (rule_action === 'ENSURE_ORDER_LINE_RULE_ACTION') return 'border-l-8 border-fuchsia-600'
        else if (rule_action === 'INCLUDE_ORDER_LINE_RULE_ACTION') return 'border-l-8 border-fuchsia-600'
        else if (rule_action === 'HOLD_ORDER_RULE_ACTION') return 'border-l-8 border-gray-300'
        else if (rule_action === 'CANCEL_ORDER_RULE_ACTION') return 'border-l-8 border-red-500'
        else return 'border-l-8 border-white-500'
    }

    const buildCardRuleStyle = (rule_action, rule_type) => {
        const cardStyle = 'bg-white shadow-lg sm:rounded-lg mt-5'
        let cardBorderStyle = buildCardBorderStyle(rule_action, rule_type)
        const cardOpacity = enabled ? 'opacity-100' : 'opacity-50'
        return cardStyle + ' ' + cardBorderStyle + ' ' + cardOpacity
    }

    const buildCardTagStyle = (rule_action, rule_type) => {
        const cardTagStyle = 'inline-flex items-center px-1 py-1 rounded text-base font-semibold mt-3'
        let cardTextStyle = ''
        if (rule_type === 'PACKAGE_RULE_TYPE') cardTextStyle = 'text-purple-500'
        else if (rule_action === 'ENSURE_ORDER_LINE_RULE_ACTION') cardTextStyle = 'text-fuchsia-600'
        else if (rule_action === 'INCLUDE_ORDER_LINE_RULE_ACTION') cardTextStyle = 'text-fuchsia-600'
        else if (rule_action === 'HOLD_ORDER_RULE_ACTION') cardTextStyle = 'text-gray-500'
        else if (rule_action === 'CANCEL_ORDER_RULE_ACTION') cardTextStyle = 'text-red-500'
        return cardTagStyle + ' ' + cardTextStyle
    }

    const buildCardTagText = (rule_action, rule_type) => {
        if (rule_type === 'PACKAGE_RULE_TYPE') return t("settings.store_business_container.package_order")
        else if (rule_action === 'ENSURE_ORDER_LINE_RULE_ACTION') return t("settings.store_business_container.ensure_order")
        else if (rule_action === 'INCLUDE_ORDER_LINE_RULE_ACTION') return t("settings.store_business_container.include_order")
        else if (rule_action === 'HOLD_ORDER_RULE_ACTION') return t("settings.store_business_container.hold_order")
        else if (rule_action === 'CANCEL_ORDER_RULE_ACTION') return t("settings.store_business_container.cancel_order")
        return ''
    }

    const ProductsToAddTable = ({ actionParametersArray }) => {
        return (
            <table className="w-full table-auto">
                <thead>
                    <tr>
                        <th className="w-1/2 text-left text-gray-500 font-medium text-sm"></th>
                        <th className={`w-${isSpecialRule ? '1/4' : '1/2'} px-5 text-left text-gray-500 font-medium text-sm`}></th>
                        {
                            isSpecialRule && (
                                <th className="w-1/4 px-2 text-left text-gray-500 font-medium text-sm"></th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {actionParametersArray.map((rule_action_parameter, index) => (
                        <tr key={index}>
                            <td>
                                <div className="flex items-center mt-3 mr-4" key={index}>
                                    <div className="w-1/4-400">
                                        <TableImage
                                            thumbnail_image={rule_action_parameter.product_image?.thumbnail_path}
                                            regular_image={rule_action_parameter.product_image?.path}
                                        />
                                    </div>
                                    <div className="w-3/4 ml-3">
                                        <div className="mt-1 text-gray-800 font-normal text-sm">
                                            <span>{rule_action_parameter?.product_name}</span>
                                        </div>
                                        <div className="flex flex-nowrap">
                                            {rule_action_parameter?.is_kit && (
                                                <div className="mb-1 text-gray-500 font-normal text-xs flex-nowrap mr-1">
                                                    <span>{t("settings.store_business_container.kit")}</span>
                                                    <span className="ml-1">|</span>
                                                </div>
                                            )}
                                            <div className="mb-1 text-gray-500 font-normal text-xs">
                                                <span>{`${t("settings.store_business_container.sku")}: ${rule_action_parameter?.sku}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="px-5 text-gray-800 font-normal text-sm">
                                {`${t("settings.store_business_container.products_quantity")}: `}
                                {rule_action_parameter?.quantity || rule_action_parameter?.quantity_multiplier} {(rule_action_parameter?.quantity > 1 || rule_action_parameter?.quantity_multiplier > 1)
                                    ?
                                    `${t("settings.store_business_container.many_pieces_to_add")}`
                                    :
                                    `${t("settings.store_business_container.single_piece_to_add")}`}
                            </td>
                            {
                                isSpecialRule ?
                                    rule_action_parameter?.max_multiplied_quantity ? (
                                        <td className="px-2 text-gray-800 font-normal text-sm">
                                            {`${t("settings.store_business_container.max_products_quantity")}: `}
                                            {rule_action_parameter?.max_multiplied_quantity ? rule_action_parameter?.max_multiplied_quantity : 1}
                                            {rule_action_parameter?.max_multiplied_quantity > 1
                                                ?
                                                ` ${t("settings.store_business_container.many_pieces_to_add")}`
                                                :
                                                ` ${t("settings.store_business_container.single_piece_to_add")}`
                                            }</td>
                                    ) : (
                                        <td className="px-2 text-gray-800 font-normal text-sm">
                                            {`${t("settings.store_business_container.max_products_quantity")}: `}
                                            {t("settings.store_business_container.without_max_limit")}
                                        </td>
                                    ) :
                                    null
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    const ProductsToInsert = ({ rule_action_parameters }) => {
        const parametersArray = Array.isArray(rule_action_parameters) ? rule_action_parameters : [rule_action_parameters]
        return (
            <div className="flex flex-wrap mt-1 mb-1 max-w-full">
                <ProductsToAddTable actionParametersArray={parametersArray} />
            </div>
        )
    }

    return (

        <div>
            <>
                <div className="max-w-full min-w-min">
                    <div className={buildCardRuleStyle(rule_data?.rule_action, rule_data?.rule_type)}>
                        <div className="px-4 py-5 sm:m-4">
                            <div className="sm:flex sm:items-start sm:justify-between">
                                <RuleActionTitle key={rule_data?.alias} rule_alias={rule_data?.alias} created_at={rule_data?.created_at} />
                                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                                    {store_id && (
                                        <SwitchToggleButton
                                            value={enabled}
                                            onChange={() => enabled ? setOpenDisableDialog(true) : setOpenEnableDialog(true)}
                                            hasStrongBackground={true}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="sm:flex sm:items-start sm:justify-between">
                                <div className="max-w-xl text-sm text-gray-500">
                                    <RuleActionTimeValidity key={rule_data?.validity_end_date} start_date={rule_data?.validity_start_date} end_date={rule_data?.validity_end_date} updated_at={rule_data?.updated_at} created_at={rule_data?.created_at} />
                                </div>
                            </div>

                            <hr className="bg-gray-400 max-w-full mt-4" />
                            <div className={buildCardTagStyle(rule_data?.rule_action, rule_data?.rule_type)}>
                                {buildCardTagText(rule_data?.rule_action, rule_data?.rule_type)}
                            </div>

                            {
                                (rule_data?.rule_action === "ENSURE_ORDER_LINE_RULE_ACTION" || rule_data?.rule_action === "INCLUDE_ORDER_LINE_RULE_ACTION") && (

                                    <div className="max-w-full">
                                        <ProductsToInsert rule_action_parameters={rule_data?.rule_action_parameters} />
                                    </div>
                                )
                            }

                            <div className='mt-2'>
                                <Collapse
                                    defaultActiveKey={['1', '2']}
                                    config={{
                                        'ant-collapse-arrow-color': 'blue',
                                    }}
                                    ghost
                                >
                                    <Collapse.Panel key={store_id ? null : '2'} header={
                                        <span className="text-blue-500 text-base font-medium">
                                            {isSpecialRule ?
                                                t("settings.store_business_container.for_every_product")
                                                :
                                                t("settings.store_business_container.order_has")
                                            }
                                        </span>
                                    }
                                    >
                                        <div style={{ marginTop: '-10px' }}>
                                            {isSpecialRule && (<StoreBusinessRuleAction rule_action_parameters={rule_data?.rule_action_parameters} />)
                                            }

                                            {isSpecialRule &&
                                                <div className="mt-4 mb-4 ml-3">
                                                    <p className="text-gray-500 text-sm">{t("settings.store_business_container.as_long_as_the_order")}</p>
                                                </div>
                                            }
                                            <StoreBusinessRuleStatement arrayStatement={rule_data?.rule_statement} />

                                            {isSpecialRule &&
                                                <div className="mt-12">
                                                    <p className="text-gray-500 text-sm font-normal">{t("settings.store_business_container.note_max_times_rule_applied")}</p>
                                                </div>
                                            }
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <ConfirmDialog
                open={openEnableDialog}
                setOpen={value => setOpenEnableDialog(value)}
                title={t("settings.store_business_container.confirm_dialog.title_enable")}
                description={t("settings.store_business_container.confirm_dialog.description")}
                confirmLabel={t("settings.store_business_container.confirm_dialog.confirm")}
                cancelLabel={t("settings.store_business_container.confirm_dialog.cancel")}
                onConfirm={() => handleConfirmRuleToggle(!enabled)}
                onCancel={() => {
                    setOpenEnableDialog(false)
                    setErrorMessage("")
                }}
                errorMessage={errorMessage}
                loading={isLoading}
            />
            <ConfirmDialog
                open={openDisableDialog}
                setOpen={value => setOpenDisableDialog(value)}
                title={t("settings.store_business_container.confirm_dialog.title_disable")}
                description={t("settings.store_business_container.confirm_dialog.description")}
                confirmLabel={t("settings.store_business_container.confirm_dialog.confirm")}
                cancelLabel={t("settings.store_business_container.confirm_dialog.cancel")}
                onConfirm={() => handleConfirmRuleToggle(!enabled)}
                onCancel={() => {
                    setOpenDisableDialog(false)
                    setErrorMessage("")
                }}
                errorMessage={errorMessage}
                loading={isLoading}
            />
        </div>
    )
}
