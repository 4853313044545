export const OrderChannelCreatedAtIcon = ({ className = null }) => {
    return (
        <svg className={className} viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M36.7917 17V28.875M92.2083 17V28.875M17 100.125V40.75C17 37.6006 18.2511 34.5801 20.4781 32.3531C22.7051 30.1261 25.7256 28.875 28.875 28.875H100.125C103.274 28.875 106.295 30.1261 108.522 32.3531C110.749 34.5801 112 37.6006 112 40.75V100.125M17 100.125C17 103.274 18.2511 106.295 20.4781 108.522C22.7051 110.749 25.7256 112 28.875 112H100.125C103.274 112 106.295 110.749 108.522 108.522C110.749 106.295 112 103.274 112 100.125M17 100.125V60.5417C17 57.3922 18.2511 54.3718 20.4781 52.1448C22.7051 49.9178 25.7256 48.6667 28.875 48.6667H100.125C103.274 48.6667 106.295 49.9178 108.522 52.1448C110.749 54.3718 112 57.3922 112 60.5417V100.125M64.5 68.4583H64.5422V68.5006H64.5V68.4583ZM64.5 80.3333H64.5422V80.3756H64.5V80.3333ZM64.5 92.2083H64.5422V92.2505H64.5V92.2083ZM52.625 80.3333H52.6672V80.3756H52.625V80.3333ZM52.625 92.2083H52.6672V92.2505H52.625V92.2083ZM40.75 80.3333H40.7922V80.3756H40.75V80.3333ZM40.75 92.2083H40.7922V92.2505H40.75V92.2083ZM76.375 68.4583H76.4172V68.5006H76.375V68.4583ZM76.375 80.3333H76.4172V80.3756H76.375V80.3333ZM76.375 92.2083H76.4172V92.2505H76.375V92.2083ZM88.25 68.4583H88.2922V68.5006H88.25V68.4583ZM88.25 80.3333H88.2922V80.3756H88.25V80.3333Z"
                stroke="currentColor"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
