import { Text, Title } from "@tremor/react"
import { useState } from "react"
import { differenceInDays } from "date-fns"
import { INTERVAL_DAY, INTERVAL_MONTH, INTERVAL_WEEK, INTERVAL_YEAR } from "./metrics/chartDataUtils"
import { useTranslation } from "react-i18next"
import { DateRangePickerIntl, date30DaysAgo, date3YearsAgo } from "../../components/DateRangePickerIntl"
import { ShippedOrdersHistoric } from "./metrics/ShippedOrdersHistoric"
import { ClickToPacked } from "./metrics/ClickToPacked/ClickToPacked"
import { StockSold } from "./metrics/StockSold/StockSold"
import { SalesChannel } from "./metrics/SalesChannel/SalesChannel"
import { Replenishments } from "./metrics/Replenishments/Replenishments"
import { CollectedToDoorLayout } from "./metrics/CollectedToDoor/CollectedToDoorLayout"
import { ClickToDoor } from "./metrics/ClickToDoor/ClickToDoor"
import { StuckOrders } from "./metrics/StuckOrders/StuckOrders"

export const MetricsContainer = ({ storeId, onDateRangeChange = (v) => {} }) => {
    const { i18n, t } = useTranslation()

    const [dateFilter, setDateFilter] = useState({
        from: date30DaysAgo(),
        // from: date3YearsAgo(),
        to: new Date(),
    })
    const [interval, setInterval] = useState(getIntervalForDateRange(dateFilter))

    const handleDateRangeChange = (value) => {
        setDateFilter(value)
        onDateRangeChange(value)
        setInterval(getIntervalForDateRange(value))
        window.analytics.track("Metrics - DateRangeFilter selected", { value })
    }

    return (
        <div className="w-full">
            <div className="mt-10 mb-5">
                <Title className="text-black font-semibold !text-xl">{t("metrics.title")}</Title>
                <Text>{t("metrics.subtitle")}</Text>
            </div>

            <DateRangePickerIntl className="text-lg max-w-2xl my-4" value={dateFilter} onValueChange={handleDateRangeChange} language={i18n.language} />

            <ShippedOrdersHistoric storeId={storeId} dateFilter={dateFilter} interval={interval} />
            <ClickToDoor storeId={storeId} dateFilter={dateFilter} interval={interval} />
            <StuckOrders storeId={storeId} dateFilter={dateFilter} interval={interval} />
            <ClickToPacked storeId={storeId} dateFilter={dateFilter} interval={interval} />
            <CollectedToDoorLayout storeId={storeId} dateFilter={dateFilter} interval={interval} />
            <SalesChannel storeId={storeId} dateFilter={dateFilter} interval={interval} />
            <StockSold storeId={storeId} dateFilter={dateFilter} interval={interval} />
            <Replenishments storeId={storeId} dateFilter={dateFilter} interval={interval} />
        </div>
    )
}

const getIntervalForDateRange = (dateRange) => {
    // return INTERVAL_MONTH
    const days = differenceInDays(dateRange.to, dateRange.from)
    if (days <= 31) return INTERVAL_DAY
    // if (days <= 90) return INTERVAL_WEEK
    if (days <= 366) return INTERVAL_MONTH
    return INTERVAL_YEAR
}
