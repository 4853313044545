import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { UserContext } from "../../hooks/UserContext";
import { useContext, useState, useMemo } from 'react';
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { INVOICE_ORDER, INVOICE_ORDERS, STORE_PATH } from "../../navigation/constants";
import { setAsyncTask } from "../../redux/asyncTaskSlice";
import { enqueueInvoiceExport, fetchInvoices } from "../../services/invoicesService";
import {
    ActionMenu,
    ExportOverviewModal,
    FormattedDate,
    NewTable,
    SlidePanel
} from "../../components";
import { Loader } from '../../components/Loader';
import { setOrdersListPageSize } from "../../redux/pageSizeSlice";
import { ShowOrderContainer } from "../Orders";
import { INVOICES_TYPES, OPERATION_TYPES, ORDER_PURPOSE_TYPES, STATUS_TYPES } from "./constants";
import moment from "moment";
import { customButtonIcon } from "./Util";

const statusFilters = STATUS_TYPES.filter(
    (status) => status.filterable !== false).map((status) => {
        return status.filterable === false
            ? null : { id: status.type, label: status.label }
    }
    )

const purposeFilters = ORDER_PURPOSE_TYPES.filter(
    (purpose) => purpose.filterable !== false).map((purpose) => {
        return purpose.filterable === false
            ? null : { id: purpose.type, label: purpose.label }
    }
    )

export const OrderInvoicesContainer = function () {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })

    const { user } = useContext(UserContext)
    const pageSize = useSelector((state) => state.pageSize.ordersInvoicesList);

    const defaultSearchParams = {
        page: 1,
        invoice_type: INVOICES_TYPES.orders,
        per_page: pageSize,
        store_id: user.current_store?.id,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    const [searchParams, setSearchParams] = useState(defaultSearchParams)
    const [exportInvoicesModalOpen, setExportInvoicesModalOpen] = useState(false)

    const table_columns = [
        {
            Header: 'invoices.list.from_orders.columns.order_number',
            accessor: 'order_number',
            searchAs: 'order_number',
            disableSortBy: true,
        },
        {
            Header: 'invoices.list.status',
            accessor: 'status',
            searchAs: 'status',
            disableSortBy: true,
        },
        {
            Header: 'invoices.list.serial_number',
            accessor: 'serial_number',
            searchAs: 'series_and_serial_number',
            disableSortBy: true,
            disableWrap: true
        },
        {
            Header: 'invoices.list.key',
            accessor: 'key',
            searchAs: 'key',
            disableSortBy: true,
            disableWrap: true
        },
        {
            Header: 'invoices.list.type',
            accessor: 'operation_type',
            searchAs: 'purposes',
            shrinkToContent: true,
            disableSortBy: true,
        },
        {
            Header: 'invoices.list.created_at',
            accessor: 'created_at',
            searchAs: 'created_at',
            disableSortBy: false,
        },
        {
            Header: 'invoices.list.from_orders.columns.billing_name',
            accessor: 'billing_name',
            disableSortBy: true,
            disableWrap: true
        },
        {
            Header: 'invoices.list.from_orders.columns.billing_email',
            accessor: 'billing_email',
            disableSortBy: true,
            disableWrap: true
        },
        {
            Header: 'invoices.list.attachments',
            accessor: 'attachments',
            disableSortBy: true
        },
    ]

    const {
        data: { invoices: invoicesData, meta: paginationMeta } = {},
        isLoading,
        isError,
        isFetching,
        error,
        isPreviousData
    } = useQuery(
        ['orders_invoices', searchParams],
        () => fetchInvoices(searchParams),
        { keepPreviousData: true }
    )

    const filtersInitialData = [
        {
            key: "order_number",
            label: "invoices.filters.order_number",
            placeholder: "invoices.filters.order_number",
            data: {
                visible: true,
                type: 'text',
                value: ''
            },
        },
        {
            key: "status",
            label: "invoices.filters.status",
            placeholder: "invoices.filters.status",
            data: {
                visible: true,
                type: 'multiFilter',
                value: [],
                options: statusFilters,
            },
        },
        {
            key: "created_at",
            label: "invoices.filters.created_at",
            placeholder: "invoices.filters.created_at",
            data: {
                visible: true,
                type: 'date',
                value: null
            },
        },
        {
            key: "purposes",
            label: "invoices.filters.purpose",
            placeholder: "invoices.filters.purpose",
            data: {
                visible: true,
                type: 'multiFilter',
                value: [],
                options: purposeFilters,
            },
        },
        {
            key: "key",
            label: "invoices.filters.key",
            placeholder: "invoices.filters.key",
            data: {
                visible: false,
                type: 'text',
                value: ''
            },
        },
        {
            key: "series_and_serial_number",
            label: "invoices.filters.serial_number",
            placeholder: "invoices.filters.serial_number",
            data: {
                visible: false,
                type: 'text',
                value: ''
            },
        }
    ]

    const [filtersData, setFiltersData] = useState(filtersInitialData)

    const onClickOrderNumber = orderId =>
        history.push(storePath + INVOICE_ORDER.replace(':id', orderId))

    const onSortChange = (orderBy) => {
        if (orderBy.length > 0) {
            setSearchParams({
                ...searchParams,
                order_by: orderBy[0].id,
                order: orderBy[0].desc ? 'desc' : 'asc',
                page: 1
            })
        }
        else {
            if (searchParams.order_by != defaultSearchParams.order_by || searchParams.order != defaultSearchParams.order) {
                setSearchParams({
                    ...searchParams,
                    order_by: defaultSearchParams.order_by,
                    order: defaultSearchParams.order,
                    page: 1
                })
            }
        }
    }

    const buildOrderNumberLink = invoice =>
        <div
            onClick={() => onClickOrderNumber(invoice.order.id)}
            className="hover:underline cursor-pointer text-indigo-600 font-bold"
        >
            #{invoice.order?.order_number}
        </div>

    const buildAttachmentItems = function (invoice) {
        const platformUrl = process.env.REACT_APP_PLATFORM_URL
        const attachments = []

        if (invoice.pdf_file)
            attachments.push({
                title: 'PDF',
                clickHandler: () => window.open(platformUrl + invoice.pdf_file.path)
            })

        if (invoice.xml_file)
            attachments.push({
                title: 'XML',
                clickHandler: () => window.open(platformUrl + invoice.xml_file.path)
            })

        return attachments
    }

    const mapStatus = (paramValues) => {
        const mappedStatus = STATUS_TYPES
            .filter(statusType => paramValues?.includes(statusType.type))
            .map(statusType => t(statusType.label))

        return mappedStatus.join(', ')
    }

    const filterDescriptorValueBuilder = function (paramAccessor, paramValue) {
        if (!paramValue) {
            return null
        }

        const descriptorValues = {
            order_number: paramValue,
            key: paramValue,
            series_and_serial_number: paramValue,
        }

        if (paramAccessor === 'status') {
            descriptorValues.status = mapStatus(paramValue)
        } else if (paramAccessor === 'created_at') {
            descriptorValues.created_at = t(
                `invoices.list.filter_descriptor.${paramAccessor}.descriptionValue`,
                { from: moment(paramValue.from).format('YYYY/MM/DD'), to: moment(paramValue.to).format('YYYY/MM/DD') }
            )
        }

        return descriptorValues[paramAccessor]
    }

    const getStatusColor = (status) => {
        if (status === 'APPROVED') return '#00C000'
        else return 'gray'
    }

    const getTableData = () => invoicesData.map(invoice => ({
        order_number: buildOrderNumberLink(invoice),
        status: <div style={{ color: getStatusColor(invoice.status), fontWeight: 400 }}>{t(STATUS_TYPES.find(invoice_status => invoice_status.type === invoice.status)?.label)}</div>,
        key: <div style={{ 'maxWidth': '12rem' }}><span className="break-words">{invoice.key}</span></div>,
        serial_number: <div style={{ 'maxWidth': '12rem' }}><span className="break-words">{invoice.serie}-{invoice.serial_number}</span></div>,
        operation_type: (
            <>
                <div style={{ marginBottom: '3px' }}>{t(ORDER_PURPOSE_TYPES.find(purpose_type => purpose_type.type === invoice.purpose)?.label)}</div>
                <div style={{ fontWeight: 250 }}>{invoice.operation_type}</div>
            </>
        ),
        created_at: <FormattedDate date={invoice.created_at} shortDate={true} />,
        billing_name: <div style={{ 'maxWidth': '8rem' }}><span className="break-words">{`${invoice.order?.billing_first_name || ''} ${invoice.order?.billing_last_name || ''}`}</span></div>,
        billing_email: <div style={{ 'maxWidth': '12rem' }}><span className="break-words">{invoice.order?.billing_email}</span></div>,
        attachments: <ActionMenu className="float-right" items={buildAttachmentItems(invoice)} customButtonIcon={customButtonIcon()} />
    }))

    const memoizedColumns = useMemo(() => table_columns)

    const updateFiltersData = (updatedData, filter, override = false) => {
        setFiltersData((prev) => {
            return prev.map((item) => {
                if (item.key == filter)
                    return {
                        ...item,
                        data: updatedData
                    }
                else return item
            }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
        })
        let value = updatedData.value

        if (["created_at"].includes(filter) && value?.from && value?.to) {
            const from = new Date(value.from)
            const to = new Date(value.to)
            setSearchParams((prev) => ({
                ...prev,
                [filter]: {
                    from: from.toISOString(),
                    to: to.toISOString()
                }, page: 1
            }))
        }
        else {
            if (!value) {
                setSearchParams((prev) => {
                    // Delete filter key from searchParams
                    const newSearchParams = { ...prev, page: 1 }
                    delete newSearchParams[[filter]]
                    return newSearchParams
                })
            }
            else {
                setSearchParams((prev) => ({ ...prev, [filter]: value, page: 1 }))
            }
        }
    }

    const onFilterChanged = (updatedData, filter) => {
        updateFiltersData(updatedData, filter)
    }

    const handleResetFilters = () => {
        setSearchParams(defaultSearchParams)
    }

    const buildFilterDescriptions = function (params) {
        const filterDescriptions = Object.keys(params)
            .filter(paramAccessor => paramAccessor !== 'purposes')
            .filter(paramAccessor => table_columns.some(column => column.searchAs === paramAccessor))
            .filter(paramAccessor => params[paramAccessor])
            .map(paramAccessor => ({
                description: t(`invoices.list.filter_descriptor.${paramAccessor}.description`),
                descriptionValue: filterDescriptorValueBuilder(paramAccessor, params[paramAccessor])
            })) || []

        if (!filterDescriptions?.length)
            filterDescriptions.push({
                description: '',
                descriptionValue: t(`invoices.list.filter_descriptor.all_from_orders.descriptionValue`),
                warning: t(`invoices.list.filter_descriptor.all_warning`)
            })
        return filterDescriptions
    }

    const isLoadingData = () => (!isPreviousData && isLoading)

    if (isLoadingData())
        return <Loader show={true} />

    if (!isLoadingData() && isError)
        return <>Error: {error.message}</>

    return (
        <>
            <NewTable
                data={getTableData()}
                columns={memoizedColumns}
                showLoader={isFetching && isPreviousData}
                showPaginationOnFooter
                isFetching={isFetching}
                emptyTableText={t('invoices.list.noData')}
                paginationMeta={paginationMeta}
                onPaginationChange={requestedPage => setSearchParams({ ...searchParams, page: requestedPage })}
                onPageSizeChange={pageSize => {
                    setSearchParams({ ...searchParams, per_page: pageSize.id, page: 1 })
                    dispatch(setOrdersListPageSize(pageSize.id))
                }}
                handleResetFilters={handleResetFilters}
                filtersData={filtersData}
                onSortChange={onSortChange}
                onFilterChanged={onFilterChanged}
                openExportDialog={() => setExportInvoicesModalOpen(true)}
                footerLabel={t('invoices.footer_invoice_name')}
            />

            <Switch>
                <Route exact path={storePath + INVOICE_ORDER}>
                    <SlidePanel title="Ver Orden" referrer={storePath + INVOICE_ORDERS}>
                        <ShowOrderContainer />
                    </SlidePanel>
                </Route>
            </Switch>
            <ExportOverviewModal
                filterDescriptions={buildFilterDescriptions(searchParams)}
                open={exportInvoicesModalOpen}
                setOpen={setExportInvoicesModalOpen}
                type="INVOICES"
                subtype="ORDERS"
                purposes={searchParams?.purposes}
                onConfirm={async exportDescriptorsData => {
                    setExportInvoicesModalOpen(false)

                    const { job_id: jobId } = await enqueueInvoiceExport({
                        ...searchParams,
                        export_descriptor: exportDescriptorsData
                    })

                    dispatch(setAsyncTask({ jobId }))
                }}
            />
        </>

    )
}