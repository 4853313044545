import { MultiSelect, MultiSelectItem } from "@tremor/react"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { getStoreCountryStates } from "../../../../services/storeMetricsService"
import { useTranslation } from "react-i18next"
import { SlidersIcon } from "../../../../components/Icons/SlidersIcon"

export const SelectMultipleCountryStates = ({ storeId, onChange = (v) => {} }) => {
    const { t } = useTranslation()

    const [controlledValue, setControlledValue] = useState([])
    const [countryStateOptions, setCountryStateOptions] = useState([])

    const {
        data: countryStatesData,
        // isFetching,
        isLoading: isLoadingCountryStates,
        // refetch
    } = useQuery(["store_metrics_country_states", storeId], () => getStoreCountryStates(storeId), {
        enabled: !!storeId,
        refetchOnWindowFocus: true,
        staleTime: 1000 * 60 * 60 * 8, // after 8h, refetch if screen is focused
        cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    useEffect(() => {
        const formatCountryStatesOptions = async (data) => {
            let countryStatesOptions = []
            if (!data) return
            for (const countryState of data) {
                const option = {
                    value: countryState,
                    label: countryState,
                    key: countryState,
                }
                countryStatesOptions.push(option)
            }
            countryStatesOptions.sort((a, b) => {
                if (a.label < b.label) return -1
                if (a.label > b.label) return 1
                return 0
            })
            setCountryStateOptions(countryStatesOptions)
        }
        formatCountryStatesOptions(countryStatesData).catch((error) => {
            console.error(error)
        })
    }, [countryStatesData])

    const handleOnChange = (v) => {
        setControlledValue(v)
        onChange(v)
    }

    const Placeholder = () => <span className="pl-4">{t("metrics.collected_to_door.select.all_states")}</span>

    return (
        <MultiSelect
            className="text-gray-500 !text-base sm:w-full md:max-w-md"
            value={controlledValue}
            onValueChange={(v) => handleOnChange(v)}
            disabled={isLoadingCountryStates}
            // placeholder={<Placeholder />}
            placeholder={t("metrics.collected_to_door.select.all_states")}
            // icon={() => <SlidersIcon className="h-[1em] w-[1em] ml-2" />}
        >
            {countryStateOptions.map((item) => {
                return (
                    <MultiSelectItem value={item.value} key={item.key}>
                        {item.label}
                    </MultiSelectItem>
                )
            })}
        </MultiSelect>
    )
}
