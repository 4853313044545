import { Divider, Menu, Switch } from 'antd';
import {useSelector, useDispatch} from "react-redux";


const joinClassNames = (...classes) => classes.filter(Boolean).join(' ')
export const TabPanel = ({
    icon,
    title,
    description,
    items,
    selectedkeys,
    children,
    index,
    selected,
    itemSelect=null
}) => {

        const validSettingsForm = useSelector((state) => state.validSettingsForm.form);

    
        const onClick = e => {
            itemSelect(e.key)
          };

          const onSelected = e => {
            itemSelect("default")
          };
          
    return (
        <>
            <div className='text-left w-80'>
                <div
                    className = "flex-col"
                    aria-current={selected ? 'page' : undefined}
                >

                        <div  className={joinClassNames( selected ? 'bg-blue-50 bg-opacity-50' : 'hover:bg-blue-50 hover:bg-opacity-50','flex p-6 border-b border-blue-gray-200')}  onClick={onSelected}>

                            <div className='mt-0.5 h-6 w-6 text-blue-gray-400'>
                                {icon}
                            </div>
                            
                            <div className='ml-3 text-sm'>
                                <h2 className="font-bold text-blue-gray-900">{title}</h2>
                                <p className="mt-1 mb-0 text-blue-gray-500">{description}</p>
                            </div> 
                        </div>
                        
                            { (items && selected) &&
                            <div className = "pl-3">
                                <Menu
                                    className = "font-medium"
                                    selectedKeys = {validSettingsForm.selectedkeys}
                                    style={{ width: 300 }}
                                    items={items}
                                    mode = {'inline'}
                                    onClick={onClick}
                                />
                                
                            </div>
                                
                            }



                </div>
                    
            </div>
        </>
    )
}
