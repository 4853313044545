export const AVAILABLE_TEXT_TAGS = {
    BOLD_START: "{BOLD_START}",
    BOLD_END: "{BOLD_END}",
    HIGHLIGHTED_START: "{HIGHLIGHTED_START}",
    HIGHLIGHTED_END: "{HIGHLIGHTED_END}"
}

export const DEFAULT_TEXT_TAG_CONTENT = {
    isActive: false,
    currentText: ""
}
