import { useState } from 'react';
import { ChevronDownIcon } from '../Icons/ChevronDownIcon';
import { ChevronUpIcon } from '../Icons/ChevronUpIcon';

const Collapse = ({show = "", hide = "", description="", children = ""}) => {
    const [open, setOpen] = useState(false)

    return (
        <> 
            {!open ?
            <div className="flex flex-col text-sm">
                {description &&
                <div className='text-gray-600'>
                    {description}
                </div>}
                <label className="cursor-pointer text-blue-500 mt-1"  
                    onClick={() => {
                        setOpen(previousState => {
                            return !previousState
                        })
                    }}> 
                    {show}
                    <ChevronDownIcon className='inline ml-1 h-4 w-4'/>
                </label>
            </div> : <>
            <div className="flex flex-col text-sm">
                {description &&
                <div className='text-gray-600'>
                    {description}
                </div>}
                <div className='mt-1 text-gray-600'>
                    {children}
                </div>
                <label className="cursor-pointer text-blue-500"  
                    onClick={() => {
                        setOpen(previousState => {
                            return !previousState
                        })
                    }}> 
                    {hide}
                    <ChevronUpIcon className='inline ml-1 h-4 w-4'/>
                </label>
            </div>
            </>
            }
        </>
    )
};

export default Collapse