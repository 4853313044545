import {Radio} from "antd";
import {useContext, useEffect, useState} from "react";
import {useParams, useRouteMatch} from "react-router-dom";
import {useQuery} from "react-query";

import ProductListItem from "./ProductListItem";
import {
    addProductsTo,
    buildProductsData,
    countProductItems,
    getGenerateLabelHelpText,
    getReturnTypeDefinition,
    GENERATE_LABEL_OPTIONS,
    RETURN_TYPES, returnNeedsReview
} from "../../utils/returnsUtils";
import {Button, ConfirmDialog, TextareaField} from "../../components";
import {Loader} from "../../components/Loader";
import {OrderLineItem} from "../Orders/OrderLineItem";
import {reviewReturn, fetchReturn} from "../../services/returnServices"
import {SelectReturnedProductDialog} from "../Orders/CreateReturn/SelectReturnedProductDialog";
import {SHOW_ORDER_RETURNS, STORE_PATH} from "../../navigation/constants";
import {UserContext} from "../../hooks/UserContext";
import { Link } from "react-router-dom";
import  i18n from "../../translations/index";


const formIsValid = function(returnData) {
    const requiredFieldsAreMissing = !returnData.return_type || returnData.generate_guide == null
        || !returnData.expected_products?.length

    const exchangeProductsAreMissing = (returnData.return_type === "EXCHANGE" || returnData.return_type === "IMMEDIATE_EXCHANGE")
        && returnData.exchange_products?.length === 0

    return !(requiredFieldsAreMissing || exchangeProductsAreMissing);
}

const showExchangeProductsList = (returnData) =>
    returnData.return_type && ['EXCHANGE', 'IMMEDIATE_EXCHANGE'].includes(returnData.return_type);

const buildOrderDetailsUrl = (returnData, storePath) =>
    storePath + SHOW_ORDER_RETURNS.replace(":id", returnData.returned_order.id)

const useReviewState = () => {
    const [returnData, setReturnData] = useState(null);
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [showSelectProductToReturnDialog, setShowSelectProductToReturnDialog] = useState(false);
    const [showSelectExchangeProductDialog, setShowSelectExchangeProductDialog] = useState(false);
    const [showConfirmReviewDialog, setShowConfirmReviewDialog] = useState(false);
    const [showConfirmRejectDialog, setShowConfirmRejectDialog] = useState(false);

    const handleGenerateGuideChange = val => setReturnData({ ...returnData, generate_guide: val });

    const handleNotesChange = val => setReturnData({ ...returnData, notes: val });

    const handleExpectedProductQuantityChange = function (value, productIndex) {
        const expected_products = returnData.expected_products
        expected_products[productIndex].quantity = value

        setReturnData({ ...returnData, expected_products })
    }

    const handleReturnTypeChange = val => setReturnData({ ...returnData, return_type: val });

    const handleDamagedProductAddition = function (productIndex) {
        const damagedProduct = {
            ...returnData.expected_products[productIndex],
            damaged: true,
            quantity: 1,
        }

        const products = [...returnData.expected_products]

        if (products[productIndex].quantity > 0) {
            products[productIndex].quantity -= 1
        }

        const damagedProductIndex = returnData.expected_products.findIndex(product =>
            product.damaged && product.product_id === damagedProduct.product_id
        )

        if (damagedProductIndex === -1) {
            products.push(damagedProduct)
        } else {
            products[damagedProductIndex].quantity += 1
        }

        setReturnData({ ...returnData, expected_products: [...products] })
    }

    const handleExpectedProductRemoval = function (productIndex) {
        const products = [...returnData.expected_products]
        products.splice(productIndex, 1)
        setReturnData({ ...returnData, expected_products: [...products] })
    }

    const handleReplacementQuantityChange = function (value, productIndex) {
        const products = returnData.exchange_products
        products[productIndex].quantity = value

        setReturnData({ ...returnData, exchange_products: products })
    }

    const handleRemoveReplacementProduct = function (productIndex) {
        const products = [...returnData.exchange_products]
        products.splice(productIndex, 1)

        setReturnData({ ...returnData, exchange_products: [...products] })
    }
    
    const handleExchangeProductAddition = function (productsToAdd) {
        const productsToExchange = addProductsTo([...returnData.exchange_products], productsToAdd, false)

        setReturnData({ ...returnData, exchange_products: productsToExchange})
        setShowSelectExchangeProductDialog(false)
    }
    
    const handleProductToReturnAddition = function (productsToAdd) {
        const productsToReturn = addProductsTo([...returnData.expected_products], productsToAdd)

        setReturnData({ ...returnData, expected_products: productsToReturn })
        setShowSelectProductToReturnDialog(false)
    }

    return {
        handleDamagedProductAddition,
        handleExpectedProductQuantityChange,
        handleExpectedProductRemoval,
        handleGenerateGuideChange,
        handleNotesChange,
        handleExchangeProductAddition,
        handleProductToReturnAddition,
        handleReplacementQuantityChange,
        handleRemoveReplacementProduct,
        handleReturnTypeChange,
        loadingSubmit,
        returnData,
        setLoadingSubmit,
        setReturnData,
        setShowConfirmRejectDialog,
        setShowConfirmReviewDialog,
        setShowSelectExchangeProductDialog,
        setShowSelectProductToReturnDialog,
        showConfirmRejectDialog,
        showConfirmReviewDialog,
        showSelectExchangeProductDialog,
        showSelectProductToReturnDialog,
    }
}

export const ReviewExternalReturnContainer = ({ onSuccess }) => {
    const {
        handleDamagedProductAddition,
        handleExpectedProductQuantityChange,
        handleExpectedProductRemoval,
        handleGenerateGuideChange,
        handleNotesChange,
        handleExchangeProductAddition,
        handleProductToReturnAddition,
        handleReplacementQuantityChange,
        handleRemoveReplacementProduct,
        handleReturnTypeChange,
        loadingSubmit,
        returnData,
        setLoadingSubmit,
        setReturnData,
        setShowConfirmRejectDialog,
        setShowConfirmReviewDialog,
        setShowSelectExchangeProductDialog,
        setShowSelectProductToReturnDialog,
        showConfirmRejectDialog,
        showConfirmReviewDialog,
        showSelectProductToReturnDialog,
        showSelectExchangeProductDialog,
    } = useReviewState();

    const { user } = useContext(UserContext);
    const { return_id } = useParams();
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })

    const {
        data: _return,
    } = useQuery(["return", return_id], () => fetchReturn(return_id));

    let isActive = true;

    useEffect(() => {
        if (!returnData && _return && isActive)
            setReturnData({
                ..._return,
                exchange_products: [],
                expected_products: buildProductsData(_return.expected_products, _return.returned_order)
            })

        return () => { isActive = false };
    }, [_return]);

    if (_return && !returnNeedsReview(_return)) {
        return <>Error: {i18n.t("returns.externalContainer.unchecked_return")} </>
    }

    if (returnData) {
        const { returned_order: order } = returnData;

        const handleSubmit = async function (reject, setShowConfirmDialog) {
            if (reject){
                window.analytics.track('Returns - Rejects', {id: returnData.id})
            } else {
                window.analytics.track('Returns - Accepts', {id: returnData.id})
            }
            setLoadingSubmit(true);

            try {
                await reviewReturn({...returnData, rejected: reject})

                setShowConfirmDialog(false)
                await onSuccess()
            } catch (error) {
                window.alert(error);
            }

            setLoadingSubmit(false)
        }

        return (
            <div>
                <div className="">
                    <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                {i18n.t("returns.externalContainer.order_number")}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <Link to={buildOrderDetailsUrl(returnData, storePath)} target="_blank">#{order.order_number}</Link>
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">{i18n.t("returns.externalContainer.store")}</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {order.store.name}
                            </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">{i18n.t("returns.externalContainer.notes")}</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <TextareaField
                                    value={returnData.notes}
                                    onChange={(val) => handleNotesChange(val)}
                                />
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                {i18n.t("returns.externalContainer.type_of_return")}<span className="text-red-500">*</span>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <Radio.Group
                                    options={RETURN_TYPES}
                                    onChange={(e) => handleReturnTypeChange(e.target.value)}
                                    value={returnData.return_type}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                                <div className="text-gray-600 pt-2">
                                    <i>{getReturnTypeDefinition(returnData.return_type)?.help}</i>
                                </div>
                            </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                {i18n.t("returns.externalContainer.return_guide")}{" "}
                                <span className="text-red-500"> *</span>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <Radio.Group
                                    options={GENERATE_LABEL_OPTIONS}
                                    onChange={(e) => handleGenerateGuideChange(e.target.value)}
                                    value={returnData.generate_guide}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                                <div className="text-gray-600 pt-2">
                                    <i>
                                        {getGenerateLabelHelpText(
                                            returnData.generate_guide,
                                            user.current_store?.warehouses[0]?.country
                                        )}
                                    </i>
                                </div>
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                {i18n.t("returns.externalContainer.original_content")} 
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                    {order.order_lines?.map(order_line => (
                                        <li className="pl-3 pr-4 py-3 text-md" key={order_line.id}>
                                            <span>
                                                <div>
                                                    <OrderLineItem orderLine={order_line}/>
                                                </div>
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                {i18n.t("returns.externalContainer.products_to_return")}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <Button
                                    type="secondary"
                                    className="float-right"
                                    onClick={() => setShowSelectProductToReturnDialog(true)}
                                >
                                       {i18n.t("returns.externalContainer.add_product")}
                                </Button>
                            </dd>
                        </div>
                        <div className="bg-gray-50 px-6 pb-5 w-full">
                            <table className="w-full text-left">
                                <thead>
                                <tr>
                                    <th>{i18n.t("returns.externalContainer.product")}</th>
                                    <th>UPC</th>
                                    <th>SKU</th>
                                    <th>{i18n.t("returns.externalContainer.quantity")}</th>
                                    <th className="w-0"/>
                                    <th className="w-0"/>
                                </tr>
                                </thead>
                                <tbody>
                                {returnData.expected_products.map((expected_product, index) => (
                                    <ProductListItem
                                        product={expected_product}
                                        productIndex={index}
                                        onQuantityChange={handleExpectedProductQuantityChange}
                                        onDamagedProductAddition={handleDamagedProductAddition}
                                        onProductRemoval={handleExpectedProductRemoval}
                                        key={expected_product.product_id}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </div>
                        {showExchangeProductsList(returnData) && (
                            <>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {i18n.t("returns.externalContainer.send_replacement")}<span className="text-red-500"> *</span>
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <Button
                                            type="secondary"
                                            className="float-right"
                                            onClick={() => setShowSelectExchangeProductDialog(true)}
                                        >
                                            {i18n.t("returns.externalContainer.add_product")}
                                        </Button>
                                    </dd>
                                </div>
                                <div className="bg-white px-6 pb-5 w-full">
                                    <table className="w-full text-left">
                                        <thead>
                                        <tr>
                                            <th>{i18n.t("returns.externalContainer.product")}</th>
                                            <th>UPC</th>
                                            <th>SKU</th>
                                            <th>{i18n.t("returns.externalContainer.quantity")}</th>
                                            <th className="w-0"/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {returnData.exchange_products?.map((exchange_product, index) => (
                                            <ProductListItem
                                                product={exchange_product}
                                                productIndex={index}
                                                onQuantityChange={handleReplacementQuantityChange}
                                                onProductRemoval={handleRemoveReplacementProduct}
                                                hideDamagedDetails={true}
                                                key={exchange_product.product_id}
                                            />
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                        <div className="flex place-content-end mt-2">
                            <Button
                                className="mr-2"
                                onClick={() => setShowConfirmRejectDialog(true)}
                                type="danger"
                            >
                                {i18n.t("returns.externalContainer.reject")}
                            </Button>
                            <Button
                                disabled={!formIsValid(returnData)}
                                className=""
                                onClick={() => setShowConfirmReviewDialog(true)}
                            >
                                {i18n.t("returns.externalContainer.accept")}
                            </Button>
                        </div>
                    </dl>
                </div>
                <SelectReturnedProductDialog
                    open={showSelectProductToReturnDialog}
                    onCancel={() => setShowSelectProductToReturnDialog(false)}
                    storeData={order?.store}
                    onConfirm={(productsToAdd) => handleProductToReturnAddition(productsToAdd)}
                    key={'selectProductToReturnDialog' + returnData.id}
                />
                <SelectReturnedProductDialog
                    open={showSelectExchangeProductDialog}
                    onCancel={() => setShowSelectExchangeProductDialog(false)}
                    storeData={order?.store}
                    onConfirm={(productsToAdd) => handleExchangeProductAddition(productsToAdd)}
                    key={'SelectReturnedProductDialog' + returnData.id}
                />
                <ConfirmDialog
                    open={showConfirmRejectDialog}
                    setOpen={(val) => setShowConfirmRejectDialog(val)}
                    cancelLabel= {i18n.t("returns.externalContainer.cancel")}
                    confirmLabel= {i18n.t("returns.externalContainer.confirm")} 
                    description= {i18n.t("returns.externalContainer.refuse_return")} 
                    title= {i18n.t("returns.externalContainer.return")}
                    loading={loadingSubmit}
                    onConfirm={() => handleSubmit(true, setShowConfirmReviewDialog)}
                    key={'confirmRejectDialog' + returnData.id}
                />
                <ConfirmDialog
                    open={showConfirmReviewDialog}
                    setOpen={(val) => setShowConfirmReviewDialog(val)}
                    cancelLabel= {i18n.t("returns.externalContainer.cancel")}
                    confirmLabel= {i18n.t("returns.externalContainer.confirm")}
                    description={`${i18n.t("returns.externalContainer.total_returned")} ${countProductItems(returnData.expected_products)} ${i18n.t("returns.externalContainer.product")}`}
                    title= {i18n.t("returns.externalContainer.accept_return")}
                    loading={loadingSubmit}
                    onConfirm={() => handleSubmit(false, setShowConfirmReviewDialog)}
                    key={'confirmReviewDialog' + returnData.id}
                />
            </div>
        )
    }

    return <Loader show={true}/>
};