import { useTranslation } from 'react-i18next';

import { PageTopBar } from "../../components";

const TopMenu = ({store}) => {
    const { t } = useTranslation()

    return (
        <PageTopBar>
            <div className="text-lg text-gray-400">
                { store.name }

                &nbsp;&rarr;&nbsp;

                {t("settings.store_settings.config_title")}

                &nbsp;&rarr;&nbsp;

                <span className="font-semibold text-gray-600">
                    {t("settings.business_rule_creator.path")}
                </span>
            </div>

        </PageTopBar>
    )
}

export default TopMenu