import {BasicTable, getSkus} from "../../../components";
import {EditableCell} from "../../../components/BasicTable/EditableCell";
import {EDIT_KIT, EDIT_PRODUCT} from "../../../navigation/constants";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

export const ProductOrderTable = ({products, updateData=null}) => {

    const { t, i18n } = useTranslation();

    const columns = [
        {
            Header: "replenishments.orderTable.name",
            accessor: 'name'
        },
        {
          Header: "replenishments.orderTable.sku",
          accessor: 'sku'
        },
        {
            Header: "replenishments.orderTable.quantity",
            accessor: 'quantity',
            Cell: EditableCell,
        }
    
    ]

    

    return (
        <BasicTable
            showHeader
            columns={columns}
            data={products.map(product => {
                return {
                    name: product.product && product.product.full_name ? product.product.full_name :  (product.parent && product.parent.name ? product.parent.name +" - "+ product.name : product.name),
                    sku: product.hasError==false ? getSkus(product.product_codes) : <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">{product.error}</span>,
                    quantity: product.quantity
                }
            })}
            selectable={false}
            editable={true}
            updateData={updateData}
            emptyTableText= {i18n.t("replenishments.orderTable.no_added_products")}
        />
    )
}