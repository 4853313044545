import { useContext, useState } from 'react';
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PageView, Notification } from "../../components";
import { UserContext } from "../../hooks/UserContext";
import TopMenu from "./TopMenu";
import { BusinessRuleCreator } from './BusinessRuleCreator';
import { STORE_PATH, SETTINGS_BUSINESS_RULES } from '../../navigation/constants';
import { notificationTypes } from '../../components/Notification';
import { createBusinessRule } from '../../services/storeServices';

const BusinessRuleCreatorContainer = () => {
    const { t } = useTranslation()
    const { user } = useContext(UserContext)
    const history = useHistory()
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })
    const [notificationMessage, setNotificationMessage] = useState({show: false, type: "", title: ""})

    const handleOnCreateBusinessRule = async (createdBusinessRule) => {
        const params = {
            store_id: user.current_store.id,
            enabled: true,
            rule_type: createdBusinessRule.rule_type,
            rule_action: createdBusinessRule.rule_action,
            rule_action_parameters: createdBusinessRule.rule_action_parameters,
            rule_statement: createdBusinessRule.rule_statement,
            alias: createdBusinessRule.alias,
            validity_start_date: createdBusinessRule.validity_start_date,
            validity_end_date: createdBusinessRule.validity_end_date
        }

        window.analytics.track("Business Rules - Preview - Create rule")

        try{
            await createBusinessRule(params)
            setNotificationMessage({show: true, type: notificationTypes.SUCCESS, title: t("settings.business_rule_creator.create_rule.on_success")})
            history.push(storePath + SETTINGS_BUSINESS_RULES)
        } catch(error){
            setNotificationMessage({show: true, type: notificationTypes.ERROR, title: t("settings.business_rule_creator.create_rule.on_error")})
        }
    }

    const handleOnCancelBusinessRuleCreation = () => {
        window.analytics.track("Business Rules - Discards Creation")

        history.push(storePath + SETTINGS_BUSINESS_RULES)
        setNotificationMessage({show: true, type: notificationTypes.ERROR, title: t("settings.business_rule_creator.cancel_creation.on_cancel")})
    }

    return (
        <div>
            <PageView
                topMenu={ <TopMenu store={user.current_store} /> }
                childrenFullWidth={ true }
                >
                <div className='rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 w-full p-10'>
                    <BusinessRuleCreator
                        onCreateBusinessRule = {handleOnCreateBusinessRule}
                        onCancelBusinessRuleCreation = {handleOnCancelBusinessRuleCreation}
                    />
                </div>
            </PageView>

            <Notification
                show={notificationMessage.show}
                setShow={(show) => setNotificationMessage({...notificationMessage, show: show})}
                type={notificationMessage.type}
                title={notificationMessage.title}
            />
        </div>
    )
}

export default BusinessRuleCreatorContainer