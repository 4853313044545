import axiosInstance from "../helpers/axiosInstance";
import { validateTransactionStatus, TRANSACTION_STATUS_SUCCESS } from "../services/concurrencyService"

export const fetchReturns = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns`, { params })
    return res.data
}

export const fetchCountPendingReturns = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns/count-pending-returns`, { params })
    return res.data
}

export const fetchReturn = async (returnId) => {
    if(returnId===undefined){
        return null
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns/${returnId}`)
    return res.data
}

export const cancelReturn = async (returnId) => {
    if(returnId===undefined){
        return null
    }
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns/${returnId}/cancel`)
    return res.data
}

export const duplicateReturnedOrder = async (returnId) => {
    if(returnId===undefined){
        return null
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns/${returnId}/create-order`)
    return res.data
}

export const unassignReservedStock = async (returnId) => {
    if(returnId===undefined){
        return null
    }
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns/${returnId}/unassign-reserved-stock`)
    return res.data
}

export const createPlannedReturn = async (orderId, data) => {
    try {
        const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/${orderId}/return`, data )
        if (res.status === 202 && res.data.transaction_id) {
            const transactionStatus = await validateTransactionStatus(res.data.transaction_id)
            console.log("transactionStatus: ", transactionStatus)
            if (transactionStatus != TRANSACTION_STATUS_SUCCESS) {
                throw new Error('Error')
            }
        }
        return res.data
    } catch (error) {
        throw error
    }
}

export const reviewReturn = async data => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns/externally-requested/${data.id}/review`, data)

    return res.data
}