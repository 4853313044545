import { useEffect } from "react"
import { useContext } from "react"
import { Route, Redirect, useRouteMatch } from "react-router-dom"
import { findStoreInAccounts } from "../helpers/userHelper"
import { UserContext } from "../hooks/UserContext"
import { fetchStore } from "../services/storeServices"
import { STORES, STORE_PATH } from "./constants"

const StoreRoute = ({ children, path, location, computedMatch, ...otherProps }) => {
    // console.log("StoreRoute:", { path, location, computedMatch, otherProps })
    return (
        <Route path={path}>
            <CurrentStoreMonitor>{children}</CurrentStoreMonitor>
        </Route>
    )
}

const CurrentStoreMonitor = ({ children }) => {
    const { user, setUser } = useContext(UserContext)
    const match = useRouteMatch({ path: STORE_PATH })
    // console.log("CurrentStoreMonitorMatch:", match)
    const pathStoreId = parseInt(match.params.storeId)

    // check if current_store matches path -> render children
    // check if user has store in accounts -> copy store to current store -> render children
    // request store from api -> copy to current store -> render children
    //                          -> redirect to store list
    useEffect(() => {
        const updateCurrentStore = async (pathStoreId, user) => {
            // find store in accounts
            let foundAccountStore = findStoreInAccounts(pathStoreId, user)
            if (foundAccountStore) {
                setUser({ ...user, current_store: foundAccountStore })
                return
            }
            // request store info, if user has permission
            if (user?.permissions?.read_all_stores) {
                try {
                    let storeFetch = await fetchStore(pathStoreId)
                    setUser({ ...user, current_store: { ...storeFetch } })
                } catch (error) {
                    setUser({ ...user, current_store: null })
                }
                return
            }
            setUser({ ...user, current_store: null })
        }

        if (!isNaN(pathStoreId)) {
            // update current_store on startup or if path changes
            if (!user.current_store || pathStoreId !== user.current_store.id) {
                updateCurrentStore(pathStoreId, user)
            }
        }
    }, [pathStoreId, user])

    if (isNaN(pathStoreId) || user.current_store === null) {
        return <Redirect to={STORES} />
    }
    if (user.current_store === undefined) {
        return <></>
    }
    return <>{children}</>
}

export default StoreRoute
