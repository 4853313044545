import { PencilIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline"
import { CARD_STEPS } from "../utils/constants"

const WorkOrderStepCard = ({
    children,
    title,
    status,
    onEdit,
    completedDescription = null
}) => {

    return (
        status === CARD_STEPS.HIDDEN ? (
            null
        ) : (
            <div className={`shadow-lg border rounded-lg overflow-hidden ${status === CARD_STEPS.EDITING ? "" : "relative"}`}>
                {status === CARD_STEPS.INCOMPLETE && <div className="absolute inset-0 bg-gray-50 bg-opacity-50"></div>}

                <div className={`flex justify-between items-center px-5 py-3 ${status === CARD_STEPS.EDITING ? "bg-blue-950 text-white" : (status === CARD_STEPS.COMPLETED || status === CARD_STEPS.IN_REVIEW) ? "bg-gray-100" : ""}`}>
                    <div className="flex flex-col font-light">
                        <div className="flex gap-2 items-center">
                            <h2 className="text-lg font-normal">
                                {title}
                            </h2>

                            {(status === CARD_STEPS.COMPLETED || status === CARD_STEPS.IN_REVIEW) && (
                                <CheckCircleIcon className="h-6 text-green-600" />
                            )}

                            {status === CARD_STEPS.INCOMPLETE && (
                                <ExclamationCircleIcon className="h-6 text-red-600" />
                            )}
                        </div>
                    </div>

                    {status === CARD_STEPS.COMPLETED && (
                        <PencilIcon className="cursor-pointer hover:text-gray-500 h-6 text-gray-600" onClick={onEdit} />
                    )}
                </div>

                <div>
                    {status === CARD_STEPS.EDITING && (
                        children
                    )}
                </div>

                {(status === CARD_STEPS.COMPLETED || status === CARD_STEPS.IN_REVIEW) && (
                    <div className="p-5 font-semibold">
                        {completedDescription}
                    </div>
                )}
            </div>
        )
    )
}

export default WorkOrderStepCard
