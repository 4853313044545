import React, {useState} from "react";

export const InputField = React.forwardRef(({maxLength, minLength, value, onChange, disabled = false, title=null, labelElement=null, placeholder, required=false, readOnly=false, inputClassName="", help = null, border=false, ...params}, ref) => {
    const { className } = params

    return (
        <div className={className}>
            { title && <label className="block text-sm font-medium text-gray-700">
                {title}{required && <span className="text-red-500"> *</span>}
            </label>}
            { help && 
                <label>
                    <span className="text-xs text-gray-400">{help}</span>
                </label>
            }
            {labelElement}
            <div className="mt-1">
                <input
                    ref={ref}
                    type="text"
                    minLength = {minLength}
                    maxLength={maxLength}
                    className={"shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"+" "+inputClassName+ (required && border && !value ? ' border-red-400' : ' border-gray-400 ')}
                    placeholder={placeholder}  
                    onChange={(event) => {
                        if(onChange!=null){
                            onChange(event.target.value)
                        }
                    }}
                    value={value}
                    readOnly={readOnly}
                    disabled={disabled}
                />
            </div>
        </div>
    )
})