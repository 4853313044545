import {useContext, useState} from "react";
import {getConfigurations, updateUnpaidOrderCancellationSettings} from "../../services/storeServices";
import {UserContext} from "../../hooks/UserContext";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import {Notification} from "../../components";
import {SwitchToggleButton} from "../../components/SwitchToggleButton";

const useAutomationState = function () {
    const [paymentWindow, setPaymentWindow]  = useState('');
    const [paymentWindowUnit, setPaymentWindowUnit]  = useState('days');
    const [successfulUpdateNotificationState, setSuccessfulUpdateNotificationState] = useState(false)
    const [unpaidOrderCancellationActivated, setUnpaidOrderCancellationActivated] = useState(false);
    const [unpaidOrderCancellationIsLoading, setUnpaidOrderCancellationIsLoading] = useState(true);

    const handlePaymentWindowChange = async function (event, store_id) {
        const value = parseInt(event?.target?.value || -1)

        if (value <= 0) {
            setPaymentWindow('')
            await handleUnpaidOrderCancellationToggle(false, store_id)

            return false
        }

        setUnpaidOrderCancellationIsLoading(true)

        setPaymentWindow(value)

        if (unpaidOrderCancellationActivated) {
            await updateUnpaidOrderCancellationSettings(
                store_id,
                {
                    isActive: unpaidOrderCancellationActivated,
                    paymentWindow: value,
                    paymentWindowUnit
                })
            setSuccessfulUpdateNotificationState(true)
        }

        setUnpaidOrderCancellationIsLoading(false)
    }

    const handlePaymentWindowUnitChange = async function(event, store_id) {
        const value = event?.target?.value

        if (!value) return false

        setUnpaidOrderCancellationIsLoading(true)
        setPaymentWindowUnit(value)

        if (unpaidOrderCancellationActivated) {
            await updateUnpaidOrderCancellationSettings(
                store_id,
                {
                    isActive: unpaidOrderCancellationActivated,
                    paymentWindow,
                    paymentWindowUnit: value
                })
            setSuccessfulUpdateNotificationState(true)
        }

        setUnpaidOrderCancellationIsLoading(false)
    }

    const handleUnpaidOrderCancellationToggle = async function (value, store_id) {
        if (!value && value === unpaidOrderCancellationActivated)
            return false

        if (paymentWindow < 1) {
            return false
        }

        setUnpaidOrderCancellationIsLoading(true)

        setUnpaidOrderCancellationActivated(value)

        await updateUnpaidOrderCancellationSettings(
            store_id,
            {
                isActive: value,
                paymentWindow,
                paymentWindowUnit
            })

        setSuccessfulUpdateNotificationState(true)
        setUnpaidOrderCancellationIsLoading(false)
    }

    const setState = function (rawPaymentWindow) {
        if (!rawPaymentWindow) {
            setUnpaidOrderCancellationIsLoading(false)
            return false
        }

        const window_in_months = rawPaymentWindow / (3600*24*30.436875)
        const window_in_months_is_whole_number = (window_in_months - Math.floor(window_in_months)) === 0

        if (window_in_months_is_whole_number) {
            setPaymentWindow(window_in_months)
            setPaymentWindowUnit('months')
        } else {
            setPaymentWindow(rawPaymentWindow / (3600*24))
            setPaymentWindowUnit('days')
        }
        setUnpaidOrderCancellationActivated(true)
        setUnpaidOrderCancellationIsLoading(false)
    }

    return {
        handlePaymentWindowChange,
        handlePaymentWindowUnitChange,
        handleUnpaidOrderCancellationToggle,
        paymentWindow,
        paymentWindowUnit,
        unpaidOrderCancellationActivated,
        unpaidOrderCancellationIsLoading,
        successfulUpdateNotificationState,
        setState,
        setSuccessfulUpdateNotificationState
    }
}

export const UnpaidOrderCancellationCard = function () {
    const {
        handlePaymentWindowChange,
        handlePaymentWindowUnitChange,
        handleUnpaidOrderCancellationToggle,
        paymentWindow,
        paymentWindowUnit,
        unpaidOrderCancellationActivated,
        unpaidOrderCancellationIsLoading,
        successfulUpdateNotificationState,
        setState,
        setSuccessfulUpdateNotificationState,
    } = useAutomationState();

    const {user: { current_store }} = useContext(UserContext);
    const store_id = current_store?.id
    const { i18n } = useTranslation();

    const buildHelpText = function () {
        const windowUnitText = 'days' === paymentWindowUnit ?
            i18n.t("settings.automation_container.unpaid_order_cancellation_panel.payment_window_units.days", {count: paymentWindow}) :
            i18n.t("settings.automation_container.unpaid_order_cancellation_panel.payment_window_units.months", {count: paymentWindow})

        return i18n.t(
            'settings.automation_container.unpaid_order_cancellation_panel.help_text',
            { payment_window: `${paymentWindow} ${windowUnitText.toLowerCase()}` }
        )
    }

    useQuery(
        ["automation_settings-unpaid_order_cancellation", store_id],
        () => getConfigurations(store_id, 'cancel_unpaid_orders_after'),
        { onSuccess: setState }
    );

    return (
        <>
            <Notification
                show={successfulUpdateNotificationState}
                setShow={setSuccessfulUpdateNotificationState}
                type="success"
                title={i18n.t(
                    'settings.automation_container.success_message',
                    {
                        status: unpaidOrderCancellationActivated ?
                            i18n.t('settings.automation_container.enabled_result') :
                            i18n.t('settings.automation_container.disabled_result')
                    })
                }
            />

            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6 m-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="renew-subscription-label">
                        {i18n.t("settings.automation_container.unpaid_order_cancellation_panel.title")}
                    </h3>
                    <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                        <div className="max-w-xl text-sm text-gray-500">
                            <p>
                                {i18n.t("settings.automation_container.unpaid_order_cancellation_panel.description")}
                            </p>
                        </div>
                        <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                            <SwitchToggleButton
                                value={unpaidOrderCancellationActivated}
                                onChange={event => handleUnpaidOrderCancellationToggle(event, store_id)}
                                disabled={unpaidOrderCancellationIsLoading}
                            />
                        </div>
                    </div>
                    <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                        <div>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="number"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="0"
                                    value={paymentWindow}
                                    onChange={event => handlePaymentWindowChange(event, store_id)}
                                    disabled={unpaidOrderCancellationIsLoading}
                                />
                                <div className="absolute inset-y-0 right-0 flex items-center">
                                    <select
                                        className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                                        value={paymentWindowUnit}
                                        onChange={event => handlePaymentWindowUnitChange(event, store_id)}
                                        disabled={unpaidOrderCancellationIsLoading}
                                    >
                                        <option value="days">
                                            {i18n.t("settings.automation_container.unpaid_order_cancellation_panel.payment_window_units.days", {count: paymentWindow || 0})}
                                        </option>
                                        <option value="months">
                                            {i18n.t("settings.automation_container.unpaid_order_cancellation_panel.payment_window_units.months", {count: paymentWindow || 0})}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {unpaidOrderCancellationActivated &&
                        <p className="mt-2 text-sm text-gray-500">
                            {buildHelpText()} <br/> {i18n.t('settings.automation_container.unpaid_order_cancellation_panel.shopify_help_text')}
                        </p>
                    }
                </div>
            </div>
        </>
    )
}