import PDFMerger from "pdf-merger-js"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { IconButton } from "../../../components/IconButton"
import { DownloadIcon } from "../../../components/Icons/DownloadIcon"
import { PdfFileIcon } from "../../../components/Icons/PdfFileIcon"
import { PrintIcon } from "../../../components/Icons/PrintIcon"

export const OrderShippingLabel = ({ labelType, trackingNumber, pdfUrls = [], openPrintDialog = null, onOpenPrintDialog = () => {} }) => {
    const { t } = useTranslation()

    const [mergedPdfUrl, setMergedPdfUrl] = useState(null)
    const mergedPdfIframeRef = useRef(null)
    const containerRef = useRef(null)
    const [openPrintOnUrlChange, setOpenPrintOnUrlChange] = useState(false)

    const handlePrint = () => {
        // mergedPdfIframeRef.current?.focus()
        if (mergedPdfIframeRef.current) {
            mergedPdfIframeRef.current.contentWindow.print()
            onOpenPrintDialog()
        }
    }

    const handleOpen = () => {
        const win = window.open(mergedPdfUrl, "_blank")
        if (win != null) {
            win.focus()
        }
    }

    useEffect(() => {
        // console.log("pdfUrls", pdfUrls)
        if (pdfUrls.length === 0) {
            setMergedPdfUrl(null)
        } else if (pdfUrls.length === 1) {
            setMergedPdfUrl(pdfUrls[0])
        }

        const merger = new PDFMerger()
        const mergePdfs = async () => {
            for (const url of pdfUrls) {
                await merger.add(url)
            }

            const mergedPdf = await merger.saveAsBlob()
            const mergedUrl = URL.createObjectURL(mergedPdf)

            // console.log("mergedPdfUrl", mergedUrl)
            setMergedPdfUrl(mergedUrl)
        }

        mergePdfs().catch((err) => {
            throw err
        })
    }, [pdfUrls])

    useEffect(() => {
        if (openPrintDialog && mergedPdfUrl) {
            handlePrint()
        } else {
            setOpenPrintOnUrlChange(true)
        }
    }, [openPrintDialog])

    // open print screen when merged pdf is available
    useEffect(() => {
        if (openPrintDialog && mergedPdfUrl && openPrintOnUrlChange && mergedPdfIframeRef.current) {
            let printTimer = setTimeout(() => {
                handlePrint()
            }, 20)
            setOpenPrintOnUrlChange(false)
            return () => clearTimeout(printTimer)
        }
    }, [mergedPdfUrl])

    return (
        <div className="" ref={containerRef}>
            <div className="flex items-center">
                <div>
                    <PdfFileIcon className="w-5 h-5" />
                </div>
                <div className="flex-grow">
                    <div className="ml-2">{t(`shipping_labels.label_types.${labelType}`)}</div>
                    <div className="ml-2 font-semibold">{trackingNumber}</div>
                </div>
                <div className="ml-2">
                    <IconButton onClick={() => handleOpen()} icon={<DownloadIcon className="w-4 h-4" />} tooltipTitle={t("files.open")+" PDF"} />
                </div>
                <div className="ml-2">
                    <IconButton onClick={() => handlePrint()} icon={<PrintIcon className="w-4 h-4" />} tooltipTitle={t("files.print")+" PDF"} />
                </div>
            </div>
            <iframe className="hidden" src={mergedPdfUrl} ref={mergedPdfIframeRef} />
        </div>
    )
}
