import axiosInstance from "../helpers/axiosInstance";

export const fetchCnabs = async function (params) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/cnabs`, { params })
    return res.data
}

export const fetchCnab = async function (id) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/cnabs/${id}`)
    return res.data
}

export const uploadCnabPaymentReceipt = async function (id, base64_data) {
    const data = {
        base64_data: base64_data
    }
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/cnabs/${id}/payment-receipt`,  data )
    return res
}
