import { useTranslation } from "react-i18next"
import { Button } from "../../../components"
import { DownloadIcon } from "@heroicons/react/solid"

const StoreBillingClaimDetail = ({ onExport = () => {}, exportBusy = false }) => {
    const { i18n } = useTranslation()

    return (
        <div className="flex-col bg-white flex py-3 px-4">
            <div className="bg-white flex pt-2 pr-2">
                <Button type={"secondary"} onClick={() => onExport()} disabled={exportBusy}>
                    <DownloadIcon className="inline mr-1 h-4 w-4" aria-hidden="true" />
                    {exportBusy ? i18n.t("billing.statement.exporting_services_summary") : i18n.t("billing.statement.export_services_summary")}{" "}
                    {i18n.t(`billing.summaries_options.CLAIM_DETAIL`)}
                </Button>
            </div>
            { exportBusy ? <a className="text-center my-5" onClick={() => onExport()}> {i18n.t("billing.downloading_report")} </a> :
            <a className="text-center my-5 cursor-pointer underline" onClick={() => onExport()}> 
                {i18n.t("billing.download_report")}
            </a>
            }
        </div>
    )
}

export default StoreBillingClaimDetail
