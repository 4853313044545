import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { CONDITION_TYPES } from "../../utils/businessRuleStatementUtils";
import ConditionTypeOption from "./ConditionTypeOption";
import { Button } from "../../../../../components";

const ConditionTypeSelector = ({onSelectConditionType}) => {
    const { t } = useTranslation()
    const containerRef = useRef(null)
    const [showOptions, setShowOptions] = useState(false)

    const conditionTypeOptions = useMemo(() => (
        Object.values(CONDITION_TYPES).map((conditionType) => ({
            value: conditionType,
            optionTitle: t(`settings.business_rule_creator.condition_types.${conditionType}.title`),
            optionDescription: t(`settings.business_rule_creator.condition_types.${conditionType}.description`)
        }))
    ), [t])

    useEffect(() => {
        const validateCurrentClick = (event) => {
            if(!containerRef.current?.contains(event.target)){
                setShowOptions(false)
            }
        }

        document.addEventListener("click", validateCurrentClick)

        return () => {
            document.removeEventListener("click", validateCurrentClick)
        }
    }, [containerRef])

    const handleOnSelectConditionType = (selectedConditionTypeOption) => {
        setShowOptions(false)
        onSelectConditionType(selectedConditionTypeOption)
    }

    return (
        <div ref={containerRef} className="cursor-pointer w-fit">
            <Button
                type="secondary"
                onClick={() => setShowOptions(!showOptions)}
            >
                {t("settings.business_rule_creator.add_condition_button")}
            </Button>

            {showOptions && (
                <div className="absolute border rounded flex flex-col bg-white mt-2">
                    {conditionTypeOptions.map((conditionTypeOption) => (
                        <ConditionTypeOption
                            optionTitle={conditionTypeOption.optionTitle}
                            optionDescription={conditionTypeOption.optionDescription}
                            key={`condition-type-option-${conditionTypeOption.value}`}
                            onSelectedOption={() => handleOnSelectConditionType(conditionTypeOption.value)}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default ConditionTypeSelector