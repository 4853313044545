import { useMemo } from "react";

import { AVAILABLE_TEXT_TAGS, DEFAULT_TEXT_TAG_CONTENT } from "./utils";

const TextWithTags = ({textWithTags, listKey}) => {
    return useMemo(() => {
        const splittedText = textWithTags.split(/({BOLD_START}|{BOLD_END}|{HIGHLIGHTED_START}|{HIGHLIGHTED_END})/)
        const textComponents = []
        const boldTagText = {...DEFAULT_TEXT_TAG_CONTENT}
        const highlightedTagText = {...DEFAULT_TEXT_TAG_CONTENT}

        splittedText.forEach((currentText, index) => {
            switch(currentText){
                case AVAILABLE_TEXT_TAGS.BOLD_START:
                    boldTagText.isActive = true
                    break

                case AVAILABLE_TEXT_TAGS.HIGHLIGHTED_START:
                    highlightedTagText.isActive = true
                    break

                case AVAILABLE_TEXT_TAGS.BOLD_END:
                    {
                        if(boldTagText.isActive){
                            textComponents.push(
                                <span className="font-bold" key={`${listKey}-${index}`}>
                                    {boldTagText.currentText}
                                </span>
                            )
                            boldTagText.isActive = false
                            boldTagText.currentText = ""
                        }
                        break
                    }
                case AVAILABLE_TEXT_TAGS.HIGHLIGHTED_END:
                    {
                        if(highlightedTagText.isActive){
                            textComponents.push(
                                <span className="italic bg-gray-100 text-gray-500" key={`${listKey}-${index}`}>
                                    {highlightedTagText.currentText}
                                </span>
                            )
                            highlightedTagText.isActive = false
                            highlightedTagText.currentText = ""
                        }
                        break
                    }

                default:
                    {
                        if(!boldTagText.isActive && !highlightedTagText.isActive){
                            textComponents.push(
                                <span className="font-normal" key={`${listKey}-${index}`}>
                                    {currentText}
                                </span>
                            )
                        } else if(boldTagText.isActive){
                            boldTagText.currentText += currentText
                        } else if(highlightedTagText.isActive){
                            highlightedTagText.currentText += currentText
                        }
                        break
                    }
            }
        })

        return(
            <span>
                {textComponents.map((textComponent) => textComponent)}
            </span>
        )
    }, [textWithTags, listKey])
}

export default TextWithTags