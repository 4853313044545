import {CheckCircleIcon} from "@heroicons/react/outline";
import {Notification} from "./Notification";
import {useDispatch} from "react-redux";
import {removeAsyncTask, setAsyncTask} from "../redux/asyncTaskSlice";
import {getExport} from "../services/invoicesService";
import {useState} from 'react';
import {useTranslation} from "react-i18next";

export const AsyncTaskNotification = function (
    {
        jobId
    }) {
    const { t } = useTranslation();
    const [exportFileUrl, setExportFileUrl] = useState(null)
    const [progressPercentage, setProgressPercentage] = useState(0)
    const [open, setOpen] = useState(true)
    const dispatch = useDispatch()

    if (!jobId) {
        return <>Error</>
    }

    const handleExportDownload = function () {
        window.open(process.env.REACT_APP_PLATFORM_URL + exportFileUrl)
        setOpen(false)
        dispatch(removeAsyncTask({ jobId }))
    }

    const watchExportProgress = function () {
        const intervalId = setInterval(async () => {
            try {
                const {progress_percentage, download_url} = await getExport(jobId)

                setExportFileUrl(download_url)
                setProgressPercentage(progress_percentage)
                
                if (progress_percentage > 99 || download_url) {
                    clearInterval(intervalId);
                }

            } catch (e) {
                clearInterval(intervalId);
                dispatch(removeAsyncTask({ jobId }))
            }
        }, 1000)
    }

    watchExportProgress()

    const exportNotificationBody = (
        <>
            <div className="w-0 flex-1 flex justify-between">
                {exportFileUrl &&
                    <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                    </div>
                }
                <p className="my-0 ml-2 w-0 flex-1 text-sm font-medium text-gray-900">{!exportFileUrl ? t('async_task_notification.working') : t('async_task_notification.done')}</p>
                {!exportFileUrl &&
                    <p className="my-0 flex-shrink-0 text-base font-medium text-indigo-600">{progressPercentage}%</p>
                }
                {exportFileUrl &&
                    <button
                        type="button"
                        className="ml-3 flex-shrink-0 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleExportDownload}
                    >
                        {t('async_task_notification.download')}
                    </button>
                }
            </div>
        </>
    )

    return (
        <Notification
            show={open}
            ephemeral={false}
            customBody={exportNotificationBody}
        />
    )
}