import { useEffect, useMemo, useRef, useState } from "react"
import { DEFAULT_QUERY_STALE_TIME, INTERVAL_DAY, formatDates, parseDates, popCategories, replaceCategories } from "./chartDataUtils"
import { getShippedOrdersHistoric } from "../../../services/storeMetricsService"
import { useQuery } from "react-query"
import { useTranslation } from "react-i18next"
import { numberToString } from "../../../helpers/internationalization"
import { BaseCard } from "../../../components/MetricsCards/BaseCard"
import { MetricsCardHeader } from "../../../components/MetricsCards/MetricsCardHeader"
import { AreaChart } from "../../../components/MetricsCards/AreaChart"
import { MetricHighlighted } from "../../../components/MetricsCards/MetricHighlighted"
import useOnScreen from "../../../hooks/useOnScreen"

export const ShippedOrdersHistoric = ({ storeId = null, dateFilter = null, interval = INTERVAL_DAY }) => {
    const { i18n, t } = useTranslation()

    const [formattedData, setFormattedData] = useState([])
    const [categories, setCategories] = useState([])

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["shipped_orders_historic", storeId, dateFilter, interval], () => getShippedOrdersHistoric(storeId, dateFilter, interval), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to && !!interval,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    useEffect(() => {
        const formatData = async (data) => {
            // [{truncated_date:a, categories: {b => c, d => f}}]
            const dataParsedDates = parseDates(data)
            const dataReplacedCategories = replaceCategories(dataParsedDates, { shipped: t("metrics.total_orders_historic.legend") })
            setCategories([t("metrics.total_orders_historic.legend")])
            const flattenedData = popCategories(dataReplacedCategories, null)
            const formattedData = formatDates(flattenedData, interval, i18n.language)
            setFormattedData(formattedData)
            return null
        }
        formatData(data).catch((error) => {
            console.error(error)
        })
    }, [data, interval, i18n.language])

    const totalShippedOrdersText = useMemo(() => {
        let text =  " " + t("metrics.generic.orders").toLowerCase()
        if (!data) return text
        const totalOrders = data.reduce((total, item) => total + item.shipped, 0)
        text = numberToString(totalOrders, i18n.language) + text
        return text
    }, [data, i18n.language])

    const cardRef = useRef(null)
    const cardIsOnScreen = useOnScreen(cardRef)
    useEffect(() => {
        if (cardIsOnScreen) window.analytics.track("Metrics - Views ShippedOrders")
    }, [cardIsOnScreen])

    return (
        <BaseCard className="my-6" ref={cardRef}>
            <MetricsCardHeader title={t("metrics.total_orders_historic.title")} />
            <MetricHighlighted
                text={totalShippedOrdersText}
                description={t("metrics.total_orders_historic.highlighted_description")}
                isLoading={isLoading}
                className="mt-4 mb-10"
            />
            <AreaChart
                categories={categories}
                data={formattedData}
                valueFormatter={(n) => numberToString(n, i18n.language)}
                areaChartProps={{
                    showLegend: false,
                    showGridLines: false,
                    curveType: "monotone",
                    // noDataText: 'No hay pedidos enviados en este periodo',
                    className: "h-60",
                }}
                isLoading={isLoading}
            />
        </BaseCard>
    )
}
