
import { useTranslation } from 'react-i18next';
import {SwitchToggleButton} from "../../components/SwitchToggleButton";
import { useContext, useMemo, useState } from 'react';
import { Button } from 'antd';
import { ButtonLoader } from '../../components';
import { UserContext } from '../../hooks/UserContext';
import { MAIL_REGEX } from './constants';


export const TrackingUpdatesNotificationsCard =  ({
    notification,
    disabled,
    loading,
    value,
    handleNotificationToggle = () => {},
    handleSendTestEmail = () => {},
    onValidateEmailError = () => {}
}) => {
    const { i18n } = useTranslation();
    
    const selectedValue = useMemo(() => value, [value])

    const [showTest, setShowTest] = useState(false)
    const context = useContext(UserContext);
    const [testEmail, setTestEmail] = useState(context?.user?.email)
    
    const toggleCurrentValue = (val) => {
        value = val
        handleNotificationToggle(notification, val)
    }
    const toggleTest = () => {
        setShowTest(!showTest)
    }
    
    const sendTestEmail = () => {
        const email = testEmail.trim() || context?.user?.email
        if (!MAIL_REGEX.test(email)) {
            onValidateEmailError(email)
        }else{
            handleSendTestEmail(notification, email)
        }

    }

    return (
        <div className="bg-white rounded-lg border border-gray-199 p-5 py-2">
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="max-w-xl text-sm text-gray-500 mb-1">
                    <h3 className="text-base leading-6 font-medium text-gray-900 mb-0" id="renew-subscription-label">
                        {i18n.t(`settings.tracking_emails.${notification}`)}
                    </h3>
                    {i18n.exists(`settings.tracking_emails.${notification}_description`) &&
                    <span className='text-base'>
                        {i18n.t(`settings.tracking_emails.${notification}_description`)}
                    </span>}
                </div>
                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                    <SwitchToggleButton
                        value={selectedValue}
                        onChange={newValue => toggleCurrentValue(newValue)}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className="mb-2 sm:flex sm:items-start sm:justify-between">
                {!showTest ?
                 <button onClick={toggleTest} className="text-base text-blue-600 hover:text-blue-500 cursor-pointer">{i18n.t("settings.tracking_emails.send_test")}</button>
                :
                <>
                    <div className="flex">
                        <div className="">
                            <input
                                placeholder={context?.user?.email}
                                type="text"
                                name="email"
                                className="appearance-none block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm placeholder-gray-400" 
                                value={testEmail}
                                onChange={(e) => setTestEmail(e.target.value)}
                            />
                        </div>
                        <span>
                            <ButtonLoader className="ml-3" loading_text={i18n.t("settings.tracking_emails.processing")} loading={loading} disabled={disabled}
                                onClick={sendTestEmail}
                            >
                                {i18n.t("settings.tracking_emails.send")}
                            </ButtonLoader>
                        </span>
                    </div>
                </>
                }
            </div>
        </div>
    )
}