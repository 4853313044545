import { useState, useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { DownloadIcon, TrashIcon } from "@heroicons/react/outline"
import { FilePdfOutlined } from '@ant-design/icons'
import { WorkOrderStepCard } from "./components"
import { Button, InputField, TextareaField } from "../../components"
import { UserContext } from "../../hooks/UserContext"
import SectionOption from "./components/SectionOption"
import { Link, useRouteMatch } from "react-router-dom"
import { FileInputField } from "../../components/FileInputField"
import { Loader } from "../../components/Loader"
import { ALL_INVENTORY_AT_WAREHOUSE, CARD_STEPS, WORK_ORDER_TYPES, MATERIAL_IN_TRANSIT_TO_WAREHOUSE, PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE, REQUIRED_MATERIAL, LABELING_PLASTISEAL, PRODUCT_VALIDATION, KIT_ASSEMBLY_SHRINKWRAP, PACKAGING, PRODUCT_SEGREGATION } from "./utils/constants"
import { ADD_ORDER_REMOVE_STOCK, ADD_REPLENISHMENT, STORE_PATH } from "../../navigation/constants"

export const WorkOrderSettings = ({ formState, handleChange }) => {
    const { t, i18n } = useTranslation()
    const { user } = useContext(UserContext)
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })

    const [loadingDocuments, setLoadingDocuments] = useState(false)
    const [fileError, setFilesError] = useState(null)
    const [files, setFiles] = useState([])

    useEffect(() => {
        handleChange('files', files)
    }, [files])

    const filesChangeHandler = (pdfs) => {
        if (pdfs.length > 0) {
            setFilesError(null)
            setFiles([...files, ...pdfs])
        }
    }

    const removeFiles = (id) => {
        setFiles(files.filter((document) => (document.id != id && document.document_id != id)))
    }

    const handleSection = (currentSection, nextSection, key, value) => {
        handleChange(currentSection, CARD_STEPS.COMPLETED)
        handleChange(nextSection, CARD_STEPS.EDITING)
        handleChange(key, value)
    }

    const onEdit = (section) => {
        handleChange(section, CARD_STEPS.EDITING)
    }

    const validateRequiredMaterialSectionButton = () => {
        if (formState.requiredMaterial === MATERIAL_IN_TRANSIT_TO_WAREHOUSE) return false
        else if (formState.requiredMaterial === ALL_INVENTORY_AT_WAREHOUSE || formState.requiredMaterial === PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE)
            if (formState.remOrderId?.length > 0 && formState.remOrderId !== "") return false
        return true
    }

    const buildRequiredMaterialSectionCompletedDescription = () => {
        if (formState.requiredMaterial === PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE)
            return (
                <div>
                    {i18n.t(`work_orders.creation_sections.required_material.product_origin.options.${PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE}.completed_description`)}
                    <b>
                        &nbsp;{i18n.t(`work_orders.creation_sections.required_material.removal_order.removal_order`)} #{formState.remOrderId}
                    </b>
                </div>
            )
        else if (formState.requiredMaterial === ALL_INVENTORY_AT_WAREHOUSE)
            return (
                <div>
                    {i18n.t(`work_orders.creation_sections.required_material.product_origin.options.${ALL_INVENTORY_AT_WAREHOUSE}.completed_description`)}
                    <b>
                        &nbsp;{i18n.t(`work_orders.creation_sections.required_material.removal_order.removal_order`)} #{formState.remOrderId}
                    </b>
                </div>
            )
        return t(`work_orders.creation_sections.required_material.product_origin.options.${MATERIAL_IN_TRANSIT_TO_WAREHOUSE}.completed_description`)
    }

    const validateInstructionsSectionButton = () => {
        if (formState.notes?.length < 1) return true
        else if (formState.notes?.length === 1 && formState?.notes === '') return true
        else if (formState.files?.length < 1) return true
        return false
    }

    const buildInstructionsDownloadLink = () => {
        let filePath = null
        const fileName = i18n.t("work_orders.creation_sections.work_order_instructions.title")
        const instructionsPath = 'https://docs.google.com/document/d/12Z9le6IYeAYjWOqSKTxkqueLG53Cwcwh/edit?usp=sharing&ouid=111763871100195235142&rtpof=true&sd=true'
        const instructionsName = i18n.t("work_orders.creation_sections.work_order_instructions.download_file.instructions_name")
        switch (formState.workOrderType) {
            case LABELING_PLASTISEAL:
                filePath = "https://docs.google.com/spreadsheets/d/1AbMJxknRzZYM8WMPeotHjTqThN3pxx5g/export?format=xlsx"
                break
            case PRODUCT_VALIDATION:
                filePath = "https://docs.google.com/spreadsheets/d/1pejwmwn8_lfcqTxVDJ4O5qN7iQ3DbUWi/export?format=xlsx"
                break
            case KIT_ASSEMBLY_SHRINKWRAP:
                filePath = "https://docs.google.com/spreadsheets/d/1ZjaHV1IDDkGtdmdPh7tiKw04BlW-DUKR/export?format=xlsx"
                break
            case PACKAGING:
                filePath = null
                break
            case PRODUCT_SEGREGATION:
                filePath = "https://docs.google.com/spreadsheets/d/1tPoNrAs5vEnIj6Dd0G0tbcYAVvWwn8is/export?format=xlsx"
            default:
                break
        }
        if (!filePath) return (
            <div className="text-md mt-[3px]">
                <a target="_blank" rel="noopener noreferrer" href={instructionsPath}>
                    {instructionsName} &nbsp;<DownloadIcon className="inline mb-1.5 mr-1 h-4 w-4" aria-hidden="true" />
                </a>
            </div>
        )

        return (
            <div className="text-md mt-[3px]">
                <ul className="list-none">
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href={filePath}>
                            {fileName} &nbsp;<DownloadIcon className="inline mb-1.5 mr-1 h-4 w-4" aria-hidden="true" />
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href={instructionsPath}>
                            {instructionsName} &nbsp;<DownloadIcon className="inline mb-1.5 mr-1 h-4 w-4" aria-hidden="true" />
                        </a>
                    </li>
                </ul>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-7">
            <WorkOrderStepCard
                title={i18n.t("work_orders.creation_sections.work_order_type.title")}
                status={formState.section1}
                onEdit={() => onEdit('section1')}
                completedDescription={i18n.t(`work_orders.creation_sections.work_order_type.select_work_order.options.${formState.workOrderType}.title`)}
            >
                <div className="flex flex-col p-5 gap-3">
                    <div className="mb-1">
                        <div className="font-semibold">
                            <span>{i18n.t("work_orders.creation_sections.work_order_type.select_work_order.title")}</span>
                            <span className="text-red-500">{" *"}</span>
                        </div>
                        <div>
                            <span className="text-sm">{i18n.t("work_orders.creation_sections.work_order_type.select_work_order.description")}</span>
                        </div>
                    </div>
                    <div>
                        {WORK_ORDER_TYPES.map((work_order_type, index) => <div key={index} onClick={() => handleSection('section1', 'section2', 'workOrderType', work_order_type)}> <SectionOption title={i18n.t(`work_orders.creation_sections.work_order_type.select_work_order.options.${work_order_type}.title`)} subtitle={i18n.t(`work_orders.creation_sections.work_order_type.select_work_order.options.${work_order_type}.subtitle`)} /> </div>)}
                    </div>
                </div>
            </WorkOrderStepCard>

            <WorkOrderStepCard
                title={i18n.t("work_orders.creation_sections.required_material.title")}
                status={formState.section2}
                onEdit={() => onEdit('section2')}
                completedDescription={buildRequiredMaterialSectionCompletedDescription()}
            >
                {formState.subsection2_1 === CARD_STEPS.EDITING ? (
                    <div className="flex flex-col p-5 gap-3">
                        <div className="mb-1">
                            <div className="font-semibold">
                                <span>{i18n.t("work_orders.creation_sections.required_material.product_origin.title")}</span>
                                <span className="text-red-500">{" *"}</span>
                            </div>
                            <div>
                                <span className="text-sm">{i18n.t("work_orders.creation_sections.required_material.product_origin.description")}</span>
                            </div>
                        </div>

                        <div>
                            {REQUIRED_MATERIAL.map((material, index) => (
                                <div key={index} onClick={() => handleSection('subsection2_1', 'subsection2_2', 'requiredMaterial', material)}>
                                    <SectionOption title={i18n.t(`work_orders.creation_sections.required_material.product_origin.options.${material}.title`)} />
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col pr-5 pl-5 pt-5 pb-3 gap-3">
                        <div className="mb-1">
                            <div className="font-semibold">
                                <span>{i18n.t("work_orders.creation_sections.required_material.product_origin.title")}</span>
                                <span className="text-red-500">{" *"}</span>
                            </div>
                            <div>
                                <span className="text-sm">{i18n.t("work_orders.creation_sections.required_material.product_origin.description")}</span>
                            </div>
                        </div>

                        <div>
                            <SectionOption title={i18n.t(`work_orders.creation_sections.required_material.product_origin.options.${formState.requiredMaterial}.title`)} status={formState.subsection2_1} onEdit={() => onEdit('subsection2_1')} />
                        </div>
                    </div>
                )
                }

                {(formState.subsection2_2 === CARD_STEPS.EDITING && formState.subsection2_1 !== CARD_STEPS.EDITING) && (formState.requiredMaterial === ALL_INVENTORY_AT_WAREHOUSE || formState.requiredMaterial === PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE) && (
                    <div className="flex flex-col pr-5 pl-5 pb-1 gap-2">
                        <div className="mb-1">
                            <div className="font-semibold">
                                <span>{i18n.t("work_orders.creation_sections.required_material.removal_order.title")}</span>
                                <span className="text-red-500">{" *"}</span>
                            </div>
                            <div>
                                <span className="text-sm">{i18n.t("work_orders.creation_sections.required_material.removal_order.description")}</span>
                            </div>

                            <div>
                                <InputField onChange={(value) => handleChange('remOrderId', value)} value={formState.remOrderId}></InputField>
                                <Link to={storePath + ADD_ORDER_REMOVE_STOCK} className="focus:outline-none" target="_blank">
                                    {i18n.t("work_orders.creation_sections.required_material.removal_order.create_rem_order")}
                                </Link>
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex flex-col pl-5 pr-5 pb-5 pt-2">
                    <Button
                        type="primary"
                        className={`w-full`}
                        onClick={() => handleSection('section2', 'section3')}
                        disabled={validateRequiredMaterialSectionButton()}
                    >
                        {i18n.t("work_orders.creation_sections.required_material.removal_order.continue_button")}
                    </Button>
                </div>
            </WorkOrderStepCard>

            <WorkOrderStepCard
                title={i18n.t("work_orders.creation_sections.work_order_instructions.title")}
                status={formState.section3}
                onEdit={() => onEdit('section3')}
                completedDescription={`${i18n.t("work_orders.creation_sections.work_order_instructions.download_file.completed_description_notes")}: ${formState.notes}`}
            >
                <div className="flex flex-col p-5 gap-3">
                    <div className="mb-1">
                        <div className="font-semibold">
                            {formState.workOrderType === PACKAGING ? (
                                <span>
                                    {i18n.t("work_orders.creation_sections.work_order_instructions.download_file.file_name")}
                                </span>
                            ) : (
                                <span>
                                    {i18n.t("work_orders.creation_sections.work_order_instructions.download_file.title")}
                                </span>
                            )}

                        </div>
                        <div className="mt-1 mb-1">
                            <span className="text-sm">{i18n.t("work_orders.creation_sections.work_order_instructions.download_file.subtitle")}</span>
                        </div>

                        <div className="mt-2">
                            {buildInstructionsDownloadLink()}
                        </div>

                    </div>
                    {/* Upload files*/}
                    <div className="mt-1 mb-1 relative">
                        {loadingDocuments && <Loader show={true} />}
                        <FileInputField
                            label={
                                <div className="font-semibold">
                                    <span>{i18n.t("work_orders.creation_sections.work_order_instructions.download_file.attach_document")}</span>
                                    <span className="text-red-500">{" *"}</span>
                                </div>
                            }
                            onChange={(files) => filesChangeHandler(files)}
                            onError={(error, file, message) => setFilesError(message)}
                            placeholder={i18n.t("work_orders.creation_sections.work_order_instructions.download_file.files_placeholder")}
                            maxFiles={10}
                            accepts={["application/pdf", "text/xml", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                        />
                        {fileError && (
                            <div className="text-red-500 text-center italic mt-2">
                                {fileError}
                            </div>
                        )}
                        <div className="flex flex-wrap justify-center flex-col">
                            {files.map((file, x) => (
                                <div className="mt-3 grid grid-cols-4 border-b border-gray-200" key={String(file.id || file.document_id)}>

                                    <p className="col-span-2"><FilePdfOutlined className="text-center text-2xl w-8" />{file.name || file.filename}</p>
                                    <div onClick={() => removeFiles(file.id || file.document_id)}
                                        className="bg-white color-red cursor-pointer justify-self-center">
                                        <TrashIcon className="h-5 w-5 text-red-500" />
                                    </div>


                                </div>

                            ))}
                        </div>
                    </div>

                    <div className="mt-2 relative">
                        <TextareaField
                            className="sm:col-span-6"
                            label={
                                <div className="font-semibold">
                                    <span>{i18n.t("work_orders.creation_sections.work_order_instructions.download_file.notes")}</span>
                                    <span className="text-red-500">{" *"}</span>
                                </div>
                            }
                            value={formState.notes}
                            onChange={(value) => handleChange('notes', value)}
                            maxlength={200}
                        />
                    </div>

                    <div className="flex flex-col p-5">
                        <Button
                            type="primary"
                            className={`w-full`}
                            onClick={() => handleSection('section3', 'section4')}
                            disabled={validateInstructionsSectionButton()}
                        >
                            {i18n.t("work_orders.creation_sections.work_order_instructions.download_file.continue_button")}
                        </Button>
                    </div>
                </div>
            </WorkOrderStepCard>

            <WorkOrderStepCard
                title={i18n.t("work_orders.creation_sections.work_order_replenishment.title")}
                status={formState.section4}
                onEdit={() => onEdit('section4')}
                completedDescription={
                    <div>
                        {i18n.t("work_orders.creation_sections.work_order_replenishment.replenishment.completed_description_entry")}
                        <b>
                            &nbsp;{i18n.t("work_orders.creation_sections.work_order_replenishment.replenishment.completed_description_replenishment")} #{formState.replenishmentId}
                        </b>
                    </div>
                }
            >

                {formState.section4 === CARD_STEPS.EDITING && (
                    <div className="flex flex-col p-5 gap-2">
                        <div className="mb-1">
                            <div className="font-semibold">
                                <span>{i18n.t("work_orders.creation_sections.work_order_replenishment.replenishment.title")}</span>
                                <span className="text-red-500">{" *"}</span>
                            </div>
                            <div>
                                <span className="text-sm">{i18n.t("work_orders.creation_sections.work_order_replenishment.replenishment.description")}</span>
                            </div>

                            <div>
                                <InputField onChange={(value) => handleChange('replenishmentId', value)} value={formState.replenishmentId}></InputField>
                                <Link to={storePath + ADD_REPLENISHMENT} className="focus:outline-none" target="_blank">
                                    {i18n.t("work_orders.creation_sections.work_order_replenishment.replenishment.create_replenishment")}
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </WorkOrderStepCard>
        </div>
    )
}
