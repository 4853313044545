import { useQuery } from "react-query"
import { MetricPercentage } from "../../../../components/MetricsCards/MetricPercentage"
import { getDockToStockTotal } from "../../../../services/storeMetricsService"
import { useTranslation } from "react-i18next"
import { numberToString } from "../../../../helpers/internationalization"
import { useMemo } from "react"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"
import { DEFAULT_QUERY_STALE_TIME } from "../chartDataUtils"

export const ReplenishmentsWithin24h = ({ storeId = null, dateFilter = null }) => {
    const { i18n, t } = useTranslation()

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["replenishments_within_24h", storeId, dateFilter], () => getDockToStockTotal(storeId, dateFilter), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refech if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    const replenishmentsWithin24h = useMemo(() => {
        if (!data) return 0
        const { in_stock_within_1_day } = data
        return in_stock_within_1_day
    }, [data])

    const replenishmentsAfter24h = useMemo(() => {
        if (!data) return 0
        const { in_stock_more_than_1_day } = data
        return in_stock_more_than_1_day
    }, [data])

    const totalReplenishments = useMemo(() => {
        const total = replenishmentsWithin24h + replenishmentsAfter24h
        return total
    }, [replenishmentsWithin24h, replenishmentsAfter24h])

    return (
        <>
            <MetricTitle title={t("metrics.replenishments_on_time.title")} className={"mb-1"}/>
            <MetricPercentage
                value={replenishmentsWithin24h}
                total={totalReplenishments}
                showLoader={isLoading}
                valueFormatter={(n) => numberToString(n, i18n.language)}
            />
        </>
    )
}
