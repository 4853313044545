import { DialogView, InputField, ConfirmDialog, TextareaField, Button, LocalTable} from "../../../components";
import {Route, Switch, useHistory, useParams, useRouteMatch} from "react-router-dom";
import SelectProductDialog from "../../../components/SelectProductDialog/SelectProductDialog";
import {useContext,useState} from "react";
import {ProductOrderTable} from "./ProductOrderTable";
import {useDispatch, useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import {CanlendlyView} from "./CanlendlyView";
import {SwitchPanel} from "../../../components/SwitchPanel";
import "react-datepicker/dist/react-datepicker.css";
import {UserContext} from "../../../hooks/UserContext";
import { Radio } from 'antd';
import {
    addProducts,
    modifyProduct,   
    setHasOptions,
    productHasOptions,
    deleteProduct,
    setReplenishmentData,
    replenishmentData,
    replenishmentProducts,
    resetData,
    setData
} from "../../../redux/createReplenishmentSlice";
import Product from "../../Inventory/AddProduct/Product";
import {saveReplenishment, fetchReplenishment, updateReplenishment,requestedReplenishments} from "../../../services/replenishmentsServices";
import moment from 'moment';
import {setLogger, useQuery, useQueryClient} from "react-query";
import { sortBy } from "lodash";
import { fetchSearchProducts, fetchStoreSkus } from "../../../services/productServices";
import { FlatfileButton } from "@flatfile/react";
import  i18n from "../../../translations/index";
import { NumericInputField } from "../../../components/NumericInputField";
import { SHOW_REPLENISHMENT_INFO, STORE_PATH} from '../../../navigation/constants';

const primaryButton = {
    backgroundColor: "#327cfb",
    color: "white",
    border: "1px solid #00c6ff",
    padding: "6px 16px",
    fontSize: "15px",
    borderRadius: "8px",
    ":hover": {
      backgroundColor: "#0D93BA",
      border: "1px solid #0D93BA"
    }
}

const shortid = require('shortid')
const optionsWithDisabled = [
    { label: i18n.t("replenishments.addContainer.yes"), value: true },
    { label: i18n.t("replenishments.addContainer.no"), value: false}
  ];
export const AddReplenishmentContainer = ({edit=false, onClose = () => {}}) => {
    
    AddReplenishmentContainer.propTypes = {
        edit: PropTypes.bool
    }
    const {user} = useContext(UserContext)
    const queryClient = useQueryClient()
    const history = useHistory()
    const {id} = useParams()
    const [resp, setResp] = useState(false)
    const { url:storePath } = useRouteMatch({ path: STORE_PATH })
    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])
    const [openDialogProducts, setOpenDialogProducts] = useState(false)
    const [isReplenishmentProductsLoading, setIsReplenishmentProductsLoading] = useState(false)
    const [deletedProducts, setDeletedProducts] = useState([])
    const products = useSelector(replenishmentProducts)
    const hasOptions = useSelector(productHasOptions)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [openCalendly, setOpenCalendly] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [shippingDate, setShippingDate] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const dispatch = useDispatch()
    const replenishment = useSelector(replenishmentData)

    const {
        isLoading,
        isError,
        error,
        refetch,
        isFetchedAfterMount
    } = useQuery(`replenishment-${id}`, ()=>fetchReplenishment(id), {
        
        onSuccess: (info) => {
            if(isFetchedAfterMount===false && edit){
                setEditReplenishmentData(info)
            }
        }
    })


    const setEditReplenishmentData = (info) => {

        let products = info.products.map((item,index) => {
            let found_index = info.replenishment_products.findIndex(rp => rp.product_id == item.id)
            if (found_index === -1){
                console.error(i18n.t("replenishments.addContainer.product_not_found"))
                return
            }
            return {
                id: item.id,
                name: item.name===null? item.product?.name: item.name,
                quantity: item.quantity,
                replenishment_products_id: info.replenishment_products[found_index].id,
                edited: false,
                product_codes: item.product_codes || item.stringified_skus.split(" ").map((cur) => ({'sku': cur})) || [],
                hasError: item.has_missing_info||false,
                error: item.missing_info,
                key: item.id,
                product: item.product,
                parent: item.parent||{},
                isKit: item.product?.is_kit,
                type: Product.getType(item.product?.parent, item.product?.is_kit)
            }
        })

        products = sortBy(products, ['replenishment_products_id'])

        // console.log(products)
        
        dispatch(setData({
            replenishment: {
                shippingDate: info.created_at,
                shippingArrivedDate: info.expected_arrived_at,
                declaredValue: info.declared_value,
                notes: info.notes,
                products_count: info.products_count,
                referenceNumber: "",
                url:info.url_scheduled
            },
            hasOptions:info.sell_ahead||false,
            products: products
        }))
        console.log({
            replenishment: {
                shippingDate: info.created_at,
                shippingArrivedDate: info.expected_arrived_at,
                declaredValue: info.declared_value,
                notes: info.notes,
                products_count: info.products_count,
                referenceNumber: "",
                url:info.u
            },
            hasOptions:info.sell_ahead||false,
            products: products
        })
        //setSaveDisabled(false)
    }
    if(isLoading && edit){
        return <div className="mt-4">Loading</div>
    }
    if(isError && edit){
        return <div className="mt-4">{error.message}</div>
    }

    if(hasOptions && edit){
        return <div className="bg-red-100 text-red-800 text-center text-xl font-style: italic mx-8 h-full mt-64">{i18n.t("replenishments.addContainer.cancel_receipts")}</div>
    }

    const handleOnAddProduct = (addedProducts) => {
        addedProducts = addedProducts.filter((p)=>!products.find((stateProd)=>stateProd.product_id===p.id))
        dispatch(addProducts(
            addedProducts.map(product => {
                let {sku, ...noSkuProduct} = product
            return {
                quantity: product.order_quantity ? parseInt(product.order_quantity) : 1,
                name: product.parentName ? product.parentName.length > 20 ? `${product.name} (${product.parentName.substring(0, 15)}...)` : `${product.name} (${product.parentName})` : `${product.name}`,
                product_id: product.id,
                product: noSkuProduct,
                parent: product.product?.parent,
                product_codes: product.product_codes,
                hasError: false,
                parent: product.parent,
                isKit: product.isKit,
                type: Product.getType(product.parent, product.isKit),//"kit|single|variants",//|,
                key: shortid.generate(),
                
            }
        })))
        setOpenDialogProducts(false)
    }
    
    const handleOnAddProductThroughImport = async (addedRows) => {
        
        setIsReplenishmentProductsLoading(true)

        // addedProducts = addedProducts.filter((p)=>!products.find((stateProd)=>stateProd.product_id===p.id))
        // console.log(addedRows)
        let productsToAdd = []
        let localNotFoundProducts = []

        // let storeProducts = await fetchSearchProducts({
        //     store_id: user.current_store?.id,
        //     skus: addedRows.map(a => a.sku)
        // })
        let skusToFind = addedRows.map(a => a.sku)
        let skusToFindInBatches = []
        while (skusToFind.length > 0) {
            skusToFindInBatches.push(skusToFind.splice(0, 500))
        }
        let storeProducts = []
        for (let i = 0; i < skusToFindInBatches.length; i++) {
            let storeProductsBatch = await fetchSearchProducts(user.current_store?.id, skusToFindInBatches[i])
            storeProducts = [
                ...storeProducts,
                ...storeProductsBatch.products
            ]
        }

        // let storeProducts = await fetchSearchProducts(user.current_store?.id, addedRows.map(a => a.sku))

        // console.log(storeProducts)

        addedRows.forEach((addedRow) => {
            let found = false
            storeProducts.forEach((storeProduct) => { 

                let foundProducts = storeProduct.product_codes.filter(product_code => product_code.sku === addedRow.sku);
                if (foundProducts.length > 0) {
                    storeProduct.order_quantity = addedRow.quantity
                    productsToAdd.push(storeProduct)
                    found = true
                }
            })

            // if (!found)  {
            //     localNotFoundProducts.push(addedRow.sku)
            //     setNotFoundProducts(notFoundProducts => [...notFoundProducts, addedRow.sku]);
            // }
        })

        // productsToAdd = productsToAdd.filter((p)=>!products.find((stateProd)=>stateProd.product_id===p.id))

       
        // console.log(productsToAdd)
        // console.log(localNotFoundProducts)
        // console.log(notFoundProducts)
        handleOnAddProduct(productsToAdd)
        setIsReplenishmentProductsLoading(false)

    }

    const handleSaveReplenishment = async () => {
        setConfirmLoading(false)
        setOpenConfirmDialog(true)
    }
    
    const onConfirmAddRepleshimentHandler = async () => {
        setConfirmLoading(true)
        setErrorMessage(null)

        try {
            if(edit===false) {
                // console.log("saveReplenishment", edit)
                const response = await saveReplenishment(
                    replenishment,
                    products,
                    hasOptions,
                    user.current_store?.id,
                    user.current_store?.warehouses[0].id,
                    "WITHOUT_SCHEDULING"
                )
                // console.log(warehouse.country )
                response.warehouse_id = user.current_store?.warehouses[0].id
                setResp(response)
                //setOpenCalendly(true)
            } else {
                await updateReplenishment(
                    id,
                    replenishment,
                    products,
                    hasOptions,
                    user.current_store?.id,
                    1,
                    deletedProducts
                )
            }
            // await queryClient.invalidateQueries('replenishments')
            await refetch()
            if(edit) {
                // console.log (i18n.t("replenishments.addContainer.closing")) 
                onClose()
            }
        } catch (error) {
            if (error.response?.data?.error) {
                // console.log("api error: ", error)
                setErrorMessage(error.response.data.error)
                console.log("error response: ", error.response.data.error)
            }
            else {
                setErrorMessage(error)
            }
            // console.log("response: ", error.response)
            // window.alert(error)
        }

        setConfirmLoading(false)
        setOpenConfirmDialog(false)
    }

    const handleUpdateQuantity = (index, id, value) => {
        if(value>=1){
            dispatch(modifyProduct({
                ...products[index],
                quantity: value,
                edited: true,
                type:"edit"
            }))
        } else {
            if(edit){
                setDeletedProducts([
                    ...deletedProducts,
                    products[index]
                ])
            }
            if(value<1)
                dispatch(deleteProduct(products[index].key))
        }
    }
    const handleSaveCalendly = async (idReplenishment,data) => {
        try{
            const response = await requestedReplenishments(idReplenishment,data)
        }
        catch{
            setConfirmLoading(false)
            setOpenConfirmDialog(false)
            setOpenCalendly(false)
        }
        
    }

    const saveDisabled = () => {
        if (products.length < 1 || (warehouse.country == "BR" && !replenishment.declaredValue)) {
            return true
        }
        else {
            return false
        }
    }

    const PRODUCTS_RENDERING_LIMIT = 1000

 
    return (
        <div className="space-y-8 divide-y divide-gray-200">
            <ConfirmDialog
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                title= {i18n.t("replenishments.addContainer.receipt")} 
                description= {i18n.t("replenishments.addContainer.keep_receipt")} 
                confirmLabel= {i18n.t("replenishments.addContainer.yes")}
                onConfirm={onConfirmAddRepleshimentHandler}
                loading={confirmLoading}
            />

            
            
            <SelectProductDialog
                open={openDialogProducts}
                setOpen={setOpenDialogProducts}
                onAddProducts={handleOnAddProduct}
                queryExtraParams={{is_kit: false}}
            />
            
            <div className="space-y-8 ">
               
                {(!resp.id)&&
                    <div>
                        <div>
                        
                           <div className="pb-5  sm:flex sm:items-center">
                               {edit ? (
                                    <div>
                                    </div>
                                    ) : (
                                        <> 
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">{i18n.t("replenishments.addContainer.activate_presale")}</h3>
                                            <div className="mt-3 sm:mt-0 sm:ml-4">
                                                <Radio.Group
                                                    size="large"
                                                    options={optionsWithDisabled}
                                                    onChange={(value) => {
                                                        dispatch(setHasOptions(value.target.value))
                                                    }}
                                                    value={hasOptions}
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                />
                                            </div>    
                                        </>
                                    )
                                }
                           </div>
                           <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                               <h3 className="text-lg leading-6 font-medium text-gray-900">{i18n.t("replenishments.addContainer.products")}</h3>
                               
                               <div className="mt-3 sm:mt-0 sm:ml-4">
                               
                        {edit ? 
                        <>
                            {
                                replenishment.products_count <= PRODUCTS_RENDERING_LIMIT &&
                                <>
                                    <button
                                    type="button"
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => setOpenDialogProducts(true)}
                                >
                                       {i18n.t("replenishments.addContainer.add_product")} 
                                </button>
                                </>
                            }
                                
                        </> 
                
                        : 
                        
                        
                        <>
                        <div className="inline-flex">
                        {/* <NuvoImporter
                            licenseKey="LLdW6luF0XAOcpwXYJ7mYT/HP53RbF7WY1OV6NTYpFo="
                            
                            settings={{
                                developerMode: true,
                                modal: true,
                                disableSuccessModal: true,
                                allowManualInput: true,
                                embedUploadArea: false,
                                developerMode: false,
                                identifier: "product_data",
                                columns: [
                                {
                                    label: 'SKU',
                                    key: 'sku',
                                },
                                {
                                    label: i18n.t("replenishments.addContainer.quantity"),
                                    key: 'quantity',
                                },
                                ],
                                style: {
                                    buttons: {
                                      primary: {
                                        backgroundColor: "#327cfb",
                                        color: "white",
                                        border: "1px solid #00c6ff",
                                        padding: "6px 16px",
                                        fontSize: "0.875rem",
                                        borderRadius: "8px",
                                        ":hover": {
                                            backgroundColor: "#0D93BA",
                                            border: "1px solid #0D93BA"
                                        }
                                      },
                                      manualEntry: {
                                        backgroundColor: "white",
                                        borderColor: "#d1d5db",
                                        color: "#404a59",
                                        padding: "6px 16px",
                                        fontSize: "0.875rem",
                                        borderRadius: "5px",
                                        ":hover": {
                                          backgroundColor: "#f9fafb",
                                        }
                                      }
                                    }
                                  }
                            }}
                            onResults={(result) => {
                                console.log("Result:", result)
                                handleOnAddProductThroughImport(result)
                            }}

                            renderUploadButton={({ launch }) => {
                                return (
                                    <button className="bg-white mr-5 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={launch}>{i18n.t("replenishments.addContainer.import")}</button>
                                )
                            }}
                            >
                               {i18n.t("replenishments.addContainer.import")}
                            </NuvoImporter> */}
                            <FlatfileButton
                                licenseKey="8096b433-6f21-43de-ab84-c07472944581"
                                customer={{ userId: "12345" }}
                                settings={{
                                type: "Import Product Replenishments",
                                fields: [
                                    { label: "SKU", key: "sku", validators: [
                                        { validate: "required" },
                                        { validate: "unique" }
                                    ] },
                                    { label: i18n.t("replenishments.addContainer.quantity"), key: "quantity", validators: [
                                        {
                                        validate: "regex_matches",
                                        regex: "^[0-9]+$",
                                        error:
                                            i18n.t("replenishments.addContainer.only_number")
                                        }
                                    ] }
                                ],
                                theme: {
                                    buttons: {
                                        primary: primaryButton
                                    }
                                    // other keys below
                                },
                                managed: true,
                                autoDetectHeaders: true,
                                title: i18n.t("replenishments.addContainer.imports_products"),
                                devMode: false
                                }}
                                
                                onData={async (results) => {
                                // do something with the results
                                // use data instead of alldata for valid data
                                    await handleOnAddProductThroughImport(results.data)
                                return i18n.t("replenishments.addContainer.imported");
                                }}
                                render={(importer, launch) => {
                                    return <button className="bg-white mr-5 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={launch}>{i18n.t("replenishments.addContainer.import")}</button>;
                                }}
                                fieldHooks={{
                                    sku: async (values) => {
                                        let skusToCheck = values.map(a => a[0])
                                        let skusToCheckInBatches = []
                                        while (skusToCheck.length > 0) {
                                            skusToCheckInBatches.push(skusToCheck.splice(0, 500))
                                        }
                                        let storeSkus = {}
                                        for (let i = 0; i < skusToCheckInBatches.length; i++) {
                                            let storeSkusBatch = await fetchStoreSkus(user.current_store?.id, skusToCheckInBatches[i])
                                            storeSkus = {
                                                ...storeSkus,
                                                ...storeSkusBatch
                                            }
                                        }
                    
                                        let rows_to_fix = []
                                        values.forEach(([item, index]) => {

                                            if (!storeSkus.hasOwnProperty(item)) {
                                                rows_to_fix.push([{
                                                    info: [
                                                      {
                                                        message: i18n.t("replenishments.addContainer.skus_doesnt_exist"),
                                                        level: "error"
                                                      }
                                                    ]
                                                  },
                                                  index]
                                                )
                                            }
                                            
                                        })
                                        return rows_to_fix
                                    }
                                }}
                            >
                                Import Contacts
                            </FlatfileButton>
                                <button
                                    type="button"
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => setOpenDialogProducts(true)}
                                >
                                       {i18n.t("replenishments.addContainer.add_product")} 
                                </button>
                            </div>
                        </>
                        
                        }
                        
                               </div>
                           </div>
       
                           {/* <ProductOrderTable products={products} updateData={handleUpdateQuantity}/> */}
                           {!isReplenishmentProductsLoading ?
                                <>
                                {/* {console.log(products)}
                                {console.log(products.length)} */}
                                {products.length >= PRODUCTS_RENDERING_LIMIT || replenishment.products_count >= PRODUCTS_RENDERING_LIMIT ? 
                                    <>
                                       
                                       {edit ?
                                        <>
                                            <div className="">
                                                <div className="mb-5">{i18n.t("replenishments.addContainer.can_not_edit_big_replenishments")}</div>
                                                <Button type="secondary" 
                                                    className=""
                                                    onClick={() => {
                                                        history.push(storePath + SHOW_REPLENISHMENT_INFO.replace(':id', id))
                                                     }}>
                                                    {i18n.t("replenishments.addContainer.view_replenishment_products")}
                                                </Button>
                                           </div>
                                            
                                        </>
                                        :
                                        <>
                                            {i18n.t("replenishments.addContainer.x_number_of_products_imported", {quantity: products.length})}
                                        </>
                                       }
                                       
                                    </>
                                   :
                                   <>
                                       
                                       <ProductOrderTable products={products} updateData={handleUpdateQuantity}/>
                                   </>
                                   }
                                </>
                                :
                                <>
                                   {i18n.t("addProduct.readTable.Loading_products")}
                                </>
                           }
                           
                       </div>
                       
                       <div className="flex items-stretch">
                           <div className="flex-grow">
                                <div className="mt-5 grid grid-cols-2">                                
                                
                                       
                                    {/* <div>
                                            <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                                            {i18n.t("replenishments.addContainer.arrival_date")}
                                            </label>
                                            <div className="mt-1 w-full">
                                                <DatePicker selected={replenishment.shippingArrivedDate!=""?new Date(replenishment.shippingArrivedDate):false} 
                                                onChange={(date) => {
                                                    if(!date){
                                                        date= new Date()
                                                    }
                                                    dispatch(setReplenishmentData({
                                                        ...replenishment,
                                                        shippingArrivedDate: date.toISOString()
                                                        }))
                                                    
                                                    }
                                                } 
                                                className="shadow-sm mb-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />

                                            </div>
                                    </div> */}
                                        
                                  
                                    {(warehouse.country ==='BR') &&
                                    <NumericInputField
                                        title= {i18n.t("replenishments.addContainer.declared_value")} 
                                        value={replenishment.declaredValue}
                                        onChange={(value) => {    
                                            dispatch(setReplenishmentData({
                                                ...replenishment,
                                                declaredValue: value
                                                }))
                                                                       
                                        }} 
                                        
                                        required
                                    />
                                    }
                            
       
                                   <TextareaField
                                       className="sm:col-span-6"
                                       label="Notas"
                                       value={replenishment.notes}
                                       onChange={(value) => {dispatch(setReplenishmentData({
                                       ...replenishment,
                                       notes: value
                                   }))}}
                                   />
                                
                               </div>
                           </div>
                       </div>
                    </div>
                }
                {resp.id &&
                    <CanlendlyView
                        title= {i18n.t("replenishments.addContainer.schedule_an_appointment")} 
                        replesishmentData={resp}
                        country={warehouse.country}
                        user={user}
                        onSave={handleSaveCalendly}
                        onCancel={() =>  onClose()}
                    />
                }
            </div>
            { errorMessage &&
                <div className="text-center text-red-500 pt-5">{errorMessage}</div>
            }
                    
            {!resp.id && 
                <div className="pt-5 pb-5">
                    <div className="flex justify-end">
                    <Button type="secondary" className="w-full" onClick={() => {
                            onClose()
                        }}>{i18n.t("replenishments.addContainer.cancel")}</Button>
                            <Button className="ml-3 w-full" disabled={saveDisabled()} onClick={handleSaveReplenishment}>{i18n.t("replenishments.addContainer.save")}</Button>
                    </div>
                </div>
            }
        </div>
         
    )
}