import { BarList as TremorBarList, Divider, Flex, Text, Title } from "@tremor/react"
import { memo, useMemo, useState } from "react"
import { BarListSkeleton } from "./BarListSkeleton"
import { ShowIfPresent } from "./ShowIfPresent"

/* 
    data = [{
        key?: string;
        value: number;
        name: string;
        icon?: React.JSXElementConstructor<any>;
        href?: string;
        target?: string;
        color?: Color;
    }]
*/

export const BarList = ({
    data = [],
    mainColumnLabel = null,
    secondaryColumnLabel = null,
    valueFormatter = (v) => v,
    barListProps = {},
    isLoading = false,
    sortData = true,
    maxRows = 5,
    maxRowsLabel = "{{maxRowsLabel}}",
    viewOthersLabel = "{{viewOthersLabel}}",
    goBackLabel = "{{goBackLabel}}",
    paged = false
}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const sortedData = useMemo(() => {
        if (!sortData) return data
        return data.sort((a, b) => b.value - a.value)
    }, [data, sortData])

    const sortedDataWithMaxRows = useMemo(() => {
        if (sortedData.length <= maxRows) return sortedData
        const maxRowsStartIndex = (currentPage - 1) * (maxRows - 1)
        let maxRowsEndIndex = maxRowsStartIndex + (maxRows - 1)
        let othersData = sortedData.slice(maxRowsEndIndex)
        if (othersData.length == 1) {
            maxRowsEndIndex++
            othersData = sortedData.slice(maxRowsEndIndex)
        }
        const maxRowsData = sortedData.slice(maxRowsStartIndex, maxRowsEndIndex)
        if (othersData.length == 0) return [...maxRowsData]
        const othersValue = othersData.reduce((acc, item) => acc + item.value, 0)
        const others = {
            key: maxRowsLabel,
            value: othersValue,
            name: maxRowsLabel,
        }
        return [...maxRowsData, others]
    }, [sortedData, maxRows, maxRowsLabel, currentPage])

    const sumOfValues = useMemo(() => {
        return sortedData.reduce((acc, item) => acc + item.value, 0)
    }, [sortedData])

    const formattedData = useMemo(() => {
        return sortedDataWithMaxRows.map((item) => {
            const newItem = { ...item }
            const percentage = sumOfValues != 0 ? (item.value / sumOfValues) * 100 : 0
            newItem.name = <BarListItem label={item.name} percentage={percentage} />
            return newItem
        })
    }, [sortedDataWithMaxRows, sumOfValues])

    const innerValueFormatter = (v) => {
        let formattedValue = valueFormatter(v)
        return <span className="text-gray-900">{formattedValue}</span>
    }

    const changePage = (requestedPage) => {
        if (requestedPage < 1) return
        setCurrentPage(requestedPage)
    }

    const hasPreviousPage = () => currentPage > 1
    const hasNextPage = () => (maxRows - 1) * currentPage < sortedData.length
    const isPageable = () => paged && sortedData.length > maxRows

    return (
        <>
            <ShowIfPresent value={mainColumnLabel || secondaryColumnLabel}>
                <Flex className="mb-0 mt-4">
                    <Text>{mainColumnLabel}</Text>
                    <Text className="text-right">{secondaryColumnLabel}</Text>
                </Flex>
            </ShowIfPresent>
            <Divider className="mt-2 mb-3" />
            <BarListSkeleton active={isLoading} lines={7}>
                <TremorBarList data={formattedData} valueFormatter={(v) => innerValueFormatter(v)} {...barListProps} />
                {isPageable() && <div className="text-sm text-gray-500 text-right">
                    <button disabled={!hasPreviousPage()} onClick={() => changePage(currentPage-1)} className="disabled:opacity-50">{goBackLabel}</button>
                    <button disabled={!hasNextPage()} onClick={() => changePage(currentPage+1)} className="ml-4 disabled:opacity-50">{viewOthersLabel}</button>
                </div>}
            </BarListSkeleton>
        </>
    )
}

const BarListItem = memo(({ label, percentage }) => {
    const formattedPercentage = useMemo(() => {
        if (percentage == 0) return "0%"
        if (percentage < 1) {
            return "<1%"
        }
        return `${Math.round(percentage)}%`
    }, [percentage])

    return (
        <>
            <span className="text-gray-900">{label}</span>
            <span className="ml-2 font-semibold text-gray-900">{formattedPercentage}</span>
        </>
    )
})
