import { useEffect } from "react"
import { useQuery } from "react-query"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"
import { Button, FormattedDate } from "../../components"
import { Loader } from "../../components/Loader"
import { SHOW_ORDERS, STORE_PATH } from "../../navigation/constants"
import { StatusPill } from "../../components/BasicTable/StatusPill"
import { CLAIMS_STATUSES } from "../../components/BasicTable/StatusPillDefinitions"
import { fetchClaim } from "../../services/claimServices"
import { useTranslation } from 'react-i18next';

export const ClaimDetailContainer = () => {
  let { id } = useParams()
  const { t, i18n } = useTranslation(); 
  const { url:storePath } = useRouteMatch({ path: STORE_PATH })
  const history = useHistory()
  const { isLoading, isFetching, isError, error, data, refetch } = useQuery(
    "claim",
    () => fetchClaim(id),
    {
      // refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    window.analytics.page('Claims', 'Single', {id: id})
  }, [])

  return (
    <>
      {isError ? (
        <>Error: {error.message}</>
      ) : (
        <div className="relative">
          <Loader show={isFetching} />
          <div className="">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500"> {i18n.t("claims.detail.status")} </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <StatusPill
                    status={data?.status}
                    statuses={CLAIMS_STATUSES}
                  />
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {i18n.t("claims.detail.resolution")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data?.resolution ? (
                    data.resolution
                  ) : (
                    <span className="italic"> {i18n.t("claims.list.pending")} </span>
                  )}
                </dd>
              </div>
              {data?.status === "ACCEPTED" && (
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    {i18n.t("claims.detail.accepted_values")} 
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    ${data.accepted_value}
                  </dd>
                </div>
              )}
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {i18n.t("claims.detail.orders_number")} 
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  #{data?.order.order_number}{" "}
                  <span
                    onClick={() =>
                      history.push(storePath + SHOW_ORDERS.replace(":id", data?.order.id))
                    }
                    className="hover:underline cursor-pointer text-blue-900 ml-3"
                  >
                    {i18n.t("orders.list.show_orders")}
                  </span>
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                 {i18n.t("claims.detail.value_of_products")} 
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  ${data?.requested_value || data?.value}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                 {i18n.t("claims.list.created_at")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <FormattedDate
                    date={data?.created_at ? data.created_at : null}
                  />
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500"> {i18n.t("claims.detail.dispute")} </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data?.description}
                </dd>
              </div>
              {data?.file_paths?.length > 0 && (
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                   {i18n.t("claims.detail.Compensation_Testing")}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <div className="flex flex-row flex-wrap">
                      {data.file_paths.map((file) => (
                        <a
                          className="h-52 w-52 bg-no-repeat bg-cover bg-center rounded shadow-md m-2"
                          style={{
                            backgroundImage: `url('${
                              process.env.REACT_APP_PLATFORM_URL + file.path
                            }')`,
                          }}
                          href={process.env.REACT_APP_PLATFORM_URL + file.path}
                          target="_blank"
                          key={String(file.path)}
                        ></a>
                      ))}
                    </div>
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </div>
      )}
    </>
  )
}
