import moment from "moment-timezone";

const ruleValidityStartDateBuilder = (startDate, timeZone, buildForPreview=false) => {
    let validityStartDate = null

    if(buildForPreview){
        if(!startDate){
            let parsedTodayStartDate = moment().tz(timeZone)
            parsedTodayStartDate.startOf("day")
            parsedTodayStartDate = parsedTodayStartDate.format("YYYY-MM-DD HH:mm:ss Z")

            validityStartDate = new Date(parsedTodayStartDate)
            validityStartDate = validityStartDate.toISOString()
        } else{
            validityStartDate = startDate.toISOString()
        }
    } else {
        validityStartDate = startDate ? startDate.toISOString() : null
    }

    return validityStartDate
}

export default ruleValidityStartDateBuilder