import { Skeleton } from "antd"
import { ChartColumnIcon } from "../Icons/ChartColumnIcon"

export const ChartSkeleton = ({ children, active }) => {
    if (active) {
        return (
            <div className="relative">
                <div className="opacity-0">{children}</div>
                <Skeleton.Node active className="absolute inset-0 !w-full !h-full" >
                    <ChartColumnIcon className="w-8 h-8 text-gray-300" />
                </Skeleton.Node>
            </div>
        )
    }
    return children
}
