import { ButtonLoader, Notification, InputField, ConfirmDialog, SelectInput} from "../../components"
import PropTypes from "prop-types";
import { Loader } from '../../components/Loader';
import {useState, useContext, componentDidMount, useEffect} from "react";
import {useQuery} from "react-query";
import { UserContext } from "../../hooks/UserContext";
import {useForm} from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { data } from "autoprefixer";
import { editStoreData, fetchStoreInfo} from "../../services/storeServices"
import { LeftCircleOutlined } from '@ant-design/icons';
import {useSelector, useDispatch} from "react-redux";
import {
    setValidSettingsForm
} from '../../redux/validSettingsFormSlice';


export const TaxesSettingsContainer = ({
    onCancel
}) => {


    const [loadingBundleBotton, setLoadingBundleBotton] = useState(false)
    const {register, handleSubmit,formState: { errors }} = useForm({})
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const [changeDetected, setChangeDetected] = useState(false)
    const {user} = useContext(UserContext)
    const {
        isLoading,
        isError,
        error,
        data,
        isPreviousData,
        refetch,
    } = useQuery(['store_info_tax', user.current_store?.id], () => fetchStoreInfo(user.current_store?.id), { keepPreviousData: true })
    const [showNotification, setShowNotification] = useState(false)
    const [title, setTitle] = useState(false)
    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])

    const taxRegimesBR = [
        { id: "SIMPLES_NACIONAL", name: 'SIMPLES NACIONAL'},
        { id: "LUCRO_REAL", name: 'LUCRO REAL' },
        { id: "LUCRO_PRESUMIDO", name: 'LUCRO PRESUMIDO' }
    ];


    const brasilStates = [
       // { id: null, name: <>&nbsp;</>}, //TRADUCIR
        { id: "AC", name: "AC"},
        { id: "AL", name: "AL"},
        { id: "AP", name: "AP"},
        { id: "AM", name: "AM"},
        { id: "BA", name: "BA"},
        { id: "CE", name: "CE"},
        { id: "DF", name: "DF"},
        { id: "ES", name: "ES"},
        { id: "GO", name: "GO"},
        { id: "MA", name: "MA"},
        { id: "MT", name: "MT"},
        { id: "MS", name: "MS"},
        { id: "MG", name: "MG"},
        { id: "PA", name: "PA"},
        { id: "PB", name: "PB"},
        { id: "PR", name: "PR"},
        { id: "PE", name: "PE"},
        { id: "PI", name: "PI"},
        { id: "RJ", name: "RJ"},
        { id: "RN", name: "RN"},
        { id: "RS", name: "RS"},
        { id: "RO", name: "RO"},
        { id: "RR", name: "RR"},
        { id: "SC", name: "SC"},
        { id: "SP", name: "SP"},
        { id: "SE", name: "SE"},
        { id: "TO", name: "TO"},
    ]

    const [taxesData, setTaxesData] = useState({
        tax_company_name: "",
        tax_id: "",
        tax_regime: "",
        tax_state_phone: "",
        tax_address:  "",
        tax_address_neighborhood: "",
        tax_address_number: "",
        tax_city:"",
        state: "",
        tax_state_registration: "",
        tax_zipcode: ""
    })

    const [dialog, setDialog] = useState({
        loading: false,
        open: false,
        title: "",
        description: "",
        errorMessage: "",
        type: null
    })

    const [errorMessage, setErrorMessage] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)

    useEffect(() => {
        if(!data) {
            return
        }
        updateTaxesData(data)
        if(warehouse.country ==='BR' && !data.tax_regime){
            //Initialize tax_regime so form is valid even if dropdown is never clicked
            setTaxesData({
                ...data,
                tax_regime: taxRegimesBR[0].id,
                state: taxRegimesBR[0].id
            })
        }
    }, [data])

    const updateTaxesData = (storeData) => {
        let newTaxesData = {
            tax_company_name: storeData.tax_company_name || "",
            tax_id: storeData.tax_id || "",
            tax_regime: storeData.tax_regime || "",
            tax_state_phone: storeData.tax_state_phone || "",
            tax_address: storeData.tax_address || "",
            tax_address_neighborhood: storeData.tax_address_neighborhood || "",
            tax_address_number: storeData.tax_address_number || "",
            tax_city: storeData.tax_city || "",
            state: storeData.state || "",
            tax_state_registration: storeData.tax_state_registration || "",
            tax_zipcode: storeData.tax_zipcode || ""
        }
        setTaxesData(newTaxesData)
    }

    const checkRequiredFields = () => {
        if (taxesData.tax_company_name && taxesData.tax_company_name.trim() !== '' &&
            taxesData.tax_id && taxesData.tax_id.trim() !== '' &&
            taxesData.tax_regime &&
            taxesData.tax_address && taxesData.tax_address.trim() !== '' &&
            taxesData.tax_city && taxesData.tax_city.trim() !== '' &&
            taxesData.state && taxesData.state.trim() !== '' &&
            taxesData.tax_address_neighborhood && taxesData.tax_address_neighborhood.trim() !== '' &&
            taxesData.tax_address_number && taxesData.tax_address_number.trim() !== '' &&
            taxesData.tax_state_registration && taxesData.tax_state_registration.trim() !== '' &&
            taxesData.tax_zipcode && taxesData.tax_zipcode.trim() !== ''
        )
        {return true}
        return false
    }

    const setValidForm = (edit) => {
        dispatch(setValidSettingsForm({id:"1",change:edit,selectedkeys:["1"] }))
        setChangeDetected(edit)
        setSuccessMessage(false)
    }
    const handleClickOnCancel = () => {
        if (changeDetected && !successMessage){
            setDialog({...dialog, loading: false, open: true})
        }
        else{
            setValidForm(false)
            onCancel()
        }
    }

    const onSubmit = async (dataTax) => {
        setValidForm(false)
        dataTax={
            ...taxesData
        }
        setLoadingBundleBotton(true)
        editStoreData(user.current_store?.id, dataTax).then((resultado)=>{
            setSuccessMessage(i18n.t("settings.tax_fields.success_message"))
            setShowNotification(true)
            setLoadingBundleBotton(false)
        }).catch((e)=>{
            setErrorMessage(i18n.t("settings.tax_fields.error_message"))
            setShowNotification(true)
            setLoadingBundleBotton(false)
        })
        
    }
    const handleClickOnConfirm = () => {
        onCancel()
    }
    
    return (
        <div className='relative p-5'>
            <ConfirmDialog
                open={dialog.open}
                setOpen={(value) => setDialog({...dialog, open: value}) }
                title={i18n.t("settings.validate_changes.title")}
                description={i18n.t("settings.validate_changes.question")}
                confirmLabel="Si"
                onConfirm={handleClickOnConfirm}
                loading={dialog.loading}
            />
        <form action="#" method="PATCH" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-1 ">
                <div className="flex items-center justify-between">
                    <div  className="flex items-center justify-start">
                        {warehouse.country ==='BR' && <LeftCircleOutlined className="text-3xl mr-8 hover:text-indigo-700" onClick={handleClickOnCancel}/>}
                        <div  className="flex-col">
                            <p className='text-xl mb-0 font-bold text-blue-gray-900 mt-5'> {i18n.t("settings.tax_settings.tax_config")} <span className = "text-red-500"> * </span> </p>
                            <p className = "mt-0 text-base text-gray-500"> {i18n.t("settings.tax_settings.description")}</p>

                            {successMessage && <label className = "text-green-500 italic font-normal">{i18n.t("settings.validate_changes.saved_data")}</label>}
                            {changeDetected && !successMessage && <label className = "text-yellow-500 italic font-normal">{i18n.t("settings.validate_changes.unsaved_data")}</label>}
                        </div>
                    </div>
                    <div>
                            <ButtonLoader className="" loading_text={i18n.t("settings.tax_fields.processing")} loading={loadingBundleBotton} disabled={!checkRequiredFields()}>
                                {i18n.t("settings.tax_fields.send_form")}
                            </ButtonLoader>

                            {errorMessage && showNotification? (
                                <>
                                    <Notification show={showNotification} setShow={setShowNotification} type="error" title={i18n.t("settings.user_settings.error")} description={errorMessage}/>
                                </>
                            ) : null}
                            {successMessage && showNotification? (
                                <>
                                    <Notification show={showNotification} setShow={setShowNotification} type="success" title={i18n.t("settings.user_settings.done")} description={successMessage}/>
                                </>
                            ) : null}
                    </div>
                </div>
            </div>
            <div>
            
            <div className="grid grid-row">
                <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-6 mb-8 w-full">
                {
                    !isPreviousData && isLoading && !data ? (
                        <><Loader show={true}></Loader></>
                    ): isError ? (
                        <>Error: {error.message}</>
                    ):
                    (
                        <>
                            <div className="sm:col-span-3">
                                <InputField
                                    title= {i18n.t("settings.tax_fields.tax_company_name")}
                                    value={taxesData.tax_company_name}
                                    onChange={(v) => {
                                        setValidForm(true)
                                        setTaxesData({
                                            ...taxesData,
                                            tax_company_name: v
                                        })
                                    }}
                                    required
                                />

                                <p className="text-sm text-red-600">{taxesData.tax_company_name === '' && i18n.t("orders.addresView.required_field")}</p>

                            </div>

                            <div className="sm:col-span-3">
                                <InputField
                                    title= {i18n.t("settings.tax_fields.tax_id")} 
                                    value={taxesData.tax_id}
                                    onChange={(v) => {
                                        setValidForm(true)
                                        setTaxesData({
                                            ...taxesData,
                                            tax_id: v
                                        })
                                    }}
                                    required
                                />

                                <p className="text-sm text-red-600">{taxesData.tax_id === '' && i18n.t("orders.addresView.required_field")}</p>

                            </div>

                            {(warehouse.country ==='BR') ?
                                <>
                                    <SelectInput
                                    options={taxRegimesBR}
                                    selectedInitial={taxRegimesBR.find(option => option.id === taxesData.tax_regime) ?? taxRegimesBR[0]}
                                    className="sm:col-span-3"
                                    label= {i18n.t("settings.tax_fields.tax_regime")} 
                                    onChange={(tax_regime) => {
                                        setValidForm(true)
                                        setTaxesData({
                                            ...taxesData,
                                            tax_regime: tax_regime.id
                                        })
                                    }}
                                    value={taxRegimesBR.find(option => option.id === taxesData.tax_regime) ?? taxRegimesBR[0]}
                                    />
                                </>
                                :
                                <div className="sm:col-span-3">
                                    <InputField
                                        title= {i18n.t("settings.tax_fields.tax_regime")}
                                        value={taxesData.tax_regime}
                                        onChange={(v) => {
                                            setValidForm(true)
                                            setTaxesData({
                                                ...taxesData,
                                                tax_regime: v
                                            })
                                        }}
                                        required
                                    />

                                    <p className="text-sm text-red-600">{taxesData.tax_regime === '' && i18n.t("orders.addresView.required_field")}</p>

                                </div>

                            }

                            <div className="sm:col-span-3">
                                <InputField
                                    title= {i18n.t("settings.tax_fields.tax_state_registration")} 
                                    value={taxesData.tax_state_registration}
                                    onChange={(v) => {
                                        setValidForm(true)
                                        setTaxesData({
                                            ...taxesData,
                                            tax_state_registration: v
                                        })
                                    }}
                                    required
                                />

                                <p className="text-sm text-red-600">{taxesData.tax_state_registration === '' && i18n.t("orders.addresView.required_field")}</p>

                            </div>

        

                            <div className="sm:col-span-6">
                                <InputField
                                    title= {i18n.t("settings.tax_fields.tax_address")} 
                                    value={taxesData.tax_address}
                                    onChange={(v) => {
                                        setValidForm(true)
                                        setTaxesData({
                                            ...taxesData,
                                            tax_address: v
                                        })
                                    }}
                                    required
                                />

                                <p className="text-sm text-red-600">{taxesData.tax_address === '' && i18n.t("orders.addresView.required_field")}</p>

                            </div>

                            <div className="sm:col-span-3">
                                <InputField
                                    title= {i18n.t("settings.tax_fields.tax_address_neighborhood")} 
                                    value={taxesData.tax_address_neighborhood}
                                    onChange={(v) => {
                                        setValidForm(true)
                                        setTaxesData({
                                            ...taxesData,
                                            tax_address_neighborhood: v
                                        })
                                    }}
                                    required
                                />

                                <p className="text-sm text-red-600">{taxesData.tax_address_neighborhood === '' && i18n.t("orders.addresView.required_field")}</p>

                            </div>

                            <div className="sm:col-span-3">
                                <InputField
                                    title= {i18n.t("settings.tax_fields.tax_address_number")} 
                                    value={taxesData.tax_address_number}
                                    onChange={(v) => {
                                        setValidForm(true)
                                        setTaxesData({
                                            ...taxesData,
                                            tax_address_number: v
                                        })
                                    }}
                                    required
                                />

                                <p className="text-sm text-red-600">{taxesData.tax_address_number === '' && i18n.t("orders.addresView.required_field")}</p>

                            </div>

                            <div className="sm:col-span-3">
                                <InputField
                                    title= {i18n.t("settings.tax_fields.tax_city")}
                                    value={taxesData.tax_city}
                                    onChange={(v) => {
                                        setValidForm(true)
                                        setTaxesData({
                                            ...taxesData,
                                            tax_city: v
                                        })
                                    }}
                                    required
                                />

                                <p className="text-sm text-red-600">{taxesData.tax_city === '' && i18n.t("orders.addresView.required_field")}</p>

                            </div>


                            <div className="sm:col-span-3">
                            {(warehouse.country ==='BR') ? (
                                <SelectInput
                                    options={brasilStates}
                                    selectedInitial={brasilStates.find(option => option.id === taxesData.state)?? brasilStates[0]}
                                    className="sm:col-span-3"
                                    label= {i18n.t("orders.addresView.state")} 
                                    value={brasilStates.find(option => option.id === taxesData.state)?? brasilStates[0]}
                                    onChange={(province) => {
                                        setValidForm(true)
                                        setTaxesData({
                                            ...taxesData,
                                            state: province.id
                                        })
                                    }}
                                    required
                                />
                            ):(

                                <InputField
                                    title= {i18n.t("settings.tax_fields.state")}
                                    value={taxesData.state}
                                    onChange={(v) => {
                                        setValidForm(true)
                                        setTaxesData({
                                            ...taxesData,
                                            state: v
                                        })
                                    }}
                                    required
                                /> 
                            )}

                                <p className="text-sm text-red-600">{taxesData.state === '' && i18n.t("orders.addresView.required_field")}</p>

                            </div>

                            <InputField
                                className="sm:col-span-3"
                                title={i18n.t("settings.tax_fields.tax_state_phone")} 
                                value={taxesData.tax_state_phone}
                                onChange={(v) => {
                                    setValidForm(true)
                                    setTaxesData({
                                        ...taxesData,
                                        tax_state_phone: v
                                    })
                                }}
                                required
                            />
                            

                            <div className="sm:col-span-3">
                                <InputField
                                    title= {i18n.t("settings.tax_fields.tax_zipcode")}  
                                    value={taxesData.tax_zipcode}
                                    onChange={(v) => {
                                        setValidForm(true)
                                        setTaxesData({
                                            ...taxesData,
                                            tax_zipcode: v
                                        })
                                    }}
                                    required
                                />

                                <p className="text-sm text-red-600">{taxesData.tax_zipcode === '' && i18n.t("orders.addresView.required_field")}</p>

                            </div>
                        </>
                    )
                }
                </div>
                </div>
                <div className="flex items-center justify-between">
                    <div  className="flex items-center justify-start">
                    </div>
                        <div>
                            <ButtonLoader className="" loading_text={i18n.t("settings.tax_fields.processing")} loading={loadingBundleBotton} disabled={!checkRequiredFields()}>
                                {i18n.t("settings.tax_fields.send_form")}
                            </ButtonLoader>

                            {errorMessage && showNotification? (
                                <>
                                    <Notification show={showNotification} setShow={setShowNotification} type="error" title={i18n.t("settings.user_settings.error")} description={errorMessage}/>
                                </>
                            ) : null}
                            {successMessage && showNotification? (
                                <>
                                    <Notification show={showNotification} setShow={setShowNotification} type="success" title={i18n.t("settings.user_settings.done")} description={successMessage}/>
                                </>
                            ) : null}
                        </div>
                </div>
                  
                
           </div>
        </form>
    </div>

    )

}