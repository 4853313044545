export const CheckBox = () => {
    return (
        <div className="flex items-center h-5">
            <input
                id="comments"
                name="comments"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
        </div>
    )
}