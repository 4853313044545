import ReactTagInput from "@pathofdev/react-tag-input";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {deleteOption, modifyOption} from "../../../redux/createProductSlice";
import {Controller} from "react-hook-form";
import { useTranslation } from 'react-i18next';

export const OptionRow = ({option, control, errors, setValue}) => {

    OptionRow.propTypes = {
        option: PropTypes.shape({
            key: PropTypes.string.isRequired,
            tags: PropTypes.arrayOf(PropTypes.string).isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired
    }

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation(); 
    // const [isValid, setIsValid] = useState(false)

    const onDeleteHandler = () => {
        dispatch(deleteOption(option.key))
    }

    const onChangeTagsHandler = (tags) => {
        const isValid = optionIsValid(tags, option.name)
        setValue(`${option.key}.tags`, tags)
        dispatch(modifyOption({
            ...option,
            tags: tags,
            isValid: isValid
        }))
    }

    const onChangeTitleHandler = (event) => {
        const isValid = optionIsValid(option.tags, event.target.value)
        setValue(`${option.key}.name`, event.target.value)
        dispatch(modifyOption({
            ...option,
            name: event.target.value,
            isValid: isValid
        }))
        // event: "OptionNameChanged"
        // dispatch()
    }

    const optionIsValid = (tags, name) => {
        if(name!=="" && tags.length>0){
            return true
        }
        return false
    }

    /*useEffect(() => {
        if(optionIsValid()){
            console.log("Option", option)
        } else {
            console.log("Option false")
        }
    }, [option])*/

    return (
        <div className="py-4 flex">
            <div className="w-2/12">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    {i18n.t("addProduct.optionRow.option")} 
                </label>
                <div className="mt-1">
                    <Controller
                        name={`${option.key}.name`}
                        control={control}
                        rules={{ required: true }}
                        render={({field})=><div>
                            <input
                                //{...field}
                                type="text"
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder= {i18n.t("addProduct.optionRow.size")} 
                                onChange={onChangeTitleHandler}
                                value={option.name}
                            />
                            <p className="mt-2 text-sm text-red-600">
                                {(errors[option.key]?.name && option.name=="") && i18n.t("addProduct.optionRow.required_field")}
                            </p>
                        </div>}
                    />
                </div>
            </div>
            <div className="ml-3 flex-grow">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 flex flex-row-reverse">
                    <a className="cursor-pointer text-indigo-600" onClick={onDeleteHandler}>{i18n.t("addProduct.optionRow.delete")}</a>
                </label>
                <div className="mt-1">
                    <Controller
                        name={`${option.key}.tags`}
                        control={control}
                        rules={{ required: true }}
                        render={({field})=><div>
                            <ReactTagInput
                                {...field}
                                tags={option.tags}
                                onChange={(tags) => onChangeTagsHandler(tags)}
                                removeOnBackspace={true}
                                placeholder={i18n.t("addProduct.optionRow.press_enter")} 
                            />
                            <p className="mt-2 text-sm text-red-600">
                                {(errors[option.key]?.tags && option.tags.length==0) && i18n.t("addProduct.optionRow.required_field")}
                            </p>
                        </div>}
                    />
                </div>
            </div>
        </div>
    )
}