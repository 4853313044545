import {BasicTable, InputField} from "../../../components";
import {EditableCell} from "../../../components/BasicTable/EditableCell";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext } from "react";
import { InputNumber } from "antd";
import { UserContext } from "../../../hooks/UserContext";
import { CurrencyInputField } from "../../../components/CurrencyInputField";
import { TagIcon } from "@heroicons/react/outline";


export const ProductKitsTable = ({products, updateData=null, updatePrice=null}) => {
    
    const { t, i18n} = useTranslation();
    const {user} = useContext(UserContext)
    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])

    const columns = [
        {
            Header: "addProduct.kitsTable.name",
            accessor: 'name'
        },
        {
            Header: "addProduct.kitsTable.sku",
            accessor: 'sku'
        },
        {
            Header: "addProduct.kitsTable.quantity",
            accessor: 'quantity',
            Cell: EditableCell,
            shrinkToContent: true
        },
        {
            Header: "addProduct.kitsTable.price_per_item",
            accessor: 'price',
            shrinkToContent: true
        }
    ]

    if(warehouse.country !== 'BR')  {
        columns.splice(columns.length -1, 1);
    }

    return (
        <BasicTable
            columns={columns}
            data={products.map((product, i) => {
                const isPriceDisabled = !product?.is_scannable

                return {
                    name: product.name,
                    sku: <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-80">{product.sku}</span>,
                    quantity: product.quantity,
                    price:
                    (warehouse.country === 'BR') ?
                        <div className="m-2 h-10">
                            <CurrencyInputField 
                            icon= {<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>}
                            min={0} 
                            value={product.price} 
                            onChange={(v) => {
                                updatePrice(i, v);
                            }} 
                            required
                            minInputValue={0.0}
                            permanentError={true}
                            customMinInputMessage={" "}
                            isDisabled={isPriceDisabled}
                            inputClassName={isPriceDisabled ? "shadow-none border-0 bg-transparent" : ""}
                            keyboard={true}/> 
                        </div>
                    :
                    "",
                }
            })}
            selectable={false}
            editable={true}
            updateData={updateData}
            emptyTableText= {i18n.t("addProduct.kitsTable.no_products_added")} 
            showHeader
        />
    )
}