import { useQuery, useQueryClient } from "react-query"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"
import { Loader } from "../../components/Loader"
import { STORE_PATH } from "../../navigation/constants"
import { useState, useContext } from "react"
import { UserContext } from "../../hooks/UserContext";
import  i18n from "../../translations/index";
import { fetchCnab, uploadCnabPaymentReceipt } from "../../services/cnabServices"
import { FileInputField, fileToBase64 } from "../../components/FileInputField"
import {FilePdfOutlined} from '@ant-design/icons';
import { TrashIcon } from "@heroicons/react/outline"
import { FormattedDate } from "../../components"
import NumberFormat from "react-number-format"


export const ShowCnabContainer = () => {
  let { id } = useParams()
  const { url:storePath } = useRouteMatch({ path: STORE_PATH })
  const history = useHistory()
  const queryClient = useQueryClient()

  const [fileError, setFilesError] = useState(null)
  const [files, setFiles] = useState([])


  const { isLoading, isFetching, isError, error, data, refetch } = useQuery(
    ["cnab_detail", id],
    () => fetchCnab(id)
  )
  const { user } = useContext(UserContext)

  const cnabInfoColumns = [
    [i18n.t("cnabs.showContainer.reference_id")],
    [i18n.t("cnabs.showContainer.total_amount")],
    [i18n.t("cnabs.showContainer.num_of_items")], 
    [i18n.t("cnabs.showContainer.created_at")]
  ]

  const filesChangeHandler = async (pdfs) => {

    if (pdfs.length == 1) {
        setFilesError(null)
        setFiles([...files, ...pdfs])
        let base64_data = await fileToBase64(pdfs[0])
        try {
          await uploadCnabPaymentReceipt(id, base64_data)
          refetch()
        } catch (error) {
          console.log(error)
          window.alert(error.response?.data?.error)
        }
    }
  //console.log("files:", files)
  }

  const removeFiles = (id) => {
      setFiles(files.filter((document) => (document.id != id && document.document_id != id)))
  }

  return (
    <>
      {isError ? (
        <>Error: {error.message}</>
      ) : (
        <div className="relative">
          <Loader show={isLoading} />
          <div className="flex flex-col gap-8 pt-0">
            <section id="section-general-info">
              <h2 className="text-gray-800 font-semibold text-lg m-0 mb-3">{i18n.t("cnabs.showContainer.cnab_information")}</h2>
              <div className="border rounded-lg border-gray-200">
                <table className="relative w-full table-fixed">
                  <thead>
                    <tr >{cnabInfoColumns.map( (header, index) => (
                      <th>
                        <div className="p-3">
                            <p className="text-gray-800 font-medium text-base m-0 text-left">{header[0]}</p>
                            {(index === 0 || index === 1) && (
                              <p className="text-gray-600 font-normal text-xs m-0 text-left">{header[1]}</p>
                            )}
                        </div>
                      </th>
                    ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-0 text-left border-t border-r border-gray-200">
                          <div className="flex justify-center flex-col p-3">
                            <p className="text-gray-700 font-normal text-base m-0">
                                {"#" + data?.cnab.reference_id}
                            </p>
                          </div>
                      </td>
                      <td className="p-0 border-t border-r border-gray-200">
                          <div className="flex justify-center flex-col text-left p-3">
                            <p className="text-gray-700 font-normal text-base m-0">
                              <NumberFormat value={data?.cnab.total_amount} displayType={'text'} thousandSeparator={true} prefix={'R$'} decimalScale={2} fixedDecimalScale={2}/>
                            </p>
                          </div>
                      </td>

                      <td className="p-0 border-t border-r border-gray-200">
                          <div className="flex justify-center flex-col text-left p-3">
                            <p className="text-gray-700 font-normal text-base m-0">
                              {data?.cnab.num_of_items}
                            </p>
                          </div>
                      </td>

                      <td className="p-0 border-t border-gray-200">
                        <div className="flex justify-center flex-col text-left p-3">
                            <p className="text-gray-700 font-normal text-base m-0">
                              <FormattedDate date={data?.cnab.created_at} shortDate={true} />
                            </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section id="section-attached-files">
              <h3 className="text-lg leading-6 font-medium text-gray-900">{i18n.t("cnabs.showContainer.payment_bill")}</h3>
              <table className="relative w-full table-fixed">
                <thead>
                  <tr>
                    <th><p className="text-base text-gray-800 font-medium m-0 text-left pb-3">{i18n.t("orders.showContainer.file_name")}</p></th>
                    <th><p className="text-base text-gray-800 font-medium m-0 text-left pb-3 pl-3">{i18n.t("orders.showContainer.printing_type")}</p></th>
                    <th><p className="text-base text-gray-800 font-medium m-0 text-left pb-3 pl-3">{i18n.t("orders.showContainer.actions")}</p></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.cnab?.files?.map((document, index, docsArray) => (
                    <tr>
                      <td className="p-0">
                          <div className={`border-t border-l border-gray-200 ${index === 0 ? "rounded-tl-lg" : ""} ${index === docsArray.length - 1 ? "border-b rounded-bl-lg" : ""}`}>
                              <p className="text-base font-normal text-gray-600 m-0 p-3">{document.filename}</p>
                          </div>
                      </td>
                      <td className="p-0">
                          <div className={`border-t border-gray-200 ${index === docsArray.length - 1 ? "border-b" : ""}`}>
                              <p className="text-base font-normal text-gray-600 m-0 p-3">
                              {document.filename.startsWith('receipt') ? i18n.t("cnabs.showContainer.return_type") : i18n.t("cnabs.showContainer.replenishment_type")}</p>
                          </div>
                      </td>
                      <td className="p-0">
                          <div className={`border-t border-r border-gray-200 ${index === 0 ? "rounded-tr-lg" : ""} ${index === docsArray.length - 1 ? "border-b rounded-br-lg" : ""}`}>
                            <p className="text-base font-normal m-0 p-3">
                              <a
                                  rel="noopener noreferrer"
                                  href={process.env.REACT_APP_PLATFORM_URL + document.path}
                                  target="_blank"
                              >{i18n.t("orders.showContainer.download")}
                              </a>
                            </p>
                          </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
            <section id="section-attach-file">
              <h3 className="text-lg leading-6 font-medium text-gray-900">{i18n.t("cnabs.showContainer.payment_receipt")}</h3>
              <p className="mt-1 text-gray-600 text-base">{i18n.t("cnabs.showContainer.payment_receipt_description")}</p>

              <div className="mt-4 relative">
                {files?.length == 0 ? <>
                  <FileInputField
                    maxFiles={1}
                    onChange={(files) => filesChangeHandler(files)}
                    onError={(error, file, message) => setFilesError(message)}
                    accepts={["text/plain"]}
                  />
                  {fileError && (
                    <div className="text-red-500 text-center italic mt-2">
                        {fileError}
                    </div>
                  )} </> : 
                  <div className="flex flex-wrap justify-center flex-col">
                    {files.map((file,x) => (
                      <div className="py-3 items-center grid grid-cols-4 border-b border-gray-200" key={String(file.id || file.document_id)}>
                          <p className="col-span-2"><FilePdfOutlined className="text-center text-2xl w-8"/>{file.name || file.filename}</p>
                          <div onClick={() => removeFiles(file.id || file.document_id)}
                            className="bg-white color-red cursor-pointer justify-self-center">
                            <TrashIcon className="h-5 w-5 text-red-500" />
                          </div>
                      </div>
                    ))}
                  </div>
                }
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  )
};