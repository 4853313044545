import { Tab } from '@headlessui/react'
import { useState, useEffect} from 'react';


export const Tabs = ({
    options = [], 
    vertical = false,
    defaultTabIndex = 0,
    tabChanged = () => {}
}) => {

    const [tabsOptions, setTabsOptions] = useState([])
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)
    
    useEffect(() => {
        setTabsOptions(options)
      }, [options])
    useEffect(() => {
        setSelectedTabIndex(defaultTabIndex)
    }, [defaultTabIndex])
    
    const tabClicked = (index) => {
        setSelectedTabIndex(index)
        tabChanged(index)
    }
    return (
        <>
        <div className='w-full'>
            <Tab.Group vertical={vertical} as={vertical ? 'div' : 'Fragment' } className={ vertical ? 'flex' : 'flex-col'} selectedIndex={selectedTabIndex}>
                <Tab.List className={vertical ? "flex flex-col border" : ''}>
                    {tabsOptions.length > 0  && tabsOptions.map((option, index) => {
                        return (
                        <Tab
                            key={index}
                            className='w-full'
                            onClick={() => tabClicked(index)}
                        >
                            {({ selected }) => (
                                option.tabTitle(index, selected)
                            )}
                        </Tab>
                        
                        )
                    }) }
                </Tab.List>
                <Tab.Panels className='w-full mr-5'>
                    {tabsOptions.length > 0 && tabsOptions.map((option,index) => {
                        return (
                            <div className='w-full'>
                                <Tab.Panel
                                    key={ index} 
                                    className= 'ml-5'
                                >
                                    {option.content}
                                </Tab.Panel>
                            </div>
                        )
                    })}
                </Tab.Panels>
            </Tab.Group>
        </div>
        </>
    )
}