const localeByLanguage = {
    es: 'es-MX',
    en: 'en-US',
    pt: 'pt-BR'
}

export const numberToString = (number, languageCode) => {
    if (!number && number !== 0) return ""
    if (isNaN(number)) return number
    const locale = localeByLanguage[languageCode]
    return number?.toLocaleString(locale)
    /*
    if (!number && number !== 0) return ""
    if (isNaN(number)) return number
    // To do: improve figure out country, 
    // for now using mx and changing the language works in almost all cases
    const country = "mx"
    const locale = `${languageCode}-${country}`
    return Intl.NumberFormat(locale).format(number).toString()*/
}

export const formatMoney = (money, languageCode, currencyCode, minimumFractionDigits=2, maximumFractionDigits=2, removeFractionOnZero=false) => {
    let roundedMoney = parseFloat(money).toFixed(maximumFractionDigits)
    roundedMoney = parseFloat(roundedMoney)

    const localeOptions = {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits:  minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits
    }

    if(removeFractionOnZero && roundedMoney === 0){
        localeOptions.minimumFractionDigits = 0
    }

    const locale = localeByLanguage[languageCode]
    const formattedMoney = roundedMoney.toLocaleString(locale, {...localeOptions})

    return formattedMoney
}
