import { Grid, Col } from "@tremor/react"
import { BaseCard } from "../../../../components/MetricsCards/BaseCard"
import { MetricsCardHeader } from "../../../../components/MetricsCards/MetricsCardHeader"
import { StockPerOrderHistoric } from "./StockPerOrderHistoric"
import { ShippedStockHistoric } from "./ShippedStockHistoric"
import { StockPerOrder } from "./StockPerOrder"
import { useTranslation } from "react-i18next"
import { MetricHighlighted } from "../../../../components/MetricsCards/MetricHighlighted"
import { INTERVAL_DAY } from "../chartDataUtils"
import { useEffect, useMemo, useRef, useState } from "react"
import { numberToString } from "../../../../helpers/internationalization"
import useOnScreen from "../../../../hooks/useOnScreen"

export const StockSold = ({ storeId = null, dateFilter = null, interval = INTERVAL_DAY }) => {
    const { i18n, t } = useTranslation()

    const [totalStockSold, setTotalStockSold] = useState(null)
    const [averageStockPerOrder, setAverageStockPerOrder] = useState(null)
    const [isLoadingTotalStockSold, setIsLoadingTotalStockSold] = useState(false)
    const [isLoadingAverageStockPerOrder, setIsLoadingAverageStockPerOrder] = useState(false)

    const cardRef = useRef(null)
    const cardIsOnScreen = useOnScreen(cardRef)
    useEffect(() => {
      if (cardIsOnScreen) window.analytics.track("Metrics - Views StockSold")
    }, [cardIsOnScreen])

    const totalStockPerOrderText = useMemo(() => {
        let text =  " " + t("metrics.generic.items").toLowerCase()
        if (!totalStockSold) return text
        text = numberToString(totalStockSold || 0, i18n.language) + text
        return text
    }, [totalStockSold, i18n.language])

    const averageStockPerOrderText = useMemo(() => {
        let text =  " " + t("metrics.generic.items").toLowerCase()
        if (!averageStockPerOrder) return text
        text = numberToString(averageStockPerOrder || 0, i18n.language) + text
        return text
    }, [averageStockPerOrder, i18n.language])

    return (
        <BaseCard className="my-6" ref={cardRef}>
            <MetricsCardHeader title={t("metrics.stock_sold.title")} subtitle={t("metrics.stock_sold.subtitle")} />
            <MetricHighlighted
                text={totalStockPerOrderText}
                description={t("metrics.stock_sold.total_stock_sold.description")}
                isLoading={isLoadingTotalStockSold}
                className="mt-4 mb-10"
            />
            <ShippedStockHistoric
                storeId={storeId}
                dateFilter={dateFilter}
                interval={interval}
                setIsLoading={(value) => setIsLoadingTotalStockSold(value)}
                calculateTotalStockSold={(value) => setTotalStockSold(value)}
            />
            <Grid numItemsSm={1} numItemsLg={3} className="w-full gap-10 lg:gap-x-20 py-5">
                <Col numColSpan={1} numColSpanLg={3}>
                    <Grid numItemsSm={1} numItemsLg={3} className="w-full lg:gap-x-20">
                        <Col numColSpan={1} numColSpanLg={3}>
                            <MetricHighlighted
                                text={averageStockPerOrderText}
                                description={t("metrics.stock_sold.stock_per_order_average.description")}
                                isLoading={isLoadingAverageStockPerOrder}
                                className="mt-12 mb-4"
                            />
                        </Col>
                        <StockPerOrder
                            storeId={storeId}
                            dateFilter={dateFilter}
                            setIsLoading={(value) => setIsLoadingAverageStockPerOrder(value)}
                            calculateAverageStockPerOrder={(value) => setAverageStockPerOrder(value)}
                        />
                        <Col className="mt-10 lg:mt-0" numColSpan={1} numColSpanLg={2}>
                            <StockPerOrderHistoric storeId={storeId} dateFilter={dateFilter} interval={interval} />
                        </Col>
                    </Grid>
                </Col>
            </Grid>
        </BaseCard>
    )
}
