import axiosInstance from "../helpers/axiosInstance";


export const fetchStores = async (params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores`, { params })
    return res.data
}

export const fetchStoreAccounts = async (id, params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${id}/user_accounts`, { params })
    return res.data
}

export const fetchStoreBusinessRules = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store_business_rules/index`, { params })
    return res.data
}

export const updateBusinessRule = async (params) => {
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store_business_rules/update`, params )
    return res.data
}

export const createBusinessRule = async (params) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store_business_rules`, params )
    return res.data
}

export const fetchHomeSummary = async (id, params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${id}/home-summary`, { params })
    return res.data
}

export const fetchOrdersSummary = async (id, params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${id}/orders-summary`, { params })
    return res.data
}

export const fetchStore = async (id, params = {}) => {
    if(id==null)
        return null
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${id}`, {params})
    return res.data
}

export const assignShippingBundles = async (id, bundleName) => {
    if(id==null)
        return null
        
    const data = {
        bundle_name: bundleName
    }

    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${id}/assign-shipping-bundles`, data)
    return res.data
}

export const fetchStorePackingOptions = async(id) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${id}/packing_options`)

    return response.data
}

export const updatePackingOptions = async(id, packingOptions) => {
    const response = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${id}/packing_options`, packingOptions)

    return response.data
}

export const fetchStoreInfo = async (id, data) => {

    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${id}`, data)
    return res.data
}

export const editStoreData = async (id, data) => {

    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${id}`, data)
    return res.data
}

export const updateUnpaidOrderCancellationSettings = async function (store_id, { isActive, paymentWindow, paymentWindowUnit }) {
    const body = {
        is_active: isActive,
        payment_window: paymentWindow,
        payment_window_unit: paymentWindowUnit
    }

    return await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${store_id}/configurations/unpaid-orders-cancellation`, body).data
}

export const updateCarrierReturnsCancellationSettings = async function (store_id, { isActive, reviewWindow }, immediately_str) {
    const body = {
        is_active: isActive,
        review_window: reviewWindow === immediately_str ? 0 : reviewWindow,
    }

    return await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${store_id}/configurations/carrier-returns-cancellation`, body).data
}

export const updateTrackingUpdatesEmailNotificationSettings = async function (store_id, config) {
    const body = {config: config}
    return await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${store_id}/configurations/tracking-updates-email-notification`, body).data
}

export const getConfigurations = async function (store_id, configurationKey = null, categoryTreeMode = false) {
    const params = {
        key: configurationKey,
        category_tree: categoryTreeMode,
        raw_mode: false
    }

    const response = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${store_id}/configurations`, { params })
    return response.data
}

export const updateConfigurations = async function (store_id, configurations) {
    const body = {
        configurations: configurations
    }
    const response = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${store_id}/configurations`, body)
    return response.data
}


export const sendNotificationTestEmail = async function (store_id, notification_type, email) {
    const body = {
        to_email: email
    }
    const response = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${store_id}/configurations/email_test/${notification_type}`, body)
    return response.data
}

export const fetchStoreReports = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/automatic_reports`, { params })
    return res.data
}

export const fetchStoreReport = async (id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/automatic_reports/${id}`)
    return res.data
}

export const createStoreReport = async (data) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/automatic_reports`, data)
    return res.data
}

export const updateStoreReport = async (id, data) => {
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/automatic_reports/${id}`, data)
    return res.data
}

export const enqueueReportsExport = async function (data) {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user-async-tasks/reports_export`, data)
    return res.data
}

export const getReportsExport = async function (jobId) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user-async-tasks/reports_export/${jobId}`)
    return res.data
}

export const getLatestStatementAttachment = async function (storeId, attachment) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/latest-billing-statement/${attachment}`, { responseType: 'blob' })
    return res
}

export const fetchStoreShippingPackages = async (storeId, params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/shipping-packages`, { params: params })
    return res.data
}
