import { useMemo } from "react"

const InputProductQuantity = ({
    quantity,
    onQuantityChange,
    placeholder="",
    minValue=1,
    isDisabled=false,
    isInvalid=false,
    isEmptyInputInvalid=true
}) => {

    const handleOnChangeQuantity = (newQuantity) => {
        const quantityToUpdate = Number(newQuantity)

        if(!isDisabled && quantityToUpdate >= minValue){
            onQuantityChange(quantityToUpdate)
        }
    }

    const hasNoQuantity = useMemo(() => {
        return quantity === null || quantity === undefined || quantity === "" ? true : false
    }, [quantity])

    return (
        <div className={`relative border rounded overflow-hidden w-fit flex ${isDisabled ? "cursor-not-allowed" : ""} ${(hasNoQuantity || isInvalid) && isEmptyInputInvalid ? "border-red-500" : ""}`}>

            {isDisabled && <div className="absolute inset-0 bg-gray-100 bg-opacity-50"></div>}

            <button
                onClick={() => handleOnChangeQuantity(Number(quantity) - 1)}
                className={`bg-white w-10 border-r ${!isDisabled ? "hover:bg-gray-200" : ""}`}
                disabled={isDisabled}
            >
                {"-"}
            </button>

            <input
                type="number"
                className="border-0 text-center focus:ring-0 w-12"
                placeholder={placeholder}
                onChange={(event) => handleOnChangeQuantity(Number(event.target.value))}
                value={quantity === null || quantity === undefined ? "" : quantity}
                disabled={isDisabled}
            />

            <button
                onClick={() => handleOnChangeQuantity(Number(quantity) + 1)}
                className={`bg-white w-10 border-l ${!isDisabled ? "hover:bg-gray-200" : ""}`}
                disabled={isDisabled}
            >
                {"+"}
            </button>
        </div>
    )
}

export default InputProductQuantity