import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import moment from "moment-timezone";

const ValidityEndSelector = ({validityEndDateContent, onUpdateValidityEndDateContent, timeZone}) => {
    const { t } = useTranslation()

    const localValidityEndDate = useMemo(() => {
        let createdValidityEndDate = null

        if(validityEndDateContent.validityEndDate){
            const formattedDate = validityEndDateContent.validityEndDate.toISOString()

            let parsedDate = moment(formattedDate).tz(timeZone)
            parsedDate = parsedDate.endOf("day")
            parsedDate = parsedDate.format("YYYY-MM-DD HH:mm:ss")

            createdValidityEndDate = new Date(parsedDate)
        }

        return createdValidityEndDate
    }, [validityEndDateContent, timeZone])

    const handleOnChangeValidityEnd = (newValidityEnd) => {
        let validityEndDateToUpdate = null

        if(newValidityEnd){
            const validityYear = newValidityEnd.getFullYear()
            const validityMonth = (newValidityEnd.getMonth() + 1).toString().padStart(2, "0")
            const validityDate = newValidityEnd.getDate().toString().padStart(2, "0")

            const parsedNewValidityEnd = `${validityYear}-${validityMonth}-${validityDate}`

            let formattedValidityDate = moment.tz(parsedNewValidityEnd, timeZone)
            formattedValidityDate = formattedValidityDate.endOf("day")
            formattedValidityDate = formattedValidityDate.format("YYYY-MM-DD HH:mm:ss Z")

            validityEndDateToUpdate = new Date(formattedValidityDate)
        }

        onUpdateValidityEndDateContent({validityEndDate: validityEndDateToUpdate})
    }

    const calculateMinimumEndDate = () => {
        let formattedMinimumDate = moment().tz(timeZone)
        formattedMinimumDate = formattedMinimumDate.endOf("day")
        formattedMinimumDate = formattedMinimumDate.format("YYYY-MM-DD HH:mm:ss")

        const minimumEndDate = new Date(formattedMinimumDate)

        return minimumEndDate
    }

    return (
        <div className="flex gap-5 items-center">
            <label>
                {t("settings.business_rule_creator.general_business_rule_settings.validity_end_field")}
            </label>

            <div className="flex border rounded border-gray-300 pl-2">
                <label className="flex gap-2 items-center border-r pr-2">
                    <input
                        type="checkbox"
                        className="border-gray-300 focus:ring-0"
                        checked={validityEndDateContent.withoutValidityEnd}
                        onChange={() => onUpdateValidityEndDateContent({validityEndDate: null, withoutValidityEnd: !validityEndDateContent.withoutValidityEnd})}
                    />
                    <span className="shrink-0">
                        {t("settings.business_rule_creator.general_business_rule_settings.without_validity_end")}
                    </span>
                </label>
                <div onClick={() => {if(validityEndDateContent.withoutValidityEnd){onUpdateValidityEndDateContent({withoutValidityEnd: false})}} }>
                    <ReactDatePicker
                        className={"placeholder:text-gray-400 border-0 cursor-pointer"}
                        selected={localValidityEndDate}
                        placeholderText={"dd mm yy"}
                        dateFormat="dd/MM/yy"
                        onChange={handleOnChangeValidityEnd}
                        minDate={calculateMinimumEndDate()}
                    />
                </div>
            </div>
        </div>
    )
}

export default ValidityEndSelector