import { useMemo } from "react";

import { BasicTable } from "../../../../components";
import { EditableCell } from "../../../../components/BasicTable/EditableCell";
import { PRODUCT_TABLE_ACCESSORS } from "../utils/productsTableUtils";
import ProductImage from "./ProductImage";

const ProductsTable = ({products, onProductUpdate}) => {
    const tableColumns = useMemo(() => [
        {
            Header: "settings.business_rule_creator.products_table.product_image_header",
            accessor: PRODUCT_TABLE_ACCESSORS.PRODUCT_IMAGE,
        },
        {
            Header: "settings.business_rule_creator.products_table.product_name_header",
            accessor: PRODUCT_TABLE_ACCESSORS.PRODUCT_NAME,
            disableWrap: true
        },
        {
            Header: "settings.business_rule_creator.products_table.product_sku_header",
            accessor: PRODUCT_TABLE_ACCESSORS.PRODUCT_SKU
        },
        {
            Header: "settings.business_rule_creator.products_table.product_quantity_header",
            accessor: PRODUCT_TABLE_ACCESSORS.PRODUCT_QUANTITY,
            shrinkToContent: true,
            Cell: EditableCell,
        }
    ], [])

    const tableData = useMemo(() => products?.map((product) => ({
        product_name: product.name,
        product_sku: product.sku,
        product_quantity: product.quantity,
        product_image: (
            <ProductImage
                relativeThumbnailImagePath={product.product_image_path?.thumbnail_path}
                relativeFullScreenImagePath={product.product_image_path?.path}
            />
        )
    })),[products])


    return (
        <BasicTable
            showHeader={true}
            columns={tableColumns}
            data={tableData}
            updateData={onProductUpdate}
        />
    )
}

export default ProductsTable