import axiosInstance from "../helpers/axiosInstance";

export const fetchInvoices = async function (params) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${params.store_id}/invoices`, { params })

    return res.data
}

export const enqueueInvoiceExport = async function (data) {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user-async-tasks/invoices_export`, data)
    return res.data
}

export const getExport = async function (jobId) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user-async-tasks/exports/${jobId}`)
    return res.data
}