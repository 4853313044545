import cubboLogo from "../../assets/logo-2.png"
import {useState} from "react";
import {useHistory, Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Notification, ButtonLoader} from "../../components";
import useAuth from "../../hooks/useAuth";
import { LOGIN } from "../../navigation/constants";
import {forgotPassword} from "../../services/loginServices"
import { useTranslation } from 'react-i18next';

export const ForgotPassword = () => {

  const { t, i18n } = useTranslation(); 
  const {register, handleSubmit, formState: { errors }} = useForm({})

  const history = useHistory()

  const [showNotification, setShowNotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)

  const [loginUser, isLoading, isError] = useAuth()

  const onSubmit = async (data) => {
      const {email} = data
      const res = await forgotPassword(email)
      console.log('dsfd', res)
      if (res.error){
        setErrorMessage(res.error)
        setShowNotification(true)
      } else if (res.message) {
        setSuccessMessage(res.message)
        setShowNotification(true)
      }
      // loginUser({email, password}).then((user)=>{
      //     console.log(user)
      //     if (user.current_store) {
      //         history.push("/")
      //     }
      //     else {
      //         history.push(STORES)
      //     }
      // }).catch((e)=>{
      //     setShowNotification(true)
      // })

  }

  return (
          <div className="min-h-screen bg-light-blue-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={cubboLogo}
            alt="Workflow"
          />
          
        </div>
  
        <div className="mt-16 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h2 className="mt-3 mb-3 text-center text-2xl text-gray-900"> {i18n.t("forgotPassword.recover_password")}</h2>
          <p className="text-center text-gray-500 mb-5"> {i18n.t("forgotPassword.enter_your_email")}</p>
          <form action="#" method="POST" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1">
                <input
                      name="email"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      {...register("email", {
                          required: i18n.t("forgotPassword.required_field"),
                      })}
                  />
                  <p className="mt-2 text-sm text-red-600">
                      {errors.email && errors.email.message}
                  </p>
                </div>
              </div>
  
              <div className="flex items-center justify-between">
  
                <div className="text-sm">
                  <Link
                    to={LOGIN}
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                    key={"web"+LOGIN}
                                      >
                      {i18n.t("forgotPassword.back_to_login")} 
                    </Link>
                </div>
              </div>
  
              <div>
                {/* <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Sign in
                </button> */}
                {isLoading ? (
                                              i18n.t("forgotPassword.entering") 
                                          ) : (
                                              <>
                                                  {errorMessage && showNotification? (
                                                      <>
                                                          <Notification show={showNotification} setShow={setShowNotification} type="error" title= {i18n.t("forgotPassword.error")}  description={errorMessage}/>
                                                      </>
                                                  ) : null}
                                                  {successMessage && showNotification? (
                                                      <>
                                                          <Notification show={showNotification} setShow={setShowNotification} type="success" title= {i18n.t("forgotPassword.check_your_email")} description={successMessage}/>
                                                      </>
                                                  ) : null}
                                                  <button
                                                      type="submit"
                                                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                  >
                                                      {i18n.t("forgotPassword.password_recovery")}
                                                  </button>
                                                  
                                              </>
                                          )}
              </div>
            </form>
  
  
          </div>
        </div>
      </div>
  
  )
}