const ruleValidityEndBuilder = (endDate, withoutValidityEnd, buildForPreview=false) => {
    let validityEndDate = null

    if(buildForPreview){
        validityEndDate = withoutValidityEnd ? null : endDate.toISOString()
    } else {
        validityEndDate = withoutValidityEnd ? null : endDate.toISOString()
    }

    return validityEndDate
}

export default ruleValidityEndBuilder