import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { DEFAULT_QUERY_STALE_TIME, INTERVAL_DAY, capitalizeCategories, formatDates, parseDates, popCategories } from "../chartDataUtils"
import { getShippingMethodsHistoric } from "../../../../services/storeMetricsService"
import { BarChart } from "../../../../components/MetricsCards/BarChart"
import { formatPercentage } from "../../../../utils/StringUtils"

export const ShippingMethodsHistoric = ({ storeId = null, dateFilter = null, interval = INTERVAL_DAY }) => {
    const { i18n, t } = useTranslation()

    const [formattedData, setFormattedData] = useState([])
    const [categories, setCategories] = useState([])

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["shiping_methods_historic", storeId, dateFilter, interval], () => getShippingMethodsHistoric(storeId, dateFilter, interval), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to && !!interval,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    useEffect(() => {
        const formatData = async (data) => {
            // [{truncated_date:a, categories: {b => c, d => f}}]
            const dataParsedDates = parseDates(data)
            const [dataCapitalizedCategories, uniqueCategories] = capitalizeCategories(dataParsedDates, true)
            setCategories(uniqueCategories)
            const flattenedData = popCategories(dataCapitalizedCategories)
            const formattedData = formatDates(flattenedData, interval, i18n.language)
            setFormattedData(formattedData)
            return null
        }
        formatData(data).catch((error) => { console.error(error) })
    }, [data, interval, i18n.language])
    
    return (
        <BarChart
            categories={categories}
            data={formattedData}
            valueFormatter={(percentageValue) =>
                `${formatPercentage(percentageValue.getPercentage())} (${percentageValue.getValue()} ${t("metrics.generic.orders")})`
            }
            barChartProps={{
                stack: true,
                relative: true,
                showGridLines: false,
                className: "h-60",
            }}
            isLoading={isLoading}
        />
    )
}


