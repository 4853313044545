import {Button, InputField, SelectInput} from "../../../components";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react"
import {useState, useContext} from "react";
import  i18n from "../../../translations/index";
import { UserContext } from "../../../hooks/UserContext";
import countryList from 'react-select-country-list'
import {isBlank} from "../../../utils/StringUtils";
import { ComboBox } from "../../../components/ComboBox";

export const AddressView = ({
    shipping_type,
    title,
    initialData,
    onSave,
    onCancel
}) => {

    AddressView.propTypes = {
        initialData: PropTypes.shape({
            id: PropTypes.string,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            phone: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            tax_id: PropTypes.string.isRequired,
            company: PropTypes.string,
            tax_regime: PropTypes.string,
            state_registration: PropTypes.string,
            address: PropTypes.string.isRequired,
            address2: PropTypes.string,
            number: PropTypes.string.isRequired,
            neighborhood: PropTypes.string.isRequired,
            country: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired,
            province: PropTypes.string.isRequired,
            zipCode: PropTypes.string.isRequired
        }),
        onSave: PropTypes.func,
        onCancel: PropTypes.func
    }

    const [addressData, setAddressData] = useState(initialData)
    const {user} = useContext(UserContext)
    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])
    const [showInputIe, setShowInputIe] = useState(false)
    const internationalCountries = useMemo(() => countryList().getData().map(country => {return {id: country.value, name: country.label}}), [])
    const [isInternationalOrder, setIsInternationalOrder] = useState(addressData.country != warehouse.country)

    useEffect(() => {
        setIsInternationalOrder(addressData.country != warehouse.country)
    }, [addressData.country])

    useEffect(() => {
        if(addressData && !addressData.country){
            setAddressData({
                ...addressData,
                country: warehouse.country
            })
        }
    }, [warehouse, addressData])

   

    const handleClickOnSave = () => {
        if(onSave){
            onSave(addressData)
        }
    }
    
    const handleClickOnCancel = () => {
        if(onCancel){
            onCancel()
        }
    }

    const taxRegimeOptions = [
        { id: null, name: <>&nbsp;</>}, //TRADUCIR
        { id: "PHYSICAL", name: 'Físico' },
        { id: "LEGAL", name: 'Legal' },
    ]

    const cubboCountries = [
        { name: <>&nbsp;</>, id: '' },
        { name: "Mexico", id: 'MX' },
        { name: "Colombia", id: 'CO' },
        { name: "Brazil", id: 'BR' }
    ]

    const brasilStates = [
        { id: null, name: ""}, //TRADUCIR
        { id: "AC", name: "AC"},
        { id: "AL", name: "AL"},
        { id: "AP", name: "AP"},
        { id: "AM", name: "AM"},
        { id: "BA", name: "BA"},
        { id: "CE", name: "CE"},
        { id: "DF", name: "DF"},
        { id: "ES", name: "ES"},
        { id: "GO", name: "GO"},
        { id: "MA", name: "MA"},
        { id: "MT", name: "MT"},
        { id: "MS", name: "MS"},
        { id: "MG", name: "MG"},
        { id: "PA", name: "PA"},
        { id: "PB", name: "PB"},
        { id: "PR", name: "PR"},
        { id: "PE", name: "PE"},
        { id: "PI", name: "PI"},
        { id: "RJ", name: "RJ"},
        { id: "RN", name: "RN"},
        { id: "RS", name: "RS"},
        { id: "RO", name: "RO"},
        { id: "RR", name: "RR"},
        { id: "SC", name: "SC"},
        { id: "SP", name: "SP"},
        { id: "SE", name: "SE"},
        { id: "TO", name: "TO"},
    ]

    const checkRequiredFields = () => {
        if (addressData.firstName && addressData.firstName.trim() !== '' &&
            addressData.lastName && addressData.lastName.trim() !== '' &&
            addressData.phone && addressData.phone.trim() !== '' &&
            addressData.email && addressData.email.trim() !== '' &&
            addressData.address && addressData.address.trim() !== '' &&
            addressData.city && addressData.city.trim() !== '' &&
            addressData.country && addressData.country.trim() !== '' &&
            addressData.province && addressData.province.trim() !== '' &&
            addressData.zipCode && addressData.zipCode.trim() !== '' && checkZipCodeLength(addressData.zipCode)
        )
        {
            return true
        }

        return false
    }

    const checkZipCodeLength = (_zc) => {
        switch (addressData.country) {
            case  'BR':
                return _zc.length === 8
            case  'CO':
                return _zc.length === 6
            case  'MX':
                return _zc.length === 5
            default:
                return _zc.length >= 2 
        }
    }

    const checkRequiredFieldsBR = () => {
        let isValid = false
        if (addressData.firstName && addressData.firstName.trim() !== '' &&
            addressData.lastName && addressData.lastName.trim() !== '' &&
            addressData.phone && addressData.phone.trim() !== '' &&
            addressData.email && addressData.email.trim() !== '' &&
            (isInternationalOrder || (addressData.tax_id && addressData.tax_id.trim() !== '')) &&
            addressData.address && addressData.address.trim() !== '' &&
            addressData.number && addressData.number.trim() !== '' &&
            addressData.neighborhood && addressData.neighborhood.trim() !== '' &&
            addressData.city && addressData.city.trim() !== '' &&
            addressData.zipCode && addressData.zipCode.trim() !== '' && checkZipCodeLength(addressData.zipCode) &&
            addressData.country && addressData.country.trim() !== '' &&
            addressData.tax_regime && addressData.tax_regime.trim() !== '' &&
            (isInternationalOrder || (addressData.province && addressData.province.trim() !== ''))
        ){
            isValid = true
        }

        if (addressData.tax_regime === "LEGAL") {
            if (!addressData.company && addressData.company.trim() === '' || !addressData.state_registration && addressData.company.trim() === '') {
                isValid = false
            }
        }

        return isValid
    }


    return (
        <form className="">
            <div className="space-y-3 divide-y divide-gray-200">
                <div>
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
                    </div>
                </div>

                <div className="pt-4">

                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                        <div className="sm:col-span-3">
                            <InputField
                                title= {i18n.t("orders.addresView.name")} 
                                value={addressData.firstName}
                                onChange={(value) => {
                                    setAddressData({
                                        ...addressData,
                                        firstName: value
                                    })
                                }}
                                required
                            />

                            <p className="text-sm text-red-600">{addressData.firstName === '' && i18n.t("orders.addresView.required_field")}</p>

                        </div>
                        <div className="sm:col-span-3">
                            <InputField
                                title= {i18n.t("orders.addresView.surname")} 
                                value={addressData.lastName}
                                onChange={(value) => {
                                    setAddressData({
                                        ...addressData,
                                        lastName: value
                                    })
                                }}
                                required
                            />

                            <p className="text-sm text-red-600">{addressData.lastName === '' && i18n.t("orders.addresView.required_field")}</p>

                        </div>

                        <div className="sm:col-span-3">

                            <InputField
                                title= {i18n.t("orders.addresView.phone")}
                                value={addressData.phone}
                                onChange={(value) => {
                                    setAddressData({
                                        ...addressData,
                                        phone: value
                                    })
                                }}
                                required
                            />

                            <p className="text-sm text-red-600">{addressData.phone === '' && i18n.t("orders.addresView.required_field")}</p>
                        </div>

                        <div className="sm:col-span-3">
                            <InputField
                                title="E-mail"
                                value={addressData.email}
                                onChange={(value) => {
                                    setAddressData({
                                        ...addressData,
                                        email: value
                                    })
                                }}
                                required
                            />
                            <p className="text-sm text-red-600">{addressData.email === '' && i18n.t("orders.addresView.required_field")}</p>
                        </div>

                        {(warehouse.country === 'BR' && !isInternationalOrder) && 
                            <>
                                <div className="sm:col-span-3">
                                    <InputField
                                        title= {i18n.t("orders.addresView.billing_tax_id")}
                                        value={addressData.tax_id}
                                        onChange={(value) => {
                                        let re = /^[0-9]+$/;
                                        if (value === '' || re.test(value)) {
                                            setAddressData({
                                                ...addressData,
                                                tax_id: value
                                            })
                                        }}
                                        }
                                        required={!isInternationalOrder}
                                    />

                                    <p className="text-sm text-red-600">{addressData.tax_id === '' && i18n.t("orders.addresView.required_field")}</p>

                                </div>
                                <div className="sm:col-span-3">
                                <SelectInput
                                    options={taxRegimeOptions}
                                    selectedInitial={taxRegimeOptions.find(option => option.id === addressData.tax_regime) ?? taxRegimeOptions[0]}
                                    className="sm:col-span-3"
                                    label= {i18n.t("orders.addresView.billing_tax_regime")}
                                    onChange={(value) => {
                                        setAddressData({
                                            ...addressData,
                                            tax_regime: value.id
                                        })
                                    }}
                                    value={taxRegimeOptions.find(option => option.id === addressData.tax_regime) ?? taxRegimeOptions[0]}
                                        required
                                    />
                                </div>


                                {(addressData.tax_regime === "LEGAL") &&
                                    <>
                                        <InputField
                                        className="sm:col-span-3"
                                        title= {i18n.t("orders.addresView.billing_company")}
                                        value={addressData.company}
                                        onChange={(value) => {
                                            setAddressData({
                                                ...addressData,
                                                company: value
                                            })
                                        }}
                                        required
                                        />
                                        
                                        <InputField
                                        className="sm:col-span-3"
                                        title= {i18n.t("orders.addresView.billing_state_registration")}
                                        value={addressData.state_registration}
                                        onChange={(value) => {
                                            let re = /^[a-zA-Z0-9 ]+$/;
                                            if (value === '' || re.test(value)) {
                                            setAddressData({
                                                ...addressData,
                                                state_registration: value
                                            })
                                        }}}
                                        required
                                        />
                                    </>
                                }

                            </>

                        }   

                        <div className="sm:col-span-6">
                            <InputField
                                title= {i18n.t("orders.addresView.address")} 
                                value={addressData.address}
                                onChange={(value) => {
                                    setAddressData({
                                        ...addressData,
                                        address: value
                                    })
                                }}
                                required
                            />

                            <p className="text-sm text-red-600">{addressData.address === '' && i18n.t("orders.addresView.required_field")}</p>
                        </div>

                        <InputField
                            className="sm:col-span-6"
                            title= {i18n.t("orders.addresView.apartment")} 
                            value={addressData.address2}
                            onChange={(value) => {
                                setAddressData({
                                    ...addressData,
                                    address2: value
                                })
                            }}
                        />

                        {(warehouse.country ==='BR') && (
                            <>
                                <div className="sm:col-span-3">
                                    <InputField
                                        title= {i18n.t("orders.addresView.billing_number")}
                                        value={addressData.number}
                                        onChange={(value) => {
                                            setAddressData({
                                                ...addressData,
                                                number: value
                                            })
                                        }}
                                        required
                                    />
                                    {isBlank(addressData.number) &&
                                        <p className="text-sm text-red-600">{i18n.t("orders.addresView.required_field")}</p>
                                    }
                                </div>

                                <div className="sm:col-span-3">
                                    <InputField
                                        className="sm:col-span-3"
                                        title= {i18n.t("orders.addresView.billing_neighborhood")}
                                        value={addressData.neighborhood}
                                        onChange={(value) => {
                                            setAddressData({
                                                ...addressData,
                                                neighborhood: value
                                            })
                                        }}
                                        required
                                    />

                                    {isBlank(addressData.neighborhood)  &&
                                        <p className="text-sm text-red-600">{i18n.t("orders.addresView.required_field")}</p>
                                    }
                                </div>
                            </>
                        )}

                        <ComboBox 
                            options={internationalCountries}
                            selectedInitial={internationalCountries.find(country => country.id === addressData.country) ?? {id:'', name: null}}
                            className="sm:col-span-3 mt-1"
                            label= {i18n.t("orders.addresView.country")}
                            onChange={(country) => {
                                setAddressData({
                                    ...addressData,
                                    country: country.id
                                })
                            }}
                            value={internationalCountries.find(country => country.id === addressData.country) ?? {id:'', name: null}}
                            required
                        />

                        <div className="sm:col-span-3">
                            <InputField
                                title= {i18n.t("orders.addresView.city")}
                                value={addressData.city}
                                onChange={(value) => {
                                    setAddressData({
                                        ...addressData,
                                        city: value
                                    })
                                }}
                                required
                            />

                            <p className="text-sm text-red-600">{addressData.city === '' && i18n.t("orders.addresView.required_field")}</p>
                        </div>

                        {(addressData.country ==='BR') ? 
                            <>
                                <div className="sm:col-span-3">
                                <ComboBox
                                    options={brasilStates}
                                    selectedInitial={brasilStates.find(option => option.id === addressData.province) ?? {id:'', name: null}}
                                    className="sm:col-span-3 mt-1"
                                    label= {i18n.t("orders.addresView.state")}
                                    onChange={(province) => {
                                        setAddressData({
                                            ...addressData,
                                            province: province.id
                                        })
                                    }}
                                    value={brasilStates.find(option => option.id === addressData.province) ?? {id:'', name: null}}
                                    required
                                />
                                </div>
                            </>
                        :
                            <>
                                <div className="sm:col-span-3">
                                    <InputField
                                        title= {i18n.t("orders.addresView.state")} 
                                        value={addressData.province}
                                        onChange={(value) => {
                                            setAddressData({
                                                ...addressData,
                                                province: value
                                            })
                                        }}
                                    required={!(warehouse.country == "BR" && isInternationalOrder)}
                                    />
                                    {!(warehouse.country == "BR" && isInternationalOrder) &&
                                     <p className="text-sm text-red-600">{addressData.province === '' && i18n.t("orders.addresView.required_field")}</p>
                                    }
                                </div>
                            </>
                        }

                        <div className="sm:col-span-3">
                            <InputField
                                title= {i18n.t("orders.addresView.postal_code")}
                                maxLength={addressData.country == "MX" ? "5": ["BR", "CO"].includes(addressData.country)? "8" : "16"} 
                                value={addressData.zipCode}
                                onChange={(value) => {
                                    if (["BR", "CO", "MX"].includes(addressData.country)) {
                                        let re = /^[0-9]+$/;
                                        if (value === '' || re.test(value)) {
                                            setAddressData({
                                                ...addressData,
                                                zipCode: value
                                            })
                                        }
                                    }
                                    else {
                                        setAddressData({
                                            ...addressData,
                                            zipCode: value
                                        })
                                    }
                                }}
                                required
                            />
                            <p className="text-sm text-red-600 w-screen ">
                            {addressData.country === 'BR'? <>
                                {addressData.zipCode.length < 8 && i18n.t("orders.addresView.postal_code_req_br")}
                                {addressData.zipCode.length > 8 && i18n.t("orders.addresView.postal_code_req_br_wrong")}
                            </> : addressData.country === 'MX' ? <>
                                {addressData.zipCode.length < 5 &&  (addressData.country == "MX" || addressData.country == "Mexico" )&& i18n.t("orders.addresView.postal_code_req_mx")}
                                {addressData.zipCode.length > 5 && (addressData.country == "MX" || addressData.country == "Mexico")  &&i18n.t("orders.addresView.postal_code_req_mx_wrong")}
                            </> : addressData.country === 'CO' ? <>  
                                {addressData.zipCode.length < 6 && i18n.t("orders.addresView.postal_code_req_co")}
                                {addressData.zipCode.length > 8 && i18n.t("orders.addresView.postal_code_req_co_wrong")}
                            </> : ''}
                            </p>
                            
                        </div>
                    </div>


                    <div className="mt-8 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <Button  onClick={handleClickOnSave} disabled={warehouse.country ==='BR' ? !checkRequiredFieldsBR() : !checkRequiredFields()}>
                            {i18n.t("orders.addresView.save")}
                        </Button>

                        <Button type="secondary" onClick={handleClickOnCancel}> {i18n.t("orders.addresView.cancel")}</Button>
                    </div>
                    
                </div>
            </div>

        </form>
    )
}