import { useEffect, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from "react-query";

import { ButtonLoader, Notification } from '../../../components';
import { notificationTypes } from '../../../components/Notification';
import { UserContext } from "../../../hooks/UserContext";
import { fetchStorePackingOptions, updatePackingOptions } from "../../../services/storeServices";
import { Loader } from "../../../components/Loader";
import { FRAGILE_TYPES, PACKAGES_TYPES, PACKING_OPTIONS_IMAGES, UNEDITABLE_PACKING_OPTIONS, NON_FRAGILE_PACKAGE_TYPES, FRAGILE_PACKAGE_TYPES, PACKAGELESS_PACKAGE_TYPES } from './packingOptionConstants';
import { NonFragileOptionsSection, SpecificConfigSection } from './sections';
import { fetchWarehousePackingMaterials } from '../../../services/warehousePackingMaterialsService';

import { fetchStorePackingMaterials, updateStorePackingMaterials } from '../../../services/storePackingMaterialsService';

const PackingOptionsContainer = () => {
    const { t, i18n } = useTranslation()
    const [nonFragilePackingOptions, setNonFragilePackingOptions] = useState({})
    const [fragilePackingOptions, setFragilePackingOptions] = useState({})
    const [isSavingInProgress, setIsSavingInProgress] = useState(false)

    const [isFetchOptionsEnabled, setIsFetchOptionsEnabled] = useState(true)
    const [isFetchWarehousePackingMaterialsEnabled, setIsFetchWarehousePackingMaterialsEnabled] = useState(true)
    const [isFetchStorePackingMaterialsEnabled, setIsFetchStorePackingMaterialsEnabled] = useState(true)

    const [fragilePackingForFragile, setFragilePackingForFragile] = useState(null)
    const [fragilePackingForNotFragile, setFragilePackingForNotFragile] = useState(null)

    const [notificationContent, setNotificationContent] = useState({
        type: "",
        title: "",
        description: "",
        showNotification: false,
    })
    const {user} = useContext(UserContext)
    const {
        isLoading,
        isError,
        error,
        data: packingOptions
    } = useQuery(['packing_options', user.current_store?.id], () => fetchStorePackingOptions(user.current_store?.id),{
        enabled: isFetchOptionsEnabled,
        onSuccess: (data) => {
            setIsFetchOptionsEnabled(false)
        }
    })

    const {
        isLoading: warehousePackingMaterialsIsLoading,
        isError: warehousePackingMaterialsIsError,
        error: warehousePackingMaterialsError,
        data: warehousePackingMaterialsData
    } = useQuery(['warehouse_packing_materials', user.current_store?.id], () => fetchWarehousePackingMaterials(user.current_store?.warehouses[0].id),{
        enabled: isFetchWarehousePackingMaterialsEnabled,
        onSuccess: (data) => {
            setIsFetchWarehousePackingMaterialsEnabled(false)
        }
    })

    const {
        isLoading: storePackingMaterialsIsLoading,
        isError: storePackingMaterialsIsError,
        error: storePackingMaterialsError,
        data: storePackingMaterialsData
    } = useQuery(['store_packing_materials', user.current_store?.id], () => fetchStorePackingMaterials(user.current_store?.warehouses[0].id, user.current_store?.id),{
        enabled: isFetchStorePackingMaterialsEnabled,
        onSuccess: (data) => {
            setIsFetchStorePackingMaterialsEnabled(false)
        }
    })

    useEffect(() => {
        if(packingOptions){
            const availableNonFragilePackingOptions = {
                parcel_bag: null,
                jiffy_envelope: null,
                cubbo_box: null
            }
            const availableFragilePackingOptions = {
                jiffy_envelope: null,
                cubbo_box: null
            }

            // Assign the packing option status
            Object.entries(packingOptions.store_packing_options).forEach(([packageType, fragile]) => {
                const isValidForFragile = availableFragilePackingOptions.hasOwnProperty(packageType)
                const isValidForNonFragile = availableNonFragilePackingOptions.hasOwnProperty(packageType)

                if(fragile === FRAGILE_TYPES.BOTH){
                    if(isValidForFragile) { availableFragilePackingOptions[packageType] = true }
                    if(isValidForNonFragile) { availableNonFragilePackingOptions[packageType] = true }
                }

                else if(fragile === FRAGILE_TYPES.FRAGILE_ONLY){
                    if(isValidForFragile) { availableFragilePackingOptions[packageType] = true }
                    if(isValidForNonFragile) { availableNonFragilePackingOptions[packageType] = false }
                }

                else if(fragile === FRAGILE_TYPES.NON_FRAGILE_ONLY){
                    if(isValidForFragile) { availableFragilePackingOptions[packageType] = false }
                    if(isValidForNonFragile) { availableNonFragilePackingOptions[packageType] = true }
                }

                else if(fragile === FRAGILE_TYPES.NEITHER){
                    if(isValidForFragile) { availableFragilePackingOptions[packageType] = false }
                    if(isValidForNonFragile) { availableNonFragilePackingOptions[packageType] = false }
                }
            })

            // Remove unavailable package options
            Object.keys(availableNonFragilePackingOptions).forEach(option => {
                if(availableNonFragilePackingOptions[option] === null) {
                    delete availableNonFragilePackingOptions[option]
                }
            })
            Object.keys(availableFragilePackingOptions).forEach(option => {
                if(availableFragilePackingOptions[option] === null) {
                    delete availableFragilePackingOptions[option]
                }
            })

            setNonFragilePackingOptions(availableNonFragilePackingOptions)
            setFragilePackingOptions(availableFragilePackingOptions)
        }
    },[packingOptions])

    const handleChangeOptions = (event) => {
        window.analytics.track("Settings - Selects New Packing Setting")
        const optionValueSplitted = event.target.value.split("-")
        const optionStatus = event.target.checked

        const packingType = optionValueSplitted[0]
        const packagesType = optionValueSplitted[1]

        if( packingType === FRAGILE_TYPES.NON_FRAGILE_ONLY && nonFragilePackingOptions.hasOwnProperty(packagesType)){
            setNonFragilePackingOptions({...nonFragilePackingOptions, [packagesType]: optionStatus})
        }

        if( packingType === FRAGILE_TYPES.FRAGILE_ONLY && fragilePackingOptions.hasOwnProperty(packagesType)){
            setFragilePackingOptions({...fragilePackingOptions, [packagesType]: optionStatus})
        }
    }

    const getUnifiedFragileOption = (noneFragileOption, fragileOption) => {
        return noneFragileOption && fragileOption ? FRAGILE_TYPES.BOTH : noneFragileOption ? FRAGILE_TYPES.NON_FRAGILE_ONLY : fragileOption ? FRAGILE_TYPES.FRAGILE_ONLY : FRAGILE_TYPES.NEITHER
    }

    const handleSaveOptions = async() => {
        window.analytics.track("Settings - Saves Packing Settings")
        setIsSavingInProgress(true)

        const updatedPackingOptions = {
            parcel_bag: null,
            jiffy_envelope: null,
        }

        const editablePackages =  Object.entries(PACKAGES_TYPES).filter(([key, value]) => !(key in UNEDITABLE_PACKING_OPTIONS)).map(([key,value]) => value)

        // Set only the available packages
        editablePackages.forEach(packageType => {
            if(nonFragilePackingOptions.hasOwnProperty(packageType) || fragilePackingOptions.hasOwnProperty(packageType)){
                updatedPackingOptions[packageType] = getUnifiedFragileOption(nonFragilePackingOptions[packageType], fragilePackingOptions[packageType])
            } else {
                delete updatedPackingOptions[packageType]
            }
        })

        const packingMaterialsToUpdate = []

        if(fragilePackingForFragile){
            packingMaterialsToUpdate.push({...fragilePackingForFragile})
        }
        if(fragilePackingForNotFragile){
            packingMaterialsToUpdate.push({...fragilePackingForNotFragile})
        }

        const editableNoneFragileOptionsPresent = Object.keys(nonFragilePackingOptions).some(key => !( key.toUpperCase() in UNEDITABLE_PACKING_OPTIONS))
        const editableFragileOptionsPresent = Object.keys(fragilePackingOptions).some(key => !( key.toUpperCase() in UNEDITABLE_PACKING_OPTIONS))

        try {
            if(editableNoneFragileOptionsPresent || editableFragileOptionsPresent){
                await updatePackingOptions(user.current_store?.id, updatedPackingOptions)
            }

            if(packingMaterialsToUpdate.length > 0){
                await updateStorePackingMaterials(user.current_store?.warehouses[0].id, user.current_store?.id, {
                    warehouse_packing_materials: packingMaterialsToUpdate
                })
            }

            setNotificationContent({
                type: notificationTypes.SUCCESS,
                title: t("settings.packing_options.container.save_success_title"),
                showNotification: true,
            })

        } catch (error) {
            setNotificationContent({
                type: notificationTypes.ERROR,
                title: t("settings.packing_options.container.save_error_title"),
                description: t("settings.packing_options.container.save_error_description"),
                showNotification: true,
            })
        }

        setIsSavingInProgress(false)
    }

    const handleOnPackingMaterialChange = (packingMaterial) => {
        if(packingMaterial.for_fragile === true){
            setFragilePackingForFragile({...packingMaterial})
        } else if(packingMaterial.for_fragile === false){
            setFragilePackingForNotFragile({...packingMaterial})
        }
    }

    const packingOptionsContent = useMemo(() => {
        // For non fragile options section
        const nonFragilePackingOptionsList = Object.entries(NON_FRAGILE_PACKAGE_TYPES).map(([key, packageType]) => {
            const tooltipTranslationPath = `settings.packing_options.container.non_fragile_packing.options.${packageType}.disabled_hint`

            return{
                type: packageType,
                image: PACKING_OPTIONS_IMAGES[key],
                nonAvailableText: t("settings.packing_options.container.not_available_option"),
                title: t(`settings.packing_options.container.non_fragile_packing.options.${packageType}.title`),
                description: t(`settings.packing_options.container.non_fragile_packing.options.${packageType}.description`),
                isDisabled: UNEDITABLE_PACKING_OPTIONS.hasOwnProperty(key),
                tooltipText: i18n.exists(tooltipTranslationPath) ? t(tooltipTranslationPath) : null
        }})

        // For fragile options section
        const fragilePackingOptionsList = Object.entries(FRAGILE_PACKAGE_TYPES).map(([key, packageType]) => {
            const tooltipTranslationPath = `settings.packing_options.container.non_fragile_packing.options.${packageType}.disabled_hint`

            return{
                type: packageType,
                image: PACKING_OPTIONS_IMAGES[key],
                nonAvailableText: t("settings.packing_options.container.not_available_option"),
                title: t(`settings.packing_options.container.fragile_packing.options.${packageType}.title`),
                description: t(`settings.packing_options.container.fragile_packing.options.${packageType}.description`),
                isDisabled: UNEDITABLE_PACKING_OPTIONS.hasOwnProperty(key),
                tooltipText: i18n.exists(tooltipTranslationPath) ? t(tooltipTranslationPath) : null
        }})


        // For packageless options section
        const packagelessPackingOptionsList = Object.entries(PACKAGELESS_PACKAGE_TYPES).map(([key, packageType]) => {
            const tooltipTranslationPath = `settings.packing_options.container.virtual.packageless.options.${packageType}.disabled_hint`

            return{
                type: packageType,
                image: PACKING_OPTIONS_IMAGES[key],
                nonAvailableText: t("settings.packing_options.container.not_available_option"),
                title: t(`settings.packing_options.container.virtual.packageless.options.${packageType}.title`),
                description: t(`settings.packing_options.container.virtual.packageless.options.${packageType}.description`),
                isDisabled: UNEDITABLE_PACKING_OPTIONS.hasOwnProperty(key),
                tooltipText: i18n.exists(tooltipTranslationPath) ? t(tooltipTranslationPath) : null
        }})

        return {
            nonFragilePackingOptionsList: nonFragilePackingOptionsList,
            fragilePackingOptionsList: fragilePackingOptionsList,
            packagelessPackingOptionsList: packagelessPackingOptionsList,
        }
    },[i18n, t])

    if(isLoading || warehousePackingMaterialsIsLoading || storePackingMaterialsIsLoading) return <Loader show={true} />
    else if(isError) return <>Error: {error.message}</>
    else if(warehousePackingMaterialsIsError) return <>Error: {warehousePackingMaterialsError.message}</>
    else if(storePackingMaterialsIsError) return <>Error: {storePackingMaterialsError.message}</>

    return (
        <div className='flex flex-col m-10'>

            <Notification
                show={notificationContent.showNotification}
                setShow={(showNotification) => setNotificationContent({...notificationContent, showNotification: showNotification})}
                type={notificationContent.type}
                title={notificationContent.title}
                description={notificationContent.description}
            />

            <div className='mb-10'>
                <h1 className='text-3xl mb-0 font-bold text-gray-900'>
                    {t("settings.packing_options.container.title")}
                </h1>
                <div className='mt-1 flex items-center justify-between'>
                    <p className='mb-0 text-lg text-gray-500'>
                        {t("settings.packing_options.container.description")}
                    </p>

                    <ButtonLoader
                        className=""
                        loading_text={t("settings.packing_options.container.save_processing")}
                        loading={isSavingInProgress}
                        onClick={handleSaveOptions}
                    >
                        {t("settings.packing_options.container.save_options")}
                    </ButtonLoader>
                </div>
            </div>

            <div className='flex flex-col gap-6'>
                <NonFragileOptionsSection
                    title={t("settings.packing_options.container.non_fragile_packing.title")}
                    titleComplement={t("settings.packing_options.container.non_fragile_packing.title_description")}
                    description={t("settings.packing_options.container.non_fragile_packing.description")}
                    packingOptionsList={packingOptionsContent.nonFragilePackingOptionsList}
                    packingOptionsStatuses={nonFragilePackingOptions}
                    onOptionChange={handleChangeOptions}
                    warehouseMaterialList={warehousePackingMaterialsData?.warehouse_packing_materials}
                    storePackingMaterialList={storePackingMaterialsData?.store_packing_materials}
                    onPackingMaterialChange={handleOnPackingMaterialChange}
                />

                <SpecificConfigSection
                    packingOptionsContent={packingOptionsContent}
                    fragilePackingOptions={fragilePackingOptions}
                    handleChangeOptions={handleChangeOptions}
                    title={t("settings.packing_options.container.fragile_packing.title")}
                    titleComplement={t("settings.packing_options.container.fragile_packing.title_complement")}
                    description={t("settings.packing_options.container.fragile_packing.title_description")}
                    fragileDescription={t("settings.packing_options.container.fragile_packing.sub_description")}
                    packagelessDescription={t("settings.packing_options.container.virtual.packageless.sub_description")}
                    packagelessTooltipText={t("settings.packing_options.container.virtual.packageless.sub_description_hint")}
                    warehouseMaterialList={warehousePackingMaterialsData?.warehouse_packing_materials}
                    storePackingMaterialList={storePackingMaterialsData?.store_packing_materials}
                    onPackingMaterialChange={handleOnPackingMaterialChange}
                />
            </div>

            <div className='mt-10 flex items-center justify-end'>
                <ButtonLoader
                    className=""
                    loading_text={t("settings.packing_options.container.save_processing")}
                    loading={isSavingInProgress}
                    onClick={handleSaveOptions}
                >
                    {t("settings.packing_options.container.save_options")}
                </ButtonLoader>
            </div>
        </div>
    )
}

export default PackingOptionsContainer
