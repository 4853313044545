import { InformationCircleIcon } from '@heroicons/react/outline';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

export const StatusHighlighted = ({
  status = "unknown",
  className = "",
  statuses = [],
  description,
  ...props
}) => {
  const { i18n } = useTranslation();

  const statusDef = statuses.find((item) => item.status === status) || statuses[0]

  const tooltip = statusDef.tooltip

    // We should remove this once we start showing the returned status.
  if (!description) {
      return (
          <span className={`p-1.5 text-base font-semibold rounded-lg ${statusDef.classes} ${className}`} {...props}>
            <span>
              {i18n.t(statusDef.label)}
              {tooltip && <Tooltip title={i18n.t(tooltip)}><InformationCircleIcon className='ml-1 mb-1 w-4 h-4 inline'/></Tooltip>}
            </span>
          </span>
      );
  } else {
    const classes = statusDef.classes.split(' ')
    const textClasses = classes.filter(class_entry => class_entry.includes('text'))
    const otherClasses = classes.filter(class_entry => !class_entry.includes('text'))

    return  <div className='flex'>
              <div className={`p-1.5 text-base rounded-lg ${otherClasses.join(' ')}`}>
                <span className={`font-semibold ${textClasses.join(' ')}`} {...props}>
                    {i18n.t(statusDef.label)}
                    {tooltip && <Tooltip title={i18n.t(tooltip)}><InformationCircleIcon className='ml-1 mb-1 w-4 h-4 inline'/></Tooltip>}
                </span>
                <br/>
                <span className="italic text-xs text-gray-500">{i18n.t(`statuses.tracking_descriptions.${description}`)}</span>
              </div>
          </div>
  }

};
