import { Skeleton } from "antd"
import { memo } from "react"

export const BarListSkeleton = ({ children, active, lines = 5 }) => {
    if (active) {
        // return <Skeleton active paragraph={{ rows: lines, width: "100%" }} className="" title={false} />
        return (
            <div className="w-full space-y-2">
                <SkeletonLines lines={lines} />
            </div>
        )
    }
    return children
}

const SkeletonLines = memo(({ lines }) => {
    const emptyArray = Array.from({ length: lines })
    return (
        <>
            {emptyArray.map((_, i) => (
                <Skeleton.Button active size="large" shape="square" block key={i} className="" />
            ))}
        </>
    )
})
