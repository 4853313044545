import { SearchIcon } from "@heroicons/react/solid";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import {debounce as _debounce} from 'lodash'
import { CloseIcon } from "./Icons/CloseIcon";
import i18n from "../translations/index"

export const SearchInput = forwardRef(({ 
    inputValue = '', 
    onChange = (v) => {},
    className = "",
    placeholder = i18n.t("search_input.search"),
    debounce = 0,
    numericOnly = false
}, ref) => {

    useImperativeHandle(ref, () => ({resetInput: resetInput}))

    const [searchText , setSearchText] = useState('')

    useEffect(() => {
        setSearchText(inputValue)
    }, [inputValue])

    const resetInput = () => {
        setSearchText('')
        onChange('')
    }

    // RESET INPUT IF ESC IS PRESSED WHILE ACTIVE
    const onKeyDown = (e) => {
        if(e.keyCode === 27) {
            resetInput()
        }
    }

    const handleChange = (e) => {
        let newVal = e.target.value
        if (numericOnly) {
            newVal = newVal.replace(/\D/g,'')
        }
        setSearchText(newVal)
        if (debounce > 0) {
            debouncedChange(newVal)
        }
        else {
            onChange(newVal)
        }
    }

    const debouncedChange = useCallback(_debounce(onChange, debounce), [])

    const hasText = () => {
        return searchText.length > 0
    }

    return (
        <div className={className ? 'relative '+className : 'relative'}>
            <div className={"absolute inset-y-0 left-0 pl-3 flex items-center " + (hasText() ? 'cursor-pointer' : 'pointer-events-none')} onClick={resetInput}>
                { hasText() ?
                    <CloseIcon className="h-5 w-5" />
                    :
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                }
            </div>
            <input
                className="min-w-6 block w-full font-normal bg-white py-2 pl-10 pr-3 border border-gray-300 rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
                placeholder={placeholder}
                name="search"
                value={searchText}
                onChange={handleChange}
                onKeyDown={onKeyDown}
            />
        </div>
    )
})
