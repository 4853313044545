import {useEffect, useState} from 'react'
import { Switch } from '@headlessui/react'
import PropTypes from "prop-types";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function SwitchPanel({
    title="Title",
    description="Description",
    value=true,
    disabled,
    onChange,
    clickableLabel=false
} ) {

    SwitchPanel.propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        value: PropTypes.bool.isRequired,
        disabled: PropTypes.bool.isRequired,
        onChange: PropTypes.func
    }

    //const [enabled, setEnabled] = useState(value)

    /*useEffect(() => {
        if(onChange!==null)
            onChange(enabled)
    }, [enabled])*/

    const onHandleChange = (value) => {
        if(disabled===false)
            onChange(value)
    }

    return (
        <Switch.Group as="div" className={`flex items-center justify-between ${clickableLabel ? 'cursor-pointer' : ''}`}>
            <Switch.Label as="span" className="flex-grow flex flex-col" passive={!clickableLabel}>
                <span className="text-sm font-medium text-gray-900">{title}</span>
                <span className="text-sm text-gray-500">{description}</span>
            </Switch.Label>
            <Switch
                checked={value}
                onChange={onHandleChange}
                className={classNames(
                    value ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                )}
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className={classNames(
                        value ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                />
            </Switch>
        </Switch.Group>
    )
}
