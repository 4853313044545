import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";

import { InputProductQuantity, ConditionProductsSelector } from "../../../components";

const ForEveryProductCondition = ({conditionContent, onUpdateConditionContent, onAllSkuExists}) => {
    const { t } = useTranslation()
    const [allSkuExists, setAllSkuExists] = useState(true)

    const forEveryQuantityErrorMessage = useMemo(() => {
        return Number(conditionContent.for_every_quantity) > 0 ? "" : t("settings.business_rule_creator.business_rule_statements.for_every_product.error_messages.missing_for_every_quantity")
    }, [conditionContent, t])

    const productsErrorMessage = useMemo(() => {
        return (!conditionContent.forEveryProduct || conditionContent.forEveryProduct.length <= 0) && !conditionContent.anyProduct ? t("settings.business_rule_creator.business_rule_statements.for_every_product.error_messages.missing_products") : ""
    }, [conditionContent, t])

    const maxMultipliedQuantityErrorMessage = useMemo(() => {
        let errorMessage = ""

        if((!conditionContent.maxMultipliedQuantity || conditionContent.maxMultipliedQuantity <= 0) && !conditionContent.withoutMaxMultipliedQuantity){
            errorMessage = t("settings.business_rule_creator.business_rule_statements.for_every_product.error_messages.invalid_maximum_quantity")
        }

        return errorMessage
    }, [conditionContent, t])

    const handleOnChangeAnyProduct = () => {
        const newAnyProductValue = !conditionContent.anyProduct
        const newProducts = newAnyProductValue ? null : conditionContent.forEveryProduct

        onUpdateConditionContent({...conditionContent, anyProduct: newAnyProductValue, forEveryProduct: newProducts})
    }

    const handleOnAllSkuExists = (newAllSkuExists) => {
        setAllSkuExists(newAllSkuExists)
        onAllSkuExists(newAllSkuExists)
    }

    return (
        <div>
            <div className={`p-5 border flex flex-col gap-2 ${forEveryQuantityErrorMessage !== "" ? "border-red-300" : ""}`}>
                <div className="flex gap-2">
                    <div className="font-semibold">
                        {t("settings.business_rule_creator.business_rule_statements.for_every_product.title_for_every_product")}
                        <span className="text-red-500">{" *"}</span>
                    </div>

                    {forEveryQuantityErrorMessage === "" ? (
                        <CheckCircleIcon className="h-6 text-green-600" />
                    ) : (
                        <ExclamationCircleIcon className="h-6 text-red-600" />
                    )}
                </div>

                <InputProductQuantity
                    quantity={conditionContent.for_every_quantity}
                    onQuantityChange={(newForEveryQuantity) => onUpdateConditionContent({...conditionContent, for_every_quantity: newForEveryQuantity})}
                />

                {forEveryQuantityErrorMessage !== "" && (
                    <div className="text-red-500">
                        {forEveryQuantityErrorMessage}
                    </div>
                )}
            </div>

            <div className={`p-5 border flex flex-col gap-2 ${productsErrorMessage !== "" || !allSkuExists ? "border-red-300" : ""}`}>
                <div className="flex gap-2">
                    <div className="font-semibold">
                        {t("settings.business_rule_creator.business_rule_statements.for_every_product.title_from_sku")}
                        <span className="text-red-500">{" *"}</span>
                    </div>

                    {productsErrorMessage === "" && allSkuExists ? (
                        <CheckCircleIcon className="h-6 text-green-600" />
                    ) : (
                        <ExclamationCircleIcon className="h-6 text-red-600" />
                    )}
                </div>

                <div className="w-10/12">
                    <ConditionProductsSelector
                        products={conditionContent.forEveryProduct}
                        anyProduct={conditionContent.anyProduct}
                        onUpdateProducts={(newProducts) => onUpdateConditionContent({...conditionContent, forEveryProduct: newProducts})}
                        onUpdateAnyProduct={handleOnChangeAnyProduct}
                        onAllSkuExists={handleOnAllSkuExists}
                        addProductButtonText={t("settings.business_rule_creator.business_rule_statements.for_every_product.button_add_product")}
                        anyProductText={t("settings.business_rule_creator.business_rule_statements.for_every_product.any_product")}
                        singleSkuErrorText={t("settings.business_rule_creator.business_rule_statements.for_every_product.single_sku_error")}
                        multipleSkuErrorText={t("settings.business_rule_creator.business_rule_statements.for_every_product.multiple_sku_error")}
                        anyProductPlaceholder={t("settings.business_rule_creator.business_rule_statements.for_every_product.any_product_placeholder")}
                        notAnyProductPlaceholder={t("settings.business_rule_creator.business_rule_statements.for_every_product.not_any_product_placeholder")}
                    />
                </div>

                <div className="font-semibold">
                    {t("settings.business_rule_creator.business_rule_statements.for_every_product.title_complement_from_sku")}
                </div>

                {productsErrorMessage !== "" && (
                    <div className="text-red-500">
                        {productsErrorMessage}
                    </div>
                )}
            </div>

            <div className={`p-5 border flex flex-col gap-2 ${maxMultipliedQuantityErrorMessage !== "" ? "border-red-300" : ""}`}>
                <div className="flex gap-2">
                    <div className="font-semibold">
                        {t("settings.business_rule_creator.business_rule_statements.for_every_product.title_max_quantity")}
                        <span className="text-red-500">{" *"}</span>
                    </div>

                    {maxMultipliedQuantityErrorMessage === "" ? (
                        <CheckCircleIcon className="h-6 text-green-600" />
                    ) : (
                        <ExclamationCircleIcon className="h-6 text-red-600" />
                    )}
                </div>

                <div className="flex gap-2">
                    <InputProductQuantity
                        quantity={conditionContent.maxMultipliedQuantity}
                        onQuantityChange={(newMaxMultipliedQuantity) => onUpdateConditionContent({...conditionContent, maxMultipliedQuantity: newMaxMultipliedQuantity})}
                        isDisabled={conditionContent.withoutMaxMultipliedQuantity}
                        isEmptyInputInvalid={!conditionContent.withoutMaxMultipliedQuantity}
                    />

                    <label className="flex gap-2 items-center pr-2">
                        <input
                            type="checkbox"
                            className="border-gray-300 focus:ring-0"
                            checked={conditionContent.withoutMaxMultipliedQuantity}
                            onChange={() => onUpdateConditionContent({
                                ...conditionContent,
                                withoutMaxMultipliedQuantity: !conditionContent.withoutMaxMultipliedQuantity,
                                maxMultipliedQuantity: null
                            })}
                        />
                        <span className="shrink-0">
                            {t("settings.business_rule_creator.business_rule_statements.for_every_product.option_max_quantity")}
                        </span>
                    </label>
                </div>

                {maxMultipliedQuantityErrorMessage !== "" && (
                    <div className="text-red-500">
                        {maxMultipliedQuantityErrorMessage}
                    </div>
                )}
            </div>
        </div>
    )
}

export default ForEveryProductCondition