export const INITIAL_STATE = [
    {
        id: "id", 
        open: true,
    },
    {
        id: "statuses",
        open: true
    },
    {
        id: "work_order_types",
        open: true
    }
]

export const RESET_STATE = [
    {
        id: "id", 
        open: false,
    },
    {
        id: "statuses",
        open: false
    },
    {
        id: "work_order_types",
        open: false
    }
]
  
export const filterReducer = (state, action) => {
    switch (action.type) {
        case "OPEN":
            return state.map((filter) => {
                if (filter.id === action.id) {
                  return { ...filter, open: true }
                } else {
                  return filter
                }
            })
        case "CLOSE":
            return state.map((filter) => {
                if (filter.id === action.id) {
                    return { ...filter, open: false }
                } else {
                    return filter
                }
            })
        case "RESET":
            return RESET_STATE
        default:
          return state
      }
}