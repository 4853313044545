import React, { useContext } from "react"
import { useQuery } from "react-query"
import { BadgeText } from "../../components/BadgeText"
import { UserContext } from "../../hooks/UserContext"
import { fetchCountPendingReturns } from "../../services/returnServices"

export const ReturnsPendingCountBadge = () => {
  const { user } = useContext(UserContext)

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(
    ["returns_pending_count", user.current_store?.id],
    () => fetchCountPendingReturns({ store_id: user.current_store?.id }),
    {
      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: 60000,
      enabled: user.current_store ? true : false,
    }
  )

  return <>{data && !isError && data.count > 0 && <BadgeText>{data.count}</BadgeText>}</>
}
