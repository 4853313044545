
export const ORDER_RULE_TYPE_ACTIONS = {
    INCLUDE_ORDER_LINE_RULE_ACTION: "INCLUDE_ORDER_LINE_RULE_ACTION",
    ENSURE_ORDER_LINE_RULE_ACTION: "ENSURE_ORDER_LINE_RULE_ACTION",
    CANCEL_ORDER_RULE_ACTION: "CANCEL_ORDER_RULE_ACTION",
    HOLD_ORDER_RULE_ACTION: "HOLD_ORDER_RULE_ACTION"
}

export const BUSINESS_RULE_CATEGORIES = {
    CUSTOM_BUSINESS_RULE_CATEGORY: "CUSTOM_BUSINESS_RULE_CATEGORY",
    SHIPPING_PACKAGE_BUSINESS_RULE_CATEGORY: "SHIPPING_PACKAGE_BUSINESS_RULE_CATEGORY"
}

export const AVAILABLE_ACTION_OPTIONS = {
    ADD_SHIPPING_PACKAGE_OPTION: "ADD_SHIPPING_PACKAGE_OPTION",
    ADD_PRODUCT_OPTION: "ADD_PRODUCT_OPTION",
    PAUSE_ORDER_OPTION: "PAUSE_ORDER_OPTION",
    CANCEL_ORDER_OPTION: "CANCEL_ORDER_OPTION"
}

export const AVAILABLE_ACTION_OPTIONS_FOR_BR = {
    ADD_SHIPPING_PACKAGE_OPTION: "ADD_SHIPPING_PACKAGE_OPTION",
    ADD_INSERT_OPTION: "ADD_INSERT_OPTION",
    PAUSE_ORDER_OPTION: "PAUSE_ORDER_OPTION",
    CANCEL_ORDER_OPTION: "CANCEL_ORDER_OPTION"
}

export const ALL_AVAILABLE_ACTION_OPTIONS = {
    ...AVAILABLE_ACTION_OPTIONS,
    ...AVAILABLE_ACTION_OPTIONS_FOR_BR
}

export const AVAILABLE_PRODUCT_INSERT_OPTIONS = {
    INCLUDE_PRODUCT: "INCLUDE_PRODUCT",
    ENSURE_PRODUCT: "ENSURE_PRODUCT"
}
