import { useTranslation } from 'react-i18next';
import { SelectInput } from '../../SelectInput';
import {useDispatch} from "react-redux";

const pagesInterval = [
    { id: "25", name: '25'},
    { id: "50", name: '50' },
    { id: "100", name: '100' }
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const TablePaginator = ({
    onNextPage= ()=>{},
    onPreviousPage= ()=>{},
    onSelectRangePage= ()=>{},
    canNextPage = false,
    canPreviousPage = false,
    tableTitle = "",
    pageIndex = 0,
    pageCount = 1,
    pagesSize = 0,
    rowsCount = 0,
    showCount = true,
    buttonText,
    buttonOnClick,
    hideBorders = false
}) => {
    const { t, i18n } = useTranslation(); 

    const dispatch = useDispatch()
    
   return (
       <nav className={classNames(hideBorders ? '' : 'border', 'bg-white px-4 py-3 flex justify-between sm:px-0')} aria-label="Pagination">
           {buttonText &&
               <div className="ml-4 mt-1 flex-shrink-0">
                   <button
                       type="button"
                       className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                       onClick={buttonOnClick}
                   >
                       {buttonText}
                   </button>
               </div>
           }
           <div className="flex-1 flex justify-between px-6 sm:justify-end">
                <div className=''>
                    <span className="text-sm text-gray-700 text-center flex ml-10 sm:col-span-3 sm:ml-0">
                        <label className='inline-flex items-center ml-1 sm:mr-3'>{tableTitle}  {i18n.t("paginator.per_page")} </label>
                        <SelectInput
                            options={pagesInterval}
                            selectedInitial={pagesInterval.find(option => option.id === pagesSize) ?? pagesInterval[0]}
                            label=''
                            className="inline-flex text-sm items-center px-4 sm:px-0"
                            onChange={(page) => {
                                onSelectRangePage(page.id)
                            }}
                            value={pagesInterval.find(option => option.id === pagesSize) ?? pagesInterval[0]}
                        />
                    </span>
                </div>
                <div className="hidden sm:block mr-5 mt-3 sm:ml-3">
                    <div className="text-sm text-gray-700">
                        {i18n.t("paginator.page")} <span className="text-sm text-gray-700 font-semibold">{pageIndex+1}</span> {i18n.t("paginator.of")} <span className="text-sm text-gray-700 font-semibold">{pageCount}</span>{showCount? <label>, {i18n.t("paginator.total_elements")} <span className="font-medium font-semibold">{rowsCount}</span></label>:<p></p>}
                    </div>
                </div>
               <div className='mt-1'>
                <a
                    onClick={() => {canPreviousPage && onPreviousPage() }}
                    disabled={ !canPreviousPage }
                    className="cursor-pointer relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 has-tooltip"
                >
                    <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                        {i18n.t("paginator.previous")}
                    </span>
                    {"<"}
                </a>
                <a
                    onClick={() =>{ canNextPage && onNextPage() }}
                    disabled={ !canNextPage }
                    className="cursor-pointer ml-3 relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 has-tooltip"
                >
                    <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                        {i18n.t("paginator.next")}
                    </span>

                    {">"}
                </a>
               </div>
           </div>
       </nav>
   )
}