import { InformationCircleIcon } from "@heroicons/react/outline"
import { ExclamationIcon } from "@heroicons/react/solid"
import { Tooltip } from "antd"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
    CheckIcon,
    FastTruckIcon,
    HandHoldingDollarIcon,
    LoaderSpinner,
    LocationDotIcon,
    LocationPinLockIcon,
    ShopIcon,
    ShopLockIcon,
    TrashIcon,
} from "../Icons/CustomAppIcons"
import { checkShippingMethodCompatibility, ShippingMethodTable } from "./ShippingMethodTable"

const ShippingGroupPriorityPill = ({ priority, className = "" }) => {
    const { t } = useTranslation()

    let pillPriorityClass = null
    let pillPriorityIcon = null
    if (priority === "CHEAPEST") {
        pillPriorityClass = "bg-blue-700 text-white"
        pillPriorityIcon = <HandHoldingDollarIcon className="h-4 w-4 inline -mt-1 mr-2" />
    } else {
        pillPriorityClass = "bg-yellow-600 text-white"
        pillPriorityIcon = <FastTruckIcon className="h-4 w-4 inline -mt-1 mr-2" />
    }
    return (
        <div className={`inline-block rounded-full py-1 px-3 text-sm font-semibold opacity-80 ${pillPriorityClass} ${className}`}>
            <span>{pillPriorityIcon}</span>
            {t(`shipping_groups.priorities.${priority}`)}
        </div>
    )
}

const CoveragePreferences = ({ allowsExtendedZones, allowsPOBoxDelivery }) => {
    const { t } = useTranslation()

    return (
        <div>
            <div className="font-semibold pb-2">Preferencias de Cobertura</div>
            <Tooltip title={t("shipping_groups.allows_extended_zones.tip")}>
                <div>
                    {allowsExtendedZones ? (
                        <span className="text-xs">
                            <LocationDotIcon className="inline w-4 h-4 mr-2 mb-1 text-gray-500" />
                            <span>{t("shipping_groups.allows_extended_zones.yes")}</span> 
                            <InformationCircleIcon className="inline w-4 h-4 ml-1 mb-1" />
                        </span>
                    ) : (
                        <span className="text-xs">
                            <LocationPinLockIcon className="inline w-4 h-4 mr-2 mb-1 text-gray-500" />
                            <span>{t("shipping_groups.allows_extended_zones.no")}</span>
                            <InformationCircleIcon className="inline w-4 h-4 ml-1 mb-1" />
                        </span>
                    )}
                </div>
            </Tooltip>
            <Tooltip title={t("shipping_groups.allows_pobox_delivery.tip")}>
                <div>
                    {allowsPOBoxDelivery ? (
                        <span className="text-xs">
                            <ShopIcon className="inline w-4 h-4 mr-2 mb-1 text-gray-500" />
                            <span>{t("shipping_groups.allows_pobox_delivery.yes")}</span>
                            <InformationCircleIcon className="inline w-4 h-4 ml-1 mb-1" />
                        </span>
                    ) : (
                        <span className="text-xs">
                            <ShopLockIcon className="inline w-4 h-4 mr-2 mb-1 text-gray-500" />
                            <span>{t("shipping_groups.allows_pobox_delivery.no")}</span>
                            <InformationCircleIcon className="inline w-4 h-4 ml-1 mb-1" />
                        </span>
                    )}
                </div>
            </Tooltip>
        </div>
    )
}

export const ShippingGroupCard = ({
    className = "",
    shippingGroupData = [],
    clickable = false,
    selectable = false,
    onClick = () => {},
    selected = false,
    busy = false,
    integrationShippingMethodType = null,
}) => {
    const { t } = useTranslation()

    const standardGroupShippingMethods = useMemo(
        () => shippingGroupData.shipping_method_groups.filter((groupShippingMethod) => groupShippingMethod.group_role === "STANDARD"),
        [shippingGroupData]
    )
    const fallbackGroupShippingMethods = useMemo(
        () => shippingGroupData.shipping_method_groups.filter((groupShippingMethod) => groupShippingMethod.group_role === "FALLBACK"),
        [shippingGroupData]
    )

    const allStandardShippingMethodsAreIncompatible = useMemo(() => {
        let allshippingMethodsAreIncompatible = true
        for (const standardGroupShippingMethod of standardGroupShippingMethods) {
            // console.log(`checking compatibility of standardGroupShippingMethod ${standardGroupShippingMethod.shipping_method.shipping_type} and integrationShippingMethodType ${integrationShippingMethodType}`)
            if (checkShippingMethodCompatibility(standardGroupShippingMethod.shipping_method, integrationShippingMethodType)) {
                allshippingMethodsAreIncompatible = false
                // console.log(`compatible!`)
                break
            }
            // console.log(`INCOMPATIBLE!`)
        }
        return allshippingMethodsAreIncompatible
    }, [standardGroupShippingMethods])

    let selectableContainerClass = ""
    if (clickable || !selectable) {
        selectableContainerClass = "hover:shadow-lg cursor-pointer"
    }
    if (selectable) {
        selectableContainerClass = "hover:shadow-lg"
        if (busy) {
            if (selected) {
                selectableContainerClass += " border-blue-500"
            }
        } else {
            selectableContainerClass += " cursor-pointer"
            if (selected) {
                selectableContainerClass += " border-green-500"
            }
        }
    }
    return (
        <div
            className={`border border-opacity-70 border-gray-200 rounded p-4 text-gray-700 shadow bg-white relative flex flex-col ${selectableContainerClass} ${className} `}
            onClick={() => {
                if (!busy) onClick()
            }}
        >
            {selected && busy ? (
                <div className="absolute top-0 right-0 p-4">
                    <LoaderSpinner className="w-7 h-7 text-blue-500" />
                </div>
            ) : (
                selected && (
                    <div className="absolute top-0 right-0 p-4">
                        <CheckIcon className="w-7 h-7 text-green-500" />
                    </div>
                )
            )}
            <div className="font-semibold pb-2">
                <div className="text-xl">{shippingGroupData.name}</div>
                <ShippingGroupPriorityPill priority={shippingGroupData.priority} className="my-1" />
            </div>
            <div className="flex flex-row">
                <div>
                    <div className="font-semibold pb-2">
                        <div>{t("integrations_list.shipping.methods_of_shipment")}</div>
                    </div>
                    <div className="">
                        <ShippingMethodTable
                            groupShippingMethods={standardGroupShippingMethods}
                            integrationShippingMethodType={integrationShippingMethodType}
                        />
                    </div>
                </div>
                <div className="pl-6 md:pl-16">
                    <CoveragePreferences
                        allowsExtendedZones={shippingGroupData?.allow_extended_zones}
                        allowsPOBoxDelivery={shippingGroupData?.allow_pobox_dropoff}
                    />
                    <div className="font-semibold pb-2 mt-2">
                        <div>
                            <Tooltip title={t("shipping_groups.fallback_tip")}>
                                <span>
                                    {t("shipping_groups.fallback")}
                                    <InformationCircleIcon className="inline w-4 h-4 ml-1 mb-1" />
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="">
                        {fallbackGroupShippingMethods.length > 0 ? (
                            <ShippingMethodTable
                                groupShippingMethods={fallbackGroupShippingMethods}
                                integrationShippingMethodType={integrationShippingMethodType}
                            />
                        ) : integrationShippingMethodType === "international" ? (
                            <div className="text-gray-500 flex">
                                <div className="italic text-xs max-w-16">{t("shipping_groups.without_fallback")}</div>
                            </div>
                        ) : (
                            <div className="text-red-500 flex">
                                <div>
                                    <ExclamationIcon className="w-5 h-5 mr-1" />
                                </div>
                                <div className="italic text-xs max-w-16">{t("shipping_groups.without_fallback_warning")}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {allStandardShippingMethodsAreIncompatible && (
                <div className="text-red-500 flex mt-4 ">
                    <div>
                        <ExclamationIcon className="w-5 h-5 mr-1" />
                    </div>
                    <div className="italic font-semibold text-xs max-w-30">{t("shipping_groups.all_incompatible_shipping_methods")}</div>
                </div>
            )}
        </div>
    )
}

export const RemoveShippingGroupCard = ({ onClick = () => {}, busy = false }) => {
    const { t } = useTranslation()
    let busyClass = null
    if (!busy) {
        busyClass = " cursor-pointer hover:opacity-80"
    }

    return (
        <div
            className={`border border-opacity-70 border-gray-200 rounded p-4 text-gray-700 shadow relative bg-red-500 hover:shadow-lg opacity-60 ${busyClass}`}
            onClick={() => onClick()}
        >
            {busy && (
                <div className="absolute right-0 pr-4">
                    <LoaderSpinner className="w-5 h-5 text-red-500" />
                </div>
            )}
            <div className="flex items-center justify-center text-white">
                <div className="pr-3">
                    <TrashIcon className="h-5 w-5 -mt-1 inline" />
                </div>
                <div>
                    <span className="font-semibold">{t("integrations_list.shipping.unassign_shipping_group")}</span>
                </div>
            </div>
        </div>
    )
}
