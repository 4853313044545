
import { useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { UserContext } from "../../hooks/UserContext"
import { ConfirmDialog } from "../../components"
import { Button } from "../../components"
import { WorkOrderSettings } from "."
import { CARD_STEPS, MATERIAL_IN_TRANSIT_TO_WAREHOUSE } from "./utils/constants"

export const WorkOrderCreator = ({ formState, handleChange, onCreateWorkOrder, onCancelWorkOrderCreation }) => {
    const { t, i18n } = useTranslation()
    const { user } = useContext(UserContext)
    const [createWorkOrderDialog, setCreateWorkOrderDialog] = useState({ open: false })
    const [discardWorkOrderDialog, setDiscardWorkOrderDialog] = useState({ open: false })
    const [workOrderToPreview, setWorkOrderToPreview] = useState(false)
    const [savingWorkOrder, setSavingWorkOrder] = useState(false)

    const handleOnPreviewWorkOrder = () => {
        handleChange('section1', CARD_STEPS.IN_REVIEW)
        handleChange('section2', CARD_STEPS.IN_REVIEW)
        handleChange('section3', CARD_STEPS.IN_REVIEW)
        handleChange('section4', CARD_STEPS.IN_REVIEW)
        setWorkOrderToPreview(true)
    }

    const handleOnEditManufactureOrder = () => {
        handleChange('section1', CARD_STEPS.COMPLETED)
        handleChange('section2', CARD_STEPS.COMPLETED)
        handleChange('section3', CARD_STEPS.COMPLETED)
        handleChange('section4', CARD_STEPS.COMPLETED)
        setWorkOrderToPreview(false)
    }

    const handleOnCreateWorkOrder = async () => {
        setSavingWorkOrder(true)
        await onCreateWorkOrder()
        setCreateWorkOrderDialog({ open: false })
    }

    const handleOnDiscardWorkOrder = () => {
        onCancelWorkOrderCreation()
        setDiscardWorkOrderDialog({ open: false })
    }

    const validatePreviewButton = () => {
        const isReplenishmentValid = formState.replenishmentId.length > 0 && formState.replenishmentId !== ""
        const areNotesValid = formState.notes.length > 0 && formState.notes !== ""
        const areRequiredFieldsValid = formState.requiredMaterial?.length > 0 && formState.workOrderType?.length > 0

        if (isReplenishmentValid && areNotesValid && areRequiredFieldsValid) {
            if (formState.requiredMaterial === MATERIAL_IN_TRANSIT_TO_WAREHOUSE) {
                return false
            } else {
                return !(formState.remOrderId.length > 0 && formState.remOrderId !== "")
            }
        }
        return true
    }

    return (
        <div className="flex flex-col gap-5">
            <div className="flex justify-between">
                <h1 className="text-3xl font-semibold">
                    {!workOrderToPreview ? t("work_orders.title") : t("work_orders.title_preview")}
                </h1>
                <Button
                    type="cancel"
                    onClick={() => setDiscardWorkOrderDialog({ open: true })}
                >
                    {i18n.t("work_orders.cancel_creation.button")}
                </Button>
            </div>

            <div className="flex flex-col gap-5">
                <>
                    <WorkOrderSettings
                        formState={formState}
                        handleChange={handleChange}
                    />
                </>
            </div>

            <div className="flex gap-10 bg-gray-50 rounded p-5">
                {!workOrderToPreview ? (
                    <>
                        <Button
                            type="cancel"
                            className="w-full"
                            onClick={() => setDiscardWorkOrderDialog({ open: true })}
                        >
                            {i18n.t("work_orders.cancel_creation.button")}
                        </Button>

                        <Button
                            type="primary"
                            className={`w-full`}
                            onClick={() => handleOnPreviewWorkOrder()}
                            disabled={validatePreviewButton()}
                        >
                            {i18n.t("work_orders.create_work_order.preview_work_order_button")}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            type="secondary"
                            className="w-full"
                            onClick={() => handleOnEditManufactureOrder()}
                        >
                            {i18n.t("work_orders.create_work_order.edit_work_order_button")}
                        </Button>

                        <Button
                            type="primary"
                            className="w-full"
                            onClick={() => setCreateWorkOrderDialog({ open: true })}
                        >
                            {i18n.t("work_orders.create_work_order.save_work_order_button")}
                        </Button>
                    </>
                )}
            </div>

            <ConfirmDialog
                open={discardWorkOrderDialog.open}
                setOpen={(isOpen) => setDiscardWorkOrderDialog({ open: isOpen })}
                title={i18n.t("work_orders.cancel_creation.dialog_title")}
                description={i18n.t("work_orders.cancel_creation.dialog_description")}
                confirmLabel={i18n.t("work_orders.cancel_creation.dialog_confirm_button")}
                cancelLabel={i18n.t("work_orders.cancel_creation.dialog_cancel_button")}
                onCancel={() => setDiscardWorkOrderDialog({ open: false })}
                onConfirm={handleOnDiscardWorkOrder}
            />

            <ConfirmDialog
                open={createWorkOrderDialog.open}
                setOpen={(isOpen) => setCreateWorkOrderDialog({ open: isOpen })}
                title={i18n.t("work_orders.create_work_order.dialog_title")}
                description={i18n.t("work_orders.create_work_order.dialog_description")}
                confirmLabel={i18n.t("work_orders.create_work_order.dialog_confirm_button")}
                cancelLabel={i18n.t("work_orders.create_work_order.dialog_cancel_button")}
                onCancel={() => setCreateWorkOrderDialog({ open: false })}
                onConfirm={handleOnCreateWorkOrder}
                loading={savingWorkOrder}
            />
        </div>
    )
}
