import { useState, useContext, useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

import StoreBillingTotals from "./StoreBillingTotals"
import StoreBillingAverages from "./StoreBillingAverages"
import { SelectInput, Button, PageView, PageTopBar, Notification } from "../../components"
import { UserContext } from "../../hooks/UserContext"
import { getBillingSummary } from "../../services/billingServices"
import { fetchStoreShippingPackages } from "../../services/storeServices"
import { fetchWarehouseVolumeCategories } from "../../services/warehouseService"
import { Loader } from "../../components/Loader"
import { StoreBillingServicesSummary } from "./Summaries"
import { getOrderDetail, getStatementServiceSummary, getStatementSummaryPDF, getStatements, getBillingTotals, getReturnDetail, getClaimDetail, getBillingServiceTotals } from "../../services/storeBillingStatementService"
import { getCurrentStorageServiceUsages } from "../../services/storeBillingServiceUsagesService"
import { notificationTypes } from "../../components/Notification"
import { RedirectToStorePath } from "../../navigation/StoreRouter"
import { DASHBOARD } from "../../navigation"
import StoreBillingOrderDetail from "./Summaries/StoreBillingOrderDetail"
import StoreBillingReturnDetail from "./Summaries/StoreBillingReturnDetail"
import StoreBillingClaimDetail from "./Summaries/StoreBillingClaimDetail"

const billingSummaryOptions = {
    SERVICES_SUMMARY: "SERVICES_SUMMARY",
    ORDER_DETAIL: "ORDER_DETAIL",
    RETURN_DETAIL: "RETURN_DETAIL",
    CLAIM_DETAIL: "CLAIM_DETAIL"
}

const StoreBilling = () => {
    const { i18n } = useTranslation()
    const exportServicesSummaryLinkRef = useRef(null)
    const exportStatementSummaryLinkRef = useRef(null)
    const [selectedTab, setSelectedTab] = useState(billingSummaryOptions.SERVICES_SUMMARY)
    const [statementOptions, setStatementOptions] = useState([])
    const [selectedStatementId, setSelectedStatementId] = useState(null)
    const [selectedStatementOption, setSelectedStatementOption] = useState(null)
    const [notification, setNotification] = useState({type: notificationTypes.SUCCESS, show: false, title: ""})
    const [isExportingCSV, setIsExportingCSV] = useState(false)
    const [isExportingStatementSummaryPDF, setIsExportingStatementSummaryPDF] = useState(false)
    const [warehouseVolumeCategories, setWarehouseVolumeCategories] = useState(null)
    const [storeShippingPackages, setStoreShippingPackages] = useState(null)

    const { user } = useContext(UserContext)

    const DEFAULT_QUERY_STALE_TIME = 1000 * 60 * 5 // 5 minutes

    useEffect(() => {
        window.analytics.track('Billing OMS - Click to Billing site', { store_id: user.current_store.id, store_name: user.current_store.name })
    }, [])
    
    const {
        isLoading: statementsIsLoading,
        data: statementsData
    } = useQuery(['statements', user.current_store.id], ()=>getStatements({
        page: 1,
        store_id: user.current_store.id,
        include_first_store_configuration: true,
        per_page: 13
    }), {
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME
    })

    const {
        isLoading: storeBillingServicesIsLoading,
        data: storeBillingServicesData
    } = useQuery(['billing_summary', user.current_store.id, selectedStatementId], ()=>getBillingSummary({
        store_id: user.current_store.id,
        statement_id: selectedStatementId
    }), {
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME
    })

    const {
        isLoading: storeBillingTotalsIsLoading,
        data: storeBillingTotalsData
    } = useQuery(['billing_totals', user.current_store.id, selectedStatementId], ()=>getBillingTotals(selectedStatementId, {
        store_id: user.current_store.id
    }), {
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME
    })

    const {
        isLoading: serviceTotalsIsLoading,
        data: servicesTotalsData
    } = useQuery(['billing_service_totals', user.current_store.id, selectedStatementId], ()=>getBillingServiceTotals(selectedStatementId, {
        store_id: user.current_store.id
    }), {
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME
    })

    const {
        isLoading: currentStorageServiceUsagesIsLoading,
        data: currentStorageServiceUsagesData
    } = useQuery(['current_storage_service_usages', user.current_store.id, user.current_store.warehouses[0].id, selectedStatementId], ()=>getCurrentStorageServiceUsages({
        store_id: user.current_store.id,
        warehouse_id: user.current_store.warehouses[0].id
    }), {
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME,
        enabled: false // Currently storage service usages are not displayed if the statement is not selected
    })

    function getOptionName(firstDate, lastDate, previousCutoffAt, cutoffAt, formatted_first, formatted_last) {
        let optionName
        if (previousCutoffAt.getMonth() === cutoffAt.getMonth()) {
            let firstDay = firstDate.toLocaleString(i18n.language, { day: "numeric" })
            let lastDay = lastDate.toLocaleString(i18n.language, { day: "numeric" })
            let month = cutoffAt.toLocaleString(i18n.language, { month: "long" })
            month = month.replace(/^\w/, char => char.toUpperCase())
            optionName = i18n.t("billing.statement.options.SHORTENED")
                .replace("{FIRST_DAY}", firstDay)
                .replace("{LAST_DAY}", lastDay)
                .replace("{MONTH}", month)
        } else {
            optionName = i18n.t("billing.statement.options.NORMAL")
                .replace("{FIRST_DATE}", formatted_first)
                .replace("{LAST_DATE}", formatted_last)
        }
        return optionName
    }

    function capitalizeMonth(dateString) {
        if (!dateString) return null
        const parts = dateString.split(" ")
        if (parts.length >= 3) {
            parts[2] = parts[2].charAt(0).toUpperCase() + parts[2].slice(1)
        }
        return parts.join(" ")
    }

    useEffect(() => {
        if (!statementsData) {
            const statementLoadingOptions = [{ id: "default", name: i18n.t("billing.statement.options.LOADING")}]
            setSelectedStatementOption(statementLoadingOptions[0])
            setStatementOptions([])
            return
        }
        let billingConfigCreatedAt = new Date(statementsData?.meta?.first_store_billing_configuration?.valid_from)
        let formattedBillingConfigCreatedAt = capitalizeMonth(billingConfigCreatedAt.toLocaleString(i18n.language, { day: "numeric", month: "long" }))
        const orderedStatementOptions = statementsData?.store_billing_statements
            .map((currentStatement, index, statements) => {
                let optionName = ""
                let cutoffAt = new Date(currentStatement.cutoff_at)
                let previousCutoffAt = new Date()
                let formattedCutoffAt = capitalizeMonth(cutoffAt.toLocaleString(i18n.language, { day: "numeric", month: "long" }))
                
                previousCutoffAt = new Date(statementsData?.store_billing_statements[index + 1]?.cutoff_at)
                if (previousCutoffAt  &&
                    previousCutoffAt.getHours() === 23 &&
                    previousCutoffAt.getMinutes() === 59 &&
                    previousCutoffAt.getSeconds() === 59) {
                    let nextDay = new Date(previousCutoffAt)
                    nextDay.setDate(nextDay.getDate() + 1)
                    if (nextDay.getMonth() !== previousCutoffAt.getMonth()) {
                        previousCutoffAt.setDate(previousCutoffAt.getDate() + 1)
                        previousCutoffAt.setHours(0, 0, 0, 0)
                    }
                }
                let formattedPreviousCutoffAt = capitalizeMonth(previousCutoffAt?.toLocaleString(i18n.language, { day: "numeric", month: "long" }))

                if (index === statementsData?.store_billing_statements.length - 1) {
                    if (billingConfigCreatedAt  &&
                        billingConfigCreatedAt.getHours() === 23 &&
                        billingConfigCreatedAt.getMinutes() === 59 &&
                        billingConfigCreatedAt.getSeconds() === 59) {
                        let nextDay = new Date(billingConfigCreatedAt)
                        nextDay.setDate(nextDay.getDate() + 1)
                        if (nextDay.getMonth() !== billingConfigCreatedAt.getMonth()) {
                            billingConfigCreatedAt.setDate(billingConfigCreatedAt.getDate() + 1)
                            billingConfigCreatedAt.setHours(0, 0, 0, 0)
                        }
                    }
                    
                    optionName = getOptionName(billingConfigCreatedAt, cutoffAt, billingConfigCreatedAt, cutoffAt, formattedBillingConfigCreatedAt, formattedCutoffAt)
                } else {
                    optionName = getOptionName(previousCutoffAt, cutoffAt, previousCutoffAt, cutoffAt, formattedPreviousCutoffAt, formattedCutoffAt)
                }
    
                return {
                    id: currentStatement.id,
                    name: optionName,
                    cutoffAt: cutoffAt,
                    previousCutoffAt: formattedPreviousCutoffAt
                }
            })
    
        let currentCutoffAt = orderedStatementOptions[0]?.cutoffAt
        if (currentCutoffAt  &&
            currentCutoffAt.getHours() === 23 &&
            currentCutoffAt.getMinutes() === 59 &&
            currentCutoffAt.getSeconds() === 59) {
            let nextDay = new Date(currentCutoffAt)
            nextDay.setDate(nextDay.getDate() + 1)
            if (nextDay.getMonth() !== currentCutoffAt.getMonth()) {
                currentCutoffAt.setDate(currentCutoffAt.getDate() + 1)
                currentCutoffAt.setHours(0, 0, 0, 0)
            }
        }

        let formattedCurrentCutoffAt = capitalizeMonth(currentCutoffAt?.toLocaleString(i18n.language, { day: "numeric", month: "long" }))
        
        let newStatementOptions = [{ id: "default", name: i18n.t("billing.statement.options.CURRENT").replace("{FIRST_DATE}", formattedCurrentCutoffAt ?? formattedBillingConfigCreatedAt) }]
    
        newStatementOptions = newStatementOptions.concat(orderedStatementOptions)
        
        // dont show statements before jan 2024
        let jan2024 = new Date("2024-01-02")
        newStatementOptions = newStatementOptions.filter(statement => !statement.cutoffAt || statement.cutoffAt > jan2024)
        
        if (!selectedStatementOption || selectedStatementOption.id === 'default') setSelectedStatementOption(newStatementOptions[0])
        setStatementOptions(newStatementOptions)
    }, [statementsData, i18n.language])
    

    useEffect(() => {
        const fetchWarehousesData = async () => {
            if(storeBillingServicesData?.store_billing_services){
                let storeWarehouseIds = storeBillingServicesData.store_billing_services.map(({warehouse_id}) => warehouse_id)
                storeWarehouseIds = new Set(storeWarehouseIds)

                let currentWarehouseVolumeCategories = []
                let currentStoreShippingPackages = []

                for (const warehouseId of storeWarehouseIds) {
                    const newWarehouseVolumeCategories = await fetchWarehouseVolumeCategories(warehouseId)
                    let newStoreShippingPackages = await fetchStoreShippingPackages(user.current_store.id, { warehouse_id: warehouseId })

                    newStoreShippingPackages = newStoreShippingPackages.map(shippingPackage => ({...shippingPackage, warehouse_id: warehouseId}))

                    currentWarehouseVolumeCategories = [...currentWarehouseVolumeCategories, ...newWarehouseVolumeCategories]
                    currentStoreShippingPackages = [...currentStoreShippingPackages, ...newStoreShippingPackages]
                }

                console.log("currentWarehouseVolumeCategories", currentWarehouseVolumeCategories)
                console.log("currentStoreShippingPackages", currentStoreShippingPackages)

                setWarehouseVolumeCategories(currentWarehouseVolumeCategories)
                setStoreShippingPackages(currentStoreShippingPackages)
            }
        }

        fetchWarehousesData()
    }, [storeBillingServicesData, user])

    const exportCSV = async () => {
        if (isExportingCSV) return
        setIsExportingCSV(true)

        let filename = null
        let params = {store_id: user.current_store.id}
        let response = null

        try{
            switch(selectedTab){
                case billingSummaryOptions.SERVICES_SUMMARY:
                    {
                        window.analytics.track('Billing OMS - Downloaded Billing Summary CSV', { statement_id: selectedStatementId, store_id: user.current_store.id, store_name: user.current_store.name})
                        filename = "billing_summary.csv"
                        response = await getStatementServiceSummary(selectedStatementId, params)
                        break
                    }
                case billingSummaryOptions.ORDER_DETAIL:
                    {
                        window.analytics.track('Billing OMS - Downloaded Order Detail CSV', { statement_id: selectedStatementId, store_id: user.current_store.id, store_name: user.current_store.name})
                        filename = "order_detail.csv"
                        response = await getOrderDetail(selectedStatementId, params)
                        break
                    }
                case billingSummaryOptions.RETURN_DETAIL:
                    {
                        window.analytics.track('Billing OMS - Downloaded Returns Detail CSV', { statement_id: selectedStatementId, store_id: user.current_store.id, store_name: user.current_store.name})
                        filename = "return_detail.csv"
                        response = await getReturnDetail(selectedStatementId, params)
                        break
                    }
                case billingSummaryOptions.CLAIM_DETAIL:
                    {
                        window.analytics.track('Billing OMS - Downloaded Claims Detail CSV', { statement_id: selectedStatementId, store_id: user.current_store.id, store_name: user.current_store.name})
                        filename = "claim_detail.csv"
                        response = await getClaimDetail(selectedStatementId, params)
                        break
                    }
                    default:
                        break
            }
            const contentType = response.headers['content-type']
            const blob = new Blob([response.data], {type: contentType})

            exportServicesSummaryLinkRef.current.href = URL.createObjectURL(blob)
            exportServicesSummaryLinkRef.current.download = filename
            exportServicesSummaryLinkRef.current.click()

            setNotification({type: notificationTypes.SUCCESS, show: true, title: i18n.t("billing.statement.export_success_services_summary")})
        } catch(error){
            setNotification({type: notificationTypes.ERROR, show: true, title: i18n.t("billing.statement.export_error_services_summary")})
        }

        setIsExportingCSV(false)
    }

    const exportStatementSummaryPDF = async () => {
        setIsExportingStatementSummaryPDF(true)

        window.analytics.track('Billing OMS - Downloaded Statement Summary PDF', { statement_id: selectedStatementId, store_id: user.current_store.id, store_name: user.current_store.name})

        try{
            const filename = "billing_summary.pdf"

            const response = await getStatementSummaryPDF(selectedStatementId)
            const contentType = response.headers['content-type']
            const blob = new Blob([response.data], {type: contentType})

            exportStatementSummaryLinkRef.current.href = URL.createObjectURL(blob)
            exportStatementSummaryLinkRef.current.download = filename
            exportStatementSummaryLinkRef.current.click()

            setNotification({type: notificationTypes.SUCCESS, show: true, title: i18n.t("billing.statement.export_success_statement_summary")})
        } catch(error){
            setNotification({type: notificationTypes.ERROR, show: true, title: i18n.t("billing.statement.export_error_statement_summary")})
        }

        setIsExportingStatementSummaryPDF(false)
    }

    const handleOnSelectStatement = (selectedOption) => {
        const selectedStatementId = selectedOption.id === "default" ? null : selectedOption.id
        setSelectedStatementOption(selectedOption)
        setSelectedStatementId(selectedStatementId)

        window.analytics.track('Billing OMS - Changed Selected Statement', { statement_id: selectedStatementId, store_id: user.current_store.id, store_name: user.current_store.name})
    }

    if(!user.current_store?.has_current_billing_configuration || !user.permissions?.read_store_billing_configuration){
        return <RedirectToStorePath path={DASHBOARD} />
    }

    return (
        <PageView
            topMenu={
                <PageTopBar>
                    <div className="text-lg font-semibold">
                        <span className="text-gray-400">
                            {user.current_store.name} &rarr;
                        </span>
                        {i18n.t("billing.page_title")}
                    </div>
                </PageTopBar>
            }
        >

            <div className="flex justify-between mt-5 mb-3">
                <div className="flex items-center w-2/5">
                    <span className="font-bold">{i18n.t("billing.statement.select_statement")}</span>
                    <SelectInput options={statementOptions} value={selectedStatementOption} onChange={handleOnSelectStatement} className="flex-1 ml-4"/>
                </div>
                <div className="flex items-center w-1/4 justify-end">
                    <Button type="secondary" onClick={exportStatementSummaryPDF} className={`${selectedStatementId === null ? "hidden" : ""}`} disabled={isExportingStatementSummaryPDF}>
                        {isExportingStatementSummaryPDF ? i18n.t("billing.statement.exporting_statement_summary") : i18n.t("billing.statement.export_statement_summary")}
                    </Button>
                </div>
            </div>

            <div className="flex flex-col gap-5 pt-5 w-100">
                <StoreBillingTotals isLoading={storeBillingTotalsIsLoading} data={storeBillingTotalsData} />
                <StoreBillingAverages isLoading={serviceTotalsIsLoading} data={servicesTotalsData} />

                <div className="relative">
                    <div className="flex justify-between items-center h-14">

                        <div className="flex">
                            {Object.values(billingSummaryOptions).length > 1 && Object.values(billingSummaryOptions).map(billingSummaryOption => (
                                <button
                                    key={`billing-summary-${billingSummaryOption}`}
                                    onClick={() => setSelectedTab(billingSummaryOption)}
                                    className={`rounded-t-lg px-4 py-4 mr-2 ${selectedTab === billingSummaryOption ? "font-semibold bg-white text-gray-700 cursor-default " : "text-gray-500 cursor-pointer bg-gray-200 hover:bg-gray-300"}`}
                                >
                                    {i18n.t(`billing.summaries_options.${billingSummaryOption}`)}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="rounded-b-lg bg-white pb-5">
                        {selectedTab === billingSummaryOptions.SERVICES_SUMMARY && (
                            <StoreBillingServicesSummary
                                storeBillingServices={storeBillingServicesData?.store_billing_services}
                                storeShippingPackages={storeShippingPackages}
                                warehouseVolumeCategories={warehouseVolumeCategories}
                                currentStorageServiceUsages={selectedStatementId === null ? currentStorageServiceUsagesData?.store_billing_service : null}
                                exportBusy={isExportingCSV}
                                onExport={() => exportCSV()}
                            />
                        )}
                        {selectedTab === billingSummaryOptions.ORDER_DETAIL && (
                            <StoreBillingOrderDetail
                                currency={storeBillingTotalsData?.currency}
                                selectedStatementId={selectedStatementId}
                                onExport={() => exportCSV()}
                                exportBusy={isExportingCSV}
                            />
                        )}
                        {selectedTab === billingSummaryOptions.RETURN_DETAIL && (
                            <StoreBillingReturnDetail
                                onExport={() => exportCSV()}
                                exportBusy={isExportingCSV}
                            />
                        )}
                        {selectedTab === billingSummaryOptions.CLAIM_DETAIL && (
                            <StoreBillingClaimDetail
                                onExport={() => exportCSV()}
                                exportBusy={isExportingCSV}
                            />
                        )}
                    </div>

                    <Loader show={storeBillingServicesIsLoading || !storeShippingPackages || !warehouseVolumeCategories || statementsIsLoading || currentStorageServiceUsagesIsLoading || selectedStatementOption === null} />
                </div>
            </div>


            <Notification
                show={notification.show}
                type={notification.type}
                title={notification.title}
                setShow={showNotification => setNotification({...notification, show: showNotification})}
            />

            <a href=" " className="hidden" ref={exportServicesSummaryLinkRef}>&nbsp;</a>
            <a href=" " className="hidden" ref={exportStatementSummaryLinkRef}>&nbsp;</a>

        </PageView>
    )
}

export default StoreBilling