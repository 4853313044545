import { ChevronDownIcon } from "../Icons/ChevronDownIcon"

export const ExpandibleRow = ({ className = "", expanded = false, text= "no text set", ...props }) => {
  return (
      <div className={"flex items-center "+className} {...props}>
          <ChevronDownIcon className={"w-4 h-4 mr-2 transition-transform transform " + (expanded ? 'rotate-180' : '')}/>
          {text}
      </div>
  )
}
