export const INITIAL_STATE = [
    {
        id: "id", 
        open: false,
    },
    {
        id: "sku", 
        open: false,
    },
    {
        id: "type", 
        open: false
    },
    {
        id: "status", 
        open: false
    },
    {
        id: "completed_at", 
        open: false
    }
];
  
export const filterReducer = (state, action) => {
    switch (action.type) {
        case "OPEN":
            return state.map((filter) => {
                if (filter.id === action.id) {
                  return { ...filter, open: true };
                } else {
                  return filter;
                }
            });
        case "CLOSE":
            return state.map((filter) => {
                if (filter.id === action.id) {
                    return { ...filter, open: false };
                } else {
                    return filter;
                }
            });
        case "RESET":
            return INITIAL_STATE;
        default:
          return state;
      }
};