import {Loader} from "../../components/Loader";
import {CheckCircleIcon, ExclamationCircleIcon, XCircleIcon} from "@heroicons/react/outline";
import {Button} from "../../components";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import { t } from "i18next"
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import {INVENTORY, STORE_PATH} from "../../navigation/constants";
import {useState} from "react";
import {fetchOrderCreateBulkTracker} from "../../services/orderServices";

export default function ProductBulkCreationTracker() {
    const { batch_id: bulkId } = useParams()
    const { url:storePath } = useRouteMatch({ path: STORE_PATH })
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)
    const [isComplete, setIsComplete] = useState(false)
    const [failures, setFailures] = useState(0)
    const [total, setTotal] = useState(0)
    const [pending, setPending] = useState(0)

    const watchExportProgress = function () {
        if (isComplete || failures || total || pending)
            return true

        const intervalId = setInterval(async () => {
            try {
                const {
                    is_complete,
                    failures: _failures,
                    total: _total,
                    pending: _pending
                } = await fetchOrderCreateBulkTracker(bulkId)

                setIsComplete(is_complete)
                setFailures(_failures)
                setTotal(_total)
                setPending(_pending)

                if (isLoading)
                    setIsLoading(false)

                if (is_complete) {
                    clearInterval(intervalId);
                }

            } catch (e) {
                clearInterval(intervalId);
                console.log(e)
            }
        }, 1000)
    }

    watchExportProgress()

    if (isLoading) {
        return <Loader show={true}/>
    }

    if (!isComplete) {
        return (
            <div className="relative bg-white py-16 sm:py-24 lg:py-10 h-full">
                <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                    <h3 className="text-2xl leading-6 font-medium text-gray-900">
                        {t('inventory.create_bulk.we_are_importing', {total_products: total})}
                    </h3>
                    <span className="block italic text-indigo-600">{t("inventory.create_bulk.error_reminder")}</span>
                    <div className="mt-10 mx-auto" style={{ width: 225, height: 225 }}>
                        <CircularProgressbarWithChildren
                            value={total - pending}
                            maxValue={total}
                            strokeWidth={5}
                            styles={
                                buildStyles({
                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: 'round',
                                    // Text size
                                    textSize: '16px',
                                    // How long animation takes to go from one percentage to another, in seconds
                                    pathTransitionDuration: 0.5,
                                    pathColor: '#337dfb',
                                    textColor: '#337dfb',
                                    trailColor: '#eaeaeb',
                                    backgroundColor: '#337dfb'
                                })
                            }
                        >
                            <div>
                                <div className="text-6xl font-bold text-indigo-600">{total - pending}</div>
                                <div className="mt-2 font-semibold text-gray-400">{t("inventory.create_bulk.products")}<br/>{t("inventory.create_bulk.imported")}</div>
                            </div>
                        </CircularProgressbarWithChildren>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="relative bg-white py-16 sm:py-24 lg:py-10 h-full">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                {failures > 0 &&
                    <>
                        {failures === total ?
                            <>
                                <XCircleIcon
                                    className="mx-auto h-10 w-10 text-red-500"
                                    aria-hidden="true"
                                />
                                <h3 className="mt-5 text-2xl leading-6 font-medium text-gray-900">{t("inventory.create_bulk.was_a_problem")}</h3>
                                <div className="block mt-5">
                                    <span className="block">{t("inventory.create_bulk.zero_imported")}</span>

                                    <Button className="mt-10" onClick={()=>{
                                        history.push(storePath + INVENTORY)
                                    }}>{t("inventory.create_bulk.ok")}</Button>
                                </div>
                            </>
                            :
                            <>
                                <ExclamationCircleIcon
                                    className="mx-auto h-10 w-10 text-gray-500"
                                    aria-hidden="true"
                                />
                                <h3 className="mt-5 text-2xl leading-6 font-medium text-gray-900">
                                    {t('inventory.create_bulk.not_all_imported', {products_count: total - failures, total: total })}
                                </h3>

                                <div className="block mt-5">
                                    <span className="block">{t("inventory.create_bulk.zero_imported")}</span>

                                    <Button className="mt-10" onClick={()=>{
                                        history.push(storePath + INVENTORY)
                                    }}>{t("inventory.create_bulk.ok")}</Button>
                                </div>
                            </>
                        }
                    </>
                }
                {failures === 0 &&
                    <>
                        <CheckCircleIcon
                            className="mx-auto h-10 w-10 text-indigo-600"
                            aria-hidden="true"
                        />
                        <h3 className="mt-5 text-2xl leading-6 font-medium text-gray-900">
                            {t('inventory.create_bulk.products_imported', {products_count: total})}
                        </h3>

                        <div className="block mt-5">
                            <span className="block">{t("inventory.create_bulk.thanks_for_waiting")}</span>

                            <Button
                                className="mt-10"
                                onClick={()=>{history.push(storePath + INVENTORY)}}
                            >
                                {t("inventory.create_bulk.view_inventory")}
                            </Button>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}