import { FragileOptionsSection, PackagelessOptionsSection } from '../sections';
import { FragilePackingMaterialsList } from '../components';

const SpecificConfigSection = ({
    packingOptionsContent,
    fragilePackingOptions,
    handleChangeOptions,
    title,
    titleComplement,
    description,
    fragileDescription,
    packagelessDescription,
    packagelessTooltipText,
    warehouseMaterialList,
    storePackingMaterialList,
    onPackingMaterialChange
}) => {
    return (
        <section>
            <div className="flex flex-col mb-2 gap-2">
                <div className="flex gap-2 items-center">
                    <h2 className="mb-0 text-lg font-bold text-gray-900">{title}</h2>
                    <div className="px-2.5 py-0.5 rounded text-xs font-medium bg-gray-100">
                        {titleComplement}
                    </div>
                </div>
                <p className="mb-0">{description}</p>
            </div>

            <div className='flex flex-col gap-6'>
                <FragileOptionsSection
                    tabTitleWithTags={fragileDescription}
                    packingOptionsList={packingOptionsContent.fragilePackingOptionsList}
                    packingOptionsStatuses={fragilePackingOptions}
                    onOptionChange={handleChangeOptions}
                />

                <FragilePackingMaterialsList
                    warehouseMaterialList={warehouseMaterialList}
                    storePackingMaterialList={storePackingMaterialList}
                    onPackingMaterialChange={onPackingMaterialChange}
                    forFragile={true}
                />

                <PackagelessOptionsSection
                    tabTitleWithTags={packagelessDescription}
                    tooltipText={packagelessTooltipText}
                    packingOptionsList={packingOptionsContent.packagelessPackingOptionsList}
                />
            </div>
        </section>
    )
}

export default SpecificConfigSection
