import { useEffect, useMemo, useState, useContext, useCallback } from "react";
import { Select, Tag, Tooltip } from "antd";

import SelectProductDialog from "../../../../components/SelectProductDialog/SelectProductDialog";
import { Button } from "../../../../components";
import { UserContext } from "../../../../hooks/UserContext";
import { fetchSearchProducts } from "../../../../services/productServices";

const ConditionProductsSelector = ({products, onUpdateProducts, anyProduct, onUpdateAnyProduct, addProductButtonText, anyProductText, singleSkuErrorText, multipleSkuErrorText, onAllSkuExists, anyProductPlaceholder="", notAnyProductPlaceholder=""}) => {
    const {user} = useContext(UserContext)
    const [isInitialized, setIsInitialized] = useState(false)
    const [localProducts, setLocalProducts] = useState([])
    const [productsSkuList, setProductsSkuList] = useState([])
    const [openSelectProductDialog, setOpenSelectProductDialog] = useState(false)

    const validateSkuExists = useCallback((products, skuToValidate) => {
        return products?.length > 0 && products.some((product) => product.product_codes.some(({sku}) => sku === skuToValidate))
    }, [])

    const validateSkuList = useCallback((skuListToValidate, productsToValidate) => {
        return skuListToValidate.length === 0 || skuListToValidate.every((productSku) => {
            return productsToValidate.length > 0 && validateSkuExists(productsToValidate, productSku)
        })
    }, [validateSkuExists])

    const SkuTag = ({label, value, closable, onClose}) => {
        const product = localProducts?.find(({product_codes}) => (
            product_codes.some(({sku}) => sku === value)
        ))

        const productSkuListText = product?.product_codes.map(({sku}) => sku).join(", ")

        return (
            <Tag color={product ? "blue" : "red"} closable={closable} onClose={onClose} >
                <Tooltip
                    title={productSkuListText}
                >
                    {label}
                </Tooltip>
            </Tag>
        )
    }

    const PlaceholderText = ({placeholderText}) => {
        return (
            <span className="text-gray-400 text-base">
                {placeholderText}
            </span>
        )
    }

    const productsSkuOptions = useMemo(() => {
        return !productsSkuList ? [] : productsSkuList.map((productSku) => ({label: productSku, value: productSku}))
    }, [productsSkuList])

    const invalidSkuNumber = useMemo(() => {
        return productsSkuList.reduce((accumulator, productSku) => {
            const isValid = validateSkuExists(localProducts, productSku)
            return !isValid ? (accumulator + 1) : accumulator
        }, 0)
    }, [validateSkuExists, localProducts, productsSkuList])

    useEffect(() => {
        if(!isInitialized){
            const newProductSkuList = !products ? [] : products.map((product) => product.product_codes[0].sku)
            const newProductList = !products ? [] : [...products]
            setProductsSkuList(newProductSkuList)
            setLocalProducts(newProductList)
            setIsInitialized(true)
        }
    }, [products, isInitialized])

    const handleOnChangeSkuList = async (updatedSkuInputList) => {
        const skuListToValidate = updatedSkuInputList.filter((insertedProductSku) => (
            productsSkuList.length <= 0 || !validateSkuExists(localProducts, insertedProductSku)
        ))

        let newValidProductList = localProducts.filter((product) => (
            updatedSkuInputList.some((insertedProductSku) => product.product_codes.some(({sku}) => sku === insertedProductSku))
        ))

        if(skuListToValidate.length > 0){
            const newValidProductsIds = []

            let validatedNewProductsList = await fetchSearchProducts(user.current_store.id, skuListToValidate)

            validatedNewProductsList = !validatedNewProductsList?.products ? [] : validatedNewProductsList.products
            const validatedProducts = [...newValidProductList, ...validatedNewProductsList]

            newValidProductList = validatedProducts.filter((product) => {
                const isRepeatedProduct = newValidProductsIds.some((productId) => productId === product.id)

                if(!isRepeatedProduct){
                    newValidProductsIds.push(product.id)
                }

                return !isRepeatedProduct
            })
        }

        const areAllSkuValid = validateSkuList(updatedSkuInputList, newValidProductList)
        const productsIdsList = []

        const nonDuplicatedSkuList = updatedSkuInputList.filter((productSku) => {
            const currentProduct = newValidProductList.find((product) => product.product_codes.some(({sku}) => sku === productSku))
            const isRepeatedProduct = !currentProduct ? false : productsIdsList.some((productId) => productId === currentProduct.id)

            if(!isRepeatedProduct && currentProduct){
                productsIdsList.push(currentProduct.id)
            }

            return !isRepeatedProduct
        })

        setLocalProducts(newValidProductList)
        setProductsSkuList(nonDuplicatedSkuList)

        if(areAllSkuValid){
            onUpdateProducts(newValidProductList)
        }

        onAllSkuExists(areAllSkuValid)
    }

    const handleOnAddValidatedProducts = useCallback((newProducts) => {
        const nonDuplicatedNewProducts = newProducts?.filter((newProduct) => !localProducts?.find((product) => newProduct.id === product.id))
        const updatedSelectedProducts = localProducts ? [...localProducts, ...nonDuplicatedNewProducts] : [...nonDuplicatedNewProducts]

        const newProductsSku = nonDuplicatedNewProducts ? nonDuplicatedNewProducts.map((product) => product.product_codes[0].sku) : []
        const currentProductsSku = productsSkuList ? productsSkuList : []

        const updatedProductsSkuList = [...currentProductsSku, ...newProductsSku]
        const areAllSkuValid = validateSkuList(updatedProductsSkuList, updatedSelectedProducts)

        setLocalProducts(updatedSelectedProducts)
        setProductsSkuList(updatedProductsSkuList)
        setOpenSelectProductDialog(false)

        if(areAllSkuValid){
            onUpdateProducts(updatedSelectedProducts)
        }

        onAllSkuExists(areAllSkuValid)
    }, [localProducts, onUpdateProducts, onAllSkuExists, productsSkuList, validateSkuList])

    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex w-full gap-5 items-center">
                <div className={`flex w-full gap-5 items-center ${anyProduct ? "cursor-not-allowed" : ""}`}>
                    <Select
                        mode="tags"
                        tagRender={SkuTag}
                        placeholder={<PlaceholderText placeholderText={anyProduct ? anyProductPlaceholder : notAnyProductPlaceholder} />}
                        value={productsSkuOptions}
                        className="w-full"
                        tokenSeparators={[" "]}
                        maxTagCount={1000}
                        open={false}
                        onFocus={() => {}}
                        onChange={handleOnChangeSkuList}
                        allowClear={true}
                        disabled={anyProduct}
                        suffixIcon={null}
                    />

                    <Button
                        type="secondary"
                        className={`shrink-0 ${anyProduct ? "cursor-not-allowed" : ""}`}
                        onClick={() => setOpenSelectProductDialog(true)}
                        disabled={anyProduct}
                    >
                        {addProductButtonText}
                    </Button>
                </div>

                <label className="flex gap-2 items-center pr-2 ml-5">
                    <input
                        type="checkbox"
                        className="border-gray-300 focus:ring-0"
                        checked={anyProduct}
                        onChange={onUpdateAnyProduct}
                    />
                    <span className="shrink-0">
                        {anyProductText}
                    </span>
                </label>
            </div>

            {invalidSkuNumber > 0 && (
                <div className="text-red-500">
                    {invalidSkuNumber === 1 ? singleSkuErrorText.replace("{QUANTITY}", invalidSkuNumber) : multipleSkuErrorText.replace("{QUANTITY}", invalidSkuNumber)}
                </div>
            )}

            <SelectProductDialog
                open={openSelectProductDialog}
                setOpen={setOpenSelectProductDialog}
                onAddProducts={handleOnAddValidatedProducts}
                queryExtraParams={{}}
            />
        </div>
    )
}

export default ConditionProductsSelector
