import { memo } from 'react';

export const TextWithBoldTags = memo(({textWithTags}) => {
    const boldRegex = /({BOLD_START}.*?{BOLD_END})/g;
    const textSections = textWithTags.split(boldRegex)

    return (
        textSections.map((section, index) => {
            const elementKey = index + Math.random();

            if(!section.startsWith('{BOLD_START}')){
                return <span key={elementKey}>{section}</span>
            }

            const boldSection = section.replace("{BOLD_START}", "").replace("{BOLD_END}", "")

            return(
                <span className="font-bold mb-0" key={elementKey}>{boldSection}</span>
            )
        })
    )
})
