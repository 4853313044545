import {useContext, useMemo, useState} from "react";
import {getConfigurations, sendNotificationTestEmail, updateTrackingUpdatesEmailNotificationSettings} from "../../services/storeServices";
import {UserContext} from "../../hooks/UserContext";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import {ButtonLoader, Notification} from "../../components";
import { TrackingUpdatesNotificationsCard } from "./TrackingUpdatesNotificationsCard";
import { Button, Skeleton } from "antd";
import _ from "lodash";

const useTrackingUpdatesState = function () {
    const [updateNotification, setUpdateNotification] = useState({
        show: false,
        message: null,
        type: null,
    })
    const [trackingUpdatesConfigIsLoading, setTrackingUpdatesConfigIsLoading] = useState(true)
    const [trackingUpdatesConfig, setTrackingUpdatesConfig] = useState(null)
    const [trackingUpdatesOriginalConfig, setTrackingUpdatesOriginalConfig] = useState(null)
    const { i18n } = useTranslation()
    const notifications = useMemo(() => trackingUpdatesConfig?.notifications, [trackingUpdatesConfig])

    const sendNotificationTestToEmail = async function (store_id, notification_type, email) {
        setTrackingUpdatesConfigIsLoading(true)
        await sendNotificationTestEmail(store_id, notification_type, email).then((response) => {
            setUpdateNotification({
                show: true,
                message: i18n.t('settings.tracking_emails.test_sent'),
                type: 'success',
            })
        }).catch((error) => {
            setUpdateNotification({
                show: true,
                message: i18n.t('settings.tracking_emails.test_error'),
                type: 'error',
            })
        }).finally(() => {
            setTrackingUpdatesConfigIsLoading(false)
        })
        
    }

    const onValidateEmailError = function (email) {
        setUpdateNotification({
            show: true,
            message: email + i18n.t('settings.tracking_emails.invalid_email'),
            type: 'error',
        })
    }

    const resetNotificationsConfig = function () {
        setTrackingUpdatesConfig(_.cloneDeep(trackingUpdatesOriginalConfig))
    }

    const saveNotificationsConfig = async function (store_id) {
        setTrackingUpdatesConfigIsLoading(true)
        await updateTrackingUpdatesEmailNotificationSettings(store_id, trackingUpdatesConfig).then((response) => {
            setUpdateNotification({
                show: true,
                message: i18n.t('settings.tracking_emails.update_success'),
                type: 'success',
            })
            setTrackingUpdatesOriginalConfig(_.cloneDeep(trackingUpdatesConfig))
        }).catch((error) => {
            setUpdateNotification({
                show: true,
                message: i18n.t('settings.tracking_emails.update_error'),
                type: 'error',
            })
        }).finally(() => {
            setTrackingUpdatesConfigIsLoading(false)
        })
    }

    const handleNotificationToggle = async function (notification_type, notification, value) {
        const newTrackingUpdatesConfig = {...trackingUpdatesConfig}
        newTrackingUpdatesConfig.notifications[notification_type][notification] = value
        setTrackingUpdatesConfig(newTrackingUpdatesConfig)
    }

    const setState = function (rawTrackingUpdatesConfig) {
        setTrackingUpdatesConfig(_.cloneDeep(rawTrackingUpdatesConfig))
        setTrackingUpdatesOriginalConfig(_.cloneDeep(rawTrackingUpdatesConfig))
        if (!rawTrackingUpdatesConfig) {
            setTrackingUpdatesConfigIsLoading(false)
            return false
        }
        setTrackingUpdatesConfigIsLoading(false)
    }

    return {
        trackingUpdatesConfig,
        sendNotificationTestToEmail,
        onValidateEmailError,
        updateNotification,
        setTrackingUpdatesConfigIsLoading,
        trackingUpdatesConfigIsLoading,
        setState,
        setUpdateNotification,
        handleNotificationToggle,
        saveNotificationsConfig,
        resetNotificationsConfig,
        trackingUpdatesOriginalConfig,
        notifications
    }
}

export const TrackingUpdatesSettingsContainer = function () {
    const {
        trackingUpdatesConfig,
        sendNotificationTestToEmail,
        onValidateEmailError,
        updateNotification,
        setTrackingUpdatesConfigIsLoading,
        trackingUpdatesConfigIsLoading,
        setState,
        setUpdateNotification,
        handleNotificationToggle,
        saveNotificationsConfig,
        resetNotificationsConfig,
        trackingUpdatesOriginalConfig,
        notifications
    } = useTrackingUpdatesState();

    const {user: { current_store }} = useContext(UserContext);
    const store_id = current_store?.id
    const { i18n } = useTranslation();

    useQuery(
        ["automation_settings-tracking_updates_email_notification", store_id],
        () => getConfigurations(store_id, 'tracking_updates_email_notification'),
        { onSuccess: setState }
    )

    const renderButtons = () =>
        <>
            <ButtonLoader type="secondary" className="mr-3 p-4 rounded-lg" disabled={trackingUpdatesConfigIsLoading || !configHasChanges()} onClick={() => resetNotificationsConfig()}>
                {i18n.t("settings.tracking_emails.cancel")}
            </ButtonLoader>
            <ButtonLoader className="" loading={trackingUpdatesConfigIsLoading} disabled={trackingUpdatesConfigIsLoading || !configHasChanges() } onClick={() => saveNotificationsConfig(store_id)}>
                {i18n.t("settings.tracking_emails.save")}
            </ButtonLoader>
        </>

    const renderSkeleton = () =>
        Array.from({ length: 2 }).map((_) => {
            return <>
                <Skeleton.Button active={true} block={true} size="large" className="mb-6 mt-3 w-1/2"></Skeleton.Button>
                {Array.from({ length: 4 }).map((_) => {
                    return <>
                        <div className="mt-2 flex items-start justify-between">
                            <div className="w-8/12">
                                <Skeleton.Button active={true} block={true} size="small" className="mb-2"></Skeleton.Button>
                            </div>
                            <div className="flex justify-end">
                                <Skeleton.Button active={true} block={false} size="small" className=""></Skeleton.Button>
                            </div>
                        </div>
                        <div className="w-1/4">
                            <Skeleton.Button active={true} block={true} size="small" className="mb-4"></Skeleton.Button>
                        </div>
                    </>
                })}
            </>
        })

    const configHasChanges = () => !_.isEqual(trackingUpdatesConfig, trackingUpdatesOriginalConfig)

    return (
        <>
            <Notification
                show={updateNotification.show}
                setShow={show => setUpdateNotification({...updateNotification, show})}
                type={updateNotification.type}
                title={updateNotification.message}
            />
            
                <div className='relative p-10'>
                    <div className="space-y-1">
                        <h1 className='text-3xl mb-0 font-bold text-blue-gray-900 '>{i18n.t("settings.tracking_emails.title")}</h1>
                        <div className="grid grid-cols-3 items-center">
                            <div className="col-span-2 pr-4">
                                <p className="mt-2 text-base text-gray-500 ">
                                {i18n.t("settings.tracking_emails.subtitle")}
                                </p>
                            </div>
                            <div className="col-span-1 flex justify-end pr-4">
                                {renderButtons()}
                            </div>
                        </div>
                        {!notifications ? 
                          renderSkeleton() // Print skeleton while loading
                        : (Object.keys(notifications)).map(notification_type => {
                            return <div key={notification_type}>
                                <p className='text-lg my-2 font-bold text-blue-gray-900 '>{i18n.t(`settings.tracking_emails.${notification_type}_type`)}</p>
                                {Object.keys(notifications[notification_type]).map((notification, index) => {
                                    return <div className="py-2">
                                        <TrackingUpdatesNotificationsCard 
                                            key={index}
                                            notification = {notification}
                                            value = {notifications[notification_type][notification]}
                                            disabled={trackingUpdatesConfigIsLoading}
                                            handleNotificationToggle={(notification, value) => handleNotificationToggle(notification_type, notification, value)}
                                            handleSendTestEmail={(notification_type, email) => sendNotificationTestToEmail(store_id, notification_type, email)}
                                            onValidateEmailError={(email) => onValidateEmailError(email)}
                                        ></TrackingUpdatesNotificationsCard>
                                    </div>
                                })}
                            </div>
                        })}
                        <div className="flex items-center justify-end">
                            {renderButtons()}
                        </div>
                    </div>
                </div>
            
        </>
    )
}