import noImage  from "../assets/no-image.png";

export const TableImage = ({thumbnail_image='', regular_image=''}) => {

    // console.log(thumbnail_image)
    // console.log(regular_image)
    if (thumbnail_image === null) thumbnail_image = ''
    if (regular_image === null) regular_image = ''

    const temporal_thumbnail = (
        (thumbnail_image.indexOf('http') > -1 || thumbnail_image.indexOf('https') > -1 ? 
        
            thumbnail_image
        :
            process.env.REACT_APP_PLATFORM_URL+thumbnail_image
        )
    )

    const temporal_regular_image = (
        
        (regular_image.indexOf('http') > -1 || regular_image.indexOf('https') > -1 ? 
            regular_image
        :
            process.env.REACT_APP_PLATFORM_URL+regular_image
        )
    )

    return (
        (thumbnail_image != '' || regular_image != '' ?
            <div style = {{
                backgroundSize:"contain",
                backgroundPosition:"center",
                backgroundRepeat:"no-repeat",
                border:"1px solid rgb(229 231 235)",
                borderRadius:"3px",
                backgroundImage: `url('${temporal_thumbnail === '' ? regular_image : temporal_thumbnail}')`
            }} className='h-14 w-14 rounded-md cursor-pointer' onClick={() => {
                (regular_image != '' &&
                    window.open(temporal_regular_image, "_blank")
                )
            }}>
            </div>
        :
            <div style = {{
                backgroundSize:"contain",
                backgroundPosition:"center",
                backgroundRepeat:"no-repeat",
                border:"1px solid rgb(229 231 235)",
                borderRadius:"3px",
                padding:"0px 0px 0px 0px",
                backgroundImage: `url("${noImage}")`
            }} className='h-14 w-14 rounded-md'>
            </div>
        )
    )
}
