
import {useState, useContext, componentDidMount, useEffect} from "react";
import { useTranslation } from 'react-i18next';
import {useDispatch} from "react-redux";
import { TaxesSettingsContainer } from './TaxesSettingsContainer';
import { OperationNaturSettingsContainer } from './OperationNatureSettingsContainer';
import { ObservationsSettingsContainer } from './ObservationsSettingsContainer';
import { NCMsSettingsContainer } from './NCMsSettingsContainer';
import {
    setValidSettingsForm
} from '../../redux/validSettingsFormSlice';


export const BillingConfigContainer = () => {

    const { t, i18n } = useTranslation();
    const [viewPanel, setViewPanel ]= useState('default');
    const dispatch = useDispatch()
    
    const project = () => {
        switch(viewPanel) {

          case "1": 
          dispatch(setValidSettingsForm({id:"1",change:false, selectedkeys:["1"]}))
          return <TaxesSettingsContainer
          onCancel={() => setViewPanel('default')} />;
          case "2": 
          dispatch(setValidSettingsForm({id:"2",change:false, selectedkeys:["2"]}))
          return <NCMsSettingsContainer
          onCancel={() => setViewPanel('default')}/>;
          case "3": 
          dispatch(setValidSettingsForm({id:"3",change:false, selectedkeys:["3"]}))
          return <OperationNaturSettingsContainer 
          onCancel={() => setViewPanel('default')}/>;
          case "4": 
          dispatch(setValidSettingsForm({id:"4",change:false, selectedkeys:["4"]}))
          return <ObservationsSettingsContainer 
          onCancel={() => setViewPanel('default')}/>;
          
          case "default":
          return (
            <div className='relative p-10'>
              <div className="space-y-1">
                  <p className='text-3xl mb-0 font-bold text-blue-gray-900 '>{i18n.t("settings.tax_settings.tax_config")}</p>
                  <p className = "mt-5 text-lg text-gray-500">{i18n.t("settings.tax_settings.description")}</p>
              </div>
              <div className="mt-10 cursor-pointer hover:text-blue-500" onClick={() => setViewPanel("1")} >
                  <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-5'> {i18n.t("settings.billing_config.filscal_data")} <span className = "text-red-500"> * </span> </p>
                  <p className = "mt-0 text-sm text-gray-500"> {i18n.t("settings.billing_config.filscal_data_description")}</p>
              </div>
              <div className="mt-10 cursor-pointer hover:text-blue-500" onClick={() => setViewPanel("2")} >
                  <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-5'> {i18n.t("settings.billing_config.ncms_settings")} <span className = "text-red-500"> * </span> </p>
                  <p className = "mt-0 text-sm text-gray-500">{i18n.t("settings.billing_config.ncms_settings_description")} </p>
              </div>
              <div className="mt-10 cursor-pointer hover:text-blue-500" onClick={() => setViewPanel("3")}>
                  <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-5'>{i18n.t("settings.billing_config.operation_nature")}<span className = "text-gray-500 italic font-normal"> {i18n.t("settings.billing_config.optional")}</span> </p>
                  <p className = "mt-0 text-sm text-gray-500"> {i18n.t("settings.billing_config.operation_nature_description")}</p>
              </div>
          
              <div className="mt-10 cursor-pointer hover:text-blue-500" onClick={() => setViewPanel("4")}>
                  <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-5'> {i18n.t("settings.billing_config.observation_invoice")}<span className = "text-gray-500 italic font-normal"> {i18n.t("settings.billing_config.optional")}</span>  </p>
                  <p className = "mt-0 text-sm text-gray-500"> {i18n.t("settings.billing_config.observation_invoice_description")}</p>
              </div>
           </div>
           )
        }
      }
   
    return (
        <div>{ project() }</div>
    )

}