import {BasicTable} from "../../components";
import {EditableCell} from "../../components/BasicTable/EditableCell";
import { useTranslation } from "react-i18next";


export const ReturnsTable = ({units, updateData=null}) => {
    const { t, i18n} = useTranslation();

    const columns = [
        {
            Header: "returns.table.name",
            accessor: 'name'
        },
        {
            Header: 'SKU',
            accessor: 'sku'
        },
        {
            Header: "returns.table.quantity",
            accessor: 'quantity',
        }
    ]

    return (
        <BasicTable
            columns={columns}
            data={units.map(unit => {
                return {
                    name: unit.product.name,
                    sku: <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-80">{unit.product.product_codes[0].sku}</span>,
                    quantity: unit.quantity
                }
            })}  
            selectable={false}
            editable={true}
            updateData={updateData}
            emptyTableText= {i18n.t("returns.table.no_products_added")}
            showHeader
        />
    )
}