import {React} from "react";
import { InlineWidget, CalendlyEventListener } from "react-calendly";

const CalendlyReact = ({prefill, user, country, onScheduled}) => {

    let count = 0
    let url = ""
    if(prefill?.products){
        prefill?.products?.forEach(element => {
            if(element.is_scannable) count=count + element.quantity
        });
    }else if(prefill?.replenishment_products){
        prefill?.replenishment_products?.forEach(replenishment_product => {
            if(replenishment_product.product?.is_scannable) count=count + replenishment_product.expected_quantity
        });
    }

    if (country === 'MX') {
        // to do: make the calendly selection more robust, not dependant on id.
        // console.log(prefill)
        let warehouse_id = prefill.warehouse_id
        if (!warehouse_id) {
            console.warn('No warehouse_id found for replenishment. Using default warehouse_id 1.')
            warehouse_id = 1
        }
        if (warehouse_id === 133) {
            if(count <= 250){
                url = 'https://calendly.com/rep-tlalne1/1-250-piezas'
            }else if(count <= 500){
                url = 'https://calendly.com/rep-tlalne1/251-500-piezas'
            }else if(count <= 750){
                url = 'https://calendly.com/rep-tlalne1/501-750-piezas'
            }else if( count <= 1000){
                url = 'https://calendly.com/rep-tlalne1/751-1000-piezas'
            }else if( count <= 1500){
                url = 'https://calendly.com/rep-tlalne1/1001-1500-piezas'
            }else if( count <= 2000){
                url = 'https://calendly.com/rep-tlalne1/1501-2000-piezas'
            }else if( count <= 3000){
                url = 'https://calendly.com/rep-tlalne1/2001-3000-piezas'
            }else if( count <= 4000){
                url = 'https://calendly.com/rep-tlalne1/3001-4000-piezas'
            }else if( count <= 5000){
                url = 'https://calendly.com/rep-tlalne1/4001-5000-piezas'
            }else if( count <= 6000){
                url = 'https://calendly.com/rep-tlalne1/5001-6000-piezas'
            }else if( count <= 8000){
                url = 'https://calendly.com/rep-tlalne1/6001-8000-piezas'
            }else {
                url = 'https://calendly.com/rep-tlalne1/8001-10000-piezas'
            }
        }
        else if (warehouse_id === 100) {
            if(count <= 250){
                url = 'https://calendly.com/replenishments13/250piezas'
            }else if(count <= 500){
                url = 'https://calendly.com/replenishments13/500piezas'
            }else if(count <= 750){
                url = 'https://calendly.com/replenishments13/750piezas'
            }else if( count <= 1000){
                url = 'https://calendly.com/replenishments13/1000piezas'
            }else if( count <= 1500){
                url = 'https://calendly.com/replenishments13/1500piezas'
            }else if( count <= 2000){
                url = 'https://calendly.com/replenishments13/1501-2000-piezas'
            }else if( count <= 3000){
                url = 'https://calendly.com/replenishments13/2001-3000-piezas'
            }else if( count <= 4000){
                url = 'https://calendly.com/replenishments13/3001-4000-piezas'
            }else if( count <= 5000){
                url = 'https://calendly.com/replenishments13/4001-5000-piezas'
            }else if( count <= 6000){
                url = 'https://calendly.com/replenishments13/5001-6000-piezas'
            }else if( count <= 8000){
                url = 'https://calendly.com/replenishments13/6001-8000-piezas'
            }else {
                url = 'https://calendly.com/replenishments13/8001-10000-piezas'
            }
        }
        else {
            if(count <= 250){
                url = 'https://calendly.com/cubbo-replenishments-granel-entarimado/250piezas'
            }else if(count <= 500){
                url = 'https://calendly.com/cubbo-replenishments-granel-entarimado/500piezas'
            }else if(count <= 750){
                url = 'https://calendly.com/cubbo-replenishments-granel-entarimado/750piezas'
            }else if( count <= 1000){
                url = 'https://calendly.com/cubbo-replenishments-granel-entarimado/1000piezas'
            }else if( count <= 1500){
                url = 'https://calendly.com/cubbo-replenishments-granel-entarimado/1500piezas'
            }else if( count <= 2000){
                url = 'https://calendly.com/cubbo-replenishments-granel-entarimado/1501-2000-piezas'
            }else if( count <= 3000){
                url = 'https://calendly.com/cubbo-replenishments-granel-entarimado/2001-3000-piezas'
            }else if( count <= 4000){
                url = 'https://calendly.com/cubbo-replenishments-granel-entarimado/3001-4000-piezas'
            }else if( count <= 5000){
                url = 'https://calendly.com/cubbo-replenishments-granel-entarimado/4001-5000-piezas'
            }else if( count <= 6000){
                url = 'https://calendly.com/cubbo-replenishments-granel-entarimado/5001-6000-piezas'
            }else if( count <= 8000){
                url = 'https://calendly.com/cubbo-replenishments-granel-entarimado/6001-8000-piezas'
            }else {
                url = 'https://calendly.com/cubbo-replenishments-granel-entarimado/8001-10000-piezas'
            }
        }
        
    } else if (country === 'CO') {
        if(count <= 175){
            url = 'https://calendly.com/replenishmentsrep/175piezas'
        }else if( count <= 350){
            url = 'https://calendly.com/replenishmentsrep/350piezas'
        }else if( count <= 525){
            url = 'https://calendly.com/replenishmentsrep/525piezas'
        }else if( count <= 700){
            url = 'https://calendly.com/replenishmentsrep/700piezas'
        }else if( count <= 875){
            url = 'https://calendly.com/replenishmentsrep/875piezas'
        }else if( count <= 1050){
            url = 'https://calendly.com/replenishmentsrep/1050piezas'
        }else {
            url = 'https://calendly.com/replenishmentsrep/1050piezas'
        }
    } else { // BR
        if(count <= 250){
            url = 'https://calendly.com/replenishmentssp1/250pecas'
        }else if( count <= 500){
            url = 'https://calendly.com/replenishmentssp1/500pecas'
        }else if( count <= 750){
            url = 'https://calendly.com/replenishmentssp1/750pecas'
        }else if( count <= 1000){
            url = 'https://calendly.com/replenishmentssp1/1000pecas'
        }else if( count <= 1250){
            url = 'https://calendly.com/replenishmentssp1/1250pecas'
        }else if( count <= 1500){
            url = 'https://calendly.com/replenishmentssp1/1500pecas'
        }else {
            url = 'https://calendly.com/replenishmentssp1/1500pecas'
        }
    }
    
    const onEventScheduled = (e) => {
        let invitee_uuid = e.data.payload.invitee.uri.split('/').pop()
        const event_url = `${url}/invitees/${invitee_uuid}`
        if(onScheduled)
            onScheduled(event_url)
    }
    const onDateAndTimeSelected = (e) => {
    }
  return (
    <div className="App">
         <CalendlyEventListener onEventScheduled={onEventScheduled} onDateAndTimeSelected={onDateAndTimeSelected} >

            <InlineWidget url={url} 
                prefill={{
                    email: user.email,
                    firstName: user.first_name||"",
                    lastName: user.last_name||"",
                    name: user.accounts[0].name,
                    customAnswers: { 
                        a1: user.current_store?user.current_store.name:"", 
                        a2: prefill.id, 
                        a3: "", 
                        a4: count, 
                }}} 
                />
         </CalendlyEventListener>
    </div>
  );
};

export default CalendlyReact;