import { CONDITION_VALIDATION_TYPES, CUSTOM_STATEMENT_QUANTITIES, CUSTOM_STATEMENT_VALIDATIONS } from "../../utils/businessRuleStatementUtils";

const ruleStatementBuilder = (statementSettings, buildForPreview=false) => {
    let previousConditionType = null

    let ruleStatement = statementSettings.conditions.flatMap((condition, index, conditions) => {
        let statementCondition = null

        switch (condition.validationType) {
            case CONDITION_VALIDATION_TYPES.IS_POTENTIAL_FRAUD:
                statementCondition = {is_potential_fraud: true}
                break
            
            case CONDITION_VALIDATION_TYPES.CHANNEL_TYPE_NAME:
                if (buildForPreview) {
                    statementCondition = {channel_integration_id: {channel_integration_id: Number(condition.selectedValidationOption), channel_name: condition.channel_name}}
                } else {
                    statementCondition = {channel_integration_id: Number(condition.selectedValidationOption)}
                }
                break

            case CONDITION_VALIDATION_TYPES.IS_FIRST_TIME_BUYER:
                statementCondition = {is_first_time_buyer: true}
                break

            case CONDITION_VALIDATION_TYPES.HAS_FRAGILE_ITEMS:
                statementCondition = {has_fragile_items: true}
                break

            case CONDITION_VALIDATION_TYPES.FOR_EVERY_PRODUCT:
                {
                    statementCondition = {
                        product_id: !condition.anyProduct ? condition.forEveryProduct : null,
                        min_quantity: condition.for_every_quantity
                    }

                    if(buildForPreview){
                        statementCondition.product_id = condition.anyProduct ? [{product_id: null}] : condition.forEveryProduct.map((product) => ({
                            product_id: product.id,
                            sku: product.product_codes[0].sku
                        }))

                    } else{
                        statementCondition.product_id = condition.anyProduct ? null : condition.forEveryProduct.map((product) => product.id)
                    }
                    break
                }

            case CONDITION_VALIDATION_TYPES.VALIDATE_PRODUCT:
                {
                    statementCondition = {
                        min_quantity: condition.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.EXACTLY ? condition.exactQuantity : condition.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MAXIMUM ? 1 : condition.minQuantity
                    }

                    let productsToValidate = null

                    if(buildForPreview && !condition.anyProduct){
                        productsToValidate = condition.products.map((product) => ({
                            product_id: product.id,
                            sku: product.product_codes[0].sku
                        }))
                    }
                    else if(!buildForPreview && !condition.anyProduct){
                        productsToValidate = condition.products.map((product) => product.id)
                    }
                    else if(buildForPreview && condition.anyProduct){
                        productsToValidate = [{product_id: null}]
                    }

                    if(condition.selectedValidationOption === CUSTOM_STATEMENT_VALIDATIONS.INCLUDE_PRODUCT){
                        statementCondition.product_id = productsToValidate
                    }
                    else if(condition.selectedValidationOption === CUSTOM_STATEMENT_VALIDATIONS.NOT_INCLUDE_PRODUCT){
                        statementCondition.product_id = productsToValidate
                        statementCondition.not = true
                    }

                    if(condition.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.EXACTLY){
                        statementCondition.max_quantity = condition.exactQuantity
                    }
                    else if(condition.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MAXIMUM || condition.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MINIMUM_AND_MAXIMUM){
                        statementCondition.max_quantity = condition.maxQuantity
                    }

                    if(condition.exclusive){
                        statementCondition.exclusive = condition.exclusive
                    }

                    break
                }

            default:
                break;
        }

        if(!statementCondition){
            throw new Error()
        }

        if(index === 0 && conditions.length > 1){
            previousConditionType = conditions[1].conditionType
            return [previousConditionType, statementCondition]
        }
        else if(condition.conditionType !== previousConditionType){
            previousConditionType = condition.conditionType
            return [condition.conditionType, statementCondition]
        }
        else{
            return [statementCondition]
        }
    })

    if(!ruleStatement || ruleStatement.length <= 0){
        throw new Error()
    }

    if(!buildForPreview){
        ruleStatement = JSON.stringify(ruleStatement)
    }

    return ruleStatement
}

export default ruleStatementBuilder