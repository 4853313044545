import { t } from "i18next"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { DialogView } from "../../../components"
import { CloseIcon } from "../../../components/Icons/CloseIcon"
import { Loader } from "../../../components/Loader"
import { RemoveShippingGroupCard, ShippingGroupCard } from "../../../components/ShippingGroups/ShippingGroupCard"
import { UserContext } from "../../../hooks/UserContext"
import { assignShippingGroup, fetchStoreShippingGroups } from "../../../services/shippingMethodsServices"

export const AssignShippingGroupModal = ({ integrationShippingMethod, show = false, close = () => {}, onSave = () => {} }) => {
    const { user } = useContext(UserContext)

    const [selectedShippingGroupId, setSelectedShippingGroupId] = useState(null)
    const [busy, setBusy] = useState(false)
    const [busyUnassign, setBusyUnassign] = useState(false)

    useEffect(() => {
        setSelectedShippingGroupId(integrationShippingMethod?.shipping_group?.id)
    }, [integrationShippingMethod])

    const {
        isLoading: isLoadingShippingGroups,
        isError: isErrorShippingGroups,
        error: ErrorShippingGroups,
        data: dataShippingGroups,
        isFetching: isFetchingShippingGroups,
        isPreviousData: isPreviousDataShippingGroups,
        refetch: refetchShippingGroups,
    } = useQuery(["store_shipping_groups", user?.current_store?.id], () => fetchStoreShippingGroups(user?.current_store?.id), { keepPreviousData: true })

    const handleSelectShippingGroup = async (shippingGroupId) => {
        if (integrationShippingMethod.shipping_group?.id === shippingGroupId) return
        setBusy(true)
        setSelectedShippingGroupId(shippingGroupId)
        try {
            await assignShippingGroup(shippingGroupId, integrationShippingMethod.id)
            onSave(shippingGroupId, integrationShippingMethod.id)
        } catch (error) {
            console.log(error)
            window.alert(error.response?.data?.error)
            setSelectedShippingGroupId(null)
        }
        setBusy(false)
    }

    const handleUnassignShippingGroup = async () => {
        setBusy(true)
        setBusyUnassign(true)
        let previousSelectedShippingGroupId = selectedShippingGroupId
        setSelectedShippingGroupId(null)
        try {
            await assignShippingGroup(null, integrationShippingMethod.id)
            onSave(null, integrationShippingMethod.id)
        } catch (error) {
            console.log(error)
            window.alert(error.response?.data?.error)
            setSelectedShippingGroupId(previousSelectedShippingGroupId)
        }
        setBusy(false)
        setBusyUnassign(false)
    }

    const onClose = () => {
        if (busy) return
        close()
    }

    return (
        <DialogView open={show} setOpen={() => onClose()} className="md:w-auto">
            <div className="min-h-10">
                <div className="flex flex-row justify-between">
                    <div className="mb-4">
                        <div className="text-lg">{t("integrations_list.shipping.shipping_group")}</div>
                        <div className="text-xs">{integrationShippingMethod?.name}</div>
                    </div>
                    <div>
                        <button className="p-3 cursor-pointer focus:outline-none" onClick={() => onClose()}>
                            <CloseIcon className="h6 w-6" />
                        </button>
                    </div>
                </div>
                <div className="relative">
                    <Loader show={isLoadingShippingGroups} />
                    {dataShippingGroups?.map((shippingGroupData) => (
                        <ShippingGroupCard
                            onClick={() => handleSelectShippingGroup(shippingGroupData.id)}
                            selected={selectedShippingGroupId === shippingGroupData.id}
                            key={shippingGroupData.id}
                            shippingGroupData={shippingGroupData}
                            integrationShippingMethodType={integrationShippingMethod?.shipping_type}
                            selectable
                            busy={busy}
                            className="mb-6"
                        />
                    ))}
                    {((selectedShippingGroupId != null && !busy) || busyUnassign) && (
                        <RemoveShippingGroupCard onClick={() => handleUnassignShippingGroup()} busy={busyUnassign} />
                    )}
                </div>
            </div>
        </DialogView>
    )
}
