export const OrderShippingStatusIcon = ({ className = null }) => {
    return (
        <svg className={className} viewBox="0 0 124 119" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M106.644 0C97.4724 0 90 7.47162 90 16.5082C90 24.6866 102.706 44.8633 104.103 47.1183C105.096 48.8179 107.772 48.9524 108.9 47.1183C110.313 44.8633 123.003 24.6866 123.003 16.5082C123.155 7.47162 115.682 0 106.644 0ZM117.517 16.5082C117.517 20.7489 111.592 32.0236 106.644 40.3534C101.713 32.0236 95.6378 20.7489 95.6378 16.5082C95.6378 10.4501 100.569 5.63723 106.51 5.63723C112.569 5.63749 117.517 10.5849 117.517 16.5082Z"
                fill="currentColor"
            />
            <path
                d="M107.924 18.3429H105.383C103.834 18.3429 102.555 17.0809 102.555 15.5157C102.555 13.9504 103.818 12.6885 105.383 12.6885H107.924C109.472 12.6885 110.752 13.9504 110.752 15.5157C110.752 17.0809 109.472 18.3429 107.924 18.3429Z"
                fill="currentColor"
            />
            <path
                d="M99.1485 104.109H65.0477C63.4995 104.109 62.2202 102.847 62.2202 101.282C62.2202 99.7337 63.4823 98.4546 65.0477 98.4546H99.0139C106.638 98.4546 112.848 92.2449 112.848 84.6221C112.848 76.9992 106.621 70.8232 99.0139 70.8232H89.1612C82.1095 70.8232 76.3202 65.0344 76.3202 57.9835C76.3202 50.9325 82.1095 45.1438 89.1612 45.1438H104.513C106.061 45.1438 107.34 46.4058 107.34 47.971C107.34 49.5191 106.078 50.7982 104.513 50.7982H89.1609C85.2059 50.7982 81.9578 54.0461 81.9578 58.0007C81.9578 61.9553 85.2059 65.2031 89.1609 65.2031H99.1482C109.869 65.2031 118.62 73.9536 118.62 84.6731C118.486 95.3585 109.734 104.109 99.1485 104.109Z"
                fill="currentColor"
            />
            <path
                d="M32.2903 46.0619C34.4938 44.8378 37.1734 44.8378 39.3773 46.0619L66.791 61.2903C67.9493 61.9337 68.6677 63.1543 68.6677 64.4791V94.5182C68.6677 97.1676 67.231 99.6091 64.9148 100.896L39.3773 115.082C37.1734 116.306 34.4938 116.306 32.2903 115.082L6.75294 100.896C4.43659 99.6091 3 97.1676 3 94.5182V64.4791C3 63.1543 3.71829 61.9337 4.87649 61.2903L32.2903 46.0619Z"
                stroke="currentColor"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M3.10889 62.2969L35.9427 80.536M35.9427 80.536L68.7765 62.2969M35.9427 80.536V115.19"
                stroke="currentColor"
                stroke-width="6"
                stroke-linejoin="round"
            />
            <path d="M19.418 71.4525L52.2518 53.2134" stroke="currentColor" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.9438 81.7693L24.8885 87.8681" stroke="currentColor" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
