import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: null,
    user: null
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        }
    }
});

export const { setToken } = userSlice.actions;

export const tokenUser = (state) => state.search.token;

export default userSlice.reducer;