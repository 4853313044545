export const CLAIM_ENABLED_STATUSES = ['returned', 'returning', 'shipped', 'return_arrived']

export const TRACKING_STATUSES = new Set([
    "shipment_created",
    "shipment_with_incident",
    "shipment_returned",
    "shipment_canceled",
    "shipment_collected",
    "shipment_not_defined",
    "shipment_in_transit",
    "shipment_delivered",
    'shipment_to_be_self_collected',
    'shipment_out_for_delivery'
]);

export const TRACKING_INCIDENT_FINAL_DESCRIPTIONS = new Set([
    'stolen',
    'lost',
    'damaged'
  ])

export const TRACKING_DESCRIPTIONS = new Set([
    'second_try',
    'third_try',
    'client_requested',
    'missing_references',
    'not_at_home',
    'refused_payment',
    'refused_package',
    //'cubbo_requested',
    'carrier_requested',
    'stolen',
    'lost',
    'damaged',
    'in_return'
])

export const DELIVERY_ATTEMPT_DESCRIPTIONS = {
    MISSING_REFERENCES: 'missing_references',
    NOT_AT_HOME: 'not_at_home',
    REFUSED_PAYMENT: 'refused_payment',
    REFUSED_PACKAGE: 'refused_package',
    SECOND_TRY: 'second_try',
    THIRD_TRY: 'third_try'
}
