export const PageTopBar = ({children}) => {
    return (
        <div className="overflow-hidden bg-white flex flex-row justify-between my-3 items-center py-1 pr-1">
            {
                Array.isArray(children) ?
                    children.map((child, index) => 
                        <div className="text-gray-600" key={index}>{child}</div>
                    )
                    :
                    <div className="text-gray-600">{children}</div>
            }
        </div>
    )
}