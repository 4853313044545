import { InformationCircleIcon } from '@heroicons/react/outline';

import { Tooltip } from 'antd';

import { TextWithBoldTags } from '../../../../utils/uiUtils';
import { PackingOptionsList } from '../components';
import { VIRTUAL_PACKAGES_TYPES } from '../packingOptionConstants';

const PackagelessOptionsSection = ({tabTitleWithTags, tooltipText, packingOptionsList, onOptionChange=()=>{}, packingOptionsStatuses=null}) => {
  return (
    <section>
        <PackingOptionsList
            packingType={VIRTUAL_PACKAGES_TYPES.packageless}
            packingOptionsList={packingOptionsList}
            packingOptionsStatuses={packingOptionsStatuses}
            onOptionChange={onOptionChange}
            fallbackValue={true}
            enableNonAvailableStyle={false}
            headerComponent={
                <div className='border rounded-t p-3 bg-gray-50 flex items-center'>
                    <div>
                        <TextWithBoldTags textWithTags={tabTitleWithTags}/>
                    </div>

                    <Tooltip
                        placement="top"
                        overlay={<span>{tooltipText}</span>}
                        arrowContent={<div></div>}
                        destroyTooltipOnHide
                    >
                        <InformationCircleIcon className='ml-1.5 w-4 h-4 inline'/>
                    </Tooltip>
                </div>
            }
        />
    </section>
  )
}

export default PackagelessOptionsSection