import Zendesk from "react-zendesk"
import { isProductionEnvironment } from "../../helpers/environmentHelper"

const ZENDESK_API_KEY_MX = "413ac2b3-10fd-4584-ba76-e13722bfae03"
const ZENDESK_API_KEY_BR = "f651b577-b3f1-4bed-b74b-fcc2e9928726"
export const ZendeskWidget = ({country}) => {
    if (isProductionEnvironment) {
        const key = country === "BR" ? ZENDESK_API_KEY_BR : ZENDESK_API_KEY_MX
        return <Zendesk defer zendeskKey={key} onLoaded={() => {
            console.log("is loaded zendesk")
        }} />
    } else return <></>
}
