import { ActionMenu, ButtonLoader, ConfirmDialog, NewTable, Notification } from "../../components"
import { Loader } from "../../components/Loader";
import {useQuery} from "react-query";
import {useContext, useMemo, useState} from "react";
import { UserContext } from "../../hooks/UserContext";
import {useForm} from "react-hook-form";
import { fetchStoreAccounts } from "../../services/storeServices"
import { inviteSignUp } from "../../services/loginServices";
import { useTranslation } from 'react-i18next';
import { setUsersListPageSize } from "../../redux/pageSizeSlice";
import { useDispatch, useSelector } from "react-redux";
import { disableUser } from "../../services/userService";

export const UsersSettingsContainer = () => {
    const {user} = useContext(UserContext)
    const {register, handleSubmit, formState: { errors }, resetField} = useForm({})
    const [loadingBundleBotton, setLoadingBundleBotton] = useState(false)
    const [showNotification, setShowNotification] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const pageSize = useSelector((state) => state.pageSize.usersList);
    const dispatch = useDispatch()
    const initialSearchParams = {
        page: 1,
        per_page: pageSize,
        order_by: 'first_name',
        order: 'asc'
    }
    const [searchParams, setSearchParams] = useState(initialSearchParams)

    const filtersInitialData = [
        {
            key: "email",
            placeholder: "settings.user_settings.list.email",
            label: "settings.user_settings.list.email",
            icon: "email_at",
            data: {
              visible: false,
              type: 'text',
              value: ''
            },
          },
          {
            key: "name",
            placeholder: "settings.user_settings.list.name",
            icon: "order_buyer",
            label: "settings.user_settings.list.name",
            data: {
              visible: false,
              type: 'text',
              value: ''
            },
          }
      ]
    const [filtersData, setFiltersData] = useState(filtersInitialData)

    const [dialog, setDialog] = useState({
        loading: false,
        open: false,
        title: "",
        description: "",
        type: null
    })
    
    const { i18n } = useTranslation();
    const {
        isLoading,
        isFetching,
        isError,
        error,
        data,
        isPreviousData,
        refetch,
    } = useQuery(['user_config', user.current_store?.id, searchParams], () => fetchStoreAccounts(user.current_store?.id, searchParams), { keepPreviousData: true })

    const  generateRandomPwd = (num) => {
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result1= ' ';
        const charactersLength = characters.length;
        for ( let i = 0; i < num; i++ ) {
            result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
    
        return result1;
    }

    const getMenuOptions = (user) => {
        const options = [
            {
                title: i18n.t("settings.user_settings.list.disable_user_action"),
                clickHandler: () => setDialog({...dialog,
                    open: true,
                    title: i18n.t("settings.user_settings.disable_user_dialog.title"),
                    description: i18n.t("settings.user_settings.disable_user_dialog.description", {user: user.email}),
                    type: "disable",
                    id: user.id,
                    email: user.email
                })
            }
        ]
        return options
    }

    const onConfirmDialogHandler = async () => {
        setDialog({...dialog, loading: true})
        let errorOcurred = false
        switch (dialog.type){
            case "disable":
                window.analytics.track('Users - disable', {email: dialog.email})
                try {
                    await disableUser(dialog.id, { store_id: user.current_store?.id })
                } catch (error) {
                      errorOcurred = true
                      const errorMessage = error.response.data?.code ? i18n.t(`settings.user_settings.disable_user_errors.${error.response.data?.code}`) : error.response.data?.message || error.response.data?.error
                      setDialog({...dialog,     
                          errorMessage: errorMessage,
                      })
                }
                break
        }
        if(!errorOcurred) setDialog({...dialog, loading: false, open: false})
        refetch()
    }

    const memoizedTableData = useMemo(() => {
        return data?.users.map((user)=>{
            return {
                name: <div className="mt-1.5">
                        {user?.first_name + ' ' + user?.last_name}
                    </div>,
                email: <div className="mt-1.5">
                        {user?.email}
                    </div>,
                actions: <ActionMenu className="float-right" items={getMenuOptions(user)} />
            }
        })
    }, [data?.users])

    const memoizedColumns = useMemo(() => {
        if (!data?.users) return []
    
        return [
          {
            Header: "settings.user_settings.list.name",
            accessor: "name", // accessor is the "key" in the data
            disableSortBy: true,
          },
          {
            Header: "settings.user_settings.list.email",
            accessor: "email", // accessor is the "key" in the data
            disableSortBy: true,
          },
          {
            Header: "",
            accessor: "actions",
            disableSortBy: true,
          },
        ]
    }, [data?.users])

    const handleResetFilters = () => {
        setSearchParams(initialSearchParams)
    }

    const updateFiltersData = (updatedData, filter) => {
        console.log(updatedData)
        console.log("Filter", filter)
        setFiltersData((prev) => {
            return prev.map((item) => {
                if (item.key == filter) {
                    console.log("I", item)
                    return {
                        ...item,
                        data: updatedData
                    }
                }
                else return item
            }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
        })
        let value = updatedData.value
        console.log("Value", value)
        if (!value) {
            setSearchParams((prev) => {
                // Delete filter key from searchParams
                const newSearchParams = {...prev, page: 1}
                delete newSearchParams[[filter]]
                return newSearchParams
            })
        }
        else {
            setSearchParams((prev) => ({...prev, [filter]: value, page: 1}))
        }
    }

    const onFilterChanged = (updatedData, filter) => {
        updateFiltersData(updatedData, filter)
    }

    const onSubmit = (data) => {
        data={
            ...data,
            store_id: user.current_store?.id,
            password: generateRandomPwd(8)
        }
        setLoadingBundleBotton(true)
        inviteSignUp(data).then((user)=>{
            setErrorMessage(false)
            setSuccessMessage(i18n.t("settings.user_settings.success_message"))
            setShowNotification(true)
            refetch()
            setLoadingBundleBotton(false)
            resetField("email")
        }).catch((error)=>{
            const errorCode = error.response.data.code ?? "DEFAULT"
            setSuccessMessage(false)
            setErrorMessage(i18n.t(`settings.user_settings.invite_user_errors.${errorCode}`))
            setShowNotification(true)
            setLoadingBundleBotton(false)
        })
    }

    return (
        <>
            <ConfirmDialog
                open={dialog.open}
                setOpen={(value) => setDialog({...dialog, open: value}) }
                title={dialog.title}
                description={dialog.description}
                confirmLabel={i18n.t("dialog.yes")}
                cancelLabel={i18n.t("dialog.no")}
                onConfirm={onConfirmDialogHandler}
                loading={dialog.loading}
                errorMessage={dialog.errorMessage}
            />
        <div className='relative p-10'>
              <div className="flex justify-between gap-x-2 space-y-1">
                <p className='text-3xl mb-0 font-bold text-blue-gray-900 '>{i18n.t("settings.user_settings.title")}</p>
           
                <form action="#" method="POST" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex">
                        <div className="">
                            <input
                                type="text"
                                name="email"
                                placeholder={i18n.t("settings.user_settings.invite_user_placeholder")}
                                className="appearance-none block w-60 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                {...register("email", {
                                    required: i18n.t("settings.user_settings.required_field"),
                                    pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: i18n.t("settings.user_settings.invalid_email"),
                                }
                                })}
                            />
                            <p className="mt-2 text-sm text-red-600">
                                {errors.email && errors.email.message}
                            </p>

                        </div>
                        <span>
                            <ButtonLoader className="ml-3" loading_text={i18n.t("settings.user_settings.processing")} loading={loadingBundleBotton} disabled={loadingBundleBotton}>
                                {i18n.t("settings.user_settings.invite_user")}
                            </ButtonLoader>

                            {errorMessage && showNotification? (
                                <>
                                    <Notification show={showNotification} setShow={setShowNotification} type="error" title={i18n.t("settings.user_settings.error")} description={errorMessage}/>
                                </>
                            ) : null}
                            {successMessage && showNotification? (
                                <>
                                    <Notification show={showNotification} setShow={setShowNotification} type="success" title={i18n.t("settings.user_settings.done")} description={successMessage}/>
                                </>
                            ) : null}
                        </span>
                    </div>
                </form>
            </div>

            <div className="mt-5 mb-5">
                {
                !isPreviousData && isLoading ? (
                    <><Loader show={true}></Loader></>
                ): isError ? (
                    <>Error: {error.message}</>
                ):
                (
                    <>
                    <NewTable
                        data = {memoizedTableData}
                        columns={memoizedColumns}
                        showLoader={isFetching && isPreviousData}
                        isFetching={isFetching}
                        emptyTableText= {i18n.t("settings.user_settings.list.no_users_to_show")}
                        showPaginationOnFooter
                        footerLabel={i18n.t("settings.user_settings.list.users")}
                        paginationMeta={data?.meta}
                        onPaginationChange= { (page) => { 
                            setSearchParams((prev) => ({...prev, page: page})) 
                        }}
                        onPageSizeChange = { (page) => {
                            setSearchParams((prev) => ({...prev, per_page: page.id, page: 1}))
                            dispatch(setUsersListPageSize(page.id))
                        }}
                        onFilterChanged={onFilterChanged}
                        handleResetFilters={handleResetFilters}
                        filtersData={filtersData}
                        hasExport={false}
                        />
                    </>
                )}
            </div>
        </div> 
        </>
    )
}