import { Skeleton } from "antd"
import { Flex, Metric, ProgressBar, Subtitle } from "@tremor/react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { formatPercentage } from "../../utils/StringUtils"

export const MetricPercentage = ({ value = 0, total = 0, icon = null, children, progressBarProps = {}, showLoader = false, valueFormatter = (v) => v, totalLabel = null }) => {
    const { t } = useTranslation()

    const percentage = useMemo(() => {
        if (total === 0) return 0
        return (value / total) * 100
    }, [value, total])

    return (
        <Flex justifyContent="start" alignItems="center">
            {icon && <div className="mr-4">{icon}</div>}
            <div className="grow relative">
                <div className="w-full relative">
                    {showLoader && <Skeleton.Button active size="large" shape="square" className="absolute w-10" />}
                    <Flex justifyContent="around" alignItems="baseline" className="mt-2">
                        <Flex justifyContent="start" alignItems="baseline">
                            <Metric className="font-semibold">{showLoader ? "\u00A0" : valueFormatter(value)}</Metric>
                            <p className="ml-2 text-base text-gray-500 font-medium">
                                {showLoader ? "\u00A0" : `${t("metrics.generic.of")} ${valueFormatter(total)} ${totalLabel ? totalLabel : ''}`}
                            </p>
                        </Flex>
                        <Subtitle className="text-base">{formatPercentage(percentage)}</Subtitle>
                    </Flex>
                </div>
                <ProgressBar className="mt-4" value={percentage} {...progressBarProps} color="blue" />
                {children}
            </div>
        </Flex>
    )
}
