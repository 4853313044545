import { ShoppingBagIcon } from "@heroicons/react/solid"
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { getSkus } from "../../components"
import { ADD_PRODUCT, EDIT_KIT, EDIT_PRODUCT, STORE_PATH } from "../../navigation/constants"
import { resetData } from "../../redux/createProductSlice"
import { useDispatch } from "react-redux"
import Product from "../Inventory/AddProduct/Product"
import { Tooltip } from "antd"
import  i18n from "../../translations/index";

export const OrderLineItem = ({ orderLine, showAssignedStock = true, orderStatus = null, errorComponent = null }) => {
  const history = useHistory()
  const { url:storePath } = useRouteMatch({ path: STORE_PATH })

  const showProductName = (orderLine) => {
    const type = Product.getType(
      orderLine.product?.parent,
      orderLine.product?.is_kit,
    )

    let link = storePath + EDIT_PRODUCT.replace(":id", orderLine.product?.id)
    if (type === "variants") {
      link = storePath + EDIT_PRODUCT.replace(":id", orderLine.product?.parent?.id)
    }
    if (type === "kit") {
      link = storePath + EDIT_KIT.replace(":id", orderLine.product?.id)
    }

    if (!orderLine.product) return <></>
    else
      return (
        <Tooltip title= {i18n.t("inventory.listProducts.edit_product")}>
          <Link to={link} className="focus:outline-none">
            {orderLine.product.full_name ? orderLine.product.full_name : orderLine.product.name}
          </Link>
        </Tooltip>
      )
  }

  const showisDropshipping = (orderLine) => {
    if (
      orderLine.product?.is_dropshipping !== null &&
      orderLine.product?.is_dropshipping === true
    ) {
      return (
        <span
          className={
            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-100 text-red-800"
          }
        >
          {i18n.t("orders.lineItem.dropshipping")}
          
        </span>
      )
    } else {
    }
    return ""
  }

  const showisFragile = (orderLine) => {
    if (
      orderLine.product?.is_fragile !== null &&
      orderLine.product?.is_fragile === true
    ) {
      return (
        <span
          className={
            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-yellow-100 text-yellow-800"
          }
        >
          {i18n.t("orders.lineItem.fragile_product")}
          
        </span>
      )
    } else {
    }
    return ""
  }

  const showProductSkus = (orderLine) => {
    if (orderLine.product?.product_codes) {
      return getSkus(orderLine.product.product_codes)
    }
    else if (orderLine.sku) {
      return <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-yellow-100 text-yellow-800"}><i>{orderLine.sku}</i></span>
    }
    else {
      return <></>
    }
  }

  const isFragile = (orderLine) => {
    const is_any_child_fragile = orderLine.product?.kit_childs?.find(child => child.child_product?.is_fragile)
    return is_any_child_fragile
  }

  const showisKit = (orderLine) => {
    if (orderLine.product?.is_kit && orderLine.product) {
      return (
        <span>
          <span className={
            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-green-200 text-green-800"
          }>
            Kit
          </span>

          {isFragile(orderLine) && 
          
            <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-yellow-100 text-yellow-800"}>
              Frágil
            </span>

          }
          
          <span>
          <div
              className={
                  "inline-flex items-center px-0.1 py-0.3 rounded-full text-xs font-medium mt-2 bg-blue-100 text-blue-800"
                }>
              {showProductSkus(orderLine)}
          </div>
          </span>
          
          <br />
          {
            orderLine.product?.kit_childs?.map((kit_child)  =>   
              <div className={"items-center"} key={kit_child.child_product.id}>  
                <span>
                  <br />
                  {
                    showAssignedStock && orderLine.stock_units ?
                    <div className={"text-left mx-3"}>
                      {findProductIdInStockUnits(kit_child.child_product.id, orderLine.stock_units, !checkIfPresale(kit_child.child_product.id, orderLine.stock_units)).length}/{orderLine.quantity * kit_child.quantity} - {kit_child.child_product.full_name}
                      <div
                        className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-0.5 bg-blue-100 text-blue-800"}>
                        {kit_child.child_product.product_codes[0].sku}
                      </div>

                      {checkIfPresale(kit_child.child_product.id, orderLine.stock_units) &&
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-0.5 bg-gray-200 text-gray-800">
                          {i18n.t("orders.lineItem.presale")}
                        </span>
                      }
                      {!checkIfSatisfiedQuantity(kit_child.quantity,kit_child.child_product.id, orderLine.stock_units,orderLine.quantity ) &&
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-100 text-red-800">
                          {i18n.t("orders.lineItem.no_stock")}
                        </span>
                      }
                    </div>
                    :
                    <div className={"text-left mx-3"}>
                      {orderLine.quantity * kit_child.quantity} - {kit_child.child_product.full_name}
                        <div
                          className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-0.5 bg-blue-100 text-blue-800"}>
                          {kit_child.child_product.product_codes[0].sku}
                        </div>
                    </div>
                  }
                </span>
              </div>
            )
          }
        </span>    
      
      )} else {
        
    }
    return ""
  }

  const findProductIdInStockUnits = (productId, stock_units, includePresale = true) => {
    let foundStockUnits = [];
    if(Array.isArray(stock_units)) {
      if(includePresale) {
        foundStockUnits =stock_units.filter(su => su.product_id === productId) 
      } else {
        foundStockUnits =stock_units.filter(su => su.product_id === productId && (su.status !== 'RESERVED-RECEPTION-INCOMING-SELL-AHEAD' && su.status !== 'RESERVED-INCOMING-SELL-AHEAD'))
      }
    }
    return foundStockUnits
    
  }

  const checkIfPresale = (productId, stock_units) => {
      let foundStockUnits = findProductIdInStockUnits(productId, stock_units)
      let foundIndex = foundStockUnits.findIndex(su =>
        (su.status == 'RESERVED-RECEPTION-INCOMING-SELL-AHEAD' || su.status == 'RESERVED-INCOMING-SELL-AHEAD'))
      return foundIndex === -1 ? false : true
  }

  const checkIfSatisfiedQuantity = (quantity, productId, stock_units, order_line_quantity) => {
    let foundStockUnits = findProductIdInStockUnits(productId, stock_units)
    return foundStockUnits.length < quantity && order_line_quantity != 0 ? false : true
  }

  const countSatisfiedKits = (orderLine) => {
    let remainingStockUnits = [...orderLine.stock_units]
    let satisfiedKits = 0
    for(let i=0; i < orderLine.quantity; i++){
      let skipLoop = false
      orderLine.product.kit_childs.forEach(kit_child => {
        if(skipLoop) {
          return
        }
        let foundStocks = findProductIdInStockUnits(kit_child.child_product.id, remainingStockUnits, checkIfPresale(kit_child.child_product.id, remainingStockUnits))
        if(foundStocks.length < kit_child.quantity) {
          skipLoop = true
          return
        }
        for (let x=0; x < kit_child.quantity; x++) {
          let foundIndex = remainingStockUnits.findIndex(stock_unit => stock_unit.id === foundStocks[x].id)
          remainingStockUnits.splice(foundIndex, 1)
        }
      })
      if(!skipLoop) {
        satisfiedKits++
      }
    }
    return satisfiedKits
  }

  return (
    <>
    <div className="flex justify-between">
      <div className="w-0 flex-1 flex items-center">
        <ShoppingBagIcon
          className="flex-shrink-0 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <span className="ml-2 flex-1 w-0" >
          {showProductName(orderLine)}
          {showisKit(orderLine)}
          {orderLine.damaged && (
            <span
              className={
                "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-500 text-white"
              }
            >
              {i18n.t("orders.lineItem.damaged")}
            </span>
          )}

          <div className="mt-2">{
            (!orderLine.product?.is_kit) ? showProductSkus(orderLine) : ''
          }</div>

          <div className="mt-3">{showisDropshipping(orderLine)}</div>

          <div className="mt-3">{showisFragile(orderLine)}</div>

          {orderLine.generated &&
            <Tooltip title= {i18n.t("orders.lineItem.item_generated")}>
              <span className="cursor-default inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium my-1.5 mx-1 bg-indigo-100 text-indigo-800">
              {i18n.t("orders.lineItem.generated")}
              </span>
            </Tooltip>
          }
          {showAssignedStock && orderLine.stock_units && orderLine.product && (!orderLine.product?.is_kit) &&
            <>
              {checkIfPresale(orderLine.product.id, orderLine.stock_units) &&
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-8 bg-gray-200 text-gray-800">
                  {i18n.t("orders.lineItem.presale")}
                </span>
              }{!checkIfSatisfiedQuantity(orderLine.quantity,orderLine.product.id, orderLine.stock_units,orderLine.quantity) && orderStatus != 'canceled' && orderStatus != 'interrupted' &&
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium my-1.5 mx-1 bg-red-100 text-red-800">
                  {i18n.t("orders.lineItem.no_stock")}
                </span>
              }
            </>
          }
        </span>
      </div>
      <div className="ml-4 flex-shrink-0">
        {i18n.t("orders.lineItem.quantity")}
          {
              showAssignedStock && orderLine.stock_units && orderLine.product ? orderLine.product?.is_kit ?
              <>
                <span className="font-medium">{countSatisfiedKits(orderLine)}/{orderLine.quantity}</span> 
              </>
              :
              <>
                <span className="font-medium">{findProductIdInStockUnits(orderLine.product.id, orderLine.stock_units, checkIfPresale(orderLine.product.id, orderLine.stock_units)).length}/{orderLine.quantity}</span> 
              </>
              :
                <span className="font-medium">{orderLine.quantity}</span>
          }
        <br></br>
      </div>
    </div>
    { errorComponent }
    </>
  )
}
