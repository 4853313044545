import { useEffect, useMemo, useState } from "react"

const useOnScreen = (ref) => {
    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), [ref])

    useEffect(() => {
        if (!ref.current) throw new Error("useOnScreen ref is not defined, make sure to pass a ref of a DOM element")
        observer.observe(ref.current)
        return () => observer.disconnect()
    }, [])

    return isIntersecting
}

export default useOnScreen