import React from "react"
import { getLatestStatementAttachment } from "../../services/storeServices"
import { RedirectToStorePath } from "../../navigation/StoreRouter"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import { DASHBOARD } from "../../navigation"

const LatestStatementFileDownload = () => {
    const routeParams = useParams()
    const storeId = routeParams.storeId
    const attachmentName = routeParams.attachmentName

    const handleDownload = async () => {
        try {
            const response = await getLatestStatementAttachment(storeId, attachmentName)
            console.log(response)
            const contentType = response.headers['content-type']
            const filename = attachmentName
            const blob = new Blob([response.data], { type: contentType })
            const url = URL.createObjectURL(blob)
            const link = document.createElement("a")
            link.href = url
            link.download = filename
            link.click()
        } catch (error) {
            window.alert(error.message)
        }
    }

    handleDownload()

    return (
        <RedirectToStorePath path={DASHBOARD} />
    )
}

export default LatestStatementFileDownload
