import { Tabs } from '../../components/Tabs/Tabs';
import { PageView, PageTopBar, ConfirmDialog } from '../../components';
import { UserContext } from '../../hooks/UserContext';
import { useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { TabPanel } from '../../components/Tabs/TabPanel';
import { StoreRulesSettingsContainer } from './StoreRulesSettingsContainer';
import { UsersSettingsContainer } from './UsersSettingsContainer';
import { UserCircleIcon, DocumentTextIcon, ChipIcon, AdjustmentsIcon, PencilIcon, PaintBrushIcon } from "@heroicons/react/outline"
import { useTranslation } from 'react-i18next';
import { BillingConfigContainer } from './BillingConfigContainer';
import { AutomationSettingsContainer } from "./AutomationSettingsContainer";
import { TaxesSettingsContainer } from './TaxesSettingsContainer';
import { OperationNaturSettingsContainer } from './OperationNatureSettingsContainer';
import { ObservationsSettingsContainer } from './ObservationsSettingsContainer';
import { BrandIdentitySettingsContainer } from './BrandIdentitySettingsContainer';
import { AlertsSettingsContainer } from './AlertsSettingsContainer';
import { PackingOptionsContainer } from './PackingOptionsContainer';
import { NCMsSettingsContainer } from './NCMsSettingsContainer';
import {
    setValidSettingsForm
} from '../../redux/validSettingsFormSlice';
import { TrackingUpdatesSettingsContainer } from './TrackingUpdatesSettingsContainer';
import { MailIcon, BellIcon, DocumentReportIcon } from '@heroicons/react/outline';
import BoxIcon from '../../components/Icons/BoxIcon';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { STORE_PATH, SETTINGS, SETTINGS_ALERTS, SETTINGS_AUTOMATION, SETTINGS_BRAND_IDENTITY, SETTINGS_BUSINESS_RULES, SETTINGS_PACKING_OPTIONS, SETTINGS_TAXES, SETTINGS_TRACKING_NOTIFICATIONS, SETTINGS_USERS, SETTINGS_AUTOMATIC_REPORTS, SETTINGS_AUTOMATIC_REPORT_ADD, SETTINGS_AUTOMATIC_REPORT_EDIT } from '../../navigation/constants';
import { AutomaticReportsContainer } from './AutomaticReportsContainer';
import { AutomaticReportDetail } from './AutomaticReportDetail';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import StatementEmailRecipients from './StatementEmailRecipients'

export const StoreSettingsConfig = () => {
    const validSettingsForm = useSelector((state) => state.validSettingsForm.form);
    const dispatch = useDispatch()
    const [tabContent, setTabContent] = useState('default')
    const [automaticReports, setAutomaticReports] = useState({
        reportId: null,
        reportTemplate: null,
        tabContentAutomaticReports: 'default'
    })
    const [validForm, setValidForm] = useState('default')
    const [selectedKeys, setSelectedKeys] = useState([])
    const { t } = useTranslation()
    const { user } = useContext(UserContext)
    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])
    const history = useHistory()
    const location = useLocation()
    const [currentTab, setCurrentTab] = useState(0)
    const { report_id } = useParams()
    //dispatch(setValidSettingsForm({id:"default",change:false,selectedkeys:[]}))
    //console.log(validSettingsForm)

    useEffect(() => {
        const path = location.pathname
        if (path.includes(SETTINGS_USERS)) {
            setCurrentTab(0)
        } else if (path.includes('/edit')) {
            setCurrentTab(1)
            setAutomaticReports((prevState) => ({
                ...prevState,
                tabContentAutomaticReports: '1'
            }))
        } else if (path.includes(SETTINGS_AUTOMATIC_REPORT_ADD)) {
            setCurrentTab(1)
            setAutomaticReports((prevState) => ({
                ...prevState,
                tabContentAutomaticReports: '2'
            }))
        } else if (path.includes(SETTINGS_AUTOMATIC_REPORTS)) {
            setCurrentTab(1)
        } else if (path.includes(SETTINGS_ALERTS)) {
            setCurrentTab(2)
        } else if (path.includes(SETTINGS_TAXES)) {
            setCurrentTab(3)
        } else if (path.includes(SETTINGS_AUTOMATION)) {
            setCurrentTab(4)
        } else if (path.includes(SETTINGS_BUSINESS_RULES)) {
            setCurrentTab(5)
        } else if (path.includes(SETTINGS_TRACKING_NOTIFICATIONS)) {
            setCurrentTab(6)
        } else if (path.includes(SETTINGS_BRAND_IDENTITY)) {
            setCurrentTab(7)
        } else if (path.includes(SETTINGS_PACKING_OPTIONS)) {
            setCurrentTab(8)
        } else setCurrentTab(0)
    }, [location])

    const settingsTabChanged = (index) => {
        let updated_path = "";

        if (index === 1) {
            updated_path = SETTINGS_AUTOMATIC_REPORTS
        } else if (index === 2) {
            updated_path = SETTINGS_ALERTS
        } else if (index === 3) {
            updated_path = SETTINGS_TAXES
        } else if (index === 4) {
            updated_path = SETTINGS_AUTOMATION
        } else if (index === 5) {
            updated_path = SETTINGS_BUSINESS_RULES
        } else if (index === 6) {
            updated_path = SETTINGS_TRACKING_NOTIFICATIONS
        } else if (index === 7) {
            updated_path = SETTINGS_BRAND_IDENTITY
        } else if (index === 8) {
            updated_path = SETTINGS_PACKING_OPTIONS
        } else {
            updated_path = SETTINGS_USERS
        }
        if (index === 1 && automaticReports.tabContentAutomaticReports === "1") {
            updated_path = (STORE_PATH + SETTINGS_AUTOMATIC_REPORT_EDIT).replace(":storeId", user.current_store.id).replace(":report_id", automaticReports.reportId)
        } else if (index === 1 && automaticReports.tabContentAutomaticReports === "2") {
            updated_path = (STORE_PATH + SETTINGS_AUTOMATIC_REPORT_ADD).replace(":storeId", user.current_store.id)
        } else {
            updated_path = (STORE_PATH + updated_path).replace(":storeId", user.current_store.id)
        }
        history.push(updated_path)
    }

    function getItem(label, key) {
        return {
            key,
            label
        };
    }
    const [dialog, setDialog] = useState({
        loading: false,
        open: false,
        title: "",
        description: "",
        errorMessage: "",
        type: null
    })


    const handleSelectedItem = (value) => {
        if (warehouse.country === 'BR')
            setValidForm(value)
        else
            setTabContent("1")
    }

    const items = [
        getItem(t("settings.billing_config.filscal_data"), "1"),
        getItem(t("settings.billing_config.ncms_settings"), "2"),
        getItem(t("settings.billing_config.operation_nature"), "3"),
        getItem(t("settings.billing_config.observation_invoice"), "4")
    ]
    const usersPanelTabContent = (index, selected) => {
        return (
            <TabPanel
                key={index}
                icon={<UserCircleIcon className="h-5 w-5 mr-2"></UserCircleIcon>}
                title={t("settings.store_settings.user_config")}
                description={t("settings.store_settings.description")}
                selected={selected}
                itemSelect={handleSelectedItem}
            >
            </TabPanel>
        )
    }

    const taxesInformationTabContent = (index, selected) => {

        return (
            <>
                <div>
                    <TabPanel
                        key={index}
                        icon={<DocumentTextIcon className="h-5 w-5 mr-2"></DocumentTextIcon>}
                        title={t("settings.tax_settings.tax_config")}
                        description={t("settings.tax_settings.description")}
                        selected={selected}
                        items={warehouse.country === 'BR' ? items : []}
                        itemSelect={handleSelectedItem}
                    >
                    </TabPanel>
                </div>
            </>
        )
    }

    const automationTabContent = function (index, selected) {
        return (
            <>
                <div>
                    <TabPanel
                        key={index}
                        icon={<ChipIcon className="h-5 w-5 mr-2"></ChipIcon>}
                        title={t("settings.automation_settings.title")}
                        description={t("settings.automation_settings.sub_title")}
                        selected={selected}
                        itemSelect={handleSelectedItem}
                    >
                    </TabPanel>
                </div>
            </>
        )
    }

    const storebusinessRulesTabContent = function (index, selected) {
        return (
            <>
                <div>
                    <TabPanel
                        key={index}
                        icon={<AdjustmentsIcon className="h-5 w-5 mr-2"></AdjustmentsIcon>}
                        title={t("settings.store_br.title_sbr")}
                        description={t("settings.store_br.sub_title_sbr")}
                        selected={selected}
                        itemSelect={handleSelectedItem}
                    >
                    </TabPanel>
                </div>
            </>
        )
    }

    const trackingUpdatesRulesTabContent = function (index, selected) {
        return (
            <>
                <div>
                    <TabPanel
                        key={index}
                        icon={<MailIcon className="h-5 w-5 mr-2"></MailIcon>}
                        title={t("settings.tracking_emails.config_title")}
                        description={t("settings.tracking_emails.config_subtitle")}
                        selected={selected}
                        itemSelect={handleSelectedItem}
                    >
                    </TabPanel>
                </div>
            </>
        )
    }

    const brandIdentityTabContent = function (index, selected) {
        return (
            <>
                <div>
                    <TabPanel
                        key={index}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-blue-gray-400">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.53 16.122a3 3 0 00-5.78 1.128 2.25 2.25 0 01-2.4 2.245 4.5 4.5 0 008.4-2.245c0-.399-.078-.78-.22-1.128zm0 0a15.998 15.998 0 003.388-1.62m-5.043-.025a15.994 15.994 0 011.622-3.395m3.42 3.42a15.995 15.995 0 004.764-4.648l3.876-5.814a1.151 1.151 0 00-1.597-1.597L14.146 6.32a15.996 15.996 0 00-4.649 4.763m3.42 3.42a6.776 6.776 0 00-3.42-3.42" />
                        </svg>
                        }
                        title={t("settings.brand_identity.title_sbr")}
                        description={t("settings.brand_identity.sub_title_sbr")}
                        selected={selected}
                        itemSelect={handleSelectedItem}
                    >
                    </TabPanel>
                </div>
            </>
        )
    }

    const packingConfigurationTabContent = function (index, selected) {
        return (
            <>
                <div>
                    <TabPanel
                        key={index}
                        icon={<BoxIcon />}
                        title={t("settings.packing_options.title")}
                        description={t("settings.packing_options.description")}
                        selected={selected}
                        itemSelect={handleSelectedItem}
                    >
                    </TabPanel>
                </div>
            </>
        )
    }

    const alertsTabContent = function (index, selected) {
        return (
            <>
                <div>
                    <TabPanel
                        key={index}
                        icon={<BellIcon className="h-5 w-5 mr-2"></BellIcon>}
                        title={t("settings.alerts.title_sbr")}
                        description={t("settings.alerts.sub_title_sbr")}
                        selected={selected}
                        itemSelect={handleSelectedItem}
                    >
                    </TabPanel>
                </div>
            </>
        )
    }


    const automaticReportsTabContent = function (index, selected) {
        return (
            <>
                <TabPanel
                    key={index}
                    icon={<DocumentReportIcon className="h-5 w-5 mr-2"></DocumentReportIcon>}
                    title={t("settings.automatic_reports.title")}
                    description={t("settings.automatic_reports.sub_title")}
                    selected={selected}
                    itemSelect={handleSelectedItem}
                >
                </TabPanel>
            </>
        )
    }

    const tabOptions = useMemo(() => {
        let content = <BillingConfigContainer />
        let options = []

        switch (tabContent) {
            case "1":
                content = <TaxesSettingsContainer onCancel={() => setTabContent('default')} />
                break;
            case "2":
                content = <NCMsSettingsContainer onCancel={() => setTabContent('default')} />
                break;
            case "3":
                content = <OperationNaturSettingsContainer onCancel={() => setTabContent('default')} />
                break;
            case "4":
                content = <ObservationsSettingsContainer onCancel={() => setTabContent('default')} />
                break;
            default:
                content = <BillingConfigContainer />
                break;
        }

        let automaticReportContent = <AutomaticReportsContainer setAutomaticReports={setAutomaticReports} className="w-full" />

        switch (automaticReports.tabContentAutomaticReports) {
            // Editing automatic report
            case "1":
                automaticReportContent = <Route path={STORE_PATH + SETTINGS_AUTOMATIC_REPORT_EDIT}>
                    <AutomaticReportDetail reportTemplate={automaticReports.reportTemplate} setAutomaticReports={setAutomaticReports} onCancel={() => setAutomaticReports((prevState) => ({
                        ...prevState,
                        tabContentAutomaticReports: 'default',
                    }))} />
                </Route>
                break;
            // Creating new automatic report
            case "2":
                automaticReportContent = (
                    automaticReports.reportTemplate?.report_type === "billing_statement_email_recipients" ? (
                        <StatementEmailRecipients
                            reportTemplate={automaticReports.reportTemplate}
                            setAutomaticReports={setAutomaticReports}
                            onCancel={() => setAutomaticReports((prevState) => ({
                                ...prevState,
                                tabContentAutomaticReports: 'default',
                            }))}
                        />
                    ) : (
                        <AutomaticReportDetail
                            reportTemplate={automaticReports.reportTemplate}
                            setAutomaticReports={setAutomaticReports}
                            onCancel={() => setAutomaticReports((prevState) => ({
                                ...prevState,
                                tabContentAutomaticReports: 'default',
                            }))}
                        />
                    )
                )
                break;
            default:
                automaticReportContent = <AutomaticReportsContainer setAutomaticReports={setAutomaticReports} className="w-full" />
                break;
        }

        options.push(
            {
                tabTitle: usersPanelTabContent,
                content: <UsersSettingsContainer />
            },
            {
                tabTitle: automaticReportsTabContent,
                content: automaticReportContent
            },
            {
                tabTitle: alertsTabContent,
                content: <AlertsSettingsContainer className="w-full" />
            },
            {
                tabTitle: taxesInformationTabContent,
                content: content

            }, {
            tabTitle: automationTabContent,
            content: <AutomationSettingsContainer />
        },
            {
                tabTitle: storebusinessRulesTabContent,
                content: <StoreRulesSettingsContainer className="w-full" />
            },
            {
                tabTitle: trackingUpdatesRulesTabContent,
                content: <TrackingUpdatesSettingsContainer className="w-full" />
            },
            {
                tabTitle: brandIdentityTabContent,
                content: <BrandIdentitySettingsContainer className="w-full" />
            },
            {
                tabTitle: packingConfigurationTabContent,
                content: <PackingOptionsContainer />
            },
        )
        return options

    }, [tabContent, automaticReports, t])

    useEffect(() => {

        if (validSettingsForm.change == true && validSettingsForm.id != validForm) {
            setTabContent(validSettingsForm.id)
            setDialog({ ...dialog, loading: false, open: true })
        } else {
            let selectedkeys = validForm == "default" ? [] : [validForm]
            dispatch(setValidSettingsForm({ ...validSettingsForm, selectedkeys: selectedkeys }))
            setTabContent(validForm)
        }

    }, [validForm])


    const handleClickOnConfirm = () => {
        setDialog({ ...dialog, loading: false, open: false })
        let selectedkeys = validForm == "default" ? [] : [validForm]
        dispatch(setValidSettingsForm({ id: validForm, change: false, selectedkeys: selectedkeys }))
        setTabContent(validForm)
    }
    const handleClickOnCancel = () => {
        setDialog({ ...dialog, loading: false, open: false })
        setValidForm(validSettingsForm.id)
    }

    return (
        <>
            <ConfirmDialog
                open={dialog.open}
                setOpen={(value) => setDialog({ ...dialog, open: value })}
                title={t("settings.validate_changes.title")}
                description={t("settings.validate_changes.question")}
                confirmLabel="Si"
                onConfirm={handleClickOnConfirm}
                onCancel={handleClickOnCancel}
                loading={dialog.loading}
            />
            <PageView
                topMenu={
                    <PageTopBar>
                        <div className="text-lg font-semibold"><span className="text-gray-400">{user.current_store ? user.current_store.name : `Todas las tiendas`} &rarr; </span> {t("settings.store_settings.config_title")} </div>
                    </PageTopBar>}
                childrenFullWidth={true}
            >
                <div className='rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 w-full'>
                    <Tabs key={tabOptions} options={tabOptions} defaultTabIndex={currentTab} tabChanged={settingsTabChanged} vertical />
                </div>

            </PageView>


        </>
    )
}