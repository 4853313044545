import { ruleActionBuilder, ruleActionParametersBuilder, ruleStatementBuilder, ruleTypeBuilder, ruleValidityStartDateBuilder, ruleValidityEndBuilder } from "./builders"

const businessRuleBuilder = (generalSettings, actionSettings, statementSettings, storeId, buildForPreview, timeZone) => {
    const businessRule = {
        store_id: storeId,
        enabled: true,
        rule_type: ruleTypeBuilder(actionSettings),
        rule_action: ruleActionBuilder(actionSettings),
        rule_action_parameters: ruleActionParametersBuilder(actionSettings, statementSettings, buildForPreview),
        rule_statement: ruleStatementBuilder(statementSettings, buildForPreview),
        alias: generalSettings.alias,
        validity_start_date: ruleValidityStartDateBuilder(generalSettings.validityStartDate, timeZone, buildForPreview),
        validity_end_date: ruleValidityEndBuilder(generalSettings.validityEndDate, generalSettings.withoutValidityEnd, buildForPreview)
    }

    return businessRule
}

export default businessRuleBuilder