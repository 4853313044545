import axiosInstance from "../helpers/axiosInstance"
import { getTimezone } from "../pages/Home/metrics/chartDataUtils"
import { endOfDay } from 'date-fns'

export const serializeDateFilter = (dateFilter) => {
    const toEndOfDay = dateFilter?.to ? endOfDay(dateFilter?.to) : null
    return {
        from: dateFilter?.from?.toISOString(),
        to: toEndOfDay,
    }
}

export const getStoreStats = async (storeId) => {
    // console.log("getStoreStats", storeId)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/stats-summary`)
    return res.data
}

export const getStoreInventoryStats = async (storeId) => {
    // console.log("getStoreStats", storeId)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/inventory-stats`)
    return res.data
}

export const getPickingAccuracyTotal = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
    }
    // console.log("getPickingAccuracyTotal", params)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/picking-accuracy-total`, { params })
    return res.data
}

export const getOrdersPackedOnTimeTotal = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
    }
    // console.log("getOrdersPackedOnTimeTotal", params)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/order-to-ship-total`, { params })
    return res.data
}

export const getTotalStockReceived = async (storeId, dateFilter) => {
    let params = {
        completed_at: serializeDateFilter(dateFilter),
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/total-stock-received`, { params })
    return res.data
}

export const getDockToStockTotal = async (storeId, dateFilter) => {
    let params = {
        completed_at: serializeDateFilter(dateFilter),
    }
    // console.log("getDockToStockTotal", params)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/dock-to-stock-total`, { params })
    return res.data
}

export const getOrdersOriginTotal = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
    }
    // console.log("getOrdersOriginTotal", params)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/order-origin-total`, { params })
    return res.data
}

export const getShippingMethodsTotal = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
    }
    // console.log("getShippingMethodsTotal", params)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/shipping-methods-total`, { params })
    return res.data
}

export const getStockPerOrderTotal = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
    }
    // console.log("getStockPerOrderTotal", params)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/stock-per-order-total`, { params })
    return res.data
}

export const getTotalNationalDestinations = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
    }
    // console.log("getTotalNationalDestinations", params)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/total-national-destinations`, { params })
    return res.data
}

export const getShippingMethodsHistoric = async (storeId, dateFilter, interval) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
        truncate_interval: interval,
        timezone: getTimezone(),
    }
    // console.log("getShippingMethodsHistoric", params)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/shipping-methods-historic`, { params })
    return res.data
}

export const getOrderToShipHistoric = async (storeId, dateFilter, interval) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
        truncate_interval: interval,
        timezone: getTimezone(),
    }
    // console.log("getOrderToShipHistoric", params)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/order-to-ship-historic`, { params })
    return res.data
}

export const getShippedOrdersHistoric = async (storeId, dateFilter, interval) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
        truncate_interval: interval,
        timezone: getTimezone(),
    }
    // console.log("getShippedOrdersHistoric", params)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/shipped-orders-historic`, { params })
    return res.data
}

export const getClickToDoorInDays = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
    }

    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/click-to-door-in-days`, { params })
    return res.data
}

export const getOrdersStuckBeforePendingTotal = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
    }

    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/orders-stuck-before-pending-total`, { params })
    return res.data
}

export const getFirstDeliveryAttemptOnTimeTotal = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
    }

    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/first-delivery-attempt-on-time-total`, { params })
    return res.data
}

export const getDeliveredOnTimeTotal = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
    }

    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/delivered-on-time-total`, { params })
    return res.data
}

export const getOrderStuckBeforePendingHistoric = async (storeId, dateFilter, interval) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
        truncate_interval: interval,
        timezone: getTimezone(),
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/orders-stuck-before-pending-historic`, { params })
    return res.data
}

export const getOrdersDurationStatusBeforePendingHistoric = async (storeId, dateFilter, interval, status_duration) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
        truncate_interval: interval,
        timezone: getTimezone(),
        status_duration: status_duration,
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/orders-duration-status-before-pending-historic`, { params })
    return res.data
}

export const getOrdersStuckAverage = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter)
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/orders-stuck-before-pending-average`, { params })
    return res.data
}

export const getClickToDoorHistoric = async (storeId, dateFilter, interval) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
        truncate_interval: interval,
        timezone: getTimezone(),
    }

    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/click-to-door-historic`, { params })
    return res.data
}

export const getStockPerOrderHistoric = async (storeId, dateFilter, interval) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
        truncate_interval: interval,
        timezone: getTimezone(),
    }
    // console.log("getStockPerOrderHistoric", params)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/stock-per-order-historic`, { params })
    return res.data
}

export const getShippedStockHistoric = async (storeId, dateFilter, interval) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
        truncate_interval: interval,
        timezone: getTimezone(),
    }
    // console.log("getShippedStockHistoric", params)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/shipped-stock-historic`, { params })
    return res.data
}

export const getOrderOriginHistoric = async (storeId, dateFilter, interval) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
        truncate_interval: interval,
        timezone: getTimezone(),
    }
    // console.log("getOrderOriginHistoric", params)
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/order-origin-historic`, { params })
    return res.data
}

export const getClickToDoorAverage = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter)
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/click-to-door-average`, { params })
    return res.data
}

export const getClickToPackedAverage = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter)
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/click-to-packed-average`, { params })
    return res.data
}

export const getCollectedToDoorAverage = async (storeId, dateFilter) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter)
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/collected-to-door-average`, { params })
    return res.data
}

export const getOrdersDeliveredOnTimeByStates = async (storeId, dateFilter, shippingMethodIds = null) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
        group_by: 'country_state',
        shipping_method_id: shippingMethodIds,
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/delivered-on-time`, { params })
    return res.data
}

export const getOrdersDeliveredOnTimeByCarrier = async (storeId, dateFilter, stateName = null) => {
    let params = {
        shipping_date: serializeDateFilter(dateFilter),
        group_by: 'shipping_method',
        country_state: stateName,
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/metrics/delivered-on-time`, { params })
    return res.data
}

export const getStoreCountryStates = async (storeId) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/shipping-state-names`)
    return res.data
}