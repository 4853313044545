export const EXPORT_TYPES = {
    ORDERS: {},
    INVOICES: {
        ORDERS: {
            SALE_INVOICE:  'Invoice-Id_SELL_Order-Date',
            DONATION_INVOICE: 'Invoice-Id_DONATION_Order-Date',
            SYMBOLIC_RETURN_INVOICE:  'Invoice-Id_SYMBOLIC_RETURN_Order-Date',
            REMOVE_STOCK_INVOICE:  'Invoice-Id_REMOVE_STOCK_Order-Date'
        },
        REPLENISHMENTS: {
            REPLENISHMENT_INVOICE: 'Invoice-Id_INVENTORY_REPLENISHMENT_Replenishment-Date',
            REPLENISHMENT_ADJUSTMENT_INVOICE: 'Invoice-Id_REPLENISHMENT_ADJUSTMENT_REMOVAL_Replenishment-Date',
            //REPLENISHMENT_ADJUSTMENT_REMOVAL:  'Invoice-Id_REPLENISHMENT_ADJUSTMENT_REMOVAL_Replenishment-Date'
        },
        RETURNS: {
            RETURN_INVOICE: 'Invoice-Id_RETURN_Return-Date',
            REPLENISHMENT_INVOICE: 'Invoice-Id_INVENTORY_REPLENISHMENT_Replenishment-Date',
        }
    }
}


export const EXPORT_FILE_TYPES = {
    INVOICES: {
        ORDERS: [
            'xml',
            'pdf'
        ],
        REPLENISHMENTS: [
            'xml',
            'pdf'
        ],
        RETURNS: [
            'xml',
            'pdf'
        ]
    }
}