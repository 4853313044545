import { DocumentReportIcon } from "@heroicons/react/outline"
import React from "react"
import Files from "react-files"
import i18n from "../translations/index"
import { useTranslation } from 'react-i18next'

export const fileToBase64 = async (file) => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = () => {
        resolve(reader.result)
    }
    reader.onerror = error => reject(error)
    reader.readAsDataURL(file)
  })
}
 
export const FileInputField = React.forwardRef(
  (
    {
      label = null,
      onChange = (files) => {},
      onError = (error, file, message) => {},
      required = false,
      className = "",
      containerClassName = "",
      placeholder = i18n.t("inputPlaceholder.pdfFiles"),
      multiple = true,
      maxFiles = Infinity,
      maxFileSize = 5000000,
      minFileSize = 0,
      clickable = true,
      help = null,
      accepts = ["application/pdf"]
    },
    ref
  ) => {
    const { t, i18n } = useTranslation(); 
    const onErrorHandler = (error, file) => {
      switch (error.code) {
        case 1:
          onError(error, file, i18n.t("fileInputField.not_pdf"))
          break
        case 2:
          onError(
            error,
            file,
            `${i18n.t("fileInputField.max_file")} ${maxFileSize / 1000000}${i18n.t("fileInputField.mb")}`
          )
          break
        case 3:
          onError(
            error,
            file,
            `${i18n.t("fileInputField.min_file")} ${minFileSize / 1000000}${i18n.t("fileInputField.mb")}`
          )
          break
        case 4:
          onError(
            error,
            file,
            `${i18n.t("fileInputField.max_images")} ${maxFiles} ${i18n.t("fileInputField.max_images_text")}`
          )
          break
      }
    }

    return (
      <div className={containerClassName}>
        {label && (
          <label className="block text-sm font-medium text-gray-700">
            {label}
            {required && <span className="text-red-500"> *</span>}
          </label>
        )}
        <div className="mt-1">
          <Files
            className={
              "border-dashed rounded border-2 border-black border-opacity-20 p-5 flex justify-center items-center cursor-pointer" +
              className
            }
            onChange={(files) => onChange(files)}
            onError={(error, file) => onErrorHandler(error, file)}
            accepts={accepts}
            multiple={multiple}
            maxFiles={maxFiles}
            maxFileSize={maxFileSize}
            minFileSize={minFileSize}
            clickable={clickable}
            ref={ref}
          >
            <DocumentReportIcon
              className="h-5 w-5 text-gray-400 mr-2"
              aria-hidden="true"
            />
            <span className="text-gray-500">{placeholder}</span>
          </Files>
        </div>
        {help && <div className="italic text-gray-400 mt-1">{help}</div>}
      </div>
    )
  }
)
