import {useContext, useRef, useState} from "react";
import { Loader } from '../../components/Loader';
import {getSkus, BasicTable} from "../../components";
import {useQuery} from "react-query";
import {fetchReplenishmentProducts } from "../../services";
import {useParams, useLocation} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {UserContext} from "../../hooks/UserContext";
import {
    setProductsListPageSize
} from '../../redux/pageSizeSlice';


export const ViewReplenishment = () => {

    const { t, i18n} = useTranslation();
    let { id } = useParams()
    const tableRef = useRef()
    const dispatch = useDispatch()
    const pageSize = useSelector((state) => state.pageSize.productsList)
    const {user} = useContext(UserContext)
    const [searchParams, setSearchParams] = useState({
        page: 1,
        per_page: 100, //Force it to 100 in this case, intentionally
        store_id: user.current_store?.id,
        include_stock: true
    })

    const { search } = useLocation();
    const completed = new URLSearchParams(search).get("completed");

    const {
        isLoading,
        isFetching,
        isError,
        error,
        data
    } = useQuery(['replenishment-products', id, searchParams], ()=>fetchReplenishmentProducts(id, searchParams))

const columns = [
    {
        // Build our expander column
        id: 'name', // Make sure it has an ID producto
        accessor: 'name',
        Header: "replenishments.viewReplenishmentInfo.name",
        disableFilters: false,
        disableSortBy: false,
        disableWrap: true,
    },
    {
        Header: "replenishments.viewReplenishmentInfo.sku",
        accessor: 'skus', //SKU
        disableFilters: false,
        disableSortBy: false,
    },
    {
        Header: "replenishments.viewReplenishmentInfo.expected_quantity",
        accessor: 'quantity', //CANTIDAD
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "replenishments.viewReplenishmentInfo.received_quantity",
        accessor: 'received_quantity', //CANTIDAD
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "replenishments.viewReplenishmentInfo.stored_quantity",
        accessor: 'stored_quantity', //CANTIDAD
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: "replenishments.viewReplenishmentInfo.damaged_quantity",
        accessor: 'damaged_quantity', //CANTIDAD
        disableFilters: true,
        disableSortBy: true,
    }
]


    return (
        <>
            {/* <h3>Tu recibo fué completado</h3> */}
            {/* {console.log(data)} */}
            {isLoading ? (
                    <>{i18n.t("replenishments.viewReplenishment.loading_summary")}</>
                ) : isError ? (
                    <>Error: {error.message}</>
                ) : (
                    <>
                    <Loader show={isFetching} />
                    {i18n.t("replenishments.viewReplenishment.detail_parts_received")}
                    <div className= "mt-5">
                        <BasicTable
                            labelToPaginator={i18n.t("inventory.listProducts.products")}
                            columns={columns}
                            showHeader
                            showPaginator
                            data={data.products.map(product => {
                                return {
                                    ...product,
                                    damaged_quantity:  
                                        <span className={"text-red-400"}>{product.damaged_quantity}</span>,
                                    skus: getSkus(product.product_codes, true)
                                }
                            })}
                            paginationMeta={data?.meta}
                            onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
                            onPageSizeChange= { (pageSize) => {
                                setSearchParams({...searchParams, per_page: pageSize, page: 1})
                                dispatch(setProductsListPageSize(pageSize))
                                tableRef.current.resetPagination()
                            }}
                            pagesSize = {searchParams.per_page}
                        />
                    </div>
                    </>
                    )}
                    
        </>
    )
}