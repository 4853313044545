import { useContext, useRef, useState } from "react"
import { Loader } from '../../components/Loader'
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { StatusHighlighted } from "../../components/NewTable/StatusHighlighted"
import { COMPLETED, DEFAULT_QUERY_STALE_TIME, IN_PROGRESS, IN_VALIDATION, REJECTED, REQUESTED, SCHEDULED, WORK_ORDER_STATUSES_HIGHLIGHTED_STYLES } from "./utils/constants"
import { fetchWorkOrders } from "../../services/workOrderServices"
import { FormattedDate } from "../../components"
import { DownloadIcon } from "@heroicons/react/outline"
import { LINE_TYPE_END, LINE_TYPE_MIDDLE, LINE_TYPE_NONE, LINE_TYPE_START, TimelineItem } from "../../components/Timeline/TimelineItem"

export const WorkOrderDetailContainer = () => {

    const { t, i18n } = useTranslation()
    let { id } = useParams()
    const searchParams = {
        id: id,
        page: 1,
        per_page: 1,
        order_by: "created_at"
    }
    const {
        isLoading,
        isFetching,
        isError,
        error,
        data
    } = useQuery(`work_order_${id}`, () => fetchWorkOrders(searchParams),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: true,
            staleTime: DEFAULT_QUERY_STALE_TIME
        }
    )

    const buildStatusHighlighted = (workOrder) => {
        return (
            <div workOrder={workOrder}>
                <StatusHighlighted
                    className=""
                    status={workOrder.status}
                    statuses={WORK_ORDER_STATUSES_HIGHLIGHTED_STYLES}
                />
                {!!workOrder.created_at && (
                    <div className="mt-3 ml-0.5">
                        <div className="mt-2 ml-0.5 text-gray-400 text-sm">
                            {i18n.t("work_orders.on_date")} <FormattedDate date={workOrder.created_at} shortDate={true} />
                        </div>
                    </div>
                )}
            </div>
        )
    }


    const renderTimelineSection = (workOrder) => {
        return (
            <div className="relative text-gray-700">
                {workOrder?.work_order_histories.map((workOrderHistory, index) => {
                    return (
                        <TimelineItem lineType={workOrder?.work_order_histories.length === 1 ? LINE_TYPE_NONE : index === 0 ? LINE_TYPE_START : index === workOrder?.work_order_histories.length - 1 ? LINE_TYPE_END : LINE_TYPE_MIDDLE} className="text-lg">
                            {
                                <>
                                    <div className='inline-block mr-1 text-sm text-gray-900'>
                                        {i18n.t(`work_orders.work_order_statuses.${workOrderHistory.new_status}.title`)}
                                    </div>

                                    {workOrderHistory.created_at && <>
                                        <div className="inline-block mr-1 text-sm">
                                            {i18n.t("work_orders.on_date")}&nbsp;
                                            {new Date(workOrderHistory.created_at).toLocaleDateString('en-GB',
                                                {
                                                    year: "2-digit",
                                                    month: "2-digit",
                                                    day: "2-digit"
                                                })
                                            }
                                        </div>

                                    </>}

                                    {workOrderHistory.notes && <>
                                        <div className="ml-3 text-sm text-gray-400 italic mt-[3px]">
                                            {i18n.t("work_orders.work_order_detail.notes")}: {workOrderHistory.notes}
                                        </div>
                                    </>}

                                    {workOrderHistory.files?.map((file, index) => (
                                        <div key={index} className="ml-3 text-sm mt-[3px]">
                                            <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_PLATFORM_URL}${file.path}`}>
                                                {file.filename} &nbsp;<DownloadIcon className="inline mb-1.5 mr-1 h-4 w-4" aria-hidden="true" />
                                            </a>
                                        </div>
                                    ))}</>
                            }
                        </TimelineItem>
                    )
                })}
            </div>
        )
    }

    return (
        <>
            {(isLoading || isFetching) ? (
                <><Loader show={true}></Loader></>
            ) : isError ? (
                <>Error: {error.message}</>
            ) : (
                <>
                    <Loader show={isFetching} />
                    <main className="flex-1">

                        <div className="flex-1 bg-white h-full pl-8 pr-8 pt-3 pb-3 relative w-full">

                            <dt className="text-xl font-medium w-full mb-3">
                                {i18n.t("work_orders.work_order_detail.order_details.title")}
                            </dt>

                            <div className="w-full border rounded-xl p-3 mb-5">
                                <table className="w-full">
                                    <tbody className="w-full p-5">
                                        <tr className="border-b">
                                            <td className="flex flex-col pb-3">
                                                <span> {i18n.t("work_orders.work_order_detail.order_details.work_order_id")}</span>
                                                <span> {i18n.t("work_orders.work_order_detail.order_details.solicitant")}</span>
                                            </td>
                                            <td className="pb-3"><span> {i18n.t("work_orders.work_order_detail.order_details.work_order_status")}</span></td>
                                        </tr>
                                        <tr className="w-full">
                                            <td className="w-1/2 border-r pt-3">
                                                <div className="flex flex-col">
                                                    <span>#{data?.work_orders[0].id}</span>
                                                    <span className="mt-2">{data?.work_orders[0].work_order_histories[0]?.created_by.email}</span>
                                                </div>
                                            </td>
                                            <td className="w-1/2 pt-3">
                                                <div className="flex flex-col m-2 mt-3">
                                                    {buildStatusHighlighted(data?.work_orders[0])}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <dt className="text-lg font-medium w-full mb-3 mt-9">
                                {i18n.t("work_orders.work_order_detail.product_origin.title")}
                            </dt>

                            <div className="w-full border rounded-xl p-3 mb-5">
                                <table className="w-full">
                                    <tbody className="w-full p-5">
                                        <tr className="border-b">
                                            <td className="w-1/3 pb-3">
                                                <span>{i18n.t("work_orders.work_order_detail.product_origin.title")}</span>
                                            </td>
                                            <td className="w-2/3 pb-3"><span >{i18n.t(`work_orders.creation_sections.required_material.product_origin.options.${data?.work_orders[0].product_origin}.title`)}</span></td>
                                        </tr>
                                        <tr>
                                            <td className="w-1/3 pt-3">
                                                <span>{i18n.t("work_orders.work_order_detail.product_origin.rem_order_id")}</span>
                                            </td>
                                            {
                                                (data?.work_orders[0].order_references) ? (
                                                    <td className="w-2/3 pt-3"><span>#{data?.work_orders[0].order_references}</span></td>
                                                ) : (
                                                    <td className="w-2/3 pt-3 text-gray-500 italic"><span>{i18n.t("work_orders.work_order_detail.product_origin.no_rem_order")}</span></td>
                                                )
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <dt className="text-lg font-medium w-full mb-3 mt-9">
                                {i18n.t("work_orders.work_order_detail.work_order_info.title")}
                            </dt>

                            <div className="w-full border rounded-xl p-3 mb-5">
                                <table className="w-full">
                                    <tbody className="w-full p-5">
                                        <tr className="border-b">
                                            <td className="w-1/3 pb-3">
                                                <span>{i18n.t("work_orders.work_order_detail.work_order_info.work_order_type")}</span>
                                            </td>
                                            <td className="w-2/3 pb-3"><span>{i18n.t(`work_orders.creation_sections.work_order_type.select_work_order.options.${data?.work_orders[0].work_order_type}.title`)}</span></td>
                                        </tr>
                                        <tr className="border-b">
                                            <td className="w-1/3 pt-3 pb-3">
                                                <span>{i18n.t("work_orders.work_order_detail.work_order_info.notes")}</span>
                                            </td>
                                            <td className="w-2/3 pt-3 pb-3">
                                                <span>{data?.work_orders[0]?.notes}</span>
                                            </td>

                                        </tr>
                                        <tr className="border-b">
                                            <td className="w-1/3 pt-3 pb-3">
                                                <span >{i18n.t("work_orders.work_order_detail.work_order_info.instruction")}</span>
                                            </td>
                                            <td className="w-2/3 pt-3 pb-3">
                                                {data?.work_orders[0]?.files.map((file, index) => (
                                                    <div key={index}>
                                                        <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_PLATFORM_URL}${file.path}`}>
                                                            {file.filename} &nbsp;<DownloadIcon className="inline mb-1.5 mr-1 h-4 w-4" aria-hidden="true" />
                                                        </a>
                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-1/3 pt-3">
                                                <span >{i18n.t("work_orders.work_order_detail.work_order_info.activity")}</span>
                                            </td>
                                            <td className="w-2/3 pt-3">{renderTimelineSection(data?.work_orders[0])}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <dt className="text-lg font-medium w-full mb-3 mt-9">
                                {i18n.t("work_orders.work_order_detail.replenishment.title")}
                            </dt>

                            <div className="w-full border rounded-xl p-3 mb-5">
                                <table className="w-full">
                                    <tbody className="w-full p-5">
                                        <tr>
                                            <td className="w-1/3 pt-3 pb-3">
                                                <span>{i18n.t("work_orders.work_order_detail.replenishment.replenishment_id")}</span>
                                            </td>
                                            <td className="w-2/3 pt-3 pb-3">
                                                <div className="flex flex-col">
                                                    <span>#{data?.work_orders[0].replenishment_references}</span>
                                                    <span className="text-sm text-gray-500 mt-1">{i18n.t("work_orders.work_order_detail.replenishment.replenishment_note")}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </main>
                </>
            )}

        </>
    )
}