import { useEffect, useState } from "react";

import { FormattedRelativeTime } from './FormattedRelativeTime';
import { FormattedDate } from './FormattedDate';
import { LightBulbIcon } from '@heroicons/react/outline';
import { LINE_TYPE_END, LINE_TYPE_START, TimelineItem } from "./Timeline/TimelineItem";
import { FlagIcon } from "./Icons/FlagIcon";

export const ShippingIncidents = ({shippingIncidents, t}) => {
    /*
        shippingIncidents, example content:

        const shippingIncidents = [
            {
                id: 1,
                status: "PENDING",
                category: "FALSE_DELIVERY",
                description: null,
                resolution: "STOLEN",
                resolved_at: null,
                created_at: "2023-02-15T14:39:44.568Z",
                updated_at: "2023-02-15T14:39:44.568Z",
                order_id: 17,
                origin: "CUBBO"
            },
            {
                id: 2,
                status: "RESOLVED",
                category: "DELAYED",
                description: null,
                resolution: "DELIVERED",
                resolved_at:"2023-03-12T13:23:14.568Z",
                created_at: "2023-02-15T14:39:44.568Z",
                updated_at: "2023-02-15T14:39:44.568Z",
                order_id: 17,
                origin: "CUBBO"
            },{
                id: 3,
                status: "RESOLVED",
                category: "CANCELED",
                description: "Hello World!",
                resolution: "RETURNED",
                resolved_at:"2023-03-12T13:23:14.568Z",
                created_at: "2023-02-15T14:39:44.568Z",
                updated_at: "2023-02-15T14:39:44.568Z",
                order_id: 17,
                origin: "CUBBO"
            }
        ]
    */

    const [showDescriptions, setShowDescriptions] = useState([/* {id: int, isShow: boolean} */])

    useEffect(() => {
        const newShowDescriptions = shippingIncidents?.map(({id}) => ({
            id: id,
            isShow: false
        }))

        setShowDescriptions(newShowDescriptions)
    },[shippingIncidents])

    const toggleShowDescription = (arrayId) => {
        const newShowDescriptions = [...showDescriptions]

        newShowDescriptions[arrayId].isShow = !newShowDescriptions[arrayId].isShow

        setShowDescriptions(newShowDescriptions)
    }

    if(!showDescriptions?.length) {return <></>}

    const buildIncidentsDesription = (incident) => {

        if (incident.category === 'CHANGE_ADDRESS'){
            const changeAddressIncident = JSON.parse(incident?.description)
            return (
                <>
                    <div className="flex flex-row gap-3 font-medium text-black mb-2">
                        <div>{t("orders.showContainer.new_address")}</div>
                    </div>
                        <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {t("orders.showContainer.address")}</span>
                            {changeAddressIncident?.shipping_address_1 + " " + changeAddressIncident?.shipping_address_2}</p>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                        {t("orders.showContainer.state")}</span>{(changeAddressIncident?.state || "")}</p>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {t("orders.showContainer.city")}</span>{(changeAddressIncident?.city || "")}</p>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {t("orders.showContainer.zip_code")}</span>{(changeAddressIncident?.zip_code || "")}</p>
                </>
            )
        }else if (incident.category === 'RESCHEDULE'){
            const rescheduleIncident = JSON.parse(incident?.description)
            return (
                <>
                        <div className="flex flex-row gap-3 font-medium text-black mb-2">
                            <div>{t("orders.showContainer.previous_delivery_date")}</div>
                        </div>
                        <p>
                            <FormattedRelativeTime
                                className="font-semibold"
                                forceDays={true}
                                date={rescheduleIncident?.estimated_time_arrival}
                                time_zone={'America/Mexico_City'}
                            />
                         </p>
                </>
            )
        } else {
            return <> {incident?.description} </>
        }
    }

    return (
        <div>
            {shippingIncidents?.map( (incident, index) => {

                const title = t(`orders.show_shipping_incidents.categories.${incident?.category}`)
                const status = t(`orders.show_shipping_incidents.status.${incident?.status}`)
                const info = t(`orders.show_shipping_incidents.messages.info.${incident?.status}.${incident?.category}${incident?.status === "RESOLVED" ? `.${incident?.resolution}` : ""}`)
                const tips = t(`orders.show_shipping_incidents.messages.tips.${incident?.status}.${incident?.category}${incident?.status === "RESOLVED" ? `.${incident?.resolution}` : ""}`)
                const importantInfo = t(`orders.show_shipping_incidents.messages.important_info.${incident?.status}.${incident?.category}${incident?.status === "RESOLVED" ? `.${incident?.resolution}` : ""}`)
                const descriptionTitle = t(`orders.show_shipping_incidents.description_title.${incident?.status}.${incident?.category}`)

                const isResolved = !!incident?.resolved_at
                const hasInfo = !!info.length
                const hasTips = !!tips.length
                const hastImportantInfo = !!importantInfo.length
                const hasDescription = !!incident?.description?.length
                const {isShow: isDescriptionShown} = showDescriptions?.find(({id}) => id === incident?.id)
                const statusDateTime = isResolved ? incident?.resolved_at : incident?.updated_at

                const isPink = incident?.status === "IN_REVIEW"
                const isGreen = incident?.status === "RESOLVED"

                let resolutionComplement = ""

                if(isResolved) {
                    resolutionComplement = t(`orders.show_shipping_incidents.resolutions_complements.${incident?.resolution}.${incident?.category}`)
                }

                return(
                    <div className={`border p-3 ${index >= 1 ? "mt-3" : ""} bg-gray-50 rounded-lg border-gray-50`}>
                        <p className="text-gray-800 font-medium text-base m-0 pb-3">{title}</p>

                        <div className="overflow-hidden max-h-12 p-1">
                            <TimelineItem lineType={LINE_TYPE_START} className="font-normal text-base">
                                <p className="text-gray-600 font-normal text-base m-0">

                                    {t(`orders.show_shipping_incidents.created_by.${incident?.origin}`)}

                                    <span className="lowercase">
                                        <FormattedRelativeTime date={incident?.created_at}/>
                                    </span>

                                    {t(`orders.show_shipping_incidents.from_date`)}

                                    <FormattedDate date={incident?.created_at} onlyDate={true}/>

                                    {"."}
                                </p>
                            </TimelineItem>
                            <TimelineItem lineType={LINE_TYPE_END} className="font-normal text-base">
                                <p className="text-gray-600 font-normal text-base m-0">

                                    <span className={`text-base ${isPink ? "text-pink-600" : isGreen ? "text-green-600" : "text-gray-600" }`}>

                                        <span className="font-medium">
                                            {status}
                                        </span>
                                        {resolutionComplement}
                                    </span>

                                    <span className="lowercase">
                                        {" "}
                                        <FormattedRelativeTime date={statusDateTime}/>
                                    </span>

                                    {t(`orders.show_shipping_incidents.from_date`)}

                                    <FormattedDate date={statusDateTime} onlyDate={true}/>

                                    {`.`}
                                </p>
                            </TimelineItem>
                        </div>

                        {hasInfo && (
                            <p className="text-gray-600 font-normal text-base m-0 pt-3">{info}</p>
                        )}

                        {hastImportantInfo && (
                            <p className="text-gray-600 font-normal text-base m-0 pt-3">
                                <span className="text-gray-800 font-medium text-base">
                                    {t(`orders.show_shipping_incidents.info_types.important`)}
                                </span>
                                {importantInfo}
                            </p>
                        )}

                        {hasTips && (
                            <div className="flex row items-top gap-2 pt-3">
                                <div className="w-5 h-5">
                                    <LightBulbIcon className='w-5 h-5 inline'/>
                                </div>
                                <p className="text-gray-600 font-normal text-base m-0">{tips + "."}</p>
                            </div>
                        )}

                        {hasDescription && (
                            <div
                                className="pt-3"
                                >

                                <span onClick={() => {toggleShowDescription(index)}} className="text-blue-500 hover:border-blue-400 font-normal text-base cursor-pointer">

                                    {isDescriptionShown ? t(`orders.show_shipping_incidents.toggle_description.hide`) : t(`orders.show_shipping_incidents.toggle_description.show`)}

                                    {descriptionTitle}
                                </span>

                                {isDescriptionShown && (
                                    <>
                                        <div className="text-gray-500 font-normal text-sm p-3">
                                                {buildIncidentsDesription(incident)}               
                                        </div>

                                        {incident?.file_paths && (
                                            <div className="flex flex-col gap-2">
                                                {Object.keys(incident.file_paths).map(key => (
                                                    <div className="items-top gap-2 p-2">
                                                        <p className="pm-1">{t(`orders.show_shipping_incidents.evidence_type.${key}`) }</p>

                                                        <div className="py-1 px-1 flex gap-2">
                                                            {incident.file_paths[key].map(file => (
                                                                <a
                                                                    href={process.env.REACT_APP_PLATFORM_URL + file.path}
                                                                    target="_blank"
                                                                    key={String(file.path)}
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <img
                                                                        src={process.env.REACT_APP_PLATFORM_URL + file.path}
                                                                        href={process.env.REACT_APP_PLATFORM_URL + file.path}
                                                                        alt="Incident file"
                                                                        class="h-24 w-24 rounded-lg bg-gray-100"
                                                                    />
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}