import {setProductData} from "../../../redux/createProductSlice";
import {Button, InputField, SelectInput} from "../../../components";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {setOrderData} from "../../../redux/createOrderSlice";
import {useState,useContext} from "react";
import CalendlyReact from "../../../components/CalendlyReact";
import  i18n from "../../../translations/index";

export const CanlendlyView = ({
    title,
    replesishmentData,
    user,
    country,
    onSave,
    onCancel
}) => {

    const [data, setData] = useState(false)
    
    const Scheduled = (data) =>{
        setData(data)
        if(onSave){
            onSave(replesishmentData.id,data)
        }
    }
   
    // const handleClickOnSave = () => {
    //     if(onSave){
    //         onSave(replesishmentData.id,data)
    //     }
    // }

    const handleClickOnCancel = () => {
        if(onCancel){
            onCancel()
        }
    }

    return (
        <div>
            <div className="space-y-3 ">
                <div>
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
                    </div>
                </div>

                <CalendlyReact  prefill={replesishmentData} user={user} country={country} onScheduled={Scheduled}></CalendlyReact>

                

                <div className="mt-5 pt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <Button type="secondary" onClick={() => handleClickOnCancel()} disabled={data !== false}> {i18n.t("replenishments.canlendlyView.do_not_schedule")}</Button>
                    <Button  onClick={() => handleClickOnCancel()} disabled={data === false}> {i18n.t("replenishments.canlendlyView.close")} </Button>
                </div>
            </div>
            
        </div>
    )
}