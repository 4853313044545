import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import { capitalizeFirstLetter } from "../../../../../../utils/StringUtils"

const CustomChannelCondition = ({conditionContent, onUpdateConditionContent, channelIntegrationsData}) => {
    const { t } = useTranslation()

    const validationOptionErrorMessage = useMemo(() => {
        return conditionContent.selectedValidationOption !== "" ? "" : t("settings.business_rule_creator.business_rule_statements.custom.error_messages.unselected_validation_option")
    }, [conditionContent, t])

    return (
        <div>
            <div className={`p-5 border flex flex-col gap-2 ${validationOptionErrorMessage !== "" ? "border-red-300" : ""}`}>
                <div className="flex gap-2">
                    <div className="font-semibold">
                        {t("settings.business_rule_creator.business_rule_statements.custom.title_business_rules_per_integration")}
                        <span className="text-red-500">{" *"}</span>
                    </div>

                    {validationOptionErrorMessage === "" ? (
                        <CheckCircleIcon className="h-6 text-green-600" />
                    ) : (
                        <ExclamationCircleIcon className="h-6 text-red-600" />
                    )}
                </div>

                <div className="flex">
                    <select
                        value={conditionContent.selectedValidationOption || ""}
                        className="border-gray-300 w-3/4"
                        onChange={(event) => {
                            const id = event.target.value
                            const channel = channelIntegrationsData.find(item => item.id === Number(id))
                            onUpdateConditionContent({...conditionContent, selectedValidationOption: id, channel_name: buildChannelNameLabel(channel)})
                        }}
                    >
                        <option value={""} disabled hidden>{""}</option>

                        {channelIntegrationsData.map((ch) => (
                            <option value={ch.id} key={ch.id}>
                                {capitalizeFirstLetter(buildChannelNameLabel(ch))}
                            </option>
                        ))}
                    </select>
                </div>

                {validationOptionErrorMessage !== "" && (
                    <div className="text-red-500">
                        {validationOptionErrorMessage}
                    </div>
                )}
            </div>

        </div>
    )
}

const buildChannelNameLabel = (channel) => {
    const channelNameLabel = channel.safe_config != null && (channel.safe_config?.domain || channel.safe_config?.app_name) ? `${channel.channel_type_name} - ${channel.safe_config.domain || channel.safe_config.app_name}` : channel.channel_type_name
    return channelNameLabel
}


export default CustomChannelCondition