import axios from "axios"
import userToken from "../redux/userToken"
import createAuthRefreshInterceptor from "axios-auth-refresh"

const qs = require("qs")

// Serialize nested objects
const requestInterceptor = function (config) {
    config.paramsSerializer = (params) => {
        return qs.stringify(params, {
            arrayFormat: "brackets",
            encode: true,
            strictNullHandling: true,
        })
    }
    if (userToken.token) {
        config.headers.Authorization = `Bearer ${userToken.token}`
    }
    return config
}

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) =>
    axios
        .post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user/refresh_token`, {}, { withCredentials: true })
        .then((tokenRefreshResponse) => {
            userToken.token = tokenRefreshResponse.data.token
            failedRequest.response.config.headers["Authorization"] = "Bearer " + tokenRefreshResponse.data.token
            return Promise.resolve()
        })
        .catch((error) => {
            window.location = "login"
            return Promise.reject()
        })

const baseURl = process.env.REACT_APP_PLATFORM_URL
let headers = {}
const token = userToken.token
if (token) {
    headers.Authorization = `Bearer ${token}`
}
const axiosInstance = axios.create({
    baseURL: baseURl,
    headers,
})
axiosInstance.interceptors.request.use(requestInterceptor)
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic)

export default axiosInstance
