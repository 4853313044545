export const TextareaField = ({label, helpText, className, htmlFor=null, rows=3, value, onChange=null, maxlength=null, required=false}) => {

    const onChangeHandler = (event) => {
        if(onChange!==null)
            onChange(event.target.value)
    }

    return (
        <div className={className}>
            <label htmlFor={htmlFor} className="block text-sm font-medium text-gray-700">
                {label}
                {required && <span className="text-red-500"> *</span>}
            </label>
            {helpText && <div className="text-gray-600 mt-1 mb-3">{helpText}</div>}
            <div className="mt-1">
                <textarea
                    id={htmlFor}
                    name={htmlFor}
                    rows={rows}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    defaultValue={value}
                    maxLength = {maxlength}
                    onChange={onChangeHandler}
                />
            </div>
        </div>
    )
}