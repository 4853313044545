import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";

import { AVAILABLE_ACTION_OPTIONS, AVAILABLE_ACTION_OPTIONS_FOR_BR } from "../../utils/businessRuleActionUtils";
import { UserContext } from "../../../../../hooks/UserContext";

const ActionOptions = ({selectedActionOption, onSelectedActionOption}) => {
    const { t, i18n } = useTranslation()
    const { user } = useContext(UserContext)

    const handleOnSelectActionOption = (newSelectedActionOption) => {
        const actionOption = newSelectedActionOption === selectedActionOption ? null : newSelectedActionOption
        onSelectedActionOption(actionOption)
    }

    const availableActionOptions = useMemo(() => {
        const storeWarehouses = user.current_store.warehouses
        const isBrWarehouse = storeWarehouses.some((warehouse) => warehouse.country?.toUpperCase() === "BR")
        const availableOptions = isBrWarehouse ? Object.values(AVAILABLE_ACTION_OPTIONS_FOR_BR) : Object.values(AVAILABLE_ACTION_OPTIONS)

        return availableOptions
    }, [user])

    return (
        <div>
            <div className="text-lg font-semibold bg-gray-100 p-5 flex gap-2">
                <span>
                    {t("settings.business_rule_creator.business_rule_action_settings.action_options_title")}
                    <span className="text-red-500">{" *"}</span>
                </span>

                {selectedActionOption !== null ? (
                    <CheckCircleIcon className="h-6 text-green-600" />
                ) : (
                    <ExclamationCircleIcon className="h-6 text-red-600" />
                )}
            </div>

            <div className="flex flex-col p-5 gap-5">
                {availableActionOptions.map((actionOption) => (
                    (selectedActionOption === null || selectedActionOption === actionOption) && (
                        <label
                            key={actionOption}
                            htmlFor={actionOption}
                            className="border p-4 rounded cursor-pointer hover:bg-gray-50 flex justify-between gap-0.5"
                        >
                            <input
                                className="hidden"
                                type="checkbox"
                                value={actionOption}
                                name={actionOption}
                                id={actionOption}
                                onChange={() => handleOnSelectActionOption(actionOption)}
                            />

                            <span className="flex flex-col">
                                <span className="text-base font-semibold">
                                    {t(`settings.business_rule_creator.business_rule_action_settings.action_options.${actionOption}.title`)}
                                </span>

                                {!selectedActionOption && i18n.exists(`settings.business_rule_creator.business_rule_action_settings.action_options.${actionOption}.description`) && (
                                    <span className="text-sm">
                                        {t(`settings.business_rule_creator.business_rule_action_settings.action_options.${actionOption}.description`)}
                                    </span>
                                )}
                            </span>

                            {selectedActionOption === actionOption && (
                                <span className="font-normal text-blue-700 flex items-center text-lg mr-5">
                                    {t("settings.business_rule_creator.business_rule_action_settings.change_option")}
                                </span>
                            )}
                        </label>
                    )
                ))}
            </div>
        </div>
    )
}

export default ActionOptions
