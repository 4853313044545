import { useTable, useSortBy, useRowSelect} from "react-table";
import { Filter } from "./modules/Filter";
import { Loader } from "../Loader";
import { NewTableHeader } from "./modules/NewTableHeader";
import { useEffect, useMemo, useState } from "react";
import { Pagination } from "antd";
import { SelectInput } from "../SelectInput";
import { IndeterminateCheckbox } from "../BasicTable/IndeterminateCheckbox";
import { getSelectedElements } from "./NewTableUtils";

const pagesInterval = [
    { id: "10", name: '10'},
    { id: "25", name: '25'},
    { id: "50", name: '50'},
    { id: "100", name: '100'}
];

export const NewTable = ({
    columns,
    data,
    deselectOrders = false,
    setDeselectOrders = status => {},
    disableCheck = () => false,
    selectable = false,
    showHeader = true,
    showPaginationOnFooter = false,
    showPaginationOnHeader = true,
    showLoader= false,
    isFetching = false,
    emptyTableText=null,
    paginationMeta = {},
    onPaginationChange = () => {},
    onPageSizeChange = () => {},
    onSelectionChange = () => {},
    onSortChange = () => {},
    onFilterChanged = () => {},
    handleResetFilters = () => {},
    filtersData,
    hasExport = true,
    hasSpecialExport = false,
    openExportDialog = () => {},
    exportBusy = false,
    exportButtonChildren = null,
    rowProps = () => ({}),
    footerLabel = "",
    onClickFilterDropdown = (filter_key) => {}, // only for LogRocket analysis
    backgroundgColor = ""
}, ref) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        toggleAllRowsSelected,
        toggleRowSelected,
        state: {sortBy, selectedRowIds}
        
      } = useTable(
        {
          columns,
          data,
          manualSortBy: true,
          pageCount: paginationMeta?.total_pages ? paginationMeta.total_pages : 1,
          initialState: {
            pageSize: paginationMeta?.per_page ? paginationMeta.per_page : 10,
            pageIndex: paginationMeta?.current_page ? paginationMeta.current_page - 1 : 0,
            isFetching: isFetching
          },
          autoResetSelectedRows: false,
          useControlledState: state => {
            return useMemo(
                () => ({
                    ...state,
                    isFetching: isFetching
                }),
                [state, isFetching]
            )
          }
        },
        useSortBy,
        useRowSelect,
        hooks => {
            if(selectable) {
                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        noPadding: true,
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({getToggleAllRowsSelectedProps, state, ...rest}) => (
                            <div className="mt-2" onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                {/* {console.log("table state: ", state)} */}
                                {/* {console.log("rest of props: ", rest)} */}
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} disabled={state.isFetching}/>
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({row}) => disableCheck(row) ? <></> : (
                            <div onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                <label className="p-5 block">
                                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                                </label>
                            </div>
                        ),
                    },
                    ...columns,
                ])
            }
        }
      );
    
    useEffect(() => { onSortChange(sortBy) }, [sortBy])

    useEffect(() => {
        if (selectable) updateCurrentSelection(data) 
    }, [data])

    useEffect(() => {
        if(deselectOrders) {
            toggleAllRowsSelected(false)
            setSelectedElements([])
            setPreviousPageSelectedElements([])
            setDeselectOrders(false)
        }
    },[deselectOrders])

    // Update current selection when data changes 
    const updateCurrentSelection = (data) => {
        setIgnoreSelectionEffect(true)
        for (let index = 0; index < data.length; index++) {
            // Match element with selected elements ids using unique id
            if (selectedElements.hasOwnProperty(data[index].object.id)) {
                toggleRowSelected(index, true) // Select it using index
            }
            else toggleRowSelected(index, false) // Deselect it using index
        }
        setHasPageChanged(true)
        setIgnoreSelectionEffect(false)
    }

    // SEMI-MANUAL SELECTION STATE CONTROL FOR SERVER-SIDE PAGINATION
    const [selectedElements, setSelectedElements] = useState({})
    const [previousPageSelectedElements, setPreviousPageSelectedElements] = useState({})
    const [ignoreSelectionEffect, setIgnoreSelectionEffect] = useState(false)
    const [hasPageChanged, setHasPageChanged] = useState(false)

    useEffect(() => {
        if (ignoreSelectionEffect) return

        // Get current page selected elements
        // Merge selected elements from other views with current page selected elements
        const pageSelectedElements = getSelectedElements(selectedFlatRows)
        let currentSelectedElements = {...selectedElements, ...pageSelectedElements}

        // If page has not changed (that means user deselected or selected an element)
        // So we remove these deselected elements from our selected elements state
        if (!hasPageChanged) {
            let elementsToRemove = Object.keys(previousPageSelectedElements).filter((id) => !pageSelectedElements.hasOwnProperty(id))
            elementsToRemove.forEach(element => { delete currentSelectedElements[element] })
        } 
        // Else, means page was reloaded (changed page or applied/reseted a filter for example)
        // So we dont need to remove anything from our selected elements state
        else setHasPageChanged(false)

        // Set page selected elements as previous selected elements
        setPreviousPageSelectedElements({...pageSelectedElements})
        setSelectedElements({...currentSelectedElements}) // Update our selected elements state
        onSelectionChange(Object.values(currentSelectedElements)) // Update selected elements at parent component

    }, [selectedRowIds])

    return (
        <div className="flex flex-col">
            <nav className={`flex flex-row justify-between relative rounded-t-lg border-r border-l border-t ${backgroundgColor ? backgroundgColor : "bg-white"} py-2`}>
                <Filter
                    onChange={onFilterChanged}
                    resetAll={handleResetFilters}
                    filtersData={filtersData}
                    hasExport={hasExport}
                    exportBusy={exportBusy}
                    hasSpecialExport={hasSpecialExport}
                    onClickFilterDropdown={onClickFilterDropdown}
                    openExportDialog={openExportDialog}
                    backgroundgColor={backgroundgColor}
                    exportButtonChildren={exportButtonChildren}
                />
                { showPaginationOnHeader && (
                    <div className='absolute top-3 right-0 pr-2'>
                        <SelectInput
                            options={pagesInterval}
                            selectedInitial={pagesInterval.find(option => option.id == paginationMeta?.per_page) ?? pagesInterval[0]}
                            className="inline-block text-sm px-4 sm:px-0"
                            onChange={(page) => { onPageSizeChange(page) }}
                            value={pagesInterval.find(option => option.id == paginationMeta?.per_page) ?? pagesInterval[0]} 
                            />
                        <Pagination 
                            className='inline'
                            size="small"
                            current={paginationMeta?.current_page}
                            total={paginationMeta?.total_count} 
                            onChange={onPaginationChange} 
                            page={paginationMeta?.current_page}
                            pageSize={paginationMeta?.per_page}
                            showSizeChanger={false}
                        />
                    </div>
                )}
            </nav>
            <div className="flex-grow overflow-x-auto ">
                <table className="relative w-full border divide-y divide-gray-200"
                    {...getTableProps()}>
                    {showHeader &&
                        <thead className="bg-gray-50 align-top">
                            <NewTableHeader
                                headerGroups= {headerGroups}
                                isFetching={isFetching}
                                selectable= {selectable}
                            />
                        </thead>
                    }
                    <tbody className="relative align-top" {...getTableBodyProps()}>
                    <tr><td className="h-0 p-0 m-0"><Loader show={showLoader}></Loader></td></tr>
                    {rows.length === 0 && emptyTableText &&           
                        <tr className="h-16 text-gray-400"><td colSpan={columns.length}><div className="flex justify-center items-center h-16">{emptyTableText}</div></td></tr>                    
                    }
                    {rows.map(row=>{
                        prepareRow(row)
                        const props = row.getRowProps(rowProps(row))
                        let rowClassName = 'bg-white border hover:bg-gray-50'
                        if (props.onClick) rowClassName += ' cursor-pointer'

                        return (
                            <tr className={rowClassName} {...props}>
                                {row.cells.map(cell =>{
                                    // console.log(row)
                                    return (
                                        <td
                                            // colSpan={row.canExpand ? row.cells.length : 1}
                                            className={`text-sm text-gray-500` + ` ${cell.column?.disableWrap ? '' : 'whitespace-nowrap'}` + ` ${cell.column?.noPadding ? '' : ' px-5 py-5'}`}
                                            {...cell.getCellProps()}>{cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            { showPaginationOnFooter &&
                <div className={`flex justify-between items-center px-2 rounded-b-lg border-r border-l border-b ${backgroundgColor ? backgroundgColor : "bg-white"} py-3`}>
                    <div className="mx-1">
                        Total:  
                        <div className="inline mx-1 font-medium font-semibold">
                            {paginationMeta?.total_count}
                        </div>
                        {footerLabel}
                    </div>
                    <div>
                        <SelectInput
                            options={pagesInterval}
                            selectedInitial={pagesInterval.find(option => option.id == paginationMeta?.per_page) ?? pagesInterval[0]}
                            className="inline-block text-sm px-4 sm:px-0"
                            onChange={(page) => { onPageSizeChange(page) }}
                            value={pagesInterval.find(option => option.id == paginationMeta?.per_page) ?? pagesInterval[0]} 
                            />
                        <Pagination 
                            className='inline'
                            size="small"
                            current={paginationMeta?.current_page}
                            total={paginationMeta?.total_count} 
                            onChange={onPaginationChange} 
                            page={paginationMeta?.current_page}
                            pageSize={paginationMeta?.per_page}
                            showSizeChanger={false}
                        />
                    </div>
                </div>
            }
        </div>
    )
}