export const OrderSalesChannelIcon = ({ className = null }) => {
    return (
        <svg viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M72.4173 112V72.8333C72.4173 71.7946 72.8347 70.7984 73.5778 70.0638C74.3208 69.3293 75.3285 68.9167 76.3793 68.9167H92.2272C93.278 68.9167 94.2857 69.3293 95.0287 70.0638C95.7718 70.7984 96.1892 71.7946 96.1892 72.8333V112M72.4173 112H13.5688M72.4173 112H96.1892M96.1892 112H115.418M108.075 112V51.1559C110.181 49.9543 111.978 48.2899 113.329 46.2915C114.68 44.293 115.547 42.0142 115.865 39.6312C116.182 37.2483 115.94 34.8252 115.158 32.5494C114.376 30.2736 113.075 28.2061 111.356 26.507L105.069 20.2978C103.585 18.8283 101.571 18.0018 99.4697 18H29.5118C27.4109 18.0018 25.3968 18.8283 23.9122 20.2978L17.6311 26.5018C15.9155 28.2029 14.618 30.2707 13.8386 32.5458C13.0591 34.821 12.8186 37.2427 13.1356 39.6243C13.4526 42.0058 14.3185 44.2836 15.6667 46.2821C17.0149 48.2805 18.8093 49.9462 20.9116 51.1507M20.9116 111.995V51.1611C24.0813 52.9679 27.7857 53.6352 31.3963 53.0498C35.007 52.4645 38.3016 50.6624 40.7215 47.9494C42.2075 49.6177 44.0364 50.9534 46.086 51.8674C48.1356 52.7814 50.3589 53.2528 52.6075 53.25C57.3407 53.25 61.5879 51.1977 64.4934 47.9442C65.9789 49.6134 67.8075 50.9501 69.8572 51.865C71.9068 52.78 74.1304 53.2521 76.3793 53.25C81.1125 53.25 85.3598 51.1977 88.2652 47.9442C90.6858 50.6564 93.9808 52.4574 97.5914 53.0418C101.202 53.6262 104.906 52.9581 108.075 51.1507M36.7595 96.3281H56.5694C57.6202 96.3281 58.6279 95.9155 59.371 95.1809C60.114 94.4464 60.5314 93.4502 60.5314 92.4114V72.8333C60.5314 71.7946 60.114 70.7984 59.371 70.0638C58.6279 69.3293 57.6202 68.9167 56.5694 68.9167H36.7595C35.7088 68.9167 34.701 69.3293 33.958 70.0638C33.215 70.7984 32.7976 71.7946 32.7976 72.8333V92.4167C32.7976 94.5839 34.5725 96.3281 36.7595 96.3281Z"
                stroke="currentColor"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
