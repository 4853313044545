import { capitalize } from "../../../utils/StringUtils"

export const INTERVAL_DAY = "day"
export const INTERVAL_WEEK = "week"
export const INTERVAL_MONTH = "month"
export const INTERVAL_YEAR = "year"

export const DEFAULT_QUERY_STALE_TIME = 1000 * 60 * 5 // 5 minutes

export const getTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const secondsToTimeInteval = (seconds, translationHook) => {
    let months = Math.floor(seconds / (3600 * 24 * 30))
    let secondsRemaning = seconds - months * 3600 * 24 * 30
    let days = Math.floor(secondsRemaning / (3600 * 24))
    secondsRemaning = secondsRemaning - days * 3600 * 24
    let hours = Math.floor(secondsRemaning / 3600)
    secondsRemaning = secondsRemaning - hours * 3600
    let minutes = Math.floor(secondsRemaning / 60)
    secondsRemaning = secondsRemaning - minutes * 60

    const translationBasePath = "metrics.generic.durations"

    let humanStringArray = []
    if (months > 0) {
        let translationPath = `${translationBasePath}.month`
        translationPath += months > 1 ? "s" : ""
        humanStringArray.push(months + " " + translationHook(translationPath))
    }
    if (days > 0) {
        let translationPath = `${translationBasePath}.day`
        translationPath += days > 1 ? "s" : ""
        humanStringArray.push(days + " " + translationHook(translationPath))
    }
    if (hours > 0) {
        let translationPath = `${translationBasePath}.hour`
        translationPath += hours > 1 ? "s" : ""
        humanStringArray.push(hours + " " + translationHook(translationPath))
    }
    if (minutes > 0) {
        let translationPath = `${translationBasePath}.minute`
        translationPath += minutes > 1 ? "s" : ""
        humanStringArray.push(minutes + " " + translationHook(translationPath))
    }
    if (secondsRemaning > 0 && minutes === 0) {
        secondsRemaning = Math.round(secondsRemaning)
        let translationPath = `${translationBasePath}.second`
        translationPath += secondsRemaning > 1 ? "s" : ""
        humanStringArray.push(secondsRemaning + " " + translationHook(translationPath))
    }
    const humanString = humanStringArray.join(" ")
    return humanString
}

export const formatDateForInterval = (date, interval, localeCode) => {
    // const newDate = new Date(date)
    if (interval === INTERVAL_DAY) {
        return date.toLocaleString(localeCode, { dateStyle: "medium" })
    } else if (interval === INTERVAL_WEEK) {
        return date.toLocaleString(localeCode, { dateStyle: "medium" })
    } else if (interval === INTERVAL_MONTH) {
        return date.toLocaleString(localeCode, { month: "short", year: "2-digit" })
    } else if (interval === INTERVAL_YEAR) {
        return date.getFullYear()
    }
    return date.toLocaleString(localeCode)
}

export const capitalizeCategories = (data, percentage = false, categoriesKey = "categories") => {
    if (!data) return []
    let uniqueCategories = new Set()
    const newCapitalizedData = data.map((item) => {
        const categories = Object.keys(item[categoriesKey]).reduce((object, key) => {
            object[capitalize(key)] = item[categoriesKey][key]
            return object
        }, {})
        if (percentage) {
            // Calculate total and covert it to %
            const total = Object.values(categories).reduce((acc, value) => acc + value, 0);
            Object.keys(categories).forEach(key => {
                categories[key] = new PercentageValue(categories[key], total)
            })
        }
        let newItem = { ...item }
        newItem[categoriesKey] = categories
        Object.keys(categories).forEach((category) => {
            uniqueCategories.add(category)
        })
        return newItem
    })
    return [newCapitalizedData, Array.from(uniqueCategories)]
}

const getCategoryLabelForRange = (range, groupFrom, unit_text, units_text) => {
    if (typeof range === 'number') {
        if (range === 0) {
            return `<1 ${unit_text}`;
        } else if (range === groupFrom) {
            return `${groupFrom}+ ${units_text}`;
        } else if (range === 1) {
            return `${range} ${unit_text}`;
        } else {
            return `${range} ${units_text}`;
        }
    } else {
        const first = range[0]
        const last = range.slice(-1)[0]
        if(first === 0 && last === 1)
            return `<1 ${unit_text}`
        else if(first === last && first === 1)
            return `${first} ${unit_text}`
        else if (first === last)
            return `${first} ${units_text}`
        else return `${first}-${last} ${units_text}`
    }
}

const getCategoryLabelForKey = (key, ranges, groupFrom, unit_text, units_text) => {
    const numberKey = parseInt(key);
    let categoryLabel = '';
    if (numberKey < 0) return null;

    const keyPresentInRange = ranges.findIndex(item => Array.isArray(item) && item.includes(parseInt(key))) !== -1

    if (key === 0 && !keyPresentInRange) {
        categoryLabel = `<1 ${unit_text}`;
    } else if (key === 1 && !keyPresentInRange) {
        categoryLabel = `${key} ${unit_text}`;
    } else if (key >= groupFrom) {
        categoryLabel = `${groupFrom}+ ${units_text}`;
    } else {
        const position = ranges.findIndex(item => 
            (Array.isArray(item) && item.includes(parseInt(key))) || item === parseInt(key)
        );
        if (typeof ranges[position] === 'number') {
            if (ranges[position] === 1) {
                categoryLabel = `${ranges[position]} ${unit_text}`;
            } else {
                categoryLabel = `${ranges[position]} ${units_text}`;
            }
        } else {
            categoryLabel = getCategoryLabelForRange(ranges[position], groupFrom, unit_text, units_text);
        }
    } 
    return categoryLabel   
}


export const groupDaysByRanges = (data, ranges, unit_text, units_text, categoriesKey = "categories") => {
    if (!data) return []

    const groupFrom = ranges.slice(-1)[0]

    // Create initial categories array
    let categoriesArray = ranges.map(range => getCategoryLabelForRange(range, groupFrom, unit_text, units_text))

    const newGroupedData = data.map((item) => {
        const groupedValues = Object.keys(item[categoriesKey]).reduce((object, key) => {
            key = parseInt(key)
            if (key < 0) return object;
            const categoryLabel = getCategoryLabelForKey(key, ranges, groupFrom, unit_text, units_text)
            object[categoryLabel] = (object[categoryLabel] || 0) + item[categoriesKey][key]
            return object;
        }, {})

        // Calculate total and convert it to %
        const total = Object.values(groupedValues).reduce((acc, value) => acc + value, 0);
        Object.keys(groupedValues).forEach(key => groupedValues[key] = new PercentageValue(groupedValues[key], total))

        let newItem = { ...item }
        newItem[categoriesKey] = groupedValues
        return newItem
    })

    return [newGroupedData, categoriesArray]
}


export const parseDates = (data, dateKey = "truncated_date") => {
    if (!data) return []
    const newParsedDates = data.map((item) => {
        let newItem = { ...item }
        newItem[dateKey] = new Date(item[dateKey])
        return newItem
    })
    return newParsedDates
}

// create data shape for BarChartCard
export const popCategories = (data, categoriesKey = 'categories', dateKey = 'truncated_date') => {
    const newTransformedData = data.map((item) => {
        const newItem = { name: item[dateKey] }
        if (categoriesKey) {
            Object.keys(item[categoriesKey]).forEach((category) => {
                newItem[category] = item[categoriesKey][category]
            })
        } else {
            Object.keys(item).forEach((key) => {
                if (key !== dateKey) {
                    newItem[key] = item[key]
                }
            })
        }
        return newItem
    })
    return newTransformedData
}

export const formatDates = (data, interval, locale) => {
    const newFormattedData = data.map((item) => {
        const newItem = {
            ...item,
            name: formatDateForInterval(item.name, interval, locale),
        }
        return newItem
    })
    return newFormattedData
}

export const replaceCategories = (data, categoryMap, categoriesKey = null) => {
    if (!data) return []
    const dataReplacedCategories = data.map((item) => {
        let newItem = { ...item }
        if (categoriesKey) {
            newItem[categoriesKey] = Object.keys(newItem[categoriesKey]).reduce((object, key) => {
                if (categoryMap[key]) {
                    object[categoryMap[key]] = newItem[categoriesKey][key]
                } else {
                    object[key] = newItem[categoriesKey][key]
                }
                return object
            }, {})
        } else {
            newItem = Object.keys(newItem).reduce((object, key) => {
                if (categoryMap[key]) {
                    object[categoryMap[key]] = newItem[key]
                } else {
                    object[key] = newItem[key]
                }
                return object
            }, {})
        }
        return newItem
    })
    return dataReplacedCategories
}

export class PercentageValue {
    constructor(value, total) {
        this._value = value
        this._total = total
    }

    // This method ensures our object behaves like an integer in arithmetic operations
    valueOf() {
        return this.getPercentage()
    }

    getPercentage() {
        if (this._total === 0) return 0
        return (this._value / this._total) * 100
    }

    getValue() {
        return this._value
    }

    getTotal() {
        return this._total
    }
}
