export const combine = (options1, options2) => {

    const result = []
    for(let i=0; i<options1.length; i++){
        for(let j=0; j<options2.length; j++){
            let value = options1[i]
            if(Array.isArray(value)){
                const temp = [...value]
                temp.push(options2[j])
                result.push(temp)
            } else {
                result.push([
                    options1[i],
                    options2[j]
                ])
            }
        }
    }
    return result
}

export const getVariants = (options) => {

    if (options.length === 1) {
        return options[0]
    }

    if (options.length === 2) {
        return combine(options[0], options[1])
    }

    let optionResult = []
    for(let i=0; i<options.length; i++){
        if( i+1<options.length ){
            if( i===0 )
                optionResult = combine(options[i], options[i+1])
            else
                optionResult = combine(optionResult, options[i+1])
        }
    }

    return optionResult

}
