/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { BasicTable, Button, getSkus, getVariants } from "../../../components"
import { ExpandibleRow } from "../../../components/BasicTable/ExpandibleRow"
import { useQuery } from "react-query"
import { fetchProducts } from "../../../services/productServices"
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { setProductListPageSize } from "../../../redux/pageSizeSlice"


export const SelectReturnedProductDialog = ({
  open,
  onConfirm = (selectedProducts) => {},
  onCancel = () => {},
  storeData,
}) => {
  const tableRef = useRef()
  const pageSize = useSelector((state) => state.pageSize.productList);
  const dispatch = useDispatch()
  const { t, i18n} = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState([])
  const [searchParams, setSearchParams] = useState({
    page: 1,
    include_stock: true,
    store_id: storeData?.id,
    per_page: pageSize
  })

  const { isLoading, isError, error, data, isFetching, isPreviousData } =
    useQuery(["products", searchParams], () => fetchProducts(searchParams), {
      keepPreviousData: true,
    })

    const columns = [
      {
        // Build our expander column
        id: "name", // Make sure it has an ID
        accessor: "name",
        Header: "return.returnedProduct.name",
        disableFilters: false,
        disableSortBy: false,
        disableWrap: true,
        Cell: ({ value, row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <ExpandibleRow
              text={value}
              expanded={row.isExpanded}
              {...row.getToggleRowExpandedProps()}
            />
          ) : (
            value
          ),
      },
      {
        Header: "SKU",
        accessor: "sku",
        disableFilters: false,
        disableSortBy: true,
        Cell: ({ value, row }) =>{
          if (value.length === undefined) {
            return value
          } 
          else if(value.length === 0)
          {
            return <i>{i18n.t("return.returnedProduct.no_sku")}</i>
          }
          else {
            return getSkus(value)
          }
        },
      },
      {
        Header: "UPC",
        accessor: "bar_code",
        disableFilters: false,
        disableSortBy: true,
        Cell: ({ value, row }) => (value ? value : <i>{i18n.t("return.returnedProduct.no_upc")}</i>),
      },
      {
        Header: "orders.productTable.available",
        accessor: 'stock.AVAILABLE',
        disableFilters: true,
        disableSortBy: false,
        shrinkToContent: true,
      }
    ]

  const getTableData = () => {
    if (!data) {
      return []
    }
    console.log(data)
    if (!data.products) {
      return []
    }

    console.log(data.products)
    return data.products.map((product) => {
      return {
        ...product,
        name: product.name,
        sku:product.product_codes,
        bar_code: product.bar_code,
        stock:
        {AVAILABLE: 
          product.stock.AVAILABLE <= 0 ? 
          <>
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ml-2 bg-red-100 text-red-800">Sin stock</span>
            </>
            :
            <>
              <span className="inline-flex items-center ml-10">{product.stock?.AVAILABLE}</span>
          </>
        },
        parent: product.parent,
        subRows: getVariants(
          product.variants,
          product.name,
          null,
          searchParams.search ? searchParams.search : null
        ),
      }
    })
  }

  const onFilterChange = (activeFilters) => {
    // console.log("ON_CHANGE_FILTER", activeFilters)
    columns.forEach((col) => {
      if (searchParams[col.accessor]) {
        delete searchParams[col.accessor]
      }
    })
    let filters = []
    activeFilters.forEach((filter) => {
      filters[filter.id] = filter.value
    })
    tableRef.current?.resetPagination()
    tableRef.current?.resetSelection()
    console.log("tableRef", tableRef);
    setSearchParams({ ...searchParams, ...filters, page: 1 })
    // console.log("searchParams",searchParams)
  }

  const onSortChange = (orderBy) => {
    // console.log("ON_ORDER_BY",orderBy)
    if (orderBy.length > 0) {
      setSearchParams({
        ...searchParams,
        order_by: orderBy[0].id,
        order: orderBy[0].desc ? "desc" : "asc",
        page:1
      })
    } else {
      if ("order_by" in searchParams) {
        delete searchParams.order_by
        delete searchParams.order
        setSearchParams({ ...searchParams, page:1 })
      }
    }
    tableRef.current?.resetPagination()
    tableRef.current?.resetSelection()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={() => onCancel()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
              <div className="text-lg font-bold text-center pb-2">
                {i18n.t("return.returnedProduct.store_inventory")} {storeData?.name}
              </div>
              <div>
                <BasicTable
                  labelToPaginator={i18n.t("return.returnedProduct.products")}
                  columns={columns}
                  data={getTableData()}
                  onSelectionChange={(rows) => {
                    setSelectedProducts(rows)
                    // console.log("rows", rows)
                  }}
                  onFilterChange={onFilterChange}
                  onSortChange={onSortChange}
                  onPaginationChange={(requestedPage) => {
                    setSearchParams({ ...searchParams, page: requestedPage })
                  }}
                  onPageSizeChange= { (pageSize) => { 
                    setSearchParams({...searchParams, per_page: pageSize, page:1})
                    dispatch(setProductListPageSize(pageSize))
                    tableRef.current?.resetPagination() 
                  } } 
                  pagesSize = {searchParams.per_page}
                  selectable
                  selectParents={false}
                  editable={false}
                  updateData={null}
                  filterable
                  sortable
                  showHeader
                  showTitle={false}
                  showPaginator
                  showLoader={isLoading || (isFetching && isPreviousData)}
                  showDataStatus={false}
                  isFetching={isFetching}
                  emptyTableText={i18n.t("return.returnedProduct.no_products_to_show")}
                  paginationMeta={data?.meta}
                  ref={tableRef}
                />
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <Button
                  onClick={() => onConfirm(selectedProducts)}
                  disabled={selectedProducts.length < 1}
                >
                  {i18n.t("return.returnedProduct.accept")}({selectedProducts.length})
                </Button>
                <Button type="secondary" onClick={() => onCancel()}>
                  {i18n.t("return.returnedProduct.cancel")}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
