import {useContext, useState} from "react";
import {UserContext} from "../../hooks/UserContext";
import {useTranslation} from "react-i18next";
import {SwitchToggleButton} from "../../components/SwitchToggleButton";
import {getConfigurations, updateCarrierReturnsCancellationSettings} from "../../services/storeServices";
import {useQuery} from "react-query";
import {Notification} from "../../components";

const useReturnsCancellationState = function () {
    const [carrierReturnReviewWindow, setCarrierReturnReviewWindow] = useState('')
    const [carrierReturnsCancellationActivated, setCarrierReturnsCancellationActivated] = useState(false)
    const [carrierReturnsCancellationIsLoading, setCarrierReturnsCancellationIsLoading] = useState(true)
    const [successfulUpdateNotificationState, setSuccessfulUpdateNotificationState] = useState(false)

    const { i18n } = useTranslation();
    const immediately_str = i18n.t("settings.automation_container.carrier_returns_cancellation_panel.immediately")
    const {user: { current_store }} = useContext(UserContext);
    const store_id = current_store?.id

    const handleCarrierReturnsCancellationToggle = async function (value) {
        if (!value && value === carrierReturnsCancellationActivated)
            return false

        if (carrierReturnReviewWindow !== 0 && !carrierReturnReviewWindow)
            return false

        setCarrierReturnsCancellationIsLoading(true)
        setCarrierReturnsCancellationActivated(value)

        await updateCarrierReturnsCancellationSettings(
            store_id,
            {
                isActive: value,
                reviewWindow: carrierReturnReviewWindow
            },
            immediately_str
        )

        setSuccessfulUpdateNotificationState(true)
        setCarrierReturnsCancellationIsLoading(false)
    }

    const handleReviewWindowInputChange = async function (value) {
        if (typeof value !== 'string') return false

        if (value === '') {
            setCarrierReturnReviewWindow(value)
            await handleReviewWindowChange(null)

            return true
        }

        const value_is_similar_to_immediately = immediately_str.toLowerCase().includes(value.toLowerCase())

        if (!value_is_similar_to_immediately && isNaN(value)) return false

        if (value_is_similar_to_immediately) {
            setCarrierReturnReviewWindow(value)

            if (immediately_str === value) {
                await handleReviewWindowChange(0)
            }

            return true
        }

        const returnReviewWindow = parseInt(value)

        if (returnReviewWindow > 0) {
            setCarrierReturnReviewWindow(returnReviewWindow)
            await handleReviewWindowChange(returnReviewWindow)
        } else if (returnReviewWindow === 0) {
            setCarrierReturnReviewWindow(immediately_str)
            await handleReviewWindowChange(returnReviewWindow)
        }
    }

    const handleReviewWindowChange = async function (value) {
        if (value == null) {
            await handleCarrierReturnsCancellationToggle(false)
            return true
        }

        setCarrierReturnsCancellationIsLoading(true)

        if (carrierReturnsCancellationActivated) {
            await updateCarrierReturnsCancellationSettings(
                store_id,
                {
                    isActive: carrierReturnsCancellationActivated,
                    reviewWindow: value
                },
                immediately_str
            )
            setSuccessfulUpdateNotificationState(true)
        }

        setCarrierReturnsCancellationIsLoading(false)
    }

    const setState = function (rawReviewWindow) {
        if (rawReviewWindow == null || rawReviewWindow < 0) {
            setCarrierReturnsCancellationIsLoading(false)
            return false
        }

        const parsedReviewWindow = rawReviewWindow / (3600*24)

        if (parsedReviewWindow > 0) {
            setCarrierReturnReviewWindow(parsedReviewWindow)
        } else if (parsedReviewWindow === 0) {
            setCarrierReturnReviewWindow(immediately_str)
        }

        setCarrierReturnsCancellationActivated(true)
        setCarrierReturnsCancellationIsLoading(false)
    }

    return {
        handleReviewWindowInputChange,
        handleCarrierReturnsCancellationToggle,
        carrierReturnsCancellationActivated,
        carrierReturnsCancellationIsLoading,
        carrierReturnReviewWindow,
        successfulUpdateNotificationState,
        setSuccessfulUpdateNotificationState,
        setState,
        store_id
    }
}

export const CarrierReturnsCancellationCard = function () {
    const {
        handleReviewWindowInputChange,
        handleCarrierReturnsCancellationToggle,
        carrierReturnsCancellationActivated,
        carrierReturnsCancellationIsLoading,
        carrierReturnReviewWindow,
        successfulUpdateNotificationState,
        setSuccessfulUpdateNotificationState,
        setState,
        store_id
    } = useReturnsCancellationState();

    const { i18n } = useTranslation();

    const buildHelpText = function () {
        let windowUnitText = ''
        if (!isNaN(carrierReturnReviewWindow))
            windowUnitText = i18n.t("settings.automation_container.carrier_returns_cancellation_panel.days", {count: carrierReturnReviewWindow})

        return i18n.t(
            'settings.automation_container.carrier_returns_cancellation_panel.help_text',
            { review_window: `${carrierReturnReviewWindow} ${windowUnitText.toLowerCase()}` }
        )
    }

    useQuery(
        ["automation_settings-carrier_returns_cancellation", store_id],
        () => getConfigurations(store_id, 'cancel_carrier_returns_after'),
        { onSuccess: setState }
    );

    return (
        <>
            <Notification
                show={successfulUpdateNotificationState}
                setShow={setSuccessfulUpdateNotificationState}
                type="success"
                title={i18n.t(
                    'settings.automation_container.success_message',
                    {
                        status: carrierReturnsCancellationActivated ?
                            i18n.t('settings.automation_container.enabled_result') :
                            i18n.t('settings.automation_container.disabled_result')
                    })
                }
            />

            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6 m-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="renew-subscription-label">
                        {i18n.t("settings.automation_container.carrier_returns_cancellation_panel.title")}
                    </h3>
                    <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                        <div className="max-w-xl text-sm text-gray-500">
                            <p>
                                {i18n.t("settings.automation_container.carrier_returns_cancellation_panel.description_left")}
                                <b>{i18n.t("settings.automation_container.carrier_returns_cancellation_panel.awaiting_review")}</b>
                                {i18n.t("settings.automation_container.carrier_returns_cancellation_panel.description_right")}
                            </p>
                        </div>
                        <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                            <SwitchToggleButton
                                value={carrierReturnsCancellationActivated}
                                onChange={event => handleCarrierReturnsCancellationToggle(event)}
                                disabled={carrierReturnsCancellationIsLoading}
                            />
                        </div>
                    </div>
                    <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                        <div>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-2 sm:text-sm border-gray-300 rounded-md"
                                    placeholder={i18n.t("settings.automation_container.carrier_returns_cancellation_panel.place_holder")}
                                    value={carrierReturnReviewWindow}
                                    onChange={event => handleReviewWindowInputChange(
                                        event.target.value,
                                        i18n.t("settings.automation_container.carrier_returns_cancellation_panel.immediately")
                                    )}
                                    disabled={carrierReturnsCancellationIsLoading}
                                    list="carrierReturnReviewWindow"
                                />
                                <datalist id="carrierReturnReviewWindow">
                                    <option value={i18n.t("settings.automation_container.carrier_returns_cancellation_panel.immediately")}>{i18n.t("settings.automation_container.carrier_returns_cancellation_panel.immediately")}</option>
                                </datalist>
                            </div>
                        </div>
                    </div>
                    {carrierReturnsCancellationActivated &&
                        <p className="mt-2 text-sm text-gray-500">
                            {buildHelpText()} <br/>
                        </p>
                    }
                </div>
            </div>
        </>
    )
}