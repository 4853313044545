import { Tooltip } from 'antd';

const PackingOptionCheckbox = ({checkboxValue, isChecked, isDisabled, tooltipText, onChangeStatus=(event)=>{}}) => {
    const onCheckboxClick = (event) => {
        if(!isDisabled){
            onChangeStatus(event)
        }
    }

    return (
        <>
            {!tooltipText ? (
                <input
                    type="checkbox"
                    value={checkboxValue}
                    checked={isChecked}
                    onChange={onCheckboxClick}
                    className={`${isDisabled ? "text-blue-200 border-blue-200 focus:ring-0" : ""}`}
                />
            ) : (
                <Tooltip
                    placement="top"
                    overlay={<span>{tooltipText}</span>}
                    arrowContent={<div></div>}
                    destroyTooltipOnHide
                >
                    <input
                        type="checkbox"
                        value={checkboxValue}
                        checked={isChecked}
                        onChange={onCheckboxClick}
                        className={`${isDisabled ? "text-blue-200 border-blue-200 focus:ring-0" : ""}`}
                    />
                </Tooltip>
            )}
        </>
    )
}

export default PackingOptionCheckbox