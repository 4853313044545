import { useQuery } from "react-query";
import { fetchOrder } from "../../services";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import {BasicTable, Button, ButtonLoader, InputField, getSkus, FormattedDate, ConfirmDialog, ShippingIncidents, Notification} from "../../components";
import { diff_minutes } from '../../components/FormattedDate';
import { Loader } from '../../components/Loader';
import {ADD_PRODUCT, EDIT_ORDER,SHIPPING_METHODS, STORE_PATH, SHOW_RETURN_ORDERS, DUPLICATE_ORDER, EDIT_ORDER_REMOVE_STOCK, EDIT_PRODUCT, EDIT_KIT} from "../../navigation/constants";
import {TRACKING_DESCRIPTIONS, TRACKING_STATUSES} from "./constants";
import { StatusHighlighted } from '../../components/NewTable/StatusHighlighted';
import {ADD_ORDER} from "../../navigation"
import {useState, useContext, useMemo, useEffect} from "react";
import { StatusPill } from '../../components/BasicTable/StatusPill';
import {ORDER_STATUSES, TRACKING_STATUSES_DEFINITIONS} from '../../components/BasicTable/StatusPillDefinitions';
import {ORDER_STATUSES as ORDER_STATUSES_STATUS_HIGHLIGHTED, TRACKING_STATUSES_DEFINITIONS as TRACKING_STATUSES_DEFINITIONS_HIGHLIGHTED} from '../../components/NewTable/StatusHighlightedDefinitions';
import { OrderLineItem } from './OrderLineItem';
import {FilePdfOutlined} from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import NumberFormat from 'react-number-format';
import {UserContext} from "../../hooks/UserContext";
import { generateLabel, cancelOrder, holdOrder, unholdOrder, payOrder, fetchOrderHistory } from "../../services/orderServices";
import { OrderShippingLabel } from "./OrderAttachments/OrderShippingLabel";
import { OrderInvoice } from "./OrderAttachments/OrderInvoice";
import { buildMessageForOrderError, filterOrderErrorsByFields, OrderErrorBanner, filterOrderErrorsByCodes, ORDER_ERRORS, OrderFieldErrorBanner, ORDER_ERRORS_ACTIONS, buildSolutionForOrderError, getErrorActions, ORDER_ERROR_ACTION_LIST } from "./OrderErrorUtils";
import { Skeleton, Tooltip } from 'antd';
import { forEach, set, uniqBy } from "lodash";
import { useCallback } from "react";
import { capitalize } from "../../utils/StringUtils";
import OrderHistory from "../../components/OrderHistory";
import { FormattedRelativeTime } from '../../components/FormattedRelativeTime';
import { InformationCircleIcon, PencilIcon, DocumentDuplicateIcon, PauseIcon, XCircleIcon, DownloadIcon} from '@heroicons/react/outline';
import { STILL_NOT_SHIPPED_SHIPMENT_STATUSES, getOrderShippingNumber } from '../../components/utils/orderUtils'
import { ProofOfDelivery } from "../../components/Orders/ProofOfDelivery";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import LogRocket from "logrocket";

export const ShowOrderContainer = ({onBusyChange = ()=>{}}) => {

    const { t, i18n} = useTranslation();
    let { id } = useParams()
    const { url:storePath } = useRouteMatch({ path: STORE_PATH })
    const history = useHistory()
    const {user} = useContext(UserContext)
    const [showBillingInfo, setShowBillingInfo] = useState(false)
    const [loadingLabel, setLoadingLabel] = useState(false)
    const [trackingNumber, setTrackingNumber] = useState(null)
    const [labelGenerationError, setLabelGenerationError] = useState(null)
    const [printGeneratedLabel, setPrintGeneratedLabel] = useState(false)
    const [shippingDocuments, setShippingDocuments] = useState([])
    const [shippingNumber, setShippingNumber] = useState(null)
    const [dialog, setDialog] = useState({
        loading: false,
        open: false,
        title: "",
        description: "",
        type: null
    })
    const columns = [
        {
            id: 'file',
            accessor: 'file',
            Header: "orders.showContainer.file",
        },
        {
            id: 'name',
            accessor: 'name',
            Header: "orders.showContainer.name",
        },
        {
            id: 'printing_type',
            Header: "orders.showContainer.printing_type",
            accessor: 'printing_type'
        },
        {
            id: 'link',
            accessor: 'link',
            Header: "orders.showContainer.actions",
        }]
    const {
        isLoading,
        isFetching,
        isError,
        error,
        data,
        refetch,
        isSuccess
    } = useQuery(['order', id], ()=>fetchOrder(id))

    const {
        isLoading: isLoadingStockSummary,
        isFetching: isFetchingStockSummary,
        isError: isErrorStockSummary,
        error: errorStockSummary,
        data: dataStockSummary,
        refetch: refetchStockSummary
    } = useQuery(['orderStockSummary', id], ()=>fetchOrder(id, true),{
        enabled: false
    })

    useEffect(() => {
        if(isSuccess){  
            refetchStockSummary()
        }
    }, [isSuccess])

    useEffect(() => {
        if(data?.shipping_documents && data?.shipping_documents.length > 0){  
            console.log("data?.shipping_documents", data?.shipping_documents)
            let shipping_documents = data?.shipping_documents.filter(doc => doc.printing_type != "identifier_label" )
            console.log("shipping_documents", shipping_documents)
            setShippingDocuments([...shipping_documents])
            console.log("shippingDocuments", shippingDocuments)

        }
    }, [data])

    const {
        isLoading: isLoadingOrderHistory,
        data: fetchedOrderHistory,
    } = useQuery(['orderHistory', id], () => fetchOrderHistory(id))

    const getErrorMessage = useCallback( errorObject => buildMessageForOrderError(errorObject, t), [t])
    const getErrorSolution = useCallback( errorObject => buildSolutionForOrderError(errorObject, t, storePath), [t])

    const shippingMethodErrors = useMemo(() => filterOrderErrorsByFields(data?.errors, ['shipping_method']), [data])
    const orderNumberErrors = useMemo(() => filterOrderErrorsByFields(data?.errors, ['order_number']), [data])
    const integrationShippingMethodErrors = useMemo(() => filterOrderErrorsByFields(data?.errors, ['integration_shipping_method']), [data])
    const shippingInfoErrors = useMemo(() => filterOrderErrorsByCodes(data?.errors, [ORDER_ERRORS.MISSING_SHIPPING_FIELD, ORDER_ERRORS.UNRECOGNIZED_COUNTRY, ORDER_ERRORS.INVALID_ZIPCODE, ORDER_ERRORS.UNRECOGNIZED_CITY_OR_STATE]), [data])
    const taxingInfoErrors = useMemo(() => filterOrderErrorsByCodes(data?.errors, [ORDER_ERRORS.MISSING_TAX_FIELD]), [data])
    const orderValueErrors = useMemo(() => filterOrderErrorsByFields(data?.errors, ['total_price']), [data])
    const orderLineErrors = useMemo(() => filterOrderErrorsByFields(data?.errors, ['order_lines']), [data])

    const [showPodDownloadNotification, setShowPodDownloadNotification] = useState(false)
    const [podDownloadNotifcationMessage, setPodDownloadNotifcationMessage] = useState(null)
    const [podDownloadNotifcationType, setPodDownloadNotifcationType] = useState(null)
    
    const skeletonNumber = <Skeleton.Button active={true} size="small" shape="square" width="1" block={true} />
    
    const orderLinesWithErrorObjects = useMemo(() => {
        if (!data?.order_lines) return []
        return data.order_lines.map ( orderLine => {
            let orderLineErrorObjects = orderLineErrors.filter(errorObject => errorObject.additional_info?.order_line_id === orderLine.id)
            orderLineErrorObjects = orderLineErrorObjects.map( errorObject => {
                if (errorObject.code === ORDER_ERRORS.UNIDENTIFIED_PRODUCT) {
                    return {...errorObject, callToAction: {link: storePath + ADD_PRODUCT, text: t("inventory.listProducts.create_product")}}
                }
                return {...errorObject }} )
            
            return {...orderLine, errors: orderLineErrorObjects}
        } )
    }, [orderLineErrors])

    const downloadPodAttachmentsZip = async (order_number, urls) => {
        const zip = new JSZip()

        try {
            await Promise.all(urls.map(async (url, index) => {
                const response = await fetch(`${process.env.REACT_APP_PLATFORM_URL}${url}`)
                const blob = await response.blob()
                const filename = `${index}_${url.split('/').pop().split('?')[0]}`
                zip.file(filename, blob)
            }))
            
            const content = await zip.generateAsync({ type: "blob" })
            saveAs(content, `${order_number}_pod_attachments.zip`)
            window.analytics.track('Orders - Downloads Proof of Delivery', {
                id: data.id
            })

            setPodDownloadNotifcationMessage(i18n.t("orders.pod.download_success"))
            setPodDownloadNotifcationType('success')
            setShowPodDownloadNotification(true)
        } catch (error) {
            setPodDownloadNotifcationMessage(i18n.t("orders.pod.download_error"))
            setPodDownloadNotifcationType('error')
            setShowPodDownloadNotification(true)
        }

    }
    
    const showShippingMethod = (data) => {
        return <>
            {data?.shipping_method && 
            <div>
                <span className="whitespace-nowrap capitalize">
                    { capitalize(data.shipping_method.carrier_name)} - {t(`shipping_methods.delivery_types.${data.shipping_method.delivery_type}`) }
                    { data.shipping_method.is_cod && ' - COD' }
                </span>
            </div>
            }
            { (shippingMethodErrors.length > 0 || integrationShippingMethodErrors.length > 0) &&
                <div>
                    { integrationShippingMethodErrors.map( errorObject => 
                        <div key={errorObject.code}>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                {getErrorMessage(errorObject, t)}
                            </span>
                        </div>
                    )}
                    { integrationShippingMethodErrors.length > 0 &&
                        <div className="mt-2 mb-2">
                            <button
                                type="button"
                                className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => { history.push(storePath + SHIPPING_METHODS.replace(":id", data?.channel_integration_id)) }}
                            >
                                {i18n.t("orders.showContainer.configure_shipping")}
                            </button>
                        </div>
                    }
                    { shippingMethodErrors.map( errorObject => 
                        <div key={errorObject.code}>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                {getErrorMessage(errorObject, t)}
                            </span>
                        </div>
                    )}
                    { shippingMethodErrors.length > 0 &&
                        <div className="mt-2">
                            <button
                                type="button"
                                className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => { history.push(storePath + EDIT_ORDER.replace(':id', data.id)) }}
                            >
                                {i18n.t("orders.showContainer.edit")}
                            </button>
                        </div>
                    }
                </div>
            }
        </>
    }

    const handleEditOrder = () => {
        history.push(storePath + EDIT_ORDER.replace(":id", data?.id))
    }

    const handleGenerateLabel = async () => {
        setLoadingLabel(true)
        onBusyChange(true)
        setLabelGenerationError(null)
        let firstName = data.shipping.first_name ? data.shipping.first_name : data.shipping.last_name
        let lastName = data.shipping.last_name ? data.shipping.last_name : data.shipping.last_name
        const shippingAddress = {
            firstName: firstName,
            lastName: lastName,
            phone: data.shipping.phone,
            email: data.shipping.email,
            address: data.shipping.address_1,
            address2: data.shipping.address_2,
            country: data.shipping.country,
            city: data.shipping.city,
            state: data.shipping.state,
            zipCode: data.shipping.zip_code,
        }
        const sentTrackingNumber = labelRequiresTrackingNumber ? trackingNumber : null
        try {
            await generateLabel(data.id, data.shipping_method.id, shippingAddress, sentTrackingNumber)
            await refetch()
            setPrintGeneratedLabel(true)
        } catch (error) {
            console.log("error",error)
            console.log("errorResponse",error.response)
            setLabelGenerationError(error.response?.data?.error)
        }
        onBusyChange(false)
        setLoadingLabel(false)
    }

    const labelRequiresTrackingNumber = useMemo(() => {
        if(data?.shipping_method?.carrier_name === "CORREIOS" ) return true
        return false
    }, [data])

    const disableLabelGeneration = useMemo(() => {
        if(labelRequiresTrackingNumber && !trackingNumber?.trim() ) return true
        return false
    }, [labelRequiresTrackingNumber, trackingNumber])

    const OrderStatusPill = ({orderData}) => {
        if (!orderData) return <></>
        let orderStatus = orderData.is_pick_and_ship && orderData.pick_n_ship_status && orderData.virtual_status === 'pending' ? orderData.pick_n_ship_status : orderData.virtual_status
        orderStatus = orderStatus === 'hold' ? 'holded' : orderStatus  // temporary fix: change hold to holded
        return <StatusPill status={orderStatus} statuses={ORDER_STATUSES}/>
    }

    const _isBrStore = user.current_store?.warehouses[0]?.country === "BR"

    const shippingLabels = useMemo(() => {
        if (!data || !data.invoices || !data.shipping_labels) return []
        let saleInvoiceLabel = data.invoices.find(invoice => invoice.status === 'APPROVED' && invoice.purpose === 'SALE_INVOICE')
        if (!saleInvoiceLabel && _isBrStore) return []
        let labels = []
        data.shipping_labels.forEach(shipping_label => {
            if (shipping_label.status != 'VALID' || !shipping_label.label_file) return
            let pdfUrlsForLabel = [process.env.REACT_APP_PLATFORM_URL + shipping_label.label_file.path]
            if (saleInvoiceLabel?.label_pdf_file?.path) {
                pdfUrlsForLabel.push(process.env.REACT_APP_PLATFORM_URL + saleInvoiceLabel.label_pdf_file.path)
            }
            let label = {
                id: shipping_label.id,
                pdfUrls: pdfUrlsForLabel,
                type: shipping_label.label_type,
                trackingNumber: shipping_label.shipping_number
            }
            labels.push(label)
        })
        // console.log("labels", labels)
        return labels
    }, [data])

    const generalOrderErrors = useMemo( () => {
        if (!data?.errors) return []
        // filter unique error codes only
        let uniqueErrors = uniqBy(data.errors, 'code')
        return uniqueErrors
    }, [data])

    const invoices = useMemo(() => {
        if (!data || !data.invoices) return []
        return data.invoices.filter( invoice => invoice.status === 'APPROVED')
    }, [data])

    useEffect(() => {
        if(data){
            const isBillingRequired = data?.shipping?.country?.toUpperCase() === 'BR' || data?.billing?.country?.toUpperCase() === 'BR'

            setShowBillingInfo(isBillingRequired)

            const newShippingNumber = getOrderShippingNumber(data?.shipping_labels)
            setShippingNumber(newShippingNumber)
        }
    },[data])

    useEffect(() => {
        window.analytics.page('Orders', 'Single', {
            id: id
        })
    }, [])

    const orderInfoColumns = [
        [i18n.t("orders.showContainer.order_number"), i18n.t("orders.showContainer.sales_channel_pieces")],
        [i18n.t("orders.showContainer.shipping_method"), i18n.t("orders.showContainer.tracking_number")],
        [i18n.t("orders.showContainer.status")],
        [i18n.t("orders.showContainer.shipment_status")]
    ]

    const productListColumns = [
        [],
        [i18n.t("orders.showContainer.requested_product_number")],
        [i18n.t("orders.showContainer.reserved_product_number")],
        [i18n.t("orders.showContainer.available_product_number_for_sale")]
    ]

    const pricesListColumns = [
        i18n.t("orders.pricesContainer.price_concept"),
        i18n.t("orders.pricesContainer.price_amount")
    ]
    
    const totalProducts = useMemo(() => !data ? 0 : data.order_lines.reduce((total, {quantity}) => total + quantity,0),[data]);
    

    const calculatePriceForKit = (kitChilds, kitQuantity) => {
        if(!kitChilds || !kitChilds?.length) {return 0}

        return parseFloat(kitChilds.reduce((total, {quantity, price_per_item, child_product}) =>  {
            if(child_product?.is_dropshipping) {return total}

            const price = price_per_item ?? child_product?.price

            return total + (price * (quantity * kitQuantity))
        },0).toFixed(2))
    }

    const totalPriceForItems = useMemo(() => {
        if(!data || !data?.order_lines?.length) {return 0}

        return parseFloat(data.order_lines.reduce((total, {generated, quantity, price_per_item, product}) =>  {
            if(generated || product?.is_dropshipping) {return total}

            let price

            if(product?.is_kit){
                price = calculatePriceForKit(product?.kit_childs, quantity)
            } else{
                price = !price_per_item && !product?.price ? 0 : price_per_item ? price_per_item : product?.price
            }

            return total + (price * quantity)
        },0).toFixed(2))
    },[data])

    const isThereAnImei = useMemo(() => {
        if(!data) {return false}

        const hasImei = data?.order_lines?.some(({product}) => {
            if(product?.has_imei) {return true}

            if(product?.kit_childs?.some(({child_product}) => child_product.has_imei)) {return true}

            return false
        })

        return hasImei
    },[data])

    const isThereAnyLot = useMemo(() => {
        if(!data || !data?.order_lines || !data?.order_lines?.length) {return false}

        const hasLot = data?.order_lines.some(({stock_units}) => (
            stock_units?.some(({lot}) => lot)
        ))

        return hasLot
    },[data])

    const joinWords = (...wordsToJoin) => {
        return wordsToJoin.join(" ").replace(/\s{2,}/g, " ")
    }

    const checkIfPresale = (productId, order_line_id) => {
        if(!dataStockSummary?.reserved_stock_per_order_line) {return false}
        if(!dataStockSummary?.reserved_stock_per_order_line[order_line_id]) {return false}
        return dataStockSummary.reserved_stock_per_order_line[order_line_id].some(({product_id, status}) => product_id === productId && (status === 'RESERVED-RECEPTION-INCOMING-SELL-AHEAD' || status === 'RESERVED-INCOMING-SELL-AHEAD'))
    }

    const checkIsOutOfStock = (orderLine, kitChild) => {
        let requiredNumber = 0
        let reservedNumber = 0

        if(kitChild){
            requiredNumber = getKitChildQuantity(kitChild?.quantity, orderLine?.quantity)
            reservedNumber = countReservedUnits(orderLine?.id, kitChild?.child_product?.id)
        } else if(orderLine?.product?.is_kit){
            requiredNumber = orderLine?.quantity
            reservedNumber = getCompletedKitsQuantity(orderLine?.id, orderLine?.product?.kit_childs, orderLine?.quantity)
        } else{
            requiredNumber = orderLine?.quantity
            reservedNumber = countReservedUnits(orderLine?.id, orderLine?.product?.id)
        }

        return reservedNumber < requiredNumber
    }

    const getOrderLineImeis = (orderLineId) => {
        if(!orderLineId) {return false}
        if(!dataStockSummary?.imeis_per_order_line) {return false}
        if(!dataStockSummary?.imeis_per_order_line[orderLineId]) {return false}
        return dataStockSummary.imeis_per_order_line[orderLineId].map((imei) => {
            return (
                <p className="m-0 text-base font-normal mt-2">
                    <span className="font-semibold">
                        {i18n.t("orders.showContainer.imei")}
                    </span>
                    {imei || ""}
                </p>
            )
        })
    }

     const getOrderLineLots = (orderLineId) => {
        if(!orderLineId) {return false}
        if(!dataStockSummary?.lots_per_order_line) {return false}
        if(!dataStockSummary?.lots_per_order_line[orderLineId]) {return false}
        return dataStockSummary.lots_per_order_line[orderLineId].map((lot, i) => {
            const lotUniqueCode = (
                <p className={`m-0 text-base font-normal ${i >= 1 ? "mt-4" : "mt-2"}`}>
                    <span className="font-semibold">
                        {i18n.t("orders.showContainer.lot")}
                    </span>
                    {lot?.unique_code || ""}
                </p>
            )

            let lotExpiration = <></>

            if(lot?.expiration_date){
                lotExpiration = (
                    <p className="m-0 text-base font-normal">
                        <span className="font-semibold">
                            {i18n.t("orders.showContainer.expiration")}
                        </span>
                        <FormattedDate date={lot?.expiration_date} onlyDate={true}/>
                    </p>
                )
            }

            return <>
                {lotUniqueCode}
                {lotExpiration}
            </>
        })
    }

    const generateProductsDetailElements = (order_line, kit_child, orderId) => {
        if(!order_line) {return <></>}

        let imeiOrLotElementsArray = []
        let skuElements = <></>
        let product
        let isFragile
        let is_dropshipping
        let isOutOfStock
        let isPresale
        let price
        let priceWithDiscount

        const stockUnits = order_line?.stock_units

        if(kit_child) {
            product = kit_child?.child_product
            isFragile = product?.is_fragile
            is_dropshipping = product?.is_dropshipping
            isOutOfStock = checkIsOutOfStock(order_line, kit_child)
            price = parseFloat(kit_child?.price_per_item ?? product?.price)
            priceWithDiscount = price

            if(product) {
                isPresale = checkIfPresale(product?.id, order_line?.id)
            } else {
                isPresale = false
            }
        } else {
            product = order_line?.product
            isFragile = (product?.is_fragile || product?.kit_childs?.some(child => child?.child_product?.is_fragile ?? false))
            is_dropshipping = product?.is_dropshipping
            isOutOfStock = checkIsOutOfStock(order_line)

            // if(product?.is_kit){
            //     price = calculatePriceForKit(product?.kit_childs, order_line?.quantity)
            // } else{
            price = parseFloat(order_line?.price_per_item ?? product?.price)
            // }

            priceWithDiscount = parseFloat((price - parseFloat(order_line?.discount_per_item)).toFixed(2))

            if(product && !product?.is_kit) {
                isPresale =  checkIfPresale(product?.id, order_line?.id)
            } else{
                isPresale = false
            }
        }

        //const imeis = stockUnits?.filter(({product_id, imei}) => product_id === product?.id && imei?.imei)?.map((({imei}) => imei.imei))

        /*const lots = stockUnits?.filter(({product_id, lot}, index, array) => {
            if(product_id !== product?.id || !lot){return false}

            return array.findIndex(({lot: lotSearch}) => lotSearch?.product_id === product?.id && lotSearch?.unique_code === array[index]?.lot?.unique_code) === index
        })?.map((({lot}) => ({unique_code: lot.unique_code, expiration_date: lot.expiration_date})))*/

        /*imeis?.forEach((imei, i) => {
            if(product?.has_imei){
                const imeiContent = (
                    <p className="m-0 text-base font-normal mt-2">
                        <span className="font-semibold">
                            {i18n.t("orders.showContainer.imei")}
                        </span>
                        {imei || ""}
                    </p>
                )

                imeiOrLotElementsArray.push(imeiContent)
            }
        })*/

        /*lots?.forEach((lot, i) => {
            const lotUniqueCode = (
                <p className={`m-0 text-base font-normal ${i >= 1 ? "mt-4" : "mt-2"}`}>
                    <span className="font-semibold">
                        {i18n.t("orders.showContainer.lot")}
                    </span>
                    {lot?.unique_code || ""}
                </p>
            )

            let lotExpiration = <></>

            if(product?.has_expiration && lot?.expiration_date){
                lotExpiration = (
                    <p className="m-0 text-base font-normal">
                        <span className="font-semibold">
                            {i18n.t("orders.showContainer.expiration")}
                        </span>
                        <FormattedDate date={lot?.expiration_date} onlyDate={true}/>
                    </p>
                )
            }

            const lotContent = (
                <>
                    {lotUniqueCode}
                    {lotExpiration}
                </>
            )
            imeiOrLotElementsArray.push(lotContent)
        })*/

        if(product?.product_codes){
            skuElements = getSkus(product?.product_codes)
        } else if (!kit_child && order_line?.sku) {
            skuElements = <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"}><i>{order_line?.sku}</i></span>
        }

        const productEditPath = product?.is_kit ? EDIT_KIT : EDIT_PRODUCT
        const productDoesNotExist = (!product && data?.errors && data?.errors?.length > 0)

        return (
            <>
                <Tooltip
                    placement="top"
                    overlay={<span>{i18n.t("inventory.listProducts.edit_product")}</span>}
                    arrowContent={<div></div>}
                    destroyTooltipOnHide
                >
                    <p className="text-blue-500 m-0 text-base font-normal cursor-pointer max-w-max" onClick={() => history.push(storePath + productEditPath.replace(':id', product?.id))}>{product?.name}</p>
                </Tooltip>

                {showBillingInfo && (

                    <p className="text-sm font-normal text-gray-700 m-0">
                        <span>{i18n.t("orders.showContainer.price")}</span>

                        {priceWithDiscount !== price ? (
                            <>
                                <span className="line-through mr-1 text-gray-400">&nbsp;{(data?.currency === "USD" ? "$" : "R$") + price}&nbsp;</span>
                                <span>{(data?.currency === "USD" ? "$" : "R$") + priceWithDiscount}</span>
                            </>
                        ) : (
                            <span>{(data?.currency === "USD" ? "$" : "R$") + priceWithDiscount}</span>
                        )}
                    </p>
                )}

                <div className="flex flex-col gap-1 mt-1">
                    <div className="">{skuElements}</div>

                    <div>
                        {productDoesNotExist && (
                            <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-200 text-red-800"}>
                                {i18n.t("orders.showContainer.product_dont_exist")}
                            </span>
                        )}
                    </div>
                </div>

                <div className="mt-1 flex gap-1 flex-wrap">
                    {(!kit_child && order_line?.generated) && (
                        <Tooltip
                            placement="bottom"
                            overlay={<span>{i18n.t("orders.lineItem.generated_info")}</span>}
                            arrowContent={<div></div>}
                            destroyTooltipOnHide
                        >
                            <span className="rounded-full bg-indigo-50 text-indigo-500 px-2.5 py-0.5 flex items-center">
                                <span className={"inline-flex items-center text-sm font-normal"}>
                                    {i18n.t("orders.lineItem.generated")}
                                </span>

                                <InformationCircleIcon className='ml-1.5 w-4 h-4 inline'/>
                            </span>
                        </Tooltip>
                    )}

                    {(!kit_child && product?.is_kit) && (
                        <span className={"m-0 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-50 text-green-500"}>{i18n.t("inventory.listProducts.kit")}</span>
                    )}
                    {!dataStockSummary && <Skeleton.Button active={true} size="small" shape="round" width="2" block={false} />}

                    {isPresale && (
                        <span className="m-o inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-600">
                        {i18n.t("orders.lineItem.presale")}
                        </span>
                    )}

                    {((isOutOfStock && !productDoesNotExist) || isPresale) && (
                        <Tooltip
                            placement="bottom"
                            overlay={<span>{i18n.t("statuses.tooltip.no_stock")}</span>}
                            arrowContent={<div></div>}
                            destroyTooltipOnHide
                        >
                            <span className="rounded-full bg-red-50 text-red-500 px-2.5 py-0.5 flex items-center">
                                <span className={"m-0 inline-flex items-center text-xs font-medium "}>
                                    {i18n.t("orders.lineItem.not_enough_stock")}
                                </span>

                                <InformationCircleIcon className='ml-1.5 w-4 h-4 inline'/>
                            </span>
                        </Tooltip>
                    )}

                    {(product?.is_scannable === false) && (
                        <span className={"m-0 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-50 text-pink-600"}>{i18n.t("inventory.listProducts.promoInsert")}</span>
                    )}

                    {isFragile && (
                        <span className={"m-0 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-50 text-yellow-700"}>{i18n.t("inventory.listProducts.fragile")}</span>
                    )}

                    {product?.is_packageless === true && (
                        <span className={"m-0 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-700"}>{i18n.t("inventory.listProducts.packageless")}</span>
                    )}

                    {product?.has_imei === true && (
                        <span className={"m-0 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-700"}>{i18n.t("inventory.listProducts.has_imei")}</span>
                    )}

                    {is_dropshipping && (
                        <Tooltip
                        placement="bottom"
                        overlay={<span>{i18n.t("orders.lineItem.dropshipping")}</span>}
                        arrowContent={<div></div>}
                        destroyTooltipOnHide
                        >
                            <span className="rounded-full bg-red-50 text-red-800 px-2.5 py-0.5 flex items-center">
                                <span className={"m-0 inline-flex items-center text-xs font-medium "}>
                                    {i18n.t("inventory.listProducts.dropshipping")}
                                </span>

                                <InformationCircleIcon className='ml-1.5 w-4 h-4 inline'/>
                            </span>
                        </Tooltip>
                    )}
                </div>

                {getOrderLineLots(order_line?.id)}
                {getOrderLineImeis(order_line?.id)}
            </>
        )
    }

    const countReservedUnits = (orderLineId, productId) => {
        if(!orderLineId && !productId) return skeletonNumber
        if(!dataStockSummary?.reserved_stock_per_order_line) return skeletonNumber
        if(!dataStockSummary?.reserved_stock_per_order_line[orderLineId]) return 0
        
        return dataStockSummary.reserved_stock_per_order_line[orderLineId].filter((reservedStock) => reservedStock.product_id === productId).reduce((acc, reservedStock) => acc + reservedStock.count, 0)
    }

    const getAvailableProductNumber = (order_line_id, kit_child_id = false) => {
        let productStock = null
        const product = dataStockSummary?.order_lines.find((orderLine) => orderLine.id === order_line_id)?.product
        if(dataStockSummary && !product) return 0
        if(!kit_child_id) {
            productStock = product?.stock
        }else 
            productStock = product?.kit_childs.find((kitChild) => kitChild.id === kit_child_id)?.child_product?.stock
        if(!productStock) {
            return <Skeleton.Button active={true} size="small" shape="square" width="1" block={true} />
        }
        
        const {AVAILABLE, OUT_OF_STOCK} = productStock

        const availableProductNum = AVAILABLE === 0 ? OUT_OF_STOCK : AVAILABLE

        return availableProductNum
    }

    const getAvailableProductNumberElement = (order_line_id, kit_child_id = null) => {
        const availableProductNum = getAvailableProductNumber(order_line_id, kit_child_id)

        return (
            <p className={`font-normal text-base m-0 ${availableProductNum <= 0 ? "text-red-400" : "text-gray-600"}`}>
                {availableProductNum}
            </p>
        )
    }

    const getOrderStatusElement = (order) => {
        if(!order) {return <></>}

        let orderStatus = order.is_pick_and_ship && order.pick_n_ship_status && order.virtual_status === 'pending' ? order?.pick_n_ship_status: order?.virtual_status

        let timeElement = <></>
        let returnCause

        orderStatus = orderStatus === 'hold' ? 'holded' : orderStatus  // temporary fix: change hold to holded


        if (order?.return?.return_type && ['returning', 'returned', 'reentered'].includes(orderStatus)) {
            returnCause = order?.return?.return_type === "RETURNED_BY_CARRIER" ? "returned_by_carrier" : "returned_by_client"
        }

        switch(orderStatus){
            case 'initial':
            case 'empty':
            case 'entirely_dropshipping':
            case 'cancelation_window':
            case 'backorder':
            case 'error':
                {
                    if(!order.order_date){break}

                    timeElement = (
                        <div className="text-gray-500 font-normal text-sm m-0 mt-2 ml-0.5">
                            <div className="inline mr-1">{i18n.t('orders.list.since')}</div>
                            <FormattedDate date={order.order_date} shortDate={true}/>
                        </div>
                    )
                    break
                }
            case 'pending':
                {
                    if(!order.pending_at) {break}

                    let days = 0
                    let minutes = diff_minutes(new Date(), new Date(order.pending_at))
                    let hours = Math.trunc(minutes/60)

                    if (hours > 24) {
                        days = Math.trunc(hours/24)
                        minutes = 0
                        hours = 0
                    }

                    const isRed  = ((days >= 1 && order.quantity_of_items <= 100)
                        || (days >= 3 && order.quantity_of_items <= 500)
                        || (days >= 4 && order.quantity_of_items <= 2000))


                    timeElement = (
                        <div className={`font-normal text-sm m-0 mt-2 ${isRed ? 'text-red-400' : 'text-gray-500 '}`}>
                            <FormattedRelativeTime date={order.pending_at}/>
                        </div>
                    )
                    break
                }
            case 'hold':
            case 'holded':
                {
                    timeElement = (
                        <div className='mt-1'>
                            <div className='py-1'>
                                <button className="border border-gray-300 shadow-sm rounded-md p-1.5 text-gray-700 
                                    font-normal hover:bg-gray-100 hover:text-gray-700 hover:border-blue-400"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setDialog({...dialog,
                                            open: true,
                                            title: i18n.t("orders.list.reset_order"),
                                            description: i18n.t("orders.list.sure_to_reboot"),
                                            type: "unhold",
                                            order_id: order.id
                                        })}
                                    }>
                                    {i18n.t("orders.list.reset_order")}
                                </button>
                            </div>
                        </div>
                    )
                    break
                }
            case 'unpaid':
                {
                    timeElement = (
                        <div className='mt-1'>
                            <div className='py-1'>
                                <button className="block border border-gray-300 shadow-sm rounded-md p-1.5 text-gray-700 
                                    font-normal hover:bg-gray-100 hover:text-gray-700 hover:border-blue-400"
                                    onClick={(e) =>  {
                                        e.stopPropagation()
                                        setDialog({...dialog,
                                            open: true,
                                            title: i18n.t("orders.list.pay_order"),
                                            description: i18n.t("orders.list.sure_to_pay"),
                                            type: "paid",
                                            order_id: order.id
                                        })}
                                    }>
                                    {i18n.t("orders.list.pay_order")}
                                </button>
                            </div>
                        </div>
                    )
                    break
                }
            case 'waiting_pick_up':
                {
                    if(!order.shipping_date){break}

                    timeElement = (
                        <div className="text-gray-500 font-normal text-sm mt-2 ml-0.5">
                            <div className="inline mr-1">{i18n.t('orders.list.since')}</div>
                            <FormattedDate date={order.shipping_date} shortDate={true}/>
                        </div>
                    )
                    break
                }
            case 'returning':
            case 'returned':
                {
                    timeElement = (
                        <div className='mt-1'>
                            <div className='py-1'>
                                <button className="border border-gray-300 shadow-sm
                                    rounded-md p-2 text-gray-700 font-normal hover:bg-gray-50 hover:text-gray-700"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        history.push(storePath + SHOW_RETURN_ORDERS.replace(":id", order.return.id))
                                    }}>
                                    {i18n.t("orders.list.view_return")}
                                </button>
                            </div>
                        </div>
                    )
                    break
                }
            case 'canceled':
                {
                    if(!order.updated_at){break}

                    timeElement = (
                        <div className="text-gray-500 font-normal text-sm m-0 mt-2 ml-0.5">
                            <div className="inline mr-1">{i18n.t('orders.list.since')}</div>
                            <FormattedDate date={order.updated_at} shortDate={true}/>
                        </div>
                    )
                    break
                }
            case 'shipped':
                {
                    const shipped_date = order?.shipping_method?.shipping_type === "pickup" ? order?.shipping_date : order?.shipped_shipment_date ? order?.shipped_shipment_date : order?.shipping_date

                    if(!shipped_date){break}

                    timeElement = (
                        <div className="text-gray-500 font-normal text-sm m-0 mt-2 ml-0.5">
                            <div className="inline mr-1">{i18n.t('orders.list.since')}</div>
                            <FormattedDate date={shipped_date} shortDate={true}/>
                        </div>
                    )
                    break
                }
            default:
                break
        }

        return (
            <div className="pt-1">
                <StatusHighlighted
                    className=""
                    status={orderStatus}
                    statuses={ORDER_STATUSES_STATUS_HIGHLIGHTED}
                    description={returnCause}
                />
                {timeElement}
            </div>
        )
    }

    const getTrackingStatusElement = (order) => {
        if(!order) {return <></>}

        const noTrackingStatusHighlighted = <>
            {order.requires_external_label ?
                <div className="whitespace-normal pr-2">
                    {i18n.t('orders.list.external_label_desc')}
                </div>
            : ["canceled", "empty", "interrupted", "entirely_dropshipping"].includes(order.status) ? 
                <div className="font-normal text-base text-gray-500"> {i18n.t("orders.list.no_pieces")} </div>
              : <div className="font-normal text-base text-gray-500"> {i18n.t("orders.list.no_information")} </div>
            }
            </>

        if (!order.shipping_status || Object.values(STILL_NOT_SHIPPED_SHIPMENT_STATUSES).includes(order.shipping_status) ) return noTrackingStatusHighlighted

        const description = order.last_tracking_event?.metadata?.description
        const tracking_description = order.shipping_status == "shipment_returned" ? 'in_return' : TRACKING_DESCRIPTIONS.has(description) ? description : null

        if (order.status === "shipped" && (TRACKING_STATUSES.has(order.shipping_status) || order.is_delayed)) {
            return (
            <div className="">
                <StatusHighlighted
                    className=""
                    status={order.shipping_status}
                    statuses={TRACKING_STATUSES_DEFINITIONS_HIGHLIGHTED}
                    description={tracking_description}
                />
                
                {/* {order.estimated_time_arrival && order.shipping_status != 'shipment_delivered' && <div className={`mt-2 text-sm ${order.is_delayed ? 'text-red-400' : ''}`}>
                    {i18n.t("orders.list.eta")}: <FormattedRelativeTime forceDays={true} date={order.estimated_time_arrival}/>
                </div>} */}


                { (order.last_tracking_event?.reference_timestamp && (['shipment_delivered','shipment_with_incident','shipment_canceled']).includes(order.shipping_status)) &&
                <div className="mt-2 ml-0.5 text-gray-500 font-normal text-sm">
                    <div className='text-gray-500 text-sm'>
                        <div className="inline mr-1">{i18n.t('orders.list.at')}</div>
                        <FormattedDate date={order.last_tracking_event.reference_timestamp} shortDate={true}/>
                    </div>
                </div>
                }
                
{/*                 {order.shipping_incidents && order.shipping_incidents.length > 0 && 
                <div className="mt-3 relative max-w-14 whitespace-normal italic">
                    <div className='shadow-sm rounded-md py-1.5 px-2 bg-gray-50
                        font-normal text-xs w-max'
                            onClick={(e)=> e.stopPropagation()} >
                        <span className='font-semibold'>{i18n.t(`orders.shipping_incident.category.${order.shipping_incidents[0].category}`)}</span>
                        <br></br>
                        <span>{i18n.t("orders.shipping_incident.reported_to_carrier")}</span>
                        
                        <div> 

                            {order.shipping_incidents[0].status === "PENDING" &&
                                <span className="">
                                    {i18n.t(`orders.shipping_incident.status.${order.shipping_incidents[0].status}`)}
                                </span>
                            }
                            {order.shipping_incidents[0].status === "IN_REVIEW" &&
                                <span className="text-pink-600">
                                    {i18n.t(`orders.shipping_incident.status.${order.shipping_incidents[0].status}`)}
                                </span>
                            }
                            
                            {order.shipping_incidents[0].status === "RESOLVED" ?
                            <div className="inline mr-0.5 text-green-600">
                                {i18n.t(`orders.shipping_incident.resolution.${order.shipping_incidents[0].resolution}`)}
                            </div>
                            : <>
                                </>
                            }
                        </div>
                        
                    </div>
                </div>
                } */}
            </div>
            )
        }

        return noTrackingStatusHighlighted
    }

    const getActionButton = (actionType, {orderId, channelId}) => {
        let actionFn
        let buttonText
        let CustomIcon

        switch(actionType?.toLowerCase()){
            case ORDER_ERROR_ACTION_LIST.DUPLICATE_ORDER:
                {
                    actionFn = () => {
                        history.push(storePath + DUPLICATE_ORDER.replace(':id', orderId))
                    }
                    buttonText = i18n.t("orders.list.duplicate_order_action")
                    CustomIcon = <DocumentDuplicateIcon className='w-5 h-5 inline'/>
                    break
                }
            case ORDER_ERROR_ACTION_LIST.CANCEL_ORDER:
                {
                    actionFn = () => {
                        setDialog({...dialog,
                            open: true,
                            title: i18n.t("orders.list.cancel_order"),
                            description: <>
                                            {i18n.t("orders.list.sure_to_cancel")}
                                            {data?.is_difal_paid &&
                                                <div className='mt-2 text-yellow-600'>
                                                    {i18n.t("orders.list.sure_to_cancel_with_difal")}
                                                </div>
                                            }
                                        </>,
                            type: "cancel",
                            order_id: orderId
                        })
                    }
                    buttonText = i18n.t("orders.list.cancel_order_action")
                    CustomIcon = <XCircleIcon className='w-5 h-5 inline'/>
                    break
                }

            case ORDER_ERROR_ACTION_LIST.EDIT_ORDER:
                {
                    actionFn = () => history.push(storePath + EDIT_ORDER.replace(':id', orderId))
                    buttonText = i18n.t("orders.list.edit_order_action")
                    CustomIcon = <PencilIcon className='w-5 h-5 inline'/>
                    break
                }

            case ORDER_ERROR_ACTION_LIST.PAUSE_ORDER:
                {
                    actionFn = () => {
                        setDialog({...dialog,
                            open: true,
                            title: i18n.t("orders.list.pause_order"),
                            description: i18n.t("orders.list.sure_to_pause"),
                            type: "hold",
                            order_id: orderId
                        })
                    }
                    buttonText = i18n.t("orders.list.pause_order_action")
                    CustomIcon = <PauseIcon className='w-5 h-5 inline'/>
                    break
                }

            case ORDER_ERROR_ACTION_LIST.EDIT_ORDER_REMOVE_STOCK:
                {
                    actionFn = () => history.push(storePath + EDIT_ORDER_REMOVE_STOCK.replace(':id', orderId))
                    buttonText = i18n.t("orders.list.edit_order_action")
                    CustomIcon = <PencilIcon className='w-5 h-5 inline'/>
                    break
                }

            case ORDER_ERROR_ACTION_LIST.CREATE_PRODUCT:
                {
                    actionFn = () => history.push(storePath + ADD_PRODUCT)
                    buttonText = i18n.t("inventory.listProducts.create_product")
                    break
                }

            case ORDER_ERROR_ACTION_LIST.CONFIG_SHIPPING_METHOD:
                {
                    actionFn = () => history.push(storePath + SHIPPING_METHODS.replace(":id", channelId))
                    buttonText = i18n.t("orders.list.config_shipping_method")
                    break
                }

            default:
                {
                    actionFn = () => {}
                    buttonText = ""
                    break
                }
        }

        return (
            <button
                className="mt-3 inline-flex justify-center rounded-md border border-gray-300 px-2 py-2 bg-white hover:bg-gray-50 focus:outline-no ne focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:col-start-1 sm:text-sm shadow-sm"

                onClick={actionFn}
            >
                <span className="flex items-center gap-1.5 text-base font-medium text-gray-800">
                    {!!CustomIcon && (CustomIcon)}
                    {buttonText}
                </span>
            </button>
        )
    }

    const getMainActionButtons = (order) => {
        if(!order){return <></>}

        const isDuplicateEnabled = order.order_type !== "REMOVE_STOCK"

        const isCancelEnabled = order.status!=="shipped" && order.status!=="canceled" && order.status!=="interrupted" && order.status!=="returning" && order.status!=="returned" && order.status!=="return_arrived" && order.status!=="reentered" && !order.pick_n_ship_status

        const isEditEnabled = isCancelEnabled

        const isPauseEnabled = (order.status === "pending" || order.status === "backorder" || order.status === "error" ) && !order.pick_n_ship_status

        const editType = order.order_type === "REMOVE_STOCK" ? "edit_order_remove_stock" : "edit_order"

        return (
            <div className="flex absolute w-full justify-end -top-20 right-14 gap-3 pt-2">
                { isEditEnabled && getActionButton(editType, {orderId: order.id})}
                { isDuplicateEnabled && getActionButton("duplicate_order", {orderId: order.id})}
                { isPauseEnabled && getActionButton("pause_order", {orderId: order.id})}
                { isCancelEnabled && getActionButton("cancel_order", {orderId: order.id})}
            </div>
        )
    }

    const getErrorsElements = (order, ...errors) => {
        //if(!errors || !errors.length || !orderId) {return <></>}

        /* console.log("")
        console.log("Error List", errorList)
        console.log("Order ID", orderId)
        console.log("") */

        // TODO: remove this errorList array and use the parameter
        /*const errorList = [
            {
              "field": "order_lines",
              "code": "UNIDENTIFIED_PRODUCT",
              "additional_info": { "order_line_id": 1041891, "sku": "1246" }
            },
            {
              "field": "shipping_method",
              "code": "MISSING_SHIPPING_METHOD",
              "additional_info": {}
            }
        ]*/

        const allowDuplicateErrors = [
            "INVALID_PRODUCT_NCM",
        ]

        if(!order) {return <></>}

        const flatErrorElements = [].concat(...errors)

        //const uniqueErrors = uniqBy(flatErrorElements, 'code')
        const uniqueErrors = flatErrorElements.filter(({code}, index, array) => allowDuplicateErrors.includes(code) || index === array.findIndex(({code: storedCode}) => storedCode === code))

        const actions = []
        const {id: orderId, channel_integration_id: channelId} = order

        const errorElements = uniqueErrors.map((error, index) => {

            const actionsToInsert = getErrorActions(error)

            if(actionsToInsert?.length){
                actionsToInsert?.forEach((action) => {
                    if(actions?.some((existingAction) => existingAction === action)){ return }

                    actions.push(action)
                })
            }

            const solution = getErrorSolution(error, t)

            return (
                <div className={`flex flex-col gap-0 ${index >= 1 ? "mt-3" : ""}`}>
                    <p className="text-base font-medium text-red-500 m-0">
                        {getErrorMessage(error, t)}
                    </p>
                    <p className="text-base font-normal text-gray-700 m-0">
                        <span className="text-base font-medium text-gray-800">
                            {i18n.t("orders.missingDataContainer.solution")}</span> {solution}
                    </p>
                </div>
            )
        })

        const areActionsAvailable = actions.length > 0

        return (
            <>
                <div className={`flex flex-col gap-5 ${areActionsAvailable ? "w-4/5" : ""}`}>{errorElements}</div>

                {areActionsAvailable &&
                    <div className="flex flex-col items-end gap-2">{actions.map(action => getActionButton(action, {orderId: orderId, channelId: channelId}))}</div>
                }
            </>
        )
    }

    const getProductInfoHeader = (headerIndex, headers) => {
        let headerComponent

        if(headerIndex === 0) {
            let newHeader = i18n.t("orders.showContainer.product_title")
            let subHeader = ""

            subHeader = showBillingInfo ? `${i18n.t("orders.showContainer.price_title")} | ` : ""
            subHeader = subHeader + `${i18n.t("orders.showContainer.sku_title")}`
            subHeader = subHeader + ` | ${i18n.t("orders.showContainer.labels_title")}`

            if(isThereAnImei && isThereAnyLot) {
                subHeader = subHeader + ` | ${i18n.t("orders.showContainer.imei_title")}/${i18n.t("orders.showContainer.lot_title")}`
            } else if(isThereAnImei) {
                subHeader = subHeader + ` | ${i18n.t("orders.showContainer.imei_title")}`
            } else if(isThereAnyLot) {
                subHeader = subHeader + ` | ${i18n.t("orders.showContainer.lot_title")}`
            }

            headerComponent = (
                <p className="text-gray-800 font-medium text-base m-0">
                    {newHeader}
                    <span className="text-gray-600 font-normal text-sm m-0 block">
                        {subHeader}
                    </span>
                </p>
            )
        } else if(headerIndex === 3) {
            headerComponent = (
                <Tooltip
                    placement="bottom"
                    overlay={<span>{i18n.t("orders.showContainer.available_product_number_for_sale_tooltip")}</span>}
                    arrowContent={<div></div>}
                    destroyTooltipOnHide
                    >
                        <span className="flex items-end text-left">
                            <span className="text-gray-800 font-medium text-base m-0 leading-4">
                                {headers[0]}
                            </span>

                            <span className="pl-1.5 w-8 h-full">
                                <InformationCircleIcon className='w-full h-full'/>
                            </span>
                        </span>
                </Tooltip>
            )
        } else {
            headerComponent = (
                <p className="text-gray-800 font-medium text-base m-0 leading-4">
                    {headers[0]}
                </p>)
        }

        return (headerComponent)
    }

    const getShippingName = (shippingMethod) => {
        if(!shippingMethod) {return ""}

        const {carrier_name, delivery_type} = shippingMethod
        let deliveryTypeToDisplay

        deliveryTypeToDisplay =` - ${i18n.t(`shipping_methods.delivery_types.${delivery_type}`)}`

        return carrier_name + deliveryTypeToDisplay
    }

    const onConfirmDialogHandler = async () => {
        setDialog({...dialog, loading: true})
        switch (dialog.type){
            case "hold":
                await holdOrder(dialog.order_id)
                break
            case "unhold":
                await unholdOrder(dialog.order_id)
                break
            case "cancel":
                await cancelOrder(dialog.order_id)
                break
            case "paid":
                await payOrder(dialog.order_id)
                break
            default:
                break
        }
        setDialog({...dialog, loading: false, open: false})
        refetchAll()
    }

    const refetchAll = () => {
        refetch()
        //refetchSummary()
    }

    const getIncidentsElements = (shippingIncidents) => {
        if(!shippingIncidents || !shippingIncidents.length) return <></>
        return <ShippingIncidents shippingIncidents={shippingIncidents} t={t}/>
    }

    const getEstimatedDeliveryElement = (order) => {
        if (!order){
            return(
                <div className="text-base font-normal text-gray-700">
                    {i18n.t("orders.orderMovementContainer.estimatedDeliveryNotAvailable")}
                </div>
            )
        }
        let estimated_time_arrival = new Date(order.estimated_time_arrival)
        let validate_estimated_time_arrival =  estimated_time_arrival > new Date(2020, 1, 1)
        const wh_time_zone = user.current_store?.warehouses[0]?.time_zone
        const userInSameTimezone = wh_time_zone == Intl.DateTimeFormat().resolvedOptions().timeZone 
        
        if (!(order.status === "shipped" && (TRACKING_STATUSES.has(order.shipping_status) || order.is_delayed))) {
            return(
                <div>
                    {validate_estimated_time_arrival ? (
                        <div className={`flex items-center space-x-1 text-base font-normal ${order.is_delayed ? 'text-red-400' : 'text-gray-700'}`}>
                        <FormattedRelativeTime forceDays={true} date={order.estimated_time_arrival} time_zone={user.current_store?.warehouses[0]?.time_zone}/>
                        {!userInSameTimezone && <>
                            <Tooltip placement="bottom" title={t("orders.list.time_zone_info",
                                {time_zone: user.current_store?.warehouses[0]?.country == "MX" ? "CDMX"
                                : user.current_store?.warehouses[0]?.country == "BR" ? "SP" : wh_time_zone})}>
                                <InformationCircleIcon className="inline w-4 h-4 ml-1" />
                            </Tooltip>
                        </>}
                    </div>
                    ):(
                    <div className="text-base font-normal text-gray-700">
                        {i18n.t("orders.orderMovementContainer.estimatedDeliveryNotAvailable")}
                    </div>
                    )}
                    </div>
            )
        }

        let etaElement
        let trackingElement

        
        if(validate_estimated_time_arrival && order.shipping_status !== 'shipment_delivered') {
            etaElement = (
                <div className="flex flex-col">
                    <div className={`flex items-center space-x-1 text-base font-normal ${order.is_delayed ? 'text-red-400' : 'text-gray-700'}`}>
                        <FormattedRelativeTime forceDays={true} date={order.estimated_time_arrival} time_zone={user.current_store?.warehouses[0]?.time_zone}/>
                        {!userInSameTimezone && <>
                            <Tooltip placement="bottom" title={t("orders.list.time_zone_info",
                                {time_zone: user.current_store?.warehouses[0]?.country == "MX" ? "CDMX"
                                : user.current_store?.warehouses[0]?.country == "BR" ? "SP" : wh_time_zone})}>
                                <InformationCircleIcon className="inline w-4 h-4 ml-1" />
                            </Tooltip>
                        </>}
                    </div>
                    <p className="m-0 text-base font-normal text-gray-500">{i18n.t("orders.orderMovementContainer.estimatedDeliveryAccuracy")}</p>
                </div>
            )
        }

    /*  if(order.last_tracking_event?.created_at) {
            if((['shipment_delivered','shipment_with_incident','shipment_canceled']).includes(order.shipping_status)) {
                trackingElement = (
                    <div className='text-base font-normal text-gray-700'>
                        <div className="inline mr-1">{i18n.t('orders.list.at')}</div>
                        <FormattedDate date={order.last_tracking_event.created_at} shortDate={true}/>
                    </div>
                )
            } else {
                trackingElement = (
                    <div className="text-base font-normal text-gray-700">
                        {i18n.t("orders.orderMovementContainer.estimatedDeliveryNotAvailable")}
                    </div>
                )
            }
        } */

        return (
            <div>
                {!!etaElement && etaElement}

                {!!trackingElement && (
                    <div>
                        {trackingElement}
                    </div>
                )}

                {(etaElement === undefined && trackingElement === undefined) && (
                    <div className="text-base font-normal text-gray-700">
                        {i18n.t("orders.orderMovementContainer.estimatedDeliveryNotAvailable")}
                    </div>
                )}
            </div>
        )

    }

    const getKitChildQuantity = (kitQuantity, orderQuantity) => {
        if(kitQuantity === null || kitQuantity === undefined) {return 0}
        if(orderQuantity === null || orderQuantity === undefined) {return 0}

        return parseInt(kitQuantity) * parseInt(orderQuantity)
    }

    const getCompletedKitsQuantity = (orderLineId, kitChilds, orderLineQuantity) => {
        if(!orderLineId || !kitChilds) {return 0}
        if(!kitChilds?.length) {return 0}
        
        const quantities = kitChilds.map(({child_product, quantity}) => ({
            quantityForOneCompleted: quantity,
            requiredQuantity: getKitChildQuantity(quantity, orderLineQuantity),
            reservedQuantity: countReservedUnits(orderLineId, child_product?.id),
            isDropshipping: child_product?.is_dropshipping
        }))

        if(quantities.some(({reservedQuantity}) => isNaN(reservedQuantity))) return <Skeleton.Button active={true} size="small" shape="square" width="1" block={true} />

        /*
            const quantities = [
            {
                quantityForOneCompleted: 2,
                reservedQuantity: 6
            },
            {
                quantityForOneCompleted: 4,
                reservedQuantity: 12
            },
            {
                quantityForOneCompleted: 4,
                reservedQuantity: 12
            }
        ] */

        const completedKitsQuantities = quantities.map(({quantityForOneCompleted, requiredQuantity, reservedQuantity, isDropshipping}) => {
            if(isDropshipping) {
                return requiredQuantity
            } else {
                return Math.floor(reservedQuantity / quantityForOneCompleted)
            }
        })

        return Math.min(...completedKitsQuantities)
    }

    return (
        <>
            {isError ? (
                <>Error: {error.message}</>
            ) : (
                <div className="relative">
                        <Loader show={isLoading || loadingLabel} />
                        {getMainActionButtons(data)}

                        <ConfirmDialog
                            open={dialog.open}
                            setOpen={(value) => setDialog({...dialog, open: value}) }
                            title={dialog.title}
                            description={dialog.description}
                            confirmLabel="Si"
                            onConfirm={onConfirmDialogHandler}
                            loading={dialog.loading}
                        />

                        {/* Previous content */}
                        {/*
                        <div>
                            { generalOrderErrors.map( error => <OrderErrorBanner key={error.code} errorObject={error} /> ) }
                        </div> */
                        }
                        <Notification title={i18n.t("orders.pod.title")} description={podDownloadNotifcationMessage} show={showPodDownloadNotification} setShow={setShowPodDownloadNotification} type={podDownloadNotifcationType}/>
                        <div className="flex flex-col gap-8 pt-0">
                            <section id="section-general-info">
                                <h2 className="text-gray-800 font-semibold text-lg m-0 mb-3">{i18n.t("orders.showContainer.shipping_order_information")}</h2>

                                <div className="border rounded-lg border-gray-200">
                                    <table className="relative w-full table-fixed">
                                        <thead>
                                            <tr >{orderInfoColumns.map( (header, index) => (
                                                <th>
                                                    <div className="p-3">
                                                        <p className="text-gray-800 font-medium text-base m-0 text-left">{header[0]}</p>

                                                        {(index === 0 || index === 1) && (
                                                            <p className="text-gray-600 font-normal text-xs m-0 text-left">{header[1]}</p>
                                                        )}
                                                    </div>
                                                </th>
                                            ))}</tr>
                                        </thead>

                                        <tbody><tr>
                                            <td className="p-0 text-left border-t border-r border-gray-200">
                                                <div className="flex justify-center flex-col p-3">
                                                    <p className="text-gray-700 font-normal text-base m-0">
                                                        {"#" + data?.order_number}
                                                    </p>
                                                    <p className="text-gray-500 font-normal text-sm m-0">
                                                    {`${data?.channel_name} | ${totalProducts} ${totalProducts === 1 ? i18n.t("orders.list.item") : i18n.t("orders.list.items")}`}</p>
                                                    <p >{['REMOVE_STOCK', 'REMOVE_DAMAGED_STOCK', 'REMOVE_EXPIRED_STOCK'].includes(data?.order_type) &&
                                                        <div className="whitespace-normal font-normal text-sm bg-blue-50 rounded-md p-1 inline-block mt-1.5">{i18n.t(`orders.showContainer.${data.order_type.toLowerCase()}_type`)}</div>
                                                    }</p>
                                                </div>
                                            </td>

                                            <td className="p-0 border-t border-r border-gray-200">
                                                <div className="flex justify-center flex-col text-left p-3">
                                                    {(!!data?.shipping_method?.shipping_name) && (
                                                        <p className="text-gray-700 font-normal text-base m-0">
                                                            {getShippingName(data?.shipping_method)}
                                                            <p className="font-semibold m-0 mb-1">
                                                                {data?.shipping_method.is_cod && 'Cash on Delivery'}
                                                            </p>
                                                        </p>
                                                    )}

                                                    <a
                                                        className="font-normal text-sm m-0 hover:underline"
                                                        href={data?.cubbo_tracking_url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {shippingNumber ? shippingNumber : i18n.t("orders.tracking_link_without_label")}
                                                    </a>

                                                    {data?.is_click_and_collect && (
                                                        <span className="whitespace-normal font-normal text-sm bg-blue-50 rounded-md p-1 inline-block mt-1.5 w-max">{i18n.t("orders.showContainer.is_click_and_collect")}</span>
                                                    )}
                                                </div>
                                            </td>

                                            <td className="p-0 border-t border-r border-gray-200">
                                                <div className="flex justify-center flex-col text-left p-3">
                                                    {getOrderStatusElement(data)}
                                                </div>
                                            </td>

                                            <td className="p-0 border-t border-gray-200">
                                                <div className="flex justify-center flex-col text-left p-3">
                                                    {getTrackingStatusElement(data)}
                                                </div>
                                            </td>
                                            </tr></tbody>
                                    </table>
                                </div>

                                {/* Previous content */}
                                {/*
                                { (orderNumberErrors.length > 0) &&
                                    <div className="mt-2">
                                        { orderNumberErrors.map( errorObject => <OrderFieldErrorBanner errorObject={errorObject}/> )}
                                        <div className="mt-2">
                                            <button
                                                type="button"
                                                className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => { history.push(storePath + EDIT_ORDER.replace(':id', data.id)) }}
                                                >
                                                {i18n.t("orders.showContainer.edit")}
                                            </button>
                                        </div>
                                    </div>
                                }
                                */}

                                <table className="relative w-full min-w-full border rounded-t-lg border-gray-200 border-separate mt-4">
                                    <tbody>
                                        <tr className="flex">
                                            <td className={`p-3 ${showBillingInfo ? "w-1/2" : "w-2/5"}`}>
                                                <div className="h-full">

                                                    {data?.shipping_method?.shipping_type === "pickup" ?
                                                        <p className="text-gray-800 font-medium text-base m-0">{i18n.t("orders.showContainer.picking_order_information")}</p> 
                                                    : data?.is_click_and_collect ?
                                                        <p className="text-gray-800 font-medium text-base m-0 align-top">
                                                            {i18n.t("orders.showContainer.click_and_collect_data")}
                                                        </p>
                                                    :
                                                        <p className="text-gray-800 font-medium text-base m-0 align-top">
                                                        {showBillingInfo ? i18n.t("orders.showContainer.customer_address") : i18n.t("orders.showContainer.picking_order_information")}
                                                        </p>
                                                    }

                                                    { showBillingInfo &&
                                                        <div className="flex flex-col pt-3">
                                                            <p className="text-gray-500 font-normal text-base m-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {data?.shipping_method?.shipping_type === "pickup" ? i18n.t("orders.showContainer.name") : i18n.t("orders.showContainer.sendTo")}
                                                                </span>
                                                                {joinWords(data?.shipping.first_name, data?.shipping.last_name)}
    
                                                            </p>
                                                            <p className="text-gray-500 font-normal text-base m-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("orders.showContainer.phone")}</span>{(data?.shipping?.phone || "")}</p>
                                                            <p className="text-gray-500 font-normal text-base m-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("orders.showContainer.email")}</span>{(data?.shipping?.email || "")}</p>
                                                            <p className="text-gray-500 font-normal text-base m-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("orders.showContainer.address")}</span>
                                                                    {joinWords(data?.shipping?.address_1, data?.shipping?.address_2)}</p>
                                                            <p className="text-gray-500 font-normal text-base m-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("orders.showContainer.state")}</span>{(data?.shipping?.state || "")}</p>
                                                            <p className="text-gray-500 font-normal text-base m-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("orders.showContainer.city")}</span>{(data?.shipping?.city || "")}</p>
                                                            <p className="text-gray-500 font-normal text-base m-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("orders.showContainer.zip_code")}</span>{(data?.shipping?.zip_code || "")}</p>

                                                            {data?.shipping?.country?.toUpperCase() === "BR" &&
                                                                <>
                                                                    <p className="text-gray-500 font-normal text-base m-0">
                                                                        <span className="text-gray-800 font-normal text-base">
                                                                            {i18n.t("orders.showContainer.number")}</span>{(data?.shipping?.number ?? "")}</p>
                                                                    <p className="text-gray-500 font-normal text-base m-0">
                                                                        <span className="text-gray-800 font-normal text-base">
                                                                            {i18n.t("orders.showContainer.neighborhood")}</span>{(data?.shipping?.neighborhood || "")}</p>
                                                                </>
                                                            }
                                                        </div>
                                                    }

                                                    { data?.is_click_and_collect &&
                                                        <div className="flex flex-col pt-3">
                                                            <p className="text-gray-500 font-normal text-base m-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("orders.showContainer.click_and_collect_location_name")}
                                                                </span> 
                                                                {data?.click_and_collect_metadata?.description}
                                                            </p>
                                                            <p className="text-gray-500 font-normal text-base m-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                {i18n.t("orders.showContainer.address")}
                                                                </span> 
                                                                {data?.click_and_collect_metadata?.address}
                                                            </p>
                                                            
                                                        </div>
                                                    }

                                                </div>
                                            </td>

                                            <td className={`p-3 flex ${showBillingInfo ? "w-1/2" : "w-3/5"}`}>
                                                <div>
                                                    { showBillingInfo ?
                                                        <div>
                                                            <p className="text-gray-800 font-medium text-base m-0 pb-3">
                                                                {i18n.t("orders.showContainer.billing_info")}
                                                            </p>

                                                            <div className = "flex flex-col">
                                                                <p className="text-gray-500 font-normal text-base m-0">
                                                                    <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("settings.tax_fields.tax_regime")}</span>
                                                                    { data?.billing?.tax_regime === "LEGAL" ? i18n.t("settings.tax_fields.tax_regime_legal") : i18n.t("settings.tax_fields.tax_regime_physical")}
                                                                </p>
                                                                <p className="text-gray-500 font-normal text-base m-0">
                                                                    <span className="text-gray-800 font-normal text-base">
                                                                        {i18n.t("orders.showContainer.cpf")}</span>{(data?.billing?.tax_id || "")}</p>

                                                                {data?.billing?.tax_regime?.toUpperCase() === "LEGAL" &&
                                                                    <>
                                                                        <p className="text-gray-500 font-normal text-base m-0">
                                                                            <span className="text-gray-800 font-normal text-base">
                                                                            {i18n.t("settings.tax_fields.tax_company_name_short")}</span>{(data?.billing?.company || "")}</p>
                                                                        <p className="text-gray-500 font-normal text-base m-0">
                                                                            <span className="text-gray-800 font-normal text-base">
                                                                                {i18n.t("settings.tax_fields.tax_state_registration_short")}
                                                                                </span>{(data?.billing?.state_registration || "")}</p>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className = "flex flex-col">
                                                            <p className="text-gray-500 font-normal text-base mt-1 mb-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {data?.is_click_and_collect ? i18n.t("orders.showContainer.name") : i18n.t("orders.showContainer.send_to")}
                                                                </span>
                                                                {joinWords(data?.shipping.first_name, data?.shipping.last_name)}
                                                                
                                                                {data?.is_first_time_buyer &&
                                                                        <span className="ml-2 whitespace-normal font-normal text-sm bg-blue-50 rounded-md p-1 inline-block mt-1.5">{i18n.t("orders.showContainer.is_first_time_buyer")}</span>
                                                                }
                                                            </p>
                                                            <p className="text-gray-500 font-normal text-base mt-1 mb-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("orders.showContainer.phone")}</span>{(data?.shipping?.phone || "")}</p>
                                                            <p className="text-gray-500 font-normal text-base mt-1 mb-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("orders.showContainer.email")}</span>{(data?.shipping?.email || "")}</p>
                                                            <p className="text-gray-500 font-normal text-base mt-1 mb-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("orders.showContainer.address")}</span>
                                                                    {joinWords(data?.shipping?.address_1, data?.shipping?.address_2)}</p>
                                                            <p className="text-gray-500 font-normal text-base mt-1 mb-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("orders.showContainer.state")}</span>{(data?.shipping?.state || "")}</p>
                                                            <p className="text-gray-500 font-normal text-base mt-1 mb-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("orders.showContainer.city")}</span>{(data?.shipping?.city || "")}</p>
                                                            <p className="text-gray-500 font-normal text-base mt-1 mb-0">
                                                                <span className="text-gray-800 font-normal text-base">
                                                                    {i18n.t("orders.showContainer.zip_code")}</span>{(data?.shipping?.zip_code || "")}</p>

                                                            {data?.shipping?.country?.toUpperCase() === "BR" &&
                                                                <>
                                                                    <p className="text-gray-500 font-normal text-base m-0">
                                                                        <span className="text-gray-800 font-normal text-base">{i18n.t("orders.showContainer.number")}</span>{(data?.shipping?.number ?? "")}</p>
                                                                    <p className="text-gray-500 font-normal text-base m-0">
                                                                        <span className="text-gray-800 font-normal text-base">{i18n.t("orders.showContainer.neighborhood")}</span>{(data?.shipping?.neighborhood || "")}</p>
                                                                </>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                                {(data?.shipping_method && data?.is_cod ) &&
                                <div className="border rounded-b-lg border-gray-200 border-t-0">
                                    <table className="relative w-full min-w-full">
                                        <tbody>
                                            <tr>
                                                <td className="w-2/5 p-0">
                                                    <div className="p-3">
                                                        <p className="text-gray-800 font-medium text-base m-0">{i18n.t("orders.pricesContainer.price_amount")}</p>
                                                    </div>
                                                </td>
                                                <td className="p-0">
                                                    <div>
                                                        <p className="text-gray-700 font-normal text-base m-0 text-left p-3">
                                                            {i18n.t('orders.pricesContainer.price_amount_desc')}
                                                            <p className='inline font-semibold'>
                                                                <NumberFormat value={data?.total_price} displayType={'text'} thousandSeparator={true} prefix={data?.currency ? `${data?.currency} ` : ""}/>
                                                            </p>
                                                            {i18n.t('orders.pricesContainer.price_amount_desc_continuation')}
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>}

                                {/* Previous content */}
                                <dl className="hidden">

                                    {/* <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        {data?.shipping_method?.shipping_type === "pickup" ? (
                                            <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.shipping_pickup")}</dt>
                                        ) : (
                                            <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.shipping_information")}</dt>
                                        )}
                                        <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="font-medium text-gray-800">{i18n.t("orders.showContainer.name")}</td>
                                                        <td className="text-gray-500 pl-3 align-middle">
                                                            {data?.shipping?.first_name} {data?.shipping?.last_name === null ? "" : data?.shipping?.last_name}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-medium text-gray-800"> {i18n.t("orders.showContainer.phone")} </td>
                                                        <td className="text-gray-500 pl-3 align-middle ">
                                                            {data?.shipping?.phone}
                                                            {data?.shipping?.phone && <br />}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-medium text-gray-800"> {i18n.t("orders.showContainer.email")}</td>
                                                        <td className="text-gray-500 pl-3 align-middle">
                                                            {data?.shipping?.email}
                                                            {data?.shipping?.email && <br />}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-medium text-gray-800"> {i18n.t("orders.showContainer.address")}</td>
                                                        <td className="text-gray-500 pl-3 align-middle">
                                                            {data?.shipping?.address_1} {data?.shipping?.address_2 === null ? "" : data?.shipping?.address_2}
                                                            {data?.shipping?.address_2 && <br />}
                                                        </td>
                                                    </tr>

                                                    {user.current_store.warehouses[0].country === "BR" && (
                                                        <>
                                                            <tr>
                                                                <td className="font-medium text-gray-800"> {i18n.t("orders.showContainer.cpf")}</td>
                                                                <td className="text-gray-500 pl-3 align-middle">
                                                                    {data?.billing?.tax_id}
                                                                    {data?.billing?.tax_id && <br />}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-medium text-gray-800"> {i18n.t("orders.showContainer.number")}</td>
                                                                <td className="text-gray-500 pl-3 align-middle">
                                                                    {data?.shipping?.number}
                                                                    {data?.shipping?.number && <br />}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-medium text-gray-800"> {i18n.t("orders.showContainer.neighborhood")} </td>
                                                                <td className="text-gray-500 pl-3 align-middle">
                                                                    {data?.shipping?.neighborhood}
                                                                    {data?.shipping?.neighborhood && <br />}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}

                                                    <tr>
                                                        <td className="font-medium text-gray-800"> {i18n.t("orders.showContainer.state")}</td>
                                                        <td className="text-gray-500 pl-3 align-middle">
                                                            {data?.shipping?.state}
                                                            {data?.shipping?.state && <br />}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-medium text-gray-800"> {i18n.t("orders.showContainer.city")} </td>
                                                        <td className="text-gray-500 pl-3 align-middle">
                                                            {data?.shipping?.city}
                                                            {data?.shipping?.city && <br />}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-medium text-gray-800"> {i18n.t("orders.showContainer.zip_code")} </td>
                                                        <td className="text-gray-500 pl-3 align-middle">
                                                            {data?.shipping?.zip_code}
                                                            {data?.shipping?.zip_code && <br />}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            {(shippingInfoErrors.length > 0 || taxingInfoErrors.length > 0) && (
                                                <div className="mt-2">
                                                    {shippingInfoErrors.concat(taxingInfoErrors).map((errorObject) => (
                                                        <OrderFieldErrorBanner errorObject={errorObject} />
                                                    ))}
                                                    <div className="mt-2">
                                                        <button
                                                            type="button"
                                                            className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            onClick={() => {
                                                                history.push(storePath + EDIT_ORDER.replace(":id", data.id))
                                                            }}
                                                        >
                                                            {i18n.t("orders.showContainer.edit")}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </dd>
                                    </div>
                                     */}
                                </dl>
                            </section>

                            {(!!data?.errors && !!data?.errors?.length) &&
                            <section id="section-errors">
                                <h2 className="font-semibold text-gray-800 text-lg mb-3">
                                    {i18n.t("orders.missingDataContainer.missingData")}
                                </h2>

                                <div className="relative w-full border min-w-full rounded-lg border-red-500 p-3 flex justify-between">
                                    {getErrorsElements(data,
                                        shippingMethodErrors, orderNumberErrors, integrationShippingMethodErrors,
                                        shippingInfoErrors, taxingInfoErrors, orderValueErrors, orderLineErrors, data?.errors)}
                                </div>
                            </section>
                            }

                            { data && data.is_pick_and_ship &&
                            <section id="section-pick-and-ship">
                                <h2 className="text-gray-800 font-semibold text-lg m-0 mb-5">
                                    {i18n.t("orders.showContainer.pick_and_ship")}
                                </h2>

                                { data.pick_n_ship_status && shippingLabels.length > 0 &&
                                <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("shipping_labels.generated_labels")}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <div className="">
                                            { shippingLabels.map((shippingLabel, index) => 
                                                <OrderShippingLabel
                                                    labelType={shippingLabel.type}
                                                    trackingNumber={shippingLabel.trackingNumber}
                                                    pdfUrls={shippingLabel.pdfUrls}
                                                    key={shippingLabel.id}
                                                    openPrintDialog={printGeneratedLabel && index === 0}
                                                    onOpenPrintDialog={() => setPrintGeneratedLabel(false)}
                                                />
                                            )}
                                        </div>
                                    </dd>
                                </div>
                                }

                                { data.pick_n_ship_status && invoices && invoices.length > 0 &&
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("invoices.generated_invoices")}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <div className="">
                                            { invoices.map(invoice => 
                                                <OrderInvoice
                                                    invoiceLabel={invoice.operation_nature}
                                                    invoiceKey={invoice.key}
                                                    key={invoice.id}
                                                    pdfUrl={process.env.REACT_APP_PLATFORM_URL + invoice.pdf_file.path}
                                                    xmlUrl={process.env.REACT_APP_PLATFORM_URL + invoice.xml_file.path}
                                                />
                                            )}
                                        </div>
                                    </dd>
                                </div>
                                }

                                { data && data.is_pick_and_ship && data.status === 'pending' && !data.pick_n_ship_status &&
                                
                                <div className="px-4">
                                    { labelRequiresTrackingNumber &&
                                        <div className="flex justify-end pb-4">
                                            <div className="w-60">
                                                <InputField 
                                                    required={true}
                                                    title={<>{t('orders.showContainer.tracking_number_input_label')} <span className="capitalize">{data.shipping_method?.carrier_name?.toLowerCase()}</span></>}
                                                    onChange={(v) => setTrackingNumber(v)}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className="flex justify-center">
                                        <ButtonLoader className="whitespace-nowrap ml-3 w-40" type="primary" disabled={disableLabelGeneration} loading={loadingLabel} onClick={() => handleGenerateLabel()} loading_text={i18n.t("confirmDialog.loading")+'...'}>
                                            {i18n.t("orders.showContainer.generate_label")}
                                        </ButtonLoader>
                                    </div>
                                    {labelGenerationError &&
                                        <div className="text-center mt-2 text-red-500">{labelGenerationError}</div>
                                    }
                                </div>
                                }
                            </section>
                            }

                            <section id="section-estimated-delivery">
                                <h2 className="text-gray-800 font-semibold text-lg m-0 mb-3">
                                    {i18n.t("orders.orderMovementContainer.orderMovement")}
                                </h2>

                                <div className="border rounded-lg border-gray-200">
                                    <table className="relative w-full min-w-full">
                                        <tbody>
                                            <tr>
                                                <td className="w-2/5 p-0">
                                                    <div className="p-3">
                                                        <p className="text-gray-800 font-medium text-base m-0">{i18n.t("orders.orderMovementContainer.estimatedDelivery")}</p>
                                                    </div>
                                                </td>

                                                <td className="p-0">
                                                    <div className="">
                                                        <p className="text-gray-700 font-normal text-base m-0 text-left p-3">
                                                            {getEstimatedDeliveryElement(data)}
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>

                                                <tr>
                                                    <td className="w-2/5 p-0 align-top">
                                                        <div className="border-t p-3 border-gray-200">
                                                            <p className="text-gray-800 font-medium text-base m-0">{i18n.t("orders.orderHistory.title")}</p>
                                                        </div>
                                                    </td>

                                                    <td className="p-0">
                                                        <div className="border-t border-gray-200 p-3">
                                                            <div className="relative -left-6">
                                                                <OrderHistory
                                                                    orderHistory={fetchedOrderHistory}
                                                                    isLoadingData={isLoadingOrderHistory || isLoading}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                { data?.shipping_status &&
                                                <tr>
                                                    <td className="w-2/5 p-0 align-top">
                                                        <div className="border-t p-3 border-gray-200">
                                                            <p className="text-gray-800 font-medium text-base m-0 mb-1">{i18n.t("orders.pod.title")}</p>
                                                            {data?.shipping_method?.provides_pod && data?.proof_of_delivery?.length && 
                                                                <a onClick={() => downloadPodAttachmentsZip(data?.order_number, 
                                                                    (data?.proof_of_delivery || []).flatMap((proof) => (proof?.files || []).map((file) => file.path))
                                                                    )
                                                                }
                                                                    className="font-medium cursor-pointer hover:underline text-base m-0 text-blue-500">{i18n.t("orders.pod.download")} 
                                                                    <DownloadIcon
                                                                        className="inline mb-0.5 mr-1 h-5 w-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                </a>
                                                            }
                                                        </div>
                                                    </td>

                                                    <td className="p-0">
                                                        <div className="border-t border-gray-200 p-3">
                                                            <div className="font-normal text-base m-0 mb-1">
                                                                {data?.shipping_method?.provides_pod === false
                                                                    ? <p className="">{i18n.t("orders.pod.not_provided")}</p>
                                                                    : <ProofOfDelivery order={data?.id} proofs={data?.proof_of_delivery} /> 
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                }

                                            {(!!data?.shipping_incidents && !!data?.shipping_incidents?.length) && (
                                                <tr>
                                                    <td className="w-2/5 p-0 align-top">
                                                        <div className="border-t p-3 border-gray-200">
                                                            <p className="text-gray-800 font-medium text-base m-0">{i18n.t("orders.orderMovementContainer.incidents")}</p>
                                                        </div>
                                                    </td>

                                                    <td className="p-0">
                                                        <div className="border-t border-gray-200 p-3">
                                                            {getIncidentsElements(data?.shipping_incidents)}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </section>

                            <section id="section-product-info">
                                <h2 className="text-gray-800 font-semibold text-lg m-0">
                                    {i18n.t("orders.showContainer.orderProducts")}
                                </h2>

                                <div className="mt-5">
                                    <table className="w-full border-collapse h-full">
                                        <thead>
                                            <tr>
                                                {data && productListColumns.map((header, index) => (
                                                    <th className={`${index === 0 ? "w-2/6": "w-1/6"} h-full`}>
                                                        <div className={`h-full w-full flex items-end text-left pb-3 ${index === 0 ? "": "px-3"}`}>
                                                            {getProductInfoHeader(index, header)}
                                                        </div>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {orderLinesWithErrorObjects.map((order_line, index) => (
                                                <>
                                                    <tr className="h-full">
                                                        <td className={`p-0 ${index >= 1 ? "pt-3" : ""}`}>
                                                            <div className={`h-full border-gray-200 border-l border-t rounded-tl-lg p-3 ${order_line?.product?.is_kit ? "pb-0" : " border-b rounded-bl-lg"}`}>
                                                                {generateProductsDetailElements(order_line, null, data?.id)}

                                                                {order_line?.product?.is_kit &&(
                                                                    <p className="text-gray-800 text-base font-medium m-0 mt-5 pb-1">
                                                                        {i18n.t("orders.showContainer.kit_title")}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </td>

                                                        <td className={`p-0 ${index >= 1 ? "pt-3" : ""}`}>
                                                            <div className={`flex flex-col justify-start h-full border-gray-200 border-t p-3 ${order_line?.product?.is_kit ? "pb-0" : " border-b"}`}>
                                                                <p className={`font-normal text-base m-0 text-gray-600 ${order_line?.product?.is_kit ? "pb-3" : ""}`}>{order_line?.quantity ?? 0}</p>
                                                            </div>
                                                        </td>

                                                        <td className={`p-0 ${index >= 1 ? "pt-3" : ""}`}>
                                                            <div className={`flex flex-col justify-start h-full border-gray-200 border-t p-3 ${order_line?.product?.is_kit ? "pb-0" : " border-b"}`}>
                                                                <p className={`font-normal text-base m-0 text-gray-600 ${order_line?.product?.is_kit ? "pb-3" : ""}`}>
                                                                    {order_line?.product?.is_kit ? getCompletedKitsQuantity(order_line?.id, order_line?.product?.kit_childs, order_line?.quantity) : countReservedUnits(order_line?.id, order_line?.product?.id)}
                                                                </p>
                                                            </div>
                                                        </td>

                                                        <td className={`p-0 ${index >= 1 ? "pt-3" : ""}`}>
                                                            <div className={`flex flex-col justify-start h-full border-gray-200 border-r border-t rounded-tr-lg p-3 ${order_line?.product?.is_kit ? "pb-0" : " border-b rounded-br-lg"}`}>
                                                                <span className={`${order_line?.product?.is_kit ? "pb-3" : ""}`}>
                                                                    {getAvailableProductNumberElement(order_line?.id)}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    {order_line?.product?.is_kit &&(
                                                        order_line?.product.kit_childs.map((kit_child, indexChild, {length: lengthChild}) =>(
                                                            <tr className="h-full">
                                                                <td className="p-0">
                                                                    <div className={`h-full border-gray-200 border-l pb-3 ${indexChild === lengthChild - 1 ? "border-b rounded-bl-lg" : ""}`}>
                                                                        <div className="flex flex-col justify-center h-full border-gray-200 border-l border-t border-b rounded-tl-lg rounded-bl-lg p-3 ml-3">
                                                                            {generateProductsDetailElements(order_line, kit_child, data?.id)}
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td className="p-0">
                                                                    <div className={`h-full pb-3 ${indexChild === lengthChild - 1 ? "order-gray-200 border-b" : ""} h-full`}>
                                                                        <div className="flex flex-col justify-center h-full border-gray-200 border-t border-b p-3">
                                                                            <p className="font-normal text-base m-0 text-gray-600">
                                                                                {getKitChildQuantity(kit_child?.quantity, order_line?.quantity)}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td className="p-0">
                                                                    <div className={`h-full pb-3 ${indexChild === lengthChild - 1 ? "border-gray-200 border-b" : ""}`}>
                                                                        <div className="flex flex-col justify-center h-full border-gray-200 border-t border-b p-3">
                                                                            <p className="font-normal text-base m-0 text-gray-600">
                                                                                {countReservedUnits(order_line?.id, kit_child?.child_product?.id)}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td className="p-0">
                                                                    <div className={`h-full border-gray-200 border-r pb-3 ${indexChild === lengthChild - 1 ? "border-b rounded-br-lg" : ""}`}>
                                                                      <div className="flex flex-col justify-center h-full border-gray-200 border-r border-t border-b rounded-tr-lg rounded-br-lg p-3 mr-3">
                                                                            {getAvailableProductNumberElement(order_line?.id, kit_child?.id)}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

{/* 
                                <div className="w-full flex flex-col">
                                    <div className="flex flex-row justify-between">
                                        {data && productListColumns.map((header, index) => (
                                            <div className={`pb-3 flex items-end ${ index === 0 ? "w-2/6" : "w-1/6"} ${ index === 3 ? "pr-3" : ""} ${ index === 1 ? "pr-3 pl-3" : ""}`}>
                                                {getProductInfoHeader(index, header)}
                                            </div>
                                        ))}
                                    </div>


                                    <div>
                                        {orderLinesWithErrorObjects.map((order_line, index) => (
                                        <div className={`w-full flex flex-col border border-gray-200 border-separate rounded-md ${index >= 1 ? "mt-4" : ""}`}>
                                            <div className={`flex flex-row px-3 pt-3 justify-between 
                                                    ${order_line?.product?.is_kit ? "" : "pb-5"}`}>
                                                <div className="flex flex-col gap-1 w-2/6">
                                                    <div className="max-w-full">
                                                        {generateProductsDetailElements(order_line, null, data?.id)}
                                                    </div>
                                                </div>
                                                <div className="flex flex-row gap-4 mr-5">
                                                    <div className="w-44 pl-5">
                                                        <p className="font-normal text-base m-0 text-gray-600">{order_line?.quantity ?? 0}</p></div>
                                                    <div className="w-44 pl-7">
                                                        <p className="font-normal text-base m-0 text-gray-600">{countReservedUnits(order_line?.stock_units)}</p></div>
                                                    <div className="w-44 pl-12">
                                                        {getAvailableProductNumberElement(order_line?.product?.stock)}</div>
                                                </div>
                                            </div>
                                            {order_line?.product?.is_kit &&(<>
                                                <p className="text-gray-800 text-base font-medium m-0 mt-5 mb-1 ml-5">
                                                    {i18n.t("orders.showContainer.kit_title")}
                                                </p>

                                                {order_line.product.kit_childs.map(kit_child =>(
                                                <div className="border border-gray-200 border-separate mx-3 mb-3 flex flex-row rounded-md px-3 py-3 justify-between">
                                                        <div className="w-5/12">
                                                            <div className="max-w-full">
                                                                {generateProductsDetailElements(order_line, kit_child, data?.id)}
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row ml-5 gap-5">
                                                            <div className="w-44 pl-6">
                                                                <p className="font-normal text-base m-0 text-gray-600">
                                                                    {kit_child?.quantity ?? 0}
                                                                </p>
                                                            </div>
                                                            <div className="w-44 pl-6">
                                                                <p className="font-normal text-base m-0 text-gray-600">
                                                                    {countReservedKitChild(order_line?.stock_units, kit_child?.child_product?.id)}</p>
                                                            </div>
                                                            <div className="w-44 pl-11">
                                                                {getAvailableProductNumberElement(kit_child?.child_product?.stock)}
                                                            </div>
                                                        </div>
                                                </div>
                                                ))}</>
                                            )}
                                        </div>
                                    ))}</div>

                                </div>

 */}
                            </section>

                            {(shippingDocuments && !!shippingDocuments.length) && (
                            <section id="section-attached-files">
                                <h2 className="text-gray-800 font-semibold text-lg m-0 mb-5">
                                    {i18n.t("orders.showContainer.attachments")}
                                </h2>
                                <table className="relative w-full table-fixed">
                                    <thead>
                                        <tr>
                                            <th><p className="text-base text-gray-800 font-medium m-0 text-left pb-3">{i18n.t("orders.showContainer.file_name")}</p></th>
                                            <th><p className="text-base text-gray-800 font-medium m-0 text-left pb-3 pl-3">{i18n.t("orders.showContainer.printing_type")}</p></th>
                                            <th><p className="text-base text-gray-800 font-medium m-0 text-left pb-3 pl-3">{i18n.t("orders.showContainer.actions")}</p></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {shippingDocuments?.map((document, index, docsArray) => (
                                        <tr>
                                            <td className="p-0">
                                                <div className={`border-t border-l border-gray-200 ${index === 0 ? "rounded-tl-lg" : ""} ${index === docsArray.length - 1 ? "border-b rounded-bl-lg" : ""}`}>
                                                    <p className="text-base font-normal text-gray-600 m-0 p-3">{document.file.filename}</p>
                                                </div>
                                            </td>
                                            <td className="p-0">
                                                <div className={`border-t border-gray-200 ${index === docsArray.length - 1 ? "border-b" : ""}`}>
                                                    <p className="text-base font-normal text-gray-600 m-0 p-3">
                                                    {document.printing_type === "label"? i18n.t("orders.addContainer.Label") : i18n.t("orders.addContainer.document")}</p>
                                                </div>
                                            </td>
                                            <td className="p-0">
                                                <div className={`border-t border-r border-gray-200 ${index === 0 ? "rounded-tr-lg" : ""} ${index === docsArray.length - 1 ? "border-b rounded-br-lg" : ""}`}>
                                                    <p className="text-base font-normal m-0 p-3">
                                                        <a
                                                            rel="noopener noreferrer"
                                                            href={process.env.REACT_APP_PLATFORM_URL + document.file.path}
                                                            target="_blank"
                                                        >{i18n.t("orders.showContainer.download")}
                                                        </a>
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </section>)}

                            { showBillingInfo &&
                            <section id="section-price-detail">
                                <h2 className="font-semibold text-gray-800 text-lg m-0 mb-5">
                                    {i18n.t("orders.addContainer.prices")}
                                </h2>

                                <table className="relative w-full">
                                    <thead>
                                        <tr>{pricesListColumns.map( (header,index) => (
                                            //<th className={`${index === 0 ? "w-4/5 pl-3" : "text-center"}`}>
                                            <th className={`${index === 1 ? "" : "text-center"} p-0`}>
                                                <p className={`text-base text-gray-800 font-medium m-0 pb-2 ${index === 1 ? "text-right pr-3" : "text-left"}`}>
                                                    {header}
                                                </p>
                                            </th>
                                            ))}
                                            </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td className="p-0">
                                                <div className="border-t border-l border-gray-200 rounded-tl-lg">
                                                    <p className="text-base font-normal text-gray-600 m-0 pl-3 py-2">{i18n.t("orders.pricesContainer.total_items_price")}</p>
                                                </div>
                                            </td>

                                            <td className="p-0">
                                                <div className="flex justify-center flex-col border-t border-r border-gray-200 rounded-tr-lg">
                                                    <p className="text-base font-normal text-gray-600 m-0 py-2 text-right pr-3">{(data?.currency === "USD" ? "$" : "R$") + data?.calculated_total_price_for_items /* totalPriceForItems */}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td className="p-0">
                                                <div className="border-t border-l border-gray-200">
                                                    <p className="text-base font-normal text-gray-600 m-0 pl-3 py-2">{i18n.t("orders.pricesContainer.shipping_price")}</p>
                                                </div>
                                            </td>

                                            <td className="p-0">
                                                <div className="flex justify-center flex-col text-center border-t border-r border-gray-200">
                                                    <p className="text-base font-normal text-gray-600 m-0 py-2 text-right pr-3">{(data?.currency === "USD" ? "$" : "R$") + data?.buyer_shipping_price}</p>
                                                </div>
                                            </td>
                                        </tr>

                                        { data?.extra_fees_price && data?.extra_fees_price > 0 &&
                                        <tr>
                                            <td className="p-0">
                                                <div className="border-t border-l border-gray-200">
                                                    <p className="text-base font-normal text-gray-600 m-0 pl-3 py-2">{i18n.t("orders.pricesContainer.extra_fees_price")}</p>
                                                </div>
                                            </td>

                                            <td className="p-0">
                                                <div className="flex justify-center flex-col text-center border-t border-r border-gray-200">
                                                    <p className="text-base font-normal text-gray-600 m-0 py-2 text-right pr-3">{(data?.currency === "USD" ? "$" : "R$") + data?.extra_fees_price}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        }

                                        <tr>
                                            <td className="p-0">
                                                <div className="border-t border-l border-gray-200">
                                                    <p className="text-base font-normal text-gray-600 m-0 pl-3 py-2">{i18n.t("orders.pricesContainer.discount")}</p>
                                                </div>
                                            </td>

                                            <td className="p-0">
                                                <div className="flex justify-center flex-col text-center border-t border-r border-gray-200">
                                                    <p className="text-base font-normal text-gray-600 m-0 py-2 text-right pr-3">- {(data?.currency === "USD" ? "$" : "R$") + data?.calculated_total_discount_for_items /* data?.discount_price */}</p>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="p-0">
                                                <div className="border-t border-b border-l border-gray-200 rounded-bl-lg">
                                                    <p className="text-base text-gray-600 m-0 pl-3 font-semibold py-2">{i18n.t("orders.pricesContainer.total_order_price")}</p>
                                                </div>
                                            </td>

                                            <td className="p-0">
                                                <div className="flex justify-center flex-col text-center border-t border-b border-r border-gray-200 rounded-br-lg">
                                                    <p className="text-base text-gray-600 m-0 font-semibold py-2 text-right pr-3">{(data?.currency === "USD" ? "$" : "R$") + (data?.calculated_total_order_value || 0) /* data?.total_price */}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                            }

                            {/* Previous content */}
                            <dl className="hidden">
                            {/*
                                <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.order_number")}</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        #{data?.order_number}
                                    </dd>
                                    { (orderNumberErrors.length > 0) &&
                                        <div className="mt-2">
                                            { orderNumberErrors.map( errorObject => <OrderFieldErrorBanner errorObject={errorObject}/> )}
                                            <div className="mt-2">
                                                <button
                                                    type="button"
                                                    className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    onClick={() => { history.push(storePath + EDIT_ORDER.replace(':id', data.id)) }}
                                                >
                                                    {i18n.t("orders.showContainer.edit")}
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {data?.cart_id &&
                                    <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.cart_id")}</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            #{data?.cart_id}
                                        </dd>
                                    </div>
                                }
                                <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.store")}</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.store.name}
                                    </dd>
                                </div>
                                <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.channel")}</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {capitalize(data?.channel_name)}
                                    </dd>
                                </div>
                                <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.state")}</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <OrderStatusPill orderData={data}/>
                                    </dd>
                                </div>
                                <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.shipment_status")}</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {data?.shipping_status && data?.status === 'shipped' ?
                                            <StatusPill
                                                status={data.shipping_status}
                                                statuses={TRACKING_STATUSES_DEFINITIONS}
                                            />
                                            :
                                            <div className="italic"> {i18n.t("orders.showContainer.no_information")}</div>
                                        }
                                    </dd>
                                </div>
                                <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.date_of_creation")}</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        { data && new Intl.DateTimeFormat(i18n.language, {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: 'numeric',
                                            minute: 'numeric'
                                        }).format(new Date(data.channel_created_at))}
                                    </dd>
                                </div>
                                
                                    <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    {data?.shipping_method?.shipping_type == 'pickup' ? (
                                        <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.shipping_pickup")}</dt>
                                        ) : (
                                        <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.shipping_information")}</dt>
                                    )}
                                        <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className='font-medium text-gray-800'>{i18n.t("orders.showContainer.name")}</td>
                                                        <td className='text-gray-500 pl-3 align-middle'> {data?.shipping?.first_name} {data?.shipping?.last_name === null ? "" : data?.shipping?.last_name} </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='font-medium text-gray-800'> {i18n.t("orders.showContainer.phone")} </td>
                                                        <td className='text-gray-500 pl-3 align-middle '> {data?.shipping?.phone}{ data?.shipping?.phone && <br/>} </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='font-medium text-gray-800'> {i18n.t("orders.showContainer.email")}</td>
                                                        <td className='text-gray-500 pl-3 align-middle'> {data?.shipping?.email}{ data?.shipping?.email && <br/>} </td>
                                                    </tr> 
                                                    <tr>
                                                        <td className='font-medium text-gray-800'> {i18n.t("orders.showContainer.address")}</td>
                                                        <td className='text-gray-500 pl-3 align-middle'> {data?.shipping?.address_1} {data?.shipping?.address_2 === null ? "" : data?.shipping?.address_2}{ data?.shipping?.address_2 && <br/>} </td>
                                                    </tr>

                                                    {user.current_store.warehouses[0].country === "BR" && 
                                                        <>
                                                            <tr>
                                                                <td className='font-medium text-gray-800'> {i18n.t("orders.showContainer.cpf")}</td>
                                                                <td className='text-gray-500 pl-3 align-middle'> {data?.billing?.tax_id}{ data?.billing?.tax_id && <br/>} </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='font-medium text-gray-800'> {i18n.t("orders.showContainer.number")}</td>
                                                                <td className='text-gray-500 pl-3 align-middle'> {data?.shipping?.number}{ data?.shipping?.number && <br/>} </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='font-medium text-gray-800'> {i18n.t("orders.showContainer.neighborhood")} </td>
                                                                <td className='text-gray-500 pl-3 align-middle'> {data?.shipping?.neighborhood}{ data?.shipping?.neighborhood && <br/>} </td>
                                                            </tr>
                                                        </>
                                                    }

                                                    <tr>
                                                        <td className='font-medium text-gray-800'> {i18n.t("orders.showContainer.state")}</td>
                                                        <td className='text-gray-500 pl-3 align-middle'> {data?.shipping?.state}{ data?.shipping?.state && <br/>} </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='font-medium text-gray-800'> {i18n.t("orders.showContainer.city")} </td>
                                                        <td className='text-gray-500 pl-3 align-middle'> {data?.shipping?.city}{ data?.shipping?.city && <br/>} </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='font-medium text-gray-800'> {i18n.t("orders.showContainer.zip_code")} </td>
                                                        <td className='text-gray-500 pl-3 align-middle'> {data?.shipping?.zip_code}{ data?.shipping?.zip_code && <br/>} </td>
                                                    </tr>
                                                </tbody>   
                                                
                                            </table>
                                            { (shippingInfoErrors.length > 0 || taxingInfoErrors.length > 0) &&
                                            <div className="mt-2">
                                                { shippingInfoErrors.concat(taxingInfoErrors).map( errorObject => <OrderFieldErrorBanner errorObject={errorObject}/> )}
                                                <div className="mt-2">
                                                    <button
                                                        type="button"
                                                        className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                        onClick={() => { history.push(storePath + EDIT_ORDER.replace(':id', data.id)) }}
                                                    >
                                                        {i18n.t("orders.showContainer.edit")}
                                                    </button>
                                                </div>
                                            </div>
                                            }
                                        </dd>
                                    </div>
                                
                                <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.shipping_method")}</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {showShippingMethod(data)}
                                    </dd>
                                </div>
                                <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.type_of_shipment")}</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    { (data?.shipping_method && data.is_cod ) ? 
                                        <p><b>{i18n.t("orders.showContainer.cash_on_delivery")} <NumberFormat value={data?.total_price} displayType={'text'} thousandSeparator={true} prefix={'$'} /> - {data?.currency} </b></p> 
                                        : data?.shipping_method ? (data?.shipping_method.shipping_type == 'international' ? "Internacional":"Nacional" ) 
                                        : "--"
                                    }
                                    { (orderValueErrors.length > 0) &&
                                        <div className="mt-2">
                                            { orderValueErrors.map( errorObject => <OrderFieldErrorBanner errorObject={errorObject}/> )}
                                            <div className="mt-2">
                                                <button
                                                    type="button"
                                                    className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    onClick={() => { history.push(storePath + EDIT_ORDER.replace(':id', data.id)) }}
                                                >
                                                    {i18n.t("orders.showContainer.edit")}
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    </dd>
                                </div>
                                <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">{i18n.t("orders.showContainer.products")}</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">                                                                                   
                                            { orderLinesWithErrorObjects.map((order_line)=>
                                                <li className="pl-3 pr-4 py-3 text-md" key={order_line.id}>
                                                    <div>
                                                        <OrderLineItem
                                                            orderLine={order_line}
                                                            showAssignedStock={!data.is_pick_and_ship}
                                                            orderStatus={data.status}
                                                            errorComponent={<>{order_line.errors.map(errorObject => <OrderFieldErrorBanner key={errorObject.code} errorObject={errorObject} specificTranslation={true}/>)}</>}
                                                        />
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </dd>
                                </div>
                                {data?.shipping_documents?.length > 0 && (
                                    <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {i18n.t("orders.showContainer.attachments")}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <div className=" flex-row flex-wrap">
                                            <BasicTable
                                                columns={columns}
                                                showHeader
                                                data={data.shipping_documents.map(document => {
                                                    return {
                                                        file:  <FilePdfOutlined className="text-center text-2xl"/>,
                                                        name:  <p>{document.file.filename}</p>,
                                                        printing_type:  <p>{document.printing_type == "label"? "Etiqueta":"Documento"}</p>,
                                                        link:  <a 
                                                        href={process.env.REACT_APP_PLATFORM_URL + document.file.path}
                                                        target="_blank"
                                                        >{i18n.t("orders.showContainer.download")}</a>
                                                    }
                                                })}
                                            />
                                    
                                        </div>
                                    </dd>
                                    </div>
                                )}
                                
                                
                            */}
                            </dl>
                        </div>                       
                    </div>
            )}
        </>
    )
};