import { Skeleton } from "antd"

export const MetricHighlighted = ({ text, description = null, className = null, isLoading = null, children = null }) => {
    return (
        <div className={`px-4 py-2 bg-blue-50 rounded w-fit min-w-12 text-gray-700 text-lg ${className} `}>
            <>
                {isLoading && <Skeleton.Button active={true} size="small" />}
                <span className="font-bold">{text}</span>
                <span> {description}</span>
            </>
            <>{children}</>
        </div>
    )
}
