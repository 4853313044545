import {Button, InputField, SelectInputShippingMethods} from "../../components";
import {useQuery,useQueryClient} from "react-query";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import { Loader } from "../../components/Loader";
import {fetchIntegrationShippingMethods,fetchCubboShippingMethods,saveMatchShippingMethods,deleteMatchShippingMethods} from "../../services";
import { TrashIcon  } from "@heroicons/react/outline";
import {Collapse} from 'react-collapse';
import { ClockIcon  } from "@heroicons/react/outline";
import { useTranslation } from 'react-i18next';
export const ShippingMatchView = ({
    title,
    id,
    onSave,
    onCancel
}) => {
    const { t, i18n } = useTranslation(); 
    const data=[]
    const [shippingData, setShippingData] = useState({data:data})
   
    const queryClient = useQueryClient()
    const integrationShippingMethods = useQuery(['integration_shipping_methods', id], () => fetchIntegrationShippingMethods(id))/*,{
        onSuccess: (info) => {
             console.log("onSuccess")
            info.forEach(element => {
                if(element.shipping_matches.length>1){
                    element.isOpened=true
                }
                else{
                    element.isOpened=false
                }
            });
            setShippingData({data:info})
        }
    }*/
    const [loading, setLoading] = useState(false);
    useEffect(() => { 
        if(integrationShippingMethods?.length){
            integrationShippingMethods.forEach(element => {
                if(element.shipping_matches.length>1){
                    element.isOpened=true
                }
                else{
                    element.isOpened=false
                }
            });
            setShippingData({data:integrationShippingMethods})
        }
    }, [integrationShippingMethods])
    //const integrationShippingMethods=[]
    const cubboShippingMethods = useQuery('cubbo_shipping', fetchCubboShippingMethods)

    const handleClickOnCancel = () => {
        if(onCancel){
            onCancel()
        }
    }

    const handleClickDelete = async (index,index1) =>{
        let newArr = [...shippingData.data];
        if(newArr[index].shipping_matches[index1].id){

            const response = await deleteMatchShippingMethods(newArr[index].shipping_matches[index1].id)
            await queryClient.invalidateQueries('integration_shipping_methods')
        }else{

            newArr[index].shipping_matches.splice(index1,1)
            setShippingData({data:newArr}); 
        }
    }

    const handleClickAdd = (index) =>{
        let newArr = [...shippingData.data]; 
        newArr[index].shipping_matches.push({
           
            integration_shipping_method_id: 1,
            priority: newArr[index].shipping_matches.length?newArr[index].shipping_matches.length+1:1,
            shipping_method: {},
            shipping_type:'national',
            shipping_method_id: null
        })
        setShippingData({data:newArr}); 

    }

    const handleClickOnSave = async () => {
        setLoading(true)
        let shipping_matches=[]
        
        if(shippingData.length || shippingData.data.length){
            shippingData.data.forEach(elementx => {
                elementx.shipping_matches.forEach(element => {
                    element.shipping_type=elementx.shipping_type
                    shipping_matches.push(element)
                })
    
            });
           
            const response = await saveMatchShippingMethods(
                shipping_matches
            )
            await queryClient.invalidateQueries('integration_shipping_methods')
        }
        setTimeout(() => {
            setLoading(false)
            
            onSave()
        }, 500);
    }


    return (
        <div className="">
            <div className="space-y-3 divide-y divide-gray-200">
            <div>
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
                </div>
            </div>
            
            <div>
            
                <div className="flow-root mt-6">
                    <ul className ="-my-5 divide-y divide-gray-200">
                    
                    <div className="overflow-hidden bg-white flex flex-row justify-between my-1 ">
                        

                        <div className="m-2">
                            {i18n.t("integrations.shipping_view.integration")}
                        </div>
                        <div className="m-2">
                            Cubbo
                        </div>
                    </div>
                    {!integrationShippingMethods.isPreviousData && integrationShippingMethods.isLoading ? (
                        <><Loader show={integrationShippingMethods.isLoading}></Loader></>
                    ) : integrationShippingMethods.isError ? (
                        <>Error: {integrationShippingMethods.error.message}</>
                    ) : !integrationShippingMethods.data.length ? (
                        <><p className="text-center p-10">{i18n.t("integrations.shipping_view.No_shipping_methods")}</p>
                        <br></br>
                        <p className="text-center">{i18n.t("integrations.shipping_view.Sync_shipping_methods")}</p></>
                    ) : (

                        integrationShippingMethods.data.map((item, index)=>{
                            
                            return (
                                
                             
                                <li className="py-4" key={index}>
                                    <div className="flex items-center">
                                        <div className="flex-1 min-w-0 mx-2">
                                            <p className="text-sm font-medium text-gray-900 truncate mb-2">
                                                {item.name}
                                            </p>
                                            <p className="text-sm text-gray-500 truncate">
                                                {item.shipping_type=='national'?"Nacional":"Internacional"}
                                            </p>
                                        </div>
                                        <div className="flex-1 min-w-0 mx-2">
                                            <p className="text-sm font-medium text-gray-900 truncate mb-2">
                                              {i18n.t("integrations.shipping_view.international")}
                                            </p>
                                            <p className="text-sm text-gray-500 truncate">
                                            <input
                                                className="input"
                                                type="checkbox"
                                                checked={item.shipping_type == 'international'}
                                                onChange={({target: {checked}}) => {
                                                    integrationShippingMethods.data[index].shipping_type = checked?'international':'national'
                                                    setShippingData({
                                                        ...shippingData,
                                                        data:integrationShippingMethods.data
                                                    })
                                            
                                                }}/>
                                            </p>
                                        </div>
                                        <div className="flex-1 min-w-0 mx-2">
                                            <p className="text-sm font-medium text-gray-900 truncate mb-2">
                                                {i18n.t("integrations.shipping_view.Assign_by_priority")}
                                            </p>
                                            <p className="text-sm text-gray-500 truncate">
                                            <input
                                                className="input"
                                                type="checkbox"
                                                checked={item.isOpened}
                                                onChange={({target: {checked}}) => {
                                                    integrationShippingMethods.data[index].isOpened=checked
                                                    setShippingData({
                                                        ...shippingData,
                                                        data:integrationShippingMethods.data
                                                    })
                                                }}/>
                                            </p>
                                        </div>
                                        <div className="flex-1 min-w-0 mx-2">
                                        {!cubboShippingMethods.isPreviousData && cubboShippingMethods.isLoading ? (
                                            <><Loader show={true}></Loader></>
                                        ) : cubboShippingMethods.isError ? (
                                            <>Error: {cubboShippingMethods.error.message}</>
                                        ) : (

                                           
                                            !item.isOpened &&
                                            <>
                                            
                                            <SelectInputShippingMethods
                                              options={cubboShippingMethods.data}
                                              label= {i18n.t("integrations.shipping_view.Shipping_method")}
                                              selectedInitial={item.shipping_matches.length ? item.shipping_matches[0].shipping_method:{}}
                                              onChange={(value) => {

                                                  if(!integrationShippingMethods.data[index].shipping_matches[0]){
                                                      integrationShippingMethods.data[index].shipping_matches=[{
                                                          shipping_method_id:value.id,
                                                          shipping_method:value,
                                                          priority:1,
                                                          integration_shipping_method_id:integrationShippingMethods.data[index].id
                                                          
                                                        }]
                                                    }
                                                    else{
                                                        
                                                        integrationShippingMethods.data[index].shipping_matches[0].shipping_method_id=value.id
                                                        integrationShippingMethods.data[index].shipping_matches[0].shipping_method=value
                                                        integrationShippingMethods.data[index].shipping_matches[0].integration_shipping_method_id=integrationShippingMethods.data[index].id
                                                    }
                                                    setShippingData({
                                                        ...shippingData,
                                                        data:integrationShippingMethods.data
                                                    })
                                              }}
                                          />
                                            </>
                                        )}
                                            
                                        </div>
                                    </div>
                                   
                                    
                                            <Collapse isOpened={item.isOpened} style="overflow: visible !important">
                                                <div className="flow-root mt-6">
                                                    <ul className ="-my-5 divide-y divide-gray-200">
                                                    <div className="divide-y divide-gray-200">
                                                       {i18n.t("integrations.shipping_view.priorities_shipment_method")}
                                                    </div>
                                                        {item.shipping_matches.map((shipping, index1)=>{
                                                        return (

                                                            <li className="py-4" key={index1}>
                                                                <div className="flex items-center ">
                                                                    <div className="flex-1 min-w-0 mx-2">
                                                                       {i18n.t("integrations.shipping_view.option")}{shipping.priority}
                                                                    </div>
                                                                    <div className="flex-1 min-w-0 ">
                                                                        {shipping.shipping_method.shipping_type!="national"?"Local":shipping.shipping_method.shipping_type!="international"?"Nacional":"Internacional"}
                                                                    </div>
                                                                    <div className="flex-1 min-w-0 ">
                                                                    <SelectInputShippingMethods
                                                                        options={cubboShippingMethods.data}
                                                                        selectedInitial={shipping.shipping_method}
                                                                        label= {i18n.t("integrations.shipping_view.Shipping_method")} 
                                                                        onChange={(value) => {
                                                                            integrationShippingMethods.data[index].shipping_matches[index1].shipping_method=value
                                                                            integrationShippingMethods.data[index].shipping_matches[index1].shipping_method_id=value.id 
                                                                            integrationShippingMethods.data[index].shipping_matches[index1].integration_shipping_method_id=integrationShippingMethods.data[index].id                                                            
                                                                            setShippingData({
                                                                                ...shippingData,
                                                                                data:integrationShippingMethods.data
                                                                            })
                                                                        }}
                                                                    />
                                                                    </div>
                                                                    <div className="min-w-0 ">
                                                                        <a className="cursor-pointer ml-3 relative inline-flex items-center px-2 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mt-6" 
                                                                            onClick={() => {handleClickDelete(index,index1)}}>
                                                                            <TrashIcon className="h-4 h-4"/>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            
                                                            )
                                                        })}
                                                        <Button className="mb-3" onClick={() => {handleClickAdd(index)}}>{i18n.t("integrations.shipping_view.add_option")}</Button>

                                                    </ul>
                                                </div>
                                            </Collapse>
                               
                                </li>
                                
                                
                            
                            )
                        })

                    )}
                    </ul>
                </div>
            </div>
            <div className="pt-4">
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">   
                    <Button type="secondary" onClick={handleClickOnCancel}>{i18n.t("integrations.shipping_view.cancel")}</Button>

                    {loading ? <label className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-100 text-base font-medium text-gray-800 sm:col-start-2 sm:text-sm">
                                   {i18n.t("integrations.shipping_view.loading")}
                                </label> : 
                    <Button  onClick={handleClickOnSave}>
                        {i18n.t("integrations.shipping_view.save")}
                    </Button>}
                </div>
            </div>
        </div>

       
        </div>
    )
}