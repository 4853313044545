import { useMemo } from "react"
import { InputField } from "../InputField"
import { useTranslation } from "react-i18next"
import { capitalizeFirstLetter } from "../../utils/StringUtils"

export const StoreBusinessRuleCondition = ({ products = null, notProducts = null, maxQuantity = null, minQuantity = null, not = false, channelIntegrations = null, isPotentialFraud = false, hasFragileItems = false, isFirstTimeBuyer = false, isExclusive = false }) => {
    const { t } = useTranslation()
    const SkuPill = ({ sku }) => (
        <div className={"px-2.5 py-0.5 mr-1.5 mb-1.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"}>{sku ? sku : t("settings.store_business_container.all_skus")}</div>
    )

    const conditionProducts = useMemo(() => {
        if (products) return products
        return notProducts
    }, [products, notProducts])

    const includeOrNot = () => {
        if (!not) {
            return t("settings.store_business_container.includes")
        } else {
            return t("settings.store_business_container.does_not_include")
        }
    }

    const conditionPredicate = () => {
        if (channelIntegrations) {
            return t("settings.store_business_container.channel_name", {channel_name: channelIntegrations.channel_name})
        }
        else if (isPotentialFraud) {
            return t("settings.store_business_container.is_potencial_fraud")
        } else if (isFirstTimeBuyer) {
            return t("settings.store_business_container.is_first_time_buyer")
        } else if (hasFragileItems) {
            return t("settings.store_business_container.has_fragile_items")
        }
        if (!not) {
            if (minQuantity && !maxQuantity) {
                return t("settings.store_business_container.include_at_least")
            }
            if (minQuantity && maxQuantity) {
                if (minQuantity === maxQuantity) {
                    return t("settings.store_business_container.include_exactly")
                }
                return t("settings.store_business_container.include_range")
            }
            if (!minQuantity && maxQuantity) {
                return t("settings.store_business_container.include_up_to")
            }

        }
        if (not) {
            if (minQuantity && !maxQuantity) {
                return t("settings.store_business_container.not_include_at_least")
            }
            if (minQuantity && maxQuantity) {
                if (minQuantity === maxQuantity) {
                    return t("settings.store_business_container.not_include_exactly")
                }
                return t("settings.store_business_container.not_include_range")
            }
            if (!minQuantity && maxQuantity) {
                return t("settings.store_business_container.not_include_up_to")
            }
        }

    }

    const notProductIdConditions = () => {
        if (notProducts) {
            if (minQuantity && !maxQuantity) {
                return t("settings.store_business_container.at_least") + minQuantity
            }
            if (minQuantity && maxQuantity) {
                if (minQuantity === maxQuantity) {
                    return t("settings.store_business_container.exactly") + minQuantity
                }
                return t("settings.store_business_container.of_range") + minQuantity + " " + `${t("settings.store_business_container.to")}` + " " + maxQuantity
            }
            if (!minQuantity && maxQuantity) {
                return t("settings.store_business_container.up_to") + maxQuantity
            }
        }
    }

    const quantityValues = () => {
        if (!not) {
            if (minQuantity && !maxQuantity) {
                return minQuantity
            }
            if (minQuantity && maxQuantity) {
                if (minQuantity === maxQuantity) {
                    return minQuantity
                }
                return `${minQuantity + " " + `${t("settings.store_business_container.to")}` + " " + maxQuantity}`
            }
            if (!minQuantity && maxQuantity) {
                return maxQuantity
            }
        } else {
            if (minQuantity && !maxQuantity) {
                return minQuantity
            }
            if (minQuantity && maxQuantity) {
                if (minQuantity === maxQuantity) {
                    return minQuantity
                }
                return `${minQuantity + " " + `${t("settings.store_business_container.to")}` + " " + maxQuantity}`
            }
            if (!minQuantity && maxQuantity) {
                return maxQuantity
            }
        }
    }

    const buildPillList = function () {
        if (conditionProducts)
            return conditionProducts?.map(product => <SkuPill key={product?.product_id} sku={product?.sku} />)
        else
            return <span className="italic">{t('settings.store_business_container.deleted_products')}</span>
    }

    return (
        <div>
            <div className="flex flex-wrap items-start h-full">
                {!notProducts ?
                    <>
                        {channelIntegrations || isPotentialFraud || hasFragileItems || isFirstTimeBuyer ?
                            <>
                                <p className="text-gray-700 text-sm font-normal mt-2.5 ml-1">{conditionPredicate()}</p>
                            </>
                            :
                            <>
                                <p className="text-gray-700 text-sm font-normal mt-2.5 ml-1">{includeOrNot()}</p>
                                <p className="text-gray-700 text-sm font-normal mt-2.5 ml-1">{`${conditionPredicate() + quantityValues()}`}</p>
                                <p className="text-gray-700 text-sm font-normal mt-2.5 ml-1">{t("settings.store_business_container.some_of_this")}</p>
                                <div className="flex flex-row flex-wrap bg-gray-100 rounded-md p-2 pb-1 shadow-sm block max-w-xl sm:text-sm ml-2">
                                    {buildPillList()}
                                </div>
                                {isExclusive && (
                                    <p className="text-gray-700 text-sm font-normal mt-2.5 ml-2">{t("settings.store_business_container.exclusive")}</p>

                                )}
                            </>
                        }
                    </>
                    :
                    <>
                        <p className="text-gray-700 text-sm font-normal mt-2.5 ml-1">{includeOrNot()}</p>
                        <p className="text-gray-700 text-sm font-normal mt-2.5 ml-1">{notProductIdConditions()}</p>
                        <p className="text-gray-700 text-sm font-normal mt-2.5 ml-1">{t("settings.store_business_container.not_some_of_this")}</p>
                        <div className="flex flex-row flex-wrap bg-gray-100 rounded-md p-2 pb-1 shadow-sm block max-w-xl sm:text-sm ml-2">
                            {buildPillList()}
                        </div>
                        {isExclusive && (
                            <p className="text-gray-700 text-sm font-normal mt-2.5 ml-2">{t("settings.store_business_container.exclusive")}</p>
                        )}
                    </>
                }
            </div>
        </div>
    )
}
