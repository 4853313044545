import { Grid, Col } from "@tremor/react"
import { BaseCard } from "../../../../components/MetricsCards/BaseCard"
import { MetricsCardHeader } from "../../../../components/MetricsCards/MetricsCardHeader"
import { INTERVAL_DAY } from "../chartDataUtils"
import { useTranslation } from "react-i18next"
import useOnScreen from "../../../../hooks/useOnScreen"
import { useEffect, useState, useRef } from "react"
import { StuckOrdersByReason } from "./StuckOrdersByReason"
import { StuckOrdersHistoric } from "./StuckOrdersHistoric"
import { StuckOrdersAverage } from "./StuckOrdersAverage"
import { StuckOrdersPercentage } from "./StuckOrdersPercentage"
import { StuckOrdersHistoricByStatus } from "./StuckOrdersHistoricByStatus"

export const StuckOrders = ({ storeId = null, dateFilter = null, interval = INTERVAL_DAY }) => {
    const { t } = useTranslation()

    const cardRef = useRef(null)
    const cardIsOnScreen = useOnScreen(cardRef)
    useEffect(() => {
      if (cardIsOnScreen) window.analytics.track("Metrics - Views StuckOrders")
    }, [cardIsOnScreen])
    const [stuckOrdersData, setStuckOrdersData] = useState(null)
    const categories = ["backorder", "error", "unpaid", "hold"]

    return (
        <BaseCard className="my-6" ref={cardRef}>
            <MetricsCardHeader title={t("metrics.stuck_orders.title")} subtitle={t("metrics.stuck_orders.subtitle")} />
            <StuckOrdersAverage storeId={storeId} dateFilter={dateFilter} />
            <Grid numItemsSm={1} numItemsLg={3} className="w-full gap-10 lg:gap-x-20 py-5">
                <Col numColSpan={1} className="flex flex-col lg:gap-16 gap-10">
                    <StuckOrdersPercentage data={stuckOrdersData} categories={categories}/>
                    <StuckOrdersByReason storeId={storeId} dateFilter={dateFilter} categories={categories} dataChanged={setStuckOrdersData} />
                </Col>
                <Col numColSpanLg={2}>
                    <StuckOrdersHistoric storeId={storeId} dateFilter={dateFilter} categories={categories} interval={interval} />
                </Col>
            </Grid>
            <Grid numItemsSm={1} numItemsLg={2} className="w-full gap-10 lg:gap-x-20 py-5">
                <Col numColSpan={1}>
                    <StuckOrdersHistoricByStatus storeId={storeId} dateFilter={dateFilter} interval={interval} status="error"/>
                </Col>
                <Col numColSpan={1}>
                    <StuckOrdersHistoricByStatus storeId={storeId} dateFilter={dateFilter} interval={interval} status="backorder" />
                </Col>
                <Col numColSpan={1}>
                    <StuckOrdersHistoricByStatus storeId={storeId} dateFilter={dateFilter} interval={interval} status="unpaid"/>
                </Col>
                <Col numColSpan={1}>
                    <StuckOrdersHistoricByStatus storeId={storeId} dateFilter={dateFilter} interval={interval} status="hold"/>
                </Col>
            </Grid>
        </BaseCard>
    )
}
