import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";

import { ALIAS_MAX_LENGTH, ALIAS_REGEX } from "./useGeneralSettingsValidator";

const AliasInput = ({ruleAlias, onRuleAliasUpdate}) => {
    const { t } = useTranslation()

    const handleChangeRuleAlias = (event) => {
        const newRuleAlias = event.target.value

        if(ALIAS_REGEX.test(newRuleAlias)){
            onRuleAliasUpdate(newRuleAlias)
        }
    }

    const remainingAliasLengthDescription = useMemo(() => {
        let aliasLengthDescription = ""
        const remainingLength = ALIAS_MAX_LENGTH - ruleAlias.length

        if(remainingLength <= 0){
            aliasLengthDescription = t("settings.business_rule_creator.general_business_rule_settings.remaining_alias_character_counter.no_remaining")
        } else if(remainingLength === 1){
            aliasLengthDescription = t("settings.business_rule_creator.general_business_rule_settings.remaining_alias_character_counter.one_remaining")
        } else{
            aliasLengthDescription = t("settings.business_rule_creator.general_business_rule_settings.remaining_alias_character_counter.multiple_remaining").replace("{QUANTITY}", remainingLength)
        }

        return aliasLengthDescription
    }, [ruleAlias, t])

    return (
        <div className="flex flex-col gap-2">
            <div className="flex gap-2">
                <label htmlFor="alias" className="font-semibold">
                    <span>{t("settings.business_rule_creator.general_business_rule_settings.alias_field")}</span>
                    <span className="text-red-500">{" *"}</span>
                </label>

                {ruleAlias.length > 0 ? (
                    <CheckCircleIcon className="h-6 text-green-600" />
                ) : (
                    <ExclamationCircleIcon className="h-6 text-red-600" />
                )}
            </div>

            <input
                className="placeholder:text-gray-400 border rounded border-gray-300"
                id="alias"
                name="alias"
                type="text"
                value={ruleAlias}
                maxLength={ALIAS_MAX_LENGTH}
                onChange={handleChangeRuleAlias}
                placeholder={t("settings.business_rule_creator.general_business_rule_settings.alias_placeholder")}
            />
            <div className="text-xs text-gray-400">
                {remainingAliasLengthDescription}
            </div>
        </div>
    )
}

export default AliasInput