import { useEffect, useState } from "react"
import { StoreBusinessRuleCondition } from "./StoreBusinessRuleCondition"
import { useTranslation } from "react-i18next"

export const StoreBusinessRuleStatement = ({ arrayStatement = null, recursiveDepth = 0 }) => {
    const { t } = useTranslation()
    const [operator, setOperator] = useState(null)
    const [conditions, setConditions] = useState([])
    const [nestedStatements, setNestedStatements] = useState([])
    let contador = 0

    useEffect(() => {
        if (!Array.isArray(arrayStatement)) return
        if (arrayStatement[0] != "OR" && arrayStatement[0] != "AND") {
            console.log("unexpected operator", arrayStatement[0])
            return
        }

        let modifiedArrayStatement = Array.from(arrayStatement)
        let newOperator = modifiedArrayStatement.shift()
        let newNestedStatements = []
        let newConditions = []

        for (const statementElement of modifiedArrayStatement) {
            if (Array.isArray(statementElement)) {
                newNestedStatements.push(statementElement)
            } else {
                newConditions.push(statementElement)
            }
        }

        setOperator(newOperator)
        setConditions(newConditions)
        setNestedStatements(newNestedStatements)

    }, [arrayStatement])

    const operatorPerRule = (operator) => {
        if (operator === "AND") {
            return t("settings.store_business_container.and")
        }
        if (operator === "OR")
            return t("settings.store_business_container.or")
    }

    return (
        <div>
            <div>
                {conditions.map((condition, conditionIndex) => {
                    return (
                        <div key={conditionIndex}>
                            <div className="text-gray-500 text-sm mb-3.5 ml-3 mt-3">{(recursiveDepth === 0 && conditionIndex > 0) || (recursiveDepth > 0 && conditionIndex > 0) ? operatorPerRule(operator) : ""}</div>
                            <div className={`border-l-2 border-[#979797] ml-3 px-3 mt-3 mb-3`}>
                                <div className="hidden">{contador++}</div>
                                <StoreBusinessRuleCondition
                                    key={conditionIndex}
                                    maxQuantity={condition.max_quantity}
                                    minQuantity={condition.min_quantity}
                                    products={condition.product_id}
                                    notProducts={condition.not_product_id}
                                    not={condition.not}
                                    isPotentialFraud={condition.is_potential_fraud}
                                    isFirstTimeBuyer={condition.is_first_time_buyer}
                                    hasFragileItems={condition.has_fragile_items}
                                    isExclusive={condition.exclusive}
                                    channelIntegrations={condition.channel_integration_id}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
            <div>
                {nestedStatements.map((statement, statementIndex) => {
                    return (
                        <div key={statementIndex}>
                            <div className="text-gray-500 text-sm mb-3.5 ml-3 mt-3"> {((statementIndex === 0 && recursiveDepth === 0) || (statementIndex > 0 || recursiveDepth > 0)) && contador > 0 ? operatorPerRule(operator) : ""} </div>
                            <div className={`border-l-2 border-[#979797] ml-3 px-3 mt-3 mb-3`}>
                                <div className="hidden">{contador++}</div>
                                <StoreBusinessRuleStatement arrayStatement={statement} recursiveDepth={recursiveDepth + 1} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>

    )
}
