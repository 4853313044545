import { useMemo } from "react"
import { BarList } from "../../../../components/MetricsCards/BarList"
import { numberToString } from "../../../../helpers/internationalization"
import { capitalize } from "../../../../utils/StringUtils"
import { useTranslation } from "react-i18next"
import { getShippingMethodsTotal } from "../../../../services/storeMetricsService"
import { useQuery } from "react-query"
import { DEFAULT_QUERY_STALE_TIME } from "../chartDataUtils"

export const ShippingMethodsPercentage = ({ storeId = null, dateFilter = null }) => {
    const { i18n, t } = useTranslation()

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["orders_shipping_methods", storeId, dateFilter], () => getShippingMethodsTotal(storeId, dateFilter), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    const ordersPerShippingMethods = useMemo(() => {
        if (!data) return []
        let newOrdersPerShippingMethods = []
        Object.keys(data).forEach((key) => {
            newOrdersPerShippingMethods.push({
                key: key,
                value: data[key],
                name: capitalize(key),
            })
        })
        return newOrdersPerShippingMethods
    }, [data])

    const totalOrders = useMemo(() => {
        const total = ordersPerShippingMethods.reduce((acc, item) => acc + item.value, 0)
        return numberToString(total, i18n.language)
    }, [ordersPerShippingMethods, i18n.language])

    return (
        <BarList
            totalValue={totalOrders}
            totalValueLabel={t("metrics.generic.shipments")}
            title={t("metrics.shipping_methods_total.title")}
            mainColumnLabel={t("metrics.shipping_methods_total.main_column_label")}
            secondaryColumnLabel={t("metrics.shipping_methods_total.secondary_column_label")}
            cardProps={{
                className: "h-fit",
            }}
            data={ordersPerShippingMethods}
            valueFormatter={(n) => numberToString(n, i18n.language)}
            isLoading={isLoading}
            maxRowsLabel={t("metrics.shipping_methods_total.grouped_rows_label")}
        />
    )
}
