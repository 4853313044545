import { useEffect, useMemo } from "react"
import { numberToString } from "../../../../helpers/internationalization"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { getStockPerOrderTotal } from "../../../../services/storeMetricsService"
import { BarList } from "../../../../components/MetricsCards/BarList"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"
import { DEFAULT_QUERY_STALE_TIME } from "../chartDataUtils"

export const StockPerOrder = ({ storeId = null, dateFilter = null,
    calculateAverageStockPerOrder = () => {},
    setIsLoading = () => {} }) => {
    const { i18n, t } = useTranslation()

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["stock_per_order_total", storeId, dateFilter], () => getStockPerOrderTotal(storeId, dateFilter), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    const stockPerOrder = useMemo(() => {
        if (!data) return []
        // remove any 0 values
        const dataWithout0Values = Object.keys(data).reduce((object, key) => {
            if (key !== "0") {
                object[key] = data[key]
            }
            return object
        }, {})
        let newOrdersPerShippingMethods = []
        Object.keys(dataWithout0Values).forEach((key) => {
            const name = key === "1" ? t("metrics.stock_per_order_total.item_quantity_single") : t("metrics.stock_per_order_total.item_quantity", { items: key })
            newOrdersPerShippingMethods.push({
                key: key,
                value: data[key],
                name: name,
                itemQuantity: parseInt(key),
            })
        })
        newOrdersPerShippingMethods.sort((a, b) => a.itemQuantity - b.itemQuantity)
        return newOrdersPerShippingMethods
    }, [data, i18n.language])

    const averageStockPerOrder = useMemo(() => {
        const totalOrders = stockPerOrder.reduce((acc, item) => acc + item.value, 0)
        if (totalOrders === 0) return 0
        const totalItems = stockPerOrder.reduce((acc, item) => acc + item.value * item.itemQuantity, 0)
        let average = totalItems / totalOrders
        average = Math.round(average * 100) / 100
        return numberToString(average, i18n.language)
    }, [stockPerOrder, i18n.language])

    useEffect(() => {
        calculateAverageStockPerOrder(averageStockPerOrder)
        setIsLoading(isLoading)
    }, [data, i18n.language])

    return (
        <div>
            <MetricTitle title={t("metrics.stock_per_order_total.title")}/>
            <BarList
                mainColumnLabel={t("metrics.stock_per_order_total.main_column_label")}
                secondaryColumnLabel={t("metrics.stock_per_order_total.secondary_column_label")}
                data={stockPerOrder}
                valueFormatter={(n) => numberToString(n, i18n.language)}
                isLoading={isLoading}
                sortData={false}
                maxRowsLabel={t("metrics.stock_per_order_total.grouped_rows_label", {items: 5})}
            />
        </div>
    )
}
