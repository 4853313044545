import { useContext, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Pagination, Tooltip } from "antd"
import { InformationCircleIcon } from "@heroicons/react/outline"
import { useHistory, useRouteMatch, Switch, Route } from "react-router-dom"
import { sortBy } from "lodash"
import { useMemo } from "react"
import { useQuery } from "react-query"

import { StoreBusinessRuleCard } from "../../components/StoreBusinessRules/StoreBusinessRuleCard"
import { UserContext } from "../../hooks/UserContext"
import { useSelector } from "react-redux"
import { fetchStoreBusinessRules } from "../../services/storeServices"
import { Loader } from "../../components/Loader"
import { CREATE_BUSINESS_RULE, STORE_PATH } from "../../navigation/constants"
import { Filter } from "../../components/NewTable/modules/Filter"
import { SelectInput } from "../../components"
import { Button } from "../../components";

const pagesInterval = [
    { id: "10", name: '10' },
    { id: "25", name: '25' },
    { id: "50", name: '50' },
    { id: "100", name: '100' }
]

export const StoreRulesSettingsContainer = () => {
    const { t } = useTranslation()
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })
    const history = useHistory()
    const pageSize = useSelector((state) => state?.pageSize.ordersList)
    const { user } = useContext(UserContext)
    const initialSearchParams = {
        page: 1,
        store_id: user.current_store?.id,
        per_page: pageSize,
        order_by: 'created_at',
        order: 'desc'
    }
    const [searchParams, setSearchParams] = useState(initialSearchParams)

    const {
        isLoading,
        isFetching,
        isError,
        error,
        data,
        isPreviousData
    } = useQuery(['store_business_rules', searchParams], () => fetchStoreBusinessRules(searchParams), {
        keepPreviousData: true,
    })

    const orderedStoreBusinessRules = useMemo(() => {
        if (!data?.store_business_rules) return []
        let orderedStoreBusinessRules = data?.store_business_rules
        return orderedStoreBusinessRules
    }, [data?.store_business_rules])

    const STORE_RULES_STATUSES = [
        {
            status: true,
            label: t("settings.store_business_container.filters.active")
        }, {
            status: false,
            label: t("settings.store_business_container.filters.inactive")
        }
    ]

    const STORE_RULES_CATEGORIES = [
        {
            type: 'INCLUDE_OR_ENSURE_RULE_ACTION_CATEGORY',
            label: t("settings.store_business_container.filters.add_product")
        }, {
            type: 'CANCEL_RULE_ACTION_CATEGORY',
            label: t("settings.store_business_container.filters.cancel_order")
        }, {
            type: 'PACKAGE_RULE_ACTION_CATEGORY',
            label: t("settings.store_business_container.filters.use_package")
        }, {
            type: 'HOLD_RULE_ACTION_CATEGORY',
            label: t("settings.store_business_container.filters.hold_order")
        }
    ]

    const statusFilters = STORE_RULES_STATUSES.filter(
        (status) => status.filterable !== false).map((status) => {
            return status.filterable === false
                ? null : { id: status.status, label: status.label }
        }
        )

    const ruleCategoryFilters = STORE_RULES_CATEGORIES.filter(
        (rule) => rule.filterable !== false).map((rule) => {
            return rule.filterable === false
                ? null : { id: rule.type, label: rule.label }
        }
        )

    const filtersInitialData = [
        {
            key: "categories",
            label: "settings.store_business_container.filters.category",
            placeholder: "settings.store_business_container.filters.category",
            icon: "category",
            data: {
                visible: true,
                type: 'multiFilter',
                value: [],
                options: ruleCategoryFilters,
            },
        },
        {
            key: "sku_action_parameter",
            label: "settings.store_business_container.filters.add_this_sku",
            placeholder: "settings.store_business_container.filters.add_this_sku",
            icon: "add_sku",
            data: {
                visible: true,
                type: 'text',
                value: ''
            },
        },
        {
            key: "sku_statement",
            label: "settings.store_business_container.filters.that_contains_this_sku",
            placeholder: "settings.store_business_container.filters.that_contains_this_sku",
            icon: "contains_sku",
            data: {
                visible: false,
                type: 'text',
                value: ''
            },
        },
        {
            key: "alias",
            label: "settings.store_business_container.filters.alias",
            placeholder: "settings.store_business_container.filters.alias",
            icon: "alias",
            data: {
                visible: true,
                type: 'text',
                value: ''
            },
        },
        {
            key: "statuses",
            label: "settings.store_business_container.filters.estado",
            placeholder: "settings.store_business_container.filters.estado",
            icon: "status",
            data: {
                visible: true,
                type: 'multiFilter',
                value: [],
                options: statusFilters,
            },
        },
        {
            key: "expired",
            label: "settings.store_business_container.filters.expired",
            placeholder: "settings.store_business_container.filters.expired",
            icon: "expired",
            data: {
                visible: false,
                type: 'boolean',
                onClickValue: false
            },
        },
    ]

    const [filtersData, setFiltersData] = useState(filtersInitialData)

    const updateFiltersData = (updatedData, filter, override = false) => {
        setFiltersData((prev) => {
            return prev.map((item) => {
                if (item.key == filter)
                    return {
                        ...item,
                        data: updatedData
                    }
                else return item
            }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
        })
        let value = updatedData.value
        if (!value) value = {}
        setSearchParams((prev) => ({ ...prev, [filter]: value, page: 1 }))
    }

    const onFilterChanged = (updatedData, filter) => {
        updateFiltersData(updatedData, filter)
    }

    const handleResetFilters = () => {
        setSearchParams(initialSearchParams)
    }

    const onPageSizeChange = (page) => {
        setSearchParams((prev) => ({ ...prev, per_page: page.id, page: 1 }))
    }

    const onPaginationChange = (page) => {
        setSearchParams((prev) => ({ ...prev, page: page }))
    }

    const isLoadingData = () => (!isPreviousData && isLoading)

    if (isLoadingData())
        return <Loader show={true} />

    if (!isLoadingData() && isError)
        return <>Error: {error.message}</>

    const handleOnShowBusinessRuleCreator = () => {
        window.analytics.track("Business Rules - Starts Creation")

        history.push(storePath + CREATE_BUSINESS_RULE)
    }

    return (
        <div className='relative p-10'>
            <div className="space-y-1">
                <p className='text-3xl mb-0 font-bold text-blue-gray-900 '>
                    {t("settings.store_br.title_sbr")}
                </p>

                <div className="flex justify-between mt-5">
                    <p className = "text-lg text-gray-500">
                        {t("settings.store_br.sub_title_sbr")}'
                    </p>

                    <Button
                        className="shrink-0 h-fit"
                        type="primary"
                        onClick={handleOnShowBusinessRuleCreator}
                    >
                        {t("settings.business_rule_creator.button")}
                    </Button>
                </div>
            </div>

            <div className="mt-7 w-full relative">
                <Filter
                    onChange={onFilterChanged}
                    resetAll={handleResetFilters}
                    filtersData={filtersData}
                />
                <div className='absolute top-3 right-0 pr-2'>
                    <SelectInput
                        options={pagesInterval}
                        selectedInitial={pagesInterval.find(option => option.id == data?.meta?.per_page) ?? pagesInterval[0]}
                        className="inline-block text-sm px-4 sm:px-0"
                        onChange={onPageSizeChange}
                        value={pagesInterval.find(option => option.id == data?.meta?.per_page) ?? pagesInterval[0]}
                    />
                    <Pagination
                        className='inline'
                        size="small"
                        current={data?.meta?.current_page}
                        total={data?.meta?.total_count}
                        onChange={onPaginationChange}
                        page={data?.meta?.current_page}
                        pageSize={data?.meta?.per_page}
                        showSizeChanger={false}
                    />
                </div>
            </div>

            <div className="w-full">
                {isFetching && isPreviousData ? (
                    <><Loader show={true}></Loader></>
                ) : isError ? (
                    <>Error: {error.message}</>
                ) : orderedStoreBusinessRules.length <= 0 ? (
                    <div>
                        <p className="text-center italic text-lg text-gray-600"> {t("settings.store_business_container.no_rules")}</p>
                    </div>
                ) :
                    (orderedStoreBusinessRules?.map((item, index) => {
                        return <StoreBusinessRuleCard key={item.id} rule_data={item} store_id={searchParams.store_id} />
                    }))
                }
            </div>

            {!isFetching && !isLoading && (
                <div className="mt-7 w-full relative pb-7 mb-4">
                    <div className='absolute top-3 right-0 pr-2'>
                        <SelectInput
                            options={pagesInterval}
                            selectedInitial={pagesInterval.find(option => option.id == data?.meta?.per_page) ?? pagesInterval[0]}
                            className="inline-block text-sm px-4 sm:px-0"
                            onChange={onPageSizeChange}
                            value={pagesInterval.find(option => option.id == data?.meta?.per_page) ?? pagesInterval[0]}
                        />
                        <Pagination
                            className='inline'
                            size="small"
                            current={data?.meta?.current_page}
                            total={data?.meta?.total_count}
                            onChange={onPaginationChange}
                            page={data?.meta?.current_page}
                            pageSize={data?.meta?.per_page}
                            showSizeChanger={false}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
