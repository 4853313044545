import { Grid, Text, Title } from "@tremor/react"
import { useTranslation } from "react-i18next"
import { getStoreInventoryStats, getStoreStats } from "../../services/storeMetricsService"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { MultipleMetricsCard } from "../../components/MetricsCards/MultipleMetricsCard"

export const OrderStatsContainer = ({storeId}) => {
    const { t } = useTranslation()

    // Orders
    const [backorderOrders, setBackorderOrders] = useState(null)
    const [missingInfoOrders, setMissingInfoOrders] = useState(null)
    const [delayedOrders, setDelayedOrders] = useState(null)
    const [pendingOrders, setPendingOrders] = useState(null)

    // Inventory
    const [productsWithoutStock, setProductsWithoutStock] = useState(null)
    const [damagedStock, setDamagedStock] = useState(null)
    const [reservedByReturnsStock, setReservedByReturnsStock] = useState(null)
    const [expiredStock, setExpiredStock] = useState(null)

    // Returns
    const [pendingReturns, setPendingReturns] = useState(null)
    const [returningOrders, setReturningOrders] = useState(null)

    // Replenishments
    const [expectedReplenishments, setExpectedReplenishments] = useState(null)
    const [processingReplenishments, setProcessingReplenishments] = useState(null)

    const {
        isLoading: isLoading,
        // isError: isError,
        // error,
        data: statsData,
        // isFetching,
        // isPreviousData,
        refetch,
    } = useQuery(["store_stat_summary", storeId], () => getStoreStats(storeId), {
        staleTime: 1000 * 60 * 1, // after 1min, refetch if screen is focused
    })

    const {
        isLoading: isLoadingInventory,
        // isError: isError,
        // error,
        data: inventoryStatsData,
        // isFetching,
        // isPreviousData,
        refetchInventory,
    } = useQuery(["store_inventory_stats", storeId], () => getStoreInventoryStats(storeId), {
        staleTime: 1000 * 60 * 1, // after 1min, refetch if screen is focused
    })

    useEffect(() => {
        setDelayedOrders(statsData?.delayed_orders)
        setPendingOrders(statsData?.pending_orders)
        setMissingInfoOrders(statsData?.missing_info_orders)
        setPendingReturns(statsData?.pending_returns)
        setBackorderOrders(statsData?.backorder_orders)
        setReturningOrders(statsData?.returning_orders)
        setExpectedReplenishments(statsData?.expected_replenishments)
        setProcessingReplenishments(statsData?.processing_replenishments)
    }, [statsData])

    useEffect(() => {
        setProductsWithoutStock(inventoryStatsData?.out_of_stock_products)
        setDamagedStock(inventoryStatsData?.damaged_stock)
        setExpiredStock(inventoryStatsData?.expired_stock)
        setReservedByReturnsStock(inventoryStatsData?.reserved_by_returns_stock)
    }, [inventoryStatsData])

    return (
        <div className="w-full">
            <div className="mt-10 mb-5">
                <Title className="font-semibold text-black !text-xl">{t("order_stats.title")}</Title>
                <Text>{t("order_stats.subtitle")}</Text>
            </div>

            <Grid numItemsSm={1} numItemsMd={2} numItemsLg={2} className="gap-6 relative">
                <div className="flex flex-col">
                    <div className="mb-2 text-lg font-semibold">{t("home.order_stats.orders")}</div>
                    <MultipleMetricsCard
                        metrics={[
                            {
                                key: "orders_without_stock",
                                description: t("home.orders_without_stock"),
                                value: backorderOrders || 0,
                                className: buildValueTextClassName(backorderOrders, "red-600")
                            },
                            {
                                key: "missing_info",
                                description: t("home.missing_info"),
                                value: missingInfoOrders || 0,
                                className: buildValueTextClassName(missingInfoOrders, "red-600")
                            },
                            {
                                key: "delayed_orders",
                                description: t("home.delayed_orders"),
                                value: delayedOrders || 0,
                                className: buildValueTextClassName(delayedOrders, "yellow-600", "blue-600")
                            },
                            {
                                key: "pending_orders",
                                description: t("home.pending_orders"),
                                value: pendingOrders || 0,
                                className: buildValueTextClassName(pendingOrders, "gray-900", "blue-600")
                            }
                        ]}
                        showLoader={isLoading}
                    />
                </div>
                <div className="flex flex-col">
                    <div className="mb-2 text-lg font-semibold">{t("home.order_stats.inventory")}</div>
                    <MultipleMetricsCard
                        metrics={[
                            {
                                key: "products_without_stock",
                                description: t("home.products_without_stock"),
                                value: productsWithoutStock || 0,
                                className: buildValueTextClassName(productsWithoutStock, "red-600")
                            },
                            {
                                key: "damaged_stock",
                                description: t("home.damaged_stock"),
                                value: damagedStock || 0,
                                className: buildValueTextClassName(damagedStock, "yellow-600")
                            },
                            {
                                key: "reserved_by_returns_stock",
                                description: t("home.reserved_by_returns_stock"),
                                value: reservedByReturnsStock || 0,
                                className: buildValueTextClassName(reservedByReturnsStock, "yellow-600")
                            },
                            {
                                key: "expired_stock",
                                description: t("home.expired_stock"),
                                value: expiredStock || 0,
                                className: buildValueTextClassName(expiredStock, "yellow-600")
                            }
                        ]}
                        showLoader={isLoadingInventory}
                    />
                </div>
                <div className="flex flex-col">
                    <div className="mb-2 text-lg font-semibold">{t("home.order_stats.returns")}</div>
                    <MultipleMetricsCard
                        metrics={[
                            {
                                key: "returns_to_check",
                                description: t("home.returns_to_check"),
                                value: pendingReturns || 0,
                                className: buildValueTextClassName(pendingReturns, "red-600")
                            },
                            {
                                key: "returning_orders",
                                description: t("home.returning_orders"),
                                value: returningOrders || 0,
                                className: buildValueTextClassName(returningOrders, "yellow-600")
                            }
                        ]}
                        showLoader={isLoading}
                    />
                </div>
                <div className="flex flex-col">
                    <div className="mb-2 text-lg font-semibold">{t("home.order_stats.replenishments")}</div>
                    <MultipleMetricsCard
                        metrics={[
                            {
                                key: "expected_replenishments",
                                description: t("home.expected_replenishments"),
                                value: expectedReplenishments || 0,
                                className: buildValueTextClassName(expectedReplenishments, "gray-900"),
                                informationCircleText: t("home.expected_replenishments_info")
                            },
                            {
                                key: "processing_replenishments",
                                description: t("home.processing_replenishments"),
                                value: processingReplenishments || 0,
                                className: buildValueTextClassName(processingReplenishments, "gray-900")
                            }
                        ]}
                        showLoader={isLoading}
                    />
                </div>
            </Grid>
        </div>
    )
}

const buildValueTextClassName = (value, aboveZeroColor = "gray-900", zeroColor = "gray-900") => {
    if (!value) return zeroColor
    let colorClass = "text-"
    if (value > 0) {
        colorClass += aboveZeroColor
    } else {
        colorClass += zeroColor
    }
    return colorClass
}