import {
    Button,
    ConfirmDialog,
    SlidePanel
} from "../../../components";
import { useMemo,useRef } from "react"
import SelectProductDialog from "../../../components/SelectProductDialog/SelectProductDialog";
import {ProductOrderTable} from "../AddOrder/ProductOrderTable";
import {useDispatch, useSelector} from "react-redux";
import { Collapse, Radio, notification } from 'antd';
import { Tooltip } from "antd"
import {
    addProducts,
    modifyProduct,
    deleteProduct,
    orderBillingAddress,
    orderData, orderProducts,
    orderShippingAddress, resetData, setBillingAddress, setData,
    setOrderData,
    setShippingAddress
} from "../../../redux/createOrderSlice";
import {fetchOrder,CreateOrderRemoveStock,updateOrder} from "../../../services/orderServices";
import {setLogger, useQuery, useQueryClient} from "react-query";
import {useHistory, useParams,Switch,Route,useRouteMatch} from "react-router-dom";
import PropTypes from "prop-types";
import Order from "../AddOrder/Order";
import {useContext, useEffect, useState} from "react";
import {Loader} from "../../../components/Loader";
import { message } from 'antd';
import Product from "../../Inventory/AddProduct/Product";
import {UserContext} from "../../../hooks/UserContext";
import { fetchProducts, fetchSearchProducts } from "../../../services/productServices";
import { FlatfileButton } from "@flatfile/react";
import  i18n from "../../../translations/index";
import { useTranslation } from 'react-i18next';
import { buildMessageForOrderError, filterOrderErrorsByFields, OrderErrorBanner } from "../OrderErrorUtils"
import { uniqBy } from "lodash"
import { NoticeBanner } from "../../../components/NoticeBanner"
import { calculateKitPrice } from "../../../components/utils/productUtils";

const shortid = require('shortid')
const { Panel } = Collapse

const currencyOptions = [
        { id: "MXN", name: 'MXN', country: "MX"},
        { id: "COP", name: 'COP', country: "CO"},
        { id: "BRL", name: 'BRL', country: "BR"}
    ]

const primaryButton = {
    backgroundColor: "#327cfb",
    color: "white",
    border: "1px solid #00c6ff",
    padding: "6px 16px",
    fontSize: "15px",
    borderRadius: "8px",
    ":hover": {
      backgroundColor: "#0D93BA",
      border: "1px solid #0D93BA"
    }
}



export const AddRemoveStockContainer = ({edit=false, onCancel = () => {}, onSuccess}) => {

    const { t, i18n } = useTranslation();

    AddRemoveStockContainer.propTypes = {
        edit: PropTypes.bool
    }

    const {user} = useContext(UserContext)
    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])
    const queryClient = useQueryClient()
    const history = useHistory()
    const {id} = useParams()
    const [openDialogProducts, setOpenDialogProducts] = useState(false)

    const shippingAddress = useSelector(orderShippingAddress)
    const billingAddress = useSelector(orderBillingAddress)
    const order = useSelector(orderData)
    const products = useSelector(orderProducts)

    const [confirmLoading, setConfirmLoading] = useState(false)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

    const [saveDisabled, setSaveDisabled] = useState(true)

    const [deletedProducts, setDeletedProducts] = useState([])
    const [footerTotals, setFooterTotals] = useState(false)
    const [initialOrderErrors, setInitialOrderErrors] = useState([])
    const [saveOrderErrors, setSaveOrderErrors] = useState([])
    const [unexpectedErrorMessage, setUnexpectedErrorMessage] = useState(null)
    const RemoveStockCreatedSlidePanelRef = useRef(null)
    const [searchParams, setSearchParams] = useState(null)
    const [queryExtraParams, setQueryExtraParams] = useState({is_kit: false})
    const dispatch = useDispatch()
    const [allProductsLoading, setAllProductsLoading] = useState(false)
    const [allProductsLoaded, setAllProductsLoaded] = useState(false)
    const pageSize = 100

    const [orderType, setOrderType] = useState("available")

    const orderTypeOptions = [
        {label: <span className="text-sm">{t("orders.addContainer.available_stock")}</span>, value: "available"},
        {label: <span className="text-sm">{t("orders.addContainer.damaged_stock")}</span>, value: "damaged"},
        {label: <span className="text-sm">{t("orders.addContainer.expired_stock")}</span>, value: "expired"},
    ]

    const onOrderTypeChange = (value) => {
        setOrderType(value)
        setAllProductsLoaded(false)
        setQueryExtraParams({
            ...queryExtraParams,
            filter_unavailable: value === "available" ? null : value
        })
        let order_type = "REMOVE_STOCK"
        if(value == "damaged") order_type = "REMOVE_DAMAGED_STOCK" 
        else if(value == "expired") order_type = "REMOVE_EXPIRED_STOCK"
        dispatch(setOrderData({
            ...order,
            order_type: order_type
        }))
    }

    useEffect(() => {
        if(order.order_type == "SALE"){
            onOrderTypeChange("available")
        }
      }, [])

    useEffect(() => {
        if(searchParams === null ) return
        (async () => {
            const response = await fetchProducts(searchParams)
            if (response.products.length > 0) {
                const products = response.products.filter((product) => 
                    orderType === "damaged" ? product.stock?.DAMAGED > 0 : orderType === "expired" ? product.stock?.EXPIRED > 0 : product.stock?.AVAILABLE > 0
                )
                handleOnAddProduct(products)
            }
            if(response.products.length === pageSize){
              setSearchParams({
                  ...searchParams,
                  page: searchParams.page + 1,
              })
            }else{
                setAllProductsLoading(false)
                setAllProductsLoaded(true)
                setSearchParams(null)
            }
          })()
    }, [searchParams])

    const addAllProducts = async() => {
        setAllProductsLoading(true)
        setSearchParams({
            ...queryExtraParams,
            page: 1,
            include_stock: true,
            per_page: pageSize,
            store_id: user.current_store?.id,
        })
        setAllProductsLoaded(false)
    }

    const onConfirmAddOrderHandler = async () => {
        setConfirmLoading(true)
        setInitialOrderErrors([])
        setSaveOrderErrors([])
        setUnexpectedErrorMessage(null)
        let order_id = null
        
        try{
            setConfirmLoading(true)
            if(edit===false) {
                let response = await CreateOrderRemoveStock(
                    order,
                    products,
                    user.current_store?.id
                )
                order_id = response.id
                onSuccess(order.orderNumber)
            } else {
                order_id = id
                if(validStatus()){
                    await updateOrder(id, order, shippingAddress,billingAddress, [], [], [])
                }
                else{
                    await updateOrder(id, order, shippingAddress,billingAddress, Order.getProductsForUpdate(products), deletedProducts, [])
                }
                history.goBack()
            }
            await queryClient.invalidateQueries('orders')

           

        } catch (e){
            //alert("Error al guardar")
            setConfirmLoading(false)
            setOpenConfirmDialog(false)
            let responseOrderErrors = e.response?.data?.errors
            if (responseOrderErrors && Array.isArray(responseOrderErrors)) {
                setSaveOrderErrors(responseOrderErrors)
                message.error(buildMessageForOrderError(responseOrderErrors[0], t))
            }
            else if (e.response?.data?.error) {
                setUnexpectedErrorMessage(e.response?.data?.error)
                message.error(e.response?.data?.error)
            }
            console.error(e)
        }
    }

    const totalItemsOrderValue = useMemo(() => {
        let orderValue = 0
        let totalQtyValue = 0
        
        products.forEach((product) => {
            if(product.product.is_scannable){

                if(warehouse?.country === 'BR'){
                    orderValue += product.product.is_kit ? calculateKitPrice(product.product.kit_childs, product.quantity) : (product.price_per_item * product.quantity)
                } else {
                    orderValue += (product.price_per_item * product.quantity)
                }
            }
            totalQtyValue +=  parseInt(product.quantity)
        })
        setFooterTotals({
            total_count: totalQtyValue,
            total_price: warehouse.country === 'BR' ? parseFloat(orderValue) : null
        })
        // dispatch(setOrderData({
        //     ...order,
        //     total_price: orderValue
        // }))
        return orderValue
    }, [products, warehouse.country])

    const totalPrice = useMemo(() => totalItemsOrderValue, [totalItemsOrderValue])
   
    const handleOnAddProduct = (addedProducts) => {
        addedProducts = addedProducts.filter((p)=>!products.find((stateProd)=>stateProd.product_id===p.id))
        dispatch(addProducts(addedProducts.map(product => {
            let {sku, ...noSkuProduct} = product
            const default_quantity = orderType === "available" ? 1 : orderType === "damaged" ? product.stock.DAMAGED : product.stock.EXPIRED
            return {
                quantity: product.order_quantity ? parseInt(product.order_quantity) : default_quantity,
                // name: product.name,
                //name: product.parentName ? product.parentName.length > 20 ? `${product.name} (${product.parentName.substring(0, 15)}...)` : `${product.name} (${product.parentName})` : `${product.name}`,
                product_id: product.id,
                name: product.full_name,
                product: noSkuProduct,
                product_codes: product.product_codes,
                hasError: false,
                parent: product.parent,
                isKit: product.isKit,
                type: Product.getType(product.parent, product.isKit),//"kit|single|variants",//|,
                key: shortid.generate(),
                price_per_item: product.isKit ? calculateKitPrice(product.kit_childs, 1) : product.price,
                discount_per_item: 0,
                is_scannable: product.is_scannable,
            }
        })))
        setOpenDialogProducts(false)
    }

    const handleOnAddProductThroughImport = async (addedRows) => {
        

        // addedProducts = addedProducts.filter((p)=>!products.find((stateProd)=>stateProd.product_id===p.id))
        let productsToAdd = []
        let notFoundProducts = []

        let storeProducts = await fetchSearchProducts(user.current_store?.id, addedRows.map(a => a.sku))

        //console.log(storeProducts)

        addedRows.forEach((addedRow) => {
            let found = false
            storeProducts.products.forEach((storeProduct) => { 

                let foundProducts = storeProduct.product_codes.filter(product_code => product_code.sku === addedRow.sku);
                if (foundProducts.length > 0) {
                    storeProduct.order_quantity = addedRow.quantity
                    productsToAdd.push(storeProduct)
                    found = true
                }
            })

            if (!found) notFoundProducts.push(addedRow.sku)
        })

        // productsToAdd = productsToAdd.filter((p)=>!products.find((stateProd)=>stateProd.product_id===p.id))
        //console.log(productsToAdd)
        //console.log(notFoundProducts)
        handleOnAddProduct(productsToAdd)

    }


    const validStatus = () =>{
        if (order.status != "processing" &&  order.status != "picking" && order.status != "packing") {
            return false
        }
        return true
    }
    const handleUpdateQuantity = (index, id, value) => {
        if(value>=1){
            dispatch(modifyProduct({
                ...products[index],
                quantity: value,
                edited: true,
            }))
        } else {
            if(edit){
                setDeletedProducts([
                    ...deletedProducts,
                    products[index]
                    ])
            }
            if(value<1)
                dispatch(deleteProduct(products[index].key))
            setAllProductsLoaded(false)
        }
    }

    const handleUpdatePrice = (index, value) => {
        dispatch(modifyProduct({
            ...products[index],
            product:{
                ...products[index].product,
                price: value
            },
            price_per_item: value
        }))
    }

    const handleSaveOrder = () => {
        setConfirmLoading(false)
        setOpenConfirmDialog(true)
    }

    const {
        isLoading,
        isError,
        data,
        error,
        isFetchedAfterMount,
    } = useQuery(`order-${id}`, ()=>fetchOrder(id), {
        enabled: edit,
        onSuccess: (info) => {
            if(isFetchedAfterMount===false && edit){
                setEditOrderData(info)
            }
        }
    })

    useEffect(() => {
        if (products.length > 0 && order.orderNumber && order.orderNumber.trim() !== '') {
            
            setSaveDisabled(false)
            
            if(warehouse.country === 'BR') {
                products.forEach(item => {
                    if(item.price_per_item === "" || !item.price_per_item ) {
                        setSaveDisabled(true)
                    } else {
                        setSaveDisabled(false)
                    }
                })
            }
            
        }    
        else {
            setSaveDisabled(true)
        }


    }, [products,order])

    const setEditOrderData = (info) => {
        let orderLineErrors = filterOrderErrorsByFields(info?.errors, ['order_lines'])

        let products = info.order_lines.map(item => {
            let itemErrorObject = orderLineErrors.find(errorObject => errorObject.additional_info?.order_line_id === item.id)
            return {
                product_id: item.product?.id,
                id: item.id,
                name: item.name===null? item.product?.name: item.name,
                quantity: item.quantity,
                edited: false,
                product_codes: item.product ? item.product.product_codes : [],
                hasError: itemErrorObject ? true : false,
                error: buildMessageForOrderError(itemErrorObject, t, true),
                key: item.id,
                product: item.product,
                parent: item.product?.parent,
                isKit: item.product?.is_kit,
                type: Product.getType(item.product?.parent, item.product?.is_kit),
                generated: item.generated,
                price_per_item: item.price_per_item || item.product?.price
            }
        })
        products = products.filter(orderLine => !orderLine.generated)  

        let currency =  currencyOptions.find(option => option.name === info.currency) ||  currencyOptions.find(option => option.country === warehouse.country) 
        dispatch(setData({
            order: {
                id: info.id,
                orderNumber: info.order_number,
                status: info.status,
                shippingMethod: info.shipping_method == null ? {} : info.shipping_method,
                shipping_documents: info.shipping_documents || [],
                is_cod: info.is_cod || false,
                currency: currency.id,
                total_price: info.total_price,
                order_type: info.order_type
            },
            shippingAddress: {
                status: "editing",
                firstName: info.shipping.first_name || "",
                lastName: info.shipping.last_name || "",
                phone: info.shipping.phone || "",
                email: info.shipping.email || "",
                tax_id: info.billing.tax_id || "",
                company: info.billing.company || "",
                tax_regime: info.billing.tax_regime || "",
                state_registration: info.billing.state_registration || "",
                address: info.shipping.address_1 || "",
                address2: info.shipping.address_2 || "",
                number: info.shipping.number ||  "",
                neighborhood: info.shipping.neighborhood ||  "",
                country: info.shipping.country || "",
                city: info.shipping.city || "",
                province: info.shipping.state || "",
                zipCode: info.shipping.zip_code || "",
            },
            billingAddress: {
                status: "editing",
                firstName: info.billing.first_name || "",
                lastName: info.billing.last_name || "",
                email: info.shipping.email || "",
                phone: info.billing.phone || "",
                tax_id: info.billing.tax_id || "",
                company: info.billing.company || "",
                tax_regime: info.billing.tax_regime || "" ,
                state_registration: info.billing.state_registration || "",
                address: info.billing.address_1 || "",
                number: info.billing.number ||  "",
                neighborhood: info.billing.neighborhood ||  "",
                country: info.shipping.country || "",
                city: info.billing.city || "",
                province: info.billing.state || "",
                zipCode: info.billing.zip_code || "",
            },
            products: products
        }))
        if(info.order_type == "REMOVE_EXPIRED_STOCK") setOrderType("expired")
        else if(info.order_type == "REMOVE_DAMAGED_STOCK") setOrderType("damaged")
        else setOrderType("available")
        setSaveDisabled(false)
        setInitialOrderErrors(info.errors)
    }

    
    const topPageOrderErrors = useMemo( () => {
        if (!initialOrderErrors) return []
        // filter unique error codes only
        let uniqueErrors = uniqBy(initialOrderErrors, 'code')
        return uniqueErrors
    }, [initialOrderErrors])

    const bottomPageOrderErrors = useMemo( () => {
        if (!saveOrderErrors) return []
        // filter unique error codes only
        let uniqueErrors = uniqBy(saveOrderErrors, 'code')
        return uniqueErrors
    }, [saveOrderErrors])

    const deliveryType = useMemo(() => {
        if (!order.shippingMethod) return
        return order.shippingMethod.shipping_type

    }, [order])

    if(isLoading && edit){
        return <div className="mt-4"><Loader show={true} />Loading</div>
    }
    if(isError && edit){
        return <div className="mt-4">{error.message}</div>
    }



    return (
        <div className="divide-y divide-gray-200">


            {openDialogProducts && <SelectProductDialog
                open={openDialogProducts}
                setOpen={setOpenDialogProducts}
                onAddProducts={handleOnAddProduct}
                queryExtraParams={queryExtraParams}
            />}


            <ConfirmDialog
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                title= { edit == true ? i18n.t("orders.addContainer.edit_order") : i18n.t("orders.addContainer.create_order")}
                description={edit == true ? i18n.t("orders.addContainer.keep_order") : i18n.t("orders.addContainer.create_orders")}
                confirmLabel="Si"
                onConfirm={onConfirmAddOrderHandler}
                loading={confirmLoading}
            />

          


            <div className="">
                <div>
                    { topPageOrderErrors.map( error => <OrderErrorBanner key={error.code} errorObject={error} /> ) }
                </div>
                <div className="grid grid-cols-2 rounded-lg">
                    <div>
                        {edit === false ?
                            <div className="sm:col-span-6">
                                <div>
                                    <label htmlFor="company_website" className="block text-sm font-medium text-gray-700">
                                        {i18n.t("orders.addContainer.order")} <span className="text-red-500"> *</span>
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            className="flex-1 min-w-0 block w-full rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500  border-gray-300"
                                            placeholder= {i18n.t("orders.addContainer.order_number_placeholder")}
                                            onChange={(event) => {     
                                                let value = event.target.value   
                                                let re = /^[a-zA-Z0-9 ]+$/;
                                                let sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '-')
                                                dispatch(setOrderData({
                                                    ...order,
                                                    orderNumber: sanitizedValue + "-REM"
                                                }))
                                            }} 
                                            value={order.orderNumber.replace("-REM", "")}
                                            required
                                        />
                                         
                                            <Tooltip title={i18n.t("orders.addContainer.tooltip_order_number")}>
                                                <span className="cursor-pointer inline-flex items-center px-3  rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500">
                                                -REM
                                                </span>
                                            </Tooltip> 
                                        
                                    </div>
                                </div>
                            <p className="mt-2 ml-1 text-sm text-red-600">{(order.orderNumber === '' || order.orderNumber === '-REM') && i18n.t("orders.addresView.required_field")}</p>
                            </div>
                        :   <div>
                                <label htmlFor="country"
                                    className="block text-sm font-medium text-gray-700 flex justify-between">
                                    <div>{i18n.t("orders.addContainer.order")}</div>
                                </label>
                                <div className="mt-1 text-gray-400">
                                    {order.orderNumber}
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                    </div>
                </div>
                <div className="pt-4">
                    <label className="block text-sm font-medium text-gray-700">{i18n.t("orders.addContainer.stock_status_to_remove")}</label>
                </div>
                <div className="mt-2">
                    <Tooltip title={products.length > 0 ? i18n.t("orders.addContainer.rem_same_condition_products") : null}>
                        <Radio.Group onChange={ ({ target: { value } }) => onOrderTypeChange(value)} value={orderType} optionType="button" buttonStyle="solid" disabled={products.length > 0} size="large">
                            {orderTypeOptions.map((option) => (
                                <Radio.Button key={option.value} value={option.value} disabled={option.value != orderType && products.length > 0}>
                                    {option.label}
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </Tooltip>
                </div>
                <div className="pt-4 mt-8">
                    <div className="pb-3 sm:flex sm:items-center sm:justify-between">
                        <h3 className="leading-6 font-medium text-gray-900">{i18n.t("orders.addContainer.remove_products")}<span className="text-red-500"> *</span></h3>
                        <div className="mt-3 sm:mt-0 sm:ml-4">
                           
                            {!validStatus() ? (

                                <>
                                    <FlatfileButton
                                        licenseKey="8096b433-6f21-43de-ab84-c07472944581"
                                        customer={{ userId: "12345" }}
                                        settings={{
                                        type: "Import Order Lines",
                                        fields: [
                                            { label: "SKU", key: "sku", validators: [
                                                { validate: "required" },
                                                { validate: "unique" }
                                            ] },
                                            { label: i18n.t("orders.addContainer.quantity"), key: "quantity", validators: [
                                                {
                                                validate: "regex_matches",
                                                regex: "^[0-9]+$",
                                                error:
                                                i18n.t("orders.addContainer.only_numbers")
                                                }
                                            ] }
                                        ],
                                        theme: {
                                            buttons: {
                                                primary: primaryButton
                                            }
                                            // other keys below
                                        },
                                        managed: true,
                                        autoDetectHeaders: true,
                                        title: i18n.t("orders.addContainer.imports_products"),
                                        devMode: false
                                        }}
                                        onData={async (results) => {
                                        // do something with the results
                                        // use data instead of alldata for valid data
                                            await handleOnAddProductThroughImport(results.allData)
                                        return i18n.t("orders.addContainer.imported");
                                        }}
                                        render={(importer, launch) => {
                                            return <button className="bg-white mr-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50" disabled={orderType != 'available'} onClick={launch}>
                                                    <Tooltip title={orderType != 'available' ? i18n.t(`orders.addContainer.import_not_available`) : null}>
                                                        {i18n.t("orders.addContainer.import")}
                                                    </Tooltip>
                                                </button>
                                        }
                                        }
                                    />
                                    <button
                                        type="button"
                                        className="bg-white py-2 px-4 mr-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                                        onClick={() => setOpenDialogProducts(true)}
                                        disabled={allProductsLoading || allProductsLoaded}
                                    >
                                        <Tooltip title={allProductsLoading || allProductsLoaded ? i18n.t(`orders.addContainer.all_${orderType}_added`) : null}>
                                            {i18n.t("orders.addContainer.add_product")}
                                        </Tooltip>
                                    </button>
                                    <Tooltip title={allProductsLoading || allProductsLoaded ? i18n.t(`orders.addContainer.all_${orderType}_added`) : orderType == 'available' ? i18n.t(`orders.addContainer.add_all_not_available`) : null} 
                                        className={allProductsLoading || allProductsLoaded || orderType == 'available' ? 'opacity-50' : ''}>
                                        <button
                                            type="button"
                                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => addAllProducts()}
                                            disabled={allProductsLoading || allProductsLoaded || orderType == 'available'}
                                        >
                                                { i18n.t(`orders.addContainer.add_all_${orderType}_products`) }
                                        </button>
                                    </Tooltip>
                                </>
                            ):(
                                <div className="text-gray-600 pt-2">
                                <i>{i18n.t("orders.addContainer.is_being")}<b> {i18n.t("orders.addContainer.processing")} </b>{i18n.t("orders.addContainer.cannot_be_modified")}</i>
                                </div>
                            )

                            }
                        </div>
                    </div>
                    <p className="text-sm text-gray-500">{order.status != "processing" &&  order.status != "picking" && order.status != "packing" && i18n.t("orders.addContainer.product_select_remove_stock")}</p>
                    {allProductsLoading && 
                        <label className="animate-pulse my-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-100 text-base font-medium text-gray-800 sm:col-start-2 sm:text-sm">
                            {i18n.t("confirmDialog.loading")}...
                        </label>}
                    <div className={allProductsLoading ? 'blur-sm' : ''}>
                        <ProductOrderTable products={products} updateData={handleUpdateQuantity} updatePrice={handleUpdatePrice} disabled={validStatus()} shipping_type={deliveryType} footer_totals={footerTotals} hideDiscount={true}
                            emptyTableText={allProductsLoaded ? i18n.t(`orders.addContainer.no_${orderType}_products_to_add`) : null}
                        />
                    </div>

                </div>

            </div>

            { (bottomPageOrderErrors.length > 0 || unexpectedErrorMessage) &&
                <div className="mt-5 pt-2">
                    { bottomPageOrderErrors.map( error => <OrderErrorBanner key={error.code} errorObject={error} /> ) }
                    { unexpectedErrorMessage && <NoticeBanner text={unexpectedErrorMessage} className="mb-2" /> }
                </div>
            }

            <div className="pt-5 pb-5">
                <div className="flex justify-end">
                    <Button type="secondary" className="w-full" onClick={() => {
                        onCancel()
                    }}>{i18n.t("orders.addContainer.cancel")}</Button>
                    <Button className="ml-3 w-full" onClick={handleSaveOrder} disabled={saveDisabled}>{i18n.t("orders.addContainer.save")}</Button>
                </div>
            </div>

        </div>
    )
}
