import {calculateKitPrice, checkIfKitFullOfInserts} from './productUtils'

export const validatePricePerItem = (orderLines, calculateKitsPrice = false) => {
    if(!orderLines || orderLines.length <= 0) {return false}

    return orderLines.every(({price_per_item, product}) => {
        // If product does not exists
        if(!product){
            return false
        }

        const price = (product?.is_kit && calculateKitsPrice) ? calculateKitPrice(product.kit_childs, 1) : parseFloat(price_per_item)

        if(isNaN(price)) {return false}
        if(!product.is_scannable && price === 0) {return true}

        return price > 0
    })
}

export const validatePriceAndDiscountPerItem = (orderLines, ignoreDropshipping = false) => {
    if(!orderLines || orderLines.length <= 0) {return false}

    return orderLines.every(({price_per_item, discount_per_item, product}) => {
        // If product does not exists
        if(!product){
            return false
        }

        if(ignoreDropshipping && product.is_dropshipping){
            return true
        }

        const isKit = product.hasOwnProperty('isKit') ? product.isKit : product.is_kit

        // const price = isKit ? calculateKitPrice(product.kit_childs, 1) : parseFloat(price_per_item)
        const price = parseFloat(price_per_item)
        const discount = parseFloat(discount_per_item)
        const isKitFullOfInserts = isKit ? checkIfKitFullOfInserts(product.kit_childs) : false

        if(isNaN(price) || isNaN(discount)) {return false}
        if(isKit && isKitFullOfInserts && price === 0 && discount === 0) {return true}
        if(!product.is_scannable && price === 0) {return true}
        if(price === 0) {return false}

        return price >= discount
    })
}

export const fixOrderPricesForNonScannable = (orderLines) => {
    if(!orderLines || orderLines.length <= 0) {return orderLines}

    return orderLines.map(orderLine => {

        // If product does not exists
        if(!orderLine?.product){
            // If price per item is not set, set it to 0
            if(!orderLine?.price_per_item){
                orderLine.price_per_item = "0.0"
            }
        }

        // Set price to 0 if product is not scannable
        else if(!orderLine.product.is_scannable){
            orderLine.price_per_item = "0.0"
        }

        return orderLine
    })
}

export const fixOrderPrices = (orderLines) => {
    if(!orderLines || orderLines.length <= 0) {return orderLines}

    return orderLines.map(orderLine => {
        let isKit = false

        if(orderLine?.product){
            isKit = orderLine.product.hasOwnProperty('is_kit') ? orderLine.product.is_kit : orderLine.product.isKit
        }

        // If product does not exists
        if(!orderLine?.product){
            // If price per item is not set, set it to 0
            if(!orderLine?.price_per_item){
                orderLine.price_per_item = "0.0"
            }
        }

        // Set price to 0 if product is not scannable
        else if(!orderLine.product.is_scannable){
            orderLine.price_per_item = "0.0"
        }

        // Set calculated kit price
        // else if(isKit){
        //     orderLine.price_per_item = calculateKitPrice(orderLine.product.kit_childs, 1)
        // }

        return orderLine
    })
}

export const checkIfAllProductsAreNonScannable = (orderLines) => {
    return orderLines.every(orderLine => {
        // If product does not exists
        if(!orderLine?.product){
            return false
        }

        const isKit = orderLine.product.hasOwnProperty('is_kit') ? orderLine.product.is_kit : orderLine.product.isKit

        // If product is not scannable
        if(!orderLine.product.is_scannable){
            return true
        }

        // If kit
        if(isKit){
            return checkIfKitFullOfInserts(orderLine.product.kit_childs)
        }

        return false
    })
}

export const getOrderShippingNumber = (orderShippingLabels) => {
    const shippingLabels = orderShippingLabels?.filter(({label_type, status}) => (
        status.toUpperCase() === "VALID" && label_type.toUpperCase() === "SHIPPING"
    )).sort((a, b) => (
        new Date(b.created_at) - new Date(a.created_at)
    ))

    return shippingLabels?.length > 0 ? shippingLabels[0].shipping_number : null
}

export const STILL_NOT_SHIPPED_SHIPMENT_STATUSES = {
    STATUS_SHIPMENT_CREATED: 'shipment_created',
    STATUS_SHIPMENT_NOT_DEFINED: 'shipment_not_defined',
}