import { useQuery } from "react-query"
import { MetricPercentage } from "../../../../components/MetricsCards/MetricPercentage"
import { getOrdersPackedOnTimeTotal } from "../../../../services/storeMetricsService"
import { useMemo } from "react"
import { numberToString } from "../../../../helpers/internationalization"
import { useTranslation } from "react-i18next"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"
import { DEFAULT_QUERY_STALE_TIME } from "../chartDataUtils"

export const OrdersPackedOnTime = ({ storeId = null, dateFilter = null }) => {
    const { i18n, t } = useTranslation()

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["orders_packed_on_time_total", storeId, dateFilter], () => getOrdersPackedOnTimeTotal(storeId, dateFilter), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refech if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    const ordersPackedOnTime = useMemo(() => {
        if (!data) return 0
        const { sla_order_to_ship_met } = data
        return sla_order_to_ship_met
    }, [data])

    const ordersNotPackedOnTime = useMemo(() => {
        if (!data) return 0
        const { sla_order_to_ship_not_met } = data
        return sla_order_to_ship_not_met
    }, [data])

    const totalOrders = useMemo(() => {
        const total = ordersPackedOnTime + ordersNotPackedOnTime
        return total
    }, [ordersPackedOnTime, ordersNotPackedOnTime])

    return (
        <div>
            <MetricTitle title={t("metrics.packed_on_time.title")} className={"mb-1"}/>
            <MetricPercentage
                value={ordersPackedOnTime}
                total={totalOrders}
                // tooltip={t("metrics.packed_on_time.subtitle")}
                showLoader={isLoading}
                valueFormatter={(n) => numberToString(n, i18n.language)}
                totalLabel={t("metrics.generic.shipped")}
            />
        </div>
    )
}
