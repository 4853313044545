export const AVAILABLE_RULE_STATEMENTS = {
    ALL_ORDERS: "ALL_ORDERS",
    CHANNEL_TYPE_NAME: "CHANNEL_TYPE_NAME",
    IS_FIRST_TIME_BUYER: "IS_FIRST_TIME_BUYER",
    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY: "SPECIFIED_PRODUCT_WITH_MIN_QUANTITY",
    SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY: "SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY",
    FOR_EVERY_PRODUCT: "FOR_EVERY_PRODUCT",
    FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: "FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT",
    NOT_INCLUDING_SPECIFIED_PRODUCT: "NOT_INCLUDING_SPECIFIED_PRODUCT",
    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: "SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT",
    SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE: "SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE",
    SPECIFIED_PRODUCT_EXACT_QUANTITY: "SPECIFIED_PRODUCT_EXACT_QUANTITY",
    CUSTOM_STATEMENT: "CUSTOM_STATEMENT",
    FRAUD_ORDER_DETECTED: "FRAUD_ORDER_DETECTED",
    HAS_FRAGILE_ITEMS: "HAS_FRAGILE_ITEMS"
}

export const AVAILABLE_RULE_STATEMENTS_FOR_ADD_SHIPPING_PACKAGE_OPTION = {
    ALL_ORDERS: "ALL_ORDERS",
    CHANNEL_TYPE_NAME: "CHANNEL_TYPE_NAME",
    IS_FIRST_TIME_BUYER: "IS_FIRST_TIME_BUYER",
    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY: "SPECIFIED_PRODUCT_WITH_MIN_QUANTITY",
    SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY: "SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY",
    FOR_EVERY_PRODUCT: "FOR_EVERY_PRODUCT",
    FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: "FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT",
    NOT_INCLUDING_SPECIFIED_PRODUCT: "NOT_INCLUDING_SPECIFIED_PRODUCT",
    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: "SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT",
    SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE: "SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE",
    SPECIFIED_PRODUCT_EXACT_QUANTITY: "SPECIFIED_PRODUCT_EXACT_QUANTITY",
    HAS_FRAGILE_ITEMS: "HAS_FRAGILE_ITEMS",
    CUSTOM_STATEMENT: "CUSTOM_STATEMENT"
}

export const AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_ADD_SHIPPING_PACKAGE_OPTION = {
    CHANNEL_TYPE_NAME: "CHANNEL_TYPE_NAME",
    IS_FIRST_TIME_BUYER: "IS_FIRST_TIME_BUYER",
    HAS_FRAGILE_ITEMS: "HAS_FRAGILE_ITEMS",
    CUSTOM_STATEMENT: "CUSTOM_STATEMENT"
}

export const AVAILABLE_RULE_STATEMENTS_FOR_ADD_PRODUCT_OPTION = {
    ALL_ORDERS: "ALL_ORDERS",
    CHANNEL_TYPE_NAME: "CHANNEL_TYPE_NAME",
    IS_FIRST_TIME_BUYER: "IS_FIRST_TIME_BUYER",
    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY: "SPECIFIED_PRODUCT_WITH_MIN_QUANTITY",
    SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY: "SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY",
    FOR_EVERY_PRODUCT: "FOR_EVERY_PRODUCT",
    FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: "FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT",
    NOT_INCLUDING_SPECIFIED_PRODUCT: "NOT_INCLUDING_SPECIFIED_PRODUCT",
    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: "SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT",
    SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE: "SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE",
    SPECIFIED_PRODUCT_EXACT_QUANTITY: "SPECIFIED_PRODUCT_EXACT_QUANTITY",
    HAS_FRAGILE_ITEMS: "HAS_FRAGILE_ITEMS",
    CUSTOM_STATEMENT: "CUSTOM_STATEMENT"
}

export const AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_ADD_PRODUCT_OPTION = {
    CHANNEL_TYPE_NAME: "CHANNEL_TYPE_NAME",
    IS_FIRST_TIME_BUYER: "IS_FIRST_TIME_BUYER",
    HAS_FRAGILE_ITEMS: "HAS_FRAGILE_ITEMS",
    CUSTOM_STATEMENT: "CUSTOM_STATEMENT"
}

export const AVAILABLE_RULE_STATEMENTS_FOR_ADD_INSERT_OPTION = {
    ALL_ORDERS: "ALL_ORDERS",
    CHANNEL_TYPE_NAME: "CHANNEL_TYPE_NAME",
    IS_FIRST_TIME_BUYER: "IS_FIRST_TIME_BUYER",
    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY: "SPECIFIED_PRODUCT_WITH_MIN_QUANTITY",
    SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY: "SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY",
    FOR_EVERY_PRODUCT: "FOR_EVERY_PRODUCT",
    FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: "FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT",
    NOT_INCLUDING_SPECIFIED_PRODUCT: "NOT_INCLUDING_SPECIFIED_PRODUCT",
    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: "SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT",
    SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE: "SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE",
    SPECIFIED_PRODUCT_EXACT_QUANTITY: "SPECIFIED_PRODUCT_EXACT_QUANTITY",
    HAS_FRAGILE_ITEMS: "HAS_FRAGILE_ITEMS",
    CUSTOM_STATEMENT: "CUSTOM_STATEMENT"
}

export const AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_ADD_INSERT_OPTION = {
    CHANNEL_TYPE_NAME: "CHANNEL_TYPE_NAME",
    IS_FIRST_TIME_BUYER: "IS_FIRST_TIME_BUYER",
    HAS_FRAGILE_ITEMS: "HAS_FRAGILE_ITEMS",
    CUSTOM_STATEMENT: "CUSTOM_STATEMENT"
}

export const AVAILABLE_RULE_STATEMENTS_FOR_CANCEL_ORDER_OPTION = {
    ALL_ORDERS: "ALL_ORDERS",
    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY: "SPECIFIED_PRODUCT_WITH_MIN_QUANTITY",
    FRAUD_ORDER_DETECTED: "FRAUD_ORDER_DETECTED",
    CUSTOM_STATEMENT: "CUSTOM_STATEMENT"
}

export const AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_CANCEL_ORDER_OPTION = {
    FRAUD_ORDER_DETECTED: "FRAUD_ORDER_DETECTED",
    CUSTOM_STATEMENT: "CUSTOM_STATEMENT"
}

export const AVAILABLE_RULE_STATEMENTS_FOR_PAUSE_ORDER_OPTION = {
    ALL_ORDERS: "ALL_ORDERS",
    CHANNEL_TYPE_NAME: "CHANNEL_TYPE_NAME",
    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY: "SPECIFIED_PRODUCT_WITH_MIN_QUANTITY",
    FRAUD_ORDER_DETECTED: "FRAUD_ORDER_DETECTED",
    CUSTOM_STATEMENT: "CUSTOM_STATEMENT"
}

export const AVAILABLE_RULE_STATEMENTS_FOR_ADDITIONAL_PAUSE_ORDER_OPTION = {
    CHANNEL_TYPE_NAME: "CHANNEL_TYPE_NAME",
    FRAUD_ORDER_DETECTED: "FRAUD_ORDER_DETECTED",
    CUSTOM_STATEMENT: "CUSTOM_STATEMENT"
}

export const CUSTOM_STATEMENT_VALIDATIONS = {
    INCLUDE_PRODUCT: "INCLUDE_PRODUCT",
    NOT_INCLUDE_PRODUCT: "NOT_INCLUDE_PRODUCT"
}

export const CUSTOM_STATEMENT_QUANTITIES = {
    MINIMUM: "MINIMUM",
    MAXIMUM: "MAXIMUM",
    MINIMUM_AND_MAXIMUM: "MINIMUM_AND_MAXIMUM",
    EXACTLY: "EXACTLY"
}

export const CONDITION_TYPES = {
    AND: "AND",
    OR: "OR"
}

export const CONDITION_VALIDATION_TYPES = {
    VALIDATE_PRODUCT: "VALIDATE_PRODUCT",
    FOR_EVERY_PRODUCT: "FOR_EVERY_PRODUCT",
    CHANNEL_TYPE_NAME: "CHANNEL_TYPE_NAME",
    IS_POTENTIAL_FRAUD: "IS_POTENTIAL_FRAUD",
    IS_FIRST_TIME_BUYER: "IS_FIRST_TIME_BUYER",
    HAS_FRAGILE_ITEMS: "HAS_FRAGILE_ITEMS"
}

export const DEFAULT_STATEMENT_CONDITION_CONTENT = {
    conditionType: CONDITION_TYPES.AND,
    validationType: CONDITION_VALIDATION_TYPES.VALIDATE_PRODUCT,
    templateStatementOption: AVAILABLE_RULE_STATEMENTS.CUSTOM_STATEMENT,

    products: null,
    anyProduct: false,
    minQuantity: null,
    maxQuantity: null,
    exactQuantity: null,
    exclusive: false,
    selectedValidationOption: CUSTOM_STATEMENT_VALIDATIONS.INCLUDE_PRODUCT,
    selectedQuantityOption: CUSTOM_STATEMENT_QUANTITIES.MINIMUM,
    isCompleted: false,
    isEditing: false,
    isEditable: true,

    forEveryProduct: null,
    maxMultipliedQuantity: null,
    for_every_quantity: null,
    withoutMaxMultipliedQuantity: true
}
