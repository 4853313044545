import { useEffect, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Tooltip } from "antd"

import { RETURN_BY_CARRIER_LABEL_SERVICES, RETURN_REPLENISHMENT_SERVICES, AVERAGE_SERVICE_GROUPS, AVERAGE_RETURN_TYPES } from "./constants"
import { formatMoney } from "../../helpers/internationalization"
import { Skeleton } from "antd"

const defaultAverages = {
    pickAndPack: 0,
    package: 0,
    shipping: 0,
    extendedZone: 0,
    carrierReturns: 0,
    simpleReturns: 0,
    shippingInsurance: 0,
    difal: 0,

    orderAverage: 0,
    additionalAverage: 0,
    orderAverageWithAdditional: 0
}

const StoreBillingAverages = ({isLoading = false, data}) => {

    const { i18n } = useTranslation()
    const [d2cAverages, setD2cAverages] = useState({...defaultAverages})
    const [marketplaceAverages, setMarketplaceAverages] = useState({...defaultAverages})

    useEffect(() => {
        const newD2cAverages = {...defaultAverages}
        const newMarketplaceAverages = {...defaultAverages}

        const calculateTotal = (usageTotals, serviceTypes) => {
            let filteredUsageTotals = !usageTotals ? [] : usageTotals.filter(usageTotal => (
                !usageTotal.is_credit && (!serviceTypes || serviceTypes.some(serviceType => usageTotal.service_type === serviceType)) ? true : false
            ))
            return filteredUsageTotals.reduce((accumulator, usageTotal) => accumulator + parseFloat(usageTotal.sum_billed_total_price), 0)
        }

        const calculateShippingTotal =  (usageTotals) => {
            const notReturnByCarrierUsageTotals = usageTotals?.filter(usageTotal => !usageTotal.is_return_by_carrier)
            return calculateTotal(notReturnByCarrierUsageTotals, AVERAGE_SERVICE_GROUPS.shipping)
        }

        const calculateReturnsTotal = (usageTotals, calculateForCarrierReturn) => {
            const filteredUsageTotals = usageTotals?.filter(usageTotal => {
                const isCarrierReturnLabelService = Object.values(RETURN_BY_CARRIER_LABEL_SERVICES).some(serviceType => (
                    serviceType === usageTotal.service_type
                ))
                const isReturnReplenishmentService = Object.values(RETURN_REPLENISHMENT_SERVICES).some(serviceType => (
                    serviceType === usageTotal.service_type
                ))

                if(isCarrierReturnLabelService){
                    return calculateForCarrierReturn && usageTotal.is_return_by_carrier ? true : false
                }
                if(isReturnReplenishmentService){
                    if(calculateForCarrierReturn && usageTotal.return_type === AVERAGE_RETURN_TYPES.RETURNED_BY_CARRIER){ return true }
                    if(!calculateForCarrierReturn && usageTotal.return_type && usageTotal.return_type !== AVERAGE_RETURN_TYPES.RETURNED_BY_CARRIER){ return true }

                    return false
                }

                return true
            })

            const serviceTypes = calculateForCarrierReturn ? AVERAGE_SERVICE_GROUPS.carrierReturn : AVERAGE_SERVICE_GROUPS.simpleReturn
            return calculateTotal(filteredUsageTotals, serviceTypes)
        }

        const d2cUsages = data?.service_usage_totals.d2c
        const marketplaceUsages = data?.service_usage_totals.marketplace


        const d2cTotals = {
            pickAndPack: calculateTotal(d2cUsages, AVERAGE_SERVICE_GROUPS.pickAndPack),
            package: calculateTotal(d2cUsages, AVERAGE_SERVICE_GROUPS.package),
            shipping: calculateShippingTotal(d2cUsages),
            extendedZone: calculateTotal(d2cUsages, AVERAGE_SERVICE_GROUPS.extendedZone),
            carrierReturns: calculateReturnsTotal(d2cUsages, true),
            simpleReturns: calculateReturnsTotal(d2cUsages, false),
            shippingInsurance: calculateTotal(d2cUsages, AVERAGE_SERVICE_GROUPS.shippingInsurance),
            difal: calculateTotal(d2cUsages, AVERAGE_SERVICE_GROUPS.difal)
        }

        const marketplaceTotals = {
            pickAndPack: calculateTotal(marketplaceUsages, AVERAGE_SERVICE_GROUPS.pickAndPack),
            package: calculateTotal(marketplaceUsages, AVERAGE_SERVICE_GROUPS.package),
            shipping: calculateShippingTotal(marketplaceUsages),
            extendedZone: calculateTotal(marketplaceUsages, AVERAGE_SERVICE_GROUPS.extendedZone),
            carrierReturns: calculateReturnsTotal(marketplaceUsages, true),
            simpleReturns: calculateReturnsTotal(marketplaceUsages, false),
            shippingInsurance: calculateTotal(marketplaceUsages, AVERAGE_SERVICE_GROUPS.shippingInsurance),
            difal: calculateTotal(marketplaceUsages, AVERAGE_SERVICE_GROUPS.difal)
        }

        // Shipped orders quantity
        const d2cShippedOrders = !data ? 0 : parseFloat(data.shipped_orders_quantity.d2c)
        const marketplaceShippedOrders = !data ? 0 : parseFloat(data.shipped_orders_quantity.marketplace)

        // Picked orders quantity
        const d2cPickedOrders = !data ? 0 : parseFloat(data.picked_orders_quantity.d2c)
        const marketplacePickedOrders = !data ? 0 : parseFloat(data.picked_orders_quantity.marketplace)

        // D2C averages
        if(d2cShippedOrders !== 0){
            newD2cAverages.package = d2cTotals.package / d2cShippedOrders
            newD2cAverages.shipping = d2cTotals.shipping / d2cShippedOrders
            newD2cAverages.extendedZone = d2cTotals.extendedZone / d2cShippedOrders
            newD2cAverages.carrierReturns = d2cTotals.carrierReturns / d2cShippedOrders
            newD2cAverages.simpleReturns = d2cTotals.simpleReturns / d2cShippedOrders
            newD2cAverages.difal = d2cTotals.difal / d2cShippedOrders
            newD2cAverages.shippingInsurance = d2cTotals.shippingInsurance / d2cShippedOrders
        }
        if(d2cPickedOrders !== 0){
            newD2cAverages.pickAndPack = d2cTotals.pickAndPack / d2cPickedOrders
        }

        // Marketplace averages
        if(marketplaceShippedOrders !== 0){
            newMarketplaceAverages.package = marketplaceTotals.package / marketplaceShippedOrders
            newMarketplaceAverages.shipping = marketplaceTotals.shipping / marketplaceShippedOrders
            newMarketplaceAverages.extendedZone = marketplaceTotals.extendedZone / marketplaceShippedOrders
            newMarketplaceAverages.carrierReturns = marketplaceTotals.carrierReturns / marketplaceShippedOrders
            newMarketplaceAverages.simpleReturns = marketplaceTotals.simpleReturns / marketplaceShippedOrders
            newMarketplaceAverages.difal = marketplaceTotals.difal / marketplaceShippedOrders
            newMarketplaceAverages.shippingInsurance = marketplaceTotals.shippingInsurance / marketplaceShippedOrders
        }
        if(marketplacePickedOrders !== 0){
            newMarketplaceAverages.pickAndPack = marketplaceTotals.pickAndPack / marketplacePickedOrders
        }

        // D2C order average
        newD2cAverages.orderAverage = [
            newD2cAverages.pickAndPack,
            newD2cAverages.package,
            newD2cAverages.shipping,
            newD2cAverages.difal,
            newD2cAverages.shippingInsurance
        ].reduce((accumulator, currentAverage) => accumulator + currentAverage, 0)

        newD2cAverages.additionalAverage = [
            newD2cAverages.extendedZone,
            newD2cAverages.carrierReturns,
            newD2cAverages.simpleReturns
        ].reduce((accumulator, currentAverage) => accumulator + currentAverage, 0)

        newD2cAverages.orderAverageWithAdditional = newD2cAverages.orderAverage + newD2cAverages.additionalAverage

        // Marketplace order average
        newMarketplaceAverages.orderAverage = [
            newMarketplaceAverages.pickAndPack,
            newMarketplaceAverages.package,
            newMarketplaceAverages.shipping,
            newMarketplaceAverages.difal,
            newMarketplaceAverages.shippingInsurance
        ].reduce((accumulator, currentAverage) => accumulator + currentAverage, 0)

        newMarketplaceAverages.additionalAverage = [
            newMarketplaceAverages.extendedZone,
            newMarketplaceAverages.carrierReturns,
            newMarketplaceAverages.simpleReturns
        ].reduce((accumulator, currentAverage) => accumulator + currentAverage, 0)

        newMarketplaceAverages.orderAverageWithAdditional = newMarketplaceAverages.orderAverage + newMarketplaceAverages.additionalAverage

        setD2cAverages(newD2cAverages)
        setMarketplaceAverages(newMarketplaceAverages)

    }, [data])

    const currency = data ? data.currency : ""

    const currencyCode = useMemo(() => {
        return (i18n.language === "es" && currency  === "MXN") ? i18n.t(`billing.currencies.${currency}`) : ""
    }, [data, i18n.language])

    const formatMoneyAverage = (averageMoney) => {
        const minimumFractionDigits = 2
        const maximumFractionDigits = 2
        const removeFractionOnZero = true

        return formatMoney(averageMoney, i18n.language, currency, minimumFractionDigits, maximumFractionDigits, removeFractionOnZero)
    }

    return (
        <>
            <div className="font-bold">
                {currency === "BRL" ? i18n.t("billing.averages.average_costs_per_order_without_taxes") : i18n.t("billing.averages.average_costs_per_order")}
            </div>

            {isLoading ?
                <div className="bg-white p-5 flex rounded-lg w-1/2 justify-between">
                    <div className="py-2 pl-5 pr-10 flex-1">
                        <div className="text-gray-500">
                            <div>{i18n.t("billing.averages.order_fulfillment")}</div>
                            <Skeleton paragraph={{ rows: 2 }} active={true} />
                        </div>
                    </div>

                    <div className="bg-gray-200 w-0.5">&nbsp;</div>

                    <div className="py-2 px-5 flex-1 rounded-lg text-sm">
                        <div className="pl-5">
                            <Skeleton title={false} paragraph={{ rows: 6 }} active={true} />
                        </div>
                    </div>
                </div>
            :
            <>
                <div className="flex flex-row gap-5">
                    <div className="bg-white flex rounded-lg w-1/2 justify-between">
                        <div className="px-5 flex-1 m-5">
                            <div className="text-gray-500">
                                <div><Trans i18nKey={'billing.averages.dtc_orders'}/></div>
                                <div className="mt-4 mb-3">
                                    <Tooltip title={i18n.t("billing.averages.order_average_description")}>
                                        <span className="font-semibold mt-4 mb-1 text-3xl text-black underline underline-offset-4 decoration-1 decoration-dashed cursor-help">
                                            {formatMoneyAverage(d2cAverages.orderAverage)} {currencyCode}
                                        </span>
                                    </Tooltip>
                                </div>
                                <div className="text-lg mb-3">
                                    <Tooltip title={i18n.t("billing.averages.order_average_description")}>
                                        <span className="underline underline-offset-4 decoration-1 decoration-dashed cursor-help">
                                            {`${formatMoneyAverage(d2cAverages.orderAverageWithAdditional)} ${currencyCode}`}
                                        </span>
                                    </Tooltip>
                                    {` ${i18n.t("billing.averages.average_with_additional_services")}`}
                                </div>
                            </div>
                        </div>

                        <div className="bg-gray-200 w-0.5 m-5">&nbsp;</div>

                        <div className="py-2 px-5 flex-1 rounded-lg text-md text-gray-500 m-2">
                            <div className="px-5">
                                {d2cAverages.pickAndPack > 0 && (
                                    <div className="flex justify-between mb-1">
                                        <Tooltip title={i18n.t("billing.averages.averages_descriptions.pick_and_pack")}>
                                            <div className="underline underline-offset-4 decoration-1 decoration-dashed cursor-help">
                                                {i18n.t("billing.averages.pick_and_pack")}
                                            </div>
                                        </Tooltip>
                                        <div>{formatMoneyAverage(d2cAverages.pickAndPack)}</div>
                                    </div>
                                )}
                                {d2cAverages.package > 0 && (
                                    <div className="flex justify-between mb-1">
                                        <Tooltip title={i18n.t("billing.averages.averages_descriptions.package")}>
                                            <div className="underline underline-offset-4 decoration-1 decoration-dashed cursor-help">
                                                {i18n.t("billing.averages.package")}
                                            </div>
                                        </Tooltip>
                                        <div>{formatMoneyAverage(d2cAverages.package)}</div>
                                    </div>
                                )}
                                {d2cAverages.shipping > 0 && (
                                    <div className="flex justify-between mb-1">
                                        <div>
                                            {i18n.t("billing.averages.shipping")}
                                        </div>
                                        <div>{formatMoneyAverage(d2cAverages.shipping)}</div>
                                    </div>
                                )}
                                {d2cAverages.shippingInsurance > 0 && (
                                    <div className="flex justify-between mb-1">
                                        <div>
                                            {i18n.t("billing.averages.shipping_insurance")}
                                        </div>
                                        <div>{formatMoneyAverage(d2cAverages.shippingInsurance)}</div>
                                    </div>
                                )}
                                {(d2cAverages.difal + d2cAverages.extendedZone + d2cAverages.carrierReturns + d2cAverages.simpleReturns) > 0 && (
                                    <>
                                    <div className="flex justify-between mb-1 mt-6 font-semibold">
                                        {i18n.t("billing.averages.additional_services")}
                                    </div>
                                    {d2cAverages.difal > 0 && (
                                        <div className="flex justify-between mb-1">
                                            <div>
                                                {i18n.t("billing.averages.difal")}
                                            </div>
                                            <div>{formatMoneyAverage(d2cAverages.difal)}</div>
                                        </div>
                                    )}
                                    {d2cAverages.extendedZone > 0 && (
                                        <div className="flex justify-between mb-1">
                                            <div>
                                                {i18n.t("billing.averages.extended_zone")}
                                            </div>
                                            <div>{formatMoneyAverage(d2cAverages.extendedZone)}</div>
                                        </div>
                                    )}
                                    {d2cAverages.carrierReturns > 0 && (
                                        <div className="flex justify-between mb-1">
                                            <Tooltip title={i18n.t("billing.averages.averages_descriptions.carrier_returns")}>
                                                <div className="underline underline-offset-4 decoration-1 decoration-dashed cursor-help">
                                                    {i18n.t("billing.averages.carrier_returns")}
                                                </div>
                                            </Tooltip>
                                            <div>{formatMoneyAverage(d2cAverages.carrierReturns)}</div>
                                        </div>
                                    )}
                                    {d2cAverages.simpleReturns > 0 && (
                                        <div className="flex justify-between mb-1">
                                            <Tooltip title={i18n.t("billing.averages.averages_descriptions.simple_returns")}>
                                                <div className="underline underline-offset-4 decoration-1 decoration-dashed cursor-help">
                                                    {i18n.t("billing.averages.simple_returns")}
                                                </div>
                                            </Tooltip>
                                            <div>{formatMoneyAverage(d2cAverages.simpleReturns)}</div>
                                        </div>
                                    )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {(data?.picked_orders_quantity?.marketplace > 0 || data?.shipped_orders_quantity?.marketplace > 0) &&
                        <div className="bg-white flex rounded-lg w-1/2 justify-between">
                            <div className="px-5 flex-1 m-5">
                                <div className="text-gray-500">
                                    <div><Trans i18nKey={'billing.averages.marketplace_orders'}/></div>
                                    <div className="mt-4 mb-3">
                                        <Tooltip title={i18n.t("billing.averages.order_average_description")}>
                                            <span className="font-semibold text-3xl text-black underline underline-offset-4 decoration-1 decoration-dashed cursor-help">
                                                {`${formatMoneyAverage(marketplaceAverages.orderAverage)} ${currencyCode}`}
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <div className="text-lg mb-3">
                                        <Tooltip title={i18n.t("billing.averages.order_average_description")}>
                                            <span className="underline underline-offset-4 decoration-1 decoration-dashed cursor-help">
                                                {`${formatMoneyAverage(marketplaceAverages.orderAverageWithAdditional)} ${currencyCode}`}
                                            </span>
                                        </Tooltip>
                                        {` ${i18n.t("billing.averages.average_with_additional_services")}`}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-gray-200 w-0.5 m-5">&nbsp;</div>

                            <div className="py-2 px-5 flex-1 rounded-lg text-md text-gray-500 m-2">
                                <div className="px-5">
                                    {marketplaceAverages?.pickAndPack > 0 && (
                                        <div className="flex justify-between mb-1">
                                            <Tooltip title={i18n.t("billing.averages.averages_descriptions.pick_and_pack")}>
                                                <div className="underline underline-offset-4 decoration-1 decoration-dashed cursor-help">
                                                    {i18n.t("billing.averages.pick_and_pack")}
                                                </div>
                                            </Tooltip>
                                            <div>{formatMoneyAverage(marketplaceAverages.pickAndPack)}</div>
                                        </div>
                                    )}
                                    {marketplaceAverages?.package > 0 && (
                                        <div className="flex justify-between mb-1">
                                            <Tooltip title={i18n.t("billing.averages.averages_descriptions.package")}>
                                                <div className="underline underline-offset-4 decoration-1 decoration-dashed cursor-help">
                                                    {i18n.t("billing.averages.package")}
                                                </div>
                                            </Tooltip>
                                            <div>{formatMoneyAverage(marketplaceAverages.package)}</div>
                                        </div>
                                    )}
                                    {marketplaceAverages?.shipping > 0 && (
                                        <div className="flex justify-between mb-1">
                                            <div>
                                                {i18n.t("billing.averages.shipping")}
                                            </div>
                                            <div>{formatMoneyAverage(marketplaceAverages.shipping)}</div>
                                        </div>
                                    )}
                                    {marketplaceAverages?.shippingInsurance > 0 && (
                                        <div className="flex justify-between mb-1">
                                            <div>
                                                {i18n.t("billing.averages.shipping_insurance")}
                                            </div>
                                            <div>{formatMoneyAverage(marketplaceAverages.shippingInsurance)}</div>
                                        </div>
                                    )}
                                    {(marketplaceAverages.difal + marketplaceAverages.extendedZone + marketplaceAverages.carrierReturns + marketplaceAverages.simpleReturns) > 0 && (
                                    <>
                                    <div className="flex justify-between mb-1 mt-6 font-semibold">
                                        {i18n.t("billing.averages.additional_services")}
                                    </div>
                                    {marketplaceAverages?.difal > 0 && (
                                        <div className="flex justify-between mb-1">
                                            <div>
                                                {i18n.t("billing.averages.difal")}
                                            </div>
                                            <div>{formatMoneyAverage(marketplaceAverages.difal)}</div>
                                        </div>
                                    )}
                                    {marketplaceAverages?.extendedZone > 0 && (
                                        <div className="flex justify-between mb-1">
                                            <div>
                                                {i18n.t("billing.averages.extended_zone")}
                                            </div>
                                            <div>{formatMoneyAverage(marketplaceAverages.extendedZone)}</div>
                                        </div>
                                    )}
                                    {marketplaceAverages?.carrierReturns > 0 && (
                                        <div className="flex justify-between mb-1">
                                            <Tooltip title={i18n.t("billing.averages.averages_descriptions.carrier_returns")}>
                                                <div className="underline underline-offset-4 decoration-1 decoration-dashed cursor-help">
                                                    {i18n.t("billing.averages.carrier_returns")}
                                                </div>
                                            </Tooltip>
                                            <div>{formatMoneyAverage(marketplaceAverages.carrierReturns)}</div>
                                        </div>
                                    )}
                                    {marketplaceAverages?.simpleReturns > 0 && (
                                        <div className="flex justify-between mb-1">
                                            <Tooltip title={i18n.t("billing.averages.averages_descriptions.simple_returns")}>
                                                <div className="underline underline-offset-4 decoration-1 decoration-dashed cursor-help">
                                                    {i18n.t("billing.averages.simple_returns")}
                                                </div>
                                            </Tooltip>
                                            <div>{formatMoneyAverage(marketplaceAverages.simpleReturns)}</div>
                                        </div>
                                    )}
                                    </>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>}
        </>
    )
}

export default StoreBillingAverages