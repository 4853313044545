import PackingOption from "./PackingOption";
import PackingOptionCheckbox from "./PackingOptionCheckbox";

const PackingOptionsList = ({ headerComponent, packingOptionsList, packingOptionsStatuses, onOptionChange, packingType, customStyles=null, fallbackValue=false, enableNonAvailableStyle=true }) => {
    return (
        <div>
            {headerComponent && <div className="border rounded-t border-b-0">{headerComponent}</div>}

            <div className={`border rounded-lg ${headerComponent ? "rounded-t-none" : ""} ${customStyles ? customStyles : ""}`}>
                {packingOptionsList.map((option, index, { length }) => {
                    const optionKey = `${packingType}-${option.type}`
                    const isOptionAvailable = packingOptionsStatuses?.hasOwnProperty(option.type)
                    const isOptionDisabled = option.isDisabled || !isOptionAvailable

                    return (
                        <div key={optionKey} className={`relative p-5 ${index > 0 && index < length ? "border-t" : ""}`}>
                            {!isOptionAvailable && enableNonAvailableStyle && <div className="absolute inset-0 bg-gray-100 bg-opacity-50"></div>}

                            <label className="flex items-center">

                                <PackingOptionCheckbox
                                    checkboxValue={optionKey}
                                    isChecked={isOptionAvailable ? packingOptionsStatuses[option.type] : fallbackValue}
                                    onChangeStatus={onOptionChange}
                                    isDisabled={isOptionDisabled}
                                    tooltipText={isOptionDisabled ? option.tooltipText : null}
                                />

                                <PackingOption title={option.title} description={option.description} image={option.image} />

                                {option.nonAvailableText && !isOptionAvailable && enableNonAvailableStyle &&
                                    <span className="absolute right-0 mr-5 font-medium">{option.nonAvailableText}</span>
                                }
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default PackingOptionsList
