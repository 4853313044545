import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { LeftCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Select } from "antd"
import { StopIcon } from '@heroicons/react/outline'
import { useHistory } from "react-router-dom"

import { getConfigurations, updateConfigurations } from "../../services/storeServices"
import { UserContext } from "../../hooks/UserContext"
import { Button, Notification } from "../../components"
import { Loader } from "../../components/Loader"
import { MAIL_REGEX } from "./constants"
import { STORE_PATH, SETTINGS_AUTOMATIC_REPORTS } from "../../navigation/constants"

const StatementEmailRecipients = ({onCancel}) => {
    const { user } = useContext(UserContext)
    const { i18n } = useTranslation()
    const history = useHistory()

    const [loadErrorNotification, setLoadErrorNotification] = useState(false)
    const [errorNotification, setErrorNotification] = useState(false)
    const [successNotification, setSuccessNotification] = useState(false)
    const [updatedEmailRecipients, setUpdatedEmailRecipients] = useState([])

    const {
        isLoading: emailRecipientsIsLoading,
        isError: emailRecipientsIsError,
        data: emailRecipientsData
    } = useQuery(['billing_statement_email_recipients', user.current_store?.id, "billing_statement_email_recipients"], () => getConfigurations(user.current_store?.id, "billing_statement_email_recipients"), {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        const currentEmailRecipientsData = emailRecipientsData ? emailRecipientsData : []
        setUpdatedEmailRecipients(currentEmailRecipientsData)
    }, [emailRecipientsData])

    useEffect(() => {
        if(emailRecipientsIsError){
            setLoadErrorNotification(true)
        }
    }, [emailRecipientsIsError])

    const handleOnChangeEmailRecipients = (emailRecipients) => {
        const validEmailRecipients = emailRecipients?.filter(email => MAIL_REGEX.test(email))
        const newEmailRecipients = validEmailRecipients ? validEmailRecipients : []
        setUpdatedEmailRecipients(newEmailRecipients)
    }

    const handleOnCancel = () => {
        setUpdatedEmailRecipients(emailRecipientsData)
    }

    const handleOnSave = async () => {
        try{
            await updateConfigurations(user.current_store?.id, {
                billing_statement_email_recipients: updatedEmailRecipients
            })
            setSuccessNotification(true)
        } catch (error){
            setErrorNotification(true)
        }
    }

    const handleOnShowAutomaticReports = () => {
        let automaticReportsPath = (STORE_PATH + SETTINGS_AUTOMATIC_REPORTS).replace(":storeId", user.current_store.id)
        history.push(automaticReportsPath)
        onCancel()
    }

    return (
        <div className='w-full relative p-10 space-y-1 justify-start'>
            {!emailRecipientsIsError && (
                <div className="flex w-full flex-col">
                    <div className="flex flex-row">
                        <LeftCircleOutlined className="text-3xl mr-8 hover:text-indigo-700 mt-5" onClick={handleOnShowAutomaticReports} />
                        <div>
                            <p className='text-xl mb-0 font-bold text-blue-gray-900 mt-7'>
                                {i18n.t("settings.automatic_reports.report_names.billing_statement_email_recipients")}
                            </p>
                            <p className="mt-0 text-base text-gray-500">
                                {i18n.t("settings.automatic_reports.category")}: {i18n.t("settings.automatic_reports.report_categories.BILLING")}
                            </p>
                        </div>
                    </div>
                    <hr className="bg-gray-400 max-w-full mt-12 mb-6" />
                    <div className="flex flex-row">
                        <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-7 w-1/4'>
                            {i18n.t("settings.automatic_reports.report_details.about_the_report_title")}
                        </p>
                        <p className='text-md mb-0 text-gray-500 mt-7 ml-2 w-3/4'>
                            {i18n.t("settings.automatic_reports.report_details.report_descriptions.billing_statement_email_recipients")}
                        </p>
                    </div>
                    <hr className="bg-gray-400 max-w-full mt-12 mb-6" />
                    <div className="flex flex-row">
                        <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-7 w-1/4'>
                            {i18n.t("settings.automatic_reports.report_details.delivery_status")}
                        </p>
                        <p className={"text-md mb-0 mt-7 ml-2 w-3/4 font-normal text-green-500"}>
                            {i18n.t("settings.automatic_reports.report_details.true")}
                        </p>
                    </div>
                    <hr className="bg-gray-400 max-w-full mt-12 mb-6" />
                    <div className="flex flex-row">
                        <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-7 w-1/4'>
                            {i18n.t("settings.automatic_reports.report_details.frequency_title")}
                        </p>
                        <div className='text-md mb-0 text-gray-500 mt-7 ml-2 w-3/4'>
                            {i18n.t("settings.automatic_reports.report_frequency.STATEMENT")}
                        </div>
                    </div>
                    <hr className="bg-gray-400 max-w-full mt-12 mb-6" />
                    <div className="flex flex-row mb-7">
                        <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-7 w-1/4'> {i18n.t("settings.automatic_reports.report_details.recipients_title")} </p>
                        <div className='text-md mb-0 text-gray-500 mt-7 ml-2 w-3/4'>
                            <div>
                                <Select
                                    mode="tags"
                                    placeholder={i18n.t("settings.automatic_reports.report_details.recipients_placeholder")}
                                    className="w-full"
                                    suffixIcon={null}
                                    tokenSeparators={[" "]}
                                    value={updatedEmailRecipients}
                                    onChange={handleOnChangeEmailRecipients}
                                    open={false}
                                    status={updatedEmailRecipients?.length > 0 ? undefined : "error"}
                                />
                                {(!updatedEmailRecipients || updatedEmailRecipients.length === 0) && (
                                    <div className="text-red-500 text-sm">
                                        {i18n.t("settings.automatic_reports.error_insert_email_recipients")}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row mt-12">
                        <Button type="secondary" className="w-1/2 mr-2" onClick={handleOnCancel}>
                            {i18n.t("settings.automatic_reports.report_details.report_buttons.cancel")}
                        </Button>
                        <Button type="primary" className="w-1/2 ml-2" onClick={handleOnSave} disabled={updatedEmailRecipients?.length > 0 ? false : true}>
                            {i18n.t("settings.automatic_reports.report_details.report_buttons.save")}
                        </Button>
                    </div>
                </div>
            )}

            <Loader show={emailRecipientsIsLoading} />

            <Notification
                show={successNotification}
                setShow={setSuccessNotification}
                type="success"
                title={i18n.t("settings.automatic_reports.report_details.report_notifications.done")}
            />

            <Notification
                show={errorNotification}
                setShow={setErrorNotification}
                type="error"
                title={i18n.t("settings.automatic_reports.report_details.report_notifications.error")}
            />

            <Notification
                show={loadErrorNotification}
                setShow={setLoadErrorNotification}
                type="error"
                title={i18n.t("settings.automatic_reports.error")}
            />
        </div>
    )
}

export default StatementEmailRecipients