import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/react/solid"
import { Loader } from "../../components/Loader"
import { DocumentTextIcon, HandIcon, ShoppingBagIcon } from "@heroicons/react/outline"
import { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    IMPORT_SHOPIFY_PRODUCTS,
    ADD_REPLENISHMENT,
    INVENTORY,
    SHIPPING_METHODS,
    INTEGRATIONS,
    STORE_PATH,
} from "../../navigation/constants"
import { AVAILABLE_INTEGRATIONS } from "../../navigation"
import { Link, useHistory, useRouteMatch } from "react-router-dom"
import { UserContext } from "../../hooks/UserContext"
import { assignShippingBundles } from "../../services/storeServices"
import { Button, ButtonLoader } from "../../components"

const SelfOnboarding = ({ onboardingData = null, showLoader = false, onReloadData = () => {} }) => {
    const { i18n } = useTranslation()
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })
    const history = useHistory()
    const { user } = useContext(UserContext)

    const [currentStep, setCurrentStep] = useState(undefined)
    const [loadingBundleBotton, setLoadingBundleBotton] = useState(false)

    const selectStep = (step_key) => {
        setCurrentStep(step_key)
    }

    const stepsInfo = useMemo(() => {
        let steps = {
            store: { name: i18n.t("home.connect_store"), href: "#", status: "complete" },
            products: { name: i18n.t("home.inventory"), href: "#", status: "complete" },
            shipping: { name: i18n.t("home.ship_pref"), href: "#", status: "complete" },
            replenishment: { name: i18n.t("home.replenish"), href: "#", status: "complete" },
        }

        // Object.keys doesn't guarantee the order of the keys, in this case it is important to always follow this order.
        const sorted_step_keys = ["store", "products", "shipping", "replenishment"]

        if (onboardingData) {
            var current = currentStep

            sorted_step_keys.map((step_key) => {
                if (onboardingData["steps_completed"][step_key] !== true) {
                    if (current === undefined) {
                        steps[step_key].status = "current"
                        current = step_key
                    } else {
                        if (steps[step_key].status !== "current") steps[step_key].status = "upcoming"
                        if (steps[current] && steps[current].status !== "complete") steps[current].status = "current"
                    }
                }
            })
        }
        if (current && steps[current].status === "complete") current = undefined
        setCurrentStep(current)

        return steps
    }, [onboardingData, currentStep])

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(" ")
    }

    const onClickAddIntegration = () => {
        history.push(storePath + AVAILABLE_INTEGRATIONS)
    }

    const handleSelectBundle = async (bundleName) => {
        setLoadingBundleBotton(true)
        await assignShippingBundles(user.current_store?.id, bundleName)
        onReloadData()
        setLoadingBundleBotton(false)
    }

    return (
        <div className="bg-white shadow sm:rounded-lg relative">
            <Loader show={showLoader}></Loader>
            <div className="px-4 py-5 sm:px-6">
                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                    {i18n.t("home.start_to_config")}
                </h2>
                {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">{i18n.t("home.start_to_config")}</p> */}
            </div>

            <div className="flex flex-row border-t border-gray-200 py-5 px-4 sm:px-6 lg:px-8">
                <nav className="flex-none border-r border-gray-200 pr-10" aria-label="Progress">
                    <ol role="list" className="space-y-6">
                        {Object.keys(stepsInfo).map((step_key) => (
                            <li key={stepsInfo[step_key].name}>
                                {stepsInfo[step_key].status === "complete" ? (
                                    <span className="flex items-start">
                                        <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                                            <CheckCircleIcon className="h-full w-full text-indigo-600 group-hover:text-indigo-800" aria-hidden="true" />
                                        </span>
                                        <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">{stepsInfo[step_key].name}</span>
                                    </span>
                                ) : stepsInfo[step_key].status === "current" ? (
                                    <a onClick={() => selectStep(step_key)} className="flex items-start" aria-current="step">
                                        <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                                            <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                                            <span className="relative block w-2 h-2 bg-indigo-600 rounded-full" />
                                        </span>
                                        <span className="ml-3 text-sm font-medium text-indigo-600">{stepsInfo[step_key].name}</span>
                                    </a>
                                ) : (
                                    <a onClick={() => selectStep(step_key)} className="group">
                                        <div className="flex items-start">
                                            <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                                                <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                                            </div>
                                            <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">{stepsInfo[step_key].name}</span>
                                        </div>
                                    </a>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>
                <div className="flex-auto">
                    <div className="pl-10">
                        {stepsInfo["products"]["status"] == "current" ? (
                            <div>
                                {/* <h2 className="text-lg font-medium text-gray-900">Da de alta tus productos</h2> */}
                                <p className="mt-1 text-sm text-gray-500">{i18n.t("home.product_register")}</p>
                                <ul role="list" className="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200">
                                    {onboardingData &&
                                    onboardingData["additional_info"] &&
                                    onboardingData["additional_info"]["has_one_shopify_integration"] === true ? (
                                        <>
                                            <li key="1">
                                                <div className="relative group py-4 flex items-start space-x-3">
                                                    <div className="flex-shrink-0">
                                                        <span
                                                            className={classNames(
                                                                "bg-green-400",
                                                                "inline-flex items-center justify-center h-10 w-10 rounded-lg"
                                                            )}
                                                        >
                                                            <ShoppingBagIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                        </span>
                                                    </div>
                                                    <div className="min-w-0 flex-1">
                                                        <div className="text-sm font-medium text-gray-900">
                                                            <a
                                                                href="#"
                                                                onClick={() =>
                                                                    history.push(
                                                                        storePath +
                                                                            IMPORT_SHOPIFY_PRODUCTS.replace(
                                                                                ":id",
                                                                                onboardingData["additional_info"]["first_integration_id"]
                                                                            )
                                                                    )
                                                                }
                                                            >
                                                                <span className="absolute inset-0" aria-hidden="true" />
                                                                {i18n.t("home.import_from_shopify")}
                                                            </a>
                                                        </div>
                                                        <p className="text-sm text-gray-600">{i18n.t("home.import_from_shop")}</p>
                                                    </div>
                                                    <div className="flex-shrink-0 self-center">
                                                        <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    <li key="2">
                                        <div className="relative group py-4 flex items-start space-x-3">
                                            <div className="flex-shrink-0">
                                                <span className={classNames("bg-green-500", "inline-flex items-center justify-center h-10 w-10 rounded-lg")}>
                                                    <DocumentTextIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                </span>
                                            </div>
                                            <div className="min-w-0 flex-1">
                                                <div className="text-sm font-medium text-gray-900">
                                                    <a href="#" onClick={() => history.push(storePath + INVENTORY)}>
                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                        {i18n.t("home.import_from_excel")}
                                                    </a>
                                                </div>
                                                <p className="text-sm text-gray-500"> {i18n.t("home.upload")} </p>
                                            </div>
                                            <div className="flex-shrink-0 self-center">
                                                <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </li>
                                    <li key="3">
                                        <div className="relative group py-4 flex items-start space-x-3">
                                            <div className="flex-shrink-0">
                                                <span className={classNames("bg-indigo-500", "inline-flex items-center justify-center h-10 w-10 rounded-lg")}>
                                                    <HandIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                </span>
                                            </div>
                                            <div className="min-w-0 flex-1">
                                                <div className="text-sm font-medium text-gray-900">
                                                    <a href="#" onClick={() => history.push(storePath + INVENTORY)}>
                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                        {i18n.t("home.manual_creation")}
                                                    </a>
                                                </div>
                                                <p className="text-sm text-gray-500">{i18n.t("home.create_one_by_one")}</p>
                                            </div>
                                            <div className="flex-shrink-0 self-center">
                                                <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className="mt-6 flex">
                                    {/* <a href="#" onClick={() => setCurrentStep(undefined)} className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                            Importar producto más tarde<span aria-hidden="true"> &rarr;</span>
                                            </a> */}
                                </div>
                            </div>
                        ) : stepsInfo["store"]["status"] == "current" ? (
                            <div>
                                <div className="text-center">
                                    <h3 className="mt-2 text-sm font-medium text-gray-900"> {i18n.t("home.connect_store_to_cubbo")} </h3>

                                    <div className="mt-6">
                                        {onboardingData && onboardingData["additional_info"]["has_pending_locations_configuration"] == true ? (
                                            <>
                                                <span className=""> {i18n.t("home.assign_location")} </span>
                                                <br></br>
                                                <span className="italic mt-1">
                                                    {" "}
                                                    <Link to={storePath + INTEGRATIONS}> {i18n.t("home.Go_to_Integration:")} </Link>{" "}
                                                    {i18n.t("home.assign_location_link")}{" "}
                                                </span>
                                                <br></br>
                                            </>
                                        ) : (
                                            <>
                                                <p className="mt-1 text-sm text-gray-500"> {i18n.t("home.connect_everything")} </p>
                                                <div className="mt-6">
                                                    <Button
                                                        onClick={() => {
                                                            onClickAddIntegration()
                                                        }}
                                                    >
                                                        {" "}
                                                        {i18n.t("home.connect_my_store")}{" "}
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : stepsInfo["shipping"]["status"] == "current" ? (
                            <div>
                                {onboardingData && onboardingData["additional_info"] && onboardingData["additional_info"]["active_integrations_count"] > 0 ? (
                                    <>
                                        {user.current_store?.warehouses[0]?.country === "CO" ? (
                                            <div className="grid grid-cols-3 gap-7">
                                                <div className="">
                                                    <p className="mt-1 text-base text-gray-900">{i18n.t("home.recommended_same_day")}</p>
                                                    <p className="mt-1 text-sm text-gray-500">{i18n.t("home.sameday_ship")}</p>
                                                    <p className="mt-1 text-sm text-gray-500 italic">{i18n.t("home.packs")}</p>
                                                    <ButtonLoader
                                                        className=""
                                                        loading_text={i18n.t("home.configuring")}
                                                        loading={loadingBundleBotton}
                                                        disabled={loadingBundleBotton}
                                                        onClick={() => handleSelectBundle("recommended_co")}
                                                    >
                                                        {i18n.t("home.select")}
                                                    </ButtonLoader>
                                                </div>
                                                <div className="">
                                                    <div className="">
                                                        <p className="mt-1 text-base text-gray-900">{i18n.t("home.custom_packs")}</p>
                                                        <p className="mt-1 text-sm text-gray-500">{i18n.t("home.custom_config")}</p>
                                                        <p className="mt-1 text-sm text-gray-500 italic"></p>
                                                        <Button
                                                            type="secondary"
                                                            onClick={() => {
                                                                history.push(
                                                                    storePath +
                                                                        SHIPPING_METHODS.replace(
                                                                            ":id",
                                                                            onboardingData["additional_info"]["first_integration_id"]
                                                                        )
                                                                )
                                                            }}
                                                        >
                                                            {i18n.t("home.select")}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="grid grid-cols-3 gap-7">
                                                <div className="">
                                                    <p className="mt-1 text-base text-gray-900">{i18n.t("home.economy")}</p>
                                                    <p className="mt-1 text-sm text-gray-500">{i18n.t("home.economy_config")}</p>
                                                    <p className="mt-1 text-sm text-gray-500 italic">{i18n.t("home.economy_packs")}</p>
                                                    <ButtonLoader
                                                        className=""
                                                        loading_text={i18n.t("home.configuring")}
                                                        loading={loadingBundleBotton}
                                                        disabled={loadingBundleBotton}
                                                        onClick={() => handleSelectBundle("economy_mx")}
                                                    >
                                                        {i18n.t("home.select")}
                                                    </ButtonLoader>
                                                </div>
                                                <div className="">
                                                    <div className="">
                                                        <p className="mt-1 text-base text-gray-900">{i18n.t("home.premium")}</p>
                                                        <p className="mt-1 text-sm text-gray-500">{i18n.t("home.premium_config")}</p>
                                                        <p className="mt-1 text-sm text-gray-500 italic">{i18n.t("home.premium_packs")}</p>
                                                        <ButtonLoader
                                                            className=""
                                                            loading_text={i18n.t("home.configuring")}
                                                            loading={loadingBundleBotton}
                                                            disabled={loadingBundleBotton}
                                                            onClick={() => handleSelectBundle("premium_mx")}
                                                        >
                                                            {i18n.t("home.select")}
                                                        </ButtonLoader>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="">
                                                        <p className="mt-1 text-base text-gray-900">{i18n.t("home.custom_packs")}</p>
                                                        <p className="mt-1 text-sm text-gray-500">{i18n.t("home.custom_config")}</p>
                                                        <p className="mt-1 text-sm text-gray-500 italic"></p>
                                                        <Button
                                                            type="secondary"
                                                            onClick={() => {
                                                                history.push(
                                                                    storePath +
                                                                        SHIPPING_METHODS.replace(
                                                                            ":id",
                                                                            onboardingData["additional_info"]["first_integration_id"]
                                                                        )
                                                                )
                                                            }}
                                                        >
                                                            {i18n.t("home.select")}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <span className="italic"> {i18n.t("home.required_store_message")} </span>
                                    </>
                                )}
                            </div>
                        ) : stepsInfo["replenishment"]["status"] == "current" ? (
                            <div>
                                <div className="text-center">
                                    <h3 className="mt-2 text-sm font-medium text-gray-900">{i18n.t("home.first_replenishment")}</h3>
                                    <p className="mt-1 text-sm text-gray-500">{i18n.t("home.replenishment_details")}</p>
                                    <div className="mt-6">
                                        {onboardingData && onboardingData["steps_completed"]["products"] === true ? (
                                            <>
                                                <Button
                                                    onClick={() => {
                                                        history.push(storePath + ADD_REPLENISHMENT)
                                                    }}
                                                >
                                                    {i18n.t("home.create_reple")}
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <span className="italic">{i18n.t("home.required_inventory_message")}.</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelfOnboarding