export const OrderDelayedIcon = ({ className = null }) => {
    return (
        <svg className={className} viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M61 30.5V61H83.875M106.75 61C106.75 67.008 105.567 72.9571 103.267 78.5078C100.968 84.0584 97.5984 89.1019 93.3501 93.3501C89.1019 97.5984 84.0584 100.968 78.5078 103.267C72.9571 105.567 67.008 106.75 61 106.75C54.992 106.75 49.0429 105.567 43.4922 103.267C37.9416 100.968 32.8981 97.5984 28.6499 93.3501C24.4016 89.1019 21.0317 84.0584 18.7325 78.5078C16.4334 72.9571 15.25 67.008 15.25 61C15.25 48.8663 20.0701 37.2297 28.6499 28.6499C37.2297 20.0701 48.8663 15.25 61 15.25C73.1337 15.25 84.7703 20.0701 93.3501 28.6499C101.93 37.2297 106.75 48.8663 106.75 61Z"
                stroke="currentColor"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
