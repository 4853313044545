import { ButtonLoader, Button, Notification, ConfirmDialog, SelectInput,InputField} from "../../components"
import { Combobox } from '@headlessui/react'
import PropTypes from "prop-types";
import { Loader } from '../../components/Loader';
import {useState, useContext, useEffect, useMemo} from "react";
import {useQuery} from "react-query";
import {useDispatch} from "react-redux";
import { UserContext } from "../../hooks/UserContext";
import {useForm} from "react-hook-form";
import { useTranslation } from 'react-i18next';
import {
    searchByTaxId
} from "../../services/productServices";
import { fetchNcm, createNcm, editNcm} from "../../services/ncmsServices"
import { fetchStoreInfo} from "../../services/storeServices"
import { TagIcon, TrashIcon, ChevronDownIcon, SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import {  Col, Row } from 'antd';
import { Collapse } from 'antd';
import { getTaxRegimen, getTaxSituation, getTaxSituationPis, getTaxSituationIpi, getTaxSituationCfop, getTaxes,tax_cpof_donation_same_state, 
    tax_cpof_donation_other_state,
tax_cpof_donation_return_same_state, tax_cpof_donation_return_other_state, tax_cpof_same_state, tax_cpof_other_state, 
tax_cpof_replenishment_same_state, tax_cpof_replenishment_other_state, tax_cpof_return_same_state, tax_aliquota, framing_code, tax_cpof_international } from '../../components/TaxDefinitions';
import {
    setValidSettingsForm
} from '../../redux/validSettingsFormSlice';

export const CreateNCMsContainer = ({
    onCancel,
    id = null,
    edit = false
}
) => {


    const [loadingBundleBotton, setLoadingBundleBotton] = useState(false)
    const {control, handleSubmit,formState: { errors, isDirty, dirtyFields  }} = useForm({})
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const [selectedTax, setSelectedTax] = useState(null)
    const [taxCodeSearch, setTaxCodeSearch] = useState("")
    const [openTaxesConfiguration, setOpenTaxesConfiguration] = useState(false)
    const [taxesList, setTaxesList] = useState([])
    const [fetchingTaxCodes, setFetchingTaxCodes] = useState(false)
    const [changeDetected, setChangeDetected] = useState(false)
    const [showNotification, setShowNotification] = useState(false)
    const [title, setTitle] = useState(i18n.t("settings.ncm_configurations.new_ncm"))
    const [search, setSearch] = useState(false)
    const [optionMessage, setOptionMessage] = useState(false)

    useEffect(() => {
        if(id != null){
            setTitle(i18n.t("settings.ncm_configurations.edit_ncm"))
            setSearch(true)
        }
      }, [id])
    const {user} = useContext(UserContext)

    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])
    const { Panel } = Collapse;

    const [dialog, setDialog] = useState({
        loading: false,
        open: false,
        title: "",
        description: "",
        errorMessage: "",
        type: null
    })

    const [ncmData, setNcmData] = useState({
        store_id: user.current_store?.id,
        ncm: id ? id : null,
        description: null, 
        cfop_tax_situation: null,
        cfop_tax_situation_other_state: null,
        cfop_tax_situation_international: null,
        // cfop_sym_return_invoice_same_state: getTaxes("1.949", "simbolic_return_same_state"),
        // cfop_sym_return_invoice_other_state: getTaxes("2.949", "simbolic_return_other_state"),
        // cfop_sale_invoice_same_state: getTaxes("5.102", "same_state"), 
        // cfop_sale_invoice_other_state: getTaxes("6.102", "other_state"), 
        // cfop_donation_invoice_same_state: getTaxes("5.949", "replenishment_same_state"), 
        // cfop_donation_invoice_other_state: getTaxes("6.949", "replenishment_other_state"),
        // cfop_donation_return_invoice_same_state: getTaxes("1.949", "donation_return_same_state"), 
        // cfop_donation_return_invoice_other_state: getTaxes("2.949", "donation_return_other_state"),  
        // cfop_return_invoice: getTaxes("1.202", "return_same_state"),
        cfop_sym_return_invoice_same_state: "1.949", 
        cfop_sym_return_invoice_other_state: "2.949", 
        cfop_sale_invoice_same_state: null, 
        cfop_sale_invoice_other_state: null,
        cfop_sale_invoice_international: null, 
        cfop_donation_invoice_same_state: null, 
        cfop_donation_invoice_other_state: null, 
        cfop_donation_return_invoice_same_state: null, 
        cfop_donation_return_invoice_other_state: null,
        cfop_return_invoice: null, 
        cfop_replenishment_invoice_same_state: "5.949", 
        cfop_replenishment_invoice_other_state: "6.949", 
        cfop_replenishment_adjustment_invoice_same_state: "1.949", 
        cfop_replenishment_adjustment_invoice_other_state: "2.949", 
        icms_credit_tax_rate: null, 
        ipi_tax_situation: null, 
        ipi_framing_code:  getTaxes("999", "framing_code"), 
        ipi_tax_rate: null, 
        pis_tax_situation: null, 
        pis_tax_rate: null, 
        cofins_tax_situation: null, 
        cofins_tax_rate:null, 
        exception_ncm_variant:"", 
    })

    const {
        isLoading: storeisLoading,
        isError: storeisError,
        error: storeerror,
        data: store,
        isPreviousData: storeisPreviousData,
    } = useQuery(['store_info_list_ncms', user.current_store?.id], () => fetchStoreInfo(user.current_store?.id), { keepPreviousData: true })

    const {
        isLoading,
        isError,
        isFetchedAfterMount,
        error,
        data,
        isPreviousData,
        refetch,
    } = useQuery(['store_info_edit_ncm', id], () => fetchNcm(id), {
        enabled: edit,
        onSuccess: (info) => {
            if(isFetchedAfterMount===false && id){
                console.log("info", info)
                setEditNcmData(info)
            }
        }
    })

    

    const setEditNcmData = (info) => {
        setOpenTaxesConfiguration(true)
        setNcmData({
            store_id: user.current_store?.id,
            ncm: info.ncm,
            description: info.description, 
            cfop_tax_situation: getTaxSituationCfop(store?.tax_regime, info.cfop_tax_situation),
            cfop_tax_situation_other_state: getTaxSituationCfop(store?.tax_regime, info.cfop_tax_situation_other_state),
            cfop_tax_situation_international: getTaxSituationCfop(store?.tax_regime, info.cfop_tax_situation_international), 
            cfop_sym_return_invoice_same_state: getTaxes(info.cfop_sym_return_invoice_same_state, "simbolic_return_same_state"),
            cfop_sym_return_invoice_other_state: getTaxes(info.cfop_sym_return_invoice_other_state, "simbolic_return_other_state"),
            cfop_sale_invoice_same_state: getTaxes(info.cfop_sale_invoice_same_state, "same_state"), 
            cfop_sale_invoice_other_state: getTaxes(info.cfop_sale_invoice_other_state, "other_state"),
            cfop_sale_invoice_international: getTaxes(info.cfop_sale_invoice_international, "international"), 
            cfop_donation_invoice_same_state: getTaxes(info.cfop_donation_invoice_same_state, "donation_same_state"), 
            cfop_donation_invoice_other_state: getTaxes(info.cfop_donation_invoice_other_state, "donation_other_state"),
            cfop_replenishment_invoice_same_state: info.cfop_replenishment_invoice_same_state, 
            cfop_replenishment_invoice_other_state: info.cfop_replenishment_invoice_other_state, 
            cfop_replenishment_adjustment_invoice_same_state: info.cfop_replenishment_adjustment_invoice_same_state, 
            cfop_replenishment_adjustment_invoice_other_state: info.cfop_replenishment_adjustment_invoice_other_state, 
            cfop_return_invoice: getTaxes(info.cfop_return_invoice, "return_same_state"),
            cfop_donation_return_invoice_same_state: getTaxes(info.cfop_donation_return_invoice_same_state, "donation_return_same_state"), 
            cfop_donation_return_invoice_other_state: getTaxes(info.cfop_donation_return_invoice_other_state, "donation_return_other_state"), 
            icms_credit_tax_rate: info.icms_credit_tax_rate, 
            ipi_tax_situation:  getTaxSituationIpi(info.ipi_tax_situation),
            ipi_framing_code: getTaxes(info.ipi_framing_code, "framing_code"), 
            ipi_tax_rate: info.ipi_tax_rate, 
            pis_tax_situation: getTaxSituationPis(info.pis_tax_situation),
            pis_tax_rate:  info.pis_tax_rate, 
            cofins_tax_situation: getTaxSituationPis(info.cofins_tax_situation),
            cofins_tax_rate: info.cofins_tax_rate, 
            exception_ncm_variant:info.exception_ncm_variant, 
        })
    }

    useEffect(() => {
        if (store?.tax_regime == "SIMPLES_NACIONAL") {
            setNcmData({
                ...ncmData,
                ipi_tax_rate: "0.00", 
                pis_tax_rate: "0.00",
                cofins_tax_rate:"0.00"
            })
        }
      }, [store])

    useEffect(() => {
        console.log("taxCodeSearch",taxCodeSearch)
        if (!taxCodeSearch || taxCodeSearch === "") {
          setTaxesList([])
          return
        }
          searchTaxFunc(taxCodeSearch.trim())
      }, [taxCodeSearch])

    useEffect(() => {
        if (selectedTax !== null) {
            setNcmData({
                ...ncmData,
                ncm: selectedTax[0],
                description: selectedTax[1],
                exception_ncm_variant: selectedTax[2]
            })
            setOpenTaxesConfiguration(true)
            setValidForm(true)
        }
    }, [selectedTax])

      
    const searchTaxFunc = async (search_code) => {
          setFetchingTaxCodes(true)
          try {
              let listTaxes = await searchByTaxId(search_code)
              if (listTaxes.length > 10) setOptionMessage(true)
              else setOptionMessage(false)
              setTaxesList(listTaxes)
          } catch (error) {
              console.log(error)
              setTaxesList([])
          }
          setFetchingTaxCodes(false)
    }

    const renderTaxesList =  useMemo(() => {
        return  taxesList.slice(0, 10)
    },[taxesList])
   

    const [errorMessage, setErrorMessage] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)

    const onSubmit = async (dataTax) => {
        setValidForm(false)
        console.log(ncmData)
        dataTax = {
            ...ncmData
        }
        if (id && edit){
            setLoadingBundleBotton(true)
            editNcm(id, dataTax).then((response)=>{
            setSuccessMessage(i18n.t("settings.tax_fields.success_message"))
            setShowNotification(true)
            setLoadingBundleBotton(false)
            onCancel()
        }).catch((e)=>{
            setErrorMessage(i18n.t("settings.tax_fields.error_message"))
            setShowNotification(true)
            setLoadingBundleBotton(false)
        })
        }else{
            dataTax = {
                ...dataTax,
                icms_credit_tax_rate: "0.00",
                cfop_tax_situation_international: getTaxSituationCfop(store?.tax_regime, "300"), 
                cfop_sale_invoice_international: getTaxes("7.102", "international"), 
            }
            setLoadingBundleBotton(true)
            createNcm(dataTax).then((response)=>{
            setSuccessMessage(i18n.t("settings.tax_fields.success_message"))
            setShowNotification(true)
            setLoadingBundleBotton(false)
            onCancel()
        }).catch((e)=>{
            console.log(e)
            setErrorMessage(i18n.t("settings.tax_fields.error_message"))
            setShowNotification(true)
            setLoadingBundleBotton(false)
        })
        }
        
    }
    const checkRequiredFields = () => {
        if (ncmData.ncm  &&
             ncmData.cfop_tax_situation  &&
            // ncmData.cfop_sym_return_invoice_same_state &&
            // ncmData.cfop_sym_return_invoice_other_state &&
            ncmData.cfop_sale_invoice_same_state &&
            ncmData.cfop_sale_invoice_other_state && 
            ncmData.cfop_donation_invoice_same_state && 
            ncmData.cfop_donation_invoice_other_state && 
            ncmData.cfop_replenishment_invoice_same_state && 
            ncmData.cfop_replenishment_invoice_other_state &&
            ncmData.store_id &&
            ncmData.cfop_replenishment_adjustment_invoice_same_state  && 
            ncmData.cfop_replenishment_adjustment_invoice_other_state  && 
            ncmData.cfop_return_invoice  && 
            ncmData.ipi_tax_situation  && 
            ncmData.ipi_framing_code  && 
            ncmData.ipi_tax_rate  && 
            ncmData.pis_tax_situation  && 
            ncmData.pis_tax_rate  && 
            ncmData.cofins_tax_situation  && 
            ncmData.cofins_tax_rate
        ){  
            if (ncmData.cfop_tax_situation?.id == 101 || ncmData.cfop_tax_situation?.id == 201){
                if (ncmData.icms_credit_tax_rate){
                    return true
                }
                return false
            }
            return true
        }
        return false
    }
    const handleClickOnCancel = () => {

        if (changeDetected && !successMessage){
            setDialog({...dialog, loading: false, open: true})
        }
        else{
            setValidForm(false)
            onCancel()
        }
    }
    const handleClickOnConfirm = () => {
        onCancel()
     }

    const setValidForm = (edit) => {
        dispatch(setValidSettingsForm({id:"2",change:edit,selectedkeys:["2"] }))
        setChangeDetected(edit)
        setSuccessMessage(false)
    }
    const genExtra = () => (

        <div onClick={e => e.stopPropagation()}>
            <Button type="cancel" onClick={handleClickOnCancel}>
                Cancelar
            </Button>
        </div>
        
    );
    return (
        <div className='relative pt-5'>
            <form action="#" method="PATCH" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                
                <div>

                <Collapse defaultActiveKey={['1']} bordered={true} ghost>
                    <Collapse.Panel header={<label><b>{title}</b> {successMessage && <label className = "text-green-500 italic font-normal">{i18n.t("settings.validate_changes.saved_data")}</label>}{changeDetected && !successMessage && <label className = "text-yellow-500 italic font-normal">{i18n.t("settings.validate_changes.unsaved_data")}</label>}</label>} key="1" className="collapse-panel-create  bg-white shadow sm:rounded-lg" extra={genExtra()} >
                        
                        {store && <p className='block text-sm font-bold text-gray-700 mt-2'>{i18n.t("settings.ncm_configurations.tax_regime_store")}: <spam className='font-medium' >{getTaxRegimen(store?.tax_regime)}</spam></p>}

                        { edit && id ? (
                            <p className='block text-sm font-bold text-gray-700 mt-2'>NCM: <spam className='font-medium' >{ncmData.ncm} - {ncmData.description}</spam></p>
                        ):(

                            <div className="sm:col-span-3 mt-2">
                                <label className='block text-sm font-medium text-gray-700 mb-1'> { <div> {i18n.t("addProduct.container.ncm_code")} <span className="text-red-500"> *</span> </div>} </label>
                                    {/* pending -> create component */}
                                    <Combobox value={selectedTax} onChange={setSelectedTax} >
                                        <div className="relative">
                                            <div className="appearance-none block w-full px-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                
                                                <Combobox.Input 
                                                className="w-full border-none text-sm leading-5 text-gray-900 focus:ring-0"
                                                displayValue={(_tax) => {
                                                    return  Array.isArray(_tax) ? _tax[0] + " - " + _tax[1] : _tax
                                                }}
                                                onChange={(evt) => {
                                                    setTaxCodeSearch(evt.target.value)
                                                }} 
                                                />
                                                {renderTaxesList.length > 0 &&
                                                <Combobox.Button className="absolute right-1 mt-2 items-center pr-2">
                                                <ChevronDownIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                                </Combobox.Button>
                                                }
                                            </div>
                                            <Combobox.Options
                                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {renderTaxesList.length === 0 && !fetchingTaxCodes && taxCodeSearch != "" ? (
                                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                    {i18n.t("addProduct.container.no_supported")}
                                                    </div>
                                                ) : (
                                                renderTaxesList.map((tax) => (
                                                    <Combobox.Option 
                                                    key={tax.product_code+tax.description+tax.exception_ncm_variant}
                                                    className={({ active }) =>`relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-gray-800' : 'text-gray-900'}`}
                                                    value={[tax.product_code, tax.description, tax.exception_ncm_variant]}>
                                                        {({ selected, active }) => (
                                                            <>
                                                                <span
                                                                className={`block truncate ${
                                                                    selected ? 'font-bold' : 'font-normal'
                                                                }`}
                                                                >
                                                                {tax.product_code} - {tax.description} {tax.exception_ncm_variant? - tax.exception_ncm_variant: ""}
                                                                </span>
                                                                {selected ? (
                                                                <span
                                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                                    active ? 'text-teal-500' : 'text-teal-600'
                                                                    }`}
                                                                >
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                                ) : null}
                                                            </>
                                                        )}

                                                    </Combobox.Option>
                                                )))
                                                }
                                                {optionMessage && renderTaxesList.length > 0 &&
                                                     <Combobox.Option
                                                        disabled
                                                        className="relative cursor-pointer select-none py-2 pl-10 pr-4"
                                                        key={"externo"}>
                                                        Existen más coincidencias... 
                                                     </Combobox.Option>
                                                }
                                            </Combobox.Options>
                                        </div>
                                    </Combobox>

                                {<p className="text-sm text-red-600">{selectedTax == '' || selectedTax == null && i18n.t("addProduct.container.required_field")}</p>}

                            </div>
                        )}

                        {openTaxesConfiguration &&
                            <>
                            
                                <label className='block text-sm font-medium text-gray-700 mb-1 mt-2'> Impuestos</label>
        
        
                                <div className="border rounded-md mt-1">
                                    <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-3 w-full justify-center border-b border-gray-200 p-3 bg-gray-100 ">
                                        ICMS
                                    </div>
                                <Collapse ordered={true} defaultActiveKey={['cfop']} ghost>
                                    <Panel className="collapse-panel-custom" b header={i18n.t("settings.ncm_configurations.parameter_configuration")} key="cfop">
                                    <div className="grid grid-cols-2 gap-x-8 gap-y-3">
                                        {/* TODO: Create or edit */}
                                        <div>
                                        <SelectInput
                                            options={getTaxSituation(store?.tax_regime, "ICMS")}
                                            selectedInitial={ncmData.cfop_tax_situation}
                                            className="sm:col-span-3"
                                            label= {i18n.t("settings.ncm_configurations.tax_situation")}
                                            onChange={(v) => {
                                                setValidForm(true)
                                                setNcmData({
                                                    ...ncmData,
                                                    cfop_tax_situation: v
                                                })
                                            }}
                                            value={ncmData.cfop_tax_situation}
                                            required
                                            />
                                        {(ncmData.cfop_tax_situation?.id == 101 || ncmData.cfop_tax_situation?.id == 201) &&
                                        <InputField
                                            className={'mt-2'}
                                            title= {i18n.t("settings.ncm_configurations.tax_rate_credit")}
                                            value = {ncmData.icms_credit_tax_rate}
                                            name = "tax_aliquota"
                                            onChange={(v) => {
                                                //let re = /^([0-9])*(\.)*([0-9]{1,2})?$/;
                                                let re = /^\d*(\.\d{0,1})?\d{0,1}$/
                                                if (v === '' || re.test(v)) {
                                                    setValidForm(true)
                                                    setNcmData({
                                                        ...ncmData,
                                                        icms_credit_tax_rate: v
                                                    })
                                                }
                                            }}
                                            required
                                            border
                                        />}
                                        </div>
                                        {store?.tax_regime != "SIMPLES_NACIONAL" &&
                                            <div>
                                            <SelectInput
                                                options={getTaxSituation(store?.tax_regime, "ICMS")}
                                                selectedInitial={ncmData.cfop_tax_situation_other_state}
                                                className="sm:col-span-3"
                                                label= {i18n.t("settings.ncm_configurations.tax_situation_other_state")}
                                                onChange={(v) => {
                                                    setValidForm(true)
                                                    setNcmData({
                                                        ...ncmData,
                                                        cfop_tax_situation_other_state: v
                                                    })
                                                }}
                                                value={ncmData.cfop_tax_situation_other_state}
                                                />
                                            </div>
                                        }
                                        <div>
                                        <SelectInput
                                            options={getTaxSituation(store?.tax_regime, "ICMS_ISENTO")}
                                            selectedInitial={ncmData.cfop_tax_situation_international}
                                            className="sm:col-span-3"
                                            label= {i18n.t("settings.ncm_configurations.tax_situation_international")}
                                            onChange={(v) => {
                                                setValidForm(true)
                                                setNcmData({
                                                    ...ncmData,
                                                    cfop_tax_situation_international: v
                                                })
                                            }}
                                            value={ncmData.cfop_tax_situation_international}
                                            />
                                        </div>
                                        {/* <p>202 - Tributada com permissão de crédito e com cobrança do ICMS por substituição tributária</p> */}
                                    </div>
                                <p className="font-bold mt-5">cfop</p>  
                                <p className="mb-1 mt-3 text-gray-500"> {i18n.t("settings.ncm_configurations.order_invoices")} <span className = "text-gray-600 italic font-normal">  {i18n.t("settings.ncm_configurations.nfs_note")}</span></p>
                                <div className="border rounded-md">
                                <div className=" border-gray-200 rounded-2xl grid grid-row bg-white shadow sm:rounded-lg">
        
                                    <div className="grid gap-y-5 gap-x-8 grid-cols-4 mb-4 w-full justify-center border-b border-gray-200 ">
                                        <div>
                                            <p className="mt-4 mb-4 pl-5 text-sm font-semibold">TIPO DE {i18n.t("settings.ncm_configurations.invoice_table_title")}</p>
                                        </div>
                                        <div >
                                            <p className="mt-4 mb-4 text-sm font-semibold">CFOP <br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.same_state_entity")}</label></p>
                                        </div>
                                        <div >
                                        <p className="mt-4 mb-4 text-sm font-semibold">CFOP <br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.other_state_entity")}</label></p>
                                        </div>
                                        <div >
                                        <p className="mt-4 mb-4 text-sm font-semibold">CFOP <br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.international")}</label></p>
                                        </div>
                                    </div>
                                    <div className="divide-y divide-gray-200"></div>
                                    
                                        <div className="grid gap-y-5 gap-x-8 grid-cols-4 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.symbolic_return")}</p>
                                            </div>
                                            <div className="mt-3">
                                                <p >1.949</p>
                                            </div>
                                            <div className="mt-3 pr-5">
                                                <p >2.949</p>
                                            </div>
                                            <p className="mt-3 ml-1">{i18n.t("settings.ncm_configurations.dont_apply")}</p>
                                        </div>
                                        <div className="grid gap-y-5 gap-x-8 sm:grid-cols-4 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.sale")}</p>
                                            </div>
                                            <div>
                                            <SelectInput
                                                    options={tax_cpof_same_state}
                                                    selectedInitial={ncmData.cfop_sale_invoice_same_state}
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setNcmData({
                                                            ...ncmData,
                                                            cfop_sale_invoice_same_state: v
                                                        })
                                                    }}
                                                    value={ncmData.cfop_sale_invoice_same_state}
                                                    required
                                                />
                                            </div>
                                            <SelectInput
                                                options={tax_cpof_other_state}
                                                selectedInitial={ncmData.cfop_sale_invoice_other_state}
                                                onChange={(v) => {
                                                    setValidForm(true)
                                                    setNcmData({
                                                        ...ncmData,
                                                        cfop_sale_invoice_other_state: v
                                                    })
                                                }}
                                                value={ncmData.cfop_sale_invoice_other_state}
                                                required
                                            />
                                            <div className="pr-5">
                                            <SelectInput
                                                    options={tax_cpof_international}
                                                    selectedInitial={ncmData.cfop_sale_invoice_international}
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setNcmData({
                                                            ...ncmData,
                                                            cfop_sale_invoice_international: v
                                                        })
                                                    }}
                                                    value={ncmData.cfop_sale_invoice_international}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid gap-y-5 gap-x-8 grid-cols-4 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.donation")}</p>
                                            </div>
                                            <div>
                                                <SelectInput
                                                    options={tax_cpof_donation_same_state}
                                                    selectedInitial={ncmData.cfop_donation_invoice_same_state}
                                                    className="sm:col-span-3"
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setNcmData({
                                                            ...ncmData,
                                                            cfop_donation_invoice_same_state: v
                                                        })
                                                    }}
                                                    value={ncmData.cfop_donation_invoice_same_state}
                                                    required
                                                />
                                            </div>
                                            <SelectInput
                                                options={tax_cpof_donation_other_state}
                                                selectedInitial={ncmData.cfop_donation_invoice_other_state}
                                                onChange={(v) => {
                                                    setValidForm(true)
                                                    setNcmData({
                                                        ...ncmData,
                                                        cfop_donation_invoice_other_state: v
                                                    })
                                                }}
                                                value={ncmData.cfop_donation_invoice_other_state}
                                                required
                                            />
                                            <p className="mt-3 ml-1">{i18n.t("settings.ncm_configurations.dont_apply")}</p>
                                        </div>
                                        <div className="grid gap-y-5 gap-x-8 grid-cols-4 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.remove_stock")}</p>
                                            </div>
                                            <div className="mt-3">
                                                <p >1.949</p>
                                            </div>
                                            <div className="pr-5 mt-3">
                                                <p >2.949</p>
                                            </div>
                                            <p className="mt-3 ml-1">{i18n.t("settings.ncm_configurations.dont_apply")}</p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <p className="mb-1 mt-5 text-gray-500"> {i18n.t("settings.ncm_configurations.return_invoices")}</p>
                                <div className="border rounded-md">
                                <div className=" border-gray-200 rounded-2xl grid grid-row bg-white shadow sm:rounded-lg">
        
                                <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center border-b border-gray-200 ">
                                        <div>
                                            <p className="mt-4 mb-4 pl-5 text-sm font-semibold">{i18n.t("settings.ncm_configurations.invoice_table_title")}</p>
                                        </div>
                                        <div >
                                            <p className="mt-4 mb-4 text-sm font-semibold">CFOP<br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.same_state_entity")}</label></p>
                                        </div>
                                        <div >
                                        <p className="mt-4 mb-4 text-sm font-semibold">CFOP<br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.other_state_entity")}</label></p>
                                        </div>
                                    </div>
                                    <div className="divide-y divide-gray-200"></div>
                                    
                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.return")}</p>
                                            </div>
                                            <div>
        
                                                <SelectInput
                                                    options={tax_cpof_return_same_state}
                                                    selectedInitial={ncmData.cfop_return_invoice}
                                                    className="sm:col-span-3"
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setNcmData({
                                                            ...ncmData,
                                                            cfop_return_invoice: v
                                                        })
                                                    }}
                                                    value={ncmData.cfop_return_invoice}
                                                    required
                                                />
                                            </div>
                                            <p className="mt-3 ml-1">{i18n.t("settings.ncm_configurations.dont_apply")}</p>
                                        </div>
                                        
                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.return_donation")}</p>
                                            </div>
                                            <div>
        
                                                <SelectInput
                                                    options={tax_cpof_donation_return_same_state}
                                                    selectedInitial={ncmData.cfop_donation_return_invoice_same_state}
                                                    className="sm:col-span-3"
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setNcmData({
                                                            ...ncmData,
                                                            cfop_donation_return_invoice_same_state: v
                                                        })
                                                    }}
                                                    value={ncmData.cfop_donation_return_invoice_same_state}
                                                    required
                                                />
                                            </div>
                                            <div className="pr-5">
        
                                                <SelectInput
                                                    options={tax_cpof_donation_return_other_state}
                                                    selectedInitial={ncmData.cfop_donation_return_invoice_other_state}
                                                    className="sm:col-span-3"
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setNcmData({
                                                            ...ncmData,
                                                            cfop_donation_return_invoice_other_state: v
                                                        })
                                                    }}
                                                    value={ncmData.cfop_donation_return_invoice_other_state}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
        
                                <p className="mb-1 mt-5 text-gray-500"> {i18n.t("settings.ncm_configurations.replenihsment_invoices")} <span className = "text-gray-600 italic font-normal">  {i18n.t("settings.ncm_configurations.note")}</span></p>
                                <div className="border rounded-md">
                                <div className=" border-gray-200 rounded-2xl grid grid-row bg-white shadow sm:rounded-lg">
        
                                <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center border-b border-gray-200 ">
                                        <div>
                                            <p className="mt-4 mb-4 pl-5 text-sm font-semibold">{i18n.t("settings.ncm_configurations.invoice_table_title")}</p>
                                        </div>
                                        <div >
                                            <p className="mt-4 mb-4 text-sm font-semibold">CFOP<br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.same_state_entity")}</label></p>
                                        </div>
                                        <div >
                                        <p className="mt-4 mb-4 text-sm font-semibold">CFOP<br></br><label className="font-normal">{i18n.t("settings.ncm_configurations.other_state_entity")}</label></p>
                                        </div>
                                    </div>
                                    <div className="divide-y divide-gray-200"></div>
                                    
                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.replenishment")}</p>
                                            </div>
                                            <div  className="mt-3">
                                                <p >5.949</p>
                                            </div>
                                            <div className="pr-5 mt-3">
                                                <p>6.949</p>
                                            </div>

                                        </div>
                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.replenishment_adjustment")}</p>
                                            </div>
                                            <div  className="mt-3">
                                                <p>{ncmData.cfop_replenishment_adjustment_invoice_same_state}</p>
                                            </div >
                                            <div className="pr-5 mt-3">
                                                <p>{ncmData.cfop_replenishment_adjustment_invoice_other_state}</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
        
                                    </Panel>
        
                                </Collapse>
        
                                </div>
        
                                <div className="border rounded-md mt-5">
                                    <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-3 w-full justify-center border-b border-gray-200 p-3 bg-gray-100 ">
                                        IPI
                                    </div>
                                <Collapse bordered={true} defaultActiveKey={['ipi']} ghost>
        
                                    <Collapse.Panel header={i18n.t("settings.ncm_configurations.parameter_configuration")} key="ipi" className="collapse-panel-custom">
        
                                    <div className="grid grid-cols-2 gap-y-5 gap-x-8 sm:grid-cols-2 w-full justify-center ">
                                        <div >
                                            <div className="sm:col-span-6">
        
                                                <SelectInput
                                                    options={getTaxSituation(store?.tax_regime, "IPI")}
                                                    selectedInitial={ncmData.ipi_tax_situation}
                                                    className="sm:col-span-3"
                                                    label= {i18n.t("settings.ncm_configurations.tax_situation")}
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setNcmData({
                                                            ...ncmData,
                                                            ipi_tax_situation: v
                                                        })
                                                    }}
                                                    value={ncmData.ipi_tax_situation}
                                                    required
                                                    />
                                                {/* <p>202 - Tributada com permissão de crédito e com cobrança do ICMS por substituição tributária</p> */}
                                            </div>
                                        </div>
                                        <div >

                                            {store?.tax_regime == "SIMPLES_NACIONAL" ? (
                                                <div >
                                                    <p className="block text-sm font-medium text-gray-700 mb-2">{i18n.t("settings.ncm_configurations.tax_rate")}</p>
                                                    <p>{ncmData.ipi_tax_rate}</p>
                                                </div>
                                            ):(
                                                <InputField
                                                    title= {i18n.t("settings.ncm_configurations.tax_rate")}
                                                    value = {ncmData.ipi_tax_rate}
                                                    name = "tax_aliquota"
                                                    onChange={(v) => {
                                                        //let re = /^([0-9])*(\.)*([0-9]{1,2})?$/;
                                                        let re = /^\d*(\.\d{0,1})?\d{0,1}$/
                                                        if (v === '' || re.test(v)) {
                                                            setValidForm(true)
                                                            setNcmData({
                                                                ...ncmData,
                                                                ipi_tax_rate: v
                                                            })
                                                        }
                                                    }}
                                                    required
                                                    border
                                                />

                                            )}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-2 gap-y-5 gap-x-8 sm:grid-cols-2 w-full justify-center mt-5">
                                        <div>
                                        <div className="sm:col-span-6">
                                                <SelectInput
                                                        options={framing_code}
                                                        selectedInitial={ncmData.ipi_framing_code}
                                                        className="sm:col-span-3"
                                                        label= {t("settings.ncm_configurations.framing_code")}
                                                        onChange={(v) => {
                                                            setValidForm(true)
                                                            setNcmData({
                                                                ...ncmData,
                                                                ipi_framing_code: v
                                                            })
                                                        }}
                                                        value={ncmData.ipi_framing_code}
                                                        required
                                                    />
                                        </div> 
                                        </div>
                                    </div>
        
                                    </Collapse.Panel>
                                
                                </Collapse>
        
                                </div>
                            
                                <div className="border rounded-md mt-5">
                                    <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-3 w-full justify-center border-b border-gray-200 p-3 bg-gray-100 ">
                                        PIS
                                    </div>
                                <Collapse bordered={true} defaultActiveKey={['pis']} ghost>
        
                                    <Panel className="collapse-panel-custom" header={i18n.t("settings.ncm_configurations.parameter_configuration")} key="pis" >
                                    <div className="grid grid-cols-2 gap-y-5 gap-x-8 sm:grid-cols-2 w-full justify-center ">
                                        <div >
                                            <div className="sm:col-span-6">
        
                                                <SelectInput
                                                    options={getTaxSituation(store?.tax_regime, "PIS")}
                                                    selectedInitial={ncmData.pis_tax_situation}
                                                    className="sm:col-span-3"
                                                    label= {i18n.t("settings.ncm_configurations.tax_situation")}
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setNcmData({
                                                            ...ncmData,
                                                            pis_tax_situation: v
                                                        })
                                                    }}
                                                    value={ncmData.pis_tax_situation}
                                                    required
                                                    />
                                                {/* <p>202 - Tributada com permissão de crédito e com cobrança do ICMS por substituição tributária</p> */}
                                            </div>
                                        </div>
                                        <div >
                                            {store?.tax_regime == "SIMPLES_NACIONAL" ? (
                                                <div >
                                                    <p className="block text-sm font-medium text-gray-700 mb-2">{i18n.t("settings.ncm_configurations.tax_rate")}</p>
                                                    <p>{ncmData.pis_tax_rate}</p>
                                                </div>
                                            ):(
                                            
                                                <InputField
                                                    title= {i18n.t("settings.ncm_configurations.tax_rate")}
                                                    value = {ncmData.pis_tax_rate}
                                                    name = "tax_aliquota"
                                                    onChange={(v) => {
                                                        let re = /^\d*(\.\d{0,1})?\d{0,1}$/
                                                        if (v === '' || re.test(v)) {
                                                            setValidForm(true)
                                                            setNcmData({
                                                                ...ncmData,
                                                                pis_tax_rate: v
                                                            })
                                                        }
                                                    }}
                                                    required
                                                    border
                                                />
                                            )}
                                        </div>
                                    </div>
                                    </Panel>
                                
                                </Collapse>
        
                                </div>
                            
                                <div className="border rounded-md mt-5">
                                    <div className="grid grid-cols-2 gap-y-5 gap-x-3.5 sm:grid-cols-2 mb-3 w-full justify-center border-b border-gray-200 p-3 bg-gray-100 ">
                                    COFINS
                                    </div>
                                <Collapse bordered={true} defaultActiveKey={['cofis']} ghost>
        
                                    <Panel className="collapse-panel-custom" header={i18n.t("settings.ncm_configurations.parameter_configuration")} key="cofis">
        
                                    <div className="grid grid-cols-2 gap-y-5 gap-x-8 sm:grid-cols-2 w-full justify-center ">
                                        <div >
                                            <div className="sm:col-span-6">
        
                                                <SelectInput
                                                    options={getTaxSituation(store?.tax_regime, "COFINS")}
                                                    selectedInitial={ncmData.cofins_tax_situation}
                                                    className="sm:col-span-3"
                                                    label= {i18n.t("settings.ncm_configurations.tax_situation")}
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setNcmData({
                                                            ...ncmData,
                                                            cofins_tax_situation: v
                                                        })
                                                    }}
                                                    value={ncmData.cofins_tax_situation}
                                                    required
                                                    />
                                                {/* <p>202 - Tributada com permissão de crédito e com cobrança do ICMS por substituição tributária</p> */}
                                            </div>
                                        </div>
                                        <div >
                                            {store?.tax_regime == "SIMPLES_NACIONAL" ? (
                                                <div >
                                                    <p className="block text-sm font-medium text-gray-700 mb-2">{i18n.t("settings.ncm_configurations.tax_rate")}</p>
                                                    <p>{ncmData.cofins_tax_rate}</p>
                                                </div>
                                            ):(
                                                <InputField
                                                    title= {i18n.t("settings.ncm_configurations.tax_rate")}
                                                    value = {ncmData.cofins_tax_rate}
                                                    name = "tax_aliquota"
                                                    onChange={(v) => {
                                                        let re = /^\d*(\.\d{0,1})?\d{0,1}$/
                                                        if (v === '' || re.test(v)) {
                                                            setValidForm(true)
                                                            setNcmData({
                                                                ...ncmData,
                                                                cofins_tax_rate: v
                                                            })
                                                        }
                                                    }}
                                                    required
                                                    border
                                                />

                                            )}

                                        </div>
                                    </div>
        
                                    </Panel>
                                
                                </Collapse>
        
                                </div>
                            
                            </>
                        }

                    <div className="flex items-center justify-between mt-5">
                        <div  className="flex items-center justify-start">
                            <ConfirmDialog
                                    open={dialog.open}
                                    setOpen={(value) => setDialog({...dialog, open: value}) }
                                    title={i18n.t("settings.validate_changes.title")}
                                    description={i18n.t("settings.validate_changes.question")}
                                    confirmLabel="Si"
                                    onConfirm={handleClickOnConfirm}
                                    loading={dialog.loading}
                                />
                             
                            {!checkRequiredFields() &&
                            <p className="font-bold text-red-500 ">{i18n.t("settings.ncm_configurations.complete_data_message")}</p>
                            }
                        </div>
                        <div>
                            <Button type="cancel" onClick={handleClickOnCancel}>
                                Cancelar
                            </Button>
                            <ButtonLoader className="ml-5" loading_text={i18n.t("settings.tax_fields.processing")} loading={loadingBundleBotton} disabled={!checkRequiredFields()}>
                                {i18n.t("settings.tax_fields.send_form")}
                            </ButtonLoader>
                            {errorMessage && showNotification? (
                                    <>
                                        <Notification show={showNotification} setShow={setShowNotification} type="error" title={i18n.t("settings.user_settings.error")} description={errorMessage}/>
                                    </>
                                ) : null}
                                {successMessage && showNotification? (
                                    <>
                                        <Notification show={showNotification} setShow={setShowNotification} type="success" title={i18n.t("settings.user_settings.done")} description={successMessage}/>
                                    </>
                                ) : null}
                        </div>
                    </div>

                    </Collapse.Panel>
                </Collapse>

                </div>

               
            </form>
        </div>
    )

}