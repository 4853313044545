import { DialogView } from "./DialogView";
import { useEffect, useState } from "react";
import { EXPORT_FILE_TYPES, EXPORT_TYPES } from "./ExportOverview/constants"
import { useTranslation } from "react-i18next";

const AttachmentType = function (
    {
        extension,
        onChange,
        checked
    }) {
    const id = extension + '-input'
    const descriptionId = id + "-description"

    return (
        <div className="relative flex items-start ml-7">
            <div className="flex items-center h-5">
                <input
                    id={id}
                    aria-describedby={descriptionId}
                    type="checkbox"
                    checked={checked}
                    onChange={event => onChange({ [extension]: event.target.checked })}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
            </div>
            <div className="ml-3 text-sm">
                <span id={descriptionId} className="text-gray-500">{extension.toUpperCase()}</span>
            </div>
        </div>
    )
}

const ExportDescriptor = function (
    {
        title,
        subtitle,
        fileName,
    }) {
    const id = fileName + "-export-descriptor"

    return (
        <>
            <div className="relative flex items-start">
                <div className="flex items-center h-5">
                </div>
                <div className="ml-3 text-sm">
                    <label htmlFor={id} className="font-medium font-bold">
                        {title}
                    </label>
                    {subtitle &&
                        <p id="descriptionId" className="text-indigo-500 italic text-xs">
                            {subtitle}
                        </p>
                    }
                    <p className="mb-4 italic text-xs">File Name: {fileName}</p>
                </div>
            </div>
        </>
    )
}

const warningComponent = (descriptionValue, warning) =>
    <>
        <p className="mb-1 text-gray-500">{warning}</p>
    </>

const FilterDescriptor = function (
    {
        description,
        descriptionValue,
        warning
    }) {
    return (
        <div className="relative flex items-start mt-3">
            <div className="flex items-center h-3">
                <p className="text-black font-bold">{description}</p>
            </div>
            <div className="ml-3 text-sm">
                {warning ? warningComponent(descriptionValue, warning) : descriptionValue}
            </div>
        </div>
    )
}

export const ExportOverviewModal = function (
    {
        open,
        setOpen,
        type,
        subtype,
        purposes,
        onConfirm = () => setOpen(false),
        onCancel = () => setOpen(false),
        filterDescriptions = [
            {
                description: 'Example description',
                descriptionValue: 'Example description value',
                warning: 'Example warning'
            }
        ]
    }) {
    const { t } = useTranslation();

    useEffect(() => {
        setProcessTypesData(buildProcessTypeFlagsState(EXPORT_TYPES[type][subtype], true))
    },[purposes])

    const buildProcessTypeFlagsState = function (processTypes, defaultValue) {
        const processTypeFlagsInitialState = {}
        if (!purposes || purposes.length === 0) {
            Object.keys(processTypes).forEach(processType => processTypeFlagsInitialState[processType] = defaultValue)
        } else {
            Object.keys(processTypes).forEach(processType => {
                processTypeFlagsInitialState[processType] = !defaultValue;
            });

            purposes.forEach(purpose => {
                if (processTypeFlagsInitialState.hasOwnProperty(purpose)) {
                    processTypeFlagsInitialState[purpose] = defaultValue;
                }
            });
        }

        return processTypeFlagsInitialState
    }

    const buildAttachmentFlagsState = function (fileTypes, defaultValue) {
        const attachmentFlagsInitialState = {}
        fileTypes.forEach(fileType => attachmentFlagsInitialState[fileType] = defaultValue)

        return attachmentFlagsInitialState
    }

    const [attachmentFlags, setAttachmentFlags] = useState(buildAttachmentFlagsState(EXPORT_FILE_TYPES[type][subtype], true))
    const [processTypesData, setProcessTypesData] = useState(buildProcessTypeFlagsState(EXPORT_TYPES[type][subtype], true))

    const exportDescriptions = Object.keys(EXPORT_TYPES[type][subtype]).map(processType => ({
        title: t(`export_overview.export_descriptions.${type}.${processType}.title`),
        subtitle: t(`export_overview.export_descriptions.${type}.${processType}.subtitle`, ''),
        fileName: EXPORT_TYPES[type][subtype][processType],
        processType
    }))

    const toggleAttachment = function (typeStatusPair) {
        setAttachmentFlags({
            ...attachmentFlags,
            ...typeStatusPair
        })
    }

    const isExportDescriptorEmpty = () => !processTypesData || !Object.keys(processTypesData).length ||
        Object.values(processTypesData).every(flag => !flag) || !attachmentFlags ||
        !Object.keys(attachmentFlags).length || Object.values(attachmentFlags).every(flag => !flag)

    const buildExportDescriptorsData = () => {
        const attachmentDescriptors = {}
        Object.keys(attachmentFlags).forEach(attachmentType => attachmentDescriptors['include_' + attachmentType] = attachmentFlags[attachmentType])
        const exportedPurposes = Object.keys(processTypesData).filter(processType => processTypesData[processType])
        return {
            purposes: exportedPurposes,
            ...attachmentDescriptors
        }
    }

    return (
        <DialogView open={open} setOpen={() => setOpen} className="md:w-auto">
            <div className="px-8 pt-6 mx-auto">
                <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">{t('export_overview.intro')}</h3>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                    {filterDescriptions.map((filterDescription, index) =>
                        <FilterDescriptor
                            key={"filterDescriptor" + index}
                            description={filterDescription.description}
                            descriptionValue={filterDescription.descriptionValue}
                            warning={filterDescription.warning}
                        />
                    )}
                </div>
            </div>
            <div className="px-8 pt-6 mx-auto">
                <div className="my-4 flex">
                    <p className="font-bold">{t('export_overview.type')}</p>
                    <div className="pl-4">
                        {exportDescriptions.map((exportDescription, index) => {
                            if (processTypesData[exportDescription.processType] === true) {
                                return (
                                    <ExportDescriptor
                                        key={"exportDescription" + index}
                                        title={exportDescription.title}
                                        subtitle={exportDescription.subtitle}
                                        fileName={exportDescription.fileName}
                                        processType={exportDescription.processType}
                                    />
                                );
                            }
                        })}
                    </div>
                </div>
                <div className="my-4 flex">
                    <p className={"font-bold"}>{t('export_overview.format')}</p>
                    {EXPORT_FILE_TYPES[type][subtype].map((fileType, index) =>
                        <AttachmentType
                            key={"AttachmentType-" + fileType + index}
                            extension={fileType}
                            onChange={toggleAttachment}
                            checked={attachmentFlags[fileType]}
                        />
                    )}
                </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm disabled:opacity-50"
                    onClick={() => onConfirm(
                        buildExportDescriptorsData()
                    )}
                    disabled={isExportDescriptorEmpty()}
                >
                    {t("export_overview.confirm_button")}
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-200 text-base font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={onCancel}
                >
                    {t("export_overview.cancel_button")}
                </button>
            </div>
        </DialogView>
    )
}