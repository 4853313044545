import { MultiSelect, MultiSelectItem } from "@tremor/react"
import { capitalize } from "../../../../utils/StringUtils"
import { useQuery } from "react-query"
import { useEffect, useState } from "react"
import { fetchStoreCubboShippingMethods } from "../../../../services/shippingMethodsServices"
import { useTranslation } from "react-i18next"
import { SlidersIcon } from "../../../../components/Icons/SlidersIcon"

export const SelectMultipleCarriers = ({ storeId, onChange = (v) => {} }) => {
    const { t } = useTranslation()

    const [controlledValue, setControlledValue] = useState([])
    const [carrierOptions, setCarrierOptions] = useState([])

    const {
        data: shippingMethodData,
        // isFetching,
        isLoading: isLoadingShippingMethods,
        // refetch
    } = useQuery(["store_metrics_shipping_methods", storeId], () => fetchStoreCubboShippingMethods(storeId, { with_tracking_only: true }, false), {
        enabled: !!storeId,
        refetchOnWindowFocus: true,
        staleTime: 1000 * 60 * 60 * 1, // after 1h, refetch if screen is focused
        cacheTime: 1000 * 60 * 60 * 2, // 2h
    })

    useEffect(() => {
        const formatCarrierOptions = async (data) => {
            let carrierOptions = []
            if (!data) return
            for (const shippingMethod of data) {
                if (carrierOptions.findIndex((item) => item.value === shippingMethod.carrier_name) !== -1) continue
                const option = {
                    value: shippingMethod.carrier_name,
                    label: capitalize(shippingMethod.carrier_name),
                    key: shippingMethod.carrier_name,
                }
                carrierOptions.push(option)
            }
            carrierOptions.sort((a, b) => {
                if (a.label < b.label) return -1
                if (a.label > b.label) return 1
                return 0
            })
            setCarrierOptions(carrierOptions)
        }

        formatCarrierOptions(shippingMethodData).catch((error) => {
            console.error(error)
        })
    }, [shippingMethodData])

    const getCarrierIds = (carriers) => {
        if (!shippingMethodData) return null
        const foundCarriers = shippingMethodData.filter((item) => carriers.includes(item.carrier_name))
        return foundCarriers.map((item) => item.id)
    }

    const handleOnChange = (v) => {
        setControlledValue(v)
        onChange(getCarrierIds(v), v)
    }

    const Placeholder = () => <span className="pl-4">{t("metrics.collected_to_door.select.all_carriers")}</span>

    return (
        <MultiSelect
            className="text-gray-500 !text-base sm:w-full md:max-w-md"
            value={controlledValue}
            onValueChange={(v) => handleOnChange(v)}
            disabled={isLoadingShippingMethods}
            // placeholder={<Placeholder />}
            placeholder={t("metrics.collected_to_door.select.all_carriers")}
            // icon={() => <SlidersIcon className="h-[1em] w-[1em] ml-2" />}
        >
            {carrierOptions.map((item) => {
                return (
                    <MultiSelectItem value={item.value} key={item.key}>
                        {item.label}
                    </MultiSelectItem>
                )
            })}
        </MultiSelect>
    )
}
