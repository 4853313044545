import { Trans, useTranslation } from "react-i18next"

import { numberToString, formatMoney } from "../../helpers/internationalization"
import { Skeleton } from "antd"
import { FormattedDate } from "../../components"
import { useMemo } from "react"

const StoreBillingTotals = ({isLoading = false, data}) => {
    const { i18n } = useTranslation()
    const chargesWithoutTaxes = data?.total_positive || 0
    const calculatedIVA = data?.taxes || 0
    const totalDiscount = data?.total_negative || 0
    const total = data?.total_billed || 0
    const totalWithTax = parseFloat(total) + parseFloat(calculatedIVA)

    const currencyCode = data?.currency || ""
    const paymentLimitDate = data?.pay_before
    const cuttoffDate = data?.cuttoff_date
    const hasStatement = paymentLimitDate !== null

    const currency = useMemo(() => {
        return (i18n.language === "es" && currencyCode === "MXN") ? i18n.t(`billing.currencies.${currencyCode}`) : ""
    }, [currencyCode, i18n.language])

    return (
        <>
            <>
                <div className="font-bold">
                    {i18n.t("billing.totals.statement_summary")}
                </div>
                {hasStatement ? "" :
                    <div className="inline">
                        <span className="font-medium">{i18n.t("billing.totals.important")}</span>{i18n.t("billing.totals.no_statement")}
                    </div>
                }
            </>
            <div className="bg-white px-5 flex gap-5 rounded-lg w-full justify-between">
                

               

                <div className="py-2 px-5 my-5 flex-grow bg-gray-50 rounded-lg">
                    <div className="text-gray-500">
                        {currencyCode === "BRL" ? i18n.t("billing.totals.charges") : i18n.t("billing.totals.charges_without_taxes")}
                    </div>
                    {isLoading ?
                        <Skeleton style={{ paragraphs: 2 }} title={false} paragraph active={true} /> :
                        <div className="text-lg font-semibold">
                            {`${formatMoney(chargesWithoutTaxes, i18n.language, currencyCode)} ${currency}`}
                        </div>
                    }
                </div>
                
                <div className="flex items-center m-5">
                    <div className="w-3 h-6">-</div>
                </div>

                <div className="py-2 px-5  my-5 flex-grow bg-gray-50 rounded-lg">
                    <div className="text-gray-500">
                        {i18n.t("billing.totals.total_discount")}
                    </div>
                    <div className="text-lg text-green-500 font-semibold">
                        {isLoading ?
                        <Skeleton title={false} paragraph={{ rows: 1 }} active={true} /> :
                        `${formatMoney(-totalDiscount, i18n.language, currencyCode)} ${currency}`}
                    </div>
                </div>

                {currencyCode !== "BRL" && (
                    <>
                    <div className="flex items-center m-5">
                        <div className="w-3 h-6">+</div>
                    </div>

                    <div className="py-2 px-5  my-5 flex-grow bg-gray-50 rounded-lg">
                        <div className="text-gray-500">
                            {i18n.t("billing.totals.tax_IVA")}
                        </div>
                        <div className="text-lg font-semibold">
                            {isLoading ?
                            <Skeleton title={false} paragraph={{ rows: 1 }} active={true} /> :
                            `${formatMoney(calculatedIVA, i18n.language, currencyCode)} ${currency}`}
                        </div>
                        <div className="text-gray-400 text-sm">
                            {`16 ${i18n.t("billing.totals.percentage_IVA")}`}
                        </div>
                    </div>
                    </>
                )}

                <div className="flex items-center m-5">
                    <div className="w-3 h-6">=</div>
                </div>

                <div className="py-2 px-5 my-5 flex-grow bg-gray-100 rounded-lg">
                    <div className="text-gray-500">
                        {hasStatement ?
                        i18n.t("billing.totals.total") :
                        i18n.t("billing.totals.total_to_date")}
                    </div>
                    {isLoading ?
                        <Skeleton style={{ paragraphs: 2 }} title={false} paragraph active={true} /> :
                        <>
                            <div className="font-bold text-lg">
                                {`${formatMoney(totalWithTax, i18n.language, currencyCode)} ${currency}`
                                }
                            </div>
                            <div className="text-sm">
                                {hasStatement ?
                                <>
                                    <span>{i18n.t("billing.totals.payment_limit_date")}: </span>
                                    <FormattedDate date={paymentLimitDate} dayMonthYearDate/>
                                </> :
                                <>
                                    <span>{i18n.t("billing.totals.next_cutoff_date")}: </span>
                                    <FormattedDate date={cuttoffDate} dayMonthYearDate/>
                                </>
                                }
                            </div>
                        </>
                    }
                    
                </div>
            </div>
        </>
    )
}

export default StoreBillingTotals