import { Switch } from "@headlessui/react"

const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ")
}

export const SwitchToggleButton = ({ value = false, onChange = () => {}, disabled = false, hasStrongBackground = false }) => {
    const onHandleChange = (value) => {
        if (disabled === false) onChange(value)
    }

    return (
        <Switch
            checked={value}
            onChange={onHandleChange}
            className={classNames(
                disabled ? "opacity-75 cursor-default" : 'cursor-pointer',
                value ? "bg-indigo-600" : (hasStrongBackground ? "bg-gray-600" : "bg-gray-300"),
                "m-1 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            )}
        >
            <span
                aria-hidden="true"
                className={classNames(
                    value ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
            />
        </Switch>
    )
}
