import { useMemo } from "react"
import { getClickToDoorInDays } from "../../../../services/storeMetricsService"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { numberToString } from "../../../../helpers/internationalization"
import { BarList } from "../../../../components/MetricsCards/BarList"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"
import { DEFAULT_QUERY_STALE_TIME } from "../chartDataUtils"


export const ClickToDoorByDays = ({ storeId = null, dateFilter = null }) => {
    const { i18n, t } = useTranslation()

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["click_to_door_in_days", storeId, dateFilter], () => getClickToDoorInDays(storeId, dateFilter), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    const clickToDoor = useMemo(() => {
        if (!data) return []
        let newOrderOrigins = []
        Object.keys(data).forEach((key) => {
            let name = key == "0" ? t("metrics.click_to_door.by_days.less_than_1_day") 
                        : key == "1" ? t("metrics.click_to_door.by_days.day", {day: key})
                        : key == "6" ? t("metrics.click_to_door.by_days.grouped_rows_label")
                        : t("metrics.click_to_door.by_days.days", {days: key}) 
            newOrderOrigins.push({
                key: key,
                value: data[key],
                name: name,
            })
        })
        newOrderOrigins.sort((a, b) => a.key -  b.key)
        return newOrderOrigins
    }, [data, i18n.language])

    return (
        <div className="h-fit">
            <MetricTitle title={t("metrics.click_to_door.by_days.title")}/>
            <BarList
                mainColumnLabel={t("metrics.click_to_door.by_days.main_column_label")}
                secondaryColumnLabel={t("metrics.click_to_door.by_days.secondary_column_label")}
                data={clickToDoor}
                valueFormatter={(n) => numberToString(n, i18n.language)}
                isLoading={isLoading}
                maxRowsLabel={t("metrics.click_to_door.by_days.grouped_rows_label")}
                maxRows={clickToDoor.length}
                sortData={false}
            />
        </div>
    )
}
