import React from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import "react-image-crop/dist/ReactCrop.css";
import { editStoreData, fetchStoreInfo} from "../../services/storeServices"
import {useContext, useState, useEffect, useRef} from "react";
import { useTranslation } from 'react-i18next';
import { Button, ButtonLoader, Notification, InputField } from "../../components";
import { ImageInputField } from "../../components/ImageInputField";
import {useForm} from "react-hook-form";
import { Tooltip } from "antd"
import { TrashIcon  } from "@heroicons/react/outline"
import {useQuery} from "react-query";
import { UserContext } from "../../hooks/UserContext";
import { useSelector} from "react-redux";
import { Loader } from "../../components/Loader";

function generateDownload(canvas, image, crop) {
  if (!crop || !canvas) {
    return;
  }
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        // Si algo sale mal, rechaza la promesa con un error
        console.error("Error al recortar la imagen.");
        reject();
        return;
      }
      blob.name = "cropped.jpeg";
      window.URL.revokeObjectURL(image.src);
      const url = window.URL.createObjectURL(blob);
      resolve(blob);
    }, "image/png");
  });

}

function setCanvasImage(image, canvas, crop) {
  if (!crop || !canvas || !image) {
    return;
  }
 const ctx = canvas.getContext('2d')

 if (!ctx) {
   throw new Error('No 2d context')
 }

 const scaleX = image.naturalWidth / image.width
 const scaleY = image.naturalHeight / image.height
 const pixelRatio = window.devicePixelRatio

 canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
 canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

 ctx.scale(pixelRatio, pixelRatio)
 ctx.imageSmoothingQuality = 'high'

 const cropX = crop.x * scaleX
 const cropY = crop.y * scaleY

 const centerX = image.naturalWidth / 2
 const centerY = image.naturalHeight / 2

 ctx.save()

 ctx.translate(-cropX, -cropY)
 ctx.translate(centerX, centerY)
 ctx.translate(-centerX, -centerY)

 ctx.drawImage(
   image,
   0,
   0,
   image.naturalWidth,
   image.naturalHeight,
   0,
   0,
   image.naturalWidth,
   image.naturalHeight,
 )

 ctx.restore()
}

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    const crop = makeAspectCrop(
      {
        unit: 'px',
        width: 600,
        height: 400,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    );
    return centerCrop(crop, mediaWidth, mediaHeight);
}

export const BrandIdentitySettingsContainer = () => {
	const inputRef = React.useRef();
    const {register, handleSubmit,formState: { errors }} = useForm({})

    const { t } = useTranslation();
    const [loadingImage, setLoadingImage] = useState(false)
    const {user} = useContext(UserContext)
    const [imageError, setImageError] = useState(null)
    const [errorMessage, setErrorMessage] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [loadingBundleBotton, setLoadingBundleBotton] = useState(false)
    const [showNotification, setShowNotification] = useState(false)

    const [storeData, setStoreData] = useState({});
    const [editing, setEditing] = useState(false);
    const [image, setImage] = useState(null);
    const [logo, setLogo] = useState(null);
    const [preview, setPreview] = useState("https://label-generator.s3.us-east-2.amazonaws.com/Isologo_Cubbo.png");
    
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: "px", width: 1, length: 1});
    const [completedCrop, setCompletedCrop] = useState(null);


    const {
        isLoading,
        isError,
        error,
        data,
        isPreviousData,
        refetch,
    } = useQuery(['store_info_obs', user.current_store?.id], () => fetchStoreInfo(user.current_store?.id), { keepPreviousData: true })

    useEffect(() => {
        if(data?.logo_file ){
          setLogo(data?.logo_file)
          setPreview(process.env.REACT_APP_PLATFORM_URL + data?.logo_file?.path)
        }
        setStoreData(data)
    }, [data])

    useEffect(() => {
      if(!data) return

      if (data?.name != storeData.name || (data?.contact_email != storeData.contact_email && (data?.contact_email != null || storeData?.contact_email != null))){
        setEditing(true)
      } else setEditing(false)
    }, [storeData])

    const validData = () => {

      if ((editing  || (!logo && image ) ) && storeData?.name != '' ) {
        return false
      }
      return true
    }

    const filesChangeHandler = (files) => {
        if (image) {
            setImageError("configurar solo una imagen ")
        }
        else {
            if (files.length > 0) {
                setImageError(null)
                const reader = new FileReader();
                if (reader.readyState !== FileReader.LOADING) {
                  reader.addEventListener("load", () => setImage(reader.result));
                  reader.readAsDataURL(files[0]);

                }
            }
        }
        
    }

    const removeImage = () => {
        setImage(null)
        setLogo(null)
    }

    const onSubmit = async () => {
      var formData = new FormData();
      if (image){
        let croppedImage = await generateDownload(previewCanvasRef.current, imgRef.current, completedCrop);
        formData.append('logo', croppedImage);
      }
        formData.append('name', storeData.name); 
        if(storeData.contact_email){
          formData.append('contact_email', storeData.contact_email);
        }
        editStoreData(user.current_store?.id, formData).then((resultado)=>{
            setImage(null)
            setLogo(preview)
            setSuccessMessage(t("settings.tax_fields.success_message"))
            setShowNotification(true)
            setLoadingBundleBotton(false)
        }).catch((e)=>{
            setErrorMessage(t("settings.tax_fields.error_message"))
            setShowNotification(true)
            setLoadingBundleBotton(false)
        })
        
    }

    

    useEffect(() => {
      setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
      generatePreview()
    }, [completedCrop]);
  
    function onImageLoad(e) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, 16 / 9));
        setCompletedCrop(centerAspectCrop(width, height, 16 / 9))
        generatePreview()
    }

    const generatePreview = () => {
      if (!previewCanvasRef.current) {
        return
      }
      previewCanvasRef.current.toBlob(blob => {
        if (!blob) {
          // Si algo sale mal, rechaza la promesa con un error
          console.error("Error al recortar la imagen.");
          return;
        }
        blob.name = "cropped.jpeg";
        window.URL.revokeObjectURL(image.src);
        const url = window.URL.createObjectURL(blob);
        setPreview(url)
      }, "image/png");
    }

	return (
        <>

        <div className='relative p-10'>
            <form action="#" method="PATCH" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center justify-between">
              <div className="space-y-1">
                  <p className='text-3xl mb-0 font-bold text-blue-gray-900 '>{t("settings.brand_identity.title_sbr")}</p>
                  <p className = "mt-5 text-lg text-gray-500">{t("settings.brand_identity.sub_title_sbr")}</p>
              </div>
              <>
                  <ButtonLoader className="" loading_text={t("settings.tax_fields.processing")} loading={loadingBundleBotton} disabled={validData()}>
                      {t("settings.tax_fields.send_form")}
                      
                  </ButtonLoader>
                  {errorMessage && showNotification? (
                      <>
                          <Notification show={showNotification} setShow={setShowNotification} type="error" title={t("settings.user_settings.error")} description={errorMessage}/>
                      </>
                  ) : null}
                  {successMessage && showNotification? (
                      <>
                          <Notification show={showNotification} setShow={setShowNotification} type="success" title={t("settings.user_settings.done")} description={successMessage}/>
                      </>
                  ) : null}
              </>
            </div>

            { !isPreviousData && isLoading && !data ? (
                  <><Loader show={true}></Loader></>
              ): isError ? (
                  <>Error: {error.message}</>
              ):
              (

                <div className="w-full">
                    <div className="mt-10">
                        <div className="mb-8">
                            <div className="max-w-full min-w-min">
                              <div className="bg-white shadow sm:rounded-lg border-gray-200 rounded-2xl grid grid-row p-7">
                                    <p className = "mt-0 mb-1 text-lg font-bold text-gray-900"> {t("settings.brand_identity_container.name_title")} <span className="text-red-500"> *</span></p>
                                    <p className = "mt-0 mb-2 text-sm font-regular text-gray-900">{t("settings.brand_identity_container.name_description")}</p>

                                    <InputField
                                        value={storeData?.name}
                                        onChange={(v) => {
                                          setStoreData({ ...storeData, name:v})
                                        }}
                                        required
                                    />
                                    <p className="text-sm text-red-600">{storeData?.name === '' && t("orders.addresView.required_field")}</p>

                              </div>
                            </div>
                        </div>
                        <div className="mb-8">
                          <div className="max-w-full min-w-min">
                              <div className="bg-white shadow sm:rounded-lg border-gray-200 rounded-2xl grid grid-row p-7">
                                
                                  <p className = "mt-0 mb-1 text-lg font-bold text-gray-900 "> {t("settings.brand_identity_container.contact_title")} </p>
                                  <p className = "mt-0 mb-2 text-sm font-regular text-gray-900">{t("settings.brand_identity_container.contact_description")}</p>

                                  <InputField
                                        value={storeData?.contact_email}
                                        onChange={(v) => {
                                          setStoreData({ ...storeData, contact_email:v})
                                        }}
                                        
                                  />
                            </div>
                          </div>
                        </div>
                        
                        <div className="max-w-full min-w-min">
                            <div className="bg-white shadow sm:rounded-lg border-gray-200 rounded-2xl grid grid-row ">
                              <div className="grid grid-cols-2 gap-y-5 gap-x-8 sm:grid-cols-2 w-full justify-center">
                                  <div className="p-7">
                                    <p className = "mt-0 mb-1 text-lg font-bold text-gray-900"> {t("settings.brand_identity_container.logo_title")} </p>
                                    <p className = "mt-0 mb-0 text-sm font-medium text-gray-700"> {t("settings.brand_identity_container.logo_description")} </p>

                                    <div>
                                      <Loader show={loadingImage} />
                                      { logo ? (
                                          <>
                                            <div
                                                className="flex items-center justify-center p-2 m-2 bg-cover bg-no-repeat bg-center rounded shadow relative"
                                                style={{
                                                    width: '200px', height: '200px',
                                                  }}
                                                  >
                                                <img
                                                  src={ logo.path ? process.env.REACT_APP_PLATFORM_URL + logo.path : preview}
                                                  alt="img"
                                                />
                                                <div
                                                    onClick={() => removeImage()}
                                                    className="absolute -right-1 -top-1 rounded-full bg-white border border-gray-600 p-1 cursor-pointer"
                                                >
                                                    <TrashIcon className="h-4 w-4 text-gray-600" />
                                                </div>
                                            </div>
                                          </>
                                      ) : (!image && !logo ) ?
                                      <>
                                          <ImageInputField
                                              containerClassName='mt-2'
                                              onChange={(files) => filesChangeHandler(files)}
                                              onError={(error, file, message) => setImageError(message)}
                                              multiple={false}
                                              maxFiles={1}
                                              required={true}
                                              placeholder = {t("addProduct.container.drag_image")} 
                                              
                                          />
                                          <p className="mt-2 text-sm text-gray-500">{t("settings.brand_identity_container.logo_recomendation")}</p>
                                          {imageError && (
                                              <div className="text-red-500 text-center italic mt-2">{imageError}</div>
                                          )}

                                        

                                      </>
                                      :
                                      <>  
                                      <div className="flex " style={{ 'width': '300px', 'max-height': '300px' }}>
                                        
                                      
                                        <div className="relative mt-10">
                                          <ReactCrop
                                            crop={crop}
                                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                                            onComplete={(c) => setCompletedCrop(c)}
                                            minWidth={200}
                                            minHeight={100}
                                            maxWidth={600}
                                            maxHeight={200} 
                                          >
                                            <img
                                              ref={imgRef}
                                              alt="Crop me"
                                              src={image}
                                              onLoad={onImageLoad}
                                              className="max-h-56"
                                            />
                                          </ReactCrop>
                                          <div
                                              onClick={() => removeImage()}
                                              className="absolute -right-1 -top-1 rounded-full bg-white border border-gray-600 p-1 cursor-pointer"
                                          >
                                              <TrashIcon className="h-4 w-4 text-gray-600" />
                                          </div>
                                        </div>
                                        
                                        <div className="hidden">
                                          {/* Canvas to display cropped image */}
                                          {completedCrop &&
                                          <canvas
                                                ref={previewCanvasRef}
                                                style={{
                                                  objectFit: 'contain',
                                                  width: completedCrop.width,
                                                  height: completedCrop.height,
                                                }}
                                              />
                                          }
                                        </div>
                                      </div>

                                      </>
                                      }

                                  </div>
                                  </div>
                                  <div className="bg-gray-50 p-7 pb-0">
                                  <p className = "mt-3 mb-7 text-lg font-medium text-gray-500">{t("settings.brand_identity_container.logo_preview")}</p>

                                      <div className="max-w-full min-w-min">
                                        <div className="grid grid-row ">
                                            {preview &&
                                              <div className="grid grid-cols-2 gap-y-5 sm:grid-cols-2  w-full flex justify-items-center">
                                                <div>

                                                  
                                                  <svg viewBox="0 0 430 711" width="100%" height="80%"  fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect x="0.5" y="0.5" width="429" height="710" rx="14.5" fill="#F3F4F6" stroke="#D1D5DB"/>
                                                  <rect x="52" y="189" width="326" height="52" rx="7" fill="white"/>
                                                  <rect x="52" y="271" width="326" height="52" rx="7" fill="white"/>
                                                  <rect x="126" y="367" width="178" height="52" rx="7" fill="#4B5563"/>
                                                  <rect x="184" y="647" width="62" height="37" fill="url(#pattern0)"/>
                                                  <rect x="52" y="46" width="90" height="50" fill="url(#pattern1)"/>
                                                  <defs>
                                                  <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                  <use href="#image0_161_746" transform="matrix(0.000520291 0 0 0.00087184 -0.0202914 0)"/>
                                                  </pattern>
                                                  <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                  <use href="#image1_161_746" transform="matrix(0.000520291 0 0 0.00087184 -0.0202914 0)"/>
                                                  </pattern>
                                                  <image id="image1_161_746"  width="1969" height="791" href={preview}/>
                                                  <image id="image0_161_746" width="2000" height="1147"  href="https://label-generator.s3.us-east-2.amazonaws.com/p_b_cubbo.png"/>

                                                  </defs>
                                                  </svg>
                                                  <p className = "mt-5 mb-0 text-sm text-center text-gray-500">{t("settings.brand_identity_container.preview_tracking")}</p>

                                                </div>
                                                <div >
                                                  <svg viewBox="0 0 430 711" width="100%" height="80%"  fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                  <rect x="0.5" y="0.5" width="429" height="717" rx="14.5" fill="#F3F4F6" stroke="#D1D5DB"/>
                                                  <rect x="32" y="131" width="367" height="436" rx="7" fill="white"/>
                                                  <rect x="59" y="164" width="102" height="5" rx="2.5" fill="#6B7280"/>
                                                  <rect x="59" y="211" width="314" height="5" rx="2.5" fill="#6B7280"/>
                                                  <rect x="59" y="301" width="314" height="5" rx="2.5" fill="#6B7280"/>
                                                  <rect x="59" y="256" width="314" height="5" rx="2.5" fill="#6B7280"/>
                                                  <rect x="59" y="346" width="314" height="5" rx="2.5" fill="#6B7280"/>
                                                  <rect x="184" y="647" width="62" height="37" fill="url(#pattern0)"/>
                                                  <rect x="167" y="46" width="90" height="50" fill="url(#pattern1)"/>
                                                  <defs>
                                                  <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                  <use href="#image0_161_745" transform="matrix(0.000520291 0 0 0.00087184 -0.0202914 0)"/>
                                                  </pattern>
                                                  <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                  <use href="#image1_161_745" transform="matrix(0.000520291 0 0 0.00087184 -0.0202914 0)"/>
                                                  </pattern>
                                                  <image id="image1_161_745"  width="1969" height="791" href={preview}/>
                                                  <image image id="image0_161_745" width="2000" height="1147"  href="https://label-generator.s3.us-east-2.amazonaws.com/p_b_cubbo.png"/>


                                                  </defs>
                                                  </svg>
                                                  <p className = "mt-5 mb-0  text-sm  text-center text-gray-500">{t("settings.brand_identity_container.preview_email")}</p>
                                                </div>
                                              </div>
                                              }
                                        </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
              )}
           
          
            </form>
        </div>
        </>
	);
}
