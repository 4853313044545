import { Grid, Col } from "@tremor/react"
import { BaseCard } from "../../../../components/MetricsCards/BaseCard"
import { MetricsCardHeader } from "../../../../components/MetricsCards/MetricsCardHeader"
import { useTranslation } from "react-i18next"
import { ReplenishmentsWithin24h } from "./ReplenishmentsWithin24h"
import { MetricHighlighted } from "../../../../components/MetricsCards/MetricHighlighted"
import { useQuery } from "react-query"
import { getTotalStockReceived } from "../../../../services/storeMetricsService"
import { numberToString } from "../../../../helpers/internationalization"
import useOnScreen from "../../../../hooks/useOnScreen"
import { useEffect, useRef } from "react"
import { DEFAULT_QUERY_STALE_TIME } from "../chartDataUtils"

export const Replenishments = ({ storeId = null, dateFilter = null }) => {
    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["total_stock_received", storeId, dateFilter], () => getTotalStockReceived(storeId, dateFilter), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refech if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    const { i18n, t } = useTranslation()

    const cardRef = useRef(null)
    const cardIsOnScreen = useOnScreen(cardRef)
    useEffect(() => {
        if (cardIsOnScreen) window.analytics.track("Metrics - Views Replenishments")
    }, [cardIsOnScreen])

    return (
        <BaseCard className="my-6" ref={cardRef}>
            <MetricsCardHeader title={t("metrics.replenishments.title")} subtitle={t("metrics.replenishments.subtitle")} />
            <MetricHighlighted
                text={t("metrics.replenishments.total_received.text", { total: numberToString(data?.total_stock_received, i18n.language) })}
                description={t("metrics.replenishments.total_received.description")}
                isLoading={isLoading}
                className="mt-4 mb-10"
            />
            <Grid numItemsSm={1} numItemsLg={3} className="w-full gap-10 lg:gap-x-20">
                <Col numColSpan={1}>
                    <ReplenishmentsWithin24h storeId={storeId} dateFilter={dateFilter} />
                </Col>
            </Grid>
        </BaseCard>
    )
}
