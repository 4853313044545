import "./App.css"
import "./index.css"

import { BrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import { RouterConfig } from "./navigation"
import useFindUser from "./hooks/useFindUser"
import { UserContext } from "./hooks/UserContext"
import LogRocket from "logrocket"
import { clarity } from 'react-microsoft-clarity';
import setupLogRocketReact from "logrocket-react"
import { isProductionEnvironment } from "./helpers/environmentHelper"
import { IntercomContainer } from "./components/Intercom/IntercomContainer"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

const queryClient = new QueryClient()

function App() {
    const { i18n } = useTranslation()

    if (isProductionEnvironment) {
        if (clarity.hasStarted()) {
            clarity.init('nyo1ldf5zp');
        }
        LogRocket.init("lkrho1/customer-app-production")
        setupLogRocketReact(LogRocket)
    }
    
    const { user, setUser, isLoading } = useFindUser()

    // update html tag lang attribute when translation changes
    useEffect(() => {
        document.documentElement.lang = i18n.language
    }, [i18n.language])

    return (
        <IntercomContainer>
            <QueryClientProvider client={queryClient}>
                <UserContext.Provider value={{ user, setUser, isLoading }}>
                    <BrowserRouter>
                        <RouterConfig />
                    </BrowserRouter>
                </UserContext.Provider>
            </QueryClientProvider>
        </IntercomContainer>
    )
}

export default App
