import { useState, useContext, useRef, useEffect } from "react"
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom"
import { useQuery } from "react-query"
import {
  ActionMenu,
  BasicTable,
  FormattedDate,
  PageTopBar,
  PageView,
  SlidePanel,
} from "../../components"
import { StatusPill } from "../../components/BasicTable/StatusPill"
import { CLAIMS_STATUSES } from "../../components/BasicTable/StatusPillDefinitions"
import { Loader } from "../../components/Loader"
import { SelectColumnFilter } from "../../components/BasicTable/modules/ColumnFiltersUI"
import { UserContext } from "../../hooks/UserContext"
import { fetchClaims } from "../../services/claimServices"
import { CLAIMS, SHOW_CLAIM, SHOW_ORDERS, STORE_PATH } from "../../navigation/constants"
import { ClaimDetailContainer } from "./ClaimDetailContainer"
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { setClaimsListPageSize } from "../../redux/pageSizeSlice"


export const ListClaimsContainer = () => {
  const { t, i18n} = useTranslation();
  const { user } = useContext(UserContext)
  const { url:storePath } = useRouteMatch({ path: STORE_PATH })
  const history = useHistory()
  const pageSize = useSelector((state) => state.pageSize.ordersList);
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useState({
    page: 1,
    store_id: user.current_store?.id,
    per_page: pageSize
  })

  const tableRef = useRef()

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(["claims", searchParams], () => fetchClaims(searchParams), {
    keepPreviousData: true,
  })

  useEffect(() => {
    window.analytics.page('Claims', 'List')
  }, [])

  const columns = [
    {
      Header: "orders.list.order_number",
      accessor: "order_number", // accessor is the "key" in the data
      disableFilters: false,
      disableSortBy: true,
    },
  
    {
      Header: "orders.list.status",
      accessor: "status", // accessor is the "key" in the data
      Filter: SelectColumnFilter,
      selectFilterOptions: CLAIMS_STATUSES.filter(
        (status) => status.filterable !== false
      ).map((status) => {
        return status.filterable === false
          ? null
          : { id: status.status, label: status.label }
      }),
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "claims.list.value",
      accessor: "value",
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "claims.list.accepted_value",
      accessor: "accepted_value",
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "claims.list.created_at",
      accessor: "created_at", // accessor is the "key" in the data
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "claims.list.resolved_at",
      accessor: "resolved_at", // accessor is the "key" in the data
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "",
      accessor: "actions", // accessor is the "key" in the data
      disableFilters: true,
      disableSortBy: true,
      fetchingIndicator: true,
      shrinkToContent: true,
    },
  ]

  const handlerOnClickStatus = (claim) => {
    history.push(storePath + SHOW_CLAIM.replace(":id", claim.id))
  }

  const onSortChange = (orderBy) => {
    if (orderBy.length > 0) {
      setSearchParams({
        ...searchParams,
        order_by: orderBy[0].id,
        order: orderBy[0].desc ? "ASC" : "DESC",
        page: 1
      })
    } else {
      if ("order_by" in searchParams) {
        delete searchParams.order_by
        delete searchParams.order
        setSearchParams({ ...searchParams, page: 1 })
      }
    }
    tableRef.current.resetPagination()
  }


  const onFilterChange = (activeFilters) => {
    columns.forEach((col) => {
      if (searchParams[col.accessor]) {
        delete searchParams[col.accessor]
      }
    })
    let filters = []
    activeFilters.forEach((filter) => {
      filters[filter.id] = filter.value
    })

    tableRef.current.resetPagination()
    setSearchParams({ ...searchParams, ...filters, page: 1 })
  }

  const getMenuOptions = (claim) => {
    const options = [
      {
        title: i18n.t("claims.list.see_detail"),
        clickHandler: () => history.push(storePath + SHOW_CLAIM.replace(":id", claim.id)),
      },
      {
        title: i18n.t("claims.list.show_orders"),
        clickHandler: () =>
          history.push(SHOW_ORDERS.replace(":id", claim.order.id)),
      },
    ]

    return options
  }

  const getTableData = () => {
    return data.claims.map((claim) => {
      return {
        order_number: (
          <div
            className="hover:underline cursor-pointer "
            onClick={() => handlerOnClickStatus(claim)}
          >
            #{String(claim.order.order_number)}
          </div>
        ),
        status: (
          <StatusPill
            className="cursor-pointer"
            status={claim.status}
            statuses={CLAIMS_STATUSES}
            onClick={() => handlerOnClickStatus(claim)}
          />
        ),
        value: <div>{String(`$${claim?.requested_value || claim?.value}`)}</div>,
        accepted_value: (
          <div>
            {claim.status === "PENDING" ? (
              <span className="italic"> {i18n.t("claims.list.pending")} </span>
            ) : claim.status === "REJECTED" ? (
              <span className="italic"> {i18n.t("claims.list.rejected")} </span>
            ) : (
              String(`$${claim.accepted_value ? claim.accepted_value : " -"}`)
            )}
          </div>
        ),
        created_at: <FormattedDate date={claim.created_at} />,
        resolved_at: <FormattedDate date={claim.resolved_at} />,
        actions: (
          <ActionMenu className="float-right" items={getMenuOptions(claim)} />
        ),
      }
    })
  }

  return (
    <>
      <PageView
        topMenu={
          <PageTopBar>
            <div className="text-lg font-semibold">
              <span className="text-gray-400">
                {user.current_store
                  ? user.current_store.name
                  : i18n.t("claims.list.all_stores")}{" "}
                &rarr; {i18n.t("topMenu.orders")} &rarr;{" "}
              </span>
              {i18n.t("claims.list.Disputes")}
            </div>
            {/* {user.current_store && (
              <Button
                className="whitespace-nowrap"
                onClick={() => {
                }}
              >
                Crear Disputa
              </Button>
            )} */}
          </PageTopBar>
        }
        childrenFullWidth={true}
        topMenuFullWidth={true}
      >
        {!isPreviousData && isLoading ? (
          <>
            <Loader show={true}></Loader>
          </>
        ) : isError ? (
          <>Error: {error.message}</>
        ) : (
          <BasicTable
            labelToPaginator = {i18n.t("orders.list.disputes")}
            showHeader
            showLoader={isFetching && isPreviousData}
            pagesSize = {searchParams.per_page}
            columns={columns}
            filterable
            sortable
            showPaginator
            data={getTableData()}
            onFilterChange={onFilterChange}
            onSortChange={onSortChange}
            paginationMeta={data?.meta}
            onPaginationChange={(requestedPage) => {
              setSearchParams({ ...searchParams, page: requestedPage })
            }}
            showCount = {true}
            onPageSizeChange= { (pageSize) => {
                setSearchParams({...searchParams, per_page: pageSize, page: 1})
                dispatch(setClaimsListPageSize(pageSize))
                tableRef.current.resetPagination()
            }}
            showDataStatus
            isFetching={isFetching}
            emptyTableText= {i18n.t("orders.list.no_orders_to_show")}
            ref={tableRef}
          />
        )}
      </PageView>
      <Switch>
        <Route exact path={STORE_PATH + SHOW_CLAIM}>
          <SlidePanel title= {i18n.t("claims.list.see_dispute")} referrer={storePath + CLAIMS}>
            <ClaimDetailContainer />
          </SlidePanel>
        </Route>
      </Switch>
    </>
  )
}
