import { useQuery } from "react-query"
import { useHistory, useParams } from "react-router"
import { Button } from "../../components"
import { Loader } from "../../components/Loader"
import { ORDERS } from "../../navigation"
import { fetchStore } from "../../services/storeServices"
import { useTranslation } from 'react-i18next';

export const StoreDetailContainer = ({
    onGoToStore = (store) => {console.log("go to store: ", store)}
}) => {
    const { t, i18n } = useTranslation(); 
    const { id } = useParams()
    const history = useHistory()

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData
    } = useQuery(['storeDetail', id], () => fetchStore(id)) 

    const formatDate = (dateString) => {
        let date = new Date(dateString)
        return date.toLocaleString()
    }

    return (
        <div className="h-full w-full relative">
            {
                (isLoading || isFetching) &&
                <Loader show={true}/>
            }
            <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">{i18n.t("store_detail_container.name")}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {data?.name}
                    </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">{i18n.t("store_detail_container.desc")}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {data?.description}
                    </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">{i18n.t("store_detail_container.created")}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {data?.created_at ? formatDate(data.created_at) : "-"}
                    </dd>
                </div>
            </dl>
            <div className="flex justify-center pt-5">
                <Button onClick={() => onGoToStore({...data})}>{i18n.t("store_detail_container.go_to_store")}</Button>
            </div>
        </div>
    )
}