import axiosInstance from "../helpers/axiosInstance";

export const fetchOrders = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders`, { params })
    return res.data
}

export const enqueueOrdersExport = async function (data) {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user-async-tasks/orders_export`, data)
    return res.data
}

export const enqueueOrdersBulkCreation = async function (store_id, orders) {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/import/orders`, {
        "store_id": store_id,
        "orders": orders
    })
    return res.data
}

export const fetchOrderCreateBulkTracker = async function (batch_id) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1//user-async-tasks/track_batch/${batch_id}`)
    return res.data
}

export const getOrdersExport = async function (jobId) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user-async-tasks/orders_export/${jobId}`)
    return res.data
}

export const fetchOrder = async (orderId, stock_summary_only = false) => {
    if(orderId===undefined){
        return null
    }
    let url = `${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/${orderId}`
    if (stock_summary_only) {
        url += `?stock_summary_only=true`
    }
    const res = await axiosInstance.get(url)
    return res.data
}

export const fetchOrderHistory = async (orderId, getShippingIncidents = true, getClaims = true) => {
    if(orderId === undefined || orderId === null) { return null }

    const config = {
        params: {
            shipping_incidents: getShippingIncidents,
            claims: getClaims,
        }
    }

    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/${orderId}/order_history`, config)
    return res.data
}

export const fetchOrderForClaimCreation = async (orderId) => {
    if(!orderId) return null

    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/${orderId}/claim_creation`)

    return res.data
}

/**
 * @param order {{shippingMethod: {id: string, name: string}, shippingDate: string, numberShipping: string, orderNumber: string, referenceName: string}}
 * @param shippingAddress {{status: string, firstName: string, lastName: string, phone: string, address: string, country: {id: string, name: string}, city: string, province: string, zipCode: string}}
 * @param billingAddress {{status: string, firstName: string, lastName: string, phone: string, address: string, country: {id: string, name: string}, city: string, province: string, zipCode: string}}
 */
const getOrderData = (order, shippingAddress, billingAddress) => {
    const shipping = {
        first_name: shippingAddress.firstName,
        last_name: shippingAddress.lastName,
        phone: shippingAddress.phone,
        email: shippingAddress.email,
        tax_id: shippingAddress.tax_id,
        company: shippingAddress.company,
        tax_regime: shippingAddress.tax_regime,
        state_registration: shippingAddress.state_registration,
        address1: shippingAddress.address,
        address2: shippingAddress.address2,
        number: shippingAddress.number,
        neighborhood: shippingAddress.neighborhood,
        country: shippingAddress.country,
        city: shippingAddress.city,
        province: shippingAddress.province,
        zip_code: shippingAddress.zipCode
    }
    const data = {
        shipping_method: order.shippingMethod.id,
        shipping_date: order.shippingDate,
        cash_on_delivery: order.is_cod,
        currency: order.currency,
        total_price: order.total_price,
        buyer_shipping_price: order.buyer_shipping_price,
        discount_price: order.discount_price,
        shipping: shipping,
        order_type: order.order_type,
        extra_fees_price: order.extra_fees_price,

        billing: billingAddress.status==="same" ? shipping : {
            first_name: billingAddress.firstName,
            last_name: billingAddress.lastName,
            phone: billingAddress.phone,
            email: billingAddress.email,
            tax_id: billingAddress.tax_id,
            company: billingAddress.company,
            tax_regime: billingAddress.tax_regime,
            state_registration: billingAddress.state_registration,
            address1: billingAddress.address,
            address2: billingAddress.address2,
            number: billingAddress.number,
            neighborhood: billingAddress.neighborhood,
            country: billingAddress.country,
            city: billingAddress.city,
            province: billingAddress.province,
            zip_code: billingAddress.zipCode
        },
    }
    return data
}

/**
 *
 * @param order {{shippingMethod: {id: string, name: string}, shippingDate: string, numberShipping: string, orderNumber: string, referenceName: string}}
 * @param products {[{product_id: integer, name: string, quantity: int}]}
 * @param shippingAddress {{status: string, firstName: string, lastName: string, phone: string, address: string, country: {id: string, name: string}, city: string, province: string, zipCode: string}}
 * @param billingAddress {{status: string, firstName: string, lastName: string, phone: string, address: string, country: {id: string, name: string}, city: string, province: string, zipCode: string}}
 * @param storeId {integer}
 * @returns {Promise<void>}
 */
export const saveOrder = async (
    order,
    shippingAddress,
    billingAddress,
    products,
    storeId,
    shippingDocuments,
    duplicatedFromOrderId
) => {

    const data = {
        ...getOrderData(order, shippingAddress, billingAddress),
        store_id: storeId,
        order_number: order.orderNumber,
        shipping_documents: shippingDocuments,
        products: products.map(item => {
            return {
                product_id: item.product_id,
                quantity: item.quantity, 
                price_per_item: item.price_per_item,
                discount_per_item: item.discount_per_item,
            }
        }),
        duplicated_from_order_id: duplicatedFromOrderId
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders`, data)
    return res.data
}

/**
 *
 * @param order {{shippingMethod: {id: string, name: string}, shippingDate: string, numberShipping: string, orderNumber: string, referenceName: string}}
 * @param products {[{id: integer, name: string, quantity: int, type: string}]}
 * @param shippingAddress {{status: string, firstName: string, lastName: string, phone: string, address: string, country: {id: string, name: string}, city: string, province: string, zipCode: string}}
 * @param billingAddress {{status: string, firstName: string, lastName: string, phone: string, address: string, country: {id: string, name: string}, city: string, province: string, zipCode: string}}
 * @param storeId {integer}
 * @returns {Promise<void>}
 */
export const updateOrder = async (
    id,
    order,
    shippingAddress,
    billingAddress,
    products = [],
    deletedProducts = [],
    shippingDocuments
) => {
    const productsTemp = [...products, ...deletedProducts.map(item => {
        if(item.id)
            return {
                id: item.id,
                type: "delete"
            }
    })]

    const data = {
        ...getOrderData(order, shippingAddress, billingAddress),
        order_lines: productsTemp,
        shipping_documents: shippingDocuments
    }
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/${id}`, data)
    return res.data
}

export const cancelOrder = async (id, reason = null) => {
    const data = {
        reason: reason || "User cancel the order"
    }
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/${id}/cancel`, data)
    return res.data
}

export const cancelOrders = async (ids) => {
    const data = {
        order_id: ids,
        reason: "User cancel the order"
    }
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/cancel_bulk`, data)
    return res.data
}

export const holdOrders = async (ids) => {
    const data = {
        order_id: ids
    }
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/hold_bulk`, data)
    return res.data
}

export const unholdOrders = async (ids) => {
    const data = {
        order_id: ids
    }
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/unhold_bulk`, data)
    return res.data
}

export const markAsPayedOrders = async (ids) => {
    const data = {
        order_id: ids
    }
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/mark_paid_bulk`, data)
    return res.data
}

export const payOrder = async (id) => {
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/${id}/paid`)
    return res.data
}

export const holdOrder = async (id) => {
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/${id}/hold`)
    return res.data
}

export const unholdOrder = async (id) => {
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/${id}/unhold`)
    return res.data
}
export const saveDocuments = async (data) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping_documents`, data)
    return res.data
}
export const deleteDocuments = async (id) => {
    const res = await axiosInstance.delete(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping_documents/${id}`)
    return res.data
}

export const generateLabel = async (
    orderId,
    shippingMethodId,
    shippingAddress,
    trackingNumber
) => {
    const data = {
        destination:{
            first_name: shippingAddress.firstName,
            last_name: shippingAddress.lastName,
            phone: shippingAddress.phone,
            email: shippingAddress.email,
            address1: shippingAddress.address,
            address2: shippingAddress.address2,
            country: shippingAddress.country,
            city: shippingAddress.city,
            province: shippingAddress.state,
            zip_code: shippingAddress.zipCode
        },
        shipping_details: {
            shipping_method: shippingMethodId,
        },
        tracking_number: trackingNumber
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping_label/generate/${orderId}`, data)
    return res.data
}

export const fetchOrderShippingDocumentAsFile = async (filePath, filename, contentType) => {
    let response = await fetch(`${process.env.REACT_APP_PLATFORM_URL}${filePath}`)
    let data = await response.blob()
    let file = new File([data], filename, { type: contentType })
    file.id = 'file-' + Math.random().toString(16).slice(2)
    return file
}

export const CreateOrderRemoveStock = async (order, products, storeId) => {
    const data = {
        store_id: storeId,
        order_number: order.orderNumber,
        order_type: order.order_type,
        products: products.map(item => {
            return {
                product_id: item.product_id,
                quantity: item.quantity, 
                price_per_item: item.price_per_item
            }
        }),
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/remove_stock`, data )
    return res.data
}

export const fetchUsages = async (orderId) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-service-usages/order-usages`, { params: {order_id: orderId} })
    return res.data
}