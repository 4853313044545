import axiosInstance from "../helpers/axiosInstance";


export const fetchProducts = async (params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/products`, { params })
    return res.data
}

export const fetchDuplicatedCodes = async (codes, store_id) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store/${store_id}/products/duplicated/codes`, { codes })
    return res.data
}

export const fetchSearchProducts = async (store_id, skus) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/search/products-by-sku`, { 
        "store_id": store_id,
        "skus": skus
    })

    return res.data
}

export const fetchStoreSkus = async (store_id, skus) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store/${store_id}/products/sku`, {
        "skus": skus
    })

    return res.data
}

export const fetchProduct = async (id) => {
    if(id==null)
        return null
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/products/${id}`)
    return res.data
}

export const fetchProductLots = async (id, params = {}) => {
    if(id==null)
        return null
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/products/${id}/lots`, { params } )
    return res.data
}


export const deleteProducts = async (params) => {
    if(params==null)
        return null
    const res = await axiosInstance.delete(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/products`, {params})
    return res.data
}

export const fetchProductImageFile = async (imagePath, filename, contentType) => {
    let response = await fetch(`${process.env.REACT_APP_PLATFORM_URL}${imagePath}`)
    let data = await response.blob()
    let imageFile = new File([data], filename, { type: contentType })
    imageFile.preview = {url: URL.createObjectURL(data), type: 'image'}
    imageFile.id = 'file-0'
    return imageFile
}

/**
 *
 * @param channel_id {int}
 * @param products {[{product_id: int, isParent: boolean, variant_id: int}]}
 * @returns {Promise<{task_id, status}>}
 */
export const importProducts = async (channel_id, products) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/${channel_id}/import_products`, {
        "products": products
    })
    return res.data
}

export const importAllProducts = async (channel_id) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/${channel_id}/import_all_products`)
    return res.data
}

export const exportInventory = async (store_id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${store_id}/export-inventory.csv`)
    return res.data
}

export const getInventoryExport = async function (jobId) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user-async-tasks/status/${jobId}`)
    return res.data
}

/**
 *
 * @param name {[{name: string, value: string}]}
 * @returns {string}
 */
const getName = (name) => {
    let names = ""
    console.log("name", name)
    for(let i=0; i<name.length; i++ ){
        names = `${names}${name[i].value} / `
    }
    return names.substring(0, names.length - 2)
}

/**
 *
 * @param hasVariants {boolean}
 * @param product {{name: string, description: string, skus: [{label: string, value:string}]}}
 * @param variants {[{name: [{name: string, value: string}], skus: [{label: string, value:string}], barCode: string}]}
 * @param options {[{name: string, tags: [string]}]}
 * @param productImage {{filename: [string], content: [string], contentType: [string]}}
 * @returns {Promise<void>}
 */

export const saveProduct = async (hasVariants, product, variants, options, store_id, productImage, selectedTax) => {
    const {name, price, weight, description, skus, barCode, isDropshipping, isFragile, hasExpiration, freshness, bufferStock, billing_name, isScannable, origin, is_packageless, has_imei} = product
    let data = {}
    if(hasVariants===false){
        data = {
            name: name,
            price: price,
            weight: weight,
            billing_name:billing_name,
            description: description,
            skus: skus.map((item)=>item.value),
            bar_code: barCode,
            store_id: store_id,
            is_dropshipping: isDropshipping,
            is_fragile: isFragile,
            has_expiration: hasExpiration,
            freshness: freshness,
            buffer_stock: bufferStock,
            is_scannable: isScannable,
            product_image: productImage, 
            tax_code: selectedTax ? selectedTax.id : null,
            origin: origin ? origin.id : null,
            is_packageless: is_packageless,
            has_imei: has_imei,
        }
    }
    else {
        data = {
            name: name,
            price: price,
            weight: weight,
            description: description,
            billing_name:billing_name,
            has_variants: true,
            has_expiration: hasExpiration,
            freshness: freshness,
            buffer_stock: bufferStock,
            options: options.map(item=>{
                return {
                    name: item.name,
                    tags: item.tags
                }
            }),
            variants: variants.map(variant => {
                return {
                    name: getName(variant.name),
                    skus: variant.skus.map((item)=>item.value),
                    bar_code: variant.barCode,
                    options_name: variant.name.map(item => { return { name: item.name, value: item.value} })
                }
            }),
            store_id: store_id,
            tax_code: selectedTax.id,
            origin: origin.id
        }
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/products`, data)
    return res.data
}


export const ImportProductsFromSheet = async (products, store_id) => {

    let data = {
        products: products,
        store_id: store_id
    }

    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/products/import/sheet`, data)
    return res.data
}

export const enqueueProductsBulkCreation = async function (store_id, products) {
    const res = await axiosInstance.post(
        `${process.env.REACT_APP_PLATFORM_URL}/api/v1/import/products`, {
            "store_id": store_id,
            "products": products
        })

    return res.data
}

/**
 *
 * @param product {{name: string, description: string, skus: [{label: string, value: string}]}}
 * @param products {[{product_id: integer, quantity: string}]}
 * @param storeId {integer}
 * @return {Promise<void>}
 */
export const saveKit = async (product, products, storeId, price = 0) => {
    console.log("saveKit", product);

    const {name, description, skus, barCode} = product
    const data = {
        name: name,
        description: description,
        skus: skus.map((item)=>item.value),
        bar_code: barCode,
        products: products.map(item=>{
            return {
                product_id: item.product_id,
                quantity: item.quantity,
                price: item.price
            }
        }),
        store_id: storeId,
        price
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/products/kits`, data)
    return res.data
}

/**
 *
 * @param id {integer}
 * @param product {{name: string, description: string, barCode: string}}
 * @param skus {[{id: integer, sku: string, type: string}]}
 */
export const updateSingleProduct = async (
    id,
    product,
    skus = [],
    productImage, 
    selectedTax = null
) => {
    const data = {
        name: product.name,
        billing_name:product.billing_name,
        description: product.description,
        is_dropshipping: product.isDropshipping,
        is_fragile: product.isFragile,
        has_expiration: product.hasExpiration,
        freshness: product.freshness,
        buffer_stock: product.bufferStock,
        price: product.price,
        weight: product.weight,
        bar_code: product.barCode,
        skus: skus,
        is_scannable: product.isScannable,
        product_image: productImage,
        tax_code: selectedTax ? selectedTax.id : null,
        origin:  product.origin ?  product.origin.id : null,
        is_packageless: product.is_packageless,
        has_imei: product.has_imei,
    }
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/products/${id}`, data)
    return res.data
}

/**
 *
 * @param id {integer}
 * @param parent {{name: string, description: string, barCode: string}}
 * @param variants {[{id: integer, skus: [{id: integer, sku: string, type: string}], bar_code: string, type: string}]}
 */
export const updateParentProductWithVariants = async (id, parent, variants, deletedVariants) => {
    const data  = {
        name: parent.name,
        description: parent.description,
        billing_name: parent.billing_name,
        bar_code: parent.barCode,
        variants: [
            ...variants,
            ...deletedVariants.map(item=> {return {
                id: item.id,
                type: "delete"
            }})
        ]
    }
    const res = await axiosInstance.put( `${process.env.REACT_APP_PLATFORM_URL}/api/v1/products/${id}`, data )
    return res.data
}

/**
 *
 * @param id {integer}
 * @param product {{name: string, description: string, barCode: string}}
 * @param skus {[{id: integer, sku: string, type: string}]}
 * @param products {[{id: integer, name: string, quantity: int, type: string}]}
 * @param productsForDelete {[{id: integer}]}
 * @variants variants {{}}
 */
export const updateKit = async (id, product, skus= [], products, productsForDelete) => {
    const data = {
        name: product.name,
        description: product.description,
        bar_code: product.barCode,
        skus: skus,
        price: product.price,
        kit_childs: [
            ...products,
            ...productsForDelete.map(item=> {return {id: item.id, type: "delete"}})
        ]
    }

    console.log("productsForDelete", productsForDelete)

    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/products/kits/${id}`, data)
    return res.data
}

export const searchByTaxId = async (search_code) => {

    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/products/search/tax_codes/`,{ params: {search_code} })
    return res.data
}