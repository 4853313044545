import { useLocation, Link, Switch, Route } from "react-router-dom";

export const TopTabs = function (
    {
        tabs = []
    }) {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const { pathname: currentUrl } = useLocation();

    const setCurrentValues = function (tabData) {
        tabData.current = tabData.href === currentUrl
    }

    tabs.forEach(tabData => setCurrentValues(tabData))

    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <nav className="flex space-x-4" aria-label="Tabs">
                    {tabs.map((tab) => (
                        <Link
                            key={'tab_lik_' + tab.name}
                            to={tab.href}
                            className={classNames(
                                tab.current ? 'bg-white text-gray-800' : 'bg-gray-200 text-gray-500 hover:text-gray-800',
                                'px-3 py-2 font-medium text-sm rounded-t-md'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                        >
                            {tab.name}
                        </Link>
                    ))}
                </nav>
            </div>
            <div className="tabs">
                <Switch>
                    {tabs.map((tab) => (
                        <Route key={'tab_route_' + tab.name} path={tab.href} component={tab.component} />
                    ))}
                </Switch>
            </div>
        </div>
    )
}