import axiosInstance from "../helpers/axiosInstance";

export const fetchClaims = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/claims`, { params })
    return res.data
}

export const fetchClaim = async (claimId) => {
    if(claimId===undefined){
        return null
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/claims/${claimId}`)
    return res.data
}

export const createClaim = async (data) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/claims_v2`, data)
    return res.data
}