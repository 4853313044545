import  i18n from "../../translations/index";

export const getReturnNewOrderLabel = (type = "", status) => {
    let foundIndex = RETURN_TYPES_NEW_ORDER_LABEL.findIndex(def => def.value === type)
    if (foundIndex === -1) {
      return null
    }
    else {
      if (RETURN_TYPES_NEW_ORDER_LABEL[foundIndex][status]) {
        return RETURN_TYPES_NEW_ORDER_LABEL[foundIndex][status]
      }
      return "UNDEFINED"
    }
  }
  
 export const RETURN_TYPES_NEW_ORDER_LABEL = [
    {
      value: "SIMPLE_RETURN",
      REQUESTED: "returns.newOrder.not_applicable",
      RECEIVED: "returns.newOrder.not_applicable",
      REENTERED: "returns.newOrder.not_applicable",
      ARRIVED_AT_WAREHOUSE: "returns.newOrder.waiting_reception",
    },
    {
      value: "EXCHANGE",
      REQUESTED: "returns.newOrder.awaiting_order",
      ARRIVED_AT_WAREHOUSE: "returns.newOrder.waiting_reception",
    },
    {
      value: "RETURNED_BY_CARRIER",
      CANCELLED: "returns.newOrder.forwarding_cancelled",
      PENDING: "returns.newOrder.decision_pending",
      RECEIVED: "returns.newOrder.not_applicable",
      REENTERED: "returns.newOrder.not_applicable",
      ARRIVED_AT_WAREHOUSE: "returns.newOrder.waiting_reception",
    },
    {
      value: "TO_BE_DETERMINED",
      CANCELLED: "returns.newOrder.forwarding_cancelled",
      PENDING: "returns.newOrder.waiting_reception",
    },
    {
      value: "UNIDENTIFIED",
      RECEIVED: "returns.newOrder.not_applicable",
      REENTERED: "returns.newOrder.not_applicable",
      ARRIVED_AT_WAREHOUSE: "returns.newOrder.not_applicable",
    }
  ]

  