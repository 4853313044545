class Order {

    /**
     *
     * @param products {[{id: integer, product_id: integer, quantity: integer}]} if item contains id is for update, if not containg id is for add
     * @return {[{id: integer, product_id: integer, quantity: integer, type: string }]}
     */
    static getProductsForUpdate(products){
        return products.map(item=>{
            if("id" in item){
                return {
                    id: item.id,
                    quantity: item.quantity,
                    price_per_item: item.price_per_item,
                    discount_per_item: item.discount_per_item,
                    type: "edit"
                }
            }
            return {
                product_id: item.product_id,
                quantity: item.quantity,
                price_per_item: item.price_per_item,
                discount_per_item: item.discount_per_item,
                type: "add"
            }
        })
    }
}

export default Order