import { useState } from "react";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";

import SelectProductDialog from "../../../../components/SelectProductDialog/SelectProductDialog";
import { Button } from "../../../../components";
import ProductsTable from "./ProductsTable";
import { PRODUCT_TABLE_ACCESSORS } from "../utils/productsTableUtils";

const ProductSelector = ({
    title,
    description,
    addProductText,
    addAnotherProductText,
    selectedProducts,
    onSelectProducts,
    productsQueryExtraParams={},
}) => {
    const [openSelectProductDialog, setOpenSelectProductDialog] = useState(false)

    const handleOnAddProducts = (newProducts) => {
        const nonDuplicatedNewProducts = newProducts?.filter((newProduct) => (
            !selectedProducts.find((product) => newProduct.id === product.id)
        ))?.map((newProduct) => ({
            ...newProduct,
            quantity: 1
        }))

        const updatedSelectedProducts = [...selectedProducts, ...nonDuplicatedNewProducts]

        onSelectProducts(updatedSelectedProducts)
        setOpenSelectProductDialog(false)
    }

    const updateProductQuantity = (productIndex, newProductQuantity) => {
        const updatedSelectedProducts = newProductQuantity > 0 ? selectedProducts.map((product, index) => ({
            ...product,
            quantity: index === productIndex ? newProductQuantity : product.quantity
        })) : selectedProducts.filter((product, index) => (
            index !== productIndex
        ))

        onSelectProducts(updatedSelectedProducts)
    }

    const handleOnProductUpdate = (rowIndex, columnAccessor, newValue) => {
        switch (columnAccessor) {
            case PRODUCT_TABLE_ACCESSORS.PRODUCT_QUANTITY:
                updateProductQuantity(rowIndex, newValue)
                break;

            default:
                break;
        }
    }

    return (
        <div className={`flex flex-col ${selectedProducts.length <= 0 ? "border-2 border-gray-400 bg-gray-100" : ""}`}>
            <div className={`px-5 pt-5 flex flex-col gap-2 bg-gray-100 ${description || selectedProducts.length > 0 ? "pb-5" : ""}`}>
                <div className="flex gap-2 text-lg font-semibold">
                    <span>
                        {title}
                        <span className="text-red-500">{" *"}</span>
                    </span>

                    {selectedProducts.length > 0 ? (
                        <CheckCircleIcon className="h-6 text-green-600" />
                    ) : (
                        <ExclamationCircleIcon className="h-6 text-red-600" />
                    )}
                </div>

                {selectedProducts.length <= 0 && (
                    <div className="text-base">{description}</div>
                )}
            </div>

            <div className="flex flex-col gap-5 px-5 pb-5">
                {selectedProducts.length <= 0 && (
                    <Button
                        type="secondary"
                        className="w-fit"
                        onClick={() => setOpenSelectProductDialog(true)}
                    >
                        {addProductText}
                    </Button>
                )}

                {selectedProducts.length > 0 && (
                    <div className="pt-5">
                        <ProductsTable
                            products={selectedProducts}
                            onProductUpdate={handleOnProductUpdate}
                        />

                        <button
                            className="justify-center text-blue-700 hover:text-blue-500 font-normal h-fit w-fit pt-5"
                            onClick={() => setOpenSelectProductDialog(true)}
                        >
                            {`+ ${addAnotherProductText}`}
                        </button>
                    </div>
                )}
            </div>

            <SelectProductDialog
                open={openSelectProductDialog}
                setOpen={setOpenSelectProductDialog}
                onAddProducts={handleOnAddProducts}
                queryExtraParams={productsQueryExtraParams}
            />
        </div>
    )
}

export default ProductSelector