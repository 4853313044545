import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    asyncTasksIds: []
};

export const asyncTaskSlice = createSlice({
    name: 'asyncTask',
    initialState,
    reducers: {
        setAsyncTask: (state, action) => {
            const jobId = action.payload.jobId

            if (jobId && !state.asyncTasksIds.includes(jobId))
                state.asyncTasksIds.push(jobId)
        },
        removeAsyncTask: (state, action) => {
            const jobId = action.payload.jobId
            const taskIndex = state.asyncTasksIds.findIndex(_jobId => _jobId === jobId)

            if (taskIndex) {
                state.asyncTasksIds.splice(taskIndex, 1)
            }
        }
    }
})

export const {
    setAsyncTask,
    removeAsyncTask
} = asyncTaskSlice.actions

export const asyncTasksIds = (state) => state.asyncTask.asyncTasksIds
export default asyncTaskSlice.reducer
