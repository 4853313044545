import { ButtonLoader, Notification, ConfirmDialog, TextareaField} from "../../components"
import { Loader } from '../../components/Loader';
import {useState, useContext, useEffect} from "react";
import {useQuery} from "react-query";
import { UserContext } from "../../hooks/UserContext";
import {useForm} from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { editStoreData, fetchStoreInfo} from "../../services/storeServices"
import { LeftCircleOutlined} from '@ant-design/icons';
import {useDispatch} from "react-redux";
import {
    setValidSettingsForm
} from '../../redux/validSettingsFormSlice';
export const ObservationsSettingsContainer = ({
    onCancel
}) => {


    const [loadingBundleBotton, setLoadingBundleBotton] = useState(false)
    const {register, handleSubmit,formState: { errors }} = useForm({})
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const {user} = useContext(UserContext)
    const [showNotification, setShowNotification] = useState(false)
    const [title, setTitle] = useState(false)
    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])
    const [changeDetected, setChangeDetected] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    
    const [dialog, setDialog] = useState({
        loading: false,
        open: false,
        title: "",
        description: "",
        errorMessage: "",
        type: null
    })
    
    const {
        isLoading,
        isError,
        error,
        data,
        isPreviousData,
        refetch,
    } = useQuery(['store_info_obs', user.current_store?.id], () => fetchStoreInfo(user.current_store?.id), { keepPreviousData: true })

    const [customizationData, setCustomizationData] = useState({
        REPLENISHMENT_INVOICE: {},
        REPLENISHMENT_ADJUSTMENT_INVOICE: {},
        SYMBOLIC_RETURN_INVOICE: {},
        SALE_INVOICE: {},
        DONATION_INVOICE: {},
        RETURN_INVOICE:  {},
        REMOVE_STOCK: {}
    })
    useEffect(() => {
        if(!data?.invoice_customization) return
        updateCustomizationData(data)
    }, [data])

    const updateCustomizationData = (storeData) =>{
        let invoice_customization = storeData.invoice_customization
        setCustomizationData(storeData.invoice_customization)
    }

     const onSubmit = async (dataTax) => {

        setValidForm(false)
        dataTax = {
            invoice_customization: customizationData
        }

        setLoadingBundleBotton(true)
        editStoreData(user.current_store?.id, dataTax).then((resultado)=>{
            setSuccessMessage(i18n.t("settings.tax_fields.success_message"))
            setShowNotification(true)
            setLoadingBundleBotton(false)
        }).catch((e)=>{
            setErrorMessage(i18n.t("settings.tax_fields.error_message"))
            setShowNotification(true)
            setLoadingBundleBotton(false)
        })
        
    }
    const checkRequiredFields = () => {
        return true
    }
    const setValidForm = (edit) => {
        dispatch(setValidSettingsForm({id:"4",change:edit,selectedkeys:["4"] }))
        setChangeDetected(edit)
        setSuccessMessage(false)
    }

    const handleClickOnCancel = () => {
        if (changeDetected && !successMessage){
            setDialog({...dialog, loading: false, open: true})
        }
        else{
            setValidForm(false)
            onCancel()
        }
    }
    const handleClickOnConfirm = () => {
       onCancel()
    }

    return (
        <div className='relative p-5'>
            <form action="#" method="PATCH" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-1 ">
                    <div className="flex items-center justify-between">
                    <div  className="flex items-center justify-start">
                        <LeftCircleOutlined className="text-3xl mr-8 hover:text-indigo-700" onClick={handleClickOnCancel}/>
                        <ConfirmDialog
                                open={dialog.open}
                                setOpen={(value) => setDialog({...dialog, open: value}) }
                                title={i18n.t("settings.validate_changes.title")}
                                description={i18n.t("settings.validate_changes.question")}
                                confirmLabel="Si"
                                onConfirm={handleClickOnConfirm}
                                loading={dialog.loading}
                            />
                        <div  className="flex-col">
                            <p className='text-xl mb-0 font-bold text-blue-gray-900 mt-5'> {i18n.t("settings.billing_config.observation_invoice")}<span className = "text-gray-500 italic font-normal"> {i18n.t("settings.billing_config.optional")}</span> </p>
                            <p className = "mt-0 text-base text-gray-500"> {i18n.t("settings.billing_config.observation_invoice_description")}</p>
                            {successMessage && <label className = "text-green-500 italic font-normal">{i18n.t("settings.validate_changes.saved_data")}</label>}
                            {changeDetected && !successMessage && <label className = "text-yellow-500 italic font-normal">{i18n.t("settings.validate_changes.unsaved_data")}</label>}
                        </div>
                    </div>
                        <div>
                            <ButtonLoader className="" loading_text={i18n.t("settings.tax_fields.processing")} loading={loadingBundleBotton} disabled={!checkRequiredFields()}>
                                {i18n.t("settings.tax_fields.send_form")}
                            </ButtonLoader>
                            {errorMessage && showNotification? (
                                    <>
                                        <Notification show={showNotification} setShow={setShowNotification} type="error" title={i18n.t("settings.user_settings.error")} description={errorMessage}/>
                                    </>
                                ) : null}
                                {successMessage && showNotification? (
                                    <>
                                        <Notification show={showNotification} setShow={setShowNotification} type="success" title={i18n.t("settings.user_settings.done")} description={successMessage}/>
                                    </>
                                ) : null}
                        </div>
                    </div>
                </div>
                <div>
                    {
                        !isPreviousData && isLoading && !data ? (
                            <><Loader show={true}></Loader></>
                        ): isError ? (
                            <>Error: {error.message}</>
                        ):
                        (
                            <div >
                                <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-10 mb-3'> {i18n.t("settings.customize_bills.orders_invocices_title")}</p>
                                <div className="max-w-full min-w-min">

                                    <div className=" border-gray-200 rounded-2xl grid grid-row bg-white shadow sm:rounded-lg">

                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center border-b border-gray-200 ">
                                            <div>
                                                <p className="mt-4 mb-4 pl-5 text-sm font-bold">{i18n.t("settings.customize_bills.invoice_table_title")}</p>
                                            </div>
                                            <div className="col-span-2">
                                                <p className="mt-4 mb-4 text-sm font-bold">{i18n.t("settings.customize_bills.observation_table_title")}</p>
                                            </div>
                                        </div>
                                        <div className="divide-y divide-gray-200"></div>
                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.symbolic_return")}</p>
                                            </div>
                                            <div className="col-span-2 pr-5">
                                                <TextareaField
                                                    maxlength={5000}
                                                    className="sm:col-span-6"
                                                    value = {customizationData.SYMBOLIC_RETURN_INVOICE?.complementary_information}
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setCustomizationData({
                                                            ...customizationData,
                                                            SYMBOLIC_RETURN_INVOICE: {
                                                                ...customizationData.SYMBOLIC_RETURN_INVOICE,
                                                                complementary_information: v
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.sale")}</p>
                                            </div>
                                            <div className="col-span-2 pr-5">
                                                <TextareaField
                                                    maxlength={5000}
                                                    className="sm:col-span-6"
                                                    value = {customizationData.SALE_INVOICE?.complementary_information}
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setCustomizationData({
                                                            ...customizationData,
                                                            SALE_INVOICE: {
                                                                ...customizationData.SALE_INVOICE,
                                                                complementary_information: v
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.donation")}</p>
                                            </div>
                                            <div className="col-span-2 pr-5">
                                                <TextareaField
                                                    maxlength={5000}
                                                    className="sm:col-span-6"
                                                    value = {customizationData.DONATION_INVOICE?.complementary_information}
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setCustomizationData({
                                                            ...customizationData,
                                                            DONATION_INVOICE: {
                                                                ...customizationData.DONATION_INVOICE,
                                                                complementary_information: v
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.remove_stock")}</p>
                                            </div>
                                            <div className="col-span-2 pr-5">
                                                <TextareaField
                                                    maxlength={5000}
                                                    className="sm:col-span-6"
                                                    value = {customizationData.REMOVE_STOCK?.complementary_information}
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setCustomizationData({
                                                            ...customizationData,
                                                            REMOVE_STOCK: {
                                                                ...customizationData.REMOVE_STOCK,
                                                                complementary_information: v
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-10 mb-3'> {i18n.t("settings.customize_bills.return_invocices_title")} </p>
                                <div className="max-w-full min-w-min">
                                    <div className=" border-gray-200 rounded-2xl grid grid-row  bg-white shadow sm:rounded-lg ">

                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center border-b border-gray-200 ">
                                            <div>
                                                <p className="mt-3 mb-3 pl-5 text-sm font-bold">{i18n.t("settings.customize_bills.invoice_table_title")}</p>
                                            </div>
                                            <div className="col-span-2">
                                                <p className="mt-3 mb-3 text-sm font-bold">{i18n.t("settings.customize_bills.observation_table_title")}</p>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.return")}</p>
                                            </div>
                                            <div className="col-span-2 pr-5" >
                                                <TextareaField
                                                    maxlength={5000}
                                                    className="sm:col-span-6"
                                                    value = {customizationData.RETURN_INVOICE?.complementary_information}
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setCustomizationData({
                                                            ...customizationData,
                                                            RETURN_INVOICE: {
                                                                ...customizationData.RETURN_INVOICE,
                                                                complementary_information: v
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-10 mb-3'> {i18n.t("settings.customize_bills.replenishment_invocices_title")} </p>
                                <div className="max-w-full min-w-min">
                                    <div className=" bg-white shadow sm:rounded-lg border-gray-200 rounded-2xl grid grid-row ">

                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center border-b border-gray-200 ">
                                            <div>
                                                <p className="mt-4 mb-4 pl-5 text-sm font-bold">{i18n.t("settings.customize_bills.invoice_table_title")}</p>
                                            </div>
                                            <div>
                                                <p className="mt-4 mb-4 text-sm font-bold">{i18n.t("settings.customize_bills.observation_table_title")}</p>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-8 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.replenishment")}</p>
                                            </div>
                                            <div className="col-span-2 pr-5">
                                                <TextareaField
                                                    maxlength={5000}
                                                    className="sm:col-span-6"
                                                    value = {customizationData.REPLENISHMENT_INVOICE?.complementary_information}
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setCustomizationData({
                                                            ...customizationData,
                                                            REPLENISHMENT_INVOICE: {
                                                                ...customizationData.REPLENISHMENT_INVOICE,
                                                                complementary_information: v
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-y-5 gap-x-8 sm:grid-cols-3 mb-4 w-full justify-center ">
                                            <div>
                                                <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.replenishment_adjustment")}</p>
                                            </div>
                                            <div className="col-span-2 pr-5">
                                                <TextareaField
                                                    maxlength={5000}
                                                    className="sm:col-span-6"
                                                    value = {customizationData.REPLENISHMENT_ADJUSTMENT_INVOICE?.complementary_information}
                                                    onChange={(v) => {
                                                        setValidForm(true)
                                                        setCustomizationData({
                                                            ...customizationData,
                                                            REPLENISHMENT_ADJUSTMENT_INVOICE: {
                                                                ...customizationData.REPLENISHMENT_ADJUSTMENT_INVOICE,
                                                                complementary_information: v
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        )
                    }
                </div>
                <div className="flex items-center justify-between">
                    <div  className="flex items-center justify-start">
                    </div>
                        <div>
                            <ButtonLoader className="" loading_text={i18n.t("settings.tax_fields.processing")} loading={loadingBundleBotton} disabled={!checkRequiredFields()}>
                                {i18n.t("settings.tax_fields.send_form")}
                            </ButtonLoader>

                            {errorMessage && showNotification? (
                                <>
                                    <Notification show={showNotification} setShow={setShowNotification} type="error" title={i18n.t("settings.user_settings.error")} description={errorMessage}/>
                                </>
                            ) : null}
                            {successMessage && showNotification? (
                                <>
                                    <Notification show={showNotification} setShow={setShowNotification} type="success" title={i18n.t("settings.user_settings.done")} description={successMessage}/>
                                </>
                            ) : null}
                        </div>
                </div>
            </form>
        </div>
    )

}