import { useTranslation } from 'react-i18next'
import { PageTopBar } from "../../../components"

const TopMenu = ({ store }) => {
    const { t } = useTranslation()

    return (
        <PageTopBar>
            <div className="text-lg text-gray-400">
                {store.name}

                &nbsp;&rarr;&nbsp;

                {t("topMenu.replenishments")}

                &nbsp;&rarr;&nbsp;

                {t("work_orders.work_orders")}

                &nbsp;&rarr;&nbsp;

                <span className="font-semibold text-gray-600">
                    {t("work_orders.path")}
                </span>
            </div>
        </PageTopBar>
    )
}

export default TopMenu