import {HomeIcon, SearchIcon} from "@heroicons/react/solid";


export const PageView = ({topMenu=null, topMenuFullWidth=true, bottomMenu=null, children, childrenFullWidth=true}) => {
    return (
        <>
            { topMenu &&
                <div className="bg-blue-500 flex-shrink-0" >
                    <div className="h-full bg-white">
                        <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 " + (childrenFullWidth ? "lg:max-w-full" : "lg:max-w-7xl")} >
                            <div className=" align-middle inline-block min-w-full  sm:px-6 lg:px-8 ">
                                {topMenu}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="mb-auto flex-grow  overflow-y-scroll">
                <div className="relative mt-5 h-full bg-gray-100">
                    <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 " + (childrenFullWidth ? "lg:max-w-full" : "lg:max-w-7xl")}>
                        <div className="flex flex-col">

                                <div className="align-middle inline-block min-w-full  sm:px-6 lg:px-8 fix-modal-gs">
                                    <div className="sm:rounded-lg mb-3">
                                        {children}
                                    </div>
                                </div>

                        </div>
                    </div>
                </div>
            </div>
            {bottomMenu && <div className="h-17 min-h-17 bg-blue-500 flex-shrink-0 bg-gray-100">
                <div className="h-full flex-shrink-0 bg-gray-100">
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                        <div className="py-2 align-middle inline-block min-w-full  sm:px-6 lg:px-8 ">
                            <div className="overflow-hidden sm:rounded-lg p-4  mb-2bg-gray-100 bg-white flex flex-row justify-center">
                                {bottomMenu}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

        </>
    )
}