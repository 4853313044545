import {
    BasicTable,
    Button,
    CheckBox,
    ConfirmDialog,
    FilterMenu,
    Notification,
    PageView,
    SearchInput
} from "../../components";
import {useParams, useHistory} from "react-router-dom";
import {useQuery} from "react-query";
import {fetchShopifyProducts} from "../../services/channelServices";
import {useContext, useState} from "react";
import { ArrowRightIcon, ArrowDownIcon} from '@heroicons/react/outline'
import {useDispatch, useSelector} from "react-redux";
import {openDialog, setOpenDialog} from "../../redux/importProductsSlice";
import {importProducts} from "../../services";
import { useTranslation } from "react-i18next";


export const ListShopifyProducts = () => {

    const { t, i18n} = useTranslation();
    let selectedFlatRows;
    const [selectedRowIds, setSelectedRowIds] = useState({})
    const history = useHistory()
    const [openImport, setOpenImport] = useState(false)
    const [showNotification, setShowNotification] = useState(false)
    const [dialogLoading, setDialogLoading] = useState(false)
    const [title, setTitle] = useState(false)
    const { id } = useParams()
    const [selectedProducts, setSelectedProducts] = useState([])
    const [products, setProducts] = useState([])
    const [product_codes, setProductCodes] = useState([])
    const [channel_integration, setChannelIntegration] = useState(false)
    const { data, isLoading, isError, error, refetch} = useQuery(["shopify_products", id], () => fetchShopifyProducts(id),{
        onSuccess: (info) => {
            if(!products.length)
                setProducts(info.products)
                setProductCodes(info.product_codes)
                setChannelIntegration(info.channel_integration)
                console.log(channel_integration)
        }})

    const handleImportProducts = () => {
        setOpenImport(true)
        //dispatch(setOpenDialog(true))
    }

    const columns = [
        {
            // Build our expander column
            id: 'name', // Make sure it has an ID
            accessor: 'name',
            Header: "store_detail_container.name",
            disableFilters: false,
            disableSortBy: false,
            disableSortBy: false,
            Cell: ({ row }) =>
                // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                // to build the toggle for expanding a row
                row.canExpand ? (
                    <span
                        {...row.getToggleRowExpandedProps({
                            style: {
                                // We can even use the row.depth property
                                // and paddingLeft to indicate the depth
                                // of the row
                                paddingLeft: `${row.depth * 2}rem`,
                            },
                        })}
                    >
                        {row.isExpanded ? ( <div className="flex underline text-blue-800"><ArrowDownIcon className="h-4 h-4 mr-2"/>{row.original.title}</div>) : <div className="flex underline text-blue-800"><ArrowRightIcon className="h-4 h-4 mr-2"/>{row.original.title}</div>}
                    </span>
                ) : (`${row.original.title}`),
        },
        {
            Header: 'sku',
            accessor: 'sku',
            disableFilters: false,
            disableSortBy: false,
        },
        {
            Header: "integrations_list.shopify.barcode",
            accessor: 'barcode',
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "orders.list.status",
            accessor: 'exists', // accessor is the "key" in the data
            disableFilters: true,
            disableSortBy: true,
        },
    ]

    /**
     *
     * @returns {[{product_id: int, isParent: boolean, variant_id: int, parent: int}]}
     */
    function getSelectedRows() {
        let rows = []
        for (let i = 0; i < selectedProducts.length; i++) {
            if(selectedProducts[i].isParent===true) {
                let original = selectedProducts[i]
                rows.push({
                    product_id: original.id,
                    is_parent: true
                })
                for (let j = 0; j < original.subRows.length; j++) {
                    let original2 = selectedProducts[i].subRows[j].original
                    rows.push({
                        product_id: original.id,
                        is_parent: false,
                        variant_id: original2.id,
                    })
                }
            }
            else{
                let original = selectedProducts[i]
                rows.push({
                    product_id: original.parent,
                    is_parent: false,
                    variant_id: original.id
                })
            }
        }
        return rows;
    }

    const confirmImportProducts = async () => {
        try {
            const rowsSelected = getSelectedRows();

            setDialogLoading(true)

            const res = await importProducts(id, rowsSelected)
            setTitle(i18n.t("integrations_list.shopify.Updated_Inventory"))
            setSelectedProducts([])
            setDialogLoading(false)
            setOpenImport(false)
            setShowNotification(true)
            setTimeout(() => {
                history.goBack()
            }, 500);

        } catch (err){
            setTitle (i18n.t("integrations_list.shopify.Error_importing"))
            //console.error("Error importando", err)
            setDialogLoading(false)
        }
    }


function getVariants(variants, productId, product_codes){
    if(variants.length===1 &&variants[0].title=="Default Title"){
        return []
    }
    return variants.map(variant=>{
        return {
            ...variant,
            barcode: variant.barcode === null || variant.barcode === "" ? <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-100 text-red-800"} >{i18n.t("integrations_list.shopify.add_Barcode_in_Shopify")}</span> : <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-800"} >{variant.barcode}</span>,
            sku: hasMissingSkus(variant) ? <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-100 text-red-800"} >{i18n.t("integrations_list.shopify.SKU missing in Shopify")}</span> : <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-800"} >{variant.sku}</span>,
            exists: isImported(variant.sku, product_codes) ? <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-green-100 text-green-800"} >{i18n.t("integrations_list.shopify.imported")}</span>:<span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-100 text-red-800"} >{i18n.t("integrations_list.shopify.no_matter")}</span>,
            checkbox: <CheckBox />,
            isParent: false,
            parent: productId
        }
    })
}

function isImported(sku, product_codes) {
        return product_codes.includes(sku)
}

function hasMissingSkus(variant) {
    if (variant.sku === null || variant.sku === ""){
        return true
    } else {
        return false
    }
    
}
  
function hasMissingBarcode(variant) {
    if (variant.barcode === null || variant.barcode === ""){
        return true
    } else {
        return false
    }
    
}


function checkMissingSkuInChilds(variants) {

    const result = variants.filter(hasMissingSkus);
    if (result.length > 0) {
        return <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-100 text-red-800"} > {i18n.t("integrations_list.shopify.missing") + result.length +" SKU en Shopify"} </span>
    } else {
        return ""
    }
}

function checkMissingBarcodeInChilds (variants) {

    const result = variants.filter(hasMissingBarcode);
    if (result.length > 0) {
        return <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-100 text-red-800"} > {i18n.t("integrations_list.shopify.missing") + result.length + i18n.t("integrations_list.shopify.barcodes_in_shopify")} </span>
    } else {
        return ""
    }

}

function getSku(variants){
    if(variants.length === 1 && variants[0].title=="Default Title"){
        return variants[0].sku === null || variants[0].sku === "" ? <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-100 text-red-800"} >{i18n.t("integrations_list.shopify.SKU_missing_in_Shopify")}</span> : <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-800"}> {variants[0].sku} </span>
    }  
    return checkMissingSkuInChilds(variants)
}

function getBarcode(variants){
    if(variants.length === 1 && variants[0].title=="Default Title"){
        return variants[0].barcode === null || variants[0].barcode === "" ? <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-100 text-red-800"} >{i18n.t("integrations_list.shopify.add_Barcode_in_Shopify")}</span> : <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-800"} >{variants[0].barcode}</span>
    }
    return checkMissingBarcodeInChilds(variants)
}

function getExists(variants, product_codes) {

    return isImported(variants[0].sku, product_codes) ? <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-green-100 text-green-800"} >{i18n.t("integrations_list.shopify.imported")}</span>:<span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-100 text-red-800"} >{i18n.t("integrations_list.shopify.no_matter")}</span>

}

function getQuantity(variants){
    if(variants.length === 1 && variants[0].title=="Default Title"){
        return variants[0].inventory_quantity
    }
    return ""
}
   

    const onFilterChange = (activeFilters) => {
         if(activeFilters.length){
             const result = data.products.filter(product => {
                 if (activeFilters[0].id === "name") {
                     return product.title.toLowerCase().includes(activeFilters[0].value.toLowerCase())
                 } else {
                    const variant = product.variants.find(variant => variant.sku);
                    return !!variant && variant.sku.toLowerCase().includes(activeFilters[0].value.toLowerCase());
                 }
             }
             );
             setProducts(result)
         }else{
             setSelectedProducts([])
             setProducts([])
             refetch()
         }
    }
    return (
        <>
            <Notification show={showNotification} setShow={setShowNotification} title={title}/>
            <ConfirmDialog
            
                open={openImport}
                setOpen={setOpenImport}
                title= {i18n.t("integrations_list.shopify.Import_Products")}
                description={`${i18n.t("integrations_list.shopify.import")} ${selectedProducts.length} ${i18n.t("integrations_list.shopify.products")}`}
                confirmLabel= {i18n.t("integrations_list.shopify.yes")}
                onConfirm={confirmImportProducts}
                loading={dialogLoading}
            />

            <PageView
                topMenu={<FilterMenu>
                    <SearchInput />
                    <Button onClick={handleImportProducts}>{i18n.t("integrations_list.shopify.Import_Products")}({selectedProducts.length})</Button>
                </FilterMenu>}
            >
                {isLoading ? (
                    <>Loading...</>
                ) : isError ? (
                    <>Error: {error.message}</>
                ) : (
                    <>
                    {
                        channel_integration && channel_integration.config && (
                            <div className="bg-light-blue-500 text-center py-4 lg:px-4">
                                <div className="p-2 bg-light-blue-500 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                    <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">{i18n.t("integrations_list.shopify.notice")}</span>
                                    <span className="leading-5 font-semibold mr-2 text-left flex-auto">{i18n.t("integrations_list.shopify.SKU_or_UPC_only")} 
                                    <br></br><a className="underline" target="_blank" href={"https://"+channel_integration.config["domain"]+"/admin/bulk?resource_name=Product&edit=variants.sku%2Cvariants.barcode&show="}>{i18n.t("integrations_list.shopify.add_SKU_or_UPC")} </a>
                                    </span>
                                </div>
                            </div>
                        )
                    }
                    
                    <BasicTable
                        onFilterChange={onFilterChange}
                        columns={columns}
                        showHeader
                        selectable
                        filterable
                        
                        data={products.map(product => {
                            return {
                                ...product,
                                number_variants: product.variants?.length,
                                sku: getSku(product.variants),
                                barcode: getBarcode(product.variants),
                                exists: getExists(product.variants, product_codes),
                                isParent: true,
                                inventory_quantity: getQuantity(product.variants),
                                checkbox: <CheckBox />,
                                subRows: getVariants(product.variants, product.id, product_codes)
                            }
                        })}
                       onSelectionChange={(rows) => {
                            setSelectedProducts(rows)
                        }}
                        /*onChangeSelection={(selectedFlatRowsParam, selectedRowIdsParam) => {
                            console.log(selectedFlatRowsParam, selectedRowIdsParam)
                            selectedFlatRows = selectedFlatRowsParam
                            setSelectedRowIds(selectedRowIdsParam)
                        }}*/
                    />
                    </>
                    )}
            </PageView>
        </>
    )
}