const PackingOption = ({ image, title, description }) => {
    return (
        <div className="flex w-full ml-10">
            <img className="w-14" src={image} alt="Packing option" />
            <div className="ml-10 text-sm text-gray-700 flex flex-col justify-center">
                <h3 className="mb-0 font-medium">{title}</h3>
                <p className="mb-0 font-normal text-gray-500">{description}</p>
            </div>
        </div>
    )
}

export default PackingOption
