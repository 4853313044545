import { useQuery, useQueryClient } from "react-query"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"
import { BasicTable, Button, ConfirmDialog } from "../../components"
import { Loader } from "../../components/Loader"
import { SHOW_ORDER_RETURNS, STORE_PATH } from "../../navigation/constants"
import { useEffect, useState, useContext } from "react"

import {
  cancelReturn,
  duplicateReturnedOrder,
  fetchReturn,
  unassignReservedStock,
} from "../../services/returnServices"
import { RETURNS_STATUSES } from '../../components/NewTable/StatusHighlightedDefinitions';
import { StatusHighlighted } from '../../components/NewTable/StatusHighlighted';
import { OrderLineItem } from "../Orders/OrderLineItem"
import { getReturnNewOrderLabel } from "./ReturnNewOrderLabels"
import { ReservedProductsDialog } from "../Orders/CreateReturn/ReservedProductsDialog"
import {UserContext} from "../../hooks/UserContext";
import {getReturnTypeDefinition} from "./ReturnTypes";
import  i18n from "../../translations/index";


export const ShowReturnContainer = () => {
  let { id } = useParams()
  const { url:storePath } = useRouteMatch({ path: STORE_PATH })
  const history = useHistory()
  const queryClient = useQueryClient()
  // const products = useSelector(productsKit)
  const { isLoading, isFetching, isError, error, data, refetch } = useQuery(
    ["return_detail", id],
    () => fetchReturn(id)
  )

  const [returnedStockUnits, setReturnedStockUnits] = useState([])
  const [reservedStockUnits, setReservedStockUnits] = useState([])
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [showSelectReturnedProductDialog, setShowSelectReturnedProductDialog] = useState(false)
  const [showSelectReservedProductDialog, setShowSelectReservedProductDialog] = useState(false)
  const [openCancelConfirmDialog, setOpenCancelConfirmDialog] = useState(false)
  const [openResendConfirmDialog, setOpenResendConfirmDialog] = useState(false)
  const [openUnassignConfirmDialog, setOpenUnassignConfirmDialog] = useState(false)
  const [busy, setBusy] = useState(false)
  const [started, setStarted] = useState(0)
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (!data) {
      return
    }
    // console.log(data)
    let returnedStocks = []
    data.returned_stock_units.forEach((stock) => {
      let foundIndex = returnedStocks.findIndex((rs) => {
        if (stock.status === "DAMAGED") {
          return rs.product.id === stock.product.id && rs.damaged
        }
        return rs.product.id === stock.product.id && rs.damaged === false
      })
      if (foundIndex === -1) {
        returnedStocks.push({
          product: { ...stock.product },
          quantity: 1,
          damaged: stock.status === "DAMAGED" ? true : false,
        })
      } else {
        returnedStocks[foundIndex].quantity += 1
      }
    })
    setReturnedStockUnits(returnedStocks)
    // console.log(returnedStocks)
  }, [data])

  useEffect(() => {
    if (!data) {
      return
    }
    let reservedStocks = []
    data.reserved_stock_units.forEach((stock) => {
      let foundIndex = reservedStocks.findIndex((rs) => {
        return rs.product.id === stock.product.id;
      })
      if (foundIndex === -1) {
        reservedStocks.push({
          product: { ...stock.product },
          quantity: 1,
        })
      } else {
        reservedStocks[foundIndex].quantity += 1
      }
    })

    setReservedStockUnits(reservedStocks)
  }, [data])

  useEffect(() => {
    window.analytics.page('Returns', 'Single', {id: id})
  }, [])
  

  const showOrderDetail = (order) => {
    history.push(storePath + SHOW_ORDER_RETURNS.replace(":id", order.id))
  }

  const onCancelReturn = async (returnId) => {
    window.analytics.track('Returns - Cancels', {id: returnId})
    setBusy(true)
    try {
      await cancelReturn(returnId)
      refetch()
      queryClient.invalidateQueries("returns_pending_count")
      setOpenCancelConfirmDialog(false)
    } catch (error) {
      window.alert(error)
      console.log(error)
      console.log(error.response?.error)
    }
    setBusy(false)
  }

  const onResendReturn = async (returnId) => {
    window.analytics.track('Returns - Resends', {id: returnId})
    setBusy(true)
    try {
      await duplicateReturnedOrder(returnId)
      refetch()
      queryClient.invalidateQueries("returns_pending_count")
      setOpenResendConfirmDialog(false)
    } catch (error) {
      window.alert(error)
      console.log(error)
      console.log(error.response?.error)
    }
    setBusy(false)
  }

  const onUnassignReturn = async (returnId) => {
    window.analytics.track('Returns - Unassign Stock', {id: returnId})
    setBusy(true)
    setStarted(1)
    try {
      await unassignReservedStock(returnId)
      refetch()
      queryClient.invalidateQueries("returns_pending_count")
      setOpenUnassignConfirmDialog(false)
    } catch (error) {
      window.alert(error)
      console.log(error)
      console.log(error.response?.error)
    }
    setBusy(false)
    setStarted(2)
    
  }

  const extractOrderIdentifier = (notes) =>{
    if (!notes) return
    let index = notes.indexOf("returned_order_reference")

    if (index !== -1) {
        let result = notes.substring(0, index).trim()
        return result
    } else {
        return notes
    }
  }

  return (
    <>
      {isError ? (
        <>Error: {error.message}</>
      ) : (
        <div className="relative">
          <Loader show={isFetching} />
          <div className="">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {i18n.t("returns.returnContainer.returned_order")}
                </dt>
              
                  {data?.returned_order?.order_number ?
                 ( 
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2  hover:underline cursor-pointer" onClick={() => showOrderDetail(data?.returned_order)}>
                    #{data?.returned_order?.order_number}
                  </dd>
                 )
                  : 
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <i>{i18n.t("returns.returnContainer.unidentified")}</i>
                  </dd>
                  }
                
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">{i18n.t("returns.returnContainer.store")}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data?.returned_order?.store.name || data?.store?.name}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">{i18n.t("returns.returnContainer.type")}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <div>{i18n.t(getReturnTypeDefinition(data?.return_type).label)}</div>
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">{i18n.t("returns.returnContainer.state")}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <StatusHighlighted
                    status={data?.status}
                    statuses={RETURNS_STATUSES}
                  />
                  {data?.status === "ARRIVED_AT_WAREHOUSE" && (
                    <p className="text-xs text-gray-500"> {i18n.t("returns.returnContainer.arrived_at_warehouse_description")}</p>
                  )}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {i18n.t("returns.returnContainer.return_date")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data &&
                    new Intl.DateTimeFormat(i18n.language, {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(data.created_at))}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">{i18n.t("returns.returnContainer.notes")}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                  {extractOrderIdentifier(data?.notes)}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">{i18n.t("returns.returnContainer.return_label")}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data?.arrived_return_label?.scanned_return_label ?(

                    <p> <b>{data?.arrived_return_label?.carrier_name} </b> {data?.arrived_return_label?.scanned_return_label}</p>
                  ):(
                    <i>{i18n.t("returns.returnContainer.no_return_label")}</i>
                  )
                  }
                </dd>
              </div>
              {data?.return_type != "SIMPLE_RETURN" && (
                <div className="bg-white bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    {i18n.t("returns.returnContainer.return_of_order")} 
                  </dt>
                  {data?.created_order ? (
                    <dd
                      className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2  hover:underline cursor-pointer"
                      onClick={() => showOrderDetail(data?.created_order)}
                    >
                      #{data?.created_order.order_number}
                    </dd>
                  ) : data?.status === "PENDING" ? (
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div className="text-center mb-2">
                        <i className="font-medium">
                          {i18n.t("returns.returnContainer.order_returned")}
                        </i>
                      </div>
                      <div className="flex justify-center">
                        <Button
                          type="danger"
                          className="mr-2"
                          onClick={() => setOpenCancelConfirmDialog(true)}
                        >
                          {i18n.t("returns.returnContainer.cancel_forwarding")}
                        </Button>
                        <Button
                          onClick={() => setOpenResendConfirmDialog(true)}
                        >
                          {i18n.t("returns.returnContainer.resend_order")} 
                        </Button>
                      </div>
                    </dd>
                  ) : (
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <i>{i18n.t(getReturnNewOrderLabel(data?.return_type, data?.status))}</i>
                    </dd>
                  )}
                </div>
              )}
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {i18n.t("returns.returnContainer.returned_products")}<br />
                  {/* <i className="font-normal">
                    Los productos que retornaron no necesariamente coinciden con
                    los productos de la orden que se envió.
                  </i> */}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data?.status === "REQUESTED" ? (
                    <div>
                      <i>{i18n.t("returns.returnContainer.order_not_returned")}</i>
                    </div>
                  ) : (
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      {returnedStockUnits.map((stockUnitGroup) => (
                        <li
                          className="pl-3 pr-4 py-3 text-md"
                          key={
                            String(stockUnitGroup.product.id) +
                            (stockUnitGroup.damaged ? "D" : "")
                          }
                        >
                          {/* {console.log(stockUnitGroup)} */}
                          <OrderLineItem orderLine={stockUnitGroup} />
                        </li>
                      ))}
                    </ul>
                  )}
                </dd>
              </div>
               <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {i18n.t("returns.returnContainer.reserved_parts")}<br />
                  {/* <i className="font-normal">
                    Los productos que retornaron no necesariamente coinciden con
                    los productos de la orden que se envió.
                  </i> */}
                  <br></br>
                
                  {data?.return_type === "RETURNED_BY_CARRIER"
                   || data?.reserved_stock_units_count === 0 ?(
                    <div>
                    </div>
                    ) : (
                    
                      <Button
                      
                        className="mr-1"
                        onClick={() => setShowSelectReservedProductDialog(true)}
                      >
                        {i18n.t("returns.returnContainer.release_reserved_parts")}
                      </Button>
                    )
                  }
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data?.reserved_stock_units_count === 0 && data?.created_order &&
                      <div>
                        <i> {i18n.t("returns.returnContainer.parts_at_order")}</i>
                      </div>
                  }
                  {data?.reserved_stock_units_count === 0 && !data?.created_order &&
                    <div>
                      <i> {i18n.t("returns.returnContainer.no_parts_reserved")}</i>
                    </div>
                  }
                  {data?.reserved_stock_units_count > 0 && (
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      {reservedStockUnits.map((stockUnitGroup) => (
                        <li
                          className="pl-3 pr-4 py-3 text-md"
                          key={
                            String(stockUnitGroup.product.id) +
                            (stockUnitGroup.damaged ? "D" : "")
                          }
                        >
                          <OrderLineItem orderLine={stockUnitGroup} />
                        </li>
                      ))}
                    </ul>
                  )}
                </dd>
              </div>

              <br>
             
              </br>
              {(user.current_store?.warehouses[0]?.country === 'CO' && data?.returned_order?.return_label) && (
                <div><i> {i18n.t("returns.returnContainer.directory")} <b>Servientrega</b>.</i></div>
              )}
               <br>
             
             </br>
              <div className="pt-5 pb-5">
                <div className="flex justify-end">
                  {data?.rma_file && (
                    <Button
                      type="secondary"
                      className="w-full"
                      onClick={() => {
                        window.open(
                          process.env.REACT_APP_PLATFORM_URL +
                            data?.rma_file.path
                        )
                      }}
                    >
                      {i18n.t("returns.returnContainer.print")}
                    </Button>
                  )}
                  {data?.returned_order?.return_label?.label_file && (
                    <Button
                      className="ml-3 w-full"
                      onClick={() => {
                        window.open(
                          process.env.REACT_APP_PLATFORM_URL +
                            data.returned_order?.return_label.label_file.path
                        )
                      }}
                    >
                      {i18n.t("returns.returnContainer.return_guide")}
                    </Button>
                  )}                  
                </div>
              </div>
            </dl>
          </div>
          
          <ConfirmDialog
            open={openCancelConfirmDialog}
            setOpen={() => setOpenCancelConfirmDialog(false)}
            title= {i18n.t("returns.returnContainer.forwarding_cancellation")} 
            description= {i18n.t("returns.returnContainer.reserved_inventory")}
            confirmLabel= {i18n.t("returns.returnContainer.confirm")}
            cancelLabel= {i18n.t("returns.returnContainer.cancel")}
            onConfirm={() => onCancelReturn(id)}
            loading={busy}
          />
          <ConfirmDialog
            open={openResendConfirmDialog}
            setOpen={() => setOpenResendConfirmDialog(false)}
            title= {i18n.t("returns.returnContainer.confirm_order_forwarding")} 
            description= {i18n.t("returns.returnContainer.duplicate_returned_order")} 
            confirmLabel= {i18n.t("returns.returnContainer.confirm")}
            cancelLabel= {i18n.t("returns.returnContainer.cancel")}
            onConfirm={() => onResendReturn(id)}
            loading={busy}
          />
          {/* <ConfirmDialog
            open={openUnassignConfirmDialog}
            setOpen={() => setOpenUnassignConfirmDialog(false)}
            title="Confirmar Liberación de stock"
            description="Estas apunto de liberar los productos que se habían reservado para cumplir la orden que se creará el recibir el retorno ¿Deseas confirmar?"
            confirmLabel="Confirmar"
            cancelLabel="Cancelar"
            onConfirm={() => onUnassignReturn(id)}
            loading={busy}
          /> */}

          <ReservedProductsDialog
            open={showSelectReservedProductDialog}
            units={reservedStockUnits}
            onCancel={() => setShowSelectReservedProductDialog(false)}
            onConfirm={() => onUnassignReturn(id)}
            loading={busy}
            statusStarted={started}
          />
        </div>
      )}
    </>
  )
}
