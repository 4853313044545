import {useEffect, useState} from "react";
import {InputQty} from "../../components";

export const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData, // This is a custom function that we supplied to our table instance
    useDeleteIcon= true
}) => {
    const [value, setValue] = useState(initialValue)
    const onChange = v => {
        let regex = /[^\d]/g
        v = v.replace(regex, '')
        setValue(v)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        // console.log("updated value:", value)
        updateData(index, id, value)
    }

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const onPulsHandler = (e) => {
        e.preventDefault()
        // setValue(value+1)
        if(updateData){
            updateData(index, id, parseInt(value)+1)
        }
    }

    const onLessHandler = (e) => {
        e.preventDefault()
        // setValue(value-1)
        if(updateData){
            updateData(index, id, parseInt(value)-1)
        }
    }

    return (
        <>
            <div className="flex flex-row border h-11 w-40 rounded-lg border-gray-400 relative mx-auto">
                {
                    useDeleteIcon && value <= 1 ? 
                    <button
                        className="font-semibold border-r bg-white hover:bg-gray-200 text-gray-900 border-gray-400 h-full w-20 flex rounded-l-lg focus:outline-none cursor-pointer"
                        onClick={onLessHandler}
                    >
                        <span className="m-auto">
                            <svg className="w-3 h-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z" />
                            </svg>
                        </span>
                    </button>
                    :
                    <button
                        className="font-semibold border-r bg-white hover:bg-gray-200 text-gray-900 border-gray-400 h-full w-20 flex rounded-l-lg focus:outline-none cursor-pointer"
                        onClick={onLessHandler}
                    >
                        <span className="m-auto">-</span>
                    </button>
                }

                <div className="bg-white w-36 text-xs md:text-base flex items-center justify-center cursor-default">
                    
                    <InputQty
                        value={value}
                        onChange={(value) => {
                            // updateData(index, id, value)
                            onChange(value)
                        }}
                        onBlur={() => onBlur()}
                        className="w-30 border-0"
                    />
                </div>

                <button
                    className="font-semibold border-l  bg-white hover:bg-gray-200 text-gray-800 border-gray-400 h-full w-20 flex rounded-r-lg focus:outline-none cursor-pointer"
                    onClick={onPulsHandler}
                >
                    <span className="m-auto">+</span>
                </button>
                {/*<div className="absolute flex flex-col p-2 w-32 md:w-full mt-6 md:mt-8 mt-10 flex items-start justify-center">
                    <svg width="10" height="10" className="fill-current ml-5 md:mx-auto">
                        <polygon points="0 10, 10 10, 5 0"/>
                    </svg>
                    <span
                        className="text-xs block w-48 flex flex-wrap justify-center bg-black p-3 h-auto rounded-lg text-white"
                    >Input validation message</span>
                </div>*/}
            </div>
        </>
    )
}