export const isBlank = function (str) {
    return (!str || /^\s*$/.test(str));
}

export const capitalizeFirstLetter = (string) => {
    if (!string) return null
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitalize = (string) => {
    if (!string) return null
    let downcaseString = string.toLowerCase()
    return capitalizeFirstLetter(downcaseString)
}

export const formatPercentage = (percentage) => {
    if (percentage == 0) return "0%"
    if (percentage < 1) {
        return "<1%"
    }
    if (percentage > 99 && percentage < 100) {
        return ">99%"
    }
    return `${Math.round(percentage)}%`
}