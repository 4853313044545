export const TableTitle = ({titleOptions: {titleText=null, subtitleText=null, buttonText= null, buttonOnClick=null}}) => {
   return (
       <div className="bg-white px-4 pt-5 sm:px-6">
           <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
               <div className="ml-4 mt-4">
                   <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">{titleText}</h3>
                   {subtitleText &&
                       <p className="mt-1 text-sm text-gray-500">
                           {subtitleText}
                       </p>
                   }
               </div>
               {buttonText &&
                   <div className="ml-4 mt-4 flex-shrink-0">
                       <button
                           type="button"
                           className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                           onClick={buttonOnClick}
                       >
                           {buttonText}
                       </button>
                   </div>
               }
           </div>
       </div>
   )
}