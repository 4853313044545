import { useEffect, useState } from "react"
import { getOrderToShipHistoric } from "../../../../services/storeMetricsService"
import { useQuery } from "react-query"
import { useTranslation } from "react-i18next"
import { DEFAULT_QUERY_STALE_TIME, INTERVAL_DAY, capitalizeCategories, formatDates, parseDates, popCategories, replaceCategories } from "../chartDataUtils"
import { numberToString } from "../../../../helpers/internationalization"
import { AreaChart } from "../../../../components/MetricsCards/AreaChart"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"

export const OrdersPackedOnTimeHistoric = ({ storeId = null, dateFilter = null, interval = INTERVAL_DAY }) => {
    const { i18n, t } = useTranslation()

    const [formattedData, setFormattedData] = useState([])
    const [categories, setCategories] = useState([])

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["orders_packed_on_time_historic", storeId, dateFilter, interval], () => getOrderToShipHistoric(storeId, dateFilter, interval), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to && !!interval,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    useEffect(() => {
        const formatData = async (data) => {
            // [{truncated_date:a, categories: {b => c, d => f}}]
            if (!data) return [] 
            const dataParsedDates = parseDates(data)
            const dataReplacedCategories = replaceCategories(
                dataParsedDates,
                { true: t("metrics.orders_packed_on_time_historic.on_time"), false: t("metrics.orders_packed_on_time_historic.late") },
                "categories"
            )
            const [dataCapitalizedCategories, uniqueCategories] = capitalizeCategories(dataReplacedCategories)
            setCategories([t("metrics.orders_packed_on_time_historic.on_time"), t("metrics.orders_packed_on_time_historic.late")]) // specific order
            const flattenedData = popCategories(dataCapitalizedCategories)
            const formattedData = formatDates(flattenedData, interval, i18n.language)
            const formattedDataWithBothCategories = formattedData.map((item) => {
                const hasOnTimeKey = t("metrics.orders_packed_on_time_historic.on_time") in item
                const hasLateKey = t("metrics.orders_packed_on_time_historic.late") in item
                if (hasOnTimeKey && hasLateKey) return item
                if (!hasLateKey) item[t("metrics.orders_packed_on_time_historic.late")] = 0
                if (!hasOnTimeKey) item[t("metrics.orders_packed_on_time_historic.on_time")] = 0
                return item
            })
            setFormattedData(formattedDataWithBothCategories)
            return null
        }
        formatData(data).catch((error) => {
            console.error(error)
        })
    }, [data, interval, i18n.language])

    return (
        <>
            <MetricTitle className={'lg:ml-3'} title={t("metrics.orders_packed_on_time_historic.title")}/>
            <AreaChart
                categories={categories}
                data={formattedData}
                valueFormatter={(n) => numberToString(n, i18n.language)}
                areaChartProps={{
                    showGridLines: false,
                    curveType: "monotone",
                    className: "min-h-15 h-60",
                    // noDataText: 'No hay pedidos enviados en este periodo',
                }}
                isLoading={isLoading}
            />
        </>
    )
}
