import { ALL_AVAILABLE_ACTION_OPTIONS } from "../../utils/businessRuleActionUtils";
import { RULE_TYPES } from "../../utils/businessRuleCreatorUtils";

const ruleTypeBuilder = (actionSettings) => {
    let ruleType = null

    switch(actionSettings.actionOption){
        case ALL_AVAILABLE_ACTION_OPTIONS.ADD_PRODUCT_OPTION:
        case ALL_AVAILABLE_ACTION_OPTIONS.ADD_INSERT_OPTION:
        case ALL_AVAILABLE_ACTION_OPTIONS.CANCEL_ORDER_OPTION:
        case ALL_AVAILABLE_ACTION_OPTIONS.PAUSE_ORDER_OPTION:
            ruleType = RULE_TYPES.ORDER_RULE_TYPE
            break

        case ALL_AVAILABLE_ACTION_OPTIONS.ADD_SHIPPING_PACKAGE_OPTION:
            ruleType = RULE_TYPES.PACKAGE_RULE_TYPE
            break

        default:
            break
    }

    if(!ruleType){
        throw new Error()
    }

    return ruleType
}

export default ruleTypeBuilder