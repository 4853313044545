import { useQuery } from "react-query"
import { MetricHighlighted } from "../../../../components/MetricsCards/MetricHighlighted"
import { useTranslation } from "react-i18next"
import { DEFAULT_QUERY_STALE_TIME, secondsToTimeInteval } from "../chartDataUtils"
import { useMemo } from "react"
import { getOrdersStuckAverage } from "../../../../services/storeMetricsService"

export const StuckOrdersAverage = ({ storeId, dateFilter }) => {
    const { i18n, t } = useTranslation()

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["stuck_orders_average", storeId, dateFilter], () => getOrdersStuckAverage(storeId, dateFilter), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    const blockedOrdersAverage = useMemo(() => {
        if (!data) return ""
        return secondsToTimeInteval(data.stuck_before_pending_duration_average_in_seconds || 0, t)
    }, [data, i18n.language])

    return <MetricHighlighted className="mt-4 mb-10" isLoading={isLoading} text={blockedOrdersAverage} description={isLoading ? t("metrics.generic.on_average") : !data?.stuck_before_pending_duration_average_in_seconds ? t("metrics.generic.no_data") : t("metrics.generic.on_average")}>
        <div className="text-gray-500 text-sm">{t("metrics.stuck_orders.average_subtext")}</div>
    </MetricHighlighted>
}
