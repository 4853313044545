import { AreaChart as TremorAreaChart } from "@tremor/react"
import { useMemo } from "react"
import { ChartSkeleton } from "./ChartSkeleton"

const COLORLIST = ["blue", "purple", "green", "orange", "zinc", "blue", "purple", "green", "orange", "zinc"]

export const AreaChart = ({
    categories = [],
    data = [],
    valueFormatter = (v) => v.toString(),
    children,
    areaChartProps = {},
    isLoading = false,
    colors = COLORLIST,
}) => {
    const chartProps = useMemo(() => {
        if (areaChartProps.colors) {
            return areaChartProps
        } else {
            return { ...areaChartProps, colors: colors }
        }
    }, [colors, areaChartProps])

    return (
        <ChartSkeleton active={isLoading}>
            <TremorAreaChart data={data} index="name" categories={categories} valueFormatter={valueFormatter} {...chartProps} />
            {children}
        </ChartSkeleton>
    )
}

