import { ButtonLoader, Notification, InputField, ConfirmDialog} from "../../components"
import { Loader } from '../../components/Loader';
import {useState, useContext, useEffect,useMemo} from "react";
import {useQuery} from "react-query";
import { UserContext } from "../../hooks/UserContext";
import {useForm} from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { editStoreData, fetchStoreInfo} from "../../services/storeServices"
import { LeftCircleOutlined } from '@ant-design/icons';
import {useSelector, useDispatch} from "react-redux";
import {
    setValidSettingsForm
} from '../../redux/validSettingsFormSlice';

export const OperationNaturSettingsContainer = ({
    onCancel
}) => {

    const [loadingBundleBotton, setLoadingBundleBotton] = useState(false)
    const {register, handleSubmit,watch,formState} = useForm()
    const watchform = watch(); 
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation();
    const {user} = useContext(UserContext)
    const [showNotification, setShowNotification] = useState(false)
    const [changeDetected, setChangeDetected] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const symbolic_return = register('symbolic_return')
    const sale = register('sale')
    const donation = register('donation')
    const remove_stock = register('remove_stock')

    const [dialog, setDialog] = useState({
        loading: false,
        open: false,
        title: "",
        description: "",
        errorMessage: "",
        type: null
    })

    const [customizationData, setCustomizationData] = useState({
        REPLENISHMENT_INVOICE: {},
        REPLENISHMENT_ADJUSTMENT_INVOICE: {},
        SYMBOLIC_RETURN_INVOICE: {},
        SALE_INVOICE: {},
        DONATION_INVOICE: {},
        RETURN_INVOICE:  {},
        REMOVE_STOCK: {}
    })

    const {
        isLoading,
        isError,
        error,
        data,
        isPreviousData,
        refetch,
    } = useQuery(['store_info_op_nature', user.current_store?.id], () => fetchStoreInfo(user.current_store?.id), { keepPreviousData: true })

    useEffect(() => {

        if(!data?.invoice_customization) return
        updateCustomizationData(data)

    }, [data])

    useEffect(() => {
        //console.log("touchedFields", formState.isDirty);
        //setChangeDetected(true)
      },[formState]); // use entire formState object as optional array arg in useEffect, not individual properties of it


    const updateCustomizationData = (storeData) =>{

        let invoice_customization = storeData.invoice_customization
        setCustomizationData(invoice_customization)

    }

    const onSubmit = async (dataTax) => {
        setValidForm(false)
        dataTax = {
            invoice_customization: customizationData
        }

        setLoadingBundleBotton(true)
        editStoreData(user.current_store?.id, dataTax).then((resultado)=>{
            setSuccessMessage(i18n.t("settings.tax_fields.success_message"))
            setShowNotification(true)
            setLoadingBundleBotton(false)
        }).catch((e)=>{
            setErrorMessage(i18n.t("settings.tax_fields.error_message"))
            setShowNotification(true)
            setLoadingBundleBotton(false)
        })
        
    }

    const setValidForm = (edit) => {
        dispatch(setValidSettingsForm({id:"3",change:edit,selectedkeys:["3"] }))
        setChangeDetected(edit)
        setSuccessMessage(false)
    }
    const handleClickOnCancel = () => {
        if (changeDetected && !successMessage){
            setDialog({...dialog, loading: false, open: true})
        }
        else{
            setValidForm(false)
            onCancel()
        }
    }
    const handleClickOnConfirm = () => {
        onCancel()
    }
    
    return (
        <>
            <div className='relative p-5'>
                <form action="#" method="PATCH" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-1 ">
                        <div className="flex items-center justify-between">
                        <div  className="flex items-center justify-start">
                            <LeftCircleOutlined className="text-3xl mr-8 hover:text-indigo-700" onClick={handleClickOnCancel}/>
                            <ConfirmDialog
                                open={dialog.open}
                                setOpen={(value) => setDialog({...dialog, open: value}) }
                                title={i18n.t("settings.validate_changes.title")}
                                description={i18n.t("settings.validate_changes.question")}
                                confirmLabel="Si"
                                onConfirm={handleClickOnConfirm}
                                loading={dialog.loading}
                            />
                            <div  className="flex-col">
                                <p className='text-xl mb-0 font-bold text-blue-gray-900 mt-5'>{i18n.t("settings.billing_config.operation_nature")}<span className = "text-gray-500 italic font-normal"> {i18n.t("settings.billing_config.optional")}</span> </p>
                                <p className = "mt-0 text-base text-gray-500">{i18n.t("settings.billing_config.operation_nature_description")}</p>
                                {successMessage && <label className = "text-green-500 italic font-normal">{i18n.t("settings.validate_changes.saved_data")}</label>}
                                {changeDetected && !successMessage && <label className = "text-yellow-500 italic font-normal">{i18n.t("settings.validate_changes.unsaved_data")}</label>}
                            </div>
                        </div>
                            <div>
                                <ButtonLoader className="" loading_text={i18n.t("settings.tax_fields.processing")} loading={loadingBundleBotton} >
                                    {i18n.t("settings.tax_fields.send_form")}
                                </ButtonLoader>
                                {errorMessage && showNotification? (
                                    <>
                                        <Notification show={showNotification} setShow={setShowNotification} type="error" title={i18n.t("settings.user_settings.error")} description={errorMessage}/>
                                    </>
                                ) : null}
                                {successMessage && showNotification? (
                                    <>
                                        <Notification show={showNotification} setShow={setShowNotification} type="success" title={i18n.t("settings.user_settings.done")} description={successMessage}/>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            !isPreviousData && isLoading && !data ? (
                                <><Loader show={true}></Loader></>
                            ): isError ? (
                                <>Error: {error.message}</>
                            ):
                            (
                                <div >
                                    <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-10 mb-3'> {i18n.t("settings.customize_bills.orders_invocices_title")}</p>

                                    <div className="max-w-full min-w-min">
                                        <div className="bg-white shadow sm:rounded-lg border-gray-200 rounded-2xl grid grid-row ">

                                            <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-8 w-full justify-center border-b border-gray-200 ">
                                                <div>
                                                    <p className="mt-4 mb-4 pl-5 text-sm font-bold">{i18n.t("settings.customize_bills.invoice_table_title")}</p>
                                                </div>
                                                <div>
                                                    <p className="mt-4 mb-4 text-sm font-bold">{i18n.t("settings.customize_bills.operation_nature_table_title")}</p>
                                                </div>
                                                <div>
                                                    <p className="mt-4 mb-4 text-sm font-bold">{i18n.t("settings.customize_bills.reason_table_title")}</p>
                                                </div>
                                            </div>
                                            <div className="divide-y divide-gray-200"></div>
                                            <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-4 w-full justify-center ">
                                                <div>
                                                    <p className="mt-3 pl-5 text-sm">{i18n.t("settings.types_invoices.symbolic_return")}</p>
                                                    
                                                </div>
                                                <div>
                                                    
                                                    <InputField
                                                        maxLength={60}                                                        
                                                        placeholder = {'Outras Saídas - Remessa para Depósito Temporário'}
                                                        value = {customizationData.SYMBOLIC_RETURN_INVOICE?.operation_nature}
                                                        name = "symbolic_return"
                                                        ref={symbolic_return.ref}
                                                        onChange={(v) => {
                                                            setValidForm(true)
                                                            setCustomizationData({
                                                                ...customizationData,
                                                                SYMBOLIC_RETURN_INVOICE:{
                                                                    ...customizationData.SYMBOLIC_RETURN_INVOICE,
                                                                    operation_nature: v
                                                                }
                                                                
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <p className="text-sm mb-0 pr-5">{i18n.t("settings.customize_bills.symbolic_return_description")}</p>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-4 w-full justify-center ">
                                                <div>
                                                    <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.sale")}</p>
                                                </div>
                                                <div>
                                                    <InputField
                                                        maxLength={60}                                                        
                                                        placeholder = {'VENDA DE PRODUTO'}
                                                        value = {customizationData.SALE_INVOICE?.operation_nature}
                                                        name = "sale"
                                                        ref={sale.ref}
                                                        onChange={(v) => {
                                                            setValidForm(true)
                                                            setCustomizationData({
                                                                ...customizationData,
                                                                SALE_INVOICE: {
                                                                    ...customizationData.SALE_INVOICE,
                                                                    operation_nature: v
                                                                }
                                                            })
                                                        }}
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                <p className="text-sm mb-0 pr-5">{i18n.t("settings.customize_bills.sale_description")}</p>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-4 w-full justify-center">
                                                <div>
                                                    <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.donation")}</p>
                                                </div>
                                                <div>

                                                    <InputField
                                                        maxLength={60}                                                        
                                                        placeholder = {'OUTRAS SAÍDAS'}
                                                        value = {customizationData.DONATION_INVOICE?.operation_nature}
                                                        name = "donation"
                                                        ref={donation.ref}
                                                        onChange={(v) => {
                                                            setValidForm(true)
                                                            setCustomizationData({
                                                                ...customizationData,
                                                                DONATION_INVOICE: { 
                                                                    ...customizationData.DONATION_INVOICE,
                                                                    operation_nature: v
                                                                }
                                                            })
                                                        }}
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                <p className="text-sm mb-0 pr-5">{i18n.t("settings.customize_bills.donation_description")}</p>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-4 w-full justify-center">
                                                <div>
                                                    <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.remove_stock")}</p>
                                                </div>
                                                <div>

                                                    <InputField
                                                        maxLength={60}                                                        
                                                        placeholder = {'Outras Entradas - Retorno de Estoque'}
                                                        value = {customizationData.REMOVE_STOCK?.operation_nature}
                                                        name = "remove_stock"
                                                        ref={remove_stock.ref}
                                                        onChange={(v) => {
                                                            setValidForm(true)
                                                            setCustomizationData({
                                                                ...customizationData,
                                                                REMOVE_STOCK: { 
                                                                    ...customizationData.REMOVE_STOCK,
                                                                    operation_nature: v
                                                                }
                                                            })
                                                        }}
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                <p className="text-sm mb-0 pr-5">{i18n.t("settings.customize_bills.remove_stock_description")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-10 mb-3'> {i18n.t("settings.customize_bills.return_invocices_title")} </p>
                                    <div className="max-w-full min-w-min">
                                        <div className="bg-white shadow sm:rounded-lg border-gray-200 rounded-2xl grid grid-row ">

                                            <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-8 w-full justify-center border-b border-gray-200 ">
                                                <div>
                                                    <p className="mt-4 mb-4 pl-5 text-sm font-bold">{i18n.t("settings.customize_bills.invoice_table_title")}</p>
                                                </div>
                                                <div>
                                                    <p className="mt-4 mb-4 text-sm font-bold">{i18n.t("settings.customize_bills.operation_nature_table_title")}</p>
                                                </div>
                                                <div>
                                                    <p className="mt-4 mb-4 text-sm font-bold">{i18n.t("settings.customize_bills.reason_table_title")}</p>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-8 w-full justify-center ">
                                                <div>
                                                    <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.return")}</p>
                                                </div>
                                                <div>
                                                    <InputField
                                                        maxLength={60}                                                        
                                                        placeholder = {'Outras Entradas - Retorno de mercadoria'}
                                                        value = {customizationData.RETURN_INVOICE?.operation_nature}
                                                        onChange={(v) => {
                                                            setValidForm(true)
                                                            setCustomizationData({
                                                                ...customizationData,
                                                                RETURN_INVOICE: {
                                                                    ...customizationData.RETURN_INVOICE,
                                                                    operation_nature: v
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                <p className="text-sm mb-0 pr-5">{i18n.t("settings.customize_bills.return_description")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className='text-lg mb-0 font-bold text-blue-gray-900 mt-10 mb-3'> {i18n.t("settings.customize_bills.replenishment_invocices_title")} </p>
                                    <div className="max-w-full min-w-min">
                                        <div className="bg-white shadow sm:rounded-lg border-gray-200 rounded-2xl grid grid-row ">

                                            <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-8 w-full justify-center border-b border-gray-200 ">
                                                <div>
                                                    <p className="mt-4 mb-4 pl-5 text-sm font-bold">{i18n.t("settings.customize_bills.invoice_table_title")}</p>
                                                </div>
                                                <div>
                                                    <p className="mt-4 mb-4 text-sm font-bold">{i18n.t("settings.customize_bills.operation_nature_table_title")}</p>
                                                </div>
                                                <div>
                                                    <p className="mt-4 mb-4 text-sm font-bold">{i18n.t("settings.customize_bills.reason_table_title")}</p>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-4 w-full justify-center ">
                                                <div>
                                                    <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.replenishment")}</p>
                                                </div>
                                                <div>
                                                    <InputField
                                                        maxLength={60}                                                        
                                                        placeholder = {'Outras Entradas - Retorno Simbólico de Depósito Temporário'}
                                                        value = {customizationData.REPLENISHMENT_INVOICE?.operation_nature}
                                                        onChange={(v) => {
                                                            setValidForm(true)
                                                            setCustomizationData({
                                                                ...customizationData,
                                                                REPLENISHMENT_INVOICE: {
                                                                    ...customizationData.REPLENISHMENT_INVOICE,
                                                                    operation_nature: v
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                <p className="text-sm mb-0 pr-5">{i18n.t("settings.customize_bills.replenishment_description")}</p>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 gap-y-5 gap-x-3.5 sm:grid-cols-3 mb-4 w-full justify-center ">
                                                <div>
                                                    <p className="mt-3 pl-5 text-sm ">{i18n.t("settings.types_invoices.replenishment_adjustment")}</p>
                                                </div>
                                                <div>
                                                    <InputField
                                                        maxLength={60}                                                        
                                                        placeholder = {'Outras Entradas - Retorno de mercadoria'}
                                                        value = {customizationData.REPLENISHMENT_ADJUSTMENT_INVOICE?.operation_nature}
                                                        onChange={(v) => {
                                                            setValidForm(true)
                                                            setCustomizationData({
                                                                ...customizationData,
                                                                REPLENISHMENT_ADJUSTMENT_INVOICE: {
                                                                    ...customizationData.REPLENISHMENT_ADJUSTMENT_INVOICE,
                                                                    operation_nature: v
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <p className="text-sm mb-0 pr-5">{i18n.t("settings.customize_bills.replenishment_adjustment_description")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                            )
                        }
                    </div>
                    <div className="flex items-center justify-between">
                        <div  className="flex items-center justify-start">
                        </div>
                        <div>
                            <ButtonLoader className="" loading_text={i18n.t("settings.tax_fields.processing")} loading={loadingBundleBotton}>
                                {i18n.t("settings.tax_fields.send_form")}
                            </ButtonLoader>

                            {errorMessage && showNotification? (
                                <>
                                    <Notification show={showNotification} setShow={setShowNotification} type="error" title={i18n.t("settings.user_settings.error")} description={errorMessage}/>
                                </>
                            ) : null}
                            {successMessage && showNotification? (
                                <>
                                    <Notification show={showNotification} setShow={setShowNotification} type="success" title={i18n.t("settings.user_settings.done")} description={successMessage}/>
                                </>
                            ) : null}
                        </div>
                    </div>
                </form>
            </div>
        </>
    )

}