import { TagIcon } from "@heroicons/react/outline"
import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';

export const ComparisonTypes = {
  LESS_THAN: "LESS_THAN",
  LESS_THAN_OR_EQUAL_TO: "LESS_THAN_OR_EQUAL_TO",
  GREATER_THAN: "GREATER_THAN",
  GREATER_THAN_OR_EQUAL_TO: "GREATER_THAN_OR_EQUAL_TO",
}

const compareValues = (a, b, comparisonType) => {
  switch (comparisonType) {
    case ComparisonTypes.LESS_THAN:
      return a < b

    case ComparisonTypes.LESS_THAN_OR_EQUAL_TO:
    return a <= b

    case ComparisonTypes.GREATER_THAN:
      return a > b

    case ComparisonTypes.GREATER_THAN_OR_EQUAL_TO:
    return a >= b

    default:
    return false
  }
}

export const CurrencyInputField = React.forwardRef(
  (
    {
      icon,
      value = "",
      onChange = () => {},
      minInputValue = null,
      maxInputValue = null,
      minInputComparisonType = ComparisonTypes.GREATER_THAN_OR_EQUAL_TO,
      maxInputComparisonType = ComparisonTypes.LESS_THAN_OR_EQUAL_TO,
      permanentError = false,
      onError = (message) => {},
      title = null,
      labelElement = null,
      placeholder,
      required = false,
      isDisabled = false,
      readOnly = false,
      inputClassName = "",
      help = null,
      precision = 11,
      scale = 4,
      showErrors = true,
      error,
      register = () => { return; },
      name,
      validationOptions,
      customMinInputMessage = null,
      customMaxInputMessage = null,
      mirrorVerticalPadding = false,
      ...params
    },
    ref
  ) => {
    const { className } = params
    const { t, i18n } = useTranslation(); 
    const [localValue, setLocalValue] = useState(value)
    const [errorMessage, setErrorMessage] = useState(null)
    const [isValid, setIsValid] = useState(true)

    let options = {}
    if(required)  {
      options = {...validationOptions}
      options.required = i18n.t("inputPlaceholder.required_field")
    }

    // useEffect(() => {
    //   console.log(options);
    // }, null)

    useEffect(() => {
      setLocalValue(value)
    }, [value])

    useEffect(() => {
      if(!permanentError){return}

      let status = true

      if(minInputValue !== null){
        const minValue = parseFloat(minInputValue)
        const newValue = parseFloat(localValue)

        const comparisonResult = compareValues(newValue, minValue, minInputComparisonType)

        if(!comparisonResult) {
          setErrorMessage(customMinInputMessage ? customMinInputMessage : `${i18n.t("currencyInput.minimum_value")} ${minValue}`)
          status = false
        }
      }

      if(maxInputValue !== null){
        const maxValue = parseFloat(maxInputValue)
        const newValue = parseFloat(localValue)

        const comparisonResult = compareValues(newValue, maxValue, maxInputComparisonType)

        if(!comparisonResult) {
          setErrorMessage(customMaxInputMessage ? customMaxInputMessage : `${i18n.t("currencyInput.maximum_value")} ${maxValue}`)
          status = false
        }
      }

      if(status){
        setErrorMessage(null)
      }

      setIsValid(status)
    }, [minInputValue, maxInputValue, localValue, i18n, permanentError, customMinInputMessage, customMaxInputMessage, minInputComparisonType, maxInputComparisonType])

    const handleChange = (e) => {
      let val = e.target.value
      val = val.replace(/[^\d.]/g, "")
      let decimals = val.split(".")
      while (decimals.length > 2) {
        decimals.pop()
      }
      if (decimals[1]?.length > scale) {
        setErrorMessage(`${i18n.t("currencyInput.max")} ${scale} ${i18n.t("currencyInput.dec")}`)
        onError(errorMessage)
        return
      }
      if (decimals[0].length > (precision-scale)) {
        setErrorMessage(`${i18n.t("currencyInput.max")} ${precision-scale} ${i18n.t("currencyInput.integer_digits")}`)
        onError(errorMessage)
        return
      }
      val = decimals.join(".")

      let showErrors = false

      if(minInputValue !== null){
        const minValue = parseFloat(minInputValue)
        const newValue = parseFloat(val)

        const comparisonResult = compareValues(newValue, minValue, minInputComparisonType)

        if(!comparisonResult) {
          setErrorMessage(customMinInputMessage ? customMinInputMessage : `${i18n.t("currencyInput.minimum_value")} ${minValue}`)
          onError(errorMessage)
          setIsValid(false)
          showErrors = true
        }
      }

      if(maxInputValue !== null){
        const maxValue = parseFloat(maxInputValue)
        const newValue = parseFloat(val)

        const comparisonResult = compareValues(newValue, maxValue, maxInputComparisonType)

        if(!comparisonResult) {
          setErrorMessage(customMaxInputMessage ? customMaxInputMessage : `${i18n.t("currencyInput.maximum_value")} ${maxValue}`)
          onError(errorMessage)
          setIsValid(false)
          showErrors = true
        }
      }

      if(!showErrors){
        setErrorMessage(null)
        setIsValid(true)
      }

      setLocalValue(val)
      onChange(val)

      // if(required && val.length <= 0) {
      //   setErrorMessage(`${i18n.t("inputPlaceholder.required_field")}`)
      //   onError(errorMessage)
      // }
    }

    const ErrorMessage = ({className= null}) =>  {
      return(
        <div className={`text-red-500 italic mt-2 leading-6 ${className}`}>
          {errorMessage}
        </div>
      )
    }

    return (
      <div className={className}>
        {title && (
          <label className="block text-sm font-medium text-gray-700">
            {title}
            {required && <span className="text-red-500"> *</span>}
          </label>
        )}  
        {labelElement}

        {(mirrorVerticalPadding && errorMessage && showErrors) && (
          <ErrorMessage className="opacity-0"/>
        )}

        <div className="relative">
          <div className="absolute top-0 bottom-0 left-0 flex justify-center items-center ml-2">
            {icon}
          </div>
          <input
            ref={ref}
            type="text"
            className={
              "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md pl-8" +
              " " +
              `${!isValid ? "border-red-500" : ""}` +
              inputClassName
            }
            placeholder={placeholder}
            onChange={(e) => handleChange(e)}
            value={localValue}
            // defaultValue={localValue}
            readOnly={readOnly}
            onBlur={() => { if(!permanentError){setErrorMessage(null); setIsValid(true);} }}
            disabled={isDisabled}
            // name={name}
            // {...register(name, options)}
          />
        </div>
        <div className="text-red-500">
          {error && error.message}
        </div>
        {errorMessage && showErrors && (
          <ErrorMessage/>
        )}
        {help && <div className="italic text-gray-400 mt-1">{help}</div>}
      </div>
    )
  }
)
