import {useEffect, useState} from "react";

export const InputQuantity = ({initialValue = 0, className="", updateData, onlyMinus = false, minIs0 = true, maxValue = Number.MAX_VALUE, ...props}) => {
    const [value, setValue] = useState(initialValue)

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const onPlusHandler = () => {
        if ((value+1) <= maxValue) {
            setValue(value+1)
            if(updateData){
                updateData(value+1)
            }
        }
    }

    const onLessHandler = () => {
        if ((value-1) >= 0 || !minIs0) {
            setValue(value-1)
            if(updateData){
                updateData(value-1)
            }
        }
        
    }

    return (
        <>
            <div className="flex flex-row border h-10 w-24 rounded border-gray-400 relative">
                <button
                    className={`font-semibold border-r text-gray-900 border-gray-400 h-full w-20 flex rounded-l ${value == 0 ? "bg-gray-200 cursor-not-allowed opacity-50" : "bg-white hover:bg-gray-200 focus:outline-none cursor-pointer"}`}
                    onClick={onLessHandler}
                    disabled={value == 0}
                >
                    <span className="m-auto">-</span>
                </button>

                <div className="bg-white w-24 text-xs md:text-base flex items-center justify-center cursor-default">
                    <span>{value}</span>
                </div>
                {onlyMinus ? 
                
                ""
                
                : 
                (
                    <>
                    <button
                    className={`font-semibold border-l text-gray-800 border-gray-400 h-full w-20 flex rounded-r ${value == maxValue ? "bg-gray-200 cursor-not-allowed opacity-50" : "bg-white hover:bg-gray-200 focus:outline-none cursor-pointer"}`}
                    onClick={onPlusHandler}
                    disabled={value == maxValue}
                >
                    <span className="m-auto">+</span>
                    </button>
                    </>
                )
                }
            </div>
        </>
    )
}
