export const getAllStoreIdsFromAccounts= (user) => {
    let user_store_ids = []
    user.accounts?.forEach(account => {
        account.stores?.forEach(store => {
            user_store_ids.push(store.id)
        })
    })
    return user_store_ids
}

export const findStoreInAccounts = (storeId, user) => {
    let accounts = user.accounts
    let foundStore = null
    for (const account of accounts) {
        let foundIndex = account.stores.findIndex(
            (store) => store.id === storeId
        )

        if (foundIndex !== -1) {
            foundStore =  account.stores[foundIndex]
            break
        }
    }
    return foundStore
}
