
export const getSkus = (productCodes, alternStyle = false) => {
    return (productCodes ? <div className={`${alternStyle ? "flex flex-row flex-wrap gap-y-2" : ""}`}>
        {/* {productCodes.map((code, index) => <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 "+ (alternStyle ? "bg-gray-200 text-gray-500":"bg-blue-100 text-blue-800")} key={index}>
        {code.sku}</span>)} */}
        {productCodes.map((code, index) => <span className={"inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"} key={index}>
        {code.sku}</span>)}
    </div>: <>-</>)
}

// const getVariantName = (name, parentName) => {
//     return (
//         parentName ? 
//         <>
//             {name}<i>({parentName.length > 20 ? (parentName.substring(0, 15)+"...") : parentName})</i>
//         </> : <>{name}</>
//     )
// }

    export const getParentTotalStock = (variants) => {
        
        if (variants.length === 1) {
            return {"DAMAGED": variants[0].stock.DAMAGED, AVAILABLE: <span className={(variants[0].stock.AVAILABLE === 0 ? "text-red-400 font-semibold" : null)}>{variants[0].stock.AVAILABLE}</span>, "OUTGOING": variants[0].stock.OUTGOING}
        }
        const stock = variants.reduce(function (a, b) {
            // console.log('a', a)
            // console.log('b', b)
            let a_s = false
            let b_s = false
            if (a.stock){
                a_s = a.stock
            } else {
                a_s = a
            }
            if (b.stock){
                b_s = b.stock
            } else {
                b_s = b
            }
            return {"DAMAGED": a_s.DAMAGED + b_s.DAMAGED, AVAILABLE: a_s.AVAILABLE + b_s.AVAILABLE, "OUTGOING": a_s.OUTGOING + b_s.OUTGOING}; // returns object with property x
        })

        const childrenOutOfStock = getChildsOutofStock(variants)
        if (childrenOutOfStock > 0) {
            stock.AVAILABLE = <span className="text-red-400 font-semibold">{childrenOutOfStock + " variantes sin stock"}</span>
        } else {
            stock.AVAILABLE = <span className={(stock.AVAILABLE === 0 ? "text-red-400 font-semibold" : null)}>{stock.AVAILABLE}</span>
        }

        return stock
    }

   export const getChildsOutofStock = (variants) => {
       const outOfStock = variants.filter(variant => variant.stock.AVAILABLE == 0 || typeof variant.stock.AVAILABLE === 'object' );
       return outOfStock.length
   }

export const getStockContent = (product) => {
    const outOfStock = product?.stock?.OUT_OF_STOCK
    const usedBuffer = product?.stock?.BUFFER_STOCK
    const available = product?.stock?.AVAILABLE == 0 ? outOfStock : product?.stock?.AVAILABLE
    const availableInWarehouse = product?.stock?.ACTIVE == 0 ? outOfStock : product?.stock?.ACTIVE
    const availableOnPresale = product.stock?.["INCOMING-SELL-AHEAD"] + product.stock?.["RECEPTION-INCOMING-SELL-AHEAD"]

    const reservedByOrders = product.stock?.RESERVED_BY_ORDERS
    const reservedByOrdersInWarehouse = product.stock?.["RESERVED-WITHOUT-SELL-AHEAD"]
    const reservedByOrdersInPresale = product.stock?.["RESERVED-INCOMING-SELL-AHEAD"] + product.stock?.["RESERVED-RECEPTION-INCOMING-SELL-AHEAD"]

    const unavailable = product.stock?.UNAVAILABLE
    const damaged = product.stock?.DAMAGED
    const holded = product.stock?.HOLDED
    const expired = product.stock?.EXPIRED
    const reservedByReturns = product.stock?.RESERVED_BY_RETURNS
    
    // const reservedInReturns = product.stock?.RESERVED_BY_RETURNS

    return ({
        ...product.stock,
        AVAILABLE: {
            value: available,
            inWarehouse: product.is_kit ? null : availableInWarehouse,
            inPresale: product.is_kit ? null : availableOnPresale,
            redNumberLabels: true
        },
        RESERVED_BY_ORDERS: {
            value: product.is_kit ? null : reservedByOrders,
            inWarehouse: product.is_kit ? null : reservedByOrdersInWarehouse,
            inPresale: product.is_kit ? null : reservedByOrdersInPresale,
            // inReturns: product.is_kit ? null : reservedInReturns,
        },
        UNAVAILABLE:{
            value: product.is_kit ? null : unavailable,
            damaged: product.is_kit ? null : damaged,
            expired: !product.is_kit && product.has_expiration || expired > 0 ? expired : null,
            holded: !product.is_kit && product.has_expiration || holded > 0 ? holded : null,
            reservedByReturns: product.is_kit ? null : reservedByReturns,
            buffer: product.is_kit || product.buffer_stock === null ? null : usedBuffer,
        }
    })
   }

   export const getChildStock = (childStock)  => {
       if (childStock.AVAILABLE === 0){
            childStock.AVAILABLE = <span className="bg-red-400 text-white font-semibold">{childStock.OUT_OF_STOCK < 0 ? childStock.OUT_OF_STOCK : 0}</span>  
            //childStock.AVAILABLE = <span className="bg-red-400 text-white font-semibold">{childStock.AVAILABLE}</span>  
        }
       return childStock
   }

export const getVariants = (variants, parentName, searchSku, multiSearch) => {
    if (!variants)
        variants = []

    if (searchSku) {
        variants = variants.filter((v)=>v.product_codes.find((pc) => pc.sku.search(searchSku)>=0 ))
    }
    else if (multiSearch) {
        let matches = variants.filter((v)=>v.product_codes.find((pc) => pc.sku.search(multiSearch)>=0 )||v.name.search(multiSearch)>=0 )
        variants = matches.length > 0 ? matches : variants
    }
    return variants.map((product) => {
        return {
            ...product,
            id: product.id,
            variantId: product.variant_id,
            // name: getVariantName(product.name, parentName),
            stock: getChildStock(product.stock),
            //stock: product.stock,
            name: product.name,
            sku: getSkus(product.product_codes, true),
            parentName: parentName
        }
    })
}

export const getProducts = (product) => {
    return product.map((product) => {
        return {
            ...product,
            id: product.id,
            store: product.name,
            pz:product.quantity
        }
    })
}

export const calculateKitPrice = (kitChilds, kitQuantity, fixedDecimals = 2) => {
    if(!kitChilds || kitChilds.length <= 0) {return 0.0}

    return parseFloat(kitChilds.reduce((total, {quantity, price_per_item, child_product}) =>  {
        if(child_product?.is_dropshipping) {return total}

        const price = !price_per_item && !child_product?.price ? 0.0 : price_per_item ? price_per_item : child_product?.price

        return total + (price * (quantity * kitQuantity))
    },0).toFixed(fixedDecimals))
}

export const checkIfKitFullOfInserts = (kitChilds) => {
    if(!kitChilds || kitChilds.length <= 0) {return false}

    return kitChilds.every(childProduct => !childProduct.child_product?.is_scannable)
}
