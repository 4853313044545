import { IntercomProvider } from "react-use-intercom"
import { isProductionEnvironment } from "../../helpers/environmentHelper"

const INTERCOM_APP_ID = "l3zn8t4j"

export const IntercomContainer = ({ children }) => {
    if (isProductionEnvironment) {
        return <IntercomProvider appId={INTERCOM_APP_ID}>{children}</IntercomProvider>
    } else return children
}
