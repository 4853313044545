import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    shippingAddress: {
        status: null,
        firstName: "",
        lastName: "",
        phone: "",
        email:"",
        tax_id: "",
        company: "",
        tax_regime: "PHYSICAL",
        state_registration: "",
        address: "",
        number: "",
        neighborhood: "",
        country: "",
        city: "",
        province: "",
        zipCode: ""
    },
    billingAddress: {
        status: "same",
        firstName: "",
        lastName: "",
        email:"",
        phone: "",
        tax_id: "",
        company: "",
        tax_regime: "PHYSICAL",
        state_registration: "",
        address: "",
        number: "",
        neighborhood: "",
        country: "",
        city: "",
        province: "",
        zipCode: ""
    },

    order: {
        shippingMethod: {},
        shippingDate: "",
        shippingNumber: "",
        orderNumber: "",
        products_count: 0,
        referenceNumber: "",
        shipping_documents:[],
        is_cod: false,
        total_price:null,
        buyer_shipping_price: null,
        discount_price: 0.00,
        currency:null,
        order_type: "SALE",
        extra_fees_price: 0
    },

    products: [
        /*
        {
            id,
            name,
            quantity,
            skus
        }
        * */
    ]
}

export const createOrderSlice = createSlice({
    name: 'crateOrder',
    initialState,
    reducers: {
        setOrderData: (state, action) => {
            state.order = action.payload
        },
        setShippingAddress: (state, action) => {
            state.shippingAddress = action.payload
        },
        setBillingAddress: (state, action) => {
            state.billingAddress = action.payload
        },
        addProducts: (state, action) => {
            if(Array.isArray(action.payload))   {
                let exists = false;
                action.payload.forEach(item => {
                    console.log(item);
                    state.products.forEach((_registeredProduct) => {
                        if(item.product_id == _registeredProduct.product_id)    {
                            exists = true;
                            _registeredProduct.quantity = _registeredProduct.quantity + 1;
                        }
                    })
                    // state.products.push(item)
                })

                if(!exists) {
                    action.payload.forEach(item => {
                        state.products.push(item)
                    })
                }
            }
            else {
                // state.products.push(action.payload)
                let exists = false;
                state.products.forEach((_registeredProduct) => {
                    if(action.payload.product_id == _registeredProduct.product_id)    {
                        exists = true;
                        _registeredProduct.quantity = _registeredProduct.quantity + 1;
                    }
                })

                if(!exists) {
                    state.products.push(action.payload)
                }
            }
        },
        deleteProduct: (state, action) => {
            state.products = state.products.filter(item => item.key!==action.payload)
        },
        modifyProduct: (state, action) => {
            state.products = state.products.map(item => {
                if(item.key===action.payload.key)
                    return action.payload
                return item
            })
        },
        resetData: (state, action) => {
            state.order = initialState.order
            state.shippingAddress = initialState.shippingAddress
            state.billingAddress = initialState.billingAddress
            state.products = initialState.products
            state.shipping_documents = initialState.shipping_documents
        },
        setData: (state, action) => {
            state.order = action.payload.order
            state.shippingAddress = action.payload.shippingAddress
            state.billingAddress = action.payload.billingAddress
            state.products = action.payload.products
        }
    }
})

export const {
    setOrderData,
    setShippingAddress,
    setBillingAddress,
    addProducts,
    deleteProduct,
    modifyProduct,
    setData,
    resetData
} = createOrderSlice.actions

export const orderShippingAddress = (state) => state.createOrder.shippingAddress
export const orderBillingAddress = (state) => state.createOrder.billingAddress
export const orderData = (state) => state.createOrder.order
export const orderProducts = (state) => state.createOrder.products
export default createOrderSlice.reducer
