import {useState} from 'react'
import {createOrGetChannel} from "../../services"
import {UserContext} from "../../hooks/UserContext"
import { useContext } from 'react'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {Notification, ButtonLoader} from "../../components";
import { STORE_PATH, INTEGRATIONS } from '../../navigation/constants';
import { useTranslation } from 'react-i18next';

export function AmazonIntegration() {
    const { t, i18n } = useTranslation(); 
    const {user} = useContext(UserContext)
    const { url:storePath } = useRouteMatch({ path: STORE_PATH })

    const [loadingButton, setLoadingButton] = useState(false)
    const [appName, setShopId] = useState("")
    const [apiPassword, setApiPassword] = useState("")
    const history = useHistory()
    const link = "https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.application-oa2-client.bd8a3443434b4320b6b7aac9282558d9"

    const handleApiPasswordChange = (event) => {
        setApiPassword(event.target.value)
    }

    const handleSubmitButtom = async (event) => {
        setLoadingButton(true)
        let url1 = "https://sellercentral.amazon.com.mx/apps/authorize/consent?application_id=amzn1.application-oa2-client.bd8a3443434b4320b6b7aac9282558d9&version=beta"
        let url2 = "https://sellercentral.amazon.com.mx/apps/authorize/consent?application_id=amzn1.application-oa2-client.e0a6fdcff73c4881a415082b426f341d&version=beta"
        let url01 = "https://sellercentral.amazon.com.mx/apps/authorize/consent?application_id=amzn1.sp.solution.f366e86a-0bb5-477b-913c-1357205041a1&version=beta"
        let url = "https://sellercentral.amazon.com.mx/apps/authorize/consent?application_id=amzn1.sp.solution.5c43b1ad-97b0-429e-957e-81858f6ee53c&version=beta"

        window.open(url, "_blank") 
    }

    const handleSubmit = async (event) => {
        setLoadingButton(true)
        try {
            let res = await createOrGetChannel("Amazon", null, apiPassword, "amazon", user.current_store?.id)

            if(res.status===422 && res.data?.error?.startsWith("ChannelIntegration Already exist"))
                throw i18n.t("integration.bling.Existing_sales_channel")
                setLoadingButton(false)
            if(res.status===201 || res.status===200)
            setLoadingButton(false)
                history.push(storePath + INTEGRATIONS)
                // window.location.href = res.data.config.install_url
        } catch (error) {
            console.error("Error creating integration: ", error)
            alert (i18n.t("integration.bling.Error_creating_integration")) 
            setLoadingButton(false)
        }
    }

    return (
        <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-10">
            <div className="relative max-w-xl mx-auto">
                <svg
                    className="absolute left-full transform translate-x-1/2"
                    width={404}
                    height={404}
                    fill="none"
                    viewBox="0 0 404 404"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="85737c0e-0916-41d7-917f-596dc7edfa27"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"/>
                </svg>
                <svg
                    className="absolute right-full bottom-0 transform -translate-x-1/2"
                    width={404}
                    height={404}
                    fill="none"
                    viewBox="0 0 404 404"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="85737c0e-0916-41d7-917f-596dc7edfa27"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"/>
                </svg>
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">{i18n.t("integration.amazon.Integrate_your_store")}</h2>
                </div>
                <div>
                
                    <form className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8" onSubmit={(event)=>handleSubmit(event)}>

                    <div className="sm:col-span-2">
                            
                            <label htmlFor="company_website" className="mt-5 block text-sm font-medium text-gray-700">
                                {i18n.t("integration.amazon.refresh_token")}
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
           
                                <input
                                    type="text"
                                    name="company_website"
                                    id="company_website"
                                    className="flex-1 min-w-0 block w-full px-3 py-3 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500  border-gray-300"
                                    placeholder=""
                                    onChange={(event)=>handleApiPasswordChange(event)}
                                    value={apiPassword}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <ButtonLoader className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" loading={loadingButton} onClick={() => handleSubmit()} disabled={loadingButton}>{i18n.t("integration.amazon.integrate_amazon")}</ButtonLoader>
                        </div>
                        {/* <div className="sm:col-span-2">
                           
                            <ButtonLoader className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" loading={loadingButton} onClick={() => handleSubmit()} disabled={loadingButton}>{i18n.t("integration.amazon.integrate_amazon")}</ButtonLoader>
                        </div> */}
                    </form>
                </div>
            </div>
        </div>
    )
}