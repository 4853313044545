import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useTranslation } from "react-i18next"
import { DEFAULT_QUERY_STALE_TIME, INTERVAL_DAY, formatDates, groupDaysByRanges, parseDates, popCategories } from "../chartDataUtils"
import { getClickToDoorHistoric } from "../../../../services/storeMetricsService"
import { BarChart } from "../../../../components/MetricsCards/BarChart"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"
import { formatPercentage } from "../../../../utils/StringUtils"

export const ClickToDoorHistoric = ({ storeId = null, dateFilter = null, interval = INTERVAL_DAY }) => {
    const { i18n, t } = useTranslation()

    const [formattedData, setFormattedData] = useState([])
    const [categories, setCategories] = useState([])
    const ranges = [0, [1,2], [3,4,5], [6,7,8,9,10], [11,12,13,14,15], 16]

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["click_to_door_historic", storeId, dateFilter, interval], () => getClickToDoorHistoric(storeId, dateFilter, interval), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to && !!interval,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    useEffect(() => {
        const formatData = async (data) => {
            // [{truncated_date:a, categories: {b => c, d => f}}]
            const dataParsedDates = parseDates(data)
            const [groupedByDaysData, uniqueCategories] = groupDaysByRanges(dataParsedDates, ranges, t("metrics.click_to_door.historic.day"), t("metrics.click_to_door.historic.days") )
            setCategories(uniqueCategories)
            const flattenedData = popCategories(groupedByDaysData)
            const formattedData = formatDates(flattenedData, interval, i18n.language)
            setFormattedData(formattedData)
            return null
        }
        formatData(data).catch((error) => { console.error(error) })
    }, [data, interval, i18n.language])
    
    return (
        <div className="flex flex-col h-full">
        <MetricTitle className={"lg:ml-3"} title={t("metrics.click_to_door.historic.title")} />
        <BarChart
            categories={categories}
            data={formattedData}
            valueFormatter={(percentageValue) =>
                `${formatPercentage(percentageValue.getPercentage())} (${percentageValue.getValue()} ${t("metrics.generic.orders")})`
            }
            barChartProps={{
                stack: true,
                relative: true,
                showGridLines: false,
                className: "h-60 grow",
            }}
            isLoading={isLoading}
        />
        </div>
    )
}
