import { Skeleton, Tooltip } from "antd"
import { Flex, Metric, Text } from "@tremor/react"
import { InformationCircleIcon } from "@heroicons/react/outline"


export const MultipleMetricsCard = ({ metrics = [], children, showLoader = false, nCols=2 }) => {
    return (
        <Flex justifyContent="start" alignItems={"stretch"} className={`flex-row flex-wrap gap-y-4 drop-shadow-md divide-x-0 divide-x divide-y-0`}>
            {metrics.map((stat, index) => (
                <div key={stat.key} className={`w-1/2 relative bg-white py-3 px-3 lg:px-5 
                    ${index % nCols === 0 ? 'rounded-l-lg' : 
                    index % nCols === 1 ? 'rounded-r-lg' : ""}`}>
                    {showLoader && <Skeleton.Button active size="large" shape="square" className="absolute top-2" />}
                    <Metric className={stat.className}>
                        {showLoader ? "\u00A0" : stat.value}
                    </Metric>
                    <Text className="mt-2 tracking-tight inline-block">{stat.description}</Text>
                    {stat.informationCircleText && 
                        <Tooltip placement="bottomLeft" title={stat.informationCircleText}>
                            <InformationCircleIcon className='ml-2 mb-0.5 w-4 h-4 inline text-gray-500'/>
                        </Tooltip>}
                </div>
            ))}
            {children}
        </Flex>
    )
}