export const DEFAULT_GENERAL_SETTINGS = {
    alias: "",
    validityStartDate: null,
    validityEndDate: null,
    withoutValidityEnd: false
}

export const DEFAULT_ACTION_SETTINGS = {
    actionOption: null,
    productInsertOption: null,
    productsToInsert: []
}

export const DEFAULT_STATEMENT_SETTINGS = {
    statement: null,
    conditions: []
}

export const CREATOR_STEPS = {
    CONFIGURE_RULE_GENERAL_SETTINGS: "CONFIGURE_RULE_GENERAL_SETTINGS",
    CONFIGURE_RULE_ACTION: "CONFIGURE_RULE_ACTION",
    CONFIGURE_RULE_STATEMENT: "CONFIGURE_RULE_STATEMENT"
}

export const RULE_TYPES = {
    ORDER_RULE_TYPE: "ORDER_RULE_TYPE",
    PACKAGE_RULE_TYPE: "PACKAGE_RULE_TYPE"
}
