import { numberToString } from "../../../../helpers/internationalization"
import { AreaChart } from "../../../../components/MetricsCards/AreaChart"
import { useTranslation } from "react-i18next"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"
import { useEffect, useState } from "react"
import { getShippedStockHistoric } from "../../../../services/storeMetricsService"
import { useQuery } from "react-query"
import { DEFAULT_QUERY_STALE_TIME, INTERVAL_DAY, formatDates, parseDates, popCategories, replaceCategories } from "../chartDataUtils"

export const ShippedStockHistoric = ({ 
    storeId = null, dateFilter = null, interval = INTERVAL_DAY,
    calculateTotalStockSold = () => {},
    setIsLoading = () => {}
}) => {
    const { i18n, t } = useTranslation()

    const [formattedData, setFormattedData] = useState([])
    const [categories, setCategories] = useState([])

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["shipped_stock_historic", storeId, dateFilter, interval], () => getShippedStockHistoric(storeId, dateFilter, interval), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to && !!interval,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    useEffect(() => {
        const formatData = async (data) => {
            // [{truncated_date:a, categories: {b => c, d => f}}]
            const dataParsedDates = parseDates(data)
            const dataReplacedCategories = replaceCategories(dataParsedDates, { "shipped_scannable_stock": t("metrics.total_shipped_stock_historic.legend") })
            setCategories([t("metrics.total_shipped_stock_historic.legend")])
            const flattenedData = popCategories(dataReplacedCategories, null)
            const formattedData = formatDates(flattenedData, interval, i18n.language)
            setFormattedData(formattedData)
            calculateTotalStockSold(!data ? 0 : data.reduce((total, item) => total + item.shipped_scannable_stock, 0))
            setIsLoading(isLoading)
            return null
        }
        formatData(data).catch((error) => { console.error(error) })
    }, [data, interval, i18n.language])

    return (
        <>
            <MetricTitle title={t("metrics.total_shipped_stock_historic.title")}/>
            <AreaChart
                categories={categories}
                data={formattedData}
                valueFormatter={(n) => numberToString(n, i18n.language)}
                areaChartProps={{
                    showLegend: false,
                    showGridLines: false,
                    curveType: "monotone",
                    // noDataText: 'No hay pedidos enviados en este periodo',
                    className: "h-60",
                }}
                isLoading={isLoading}
            />
        </>
    )
}
