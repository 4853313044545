import { createSlice } from '@reduxjs/toolkit'

export const pageSizeSlice = createSlice({
  name: 'pageSize',
  initialState: {
    productsList: 25,
    ordersList: 25,
    returnsList: 25,
    replenishmentsList: 10,
    storesList: 25,
    productList: 25,
    claimList: 25,
    usersList: 25,
    workOrdersList: 10,
    statementsList: 12,
    ordersDetailList: 10
  },
  reducers: {
    setProductsListPageSize: (state, action) => {
      state.productsList = action.payload
    },
    setOrdersListPageSize: (state, action) => {
      state.ordersList = action.payload
    },
    setReturnsListPageSize: (state, action) => {
      state.returnsList = action.payload
    },
    setReplenishmentsListPageSize: (state, action) => {
      state.replenishmentsList = action.payload
    },
    setStoresListPageSize: (state, action) => {
      state.storesList = action.payload
    },
    setProductListPageSize: (state, action) => {
      state.productList = action.payload
    },
    setClaimsListPageSize: (state, action) => {
      state.claimList = action.payload
    },
    setUsersListPageSize: (state, action) => {
      state.usersList = action.payload
    },
    setWorkOrdersListPageSize: (state, action) => {
      state.workOrdersList = action.payload
    },
    setStatementsListPageSize: (state, action) => {
      state.statementsList = action.payload
    },
    setOrdersDetailListPageSize: (state, action) => {
      state.ordersDetailList = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  setProductsListPageSize, 
  setOrdersListPageSize, 
  setReturnsListPageSize, 
  setReplenishmentsListPageSize, 
  setStoresListPageSize,
  setProductListPageSize,
  setClaimsListPageSize,
  setUsersListPageSize,
  setWorkOrdersListPageSize,
  setStatementsListPageSize,
  setOrdersDetailListPageSize
 } = pageSizeSlice.actions

export default pageSizeSlice.reducer