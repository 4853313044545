import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    openDialog: false
};

export const importProductsSlice = createSlice({
    name: 'importProducts',
    initialState,
    reducers: {
        setOpenDialog: (state, action) => {
            state.openDialog = action.payload
        }
    }
});

export const { setOpenDialog } = importProductsSlice.actions;

export const openDialog = (state) => state.importProducts.openDialog;

export default importProductsSlice.reducer;