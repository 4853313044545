import { ExclamationIcon } from "@heroicons/react/solid"
import { Tooltip } from "antd"
import { sortBy } from "lodash"
import { useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"

const ShippingMethodRow = ({
    className = "",
    carrierName,
    deliveryType,
    shippingType,
    country,
    supportsCOD,
    allowsExtendedZones,
    allowsPOBoxDropoff,
    compatible = null,
}) => {
    const { t } = useTranslation()
    const tooltipContainerRef = useRef(null)

    return (
        <tr ref={tooltipContainerRef}>
            <td>
                <div className={`text-sm whitespace-nowrap capitalize ${compatible === false && "opacity-50 line-through"}`}>{carrierName.toLowerCase()}</div>
            </td>
            <td>
                <div className={`pl-2 text-sm whitespace-nowrap ${compatible === false && "opacity-50 line-through"}`}>
                    {t(`shipping_methods.delivery_types.${deliveryType}`)}
                </div>
            </td>
            {compatible === false && (
                <td>
                    <div className="pl-2 text-sm whitespace-nowrap">
                        <Tooltip getPopupContainer={() => tooltipContainerRef?.current} title={t("shipping_methods.incompatible_shipping_type")}>
                            <div>
                                <ExclamationIcon className="w-5 h-5 opacity-50" />
                            </div>
                        </Tooltip>
                    </div>
                </td>
            )}
        </tr>
    )
}

const groupShippingMethodDeliverySorting = (groupShippingMethod) => {
    if (groupShippingMethod.shipping_method.delivery_type === "SameDay") {
        return 1
    } else if (groupShippingMethod.shipping_method.delivery_type === "NextDay") {
        return 2
    } else if (groupShippingMethod.shipping_method.delivery_type === "Ground") {
        return 3
    } else if (groupShippingMethod.shipping_method.delivery_type === "International") {
        return 4
    } else {
        return 5
    }
}

export const shippingGroupHasIncompatibleShippingMethods = (groupShippingMethods, integrationShippingMethodType) => {
    let incompatible = false
    for (const groupShippingMethod of groupShippingMethods) {
        if (!checkShippingMethodCompatibility(groupShippingMethod.shipping_method, integrationShippingMethodType)) {
            incompatible = true
            break
        }
    }
    return incompatible
}

export const checkShippingMethodCompatibility = (shippingMethod, integrationShippingMethodType) => {
    let compatible = false
    switch (integrationShippingMethodType) {
        case "national":
            if (shippingMethod.shipping_type === "national") compatible = true
            break
        case "international":
            if (shippingMethod.shipping_type === "international") compatible = true
            break
        case "cash_on_delivery":
            if (shippingMethod.is_cod) compatible = true
            break
    }
    return compatible
}

export const ShippingMethodTable = ({ groupShippingMethods, integrationShippingMethodType = null }) => {
    const { t } = useTranslation()

    const sortedGroupShippingMethods = useMemo(() => {
        // remove inactive shipping methods
        let validatedGroupShippingMethods = groupShippingMethods.filter( groupShippingMethod => groupShippingMethod?.shipping_method.is_active )
        
        // validate shipping methods and add property 'compatible'
        validatedGroupShippingMethods = validatedGroupShippingMethods.map((groupShippingMethod) => {
            return {
                ...groupShippingMethod,
                shipping_method: {
                    ...groupShippingMethod.shipping_method,
                    compatible: checkShippingMethodCompatibility(groupShippingMethod.shipping_method, integrationShippingMethodType),
                },
            }
        })

        // merge shipping methods by carrier and delivery, keeping best compatibility
        let mergedGroupShippingMethods = []
        for (const groupShippingMethod of validatedGroupShippingMethods) {
            let foundIndex = mergedGroupShippingMethods.findIndex(
                (gsm) =>
                    gsm.shipping_method.delivery_type === groupShippingMethod.shipping_method.delivery_type &&
                    gsm.shipping_method.carrier_name === groupShippingMethod.shipping_method.carrier_name
            )
            if (foundIndex === -1) {
                mergedGroupShippingMethods.push(groupShippingMethod)
            } else {
                if (!mergedGroupShippingMethods[foundIndex].shipping_method.compatible) {
                    mergedGroupShippingMethods[foundIndex].shipping_method.compatible = checkShippingMethodCompatibility(
                        groupShippingMethod.shipping_method,
                        integrationShippingMethodType
                    )
                }
            }
        }

        // sort by delivery speed
        let sortedGroupShippingMethods = sortBy(mergedGroupShippingMethods, [groupShippingMethodDeliverySorting])

        return sortedGroupShippingMethods
    }, [groupShippingMethods, integrationShippingMethodType])

    return (
        <table className="w-full">
            <thead>
                <tr>
                    <th className="w-0 text-left">
                        <div className="text-xs text-gray-400">{t("shipping_methods.carrier")}</div>
                    </th>
                    <th className="pl-2 w-0 text-left">
                        <div className="text-xs text-gray-400">{t("shipping_methods.delivery_type")}</div>
                    </th>
                    <th className="pl-2 w-0"></th>
                </tr>
            </thead>
            <tbody>
                {sortedGroupShippingMethods.map((groupShippingMethod) => (
                    <ShippingMethodRow
                        key={groupShippingMethod.id}
                        carrierName={groupShippingMethod.shipping_method.carrier_name}
                        country={groupShippingMethod.shipping_method.country}
                        deliveryType={groupShippingMethod.shipping_method.delivery_type}
                        shippingType={groupShippingMethod.shipping_method.shipping_type}
                        supportsCOD={groupShippingMethod.shipping_method.is_cod}
                        allowsExtendedZones={groupShippingMethod.shipping_method.extended_zone}
                        allowsPOBoxDropoff={groupShippingMethod.shipping_method.po_box_dropoff}
                        compatible={groupShippingMethod.shipping_method.compatible}
                    />
                ))}
            </tbody>
        </table>
    )
}
