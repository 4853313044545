import {Button} from "../../components";
import {useQuery} from "react-query";
import { SettingOutlined } from '@ant-design/icons';
import { Collapse, Select } from 'antd';
import {useState,useContext} from "react";
import { Loader } from "../../components/Loader";
import { getInvoices } from "../../services";
import {UserContext} from "../../hooks/UserContext";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/solid'

import {
    FolderOutlined,
    FolderOpenOutlined,
    PrinterOutlined,
    FileDoneOutlined,
    WarningOutlined,
    FileTextOutlined
  } from '@ant-design/icons';


const { Panel } = Collapse;
const { Option } = Select;

export const PrintInvoicesListView = ({
    title,
    replenishment,
    onCancel
}) => {
    const { t, i18n } = useTranslation(); 
    const { user } = useContext(UserContext)
    const [queryParams, setQueryParams] = useState({
        store_id: user.current_store?.id,
    })
    const [expandIconPosition, setExpandIconPosition] = useState('start');
    
    const {  data, status, isLoading, isError, isPreviousData, isFetching, error } = useQuery(['invoices', replenishment?.id], () => getInvoices(replenishment.id))

    const handleClickOnCancel = () => {
        if(onCancel){
            onCancel()
        }
    }
    const onPositionChange = (newExpandIconPosition) => {
        setExpandIconPosition(newExpandIconPosition);
      };
    
    const onChange = (key) => {
    console.log(key);
    };
    
    const genExtra = (index) => (
        index != 0 &&
            <p className="mb-0 text-yellow-500" ><b>{t("invoicing_list.adjustment")}</b></p>
      );



    return (
        <div className="">
            <div className="space-y-3 divide-y divide-gray-200">
            <div className="flex items-start justify-between ">
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
                </div>
                <button
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                        onCancel()
                    }}
                >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            
            <div>
            
                <div className="flow-root mt-6">
                    <ul className ="-my-5 divide-y divide-gray-200">
                    { <li className="py-4">
                            {t("invoicing_list.invoices_message")}
                    </li>}
                    
                    <Collapse
                    defaultActiveKey={['0']}
                    onChange={onChange}
                    expandIconPosition={expandIconPosition}
                    expandIcon={({ isActive }) => isActive ? <FolderOpenOutlined style={{ fontSize: '18px'}}/> : <FolderOutlined style={{ fontSize: '16px'}} />}
                    >
                    {!isPreviousData && isLoading ? (
                        <><Loader show={isLoading}></Loader></>
                    ) : isError ? (
                        <>Error: {error.message}</>
                    ) : !data.length? (
                        <><p className="text-center p-10">{i18n.t("invoicing_list.not_invoices")}</p></>
                    ) :(
                        
                          data.map((item, index)=>{
                              return (
                                <>
                                   
                                    <Panel header={item.purpose} key={index}  extra={genExtra(index)} >
                                        <p><b>{t("invoicing_list.operation_nature")}: </b>{item.operation_nature}</p>
                                        <p><b>{t("invoicing_list.key")}: </b>{item.key}</p>
                                        <p><b>{t("invoicing_list.operation")}: </b>
                                        
                                        {item.operation_type != "SAIDA" ?
                                            <div className="inline-block rounded-full px-3 text-sm font-semibold opacity-80 bg-red-500 text-white ml-1">
                                               {item.operation_type}
                                            </div>
                                            : 
                                            <div className="inline-block rounded-full px-3 text-sm font-semibold opacity-80 bg-yellow-500 text-white ml-1">
                                                {item.operation_type}
                                            </div>
                                        }
                                        </p>
                                    <li className="py-4 divide-y  divide-gray-200" key={index+"_label"}>
                                        <div className="flex items-center">
                                            <div className="flex-1 min-w-0 mx-2">
                                                <label className="text-sm font-semibold text-gray-900 truncate mb-2">
                                                <FileTextOutlined style={{ fontSize: '18px', color: '#0f2956'}} /> Danfe Etiqueta
                                                </label>
                                            </div>
                                            <div className="flex-1 min-w-0 mx-2 text-right">
                                            {item.label_pdf_file ? (

                                                <a href={process.env.REACT_APP_PLATFORM_URL+item.label_pdf_file.path} target="_blank"> {t("invoicing_list.print")} <PrinterOutlined /></a>
                                            ) :(
                                                <label className="text-yellow-500"> {t("invoicing_list.no_files")} <WarningOutlined style={{ fontSize: '18px', color: '#f7b13d'}}  /></label>   
                                            )}

                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-4 divide-y  divide-gray-200" key={index+"_pdf"}>
                                        <div className="flex items-center">
                                            <div className="flex-1 min-w-0 mx-2">
                                                <label className="text-sm font-semibold text-gray-900 truncate mb-2">
                                                <FileTextOutlined style={{ fontSize: '18px', color: '#0f2956'}} />  Danfe PDF
                                                </label>
                                            </div>
                                            <div className="flex-1 min-w-0 mx-2 text-right">
                                            {item.pdf_file ? (
                                                <a href={process.env.REACT_APP_PLATFORM_URL+item.pdf_file.path} target="_blank">{t("invoicing_list.print")} <PrinterOutlined /></a>
                                            ):
                                            (
                                                <label className="text-yellow-500"> {t("invoicing_list.no_files")} <WarningOutlined style={{ fontSize: '18px', color: '#f7b13d'}}  /></label>   

                                            )}

                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-4 divide-y  divide-gray-200" key={index+"_xml"}>
                                        <div className="flex items-center">
                                            <div className="flex-1 min-w-0 mx-2">
                                                <label className="text-sm font-semibold text-gray-900 truncate mb-2">
                                                <FileTextOutlined style={{ fontSize: '18px', color: '#0f2956'}} />    XML
                                                </label>
                                            </div>
                                            <div className="flex-1 min-w-0 mx-2 text-right">
                                            {item.xml_file ? (
                                                <a href={process.env.REACT_APP_PLATFORM_URL+item.xml_file.path} target="_blank">{t("invoicing_list.print")} <PrinterOutlined /></a>
                                            ):
                                            (
                                                <label className="text-yellow-500"> {t("invoicing_list.no_files")} <WarningOutlined style={{ fontSize: '18px', color: '#f7b13d'}}  /></label>   

                                            )}
                                            </div>
                                        </div>
                                    </li>
                                </Panel>
                                </>
                                  
                                  
                              
                              )
                          })
                          
                         

                    )}
                        </Collapse>
                       
                    </ul>

                </div>
            </div>
            </div>
        </div>
    )
}