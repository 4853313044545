import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import StatementCondition from "./StatementCondition";
import { CONDITION_TYPES, AVAILABLE_RULE_STATEMENTS, CONDITION_VALIDATION_TYPES } from "../../utils/businessRuleStatementUtils";
import AdditionalConditionSelector from "./AdditionalConditionSelector";
import ConditionTypeSelector from "../../components/ConditionTypeSelector/ConditionTypeSelector";

const StatementContent = ({selectedStatementOption, onUpdateSelectedStatementOption, availableAdditionalOptions, onUpdateConditionsAreValid, areConditionsValid, channelIntegrationsData}) => {
    const { t } = useTranslation()
    const [additionalConditionSelector, setAdditionalConditionSelector] = useState({show:false, conditionType: CONDITION_TYPES.AND})
    const [localConditions, setLocalConditions] = useState([...selectedStatementOption.conditions])

    const validateConditions = useCallback((conditionsToValidate) => {
        return conditionsToValidate.length > 0 && conditionsToValidate.every((condition) => condition.isCompleted && !condition.isEditing)
    }, [])

    const lastConditionType = useMemo(() => {
        let conditionType = CONDITION_TYPES.AND

        if(localConditions && localConditions.length > 0){
            conditionType = localConditions[localConditions.length - 1].conditionType
        }

        return conditionType
    },[localConditions])

    const hasForEveryProductCondition = useMemo(() => {
        return localConditions && localConditions.some(({validationType}) => validationType === CONDITION_VALIDATION_TYPES.FOR_EVERY_PRODUCT)
    }, [localConditions])

    useEffect(() => {
        setLocalConditions([...selectedStatementOption.conditions])
    }, [selectedStatementOption])

    useEffect(() => {
        const areNewConditionsValid = validateConditions(localConditions) && !additionalConditionSelector.show

        if(areNewConditionsValid !== areConditionsValid){
            onUpdateConditionsAreValid(areNewConditionsValid)
        }

    }, [localConditions, additionalConditionSelector, validateConditions, onUpdateConditionsAreValid, areConditionsValid])

    if(!selectedStatementOption.statement || !localConditions || localConditions.length <= 0){
        return <></>
    }

    const updateConditions = (newConditions) => {
        setLocalConditions([...newConditions])

        const areNewConditionsValid = validateConditions(newConditions)

        if(areNewConditionsValid || newConditions.length <= 0){
            onUpdateSelectedStatementOption({...selectedStatementOption, conditions: [...newConditions]})
        }

        if(areNewConditionsValid !== areConditionsValid){
            onUpdateConditionsAreValid(areNewConditionsValid)
        }
    }

    const handleOnDeleteStatementCondition = (conditionIndexToDelete) => {
        const newConditions = localConditions.filter((condition, conditionIndex) => conditionIndexToDelete !== conditionIndex)
        updateConditions(newConditions)
    }

    const handleOnEditStatementCondition = (conditionIndexToEdit) => {
        const newConditions = localConditions.map((condition, conditionIndex) => {
            return conditionIndex === conditionIndexToEdit ? {...condition, isEditing: true} : {...condition}
        })
        updateConditions(newConditions)
    }

    const handleOnSaveStatementCondition = (conditionIndexToUpdate, updatedCondition) => {
        const newConditions = localConditions.map((condition, conditionIndex) => {
            const templateStatementOption = conditionIndexToUpdate.isEditing ? AVAILABLE_RULE_STATEMENTS.CUSTOM_STATEMENT : conditionIndexToUpdate.templateStatementOption

            return conditionIndex === conditionIndexToUpdate ? {...updatedCondition, isCompleted: true, isEditing: false, templateStatementOption: templateStatementOption} : {...condition}
        })
        updateConditions(newConditions)

        window.analytics.track("Business Rules - Saves condition")
    }

    const handleOnCancelStatementCondition = (conditionIndexToRestore) => {
        const newConditions = localConditions.map((condition, conditionIndex) => {
            return conditionIndex === conditionIndexToRestore ? {...condition, isCompleted: true, isEditing: false} : {...condition}
        })
        updateConditions(newConditions)
    }

    const handleOnAddStatementCondition = (conditionToAdd) => {
        const newConditions = [...localConditions, ...conditionToAdd.conditions]
        setAdditionalConditionSelector({show: false, conditionType: CONDITION_TYPES.AND})
        updateConditions(newConditions)
    }

    const handleOnAddCondition = (conditionType) => {
        const isValidConditionType = Object.values(CONDITION_TYPES).some((validConditionType) => validConditionType === conditionType)

        if(isValidConditionType){
            setAdditionalConditionSelector({show: true, conditionType: conditionType})
        }

        switch(conditionType){
            case CONDITION_TYPES.AND:
                window.analytics.track("Business Rules - Compound condition - AND type selected")
                break

            case CONDITION_TYPES.OR:
                window.analytics.track("Business Rules - Compound condition - OR type selected")
                break

            default:
                break
        }
    }

    const handleOnDeleteAdditionalCondition = () => {
        setAdditionalConditionSelector({show: false, conditionType: CONDITION_TYPES.AND})
        updateConditions([...localConditions])
    }

    return (
        <div className="p-5">
            {localConditions.map((statementCondition, statementIndex) => (
                <div className="flex flex-col" key={`condition-${statementIndex}`}>
                    {statementIndex > 0 && (
                        <div className="font-semibold py-5">
                            {t(`settings.business_rule_creator.condition_types.${statementCondition.conditionType}.display_name`)}
                        </div>
                    )}

                    <div className="flex gap-5" key={`condition-${statementIndex}`}>
                        <StatementCondition
                            conditionContent={statementCondition}
                            editable={statementCondition.isEditable}
                            deletable={true}
                            onEdit={() => handleOnEditStatementCondition(statementIndex)}
                            onDelete={() => handleOnDeleteStatementCondition(statementIndex)}
                            onSave={(updatedCondition) => handleOnSaveStatementCondition(statementIndex, updatedCondition)}
                            channelIntegrationsData={channelIntegrationsData}
                            onCancel={() => handleOnCancelStatementCondition(statementIndex)}
                        />
                    </div>
                </div>
            ))}

            {additionalConditionSelector.show && (
                <div>
                    <div className="font-semibold py-5">
                        {t(`settings.business_rule_creator.condition_types.${additionalConditionSelector.conditionType}.display_name`)}
                    </div>

                    <div className="flex gap-5">
                        <div className="bg-gray-300 w-1 rounded"></div>

                        <AdditionalConditionSelector
                            conditionType={additionalConditionSelector.conditionType}
                            availableOptions={availableAdditionalOptions}
                            onDeleteCondition={handleOnDeleteAdditionalCondition}
                            onSelectStatementOption={handleOnAddStatementCondition}
                        />
                    </div>
                </div>
            )}

            {!additionalConditionSelector.show && areConditionsValid && (
                <div>
                    {(localConditions.length > 1 || (localConditions.length <= 1 && hasForEveryProductCondition)) && (
                        <button
                            className="my-5 border rounded-lg px-3 py-2 text-blue-700 hover:text-white hover:bg-blue-500 border-blue-700 hover:border-blue-500 font-semibold hover:shadow-blue-500 hover:shadow"
                            onClick={() => handleOnAddCondition(lastConditionType)}
                        >
                            {t(`settings.business_rule_creator.condition_types.${lastConditionType}.display_name`)}
                        </button>
                    )}

                    {(localConditions.length <= 1 && !hasForEveryProductCondition && (
                        <div className="mt-5">
                            <ConditionTypeSelector
                                onSelectConditionType={handleOnAddCondition}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default StatementContent