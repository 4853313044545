import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";

import { InputProductQuantity, ConditionProductsSelector } from "../../../components";
import { CUSTOM_STATEMENT_VALIDATIONS, CUSTOM_STATEMENT_QUANTITIES } from "../../../utils/businessRuleStatementUtils";

const CustomCondition = ({conditionContent, onUpdateConditionContent, onAllSkuExists}) => {
    const { t } = useTranslation()
    const [allSkuExists, setAllSkuExists] = useState(true)

    const isExclusiveOptionAvailable = useMemo(() => {
        return conditionContent.products && conditionContent.products.length > 0 && !conditionContent.anyProduct
    }, [conditionContent])

    const validationOptionErrorMessage = useMemo(() => {
        return conditionContent.selectedValidationOption !== "" ? "" : t("settings.business_rule_creator.business_rule_statements.custom.error_messages.unselected_validation_option")
    }, [conditionContent, t])

    const productsErrorMessage = useMemo(() => {
        return (!conditionContent.products || conditionContent.products.length <= 0) && !conditionContent.anyProduct ? t("settings.business_rule_creator.business_rule_statements.custom.error_messages.missing_products") : ""
    }, [conditionContent, t])

    const quantityErrorMessage = useMemo(() => {
        let errorMessage = ""

        switch(conditionContent.selectedQuantityOption){
            case CUSTOM_STATEMENT_QUANTITIES.MINIMUM:
                errorMessage = Number(conditionContent.minQuantity) > 0 ? "" : t("settings.business_rule_creator.business_rule_statements.custom.error_messages.missing_quantity")
                break

            case CUSTOM_STATEMENT_QUANTITIES.MAXIMUM:
                errorMessage = Number(conditionContent.maxQuantity) > 0 ? "" : t("settings.business_rule_creator.business_rule_statements.custom.error_messages.missing_quantity")
                break

            case CUSTOM_STATEMENT_QUANTITIES.MINIMUM_AND_MAXIMUM:
                {
                    if(Number(conditionContent.minQuantity) <= 0 || Number(conditionContent.maxQuantity) <= 0 || !conditionContent.minQuantity || !conditionContent.maxQuantity){
                        errorMessage = t("settings.business_rule_creator.business_rule_statements.custom.error_messages.missing_quantity")
                    }
                    else if(Number(conditionContent.minQuantity) >= Number(conditionContent.maxQuantity)){
                        errorMessage = t("settings.business_rule_creator.business_rule_statements.custom.error_messages.invalid_range_quantity")
                    }

                    break
                }

            case CUSTOM_STATEMENT_QUANTITIES.EXACTLY:
                errorMessage = Number(conditionContent.exactQuantity) > 0 ? "" : t("settings.business_rule_creator.business_rule_statements.custom.error_messages.missing_quantity")
                break

            default:
                errorMessage = t("settings.business_rule_creator.business_rule_statements.custom.error_messages.unselected_quantity_option")
                break
        }

        return errorMessage
    }, [conditionContent, t])

    const handleOnChangeExclusive = () => {
        const newExclusiveValue = !conditionContent.exclusive
        const newAnyProductValue = newExclusiveValue ? false : conditionContent.anyProduct
        onUpdateConditionContent({...conditionContent, exclusive: newExclusiveValue, anyProduct: newAnyProductValue})

        window.analytics.track("Business Rules - Condition creation - Clicks And Any Other Product button")
    }

    const handleOnChangeAnyProduct = () => {
        const newAnyProductValue = !conditionContent.anyProduct
        const newExclusiveValue = newAnyProductValue ? false : conditionContent.exclusive
        const newProducts = newAnyProductValue ? null : conditionContent.products

        onUpdateConditionContent({...conditionContent, anyProduct: newAnyProductValue, exclusive: newExclusiveValue, products: newProducts})
    }

    const handleOnAllSkuExists = (newAllSkuExists) => {
        setAllSkuExists(newAllSkuExists)
        onAllSkuExists(newAllSkuExists)
    }

    return (
        <div>
            <div className={`p-5 border flex flex-col gap-2 ${validationOptionErrorMessage !== "" ? "border-red-300" : ""}`}>
                <div className="flex gap-2">
                    <div className="font-semibold">
                        {t("settings.business_rule_creator.business_rule_statements.custom.title_validations")}
                        <span className="text-red-500">{" *"}</span>
                    </div>

                    {validationOptionErrorMessage === "" ? (
                        <CheckCircleIcon className="h-6 text-green-600" />
                    ) : (
                        <ExclamationCircleIcon className="h-6 text-red-600" />
                    )}
                </div>

                <div className="flex">
                    <select
                        value={conditionContent.selectedValidationOption || ""}
                        className="border-gray-300 w-3/4"
                        onChange={(event) => onUpdateConditionContent({...conditionContent, selectedValidationOption: event.target.value})}
                    >
                        <option value={""} disabled hidden>{""}</option>

                        {Object.values(CUSTOM_STATEMENT_VALIDATIONS).map((statementValidation) => (
                            <option value={statementValidation} key={statementValidation}>
                                {t(`settings.business_rule_creator.business_rule_statements.custom.validations.${statementValidation}`)}
                            </option>
                        ))}
                    </select>
                </div>

                {validationOptionErrorMessage !== "" && (
                    <div className="text-red-500">
                        {validationOptionErrorMessage}
                    </div>
                )}
            </div>

            <div className={`p-5 border flex flex-col gap-2 ? ${quantityErrorMessage !== "" ? "border-red-300" : ""}`}>
                <div className="flex gap-2">
                    <div className="font-semibold">
                        {t("settings.business_rule_creator.business_rule_statements.custom.title_quantities")}
                        <span className="text-red-500">{" *"}</span>
                    </div>

                    {quantityErrorMessage === "" ? (
                        <CheckCircleIcon className="h-6 text-green-600" />
                    ) : (
                        <ExclamationCircleIcon className="h-6 text-red-600" />
                    )}
                </div>

                <div className="flex gap-2">
                    <select
                        value={conditionContent.selectedQuantityOption || ""}
                        className="border-gray-300 w-fit"
                        onChange={(event) => onUpdateConditionContent({...conditionContent, selectedQuantityOption: event.target.value})}
                    >
                        <option value={""} disabled hidden>{""}</option>

                        {Object.values(CUSTOM_STATEMENT_QUANTITIES).map((statementQuantity) => (
                            <option value={statementQuantity} key={statementQuantity}>
                                {t(`settings.business_rule_creator.business_rule_statements.custom.quantities.${statementQuantity}`)}
                            </option>
                        ))}
                    </select>

                    {(conditionContent.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MINIMUM ||
                        conditionContent.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MINIMUM_AND_MAXIMUM) && (
                            <InputProductQuantity
                                quantity={conditionContent.minQuantity}
                                onQuantityChange={(newMinQuantity) => onUpdateConditionContent({...conditionContent, minQuantity: newMinQuantity})}
                                isInvalid={conditionContent.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MINIMUM_AND_MAXIMUM && Number(conditionContent.maxQuantity) <= Number(conditionContent.minQuantity)}
                            />
                        )
                    }

                    {conditionContent.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MINIMUM_AND_MAXIMUM && (
                        <div className="mx-2 flex items-center">
                            {t("settings.business_rule_creator.business_rule_statements.custom.from_quantity_to_quantity")}
                        </div>
                    )}

                    {(conditionContent.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MAXIMUM ||
                        conditionContent.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MINIMUM_AND_MAXIMUM) && (
                            <InputProductQuantity
                                quantity={conditionContent.maxQuantity}
                                onQuantityChange={(newMaxQuantity) => onUpdateConditionContent({...conditionContent, maxQuantity: newMaxQuantity})}
                                isInvalid={conditionContent.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MINIMUM_AND_MAXIMUM && Number(conditionContent.maxQuantity) <= Number(conditionContent.minQuantity)}
                            />
                        )
                    }

                    {conditionContent.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.EXACTLY && (
                        <InputProductQuantity
                            quantity={conditionContent.exactQuantity}
                            onQuantityChange={(newExactQuantity) => onUpdateConditionContent({...conditionContent, exactQuantity: newExactQuantity})}
                        />
                    )}
                </div>

                {quantityErrorMessage !== "" && (
                    <div className="text-red-500">
                        {quantityErrorMessage}
                    </div>
                )}
            </div>

            <div className={`p-5 border flex flex-col gap-2 ${productsErrorMessage !== "" || !allSkuExists ? "border-red-300" : ""}`}>
                <div className="flex gap-2">
                    <div className="font-semibold">
                        {t("settings.business_rule_creator.business_rule_statements.custom.title_sku")}
                        <span className="text-red-500">{" *"}</span>
                    </div>

                    {productsErrorMessage === "" && allSkuExists ? (
                        <CheckCircleIcon className="h-6 text-green-600" />
                    ) : (
                        <ExclamationCircleIcon className="h-6 text-red-600" />
                    )}
                </div>

                <div className="flex">
                    <div className="w-10/12">
                        <ConditionProductsSelector
                            products={conditionContent.products}
                            anyProduct={conditionContent.anyProduct}
                            onUpdateProducts={(newProducts) => onUpdateConditionContent({...conditionContent, products: newProducts})}
                            onUpdateAnyProduct={handleOnChangeAnyProduct}
                            onAllSkuExists={handleOnAllSkuExists}
                            addProductButtonText={t("settings.business_rule_creator.business_rule_statements.custom.button_add_product")}
                            anyProductText={t("settings.business_rule_creator.business_rule_statements.custom.any_product")}
                            singleSkuErrorText={t("settings.business_rule_creator.business_rule_statements.custom.single_sku_error")}
                            multipleSkuErrorText={t("settings.business_rule_creator.business_rule_statements.custom.multiple_sku_error")}
                            anyProductPlaceholder={t("settings.business_rule_creator.business_rule_statements.custom.any_product_placeholder")}
                            notAnyProductPlaceholder={t("settings.business_rule_creator.business_rule_statements.custom.not_any_product_placeholder")}
                        />
                    </div>
                </div>

                {!conditionContent.anyProduct && (
                    <label className={`flex gap-2 items-center pr-2 ${!isExclusiveOptionAvailable ? "cursor-not-allowed" : ""}`}>
                        <input
                            id="exclusive-option"
                            type="checkbox"
                            className={`border-gray-300 focus:ring-0 ${!isExclusiveOptionAvailable  ? "cursor-not-allowed" : ""}`}
                            checked={conditionContent.exclusive}
                            onChange={handleOnChangeExclusive}
                            disabled={!isExclusiveOptionAvailable}
                        />

                        <span
                            htmlFor="exclusive-option"
                            className={`shrink-0 text-sm ${!isExclusiveOptionAvailable ? "text-gray-400" : ""}`}
                        >
                            {t("settings.business_rule_creator.business_rule_statements.custom.exclusive")}
                        </span>
                    </label>
                )}

                {productsErrorMessage !== "" && (
                    <div className="text-red-500 mt-2">
                        {productsErrorMessage}
                    </div>
                )}
            </div>
        </div>
    )
}

export default CustomCondition