import { useState } from "react";
import noImage from "../../../../assets/no-image.png";

const ProductImage = ({relativeThumbnailImagePath, relativeFullScreenImagePath}) => {
    const [imageNotFound, setImageNotFound] = useState(false)

    const buildImageFullPath = (relativeImagePath) => {
        return relativeImagePath && relativeImagePath !== "" ? (process.env.REACT_APP_PLATFORM_URL + relativeImagePath) : ""
    }

    return (
        <div className={`flex justify-center w-10 h-10 ${imageNotFound ? "cursor-default" : "cursor-pointer"}`}>
            {imageNotFound && (
                <img
                    src={noImage}
                    alt="No product preview"
                />
            )}

            {!imageNotFound && (
                <img
                    src={buildImageFullPath(relativeThumbnailImagePath)}
                    alt="Product preview"
                    onError={() => setImageNotFound(true)}
                    onClick={() => window.open(buildImageFullPath(relativeFullScreenImagePath), "_blank")}
                />
            )}
        </div>
    )
}

export default ProductImage