import { ALL_AVAILABLE_ACTION_OPTIONS } from "../../utils/businessRuleActionUtils";
import { CONDITION_VALIDATION_TYPES } from "../../utils/businessRuleStatementUtils";

const ruleActionParametersBuilder = (actionSettings, statementSettings, buildForPreview=false) => {
    let actionParameters = null

    switch (actionSettings.actionOption) {

        case ALL_AVAILABLE_ACTION_OPTIONS.PAUSE_ORDER_OPTION:
        case ALL_AVAILABLE_ACTION_OPTIONS.CANCEL_ORDER_OPTION:
            {
                actionParameters = {}
                break
            }

        case ALL_AVAILABLE_ACTION_OPTIONS.ADD_PRODUCT_OPTION:
        case ALL_AVAILABLE_ACTION_OPTIONS.ADD_INSERT_OPTION:
        case ALL_AVAILABLE_ACTION_OPTIONS.ADD_SHIPPING_PACKAGE_OPTION:
            {
                const forEveryProductCondition = statementSettings.conditions.find(({validationType}) => validationType === CONDITION_VALIDATION_TYPES.FOR_EVERY_PRODUCT)

                if(forEveryProductCondition){
                    actionParameters = actionSettings.productsToInsert.map((product) => {
                        const productToInsert = {
                            product_id: product.id,
                            quantity_multiplier: product.quantity,
                            for_every_quantity: forEveryProductCondition.for_every_quantity
                        }

                        if(!forEveryProductCondition.withoutMaxMultipliedQuantity){
                            productToInsert.max_multiplied_quantity = forEveryProductCondition.maxMultipliedQuantity
                        }

                        if(buildForPreview){
                            productToInsert.product_name = product.name
                            productToInsert.product_image = product.product_image_path ? {...product.product_image_path} : null
                            productToInsert.sku = product.product_codes[0].sku

                            productToInsert.for_every_product_id = forEveryProductCondition.anyProduct ? [] : forEveryProductCondition.forEveryProduct.map((product) => ({
                                for_every_product_id: product.id,
                                sku: product.product_codes[0].sku
                            }))
                        } else {
                            productToInsert.for_every_product_id = forEveryProductCondition.anyProduct ? null : forEveryProductCondition.forEveryProduct.map((product) => product.id)
                        }

                        return productToInsert
                    })
                }
                else if(actionSettings.productsToInsert && actionSettings.productsToInsert.length > 0){
                    actionParameters = actionSettings.productsToInsert.map((product) => {
                        const productToInsert = {
                            product_id: product.id,
                            quantity: product.quantity
                        }

                        if(buildForPreview){
                            productToInsert.product_name = product.name
                            productToInsert.product_image = product.product_image_path ? {...product.product_image_path} : null
                            productToInsert.sku = product.product_codes[0].sku
                        }

                        return productToInsert
                    })
                }
                break
            }

        default:
            break
    }

    if(!actionParameters){
        throw new Error()
    }

    if(!buildForPreview){
        actionParameters = JSON.stringify(actionParameters)
    }

    return actionParameters
}

export default ruleActionParametersBuilder