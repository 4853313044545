export const RETURN_TYPES = [
    {
        value: "SIMPLE_RETURN",
        label: "Devolución",
        help: "Esperaremos esta devolución en nuestros almacenes.",
    },
    {
        value: "EXCHANGE",
        label: "Cambio",
        help: "Los productos de reemplazo disponibles serán apartados. En cuanto recibamos la devolución, se generará el pedido correspondiente.",
        disabled: false,
    },
    {
        value: "IMMEDIATE_EXCHANGE",
        label: "Cambio Inmediato",
        help: "Generaremos un pedido inmediatamente con los productos de reemplazo.",
        disabled: false,
    },
]

export const GENERATE_LABEL_OPTIONS = [
    { label: "Sí", value: true },
    { label: "No", value: false },
]

export const countProductItems = products => products.reduce((total, product) => total + product.quantity, 0)

export const addProductsTo = function (productsTargetArray, productsToAdd, includeProductKitsChildren = true) {
    // Increase product quantities as needed.
    productsToAdd.forEach((productToAdd) => {
        if (productToAdd.is_kit && productToAdd.kit_childs && includeProductKitsChildren) {
            productToAdd.kit_childs.forEach((kit_child) => {
                productsTargetArray = increaseProductQuantity(productsTargetArray, kit_child.child_product, kit_child.quantity);
            })
        } else {
            productsTargetArray = increaseProductQuantity(productsTargetArray, productToAdd);
        }
    })

    return productsTargetArray
}

const increaseProductQuantity = function (productsTargetArray, productToAdd, quantity = 1) {
    const productToReturn = productsTargetArray.find(
        (p) => !p.damaged && p.product_id === productToAdd.id
    )

    if (!productToReturn) {
        productsTargetArray.push(buildProductData(productToAdd, quantity))
    } else {
        productToReturn.quantity += quantity
    }

    return productsTargetArray
}

export const buildProductData = (product, quantity, quantityMultiplier = 1) => {
    return {
        product_id: product.id,
        quantity: quantityMultiplier * quantity,
        label: product.name,
        sku: product.product_codes.length > 0 ? product.product_codes[0].sku : null,
        upc: product.bar_code,
        damaged: false,
    }
}

const getExpectedProductQuantity = (target_product_id, expected_products) => {
    return expected_products.find((expectedProduct) => expectedProduct.product_id === target_product_id)?.quantity ?? 0
}

export const buildProductsData = (expected_products, orderData) => {
    let orderProducts = [];

    orderData.order_lines.forEach((order_line) => {
        const expectedProductQuantity = getExpectedProductQuantity(order_line.product.id, expected_products);

        if (order_line.product.is_kit && order_line.product.kit_childs) {
            order_line.product.kit_childs.forEach((kit_child) => {
                orderProducts = increaseProductQuantity(orderProducts, kit_child.child_product, expectedProductQuantity * kit_child.quantity);
            })
        } else {
            orderProducts = increaseProductQuantity(orderProducts, order_line.product, expectedProductQuantity);
        }
    })

    return orderProducts
}

export const getReturnTypeDefinition = (type = "") => RETURN_TYPES.find(returnType => returnType.value === type);

export const getGenerateLabelHelpText = (generate_guide, warehouse_country) => {
    if (generate_guide == null)
        return null

    if (generate_guide)
        return (
            <>
                Se generará una guía de retorno usando {warehouse_country === 'CO' ? <b> Servientrega.</b> : <b> Estafeta.</b>}
            </>
        )
    else
        return 'Se proveerán las instrucciones para que el cliente pueda mandar la devolución por su cuenta.';
}

export const returnNeedsReview = (_return) => _return.externally_requested
    && _return.return_type === 'TO_BE_DETERMINED' && _return.status !== "CANCELLED"
