import { useTranslation } from "react-i18next"

export const StoreBusinessRuleAction = ({ rule_action_parameters }) => {
    const { t } = useTranslation()
    const SkuPill = ({ sku }) => (
        <div className={"px-2.5 py-0.5 mr-1.5 mb-1.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"}>{sku ? sku : t("settings.store_business_container.all_skus")}</div>
    )

    const buildPillList = function (sku, index) {
        if (sku === Number.MIN_SAFE_INTEGER) return <span key={index} className="italic ml-1">{t('settings.store_business_container.deleted_products')}</span>
        else return <SkuPill key={index} sku={sku} />
    }

    const parametersArray = Array.isArray(rule_action_parameters) ? rule_action_parameters : [rule_action_parameters]
    let forEveryQuantity = null
    const skuSet = new Set()

    parametersArray.forEach((rule_action_parameter, index) => {
        const forEveryProductArray = Array.isArray(rule_action_parameter?.for_every_product_id) ? rule_action_parameter?.for_every_product_id : [rule_action_parameter?.for_every_product_id]
        if (!forEveryProductArray.length) {
            skuSet.add(null)
        }

        forEveryProductArray.forEach((everyProductIdElement, index) => {
            if (everyProductIdElement && 'for_every_product_id' in everyProductIdElement && everyProductIdElement?.sku === null) {
                skuSet.add(Number.MIN_SAFE_INTEGER)
            } else if (everyProductIdElement && 'for_every_product_id' in everyProductIdElement) {
                const sku = everyProductIdElement?.sku
                skuSet.add(sku)
            }
        })

        forEveryQuantity = rule_action_parameter?.for_every_quantity
    })

    const skus = Array.from(skuSet)

    return (
        <div className={`border-l-2 border-[#979797] ml-3 px-3 mt-1 `}>
            <div>
                <div className="flex flex-wrap space-x-2 space-y-2 ml-1">
                    <div className="flex flex-wrap items-start h-full">
                        <p className="text-gray-700 text-sm font-normal mt-2.5">{t("settings.store_business_container.includes")} {forEveryQuantity ? forEveryQuantity : 1}</p>
                        <p className="text-gray-700 text-sm font-normal ml-1 mt-2.5">{t("settings.store_business_container.some_of_this")}</p>
                        <div className="ml-2">
                            <div className="flex flex-row flex-wrap bg-gray-100 rounded-md p-2 pb-1 shadow-sm block max-w-xl sm:text-sm">
                                {skus.map((sku, index) => (
                                    buildPillList(sku, index)
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}