import {
    ADD_MELI_INTEGRATION,
    ADD_LIVERPOOL_INTEGRATION,
    ADD_BLING_INTEGRATION,
    ADD_AMAZON_INTEGRATION,
    ADD_COPPEL_INTEGRATION,
    ADD_F1COMMERCE_INTEGRATION,
    ADD_T1PAGINAS_INTEGRATION,
    ADD_SHOPIFY_INTEGRATION,
    ADD_SHOPIFY_PUBLIC_INTEGRATION,
    ADD_VTEX_INTEGRATION,
    ADD_VENTIAPP_INTEGRATION,
    ADD_TIENDANUBE_INTEGRATION,
    ADD_WALMART_INTEGRATION,
    ADD_WOOCOMMERCE_INTEGRATION,
    ADD_TRAYCORP_INTEGRATION,
    ADD_TRAY_INTEGRATION,
    ADD_VNDA_INTEGRATION,
    ADD_STRIPE_INTEGRATION,
    ADD_SHOPEE_INTEGRATION,
    ADD_SHEIN_INTEGRATION,
    SHIPPING_METHODS,
    AVAILABLE_INTEGRATIONS,
    IMPORT_SHOPIFY_PRODUCTS,
    IMPORT_VTEX_PRODUCTS,
    INTEGRATIONS,
    INVENTORY,
    INVOICES,
    REPLENISHMENTS,
    ORDERS,
    PRODUCT_BULK_CREATION_TRACKER,
    CREATE_ORDER_BULK_TRACKER,
    STORES,
    RETURNS,
    DASHBOARD,
    CLAIMS,
    STORE_DETAIL,
    STORE_PATH,
    SHOPIFY_ONBOARDING,
    SETTINGS,
    CREATE_BUSINESS_RULE,
    STORE_LATEST_STATEMENT_FILE,
    ADD_WORK_ORDER,
    WORK_ORDERS,
    BILLING
} from "./constants"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import {
    ListActiveIntegrations,
    MeliIntegration,
    LiverpoolIntegration,
    CoppelIntegration,
    ShopifyIntegration,
    ShopifyPublicIntegration,
    VtexIntegration,
    VentiappIntegration,
    WalmartIntegration,
    ShowAvailableIntegrations,
    WoocommerceIntegration,
    TiendanubeIntegration,
    BlingIntegration,
    AmazonIntegration,
    F1commerceIntegration,
    T1paginasIntegration,
    TraycorpIntegration
} from "../pages/integrations"
import { InvoicesContainer } from "../pages/Invoices";
import { ListOrderContainer, ShowCreateOrderBulkTracker } from "../pages/Orders"
import { Home } from "../pages/Home"
import { ListReplenishmentsContainer } from "../pages/Replenishments"
import { ListProductsContainer } from "../pages/Inventory"
import { ListShopifyProducts } from "../pages/integrations/ListShopifyProducts"
import { ListVtexProducts } from "../pages/integrations/ListVtexProducts"
import { ListStoresContainer } from "../pages/Stores"
import { ListClaimsContainer } from "../pages/Claims/ListClaimsContainer"
import { ListReturnContainer } from "../pages/Returns/ListReturnContainer"
import { StoreSettingsConfig } from "../pages/StoreSettings/StoreSettingsConfig"
import StoreRoute from "./StoreRoute"
import { UserContext } from "../hooks/UserContext"
import { useContext, useEffect, useState } from "react"
import { setUserPreferences } from "../services/userService"
import { ViewShopifyOnboarding } from "../pages/Onboardings"
import { AssignShippingGroups } from "../pages/integrations/AssignShippingGroups"
import ProductBulkCreationTracker from "../pages/Orders/ProductBulkCreationTracker";
import { BusinessRuleCreatorContainer } from "../pages/BusinessRuleCreator";
import { VndaIntegration } from "../pages/integrations/VndaIntegration";
import { StripeIntegration } from "../pages/integrations/StripeIntegration";
import { ShopeeIntegration } from "../pages/integrations/ShopeeIntegration";
import { TrayIntegration } from "../pages/integrations/TrayIntegration";
import { SheinIntegration } from "../pages/integrations/SheinIntegration";

import LatestStatementFileDownload from "../pages/Debug/LatestStatementFileDownload";
import {   } from "../pages/WorkOrders";
import { WorkOrderCreatorContainer } from "../pages/WorkOrders/WorkOrderCreatorContainer";
import { ListWorkOrdersContainer } from "../pages/WorkOrders/ListWorkOrdersContainer";
import StoreBilling from "../pages/Billing/StoreBilling"

export const StoreRouter = () => {
    const { user } = useContext(UserContext)

    return (
        <Switch>
            {/* on store root, show store list */}
            <Route exact path={[STORES, STORE_DETAIL]}>
                <ListStoresContainer />
            </Route>

            <StoreRoute path={STORE_PATH + DASHBOARD}>
                <Home />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + CREATE_ORDER_BULK_TRACKER}>
                <ShowCreateOrderBulkTracker />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + PRODUCT_BULK_CREATION_TRACKER}>
                <ProductBulkCreationTracker />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + ORDERS}>
                <ListOrderContainer />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + AVAILABLE_INTEGRATIONS}>
                <ShowAvailableIntegrations />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + IMPORT_SHOPIFY_PRODUCTS}>
                <ListShopifyProducts />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + IMPORT_VTEX_PRODUCTS}>
                <ListVtexProducts />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + SHIPPING_METHODS}>
                <AssignShippingGroups />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + ADD_MELI_INTEGRATION}>
                <MeliIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_LIVERPOOL_INTEGRATION}>
                <LiverpoolIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_COPPEL_INTEGRATION}>
                <CoppelIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_SHOPIFY_INTEGRATION}>
                <ShopifyIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_SHOPIFY_PUBLIC_INTEGRATION}>
                <ShopifyPublicIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_VTEX_INTEGRATION}>
                <VtexIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_VENTIAPP_INTEGRATION}>
                <VentiappIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_WALMART_INTEGRATION}>
                <WalmartIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_WOOCOMMERCE_INTEGRATION}>
                <WoocommerceIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_TIENDANUBE_INTEGRATION}>
                <TiendanubeIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_BLING_INTEGRATION}>
                <BlingIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_AMAZON_INTEGRATION}>
                <AmazonIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_TRAYCORP_INTEGRATION}>
                <TraycorpIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_TRAY_INTEGRATION}>
                <TrayIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_VNDA_INTEGRATION}>
                <VndaIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_STRIPE_INTEGRATION}>
                <StripeIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_SHOPEE_INTEGRATION}>
                <ShopeeIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_SHEIN_INTEGRATION}>
                <SheinIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_F1COMMERCE_INTEGRATION}>
                <F1commerceIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + ADD_T1PAGINAS_INTEGRATION}>
                <T1paginasIntegration />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + INTEGRATIONS}>
                <ListActiveIntegrations />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + CLAIMS}>
                <ListClaimsContainer />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + RETURNS}>
                <ListReturnContainer />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + CREATE_BUSINESS_RULE}>
                <BusinessRuleCreatorContainer />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + BILLING}>
                <StoreBilling />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + SETTINGS}>
                <StoreSettingsConfig />
            </StoreRoute>
            <StoreRoute path={STORE_PATH + INVENTORY}>
                <ListProductsContainer />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + INVOICES}>
                <InvoicesContainer />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + REPLENISHMENTS}>
                <ListReplenishmentsContainer />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + ADD_WORK_ORDER}>
                <WorkOrderCreatorContainer />
            </StoreRoute>

            <StoreRoute path={STORE_PATH + WORK_ORDERS}>
                <ListWorkOrdersContainer />
            </StoreRoute>

            <StoreRoute path={STORE_LATEST_STATEMENT_FILE}>
                <LatestStatementFileDownload />
            </StoreRoute>

            {/* default to dashboard */}
            <StoreRoute>
                <RedirectToStorePath path={DASHBOARD} />
            </StoreRoute>
        </Switch>
    )
}

export const RedirectToStorePath = ({ path }) => {
    const match = useRouteMatch({ path: STORE_PATH })
    const pathStoreId = parseInt(match.params.storeId)
    if (isNaN(pathStoreId)) {
        return <Redirect to={STORES} />
    }
    return <Redirect to={STORE_PATH.replace(":storeId", pathStoreId) + path} />
}

export const RedirectToPreferredStore = () => {
    const { user } = useContext(UserContext)
    const setUserDefaultStore = async (storeId) => {
        await setUserPreferences(user.id, { default_store_id: storeId })
    }
    const default_store = user.default_store
    if (default_store) {
        return <Redirect to={STORE_PATH.replace(":storeId", user.default_store.id)} />
    }
    if (user.accounts[0]?.stores[0]) {
        setUserDefaultStore(user.accounts[0].stores[0].id)
        return <Redirect to={STORE_PATH.replace(":storeId", user.accounts[0].stores[0].id)} />
    }
    return <Redirect to={STORES} />
}
