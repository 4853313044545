const BoxIcon = () => {
    return (
        <svg width="106" height="106" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5">
            <path
                d="M19.6087 19.2146L49.9113 5L88.2278 12.3133M19.6087 19.2146L60.3461 29.824M19.6087 19.2146L5 42.2876L18.607 46.5521M88.2278 12.3133L60.3461 29.824M88.2278 12.3133L101 35.4893L91.9844 41.3395M60.3461 29.824L46.7391 55.3691L18.607 46.5521M60.3461 29.824L61.0974 101M60.3461 29.824L70.3635 55.3691L91.9844 41.3395M18.607 46.5521V84.7253L61.0974 101M61.0974 101L91.9844 78.9571V41.3395"
                stroke="black"
                stroke-width="6"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default BoxIcon
