export const LABELING_PLASTISEAL = "LABELING_PLASTISEAL"
export const PRODUCT_VALIDATION = "PRODUCT_VALIDATION"
export const KIT_ASSEMBLY_SHRINKWRAP = "KIT_ASSEMBLY_SHRINKWRAP"
export const PACKAGING = "PACKAGING"
export const PRODUCT_SEGREGATION = "PRODUCT_SEGREGATION"
export const ALL_INVENTORY_AT_WAREHOUSE = "ALL_INVENTORY_AT_WAREHOUSE"
export const MATERIAL_IN_TRANSIT_TO_WAREHOUSE = "MATERIAL_IN_TRANSIT_TO_WAREHOUSE"
export const PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE = "PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE"
export const REQUESTED = "REQUESTED"
export const IN_VALIDATION = "IN_VALIDATION"
export const SCHEDULED = "SCHEDULED"
export const IN_PROGRESS = "IN_PROGRESS"
export const COMPLETED = "COMPLETED"
export const REJECTED = "REJECTED"

export const WORK_ORDER_TYPES = [
    LABELING_PLASTISEAL,
    PRODUCT_VALIDATION,
    KIT_ASSEMBLY_SHRINKWRAP,
    PACKAGING,
    PRODUCT_SEGREGATION
]

export const WORK_ORDER_STATUSES = [
    REQUESTED,
    IN_VALIDATION,
    SCHEDULED,
    IN_PROGRESS,
    COMPLETED,
    REJECTED
]

export const WORK_ORDER_PRODUCT_ORIGIN = [
    ALL_INVENTORY_AT_WAREHOUSE,
    MATERIAL_IN_TRANSIT_TO_WAREHOUSE,
    PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE
]

export const REQUIRED_MATERIAL = [
    ALL_INVENTORY_AT_WAREHOUSE,
    MATERIAL_IN_TRANSIT_TO_WAREHOUSE,
    PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE
]

export const CARD_STEPS = {
    INCOMPLETE: 0,
    EDITING: 1,
    HIDDEN: 2,
    COMPLETED: 3,
    IN_REVIEW: 4
}

export const WORK_ORDER_STATUSES_HIGHLIGHTED_STYLES = [
    {
        status: REQUESTED,
        label: `work_orders.work_order_statuses.${REQUESTED}.title`,
        classes: "bg-yellow-100 text-yellow-400",
    },
    {
        status: IN_VALIDATION,
        label: `work_orders.work_order_statuses.${IN_VALIDATION}.title`,
        classes: "bg-gray-100 text-gray-500",
    },
    {
        status: SCHEDULED,
        label: `work_orders.work_order_statuses.${SCHEDULED}.title`,
        classes: "bg-pink-100 text-pink-500",
    },
    {
        status: IN_PROGRESS,
        label: `work_orders.work_order_statuses.${IN_PROGRESS}.title`,
        classes: "bg-purple-100 text-purple-500"
    },
    {
        status: COMPLETED,
        label: `work_orders.work_order_statuses.${COMPLETED}.title`,
        classes: "bg-green-100 text-green-500",
    },
    {
        status: REJECTED,
        label: `work_orders.work_order_statuses.${REJECTED}.title`,
        classes: "bg-red-100 text-red-400",
    }
]

export const DEFAULT_QUERY_STALE_TIME = 1000 * 60 // 1 minute
