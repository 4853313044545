export const LabelNumberIcon = ({ className = null }) => {
    return (
        <svg viewBox="0 0 79 116" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M18.3684 81.5714H33.7368M56.7895 81.5714H60.6316M18.3684 65.8571H33.7368M18.3684 50.1429H33.7368M18.3684 30.5H45.2632M56.7895 30.5H60.6316M56.7895 50.1429H60.6316M56.7895 65.8571H60.6316M3 109.071V6.92857L6.84211 10.8571L14.5263 3L22.2105 10.8571L29.8947 3L37.5789 10.8571L45.2632 3L52.9474 10.8571L60.6316 3L68.3158 10.8571L76 3V109.071L68.3158 101.214L56.7895 113L49.1053 105.143L41.4211 113L33.7368 105.143L26.0526 113L18.3684 105.143L10.6842 113L3 109.071Z"
                stroke="currentColor"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
