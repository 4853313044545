import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { UserContext } from "../../hooks/UserContext";
import { useContext, useState, useMemo } from 'react';
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
    INVOICE_CNABS_SHOW,
    STORE_PATH
} from "../../navigation/constants";
import {
    FormattedDate,
    NewTable
} from "../../components";
import { Loader } from '../../components/Loader';
import { setOrdersListPageSize } from "../../redux/pageSizeSlice";
import { fetchCnabs } from "../../services/cnabServices";
import { StatusHighlighted } from "../../components/NewTable/StatusHighlighted";
import NumberFormat from "react-number-format";

export const CNABS_STATUSES = [
    {
      status: "WITHOUT_PAYMENT_RETURN",
      label: "cnabs.status.WITHOUT_PAYMENT_RETURN",
      classes: "bg-red-50 text-red-500",
    },
    {
        status: "WITH_PAYMENT_RETURN",
        label: "cnabs.status.WITH_PAYMENT_RETURN",
        classes: "bg-green-50 text-green-500",
    },
]

export const CnabsContainer = function () {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })

    const { user } = useContext(UserContext)
    const pageSize = useSelector((state) => state.pageSize.ordersInvoicesList);

    const defaultSearchParams = {
        page: 1,
        per_page: pageSize,
        store_id: user.current_store?.id,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    const [searchParams, setSearchParams] = useState(defaultSearchParams)

    const table_columns = [
        {
            Header: 'invoices.list.from_cnabs.columns.reference_id',
            accessor: 'reference_id',
            searchAs: 'reference_id',
            disableSortBy: true,
            disableWrap: true
        },
        {
            Header: 'invoices.list.from_cnabs.columns.reference_filename',
            accessor: 'reference_filename',
            searchAs: 'reference_filename',
            disableSortBy: true,
            disableWrap: true
        },
        {
            Header: 'invoices.list.from_cnabs.columns.num_of_items',
            accessor: 'num_of_items',
            searchAs: 'num_of_items',
            disableSortBy: true,
        },
        {
            Header: 'invoices.list.from_cnabs.columns.total_amount',
            accessor: 'total_amount',
            searchAs: 'total_amount',
            disableSortBy: true,
            disableWrap: true
        },
        {
            Header: 'invoices.list.status',
            accessor: 'status',
            searchAs: 'status',
            disableSortBy: true,
        },
        {
            Header: 'invoices.list.created_at',
            accessor: 'created_at',
            searchAs: 'created_at',
            disableSortBy: false,
            disableWrap: true
        },
    ]

    const {
        data: { cnabs: cnabsData, meta: paginationMeta } = {},
        isLoading,
        isError,
        isFetching,
        error,
        isPreviousData
    } = useQuery(
        ['cnabs', searchParams],
        () => fetchCnabs(searchParams),
        { keepPreviousData: true }
    )

    const filtersInitialData = [
        {
            key: "created_at",
            label: "invoices.filters.created_at",
            placeholder: "invoices.filters.created_at",
            data: {
                visible: true,
                type: 'date',
                value: null
            },
        }
    ]

    const [filtersData, setFiltersData] = useState(filtersInitialData)

    const onSortChange = (orderBy) => {
        if (orderBy.length > 0) {
            setSearchParams({
                ...searchParams,
                order_by: orderBy[0].id, 
                order: orderBy[0].desc ? 'desc' : 'asc',
                page: 1
            })
        }
        else {
            if (searchParams.order_by != defaultSearchParams.order_by || searchParams.order != defaultSearchParams.order) {
                setSearchParams({
                    ...searchParams,
                    order_by: defaultSearchParams.order_by, 
                    order: defaultSearchParams.order,
                    page: 1
                })
            }
        }
    }

    const buildCnabReferenceIdLink = cnab =>
        <div
            onClick={() => history.push(storePath + INVOICE_CNABS_SHOW.replace(':id', cnab.reference_id))}
            className="hover:underline cursor-pointer text-indigo-600 font-bold"
        >
            #{cnab.reference_id}
        </div>

    const getTableData = () => cnabsData.map(cnab => ({
        reference_id: buildCnabReferenceIdLink(cnab),
        reference_filename: cnab.reference_filename,
        num_of_items: cnab.num_of_items,
        total_amount: <NumberFormat value={cnab.total_amount} displayType={'text'} thousandSeparator={true} prefix={'R$'} decimalScale={2} fixedDecimalScale={2}/>,
        status: <StatusHighlighted status={cnab.status} statuses={CNABS_STATUSES}/>,
        created_at: <FormattedDate date={cnab.created_at} shortDate={true} />,
        
    }))

    const memoizedColumns = useMemo(() => table_columns)

    const updateFiltersData = (updatedData, filter, override = false) => {
        setFiltersData((prev) => {
            return prev.map((item) => {
                if (item.key == filter)
                    return {
                        ...item,
                        data: updatedData
                    }
                else return item
            }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
        })
        let value = updatedData.value

        if (["created_at"].includes(filter) && value?.from && value?.to) {
            const from = new Date(value.from)
            const to = new Date(value.to)
            setSearchParams((prev) => ({
                ...prev,
                [filter]: {
                    from: from.toISOString(),
                    to: to.toISOString()
                }, page: 1
            }))
        }
        else {
            if (!value) {
                setSearchParams((prev) => {
                    // Delete filter key from searchParams
                    const newSearchParams = { ...prev, page: 1 }
                    delete newSearchParams[[filter]]
                    return newSearchParams
                })
            }
            else {
                setSearchParams((prev) => ({ ...prev, [filter]: value, page: 1 }))
            }
        }
    }

    const onFilterChanged = (updatedData, filter) => {
        updateFiltersData(updatedData, filter)
    }

    const handleResetFilters = () => {
        setSearchParams(defaultSearchParams)
    }

    const isLoadingData = () => (!isPreviousData && isLoading)

    if (isLoadingData())
        return <Loader show={true} />

    if (!isLoadingData() && isError)
        return <>Error: {error.message}</>

    return (
        <>
            <NewTable
                data={getTableData()}
                columns={memoizedColumns}
                showLoader={isFetching && isPreviousData}
                showPaginationOnFooter
                isFetching={isFetching}
                emptyTableText={t('invoices.list.noData')}
                paginationMeta={paginationMeta}
                onPaginationChange={requestedPage => setSearchParams({ ...searchParams, page: requestedPage })}
                onPageSizeChange={pageSize => {
                    setSearchParams({ ...searchParams, per_page: pageSize.id, page: 1 })
                    dispatch(setOrdersListPageSize(pageSize.id))
                }}
                handleResetFilters={handleResetFilters}
                filtersData={filtersData}
                onSortChange={onSortChange}
                onFilterChanged={onFilterChanged}
                footerLabel={t('cnabs.footer')}
                hasExport={false}
            />

        </>

    )
}