import { useTranslation } from "react-i18next";
import { forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { SearchInput } from "../../SearchInput";
import { XIcon } from "@heroicons/react/outline";
import { Select } from "antd";
import { DownloadIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { cloneDeep } from "lodash";
import { FilterIcon } from "./FilterIcon";
/**
 *  Component for filtering data.
 *  Types: 'bulk', 'multiFilter', 'text', 'boolean', 'date'
 *
 * filtersData: [
 *  {
        key: "multifilter_name",
        label: "orders.filters.multifilter_name",
        placeholder: "orders.filters.multifilter_name_placeholder",
        data: {
            visible: false,
            type: 'multiFilter',
            value: [],
            options: channelIntegrationsFilters,
        },
    },
    {
        key: "textfilter_name",
        label: "orders.filters.textfilter_name",
        data: {
            visible: false,
            type: 'text',
            value: ''
        },
    },
    {
        key: "booleanfilter_name",
        label: "orders.filters.booleanfilter_name",
        data: {
            visible: false,
            type: 'boolean',
            onClickValue: false
        },
    },
   ]
 *  
 */
export const Filter = ({
    onChange = () => {},
    resetAll = () => {},
    filtersData,
    hasExport = false,
    hasSpecialExport = false,
    exportBusy = false,
    exportButtonChildren = null,
    openExportDialog = () => {},
    onClickFilterDropdown = () => {}, // only for LogRocket analysis
    backgroundgColor=""
}) => {
    const { i18n } = useTranslation()

    const [filterDropdown, setDropdown] = useState(false)

    const [innerFiltersData, setInnerFiltersData] = useState(filtersData)

    const [groupedFilters, setGroupedFilters] = useState([])

    //Handle specialExportDropdownMenu
    const [isSpecialExportDropdownOpen, setIsSpecialExportDropdownOpen] = useState(false)
    const EXPORT_BROKEN_DOWN_BY_ORDER = 0
    const EXPORT_BROKEN_DOWN_BY_PRODUCT = 1

    function hideSpecialExportDropdown (e) {
        if (isSpecialExportDropdownOpen) setIsSpecialExportDropdownOpen(false)
    }
    
    useEffect(() => {
        if (isSpecialExportDropdownOpen === true) {
            document.addEventListener("click", hideSpecialExportDropdown)
        }
        else {
            document.removeEventListener("click", hideSpecialExportDropdown)
        }
        return () => {
            document.removeEventListener("click", hideSpecialExportDropdown)
        }
    }, [isSpecialExportDropdownOpen])

    useEffect(() => {
        setInnerFiltersData(filtersData)
        const groups = filtersData.reduce((groups, filter) => {
            const group = (groups[filter.group] || []);
            group[filter.index || 0] = filter;
            groups[filter.group || filter.key] = group;
            return groups;
        }, {})
        setGroupedFilters(groups)
    }, [filtersData])

    function hideDropdown (e) {
        if (filterDropdown) setDropdown(false)
    }

    useEffect(() => { // Handle click outside dropdown
        if (filterDropdown === true) {
            document.addEventListener("click", hideDropdown)
        }
        else {
            document.removeEventListener("click", hideDropdown)
        }
        return () => {
            document.removeEventListener("click", hideDropdown)
        }
    }, [filterDropdown])

    const handleValueChanged = (value, filter_key) => {
        const updatedFilterItem = innerFiltersData.find(item => item.key == filter_key)
        if(updatedFilterItem){
            const updatedFilterData = cloneDeep(updatedFilterItem.data)
            updatedFilterData.value = value
            changeInnerState({value: value}, filter_key)
            onChange(updatedFilterData, filter_key)
        }
    }

    const handleFilterClick = (filter_key) => {
        onClickFilterDropdown(filter_key)
        setDropdown(!filterDropdown)
        const updatedFilterItem = innerFiltersData.find(item => item.key == filter_key)

        const group = updatedFilterItem.group
        let updatedFilterItems = []
        if (group) updatedFilterItems = innerFiltersData.filter(item => item.group == group)
        else updatedFilterItems.push(updatedFilterItem)

        if (updatedFilterItems) {
            updatedFilterItems.forEach(item => {
                const updatedFilterData = cloneDeep(item.data)
                if (updatedFilterData.type == 'boolean') updatedFilterData.value = true
                updatedFilterData.visible = true
                if (updatedFilterData.type === 'boolean') updatedFilterData.value = updatedFilterData.onClickValue
                changeInnerState({visible: true}, item.key)
                onChange(updatedFilterData, item.key)  
            })
        }
    }

    const handleResetFilter = (filter_key) => {
        const updatedFilterItem = innerFiltersData.find(item => item.key == filter_key)

        const group = updatedFilterItem.group
        let updatedFilterItems = []
        if (group) updatedFilterItems = innerFiltersData.filter(item => item.group == group)
        else updatedFilterItems.push(updatedFilterItem)

        if(updatedFilterItems) {
            updatedFilterItems.forEach(item => {
                const updatedFilterData = cloneDeep(item.data)
                updatedFilterData.visible = false
                updatedFilterData.value = ['bulk', 'multiFilter'].includes(updatedFilterData.type) ? [] : updatedFilterData.type == 'text' ? '' : undefined
                changeInnerState(updatedFilterData, item.key)
                onChange(updatedFilterData, item.key)
            })  
        }
    }

    const handleResetAllFilters = () => {
        innerFiltersData.filter(item => item.data.visible).map(filter => handleResetFilter(filter.key))
        resetAll()
    }

    const onChangeDatePicker = (filter_key, newDates) => {
        let adjustedDates = {
            from: newDates[0],
            to: newDates[1]?.setHours(23, 59, 59, 999)
        }

        changeInnerState({value: adjustedDates}, filter_key)

        if (adjustedDates.from && adjustedDates.to) handleValueChanged(adjustedDates, filter_key)
        else if (!adjustedDates.from && !adjustedDates.to) handleValueChanged([null, null], filter_key)
    }

    const changeInnerState = (updated_data, filter_key) => {
        setInnerFiltersData((prev) => {
            const updatedInnerFiltersData = prev.map((item) => {
                if (item.key === filter_key) {
                    return {
                        ...item,
                        data: { ...item.data, ...updated_data }
                    };
                }
                return item;
            });
    
            const updatedGroupedFilters = updatedInnerFiltersData.reduce((groups, filter) => {
                const group = (groups[filter.group] || []);
                group[filter.index || 0] = filter;
                groups[filter.group || filter.key] = group;
                return groups;
            }, {});
    
            setGroupedFilters(updatedGroupedFilters);
            return updatedInnerFiltersData;
        });
    }

    const renderFilterByType = (item) => {
        return (<>
            {item.data?.type == 'boolean' && <>
                <div className="m-2.5 mr-1">{i18n.t(item.label)} </div>
            </>}
            {item.data?.type == 'text' && <>
                <SearchInput onChange={(e) => handleValueChanged(e, item.key)} debounce={250}
                    className="text-gray-400 mr-1" inputValue={item.data.value}
                    placeholder={i18n.t(item.label)}
                />
            </>}
            {item.data?.options && !item.data?.type == 'multiFilter' && <>
                <select
                    className="mr-1 text-sm border-gray-300 rounded-md leading-5 focus:outline-none focus:ring-2 
                                        focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white"
                    onChange={(e) => handleValueChanged(e.target.value, item.key)}>
                    <option value="">{i18n.t(item.placeholder) || i18n.t('filter.select')}</option>
                    {item.options
                        .sort((a, b) => i18n.t(a?.label).toLowerCase().localeCompare(i18n.t(b?.label).toLowerCase()))
                        .map(status => {
                            return <option key={status.id} value={status.id}>{i18n.t(status.label)}</option>
                        })}
                </select>
            </>}
            {item.data?.options && item.data?.type == 'multiFilter' &&
                <>
                    <SelectMultiFilter
                        options={item.data.options}
                        filter={item.key}
                        placeholder={item.placeholder}
                        onChange={handleValueChanged}
                        value={item.data.value}
                    />
                </>}
            {item.data?.type == 'date' && <>
                <div className='flex flex-row items-center mr-1'>
                    <div className="mr-1.5 whitespace-nowrap">
                        {i18n.t(item.label)}
                    </div>
                    <ReactDatePicker
                        className="text-sm w-40 border-gray-300 rounded-md leading-5 focus:outline-none focus:ring-2
                                            focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white"
                        dateFormat="dd/MM/yy" placeholderText={i18n.t('filter.from_to')}
                        selectsRange={true}
                        startDate={item.data.value?.from}
                        endDate={item.data.value?.to}
                        onChange={(dates) => onChangeDatePicker(item.key, dates)}
                    />
                </div>
            </>}
        </>)
    }

    return (
        <div className="flex flex-col static">
            <div className='flex flex-row pl-1'>
                <div className='pl-3 py-1'>
                    <button className="whitespace-nowrap border border-gray-300 shadow-sm
                    rounded-md py-2 px-3 text-gray-700 font-medium hover:bg-gray-50"
                        onClick={(e) => {
                            e.stopPropagation()
                            setDropdown(!filterDropdown)
                        }}>
                        <svg className="inline w-5 h-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                        </svg>
                        {i18n.t('filter.button')}
                    </button>
                    {filterDropdown && (
                        <div className='border absolute z-10 mt-1 shadow divide-y bg-white rounded-md'>
                            {Object.entries(groupedFilters)
                                .sort((a, b) => i18n.t(a[1][0]?.label).toLowerCase().localeCompare(i18n.t(b[1][0]?.label).toLowerCase()))
                                .map((item) => (
                                    <div key={item[0][0]} className="px-2 py-3 cursor-pointer hover:bg-gray-50 flex items-center" onClick={() => handleFilterClick(item[1][0].key)}>
                                        {item[1][0].icon && <FilterIcon iconCode={item[1][0].icon} className="h-5 w-5 text-blue-800 mr-2" />}
                                        <div>{i18n.t(item[1][0].label)}</div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                    }
                </div>
                {innerFiltersData.some(item => item.data.visible) &&
                    <div className='pl-3 py-1'>
                        <button className={`whitespace-nowrap border ${backgroundgColor ? "border-gray-300" : ""} shadow-sm rounded-md 
                        py-2 px-3 text-blue-500 font-medium hover:bg-indigo-700 hover:text-white`}
                            onClick={handleResetAllFilters}>
                            {i18n.t('filter.reset')}
                        </button>
                    </div>
                }
                {!hasSpecialExport && hasExport && (
                <div className='pl-3 py-1'>
                    <button className="whitespace-nowrap border border-gray-300 shadow-sm
                        rounded-md py-2 px-3 text-gray-700 font-medium hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                        onClick={() => openExportDialog()}
                        disabled={exportBusy}>
                    { exportButtonChildren ? exportButtonChildren : 
                    <>
                        <DownloadIcon className="inline mb-0.5 mr-1 h-4 w-4" aria-hidden="true" />
                        <>{i18n.t("filter.export")}</>
                    </>}
                    </button>
                </div>
                )}

                {hasSpecialExport && (
                 <div className='pl-3 py-1 relative'>
                    <button
                    className="whitespace-nowrap border border-gray-300 shadow-sm
                    rounded-md py-2 px-3 text-gray-700 font-medium hover:bg-gray-50"
                    onClick={(e) => {
                        e.stopPropagation()
                        setIsSpecialExportDropdownOpen(!isSpecialExportDropdownOpen)
                    }}
                    >
                    <DownloadIcon className="inline mb-0.5 mr-1 h-4 w-4" aria-hidden="true" />
                    {i18n.t("filter.export")}
                    <ChevronDownIcon className="inline mb-0.5 ml-2 h-4 w-4" aria-hidden="true" />
                    </button>

                    {isSpecialExportDropdownOpen && (
                    <div className="mt-2 py-1 w-52 bg-white border border-gray-300 shadow-md rounded-md z-10 absolute">
                        <button
                        className="block w-full px-3 py-2 text-left text-gray-800 hover:bg-gray-100"
                        onClick={() => openExportDialog(EXPORT_BROKEN_DOWN_BY_ORDER)}
                        >
                        {i18n.t("filter.dropdownExport.breakdown_by_order")}
                        </button>
                        <button
                        className="block w-full px-3 py-2 text-left text-gray-800 hover:bg-gray-100"
                        onClick={() => openExportDialog(EXPORT_BROKEN_DOWN_BY_PRODUCT)}
                        >
                        {i18n.t("filter.dropdownExport.breakdown_by_product")}
                        </button>
                    </div>
                    )}
                </div>
                )}



            </div>
            {innerFiltersData.some(item => item.data.visible) && (
                <div className={`flex flex-wrap w-full items-start gap-x-3 gap-y-2 px-4 py-2
                ${backgroundgColor ? backgroundgColor : "bg-white"}  text-sm`}>
                    {Object.values(groupedFilters).map(group => {
                        return (
                    group[0].data.visible && 
                    <div key={group[0].key} className="bg-gray-100 rounded-lg">
                        <div className='flex items-center m-1.5 space-x-1'>
                        {group.map(filter => 
                            renderFilterByType(filter)  
                        )} 
                        <XIcon className="flex-shrink-0 cursor-pointer text-gray-400 h-5 w-5"
                            onClick={() => handleResetFilter(group[0].key)}  
                        /> 
                        </div>
                    </div>
                )})}
                </div>
            )}
        </div>)
}

export function SelectMultiFilter({
    options,
    filter,
    placeholder,
    value,
    onChange = () => {},
}) {
    const { Option } = Select;
    const { i18n } = useTranslation();
    const [search, setSearch] = useState(null)

    return (
        <Select
            getPopupContainer={trigger => trigger.parentElement} // prevent scrolling with page
            mode="multiple"
            allowClear
            placeholder={i18n.t(placeholder) || i18n.t('filter.select')}
            className="w-56"
            onChange={(array) => {
                onChange(array, filter)
            }}
            onSearch={(value) => setSearch(value?.toLowerCase())}
            value={value}
            filterOption={false}>
            {
                options.filter(option => search ? i18n.t(option.label).toLowerCase().includes(search) : true)
                    .sort((a, b) => i18n.t(a?.label).toLowerCase().localeCompare(i18n.t(b?.label).toLowerCase()))
                    .map(option => {
                        return <Option key={option.id} value={option.id}> {i18n.t(option.label)} </Option>
                    })
            }
        </Select>
    )
}