import { useState, useContext, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { UserContext } from "../../../hooks/UserContext";
import { GeneralBusinessRuleSettings, BusinessRuleAction, BusinessRuleStatement, BusinessRulePreview } from "./sections";
import { ConfirmDialog } from "../../../components";
import { DEFAULT_GENERAL_SETTINGS, DEFAULT_ACTION_SETTINGS, DEFAULT_STATEMENT_SETTINGS, CREATOR_STEPS } from "./utils/businessRuleCreatorUtils";
import { businessRuleBuilder } from "./businessRuleBuilder";
import { Button } from "../../../components";
import { fetchChannels } from "../../../services";
import { useQuery } from "react-query";

const BusinessRuleCreator = ({onCreateBusinessRule, onCancelBusinessRuleCreation}) => {
    const { t } = useTranslation()
    const {user} = useContext(UserContext)
    const [currentStep, setCurrentStep] = useState(CREATOR_STEPS.CONFIGURE_RULE_GENERAL_SETTINGS)
    const [editingSteps, setEditingSteps] = useState([])
    const [completedSteps, setCompletedSteps] = useState([])

    const [createRuleDialog, setCreateRuleDialog] = useState({open: false})
    const [discardRuleDialog, setDiscardRuleDialog] = useState({open: false})

    const [generalSettings, setGeneralSettings] = useState({...DEFAULT_GENERAL_SETTINGS})
    const [actionSettings, setActionSettings] = useState({...DEFAULT_ACTION_SETTINGS})
    const [statementSettings, setStatementSettings] = useState({...DEFAULT_STATEMENT_SETTINGS})

    const [businessRuleToPreview, setBusinessRuleToPreview] = useState(null)

    const [channelIntegrations, setChannelIntegrations] = useState(null)
    const { 
        data: channelIntegrationsData,
    } = useQuery(['channels', user.current_store?.id], () => fetchChannels({store_id: user.current_store?.id, app_name: true}), {
        keepPreviousData : true,
        refetchOnWindowFocus: false
    })

    useEffect(() => { 
        const channel_integrations = channelIntegrationsData?.channel_integrations
        channel_integrations?.unshift({id: 0, channel_type_name: "Cubbo", safe_config: {domain: "manual"}})
        setChannelIntegrations(channel_integrations)
    }, [channelIntegrationsData])

    const areRuleRequirementsCompleted = useMemo(() => {
        const ruleSteps = Object.values(CREATOR_STEPS)

        return ruleSteps.every((ruleStep) => {
            const isStepCompleted = completedSteps.some((completedStep) => completedStep === ruleStep)
            const isEditingStep = editingSteps.some((editingStep) => editingStep === ruleStep)

            return isStepCompleted && !isEditingStep
        })
    }, [completedSteps, editingSteps])

    const handleOnPreviewBusinessRule = () => {
        const timeZone = user.current_store.warehouses[0].time_zone
        const newBusinessRuleToPreview = businessRuleBuilder(generalSettings, actionSettings, statementSettings, user.current_store.id, true, timeZone)
        setBusinessRuleToPreview(newBusinessRuleToPreview)
    }

    const handleOnCreateBusinessRule = () => {
        const timeZone = user.current_store.warehouses[0].time_zone
        const createdBusinessRule = businessRuleBuilder(generalSettings, actionSettings, statementSettings, user.current_store.id, false, timeZone)

        setCreateRuleDialog({open: false})
        onCreateBusinessRule(createdBusinessRule)
    }

    const handleOnDiscardBusinessRule = () => {
        setDiscardRuleDialog({open: false})
        onCancelBusinessRuleCreation()
    }

    const setStepCompleted = (settingsOriginStep, isCompleted=true) => {
        if(isCompleted && !completedSteps.some((completedStep) => completedStep === settingsOriginStep)){
            setCompletedSteps([...completedSteps, settingsOriginStep])
        }
        else if(!isCompleted){
            const currentCompletedSteps = completedSteps.filter((completedStep) => completedStep !== settingsOriginStep)
            setCompletedSteps(currentCompletedSteps)
        }
    }

    const handleOnUpdateSettings = (settingsOriginStep, updatedSettings, resetNextSteps=false) => {
        const currentEditingSteps = editingSteps.filter((editingStep) => editingStep !== settingsOriginStep)

        switch (settingsOriginStep) {
            case CREATOR_STEPS.CONFIGURE_RULE_GENERAL_SETTINGS:
                {
                    if(currentStep === CREATOR_STEPS.CONFIGURE_RULE_GENERAL_SETTINGS){
                        setCurrentStep(CREATOR_STEPS.CONFIGURE_RULE_ACTION)
                    }
                    setGeneralSettings(updatedSettings)
                    setStepCompleted(settingsOriginStep)
                    break
                }

            case CREATOR_STEPS.CONFIGURE_RULE_ACTION:
                {
                    setCurrentStep(CREATOR_STEPS.CONFIGURE_RULE_STATEMENT)
                    setActionSettings(updatedSettings)
                    setStepCompleted(settingsOriginStep)

                    if(resetNextSteps){
                        setStatementSettings({...DEFAULT_STATEMENT_SETTINGS})
                    }
                    break
                }

            case CREATOR_STEPS.CONFIGURE_RULE_STATEMENT:
                {
                    setStatementSettings(updatedSettings)
                    break
                }

            default:
                break
        }

        setEditingSteps(currentEditingSteps)
    }

    const handleOnCancelSettings = (settingsOriginStep) => {
        const currentEditingSteps = editingSteps.filter((editingStep) => editingStep !== settingsOriginStep)
        setEditingSteps(currentEditingSteps)
    }

    const handleOnEditSettings = (settingsOriginStep) => {
        setEditingSteps([...editingSteps, settingsOriginStep])
    }

    return (
        <div className="flex flex-col gap-5">
            <div className="flex justify-between">
                <h1 className="text-3xl font-semibold">
                    {businessRuleToPreview ? t("settings.business_rule_creator.title_preview") : t("settings.business_rule_creator.title")}
                </h1>
                <Button
                    type="cancel"
                    onClick={() => setDiscardRuleDialog({open: true})}
                >
                    {t("settings.business_rule_creator.cancel_creation.button")}
                </Button>
            </div>

            <div className="flex flex-col gap-5">
                {!businessRuleToPreview ? (
                    <>
                        <GeneralBusinessRuleSettings
                            generalSettings={generalSettings}
                            onUpdateGeneralSettings={(updatedGeneralSettings) => handleOnUpdateSettings(CREATOR_STEPS.CONFIGURE_RULE_GENERAL_SETTINGS, updatedGeneralSettings)}
                            onCancelGeneralSettings={() => handleOnCancelSettings(CREATOR_STEPS.CONFIGURE_RULE_GENERAL_SETTINGS)}
                            onEdit={() => handleOnEditSettings(CREATOR_STEPS.CONFIGURE_RULE_GENERAL_SETTINGS)}
                            isPreviousStepCompleted={true}
                            isCurrentStep={currentStep === CREATOR_STEPS.CONFIGURE_RULE_GENERAL_SETTINGS}
                            isEditing={editingSteps.some((editingStep) => editingStep === CREATOR_STEPS.CONFIGURE_RULE_GENERAL_SETTINGS)}
                            isCompleted={completedSteps.some((completedStep) => completedStep === CREATOR_STEPS.CONFIGURE_RULE_GENERAL_SETTINGS)}
                        />
                        <BusinessRuleAction
                            actionSettings={actionSettings}
                            onUpdateActionSettings={(updatedActionSettings, resetNextSteps) => handleOnUpdateSettings(CREATOR_STEPS.CONFIGURE_RULE_ACTION, updatedActionSettings, resetNextSteps)}
                            onCancelGeneralSettings={() => handleOnCancelSettings(CREATOR_STEPS.CONFIGURE_RULE_ACTION)}
                            onEdit={() => handleOnEditSettings(CREATOR_STEPS.CONFIGURE_RULE_ACTION)}
                            isPreviousStepCompleted={completedSteps.some((completedStep) => completedStep === CREATOR_STEPS.CONFIGURE_RULE_GENERAL_SETTINGS)}
                            isCurrentStep={currentStep === CREATOR_STEPS.CONFIGURE_RULE_ACTION}
                            isEditing={editingSteps.some((editingStep) => editingStep === CREATOR_STEPS.CONFIGURE_RULE_ACTION)}
                            isCompleted={completedSteps.some((completedStep) => completedStep === CREATOR_STEPS.CONFIGURE_RULE_ACTION)}
                        />
                        <BusinessRuleStatement
                            statementSettings={statementSettings}
                            onUpdateStatementSettings={(updatedStatementSettings) => handleOnUpdateSettings(CREATOR_STEPS.CONFIGURE_RULE_STATEMENT, updatedStatementSettings)}
                            onCompleted={(isCompleted) => setStepCompleted(CREATOR_STEPS.CONFIGURE_RULE_STATEMENT, isCompleted)}
                            actionOption={actionSettings.actionOption}
                            isPreviousStepCompleted={completedSteps.some((completedStep) => completedStep === CREATOR_STEPS.CONFIGURE_RULE_ACTION)}
                            isCurrentStep={currentStep === CREATOR_STEPS.CONFIGURE_RULE_STATEMENT}
                            channelIntegrationsData={channelIntegrations}
                        />
                    </>
                ) : (
                    <BusinessRulePreview
                        businessRuleToPreview={businessRuleToPreview}
                    />
                )}
            </div>

            <div className="flex gap-10 bg-gray-50 rounded p-5">
                <Button
                    type="cancel"
                    className="w-full"
                    onClick={() => setDiscardRuleDialog({open: true})}
                >
                    {t("settings.business_rule_creator.cancel_creation.button")}
                </Button>

                {!businessRuleToPreview ? (
                    <Button
                        type="primary"
                        className={`w-full ${areRuleRequirementsCompleted ? "" : "cursor-not-allowed"}`}
                        onClick={handleOnPreviewBusinessRule}
                        disabled={!areRuleRequirementsCompleted}
                    >
                        {t("settings.business_rule_creator.create_rule.preview_rule_button")}
                    </Button>
                ) : (
                    <>
                        <Button
                            type="secondary"
                            className="w-full"
                            onClick={() => setBusinessRuleToPreview(null)}
                        >
                            {t("settings.business_rule_creator.create_rule.edit_rule_button")}
                        </Button>

                        <Button
                            type="primary"
                            className="w-full"
                            onClick={() => setCreateRuleDialog({open: true})}
                        >
                            {t("settings.business_rule_creator.create_rule.save_rule_button")}
                        </Button>
                    </>
                )}
            </div>

            <ConfirmDialog
                open={discardRuleDialog.open}
                setOpen={(isOpen) => setDiscardRuleDialog({open: isOpen})}
                title={t("settings.business_rule_creator.cancel_creation.dialog_title")}
                description={t("settings.business_rule_creator.cancel_creation.dialog_description")}
                confirmLabel={t("settings.business_rule_creator.cancel_creation.dialog_confirm_button")}
                cancelLabel={t("settings.business_rule_creator.cancel_creation.dialog_cancel_button")}
                onConfirm={handleOnDiscardBusinessRule}
            />

            <ConfirmDialog
                open={createRuleDialog.open}
                setOpen={(isOpen) => setCreateRuleDialog({open: isOpen})}
                title={t("settings.business_rule_creator.create_rule.dialog_title")}
                description={t("settings.business_rule_creator.create_rule.dialog_description")}
                confirmLabel={t("settings.business_rule_creator.create_rule.dialog_confirm_button")}
                cancelLabel={t("settings.business_rule_creator.create_rule.dialog_cancel_button")}
                onConfirm={handleOnCreateBusinessRule}
            />
        </div>
    )
}

export default BusinessRuleCreator