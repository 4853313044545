import { useTranslation } from 'react-i18next';

const InputText = ({ label, register, required, error, className, defaultValue=null, name }) => {
    const { t, i18n } = useTranslation(); 

    let options = {}
    if(required){
        options.required = i18n.t("inputPlaceholder.required_field")
    }

    return (
        <div className={className}>
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <div className="mt-1">
                <input
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    {...register(name, options)}
                    defaultValue={defaultValue}
                />
                <p className="text-sm text-red-600">
                    {error && error.message}
                </p>
            </div>
        </div>
    )
};

export default InputText