export const filterIsPresent = function (filterValue) {
    if (filterValue == null)
        return false

    const boolean_is_present = typeof filterValue == "boolean"
    const array_is_present = Array.isArray(filterValue) && filterValue.length
    const string_is_present = (filterValue instanceof String || typeof filterValue === 'string')
        && filterValue.trim()
    const object_is_present = typeof filterValue === 'object' && Object.values(filterValue).some(Boolean)

    return boolean_is_present || array_is_present || string_is_present || object_is_present || typeof filterValue === 'number'
}