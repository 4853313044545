import { useEffect, useState } from "react"
import { getStockPerOrderHistoric } from "../../../../services/storeMetricsService"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { DEFAULT_QUERY_STALE_TIME, INTERVAL_DAY, formatDates, parseDates, popCategories, replaceCategories } from "../chartDataUtils"
import { numberToString } from "../../../../helpers/internationalization"
import { AreaChart } from "../../../../components/MetricsCards/AreaChart"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"

export const StockPerOrderHistoric = ({ storeId = null, dateFilter = null, interval = INTERVAL_DAY }) => {
    const { i18n, t } = useTranslation()

    const [formattedData, setFormattedData] = useState([])
    const [categories, setCategories] = useState([])

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["stock_per_order_historic", storeId, dateFilter, interval], () => getStockPerOrderHistoric(storeId, dateFilter, interval), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to && !!interval,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    useEffect(() => {
        const formatData = async (data) => {
            // [{truncated_date:a, categories: {b => c, d => f}}]
            const dataParsedDates = parseDates(data)
            const dataAveragedCategories = ponderatedAverageOfCategories(dataParsedDates)
            const dataReplacedCategories = replaceCategories(dataAveragedCategories, { "average": t("metrics.stock_per_order_historic.legend") })
            setCategories([t("metrics.stock_per_order_historic.legend")])
            const flattenedData = popCategories(dataReplacedCategories, null)
            const formattedData = formatDates(flattenedData, interval, i18n.language)
            setFormattedData(formattedData)
            return null
        } 
        formatData(data).catch((error) => { console.error(error) })
    }, [data, interval, i18n.language])

    return (
        <div className="flex flex-col h-full">
            <MetricTitle className={"lg:ml-5"} title={t("metrics.stock_per_order_historic.title")}/>
            <AreaChart
                categories={categories}
                data={formattedData}
                valueFormatter={(n) => numberToString(n, i18n.language)}
                areaChartProps={{
                    showLegend: false,
                    showGridLines: false,
                    curveType: "monotone",
                    // noDataText: 'No hay pedidos enviados en este periodo',
                    className: "h-60 grow",
                }}
                isLoading={isLoading}
            />
        </div>
    )
}

const ponderatedAverageOfCategories = (data) => {
    const newData = data.map((item) => {
        const {categories, ...itemWithoutCategories} = item
        let totalOrders = 0
        let totalStock = 0
        Object.keys(categories).forEach((key) => {
            const quantityKey = parseInt(key)
            if (quantityKey === 0) return
            totalOrders += categories[key]
            totalStock += categories[key] * quantityKey
        })

        let average = totalOrders === 0 ? 0 : totalStock / totalOrders
        average = Math.round(average * 100) / 100
        let newItem = { ...itemWithoutCategories, average: average }
        return newItem
    })
    return newData
}