import { Select } from "antd"
import { t } from "i18next"
import { useMemo, useRef, useState } from "react"
import { ButtonLoader, DialogView, InputField } from "../../../components"
import { CloseIcon } from "../../../components/Icons/CloseIcon"
import { createIntegrationShippingMethod } from "../../../services/shippingMethodsServices"

export const CreateIntegrationShippingMethodModal = ({
    channelIntegrationName = "",
    channelIntegrationId = null,
    show = false,
    close = () => {},
    onConfirm = () => {},
}) => {
    const [busy, setBusy] = useState(false)
    const [newIntegrationShippingMethod, setNewIntegrationShippingMethod] = useState({
        name: "",
        shippingType: null,
    })

    const mainContainerRef = useRef(null)

    const onClose = () => {
        if (busy) return
        resetFields()
        close()
    }

    const onSave = async () => {
        setBusy(true)
        try {
            await createIntegrationShippingMethod(channelIntegrationId, {
                name: newIntegrationShippingMethod.name.trim(),
                shipping_type: newIntegrationShippingMethod.shippingType,
            })
            resetFields()
            onConfirm()
        } catch (error) {
            console.log(error)
            if (error.response?.data?.error) {
                console.log(error.response.data)
                window.alert(error.response.data.error)
            } else {
                window.alert(error)
            }
        }
        setBusy(false)
    }

    const resetFields = () => {
        setNewIntegrationShippingMethod({
            name: "",
            shippingType: null,
        })
    }

    const isFormValid = useMemo(() => {
        let valid = true
        if (!newIntegrationShippingMethod.name || newIntegrationShippingMethod.name === "") {
            valid = false
        }
        if (!newIntegrationShippingMethod.shippingType) {
            valid = false
        }
        return valid
    }, [show, newIntegrationShippingMethod])

    return (
        <DialogView open={show} setOpen={() => onClose()} className="md:w-auto overflow-visible">
            <div className="min-h-10" ref={mainContainerRef}>
                <div className="flex flex-row justify-between">
                    <div className="mb-4">
                        <div className="text-lg">{t("integrations_list.shipping.Add_Shipping_Method")}</div>
                        <div className="text-xs">{channelIntegrationName}</div>
                    </div>
                    <div>
                        <button className="p-3 cursor-pointer focus:outline-none" onClick={() => onClose()}>
                            <CloseIcon className="h6 w-6" />
                        </button>
                    </div>
                </div>
                <div className="relative">
                    <div className="mb-4">
                        <InputField
                            required
                            disabled={busy}
                            value={newIntegrationShippingMethod.name}
                            title={t("integrations_list.shipping.Integration_Delivery_Method")}
                            help={t("integrations_list.shipping.column_help.integration_shipping_method")}
                            onChange={(val) => {
                                setNewIntegrationShippingMethod({ ...newIntegrationShippingMethod, name: val })
                            }}
                        ></InputField>
                    </div>
                    <div>
                        <div className="mb-1">
                            <label className="block text-sm font-medium text-gray-700">
                                {t("integrations_list.shipping.Type_shipping_method")}
                                <span className="text-red-500"> *</span>
                            </label>
                            <label>
                                <span className="text-xs text-gray-400">{t("integrations_list.shipping.column_help.shipping_type")}</span>
                            </label>
                        </div>
                        <div>
                            <Select
                                value={newIntegrationShippingMethod.shippingType}
                                onChange={(val) => setNewIntegrationShippingMethod({ ...newIntegrationShippingMethod, shippingType: val })}
                                size={"large"}
                                className="min-w-12"
                                disabled={busy}
                                getPopupContainer={() => mainContainerRef?.current}
                            >
                                <Select.Option value="national">{t("integrations_list.shipping.integration_shipping_types.national.label")}</Select.Option>
                                <Select.Option value="international">
                                    {t("integrations_list.shipping.integration_shipping_types.international.label")}
                                </Select.Option>
                                <Select.Option value="cash_on_delivery">
                                    {t("integrations_list.shipping.integration_shipping_types.cash_on_delivery.label")}
                                </Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className="mt-6 flex justify-end w-full">
                        <ButtonLoader loading={busy} disabled={busy || !isFormValid} onClick={() => onSave()}>
                            {t("integrations_list.shipping.Add_Shipping_Method")}
                        </ButtonLoader>
                    </div>
                </div>
            </div>
        </DialogView>
    )
}
