import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import statementTemplateGenerator from "../../utils/statementTemplateGenerator";
import { TextWithTags } from "../../components";
import { AVAILABLE_RULE_STATEMENTS } from "../../utils/businessRuleStatementUtils";

const StatementOptions = ({availableOptions, selectedStatementOption, onSelectStatementOption, maxDisplayedOptions=5}) => {
    const { t, i18n } = useTranslation()
    const [displayAllOptions, setDisplayAllOptions] = useState(false)

    const handleOnSelectStatementOption = (newSelectedStatementOption) => {
        const statementOption = newSelectedStatementOption === selectedStatementOption.statement ? null : newSelectedStatementOption
        const defaultConditions = statementTemplateGenerator(statementOption)

        switch(statementOption){
            case AVAILABLE_RULE_STATEMENTS.ALL_ORDERS:
                window.analytics.track("Business Rules - Selects condition - In every order")
                break

            case AVAILABLE_RULE_STATEMENTS.IS_FIRST_TIME_BUYER:
                window.analytics.track("Business Rules - Selects condition - From new buyer")
                break

            case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_WITH_MIN_QUANTITY:
                window.analytics.track("Business Rules - Selects condition - Order contains at least")
                break

            case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY:
                window.analytics.track("Business Rules - Selects condition - Order contains from/to")
                break

            case AVAILABLE_RULE_STATEMENTS.FOR_EVERY_PRODUCT:
                window.analytics.track("Business Rules - Selects condition - Per every amount")
                break

            case AVAILABLE_RULE_STATEMENTS.FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT:
                window.analytics.track("Business Rules - Selects condition - Per every amount, only if...")
                break

            case AVAILABLE_RULE_STATEMENTS.NOT_INCLUDING_SPECIFIED_PRODUCT:
                window.analytics.track("Business Rules - Selects condition - Includes anything but something specific")
                break

            case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT:
                window.analytics.track("Business Rules - Selects condition - Order contains at least and not including at least")
                break

            case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE:
                window.analytics.track("Business Rules - Selects condition - Includes exactly something and nothing else")
                break

            case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_EXACT_QUANTITY:
                window.analytics.track("Business Rules - Selects condition - Includes exactly something and something else")
                break

            case AVAILABLE_RULE_STATEMENTS.CUSTOM_STATEMENT:
                window.analytics.track("Business Rules - Selects condition - Create personalized condition")
                break

            case AVAILABLE_RULE_STATEMENTS.FRAUD_ORDER_DETECTED:
                window.analytics.track("Business Rules - Selects condition - Detected as fraud")
                break

            case AVAILABLE_RULE_STATEMENTS.HAS_FRAGILE_ITEMS:
                window.analytics.track("Business Rules - Selects condition - Includes fragile products")
                break

            default:
                break
        }

        onSelectStatementOption({statement: statementOption, conditions: defaultConditions})
    }

    const maxAvailableOptions = useMemo(() => {
        return availableOptions ? Object.values(availableOptions).length : 0
    },[availableOptions])

    const displayedOptions = useMemo(() => {
        let newOptions = availableOptions ? Object.values(availableOptions) : []

        if(!displayAllOptions && newOptions.length > 0){
            const displayedOptionsQuantity = newOptions.length <= maxDisplayedOptions ? newOptions.length : maxDisplayedOptions
            newOptions = newOptions.slice(0, displayedOptionsQuantity)
        }

        return newOptions
    }, [availableOptions, displayAllOptions, maxDisplayedOptions])

    if(selectedStatementOption?.statement || maxAvailableOptions <= 0){
        return <></>
    }

    return (
        <div className="flex flex-col gap-5 p-5">
            <form className="flex flex-col gap-5">
                {displayedOptions.map((statementOption) => (
                    <label
                        key={statementOption}
                        htmlFor={statementOption}
                        className="border p-2 rounded cursor-pointer hover:bg-gray-50 flex justify-between gap-0.5"
                    >
                        <input
                            className="hidden"
                            type="checkbox"
                            value={statementOption}
                            name={statementOption}
                            id={statementOption}
                            onChange={() => handleOnSelectStatementOption(statementOption)}
                        />

                        <span className="flex flex-col text-base px-2">
                            <TextWithTags
                                textWithTags={t(`settings.business_rule_creator.business_rule_statement_settings.statement_options.${statementOption}.title`)}
                                listKey={"statement-option"}
                            />

                            {i18n.exists(`settings.business_rule_creator.business_rule_statement_settings.statement_options.${statementOption}.description`) && (
                                <span className="text-sm">
                                    {t(`settings.business_rule_creator.business_rule_statement_settings.statement_options.${statementOption}.description`)}
                                </span>
                            )}
                        </span>
                    </label>
                ))}
            </form>

            {maxAvailableOptions > maxDisplayedOptions && (
                <button
                    className="cursor-pointer text-blue-500 hover:text-blue-400 w-fit"
                    onClick={() => setDisplayAllOptions(!displayAllOptions)}
                >
                    {displayAllOptions ?
                        t("settings.business_rule_creator.business_rule_statement_settings.display_less_options") :
                        t("settings.business_rule_creator.business_rule_statement_settings.display_more_options")}
                </button>
            )}
        </div>
    )
}

export default StatementOptions