import { InformationCircleIcon } from "@heroicons/react/outline"
import { Text, Title } from "@tremor/react"
import { Tooltip } from "antd"

export const MetricsCardHeader = ({ title, subtitle = null, className = null, informationCircleText = null, children }) => {

    return (
        <div className={className}>
            <Title className="!text-xl font-semibold inline-block">{title}</Title>
            {informationCircleText && 
                <Tooltip placement="topLeft" title={informationCircleText}>
                    <InformationCircleIcon className='ml-2 mb-1 w-4 h-4 inline text-blue-600'/>
                </Tooltip>}
            {subtitle && <Text className="mt-1 text-gray-700 !text-base">{subtitle}</Text>}
            {children}
        </div>
    )
}