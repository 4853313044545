import { createSlice } from '@reduxjs/toolkit'

export const validSettingsFormSlice = createSlice({
  name: 'validSettingsForm',
  initialState: {
    form:{
      id: "default",
      change : false,
      selectedkeys:[]
    }

  },
  reducers: {
    setValidSettingsForm: (state, action) => {
      state.form = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { 
  setValidSettingsForm
 } = validSettingsFormSlice.actions

export default validSettingsFormSlice.reducer