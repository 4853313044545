import { useTranslation } from "react-i18next"
import { BarList } from "../../../../components/MetricsCards/BarList"
import { getTotalNationalDestinations } from "../../../../services/storeMetricsService"
import { useQuery } from "react-query"
import { useMemo } from "react"
import { numberToString } from "../../../../helpers/internationalization"
import { DEFAULT_QUERY_STALE_TIME } from "../chartDataUtils"

export const TopOrderDestinations = ({ storeId = null, dateFilter = null }) => {
    const { i18n, t } = useTranslation()

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["total_national_destinations", storeId, dateFilter], () => getTotalNationalDestinations(storeId, dateFilter), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    const nationalDestinations = useMemo(() => {
        if (!data) return []
        // remove any keys with INVALID_ZIPCODE
        const dataWithoutInvalidZipcodes = Object.keys(data).reduce((object, key) => {
            if (key !== "INVALID_ZIPCODE") {
                object[key] = data[key]
            }
            return object
        }, {})
        let newNationalDestinations = []
        Object.keys(dataWithoutInvalidZipcodes).forEach((key) => {
            newNationalDestinations.push({
                key: key,
                value: data[key],
                name: key === "INTERNATIONAL" ? t("metrics.top_national_destinations.INTERNATIONAL") : key,
            })
        })
        return newNationalDestinations
    }, [data])

    const totalOrders = useMemo(() => {
        const total = nationalDestinations.reduce((acc, item) => acc + item.value, 0)
        return numberToString(total, i18n.language)
    }, [nationalDestinations, i18n.language])

    return (
        <BarList
            totalValue={totalOrders}
            totalValueLabel={t("metrics.generic.shipments")}
            title={t("metrics.top_national_destinations.title")}
            mainColumnLabel={t("metrics.top_national_destinations.main_column_label")}
            secondaryColumnLabel={t("metrics.top_national_destinations.secondary_column_label")}
            cardProps={{
                className: "h-fit",
            }}
            data={nationalDestinations}
            valueFormatter={(n) => numberToString(n, i18n.language)}
            isLoading={isLoading}
            maxRowsLabel={t("metrics.top_national_destinations.grouped_rows_label")}
            maxRows={7}
            paged={true}
            viewOthersLabel={t("metrics.top_national_destinations.view_others_label")}
            goBackLabel={t("metrics.top_national_destinations.go_back_label")}
        />
    )
}
