import { forwardRef, memo, useMemo } from "react"

const BASE_CLASS_NAME = "rounded-lg px-10 pt-6 pb-8 bg-white shadow-md"

const BaseCardComponent = ({ children, ...otherProps }, ref) => {
    const { className: requestedClassName = "", ...otherCardProps } = otherProps
    const cardClassName = useMemo(() => {
        return `${BASE_CLASS_NAME} ${requestedClassName}`
    }, [requestedClassName])

    return (
        <div ref={ref} className={cardClassName} {...otherCardProps}>
            {children}
        </div>
    )
}

export const BaseCard = memo(forwardRef(BaseCardComponent))
