import cubboLogo from "../../assets/logo-2.png"
import {useHistory, Link, useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {useContext, useRef, useState} from "react";
import {UserContext} from "../../hooks/UserContext";
import { fetchStores } from "../../services/storeServices";
import {installShopifyStore} from "../../services/onboardingServices"
import {useForm} from "react-hook-form";
import {Notification} from "../../components";
import useAuth from "../../hooks/useAuth";
import { ROOT, FORGOT_PASSWORD, DASHBOARD } from "../../navigation/constants";
import { Loader } from "../../components/Loader";
import { CheckCircleIcon, ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import { setUserPreferences } from "../../services/userService";
import { useTranslation } from 'react-i18next';

export const ViewShopifyOnboarding = (props) => {

    const { t, i18n } = useTranslation(); 
    const history = useHistory()

    const {user, setUser} = useContext(UserContext)

    const [showDefaultStoreSetNotif, setShowDefaultStoreSetNotif] = useState(false)
    const [isInstallationLoading, setIsInstallationLoading] = useState(false)

    // if(user && !user.permissions?.read_all_stores && user.all_stores_ids?.length<2) {
    //     history.replace('/')
    // }


    const search = useLocation().search;
    const shopify_state = new URLSearchParams(search).get('shopify_state');

    if(shopify_state === null) {
      history.replace('/')
    }

    // omit store_ids param if user can real all stores
    const [searchParams, setSearchParams] = useState({
        page: 1,
        count_products: false,
        ...!user?.permissions?.read_all_stores && {store_ids: user?.all_stores_ids}
    })

    const tableRef = useRef()

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData
    } = useQuery(['stores', searchParams], () => fetchStores(searchParams), { keepPreviousData: true }) 


    const selectStore = async (store) => {
        setIsInstallationLoading(true)
        try {
            await setUserPreferences(user.id, {default_store_id: store.id})
            setUser({...user, default_store: store})
            await installShopifyStore(store.id, shopify_state)
            history.push(DASHBOARD)

        } catch (error) {
            console.log(error)
            window.alert(error)
        }

        setIsInstallationLoading(false)
    }

    return (
            <>
                {(!isPreviousData && isLoading) || isInstallationLoading ? (
                    <><Loader show={true}></Loader></>
                ) : isError ? ( 
                    <>Error: {error.message}</>
                ) : (
                    <>
                     <div className="min-h-screen bg-light-blue-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                     <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img
                        className="mx-auto h-12 w-auto"
                        src={cubboLogo}
                        alt="Workflow"
                        />
                        
                    </div>
                        <div className="mt-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold leading-tight text-gray-50">{i18n.t("onboardings.select_store")}</h1>
                        </div>
                       <div className="bg-white shadow sm:rounded-md mt-10 sm:mx-auto sm:w-full sm:max-w-md">
                            <ul role="list" className="divide-y divide-gray-200">
                                {data.stores.map((store) => (
                                <li key={store.id}>
                                    <a href="#" onClick={() => selectStore(store)} className="block hover:bg-gray-50">
                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                        <div className="min-w-0 flex-1 flex items-center">
                                        <p className="text-base font-medium text-gray-600 truncate">{store.name}</p>
                                        </div>
                                        <div>
                                        <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                    </div>
                                    </a>
                                </li>
                                ))}
                            </ul>
                        </div>

                        <div className="sm:mx-auto sm:w-full sm:max-w-md mt-10">
                            <a href="#" onClick={() => history.push('/')} className="">{i18n.t("onboardings.cancel_installation")}</a>
                        </div>
                        </div>
                    </>
                )}
            </>
    )
}