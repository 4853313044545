import parcel_bag_image from '../../../assets/parcel_bag.png';
import jiffy_envelope_image from '../../../assets/jiffy_envelope.png';
import cubbo_box_image_image from '../../../assets/cubbo_box.png';
import packageless_image from '../../../assets/packageless.png';

export const FRAGILE_TYPES = {
  FRAGILE_ONLY: "FRAGILE_ONLY",
  NON_FRAGILE_ONLY: "NON_FRAGILE_ONLY",
  BOTH: "BOTH",
  NEITHER: "NEITHER",
}

export const NON_FRAGILE_PACKAGE_TYPES = {
  PARCEL_BAG: "parcel_bag",
  JIFFY_ENVELOPE: "jiffy_envelope",
  CUBBO_BOX: "cubbo_box",
}

export const FRAGILE_PACKAGE_TYPES = {
  JIFFY_ENVELOPE: "jiffy_envelope",
  CUBBO_BOX: "cubbo_box",
}

export const PACKAGELESS_PACKAGE_TYPES = {
  PACKAGELESS: "packageless",
}

export const PACKAGES_TYPES = {
  PARCEL_BAG: "parcel_bag",
  JIFFY_ENVELOPE: "jiffy_envelope",
  CUBBO_BOX: "cubbo_box",
}

export const VIRTUAL_PACKAGES_TYPES = {
  PACKAGELESS: "packageless",
}

export const PACKING_OPTION_TYPES = {
  FRAGILE_ONLY: "FRAGILE_ONLY",
  NON_FRAGILE_ONLY: "NON_FRAGILE_ONLY",
  PACKAGELESS: "PACKAGELESS",
}

export const PACKING_OPTIONS_IMAGES = {
  PARCEL_BAG: parcel_bag_image,
  JIFFY_ENVELOPE: jiffy_envelope_image,
  CUBBO_BOX: cubbo_box_image_image,
  PACKAGELESS: packageless_image,
}

export const UNEDITABLE_PACKING_OPTIONS = {
  CUBBO_BOX: "CUBBO_BOX",
  PACKAGELESS: "PACKAGELESS",
}
