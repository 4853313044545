import {
    BasicTable,
    Button,
    CheckBox,
    ConfirmDialog,
    FilterMenu,
    Notification,
    PageView,
    SearchInput,
    ButtonLoader,
    PageTopBar
} from "../../components";
import { TableImage  } from "../../components/TableImage";
import {useParams, useHistory} from "react-router-dom";
import {useQuery} from "react-query";
import {fetchShopifyProducts} from "../../services/channelServices";
import {useContext, useState, useRef, useEffect} from "react";
import { ArrowRightIcon, ArrowDownIcon} from '@heroicons/react/outline'
import {useDispatch, useSelector} from "react-redux";
import {openDialog, setOpenDialog} from "../../redux/importProductsSlice";
import {importProducts, importAllProducts} from "../../services";
import { findIndex } from "lodash";
import { Loader } from "../../components/Loader";
import { useTranslation } from "react-i18next";

function getVariants(variants, productId){
   return []
}

function getSku(variants){
    return ""
}


export const ListVtexProducts = () => {

    const { t, i18n} = useTranslation();
    let selectedFlatRows;
    const [selectedRowIds, setSelectedRowIds] = useState({})
    const [searchParams, setSearchParams] = useState({
        page: 1,
    })
    const history = useHistory()
    const [openImport, setOpenImport] = useState(false)
    const [openImportAll, setOpenImportAll] = useState(false)
    const [showNotification, setShowNotification] = useState(false)
    const [dialogLoading, setDialogLoading] = useState(false)
    const [title, setTitle] = useState(false)
    const { id } = useParams()
    const [selectedProducts, setSelectedProducts] = useState([])
    const [products, setProducts] = useState([])
    const [loadingImportBotton, setLoadingImportBotton] = useState(false)

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,refetch
    } = useQuery(['vtex_products', id, searchParams], () => fetchShopifyProducts(id,searchParams))
    const tableRef = useRef()
    /*const { data, isLoading, isError, error, refetch} = useQuery("shopify_products", () => fetchShopifyProducts(id),{
        onSuccess: (info) => {
            if(!products.length)
                setProducts(info.products)
        }})*/

        const columns = [
            {
                Header: "integrations_list.vtex.image",
                accessor: 'image',
                disableFilters: false,
                disableSortBy: false,
            },
            {
                // Build our expander column
                id: 'name', // Make sure it has an ID
                accessor: 'NameComplete',
                Header: "integrations_list.vtex.name",
                disableFilters: false,
                disableSortBy: false,
                disableSortBy: false,
                Cell: ({ row }) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row
                    row.canExpand ? (
                        <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                    // We can even use the row.depth property
                                    // and paddingLeft to indicate the depth
                                    // of the row
                                    paddingLeft: `${row.depth * 2}rem`,
                                },
                            })}
                        >
                            {row.isExpanded ? ( <div className="flex underline text-blue-800"><ArrowDownIcon className="h-4 h-4 mr-2"/>{row.original.NameComplete}</div>) : <div className="flex underline text-blue-800"><ArrowRightIcon className="h-4 h-4 mr-2"/>{row.original.name}</div>}
                        </span>
                    ) : (`${row.original.NameComplete}`),
            },
            {
                id: 'ref_id',
                Header: 'refId - sku',
                accessor: 'AlternateIds.RefId',
                disableFilters: false,
                disableSortBy: false,
            },
            {
                id: 'sku_id',
                Header: 'Vtex SKU ID',
                accessor: 'sku_id',
                disableFilters: false,
                disableSortBy: false,
            },
            /*{
                Header: 'Cantidad',
                accessor: 'inventory_quantity',
                disableFilters: true,
                disableSortBy: true,
            },*/
            {
                Header: "integrations_list.vtex.status",
                accessor: 'status', // accessor is the "key" in the data
                disableFilters: true,
                disableSortBy: true,
            },
        ]

    useEffect(() => {
        //console.log("****",data)
        if(data?.products?.length){
            setProducts(data.products)
        }
    }, [data])
    const handleImportProducts = () => {
        setOpenImport(true)
        //dispatch(setOpenDialog(true))
    }

    const handleImportAllProducts = () => {
        setOpenImportAll(true)
    }

    /**
     *
     * @returns {[{product_id: int, isParent: boolean, variant_id: int, parent: int}]}
     */
    function getSelectedRows() {
        let rows = []
        for (let i = 0; i < selectedProducts.length; i++) {

            let original = selectedProducts[i]

            rows.push({
                product_id: original.SkuSellers[0].StockKeepingUnitId,
                is_parent: true
            })

        }
        return rows;
    }

    const confirmImportProducts = async () => {
        try {
            const rowsSelected = getSelectedRows();

            setDialogLoading(true)

            const res = await importProducts(id, rowsSelected)
            setTitle:i18n.t("integrations_list.vtex.Updated_Inventory")
            setSelectedProducts([])
            setDialogLoading(false)
            setOpenImport(false)
            setShowNotification(true)
            setTimeout(() => {
                history.goBack()
            }, 500);

        } catch (err){
            setTitle (i18n.t("integrations_list.vtex.Error_importing"))
            //console.error("Error importando", err)
            setDialogLoading(false)
        }
    }

    const confirmImportAllProducts = async () => {
        try {
            // const rowsSelected = getSelectedRows();

            setDialogLoading(true)

            const res = await importAllProducts(id)
            setTitle (i18n.t("integrations_list.vtex.import_of_products"))
            setSelectedProducts([])
            setDialogLoading(false)
            setOpenImportAll(false)
            setShowNotification(true)
            setTimeout(() => {
                history.goBack()
            }, 500);

        } catch (err){
            setTitle(i18n.t("integrations_list.vtex.error"))
            setDialogLoading(false)
        }
    }

   

    const onFilterChange = (activeFilters) => {
         if(activeFilters.length){
             let result = data.products.filter(product => {
                 if(activeFilters[0].id=="name"){

                     let value = product.NameComplete.includes(activeFilters[0].value) ? true : false
                     return value
                 }
                 else if(activeFilters[0].id=="ref_id"){
                    let index = product.AlternateIdValues.findIndex(variant => {
                        return variant.includes(activeFilters[0].value) ? true : false
                    })
                    let value = index!= -1? true : false
                    return value
                 }
                 else{
                     let filter = activeFilters[0].value.toString()
                    let value = product.Id.toString().includes(filter) ? true : false

                    return value
                 }
             }
             );
             setProducts(result)
         }else{
             setSelectedProducts([])
             setProducts(data.products)
             refetch()
         }
    }
    return (
        <>
            <Notification show={showNotification} setShow={setShowNotification} title={title}/>
            <ConfirmDialog
            
                open={openImport}
                setOpen={setOpenImport}
                title= {i18n.t("integrations_list.vtex.import_Products")}
                description={`${i18n.t("integrations_list.vtex.import")} ${selectedProducts.length} ${i18n.t("integrations_list.vtex.products")}`}
                confirmLabel= {i18n.t("integrations_list.vtex.yes")}
                onConfirm={confirmImportProducts}
                loading={dialogLoading}
            />

            <ConfirmDialog
                        
                open={openImportAll}
                setOpen={setOpenImportAll}
                title= {i18n.t("integrations_list.vtex.import_all_products")} 
                description= {i18n.t("integrations_list.vtex.import_missing_products")} 
                confirmLabel={i18n.t("integrations_list.vtex.yes")}
                onConfirm={confirmImportAllProducts}
                loading={dialogLoading}
            />

            <PageView
                topMenu={<PageTopBar>
                    {/* <SearchInput /> */}
                    <div className="text-lg font-semibold">{i18n.t("integrations_list.vtex.import_shortage_products")}</div>
                    <div>
                    <ButtonLoader type="secondary" className="mr-5" loading_text= {i18n.t("integrations_list.vtex.preparing_import")} loading={loadingImportBotton} disabled={loadingImportBotton} onClick={() => handleImportAllProducts()}>
                        {i18n.t("integrations_list.vtex.import_all")}
                        </ButtonLoader>
                        <Button onClick={handleImportProducts} className="">{i18n.t("integrations_list.vtex.import_selection")} ({selectedProducts.length})</Button>
                    </div>
                   
                    
                </PageTopBar>}
            >
                {!isPreviousData && isLoading ? (
                    <><Loader show={true}></Loader></>
                ) : isError ? (
                    <>Error: {error.message}</>
                ) : (
                    <>
                    <div class="bg-light-blue-500 text-center py-4 lg:px-4">
                        <div class="p-2 bg-light-blue-500 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                            <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">{i18n.t("integrations_list.vtex.notice")}</span>
                        </div>
                    </div>
                    <BasicTable
                        onFilterChange={onFilterChange}
                        columns={columns}
                        showHeader
                        selectable
                        filterable
                        
                        data={products.map(product => {
                            return {
                                ...product,
                                number_variants: product.skus?.length,
                                sku: <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-800"} >{getSku(product.skus)}</span>,
                                isParent: true,
                                sku_id: product.SkuSellers[0].StockKeepingUnitId,
                                //inventory_quantity: getQuantity(product.variants),
                                image: <div className='flex justify-center py-2'>
                                    <TableImage 
                                        thumbnail_image= {product.ImageUrl}
                                        regular_image= {product.ImageUrl}
                                    />
                                </div>,
                                checkbox: <CheckBox />,
                                subRows: getVariants(product.skus, product.productId),
                                status: false ? <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-green-100 text-green-800"} >{i18n.t("integrations_list.vtex.imported")}</span>:<span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-100 text-red-800"} >{i18n.t("integrations_list.vtex.no_import")}</span>
                            }
                        })}
                        onSelectionChange={(rows) => {
                            setSelectedProducts(rows)
                        }}
                        showPaginator
                        showHeader
                        
                        sortable
                        paginationMeta={data?.meta}
                        showCount = {true}
                        onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
                        showLoader={isFetching && isPreviousData}
                        isFetching = {isFetching}
                        emptyTableText= {i18n.t("integrations_list.vtex.nothing_to_show")}
                        ref = {tableRef}
                        /*onChangeSelection={(selectedFlatRowsParam, selectedRowIdsParam) => {
                            console.log(selectedFlatRowsParam, selectedRowIdsParam)
                            selectedFlatRows = selectedFlatRowsParam
                            setSelectedRowIds(selectedRowIdsParam)
                        }}*/
                    />
                    </>
                    )}
            </PageView>
        </>
    )
}