export const OrderBuyerIcon = ({ className = null }) => {
    return (
        <svg className={className} viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M80.0623 30.5C80.0623 35.5557 78.0539 40.4043 74.479 43.9792C70.9041 47.5541 66.0555 49.5625 60.9998 49.5625C55.9441 49.5625 51.0955 47.5541 47.5206 43.9792C43.9457 40.4043 41.9373 35.5557 41.9373 30.5C41.9373 25.4443 43.9457 20.5957 47.5206 17.0208C51.0955 13.4459 55.9441 11.4375 60.9998 11.4375C66.0555 11.4375 70.9041 13.4459 74.479 17.0208C78.0539 20.5957 80.0623 25.4443 80.0623 30.5ZM22.8799 102.267C23.0432 92.2643 27.1313 82.727 34.2624 75.7114C41.3935 68.6958 50.9962 64.7641 60.9998 64.7641C71.0033 64.7641 80.6061 68.6958 87.7372 75.7114C94.8683 82.727 98.9564 92.2643 99.1197 102.267C87.1606 107.75 74.1562 110.58 60.9998 110.563C47.3968 110.563 34.4851 107.594 22.8799 102.267Z"
                stroke="currentColor"
                strokeWidth="6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
