import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PackingOption from "./PackingOption";
import { TextWithBoldTags } from '../../../../utils/uiUtils';
import { PACKING_MATERIAL_TYPES } from "../../constants";

import no_image from "../../../../assets/no-image.png";
import bubble_wrap from "../../../../assets/bubble_wrap.png";
import kraft_paper from "../../../../assets/kraft_paper.png";
import corn_extrudate from "../../../../assets/corn_extrudate.png";

const FragilePackingMaterialsList = ({warehouseMaterialList, storePackingMaterialList, onPackingMaterialChange, forFragile}) => {
    const { i18n } = useTranslation()
    const [initialSelectedMaterial, setInitialSelectedMaterial] = useState({
        material_type: PACKING_MATERIAL_TYPES.PROTECTION_MATERIAL,
        code: null,
        for_fragile: forFragile,
        id: null
    })
    const [selectedMaterial, setSelectedMaterial] = useState({
        material_type: PACKING_MATERIAL_TYPES.PROTECTION_MATERIAL,
        code: null,
        for_fragile: forFragile,
        id: null
    })
    const [materialList, setMaterialList] = useState(null)
    const [visibleMaterialList, serVisibleMaterialList] = useState(null)
    const [showMaterialsList, setShowMaterialsList] = useState(false)

    const MATERIALS_IMAGES = {
        BUBBLE_WRAP: bubble_wrap,
        KRAFT_PAPER: kraft_paper,
        CORN_EXTRUDATE: corn_extrudate,
        UNSELECTED: no_image
    }

    useEffect(() => {
        let newAvailableMaterialList = warehouseMaterialList?.filter(material => (
            material.material_type === PACKING_MATERIAL_TYPES.PROTECTION_MATERIAL && material.for_fragile === forFragile
        ))

        if(!forFragile && newAvailableMaterialList){
            const deleteMaterialOption = {
                material_type: PACKING_MATERIAL_TYPES.PROTECTION_MATERIAL,
                code: null,
                for_fragile: forFragile,
                id: null
            }

            newAvailableMaterialList = [deleteMaterialOption, ...newAvailableMaterialList]
        }

        const materialsOrder = {
            DELETE_OPTION: 0,
            KRAFT_PAPER: 1,
            CORN_EXTRUDATE: 2,
            BUBBLE_WRAP: 3
        }

        const orderedMaterialList = newAvailableMaterialList.sort((a, b) => {
            const aOrderValue = a.code ? materialsOrder[a.code] : materialsOrder.DELETE_OPTION
            const bOrderValue = b.code ? materialsOrder[b.code] : materialsOrder.DELETE_OPTION

            return aOrderValue < bOrderValue ? (-1) : aOrderValue > bOrderValue ? 1 : 0
        })

        setMaterialList(orderedMaterialList)
    }, [warehouseMaterialList, forFragile])

    useEffect(() => {
        const preferredMaterial = storePackingMaterialList?.find(({warehouse_packing_material}) => (
            forFragile === warehouse_packing_material.for_fragile && warehouse_packing_material.material_type === PACKING_MATERIAL_TYPES.PROTECTION_MATERIAL
        ))

        const defaultMaterial = materialList?.find(material => (
            forFragile === material.for_fragile && material.material_type === PACKING_MATERIAL_TYPES.PROTECTION_MATERIAL && material.is_default === true
        ))

        const selectedWarehouseMaterial = preferredMaterial ? preferredMaterial.warehouse_packing_material : defaultMaterial

        const material = {
            material_type: PACKING_MATERIAL_TYPES.PROTECTION_MATERIAL,
            code: selectedWarehouseMaterial ? selectedWarehouseMaterial.code : null,
            for_fragile: forFragile,
            id: selectedWarehouseMaterial ? selectedWarehouseMaterial.id : null
        }

        setInitialSelectedMaterial({...material})
        setSelectedMaterial({...material})
    }, [storePackingMaterialList, forFragile, materialList])

    useEffect(() => {
        if(materialList){
            const visibleMaterials = materialList.filter((material) => {
                const isSelected = initialSelectedMaterial.id !== null && material.id === initialSelectedMaterial.id ? true : false
                return !isSelected && !material.is_default && material.hidden ? false : true
            })

            const showMaterials = visibleMaterials.some(material => material.id !== null ? true : false)

            setShowMaterialsList(showMaterials)
            serVisibleMaterialList(visibleMaterials)
        }
    }, [materialList, initialSelectedMaterial])

    const handleOnChange = (materialOption) => {
        const newMaterial = {
            material_type: PACKING_MATERIAL_TYPES.PROTECTION_MATERIAL,
            code: materialOption ? materialOption.code : null,
            for_fragile: forFragile,
            id: materialOption ? materialOption.id : null
        }

        setSelectedMaterial(newMaterial)
        onPackingMaterialChange({...newMaterial})
    }

    const translationKey = forFragile ? "for_fragile" : "for_not_fragile"

    if(!showMaterialsList){
        return <></>
    }

    return (
        <div>
            <div className="border rounded-t border-b-0 p-3 bg-gray-50">
                <TextWithBoldTags
                    textWithTags={i18n.t(`settings.packing_options.container.fragile_packing_materials.titles.${translationKey}`)}
                />
            </div>

            <div className="border rounded-lg rounded-t-none flex flex-col">
                {visibleMaterialList?.map((materialOption, index, { length }) => {
                    const optionKey = `${translationKey}-${materialOption.material_type}-${materialOption.code}`
                    const materialCodeKey = materialOption.code ? materialOption.code : "UNSELECTED"
                    const isChecked = selectedMaterial?.code === materialOption.code

                    return(
                        <div key={optionKey} className={`flex p-5 items-center ${index > 0 && index < length ? "border-t" : ""}`}>
                            <input
                                type="radio"
                                id={`fragile-packing-materials-list-${translationKey}-${materialOption.code}`}
                                name={`fragile-packing-materials-list-${translationKey}`}
                                value={materialCodeKey}
                                defaultChecked={isChecked}
                                checked={isChecked}
                                onChange={(event) => handleOnChange(materialOption)}
                            />

                            <label for={`fragile-packing-materials-list-${translationKey}-${materialOption.code}`}>
                                <PackingOption
                                    title={i18n.t(`settings.packing_options.container.fragile_packing_materials.${translationKey}.${materialCodeKey}.title`)}
                                    description={i18n.t(`settings.packing_options.container.fragile_packing_materials.${translationKey}.${materialCodeKey}.description`)}
                                    image={MATERIALS_IMAGES[materialCodeKey]}
                                />
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default FragilePackingMaterialsList
