import { useState } from "react"

export const useForm = ({ initialState }) => {
  const [formState, setFormState] = useState(initialState)

  const handleChange = (key, value) => {
    setFormState(prevState => ({
        ...prevState,
        [key]: value
      }))
  }

  return {
    formState,
    handleChange
  }
}