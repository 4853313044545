export const ClaimIcon = ({ className = "" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 52" className={className} role="img">
            <path
                fill="white"
                d="M20.8235 14V42.8M13.7647 36.0368L15.8329 37.6184C18.5882 39.728 23.0565 39.728 25.8141 37.6184C28.5718 35.5088 28.5718 32.0912 25.8141 29.9816C24.4376 28.9256 22.6306 28.4 20.8235 28.4C19.1176 28.4 17.4118 27.872 16.1106 26.8184C13.5082 24.7088 13.5082 21.2912 16.1106 19.1816C18.7129 17.072 22.9341 17.072 25.5365 19.1816L26.5129 19.9736M10.2353 50H20.8235C23.6045 50 26.3582 49.4413 28.9274 48.3558C31.4967 47.2703 33.8311 45.6792 35.7976 43.6735C37.764 41.6678 39.3238 39.2866 40.388 36.666C41.4523 34.0453 42 31.2366 42 28.4C42 22.6713 39.7689 17.1773 35.7976 13.1265C31.8262 9.07571 26.4399 6.8 20.8235 6.8H2M6.70588 11.6L2 6.8M6.70588 2L2 6.8"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
