import { PencilIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";

const BusinessRuleStepCard = ({
    children,
    title,
    description,
    onEdit,
    isCompleted=false,
    isValid=false,
    isEditing=false,
    isCurrentStep=false,
    isPreviousStepCompleted=false,
    completedDescription=""
}) => {

    return (
        <div className={`${isPreviousStepCompleted ? "border rounded-lg overflow-hidden shadow-lg" : "relative"} `}>
            {!isPreviousStepCompleted && <div className="absolute inset-0 bg-gray-50 bg-opacity-50"></div>}

            <div className={`flex justify-between items-center px-5 py-3 ${isCurrentStep ? "bg-blue-950 text-white" : isPreviousStepCompleted ? "bg-gray-200" : ""}`}>
                <div className="flex flex-col font-light">
                    <div className="flex gap-2 items-center">
                        <h2 className="text-lg font-normal">
                            {title}
                        </h2>

                        {isValid && (
                            <CheckCircleIcon className={`h-6 ${isCurrentStep ? "text-green-200" : "text-green-600"}`} />
                        )}

                        {!isValid && (isEditing || isCurrentStep) && (
                            <ExclamationCircleIcon className={`h-6 ${isCurrentStep ? "text-red-200" : "text-red-600"}`} />
                        )}
                    </div>
                    {description}
                </div>

                {!isEditing && isCompleted && isPreviousStepCompleted && !isCurrentStep && (
                    <PencilIcon className="cursor-pointer hover:text-gray-500 h-6 text-gray-600" onClick={onEdit}/>
                )}
            </div>

            <div>
                {isPreviousStepCompleted && (
                    children
                )}
            </div>

            {isCompleted && !isEditing && (
                <div className="p-5 font-semibold">
                    {completedDescription}
                </div>
            )}
        </div>
    )
}

export default BusinessRuleStepCard
