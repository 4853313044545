import { useHistory } from "react-router-dom"
import cubboLogo from "../../assets/cubbo_logo.png"
import poweredByCubbo from "../../assets/powered_by_cubbo.png"
export const BlingPublicPage = () => {
    const history = useHistory()
    return (
        <div className="flex justify-center absolute inset-0 sm:static sm:min-h-screen">
            <div className="flex flex-col justify-between relative w-screen sm:max-w-[480px]
                border rounded-md bg-gray-50">
                <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
                    <img className="h-16" src={cubboLogo} alt="Cubbo Logo"/>
                </div>
                <div className="sm:max-w-[480px]">
                    <div className="flex flex-col px-10 h-full bg-gray-50">
                        <div className="mt-1 mb-5 font-semibold">Bem-vindo à Cubbo</div>
                        <div>
                            Somos uma solução completa de logística para e-commerce. Nós cuidamos do operacional para que você possa focar no que realmente importa: seu produto e suas vendas.
                        </div>
                        <div className="mt-4">
                            Quer saber mais? 
                            <a href='https://br.cubbo.com/entre-em-contato?utm_source=Bling&utm_medium=Bling&utm_campaign=Bling+Integração'
                                rel='noreferrer'
                                target="_blank"
                                className="ml-1 hover:underline cursor-pointer">
                                Fale com nossos especialistas agora
                            </a>
                        </div>
                        <div className="my-4">
                            Já é parceiro da Cubbo? Faça login e siga o passo-a-passo junto com nossa equipe de suporte para realizar a integração com o Bling.
                        </div>
                        <button className={`py-3 px-5 mt-5 w-full border border-transparent shadow-sm rounded-lg bg-blue-500 text-white font-semibold
                            hover:bg-blue-600 focus:ring-2 focus:ring-offset-2 focus:outline-none focus:ring-blue-600 disabled:opacity-50`}
                            onClick={() => history.push("login")}>
                            Fazer login na Cubbo
                        </button>
                    </div>
                </div>
                <div className="bg-gray-50 p-5 sm:max-w-[480px]">
                    <a href='https://br.cubbo.com/'
                        rel='noreferrer'
                        target="_blank">
                        <img className="h-10 m-auto" src={poweredByCubbo} alt="Powered by Cubbo"/>
                    </a>
                </div>
            </div>
        </div>
    )
}