import { useState, useContext, useReducer, useRef } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom"
import { useQuery } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import {
    Button,
    PageView,
    SlidePanel,
    PageTopBar,
    SearchInput,
    SelectInput,
} from "../../components"
import { ADD_WORK_ORDER, SHOW_REPLENISHMENT, SHOW_WORK_ORDER, STORE_PATH, WORK_ORDERS } from '../../navigation/constants'
import { Loader } from '../../components/Loader'
import { UserContext } from '../../hooks/UserContext'
import { Pagination, Radio } from 'antd'
import { useTranslation } from "react-i18next"
import { filterReducer, INITIAL_STATE } from './utils/filterReducer'
import { XIcon } from '@heroicons/react/outline'
import { useEffect } from 'react'
import { fetchWorkOrders } from '../../services/workOrderServices'
import { setWorkOrdersListPageSize } from '../../redux/pageSizeSlice'
import { COMPLETED, DEFAULT_QUERY_STALE_TIME, IN_PROGRESS, IN_VALIDATION, REJECTED, REQUESTED, SCHEDULED, WORK_ORDER_STATUSES, WORK_ORDER_TYPES } from './utils/constants'
import { WorkOrderDetailContainer } from './WorkOrderDetailContainer'
import { LINE_TYPE_END, LINE_TYPE_MIDDLE, LINE_TYPE_NONE, LINE_TYPE_START, TimelineItem } from '../../components/Timeline/TimelineItem'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'

const pagesInterval = [
    { id: "10", name: '10' },
    { id: "25", name: '25' },
    { id: "50", name: '50' },
    { id: "100", name: '100' }
]

export const ListWorkOrdersContainer = () => {
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })
    const dispatch = useDispatch()
    const pageSize = useSelector((state) => state.pageSize.workOrdersList)
    const { user } = useContext(UserContext)
    const refetchWorkOrdersList = useLocation().state?.reload

    const initialQueryParams = {
        page: 1,
        store_id: user.current_store?.id,
        per_page: pageSize
    }
    const [queryParams, setQueryParams] = useState(initialQueryParams)
    const [filterDropdown, setDropdown] = useState(false);
    const [filtersState, dispatchFilter] = useReducer(filterReducer, INITIAL_STATE)

    const {
        data,
        isLoading,
        isError,
        isPreviousData,
        isFetching,
        error,
        refetch
    } = useQuery(['work_orders_list', queryParams], () => fetchWorkOrders(queryParams),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: true,
            staleTime: DEFAULT_QUERY_STALE_TIME
        })

    useEffect(() => {
        if (refetchWorkOrdersList) refetch()
    },[refetchWorkOrdersList])

    const statusFilters = WORK_ORDER_STATUSES.filter(
        (status) => status.filterable !== false).map((status) => {
            return status.filterable === false
                ? null : { id: status, label: i18n.t(`work_orders.work_order_statuses.${status}.title`) }
        }
        )

    const workOrderFilters = WORK_ORDER_TYPES.filter(
        (type) => type.filterable !== false).map((type) => {
            return type.filterable === false
                ? null : { id: type, label: i18n.t(`work_orders.creation_sections.work_order_type.select_work_order.options.${type}.title`) }
        }
        )

    const headers = [
        { id: 'id', label: i18n.t("work_orders.header.id"), subtitle: i18n.t("work_orders.subheader.id") },
        { id: 'work_order_types', label: i18n.t("work_orders.header.work_order_types"), subtitle: i18n.t("work_orders.subheader.work_order_types") },
        { id: 'statuses', label: i18n.t("work_orders.header.statuses"), subtitle: i18n.t("work_orders.subheader.work_order_types") },
        { id: 'work_order_histories', label: i18n.t("work_orders.header.work_order_histories"), subtitle: i18n.t("work_orders.subheader.work_order_histories") },
    ]

    function hideDropdown(e) {
        if (e.target.id != "btn-dropdown" && filterDropdown) {
            setDropdown(false)
        }
    }

    useEffect(() => {
        document.addEventListener("click", hideDropdown)
        return () => {
            document.removeEventListener("click", hideDropdown)
        }
    })

    useEffect(() => {
        window.analytics.page('Work Order', 'List')
    }, [])


    const handlePaginationChange = (page) => {
        setQueryParams((prev) => ({ ...prev, page: page }))
        dispatch(setWorkOrdersListPageSize(pageSize))
    };

    const handlePageSizeChange = (page) => {
        setQueryParams((prev) => ({ ...prev, per_page: page.id, page: 1 }))
        dispatch(setWorkOrdersListPageSize(page.id))
    }

    const onSearchHandler = (value, filter) => {
        setQueryParams((prev) => ({ ...prev, [filter]: value, page: 1 }))
    }

    const handleOpenFilters = () => {
        setDropdown(!filterDropdown);
    };

    const handleFilterClick = (filter_key) => {
        window.analytics.track('Work Orders - Adds Filter From List', { filter_key: filter_key })
        setDropdown(!filterDropdown)
        dispatchFilter({ type: "OPEN", id: filter_key })
    }

    const handleResetFilter = (filter) => {
        dispatchFilter({ type: "CLOSE", id: filter })
        setQueryParams((prev) => ({ ...prev, [filter]: null, page: 1 }))
    }

    const handleResetFilters = () => {
        dispatchFilter({ type: "RESET" })
        setQueryParams(initialQueryParams)
    }

    const handlerOnClickDetail = (workOrder) => {
        history.push(storePath + SHOW_WORK_ORDER.replace(':id', workOrder?.id))
    }

    useEffect(() => {
        let newSearchParams = { ...queryParams, store_id: user.current_store?.id }
        setQueryParams(newSearchParams)
    }, [user.current_store?.id])

    const renderStatusSection = (workOrder) => {
        let status = workOrder?.status

        switch (status) {
            case REQUESTED:
                return (
                    <div className="max-w-9/10">
                        <div className="inline-block mr-2 w-2 h-2 bg-yellow-300 rounded-full mb-0.5"></div>
                        <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                            {i18n.t(`work_orders.work_order_statuses.${status}.title`)}
                        </div>
                        <div className="mt-3">
                            {i18n.t(`work_orders.work_order_statuses.${status}.description`)}
                        </div>
                    </div>)
            case IN_VALIDATION:
                return (
                    <div className="max-w-9/10">
                        <div className="inline-block mr-2 w-2 h-2 bg-indigo-600 rounded-full mb-0.5"></div>
                        <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                            {i18n.t(`work_orders.work_order_statuses.${status}.title`)}
                        </div>
                        <div className="mt-3">
                            {i18n.t(`work_orders.work_order_statuses.${status}.description`)}
                        </div>
                    </div>)
            case SCHEDULED:
                return (
                    <div className="max-w-9/10">
                        <div className="inline-block mr-2 w-2 h-2 bg-pink-400 rounded-full mb-0.5"></div>
                        <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                            {i18n.t(`work_orders.work_order_statuses.${status}.title`)}
                        </div>
                        <div className='flex-wrap mt-2 gap-x-5 gap-y-2'>
                            <div>{i18n.t(`work_orders.work_order_statuses.${status}.description1`)}</div>
                            {/*<span>{i18n.t(`work_orders.work_order_statuses.${status}.description2`)} ${(workOrder?.operator_quantity * workOrder?.labor_days_quantity) || 0}</span>*/}
                            <div className='mt-2'>
                                * {i18n.t(`work_orders.work_order_statuses.${status}.description3`)}
                            </div>
                        </div>
                    </div>)
            case IN_PROGRESS:
                return (
                    <div className="max-w-9/10">
                        <div className="inline-block mr-2 w-2 h-2 bg-purple-500 rounded-full mb-0.5"></div>
                        <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                            {i18n.t(`work_orders.work_order_statuses.${status}.title`)}
                        </div>
                        <div className='flex flex-row flex-wrap mt-2 gap-x-5 gap-y-2'>
                            {i18n.t(`work_orders.work_order_statuses.${status}.description1`)} {workOrder?.operator_quantity} {i18n.t(`work_orders.work_order_statuses.${status}.description2`)} #{workOrder?.replenishment_references}
                        </div>
                    </div>)
            case COMPLETED:
                return (
                    <div className="max-w-9/10">
                        <div className="inline-block mr-2 w-2 h-2 bg-green-500 rounded-full mb-0.5"></div>
                        <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                            {i18n.t(`work_orders.work_order_statuses.${status}.title`)}
                        </div>
                        <div className='flex-wrap mt-2 gap-x-5 gap-y-2'>
                            <span>{i18n.t(`work_orders.work_order_statuses.${status}.description1`)}. </span>
                            {/*<span className='text-green-700'>{i18n.t(`work_orders.work_order_statuses.${status}.description2`)} ${(workOrder?.operator_quantity * workOrder?.labor_days_quantity) || 0}</span>*/}
                            <div className='mt-2'>
                                {i18n.t(`work_orders.work_order_statuses.${status}.description3`)} #{workOrder?.replenishment_references}
                            </div>
                        </div>
                    </div>)
            case REJECTED:
                return (
                    <div className="max-w-9/10">
                        <div className="inline-block mr-2 w-2 h-2 bg-red-500 rounded-full mb-0.5"></div>
                        <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                            {i18n.t(`work_orders.work_order_statuses.${status}.title`)}
                        </div>
                        <div className="mt-3">
                            {workOrder?.work_order_histories?.filter(history => history.new_status === REJECTED).map(history => history.notes)}
                        </div>
                    </div>)
            default:
                return (<div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                    {workOrder?.status}</div>)
        }
    }

    const renderTimelineSection = (workOrder) => {
        return (
            <div className="relative text-gray-700">
                {workOrder?.work_order_histories.map((workOrderHistory, index) => {
                    return (
                        <TimelineItem key={workOrderHistory.id} lineType={workOrder?.work_order_histories.length === 1 ? LINE_TYPE_NONE : index === 0 ? LINE_TYPE_START : index === workOrder?.work_order_histories.length - 1 ? LINE_TYPE_END : LINE_TYPE_MIDDLE} className="text-lg">
                            {
                                <>
                                    <div className='inline-block mr-1 text-sm text-gray-900'>
                                        {i18n.t(`work_orders.work_order_statuses.${workOrderHistory.new_status}.title`)}
                                    </div>

                                    {workOrderHistory.created_at && <>
                                        <div className="inline-block mr-1 text-sm">
                                            {i18n.t("work_orders.on_date")}&nbsp;
                                            {new Date(workOrderHistory.created_at).toLocaleDateString('en-GB',
                                                {
                                                    year: "2-digit",
                                                    month: "2-digit",
                                                    day: "2-digit"
                                                })
                                            }
                                        </div>

                                    </>}

                                </>
                            }
                        </TimelineItem>
                    )
                })}
            </div>
        )
    }

    const renderWorkOrderTypeSection = (workOrder) => {
        return (
            <div className='max-w-9/10'>
                <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                    {i18n.t(`work_orders.creation_sections.work_order_type.select_work_order.options.${workOrder.work_order_type}.title`)}
                </div>
                <div className="mt-3">
                    {i18n.t(`work_orders.creation_sections.required_material.product_origin.options.${workOrder.product_origin}.title`)}
                </div>
                <div className="mt-1 text-sm">
                    {i18n.t("work_orders.replenishment_id")} #{workOrder.replenishment_references}
                </div>
                {workOrder.order_references &&
                    <div className="mt-1 text-sm">
                        {i18n.t("work_orders.rem_order_id")} #{workOrder.order_references}
                    </div>
                }

            </div>
        )
    }

    const buildBorderColor = (workOrder) => {
        const status = workOrder?.status

        switch (status) {
            case REQUESTED:
                return "bg-yellow-300"
            case IN_VALIDATION:
                return "bg-indigo-600"
            case SCHEDULED:
                return "bg-pink-400"
            case IN_PROGRESS:
                return "bg-purple-500"
            case COMPLETED:
                return "bg-green-500"
            case REJECTED:
                return "bg-red-500"
            default:
                return "bg-red-300"
        }
    }

    const showWorkOrderPanelRef = useRef(null)

    const closeSlidePanel = (ref) => {
        ref.current.closePanel()
        refetch()
    }

    return (
        <>
            <PageView
                topMenu={<PageTopBar>
                    <div className="text-lg font-semibold"><span className="text-gray-400">{user.current_store ? user.current_store.name : i18n.t("work_orders.all_stores")} &rarr; {i18n.t("topMenu.replenishments")}
                        &nbsp;&rarr; </span>{i18n.t("work_orders.work_orders")}</div>
                    <div className="items-center flex">
                        <Button onClick={() => {
                            window.analytics.track('Work Orders - Starts Creating')
                            history.push(storePath + ADD_WORK_ORDER)
                        }}>
                            {i18n.t("work_orders.button")}
                        </Button>
                    </div>
                </PageTopBar>}
                childrenFullWidth={true}
                topMenuFullWidth={true}
            >
                {!isPreviousData && isLoading ? (
                    <><Loader show={true}></Loader></>
                ) : isError ? (
                    <>Error: {error.message}-{isError} - {error}</>
                ) :

                    <>
                        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-full">
                            {/* Filter and paginator section */}
                            <nav className="flex flex-row justify-between items-center rounded-t-lg border-gray-300 border-r border-l border-t bg-white py-2">
                                <div className='flex flex-row pl-1'>
                                    <div className='pl-3 py-1'>
                                        <button id="btn-dropdown" className="border border-gray-300 shadow-sm
                                    rounded-md py-2 px-3 text-gray-700 font-medium hover:bg-gray-50"
                                            onClick={handleOpenFilters}>
                                            <svg className="inline w-5 h-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                                            </svg>
                                            {i18n.t('work_orders.header.filter')}
                                        </button>
                                        {filterDropdown && (
                                            <div className='border absolute z-10 mt-1 shadow divide-y bg-white rounded-md'>
                                                {filtersState.map((item) => (
                                                    <div key={item.id} className="px-2 py-3 cursor-pointer hover:bg-gray-50"
                                                        onClick={() => handleFilterClick(item.id)}>
                                                        {i18n.t(`work_orders.filters.search.${item.id}`)}
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                        }
                                    </div>
                                    {filtersState.some(item => item.open === true) &&
                                        <div className='pl-3 py-1'>
                                            <button className="whitespace-nowrap border shadow-sm rounded-md 
                                        py-2 px-3 text-blue-500 font-medium hover:bg-indigo-700 hover:text-white"
                                                onClick={handleResetFilters}>
                                                {i18n.t('work_orders.filters.reset_filters')}
                                            </button>
                                        </div>}
                                </div>
                                {/* Paginator */}
                                {data?.meta &&
                                    <div className='pr-2'>
                                        <SelectInput
                                            options={pagesInterval}
                                            selectedInitial={pagesInterval.find(option => option.id == queryParams.per_page) ?? pagesInterval[0]}
                                            className="inline-block text-sm px-4 sm:px-0"
                                            onChange={(page) => { handlePageSizeChange(page) }}
                                            value={pagesInterval.find(option => option.id == queryParams.per_page) ?? pagesInterval[0]}
                                        />
                                        <Pagination
                                            className='inline'
                                            size="small"
                                            current={data?.meta.current_page}
                                            total={data?.meta?.total_count}
                                            onChange={handlePaginationChange}
                                            page={data?.meta.current_page}
                                            pageSize={pageSize}
                                            showSizeChanger={false}
                                        />
                                    </div>}
                            </nav>
                            {/* Filters section */}
                            {filtersState.some(item => item.open === true) && (
                                <div className="flex flex-wrap gap-x-3 gap-y-2 px-4 pb-2 border-r border-l 
                                border-gray-300 bg-white text-sm">
                                    {filtersState.map((item) => (
                                        item.open &&
                                        <div key={item.id} className="bg-gray-100 rounded-lg">
                                            <div className="flex items-center m-1.5">
                                                {item.id === "id" && <>
                                                    <SearchInput onChange={(e) => onSearchHandler(e, item.id)} debounce={250}
                                                        className="max-w-10 text-gray-400 mr-1"
                                                        placeholder={i18n.t("work_orders.filters.id")}
                                                        numericOnly
                                                    />
                                                    <XIcon className="flex-shrink-0 cursor-pointer text-gray-400 h-5 w-5"
                                                        onClick={() => handleResetFilter(item.id)} />
                                                </>}
                                                {item.id === "work_order_types" && <>
                                                    <select
                                                        className="mr-1 text-sm border-gray-300 rounded-md leading-5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white text-gray-600"
                                                        onChange={(e) => onSearchHandler(e.target.value, item.id)}>
                                                        <option value={null} disabled={true} selected={true}>{i18n.t("work_orders.filters.work_order_types")}</option>
                                                        {workOrderFilters.map((status, index) => {
                                                            return <option key={index} value={status.id}>{status.label}</option>
                                                        })}
                                                    </select>
                                                    <XIcon className="cursor-pointer text-gray-400 h-5 w-5"
                                                        onClick={() => handleResetFilter(item.id)} />
                                                </>}
                                                {item.id === "statuses" && <>
                                                    <select
                                                        className="mr-1 text-sm border-gray-300 rounded-md leading-5 focus:outline-none focus:ring-2 
                                                        focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white text-gray-600"
                                                        onChange={(e) => onSearchHandler(e.target.value, item.id)}>
                                                        <option value={null} disabled={true} selected={true}>{i18n.t("work_orders.filters.statuses")}</option>
                                                        {statusFilters.map((status, index) => {
                                                            return <option key={index} value={status.id}>{status.label}</option>
                                                        })}
                                                    </select>
                                                    <XIcon className="cursor-pointer text-gray-400 h-5 w-5"
                                                        onClick={() => handleResetFilter(item.id)} />
                                                </>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {/* Header section */}
                            <div className="border border-gray-300 bg-gray-50 flex justify-between px-4 py-2 mb-2">
                                {headers.map((item) => (
                                    <div key={item.id}
                                        className={`${item.id == 'id' || item.id === 'work_order_histories' ?
                                            'w-1/6' : 'w-1/3'} text-left flex flex-col`}>
                                        <span className='font-semibold text-gray-700'>{i18n.t(item.label)}</span>
                                        <span className='text-sm text-gray-500'>{i18n.t(item.subtitle)}</span>
                                    </div>
                                ))}
                            </div>
                            {/* Body section */}
                            <div className="flex flex-col flex-grow mt-5 gap-y-5 relative">
                                <Loader show={isFetching && isPreviousData} className="min-h-10"></Loader>
                                {data?.work_orders.length == 0 && <>
                                    <div className='flex justify-center text-gray-600 bg-gray-50 rounded-lg shadow p-5'>
                                        {i18n.t("work_orders.no_work_orders")}
                                    </div>
                                </>}
                                {data?.work_orders.map((workOrder) => (
                                    <div key={workOrder?.id} onClick={() => handlerOnClickDetail(workOrder)}
                                        className="relative text-gray-500 bg-white rounded-lg shadow hover:bg-gray-100 overflow-hidden cursor-pointer">
                                        <div className={`${buildBorderColor(workOrder)} absolute w-1.5 rounded-l-lg h-full`}></div>
                                        <div className="flex justify-between px-4 py-5">
                                            <div className="text-left w-1/6 pl-1">
                                                <div className='text-gray-700 inline 2xl:text-base max-w-9/10'>
                                                    <div className="inline font-semibold">#{workOrder?.id}</div>
                                                </div>
                                                <div className='mt-2 leading-tight tracking-tight break-words max-w-9/10 pr-1 text-sm'>
                                                    {workOrder?.notes}
                                                </div>
                                            </div>
                                            <div className="text-left w-1/3 text-sm 2xl:text-base">
                                                {renderWorkOrderTypeSection(workOrder)}
                                            </div>
                                            <div className="text-left w-1/3 text-sm 2xl:text-base">
                                                {renderStatusSection(workOrder)}
                                            </div>
                                            <div className="flex flex-col text-left w-1/6 text-sm 2xl:text-base">
                                                {renderTimelineSection(workOrder)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* Footer Navigation */}
                            <div className='mt-5 flex justify-between items-center px-2 rounded-lg border bg-white py-3'>
                                <div className="mx-1">
                                    {i18n.t("work_orders.footer.total")}
                                    <div className="inline mx-1 font-medium font-semibold">
                                        {data?.meta.total_count}
                                    </div>
                                    {i18n.t("work_orders.footer.work_orders")}
                                </div>
                                <div>
                                    <SelectInput
                                        options={pagesInterval}
                                        selectedInitial={pagesInterval.find(option => option.id == queryParams.per_page) ?? pagesInterval[0]}
                                        className="inline-block text-sm px-4 sm:px-0"
                                        onChange={(page) => { handlePageSizeChange(page) }}
                                        value={pagesInterval.find(option => option.id == queryParams.per_page) ?? pagesInterval[0]}
                                    />
                                    <Pagination
                                        className='inline'
                                        size="small"
                                        current={data?.meta.current_page}
                                        total={data?.meta?.total_count}
                                        onChange={handlePaginationChange}
                                        page={data?.meta.current_page}
                                        pageSize={pageSize}
                                        showSizeChanger={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </PageView>
            <Switch>
                <Route exact path={STORE_PATH + SHOW_WORK_ORDER}>
                    <SlidePanel title={i18n.t("work_orders.work_order_detail.title")} referrer={storePath + WORK_ORDERS}>
                        <WorkOrderDetailContainer onClose={() => closeSlidePanel(showWorkOrderPanelRef)} />
                    </SlidePanel>
                </Route>
            </Switch>
        </>
    )
}