import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { IconButton } from "../../../components/IconButton"
import { CodeFileIcon } from "../../../components/Icons/CodeFileIcon"
import { DownloadIcon } from "../../../components/Icons/DownloadIcon"
import { PdfFileIcon } from "../../../components/Icons/PdfFileIcon"

export const OrderInvoice = ({ invoiceKey, xmlUrl, pdfUrl, invoiceLabel }) => {
    const { t } = useTranslation()

    const containerRef = useRef(null)

    const handleOpenXML = () => {
        const win = window.open(xmlUrl, "_blank")
        if (win != null) {
            win.focus()
        }
    }
    const handleOpenPDF = () => {
        const win = window.open(pdfUrl, "_blank")
        if (win != null) {
            win.focus()
        }
    }

    return (
        <div className="" ref={containerRef}>
            <div className="flex items-center">
                <div>
                    <PdfFileIcon className="w-5 h-5" />
                </div>
                <div className="flex-grow">
                    <div className="ml-2 capitalize">{invoiceLabel?.toLowerCase()}</div>
                    <div className="ml-2 font-semibold">{invoiceKey}</div>
                </div>
                <div className="ml-2">
                    <IconButton onClick={() => handleOpenPDF()} icon={<DownloadIcon className="w-4 h-4" />} tooltipTitle={t("files.open")+" PDF"} />
                </div>
                <div className="ml-2">
                    <IconButton onClick={() => handleOpenXML()} icon={<CodeFileIcon className="w-4 h-4" />} tooltipTitle={t("files.open")+" XML"} />
                </div>
            </div>
        </div>
    )
}
