/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useContext, useEffect, useRef, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {ArrowDownIcon, ArrowRightIcon, CheckIcon} from '@heroicons/react/outline'
import {useQuery} from "react-query";
import {fetchProducts} from "../../services/productServices";
import {BasicTable} from "../BasicTable/BasicTable";
import {getSkus} from "../utils/productUtils";
import { Loader } from '../Loader';
import { SearchInput } from '../SearchInput';
import { ExpandibleRow } from '../BasicTable/ExpandibleRow';
import {UserContext} from "../../hooks/UserContext";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { setProductListPageSize } from "../../redux/pageSizeSlice"


export default function SelectProductDialog({open, setOpen, onAddProducts = () => {}, queryExtraParams = {}}) {
    const { t, i18n} = useTranslation();
    const {user} = useContext(UserContext)
    const cancelButtonRef = useRef(null)
    const pageSize = useSelector((state) => state.pageSize.productList);
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useState({
        page: 1,
        include_stock: true,
        per_page: pageSize,
        store_id: user.current_store?.id,
        ...queryExtraParams
    })

    useEffect(() => {
        setSearchParams({
            ...searchParams,
            ...queryExtraParams
        })
    }, [queryExtraParams])

    const getTableHeader = () => {
        if(searchParams.filter_unavailable)
            return t(`orders.productTable.${searchParams.filter_unavailable}`)
        else return t("orders.productTable.available")
    }

    const getTableAccessor = () => {
        if(searchParams.filter_unavailable) 
            return "stock." + searchParams.filter_unavailable.toUpperCase()
        else return "stock.AVAILABLE"
    }

    const [selectedProducts, setSelectedProducts] = useState([])

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData
    } = useQuery(['products_select', searchParams], () => fetchProducts(searchParams), { keepPreviousData: true }) 

    const columns = [
        {
            // Build our expander column
            id: 'expander', // Make sure it has an ID
            Header: "orders.selectProductDialog.name",
            accessor: 'name_with_tags',
            disableFilters: true,
            disableSortBy: true,
            disableWrap: true,
            Cell: ({ value, row }) =>
                // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                // to build the toggle for expanding a row
                row.canExpand ? (
                    <ExpandibleRow text={value} expanded={row.isExpanded} {...row.getToggleRowExpandedProps()}/>
                ) : (value)
        },
        {
            Header: "orders.selectProductDialog.sku",
            accessor: 'sku',
            disableFilters: true,
            disableSortBy: true,
            shrinkToContent: true
        },
        {
            Header: getTableHeader(),
            accessor: getTableAccessor(),
            disableFilters: true,
            disableSortBy: false,
        }
    ]    

    const onSortChange = (orderBy) => {
        // console.log("ON_ORDER_BY",orderBy)
        if (orderBy.length > 0) {
            setSearchParams({
                ...searchParams,
                order_by: orderBy[0].id, 
                order: orderBy[0].desc ? 'desc' : 'asc',
                page: 1
            })
        }
        else {
            if ('order_by' in searchParams) {
                delete searchParams.order_by
                delete searchParams.order
                setSearchParams({...searchParams, page: 1})
            }
        }
        console.log("tableRefSPD", tableRef);
        tableRef.current.resetPagination()
    }

    const handlerOnAddProduct = () => {
        onAddProducts(selectedProducts)
    }

    const onSearchHandler = (v) => {
        tableRef.current.resetSelection()
        tableRef.current.resetPagination()
        setSearchParams({
            ...searchParams,
            page: 1,
            search: v
        })
    }

    const tableRef = useRef()

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={setOpen}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">

                            <div className="sm:col-span-6">
                                <div className="mt-1">
                                    <SearchInput onChange = {onSearchHandler} debounce={250} className="focus-within:text-gray-600 text-gray-400 "/>
                                </div>
                            </div>

                            <div className="overflow-auto py-4" style={{maxHeight:'60vh'}}>
                            {!isPreviousData && isLoading ? (
                                <><Loader show={true}></Loader></>
                            ) : isError ? (
                                <>Error: {error.message}</>
                                ) : (
                                    <BasicTable
                                        labelToPaginator={i18n.t("orders.selectProductDialog.products")}
                                        selectable
                                        sortable
                                        columns={columns}
                                        onSortChange={onSortChange}
                                        data={data.products.map(product => {
                                            return {
                                                id: product.id,
                                                name: product.name,
                                                name_with_tags: (
                                                    <div className="flex flex-col gap-2 max-w-max">
                                                        {product.name}

                                                        {product.is_kit && (
                                                            <span className={"m-0 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-50 text-green-500 max-w-max"}>
                                                                {i18n.t("inventory.listProducts.kit")}
                                                            </span>
                                                        )}
                                                    </div>
                                                ),
                                                product_image_path: product.product_image_path,
                                                stock: 
                                                {AVAILABLE: 
                                                    product.stock.AVAILABLE <= 0 ? 
                                                    <>
                                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ml-2 bg-red-100 text-red-800">Sin stock</span>
                                                    </>
                                                    :
                                                    <>
                                                        <span className="inline-flex items-center ml-10">{product.stock?.AVAILABLE}</span>
                                                    </>,
                                                AVAILABLE_STOCK:
                                                    product.stock?.AVAILABLE,
                                                DAMAGED:
                                                    product.stock?.DAMAGED,
                                                EXPIRED:
                                                    product.stock?.EXPIRED,
                                                },
                                                sku: getSkus(product.product_codes),
                                                product_codes: product.product_codes,
                                                price: product.price == null ? "" : product.price,
                                                parent: product.parent,
                                                isKit: product.is_kit,
                                                is_scannable: product.is_scannable,
                                                kit_childs: product.kit_childs
                                            }
                                        })}
                                        onSelectionChange={(rows) => {
                                            setSelectedProducts(rows)
                                            // console.log("rows", rows)
                                        }}
                                        showHeader
                                        showPaginator
                                        onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
                                        onPageSizeChange= { (pageSize) => { 
                                            setSearchParams({...searchParams, per_page: pageSize, page:1})
                                            dispatch(setProductListPageSize(pageSize))
                                            tableRef.current?.resetPagination() 
                                        } } 
                                        pagesSize = {searchParams.per_page}
                                        paginationMeta={data.meta}
                                        showCount = {true}
                                        showLoader={isFetching && isPreviousData}
                                        emptyTableText={i18n.t("orders.selectProductDialog.no_orders_to_show")}
                                        ref={tableRef}
                                    />
                                )}
                            </div>


                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                
                                <button
                                    type="button"
                                    className="text-base w-full inline-flex justify-center rounded-md border border-gray-300  shadow-sm px-4 py-2 bg-white text-base font-medium  hover:bg-gray-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-1 sm:text-sm"
                                    onClick={() => setOpen(false)}
                                >
                                    {i18n.t("orders.selectProductDialog.cancel")}
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-white font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2 sm:text-sm"
                                    onClick={handlerOnAddProduct}
                                    ref={cancelButtonRef}
                                >
                                    {i18n.t("orders.selectProductDialog.add")} ({selectedProducts.length})
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}