import { useTranslation } from 'react-i18next';
import { FetchingIndicator } from '../../BasicTable/modules/FetchingIndicator';

export const NewTableHeader = ({headerGroups, isFetching = false, selectable = false}) => {
   const { i18n } = useTranslation();
   
   return (
    headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, index) => (
            <th className={"bg-gray-50 px-5 py-1 text-left text-sm whitespace-nowrap tracking-wider" + (column.shrinkToContent || (selectable && index === 0) ? " w-0" : "")}
            {...column.getHeaderProps(column.getSortByToggleProps())}>
              <div className="flex items-center tracking-tight font-bold text-blue-900 uppercase">
                {column.fetchingIndicator ? <FetchingIndicator isFetching={isFetching}/> : 
                <div className='mt-1'>
                  {selectable && index === 0 ? column.render('Header') : i18n.t(column.render('Header'))}
                  {/* Add a sort direction indicator */}
                  {column.canSort &&
                    <span className="pl-2 inline-block">
                        {column.isSorted
                        ? column.isSortedDesc
                            ? <svg className="w-4 h-4 mb-0.5 inline-block" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.2222 18.75V6M12.2222 6L8 10.25M12.2222 6L16.4444 10.25" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                            : <svg className="w-4 h-4 mb-0.5 inline-block" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.2221 6L12.2221 18.75M12.2221 18.75L16.4443 14.5M12.2221 18.75L7.99989 14.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                        :  <svg className="w-4 h-4 mb-0.5 inline-block"  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.22222 16.75V4M7.22222 4L3 8.25M7.22222 4L11.4444 8.25M17.7778 8.25V21M17.7778 21L22 16.75M17.7778 21L13.5556 16.75" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                        }
                    </span>
                  }
                  {column.description &&
                  <div className="w-full text-gray-500 font-normal normal-case">
                    {i18n.t(column.render('description'))}
                  </div>
                  }
                </div>
                }
              </div>
            </th>
          ))}
        </tr>
      ))
   )
}