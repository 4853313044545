import { useQuery } from "react-query"
import { MetricPercentage } from "../../../../components/MetricsCards/MetricPercentage"
import { getPickingAccuracyTotal } from "../../../../services/storeMetricsService"
import { numberToString } from "../../../../helpers/internationalization"
import { useTranslation } from "react-i18next"
import { useMemo } from "react"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"
import { DEFAULT_QUERY_STALE_TIME } from "../chartDataUtils"

export const PickingAccuracyPercentage = ({ storeId = null, dateFilter = null }) => {
    const { i18n, t } = useTranslation()

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["picking_accuracy_total", storeId, dateFilter], () => getPickingAccuracyTotal(storeId, dateFilter), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refech if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    const ordersPickedCorrectly = useMemo(() => {
        if (!data) return 0
        const { correct_picking } = data
        return correct_picking
    }, [data])

    const ordersPickedIncorrectly = useMemo(() => {
        if (!data) return 0
        const { incorrect_picking } = data
        return incorrect_picking
    }, [data])

    const totalOrders = useMemo(() => {
        const total = ordersPickedCorrectly + ordersPickedIncorrectly
        return total
    }, [ordersPickedCorrectly, ordersPickedIncorrectly])

    return (
        <div>
            <MetricTitle title={t('metrics.picking_accuracy.title')} className={"mb-1"}/>
            <MetricPercentage
                value={ordersPickedCorrectly}
                total={totalOrders}
                showLoader={isLoading}
                valueFormatter={(n) => numberToString(n, i18n.language)}
                totalLabel={t("metrics.generic.shipped")}
            />
        </div>
    )
}
