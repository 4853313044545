import {installMeliStore} from "../../services"
import i18n from "../../translations";
import {ButtonLoader} from "../../components";
import {useContext, useState} from "react";
import {UserContext} from "../../hooks/UserContext";

export const MeliIntegration = () => {
    const {user} = useContext(UserContext)

    const [loadingButton, setLoadingButton] = useState(false)

    const handleSubmit = async () => {
        setLoadingButton(true)

        const response = await installMeliStore(user.current_store?.id)

        if (response?.data?.auth_url)
            window.open(response.data.auth_url, "_self")
        else
            alert (i18n.t("integration.meli.Error_creating_integration"))

        setLoadingButton(false)
    }

    return (
        <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-10">
            <div className="relative max-w-xl mx-auto">
                <svg
                    className="absolute left-full transform translate-x-1/2"
                    width={404}
                    height={404}
                    fill="none"
                    viewBox="0 0 404 404"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="85737c0e-0916-41d7-917f-596dc7edfa27"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"/>
                </svg>
                <svg
                    className="absolute right-full bottom-0 transform -translate-x-1/2"
                    width={404}
                    height={404}
                    fill="none"
                    viewBox="0 0 404 404"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="85737c0e-0916-41d7-917f-596dc7edfa27"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"/>
                </svg>
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">{i18n.t("integration.meli.integrate_meli")}</h2>
                    <p className="mt-4 text-lg leading-6 text-gray-500">
                        {i18n.t("integration.meli.authorize_cubbo")}
                    </p>
                </div>
                <div className="mt-12">
                    <div className="sm:col-span-2">
                        <ButtonLoader
                            className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            loading={loadingButton}
                            onClick={() => handleSubmit()}
                            disabled={loadingButton}
                        >
                            {i18n.t("integration.meli.go_to_meli")}
                        </ButtonLoader>
                    </div>
                </div>
            </div>
        </div>
    )
}