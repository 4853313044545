import { useMemo } from "react"
import { getOrdersOriginTotal } from "../../../../services/storeMetricsService"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { numberToString } from "../../../../helpers/internationalization"
import { capitalize } from "../../../../utils/StringUtils"
import { BarList } from "../../../../components/MetricsCards/BarList"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"
import { DEFAULT_QUERY_STALE_TIME } from "../chartDataUtils"


export const OrdersSalesChannel = ({ storeId = null, dateFilter = null }) => {
    const { i18n, t } = useTranslation()

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["orders_origin_total", storeId, dateFilter], () => getOrdersOriginTotal(storeId, dateFilter), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    const orderOrigins = useMemo(() => {
        if (!data) return []
        let newOrderOrigins = []
        Object.keys(data).forEach((key) => {
            const name = key === 'manual' ? t("metrics.orders_sales_channel_historic.created_at_cubbo") : capitalize(key)
            newOrderOrigins.push({
                key: key,
                value: data[key],
                name: name,
            })
        })
        return newOrderOrigins
    }, [data, i18n.language])

    const totalOrders = useMemo(() => {
        const total = orderOrigins.reduce((acc, item) => acc + item.value, 0)
        return numberToString(total, i18n.language)
    }, [orderOrigins, i18n.language])

    return (
        <div className="h-fit">
            <MetricTitle title={t("metrics.orders_by_sales_channel_total.title")}/>
            <BarList
                mainColumnLabel={t("metrics.orders_by_sales_channel_total.main_column_label")}
                secondaryColumnLabel={t("metrics.orders_by_sales_channel_total.secondary_column_label")}
                data={orderOrigins}
                valueFormatter={(n) => numberToString(n, i18n.language)}
                isLoading={isLoading}
                maxRowsLabel={t("metrics.orders_by_sales_channel_total.grouped_rows_label")}
            />
        </div>
    )
}
