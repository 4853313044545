import { ORDER_RULE_TYPE_ACTIONS, ALL_AVAILABLE_ACTION_OPTIONS, AVAILABLE_PRODUCT_INSERT_OPTIONS } from "../../utils/businessRuleActionUtils";

const ruleActionBuilder = (actionSettings) => {
    let ruleAction = null

    switch (actionSettings.actionOption) {
        case ALL_AVAILABLE_ACTION_OPTIONS.ADD_SHIPPING_PACKAGE_OPTION:
            {
                ruleAction = ORDER_RULE_TYPE_ACTIONS.ENSURE_ORDER_LINE_RULE_ACTION
                break
            }
        case ALL_AVAILABLE_ACTION_OPTIONS.ADD_INSERT_OPTION:
        case ALL_AVAILABLE_ACTION_OPTIONS.ADD_PRODUCT_OPTION:
            {
                if(actionSettings.productInsertOption === AVAILABLE_PRODUCT_INSERT_OPTIONS.ENSURE_PRODUCT){
                    ruleAction = ORDER_RULE_TYPE_ACTIONS.ENSURE_ORDER_LINE_RULE_ACTION
                } else if(actionSettings.productInsertOption === AVAILABLE_PRODUCT_INSERT_OPTIONS.INCLUDE_PRODUCT){
                    ruleAction = ORDER_RULE_TYPE_ACTIONS.INCLUDE_ORDER_LINE_RULE_ACTION
                }
                break
            }

        case ALL_AVAILABLE_ACTION_OPTIONS.PAUSE_ORDER_OPTION:
            {
                ruleAction = ORDER_RULE_TYPE_ACTIONS.HOLD_ORDER_RULE_ACTION
                break
            }

        case ALL_AVAILABLE_ACTION_OPTIONS.CANCEL_ORDER_OPTION:
            {
                ruleAction = ORDER_RULE_TYPE_ACTIONS.CANCEL_ORDER_RULE_ACTION
                break
            }

        default:
            break
    }

    if(!ruleAction){
        throw new Error()
    }

    return ruleAction
}

export default ruleActionBuilder
