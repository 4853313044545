import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

import ConditionTemplateOption from "./ConditionTemplateOption";

const ConditionTemplateSelector = ({availableOptions, onSelectConditionTemplate}) => {
    const { t } = useTranslation()
    const containerRef = useRef(null)
    const [showOptions, setShowOptions] = useState(false)

    const conditionTypeOptions = useMemo(() => (
        Object.values(availableOptions).map((conditionTemplate) => ({
            value: conditionTemplate,
            optionTextWithTags: t(`settings.business_rule_creator.business_rule_statement_settings.statement_options.${conditionTemplate}.title`)
        }))
    ), [availableOptions, t])

    useEffect(() => {
        const validateCurrentClick = (event) => {
            if(!containerRef.current?.contains(event.target)){
                setShowOptions(false)
            }
        }

        document.addEventListener("click", validateCurrentClick)

        return () => {
            document.removeEventListener("click", validateCurrentClick)
        }
    }, [containerRef])

    const handleOnSelectConditionTemplate = (selectedConditionTemplate) => {
        setShowOptions(false)
        onSelectConditionTemplate(selectedConditionTemplate)
    }

    return (
        <div ref={containerRef} className="cursor-pointer w-10/12">
            <div
                className="border rounded w-full p-2 relative bg-white hover:bg-gray-100 shadow-sm text-gray-500 flex justify-between items-center"
                onClick={() => setShowOptions(!showOptions)}
            >
                <div>
                    {t("settings.business_rule_creator.business_rule_additional_statement_condition.options_placeholder")}
                </div>

                {showOptions ? (
                    <ChevronUpIcon className="h-4 w-4" />
                ) : (
                    <ChevronDownIcon className="h-4 w-4" />
                )}
            </div>

            {showOptions && (
                <div className="absolute border rounded flex flex-col bg-white mt-2 w-3/4 z-10">
                    {conditionTypeOptions.map((conditionTypeOption) => (
                        <ConditionTemplateOption
                            optionTextWithTags={conditionTypeOption.optionTextWithTags}
                            key={`condition-template-option-${conditionTypeOption.value}`}
                            onSelectedOption={() => handleOnSelectConditionTemplate(conditionTypeOption.value)}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default ConditionTemplateSelector