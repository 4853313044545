import { Route, Redirect } from "react-router-dom"
import { useContext } from "react"
import { UserContext } from "../../hooks/UserContext"

const PrivateRoute = (props) => {
    const { user, isLoading } = useContext(UserContext)
    const { children, ...rest } = props

    const query_string = props.location.search

    if (isLoading) {
        return <></>
    }

    if (user?.isLogged) {
        return <Route {...rest}>{children}</Route>
    }

    return <Redirect to={"/login"+query_string} />
}

export default PrivateRoute
