import { useEffect, useState } from "react"
import { DEFAULT_QUERY_STALE_TIME, INTERVAL_DAY, capitalizeCategories, formatDates, parseDates, popCategories, replaceCategories } from "../chartDataUtils"
import { useTranslation } from "react-i18next"
import { getOrderOriginHistoric } from "../../../../services/storeMetricsService"
import { useQuery } from "react-query"
import { numberToString } from "../../../../helpers/internationalization"
import { AreaChart } from "../../../../components/MetricsCards/AreaChart"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"

export const OrdersSalesChannelHistoric = ({ storeId = null, dateFilter = null, interval = INTERVAL_DAY }) => {
    const { i18n, t } = useTranslation()

    const [formattedData, setFormattedData] = useState([])
    const [categories, setCategories] = useState([])

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["order_origin_historic", storeId, dateFilter, interval], () => getOrderOriginHistoric(storeId, dateFilter, interval), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to && !!interval,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    useEffect(() => {
        const formatData = async (data) => {
            // [{truncated_date:a, categories: {b => c, d => f}}]
            const dataParsedDates = parseDates(data)
            let [dataCapitalizedCategories, uniqueCategories] = capitalizeCategories(dataParsedDates)
            const dataReplacedCategories = replaceCategories(dataCapitalizedCategories, { Manual: t("metrics.orders_sales_channel_historic.created_at_cubbo") }, "categories")
            uniqueCategories = uniqueCategories.map((category) => {
                if (category === "Manual") return t("metrics.orders_sales_channel_historic.created_at_cubbo")
                return category
            })
            setCategories(uniqueCategories)
            const dataWithZeroes = fillDatapointsWithZeroes(dataReplacedCategories, uniqueCategories)
            const flattenedData = popCategories(dataWithZeroes)
            const formattedData = formatDates(flattenedData, interval, i18n.language)
            setFormattedData(formattedData)
            return null
        }
        formatData(data).catch((error) => {
            console.error(error)
        })
    }, [data, interval, i18n.language])

    return (
        <>
            <MetricTitle className={"lg:ml-3"} title={t("metrics.orders_sales_channel_historic.title")}/>
            <AreaChart
                categories={categories}
                data={formattedData}
                valueFormatter={(n) => numberToString(n, i18n.language)}
                areaChartProps={{
                    showLegend: true,
                    showGridLines: false,
                    curveType: "monotone",
                    // noDataText: "No hay pedidos enviados en este periodo",
                    className: "h-60",
                }}
                isLoading={isLoading}
            />
        </>
    )
}

const fillDatapointsWithZeroes = (data, allCategories) => {
    const datapointsWithZeroes = []
    data.forEach((datapoint) => {
        const datapointCategories = Object.keys(datapoint.categories)
        const datapointCategoriesWithZeroes = allCategories.reduce((acc, category) => {
            if (datapointCategories.includes(category)) {
                acc[category] = datapoint.categories[category]
            } else {
                acc[category] = 0
            }
            return acc
        }, {})
        let { categories, ...newDatapoint } = { ...datapoint }
        datapointsWithZeroes.push({ ...newDatapoint, categories: datapointCategoriesWithZeroes })
    })
    return datapointsWithZeroes
}
