import { useTranslation } from "react-i18next";
import { TrashIcon } from "@heroicons/react/outline";

import statementTemplateGenerator from "../../utils/statementTemplateGenerator";
import { ConditionTemplateSelector } from "../../components/ConditionTemplateSelector";
import { AVAILABLE_RULE_STATEMENTS } from "../../utils/businessRuleStatementUtils";

const AdditionalConditionSelector = ({availableOptions, conditionType, onDeleteCondition, onSelectStatementOption}) => {
    const { t } = useTranslation()

    const handleOnSelectStatementOption = (selectedStatementOption) => {
        const defaultConditions = statementTemplateGenerator(selectedStatementOption, {conditionType: conditionType})
        onSelectStatementOption({statement: selectedStatementOption, conditions: defaultConditions})

        switch(selectedStatementOption){
            case AVAILABLE_RULE_STATEMENTS.ALL_ORDERS:
                window.analytics.track("Business Rules - Selects condition - In every order")
                break

            case AVAILABLE_RULE_STATEMENTS.IS_FIRST_TIME_BUYER:
                window.analytics.track("Business Rules - Selects condition - From new buyer")
                break

            case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_WITH_MIN_QUANTITY:
                window.analytics.track("Business Rules - Selects condition - Order contains at least")
                break

            case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY:
                window.analytics.track("Business Rules - Selects condition - Order contains from/to")
                break

            case AVAILABLE_RULE_STATEMENTS.FOR_EVERY_PRODUCT:
                window.analytics.track("Business Rules - Selects condition - Per every amount")
                break

            case AVAILABLE_RULE_STATEMENTS.FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT:
                window.analytics.track("Business Rules - Selects condition - Per every amount, only if...")
                break

            case AVAILABLE_RULE_STATEMENTS.NOT_INCLUDING_SPECIFIED_PRODUCT:
                window.analytics.track("Business Rules - Selects condition - Includes anything but something specific")
                break

            case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT:
                window.analytics.track("Business Rules - Selects condition - Order contains at least and not including at least")
                break

            case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE:
                window.analytics.track("Business Rules - Selects condition - Includes exactly something and nothing else")
                break

            case AVAILABLE_RULE_STATEMENTS.SPECIFIED_PRODUCT_EXACT_QUANTITY:
                window.analytics.track("Business Rules - Selects condition - Includes exactly something and something else")
                break

            case AVAILABLE_RULE_STATEMENTS.CUSTOM_STATEMENT:
                window.analytics.track("Business Rules - Selects condition - Create personalized condition")
                break

            case AVAILABLE_RULE_STATEMENTS.FRAUD_ORDER_DETECTED:
                window.analytics.track("Business Rules - Selects condition - Detected as fraud")
                break

            case AVAILABLE_RULE_STATEMENTS.HAS_FRAGILE_ITEMS:
                window.analytics.track("Business Rules - Selects condition - Includes fragile products")
                break

            default:
                break
        }
    }

    return (
        <div className="flex flex-col border overflow-hidden rounded w-full">

            <div className="bg-gray-100 p-5 flex justify-between">
                <div>
                    {t("settings.business_rule_creator.business_rule_additional_statement_condition.title")}
                </div>

                <div className="h-6 text-gray-600">
                    <TrashIcon className="h-full cursor-pointer hover:text-gray-400" onClick={onDeleteCondition} />
                </div>
            </div>


            <div className="m-5">
                <ConditionTemplateSelector
                    availableOptions={availableOptions}
                    onSelectConditionTemplate={handleOnSelectStatementOption}
                />
            </div>
        </div>
    )
}

export default AdditionalConditionSelector