import React, { useEffect, useMemo, useState } from 'react'
import BottomFixedOverlay from '../BottomFixedOverlay'

import { useTranslation } from 'react-i18next'
import { ACTIONS } from './OrderActionModal'
import PauseCircleIcon from '../Icons/PauseCircleIcon'
import CancelCircleIcon from '../Icons/CancelCircleIcon'
import RefreshIcon from '../Icons/RefreshIcon'
import CurrencyCircleIcon from '../Icons/CurrencyCircleIcon'

const NOT_CANCELLABLE_STATUSES = ['canceled', 'interrupted', 'shipped', 'returning', 'returned']
const HOLDABLE_STATUSES = ['unpaid', 'empty', 'error', 'cancelation_window', 'entirely_dropshipping', 'backorder', 'pending']
const UNHOLDABLE_STATUSES = ['hold']

const TAB_SELECT_ACTION = 'SELECT_ACTION'

const OrderMultiselectionBar = ({
  selectedOrders,
  onDeselectOrders = () => {},
  handleActionClick = () => {},
  enableCancelOrders = true,
  enableHoldOrders = true,
  enableUnholdOrders = true,
  enableMarkAsPayed = true,
  ...props
}) => {
  const { i18n } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [activeTab, setActiveTab] = useState(TAB_SELECT_ACTION)

  useEffect(() => {
    if (!selectedOrders) return
    if (selectedOrders.length > 0) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [selectedOrders])

  useEffect(() => {
    if (visible) {
      setActiveTab(TAB_SELECT_ACTION)
    }
  }, [visible])

  const ActiveTab = () => {
    switch (activeTab) {
      case TAB_SELECT_ACTION:
        return <SelectActionTab />
      default:
        return <></>
    }
  }

  const showCancelButton = useMemo(() => selectedOrders.some(order => !NOT_CANCELLABLE_STATUSES.includes(order.status)), [selectedOrders])
  const showHoldButton = useMemo(() => selectedOrders.some(order => HOLDABLE_STATUSES.includes(order.status)), [selectedOrders])
  const showUnholdButton = useMemo(() => selectedOrders.some(order => UNHOLDABLE_STATUSES.includes(order.status)), [selectedOrders])

  const SelectActionTab = () => {
    return (
      <>
        <div className="flex items-center text-sm 4 gap-x-3 gap-y-2 whitespace-nowrap flex-wrap md:flex-nowrap">
          <span className="font-bold text-gray-800 lowercase">
            {selectedOrders.length} <span className="font-normal">{i18n.t('orders.action_bar.orders_selected')}</span>
          </span>
          <div className='flex gap-2 flex-wrap md:flex-nowrap'>
            {enableCancelOrders && showCancelButton && (
              <button className={`flex gap-x-1 rounded-md bg-white border border-gray-300 shadow-sm py-2 px-1.5 outline-none hover:bg-gray-50 hover:border-blue-400`} 
                onClick={() => {
                  window.analytics.track('Orders - Cancels in Bulk')
                  handleActionClick(ACTIONS.CANCEL_ORDERS)
                }}>
                <CancelCircleIcon className="h-5 w-5" />
                {i18n.t(`orders.action_bar.actions.${ACTIONS.CANCEL_ORDERS}`)}
              </button>
            )}
            {enableHoldOrders && showHoldButton && (
              <button className={`flex gap-x-1 rounded-md bg-white border border-gray-300 shadow-sm py-2 px-1.5 outline-none hover:bg-gray-50 hover:border-blue-400`}
                onClick={() => {
                  window.analytics.track('Orders - Pauses in Bulk')
                  handleActionClick(ACTIONS.HOLD_ORDERS)
                }}>
                <PauseCircleIcon className="h-5 w-5" />
                {i18n.t(`orders.action_bar.actions.${ACTIONS.HOLD_ORDERS}`)}
              </button>
            )}
            {enableUnholdOrders && showUnholdButton && (
              <button className={`flex gap-x-1 rounded-md bg-white border border-gray-300 shadow-sm py-2 px-1.5 outline-none hover:bg-gray-50 hover:border-blue-400`}
                onClick={() => {
                  window.analytics.track('Orders - Unpauses in Bulk')
                  handleActionClick(ACTIONS.UNHOLD_ORDERS)
                }}>
                <RefreshIcon className="h-5 w-5" />
                {i18n.t(`orders.action_bar.actions.${ACTIONS.UNHOLD_ORDERS}`)}
              </button>
            )}
            {enableMarkAsPayed && (
              <button className={`flex gap-x-1 rounded-md bg-white border border-gray-300 shadow-sm py-2 px-1.5 outline-none hover:bg-gray-50 hover:border-blue-400`}
                onClick={() => {
                  window.analytics.track('Orders - Marks as Paid In Bulk')
                  handleActionClick(ACTIONS.MARK_AS_PAYED)
                }}>
                <CurrencyCircleIcon className="h-5 w-5" />
                {i18n.t(`orders.action_bar.actions.${ACTIONS.MARK_AS_PAYED}`)}
              </button>
            )}
          </div>
          <span className="cursor-pointer text-blue-500 hover:text-blue-300" onClick={() => onDeselectOrders()}>{i18n.t('orders.action_bar.deselect_all_orders')}</span>
        </div>
      </>
    )
  }

  return (
    <BottomFixedOverlay centered visible={visible}>
      <div className={`shadow-md rounded rounded-lg border border-blue-300 shadow-xl bg-gray-100 px-4 py-4`}>
          <ActiveTab />
      </div>
    </BottomFixedOverlay>
  )
}

export default OrderMultiselectionBar