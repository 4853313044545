import i18n from "../../../translations";
import {Button} from "../../../components";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import {ADD_ORDER, DUPLICATE_ORDER, STORE_PATH} from "../../../navigation/constants";

const TextSection = function (
    {
        title,
        subtitle,
        text,
        textList
    }) {
    return (
        <div>
            <div className="text-lg font-medium text-gray-900">{title}</div>
            {subtitle && <div className="text-base text-gray-700">{subtitle}</div>}
            {text && <div className="text-base text-gray-700">{text}</div>}
            {textList &&
                <div className="text-base text-gray-700 mt-2 pl-4">
                    <ul className="list-disc space-y-1">
                        {textList.map(listElement => <li key={listElement}>{listElement}</li>)}
                    </ul>
                </div>
            }
        </div>
    )
}

const CreatedOrderRemoveStockSuccess = (
    {order_number}) =>
    <div className="space-y-10 ">
        <TextSection
            title={i18n.t('orders.remove_stock.create.title')}
            subtitle={<label>{i18n.t('orders.remove_stock.create.subtitle')} <b>#{order_number}</b></label>}
        />
        <ul className='list-disc space-y-1 pl-5'>
            <li>
                <TextSection
                    title={i18n.t('orders.remove_stock.create.when_pickup.title')}
                    subtitle={i18n.t('orders.remove_stock.create.when_pickup.subtitle')}
                    text={<label>{i18n.t('orders.remove_stock.create.when_pickup.information')} <a href="https://cubbohelp.zendesk.com/hc/es/articles/4426756589453-SLAs-CUBBO" target="_blank">{i18n.t('orders.remove_stock.create.when_pickup.link')}</a></label>}
                />
            </li>
            <li>
                <TextSection
                    title={i18n.t('orders.remove_stock.create.when_is_ready.title')}
                    subtitle={<label>{i18n.t('orders.remove_stock.create.when_is_ready.subtitle')} <b>{i18n.t('orders.remove_stock.create.when_is_ready.information')}</b></label>}
                />
            </li>
            <li>
                <TextSection
                    title={i18n.t('orders.remove_stock.create.make_appointment.title')}
                    subtitle={i18n.t('orders.remove_stock.create.make_appointment.subtitle')}
                />  
            </li>
        </ul>
        
        <TextSection
            text={i18n.t('orders.remove_stock.create.send_information.title')}
        />
    </div>

export const RemoveStockCreatedContainer = function ({ order_number = null}) {
    const { order_id } = useParams()
    const history = useHistory()
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })

    return (
        <>
            <div className="space-y-8 divide-y divide-gray-200">
                    <CreatedOrderRemoveStockSuccess order_number={order_number}/>
            </div>
        </>
    )
}