import { useEffect, useMemo, useState } from "react";
import { PencilIcon, TrashIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";

import { ForEveryProductCondition, CustomCondition, useConditionValidator } from "./Conditions";
import { CONDITION_VALIDATION_TYPES, AVAILABLE_RULE_STATEMENTS, CUSTOM_STATEMENT_QUANTITIES, CUSTOM_STATEMENT_VALIDATIONS } from "../../utils/businessRuleStatementUtils";
import { TextWithTags } from "../../components";
import { Button } from "../../../../../components";
import CustomChannelCondition from "./Conditions/CustomChannelCondition";
import { capitalizeFirstLetter } from "../../../../../utils/StringUtils";

const StatementCondition = ({conditionContent, onSave, onCancel, onEdit, onDelete, channelIntegrationsData, editable=false, deletable=true}) => {
    const { t } = useTranslation()
    const [localConditionContent, setLocalConditionContent] = useState(conditionContent)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [allSkuExists, setAllSkuExists] = useState(false)

    const statementProductCodes = useMemo(() => {
        const products = conditionContent.products ? conditionContent.products : conditionContent.forEveryProduct

        if(!products || products.length <= 0){
            return []
        }

        return products.map((product) => (
            product.product_codes[0].sku
        ))
    }, [conditionContent])

    const conditionDescription = useMemo(() => {
        let description = ""

        switch(localConditionContent.validationType){
            case CONDITION_VALIDATION_TYPES.HAS_FRAGILE_ITEMS:
            case CONDITION_VALIDATION_TYPES.IS_FIRST_TIME_BUYER:
            case CONDITION_VALIDATION_TYPES.IS_POTENTIAL_FRAUD:
                description = t(`settings.business_rule_creator.business_rule_statement_condition.completed_condition.${localConditionContent.validationType}`)
                break
            case CONDITION_VALIDATION_TYPES.CHANNEL_TYPE_NAME:
                description = t(`settings.business_rule_creator.business_rule_statement_condition.completed_condition.${localConditionContent.validationType}`, {channel_name: localConditionContent.channel_name})
                break

            case CONDITION_VALIDATION_TYPES.VALIDATE_PRODUCT:
                {
                    if(localConditionContent.selectedValidationOption === CUSTOM_STATEMENT_VALIDATIONS.INCLUDE_PRODUCT && localConditionContent.selectedQuantityOption === CUSTOM_STATEMENT_QUANTITIES.MINIMUM && localConditionContent.minQuantity === 1 && localConditionContent.anyProduct){
                        description = t(`settings.business_rule_creator.business_rule_statement_condition.completed_condition.${localConditionContent.validationType}.all_orders`)
                        break
                    }

                    let validationOptionText = t(`settings.business_rule_creator.business_rule_statement_condition.completed_condition.${localConditionContent.validationType}.validationOption.${localConditionContent.selectedValidationOption}`)

                    let quantityOptionText = t(`settings.business_rule_creator.business_rule_statement_condition.completed_condition.${localConditionContent.validationType}.quantityOption.${localConditionContent.selectedQuantityOption}`)

                    quantityOptionText = quantityOptionText.replace("{MIN_QUANTITY}", localConditionContent.minQuantity)
                    quantityOptionText = quantityOptionText.replace("{MAX_QUANTITY}", localConditionContent.maxQuantity)
                    quantityOptionText = quantityOptionText.replace("{EXACT_QUANTITY}", localConditionContent.exactQuantity)

                    if(localConditionContent.exclusive){
                        quantityOptionText += t(`settings.business_rule_creator.business_rule_statement_condition.completed_condition.${localConditionContent.validationType}.exclusive`)
                    }

                    description = validationOptionText + quantityOptionText

                    if(localConditionContent.anyProduct){
                        description += t(`settings.business_rule_creator.business_rule_statement_condition.completed_condition.${localConditionContent.validationType}.any_sku`)
                    } else {
                        description += t(`settings.business_rule_creator.business_rule_statement_condition.completed_condition.${localConditionContent.validationType}.specified_sku`)
                    }
                    break
                }

            case CONDITION_VALIDATION_TYPES.FOR_EVERY_PRODUCT:
                {
                    let everyQuantityText = t(`settings.business_rule_creator.business_rule_statement_condition.completed_condition.${localConditionContent.validationType}.every_quantity`)
                    let withMaxQuantityText = t(`settings.business_rule_creator.business_rule_statement_condition.completed_condition.${localConditionContent.validationType}.with_max_quantity`)

                    everyQuantityText = everyQuantityText.replace("{QUANTITY}", localConditionContent.for_every_quantity || "")
                    withMaxQuantityText = withMaxQuantityText.replace("{MAX_QUANTITY}", localConditionContent.maxMultipliedQuantity || "")

                    description = everyQuantityText

                    if(!localConditionContent.withoutMaxMultipliedQuantity){
                        description += withMaxQuantityText
                    }
                    if(localConditionContent.anyProduct){
                        description += t(`settings.business_rule_creator.business_rule_statement_condition.completed_condition.${localConditionContent.validationType}.any_sku`)
                    } else {
                        description += t(`settings.business_rule_creator.business_rule_statement_condition.completed_condition.${localConditionContent.validationType}.specified_sku`)
                    }
                    break
                }

            default:
                break
        }

        return description
    }, [localConditionContent, t])

    const TemplateDescription = ({statementTemplateOption}) => {
        return(
            <div className="flex gap-2">
                { ![AVAILABLE_RULE_STATEMENTS.CUSTOM_STATEMENT, AVAILABLE_RULE_STATEMENTS.CHANNEL_TYPE_NAME].includes(statementTemplateOption) && !!statementTemplateOption ? (
                    <>
                        <div>
                            {t("settings.business_rule_creator.business_rule_statement_condition.title_template")}
                        </div>

                        <TextWithTags
                            textWithTags={t(`settings.business_rule_creator.business_rule_statement_settings.statement_options.${statementTemplateOption}.title`)}
                            listKey={`statement-template-${statementTemplateOption}`}
                        />
                    </>
                ) : t("settings.business_rule_creator.business_rule_statement_condition.title")}
            </div>
        )
    }

    useEffect(() => {
        if(!unsavedChanges){
            setLocalConditionContent(conditionContent)
        }
    }, [conditionContent, unsavedChanges])

    const isConditionValid = useConditionValidator(conditionContent.validationType, localConditionContent, allSkuExists)

    const handleOnSave = (conditionToSave) => {
        if(isConditionValid){
            setUnsavedChanges(false)
            onSave(conditionToSave)
        }
    }

    const handleOnCancel = () => {
        setUnsavedChanges(false)
        onCancel()
    }

    const handleOnUpdateLocalContent = (updatedLocalContent) => {
        setLocalConditionContent(updatedLocalContent)
        setUnsavedChanges(true)
    }

    return (
        <div className={`rounded overflow-hidden w-full border`}>
            <div className={`flex justify-between p-5 ${!conditionContent.isCompleted ? "bg-gray-100" : ""}`}>
                <div className="font-semibold flex gap-5">
                    {conditionContent.isCompleted && !conditionContent.isEditing ? conditionDescription : (
                        <TemplateDescription
                            statementTemplateOption={conditionContent.templateStatementOption}
                        />
                    )}

                    {unsavedChanges && conditionContent.isEditing  && (
                        <div className="italic text-yellow-500 font-normal">
                            {t("settings.business_rule_creator.business_rule_statement_condition.unsaved_changes")}
                        </div>
                    )}

                    {(!conditionContent.isCompleted || conditionContent.isEditing) && (
                        <>
                            {isConditionValid ? (
                                <CheckCircleIcon className="h-6 text-green-600" />
                            ) : (
                                <ExclamationCircleIcon className="h-6 text-red-600" />
                            )}
                        </>
                    )}
                </div>

                <div className="flex h-6 text-gray-600 gap-5">
                    {editable && conditionContent.isCompleted && !conditionContent.isEditing  && (
                        <PencilIcon className="h-full cursor-pointer hover:text-gray-400" onClick={() => onEdit()}/>
                    )}

                    {deletable && <TrashIcon className="h-full cursor-pointer hover:text-gray-400" onClick={() => onDelete()} />}
                </div>
            </div>
            {(!conditionContent.isCompleted || conditionContent.isEditing) && (
                <div className="flex flex-col p-5 gap-5">
                    {conditionContent.validationType === CONDITION_VALIDATION_TYPES.FOR_EVERY_PRODUCT ? (
                        <ForEveryProductCondition
                            conditionContent={localConditionContent}
                            onUpdateConditionContent={(newConditionContent) => handleOnUpdateLocalContent({...localConditionContent, ...newConditionContent})}
                            onAllSkuExists={setAllSkuExists}
                        />
                    ): conditionContent.validationType === CONDITION_VALIDATION_TYPES.CHANNEL_TYPE_NAME ? (   
                        <CustomChannelCondition
                            conditionContent={localConditionContent}
                            onUpdateConditionContent={(newConditionContent) => handleOnUpdateLocalContent({...localConditionContent, ...newConditionContent})}
                            channelIntegrationsData={channelIntegrationsData}
                        />
                    ): (
                        <CustomCondition
                            conditionContent={localConditionContent}
                            onUpdateConditionContent={(newConditionContent) => handleOnUpdateLocalContent({...localConditionContent, ...newConditionContent})}
                            onAllSkuExists={setAllSkuExists}
                        />
                    )}

                    <div className="flex gap-5">
                        {conditionContent.isEditing && (
                            <Button
                                type="cancel"
                                onClick={() => handleOnCancel()}
                            >
                                {t("settings.business_rule_creator.business_rule_statement_condition.cancel_button")}
                            </Button>
                        )}

                        <Button
                            type="primary"
                            className={`${isConditionValid ? "" : "cursor-not-allowed"}`}
                            onClick={() => handleOnSave(localConditionContent)}
                            disabled={!isConditionValid}
                        >
                            {conditionContent.isEditing ? t("settings.business_rule_creator.business_rule_statement_condition.save_button_update") : t("settings.business_rule_creator.business_rule_statement_condition.save_button")}
                        </Button>
                    </div>
                </div>
            )}

            {conditionContent.isCompleted && !conditionContent.isEditing && !conditionContent.anyProduct && statementProductCodes.length > 0 && (
                <div className="flex gap-2 px-5 pb-5 flex-wrap">
                    {statementProductCodes.map((productCode) => (
                        <span
                            className={"inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"}
                            key={`sku-${productCode}`}
                        >
                            {productCode}
                        </span>
                    ))}
                </div>
            )}
        </div>
    )
}

export default StatementCondition