import {InputField} from "../../../components";
import {useState} from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {Controller} from "react-hook-form";
import { useTranslation } from 'react-i18next';

import {deleteVariant, modifyVariant, setProductData} from "../../../redux/createProductSlice";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";

const components = {
    DropdownIndicator: null,
};

const createOption = (label: string) => ({
    label,
    value: label,
});

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: (provided) => ({
        ...provided,
        marginTop: 2,
        width: 350,
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}


export const VariantRow = ({ variant, control, setValue, errors, register, onDelete = null}) => {

    VariantRow.propTypes = {
        variant: PropTypes.shape({
            name: PropTypes.any.isRequired,
            //skus: PropTypes.arrayOf(PropTypes.string).isRequired,
            barCode: PropTypes.string.isRequired
        }).isRequired
    }

    const { t, i18n } = useTranslation(); 

    const {name, skus, barCode} = variant

    const dispatch = useDispatch();
    const [skuInput, setSkuInput] = useState("")


    const onDeleteHandler = () => {
        dispatch(deleteVariant(variant.key))
        if(onDelete!=null){
            onDelete(variant)
        }
    }

    const onNameChangeHandler = (value) => {
        dispatch(modifyVariant({
            ...variant,
            name: value
        }))
        setValue(`${variant.key}.name`, value)
    }

    const onSkusChangeHandler = (skus) => {
        dispatch(modifyVariant({
            ...variant,
            skus: skus,
            edited: true
        }))
    }

    const onBarCodeHandler = (value) => {
        dispatch(modifyVariant({
            ...variant,
            barCode: value,
            edited: true
        }))
    }

    /**
     *
     * @param name {[{name: string, value: string}]}
     * @returns {string}
     */
    const getName = (name) => {
        let names = ""
        for(let i=0; i<name.length; i++ ){
            names = `${names}${name[i].value} / `
        }
        return names.substring(0, names.length - 2)
    }

    const handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
        if (!skuInput) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                //console.group('Value Added');
                //console.log(skus);
                //console.groupEnd();
                setSkuInput("")
                onSkusChangeHandler([...skus, createOption(skuInput)])
                setValue(`${variant.key}.skus`, [...skus, createOption(skuInput)])
                event.preventDefault();
        }
    }

    return (
        <div className="py-4 flex items-stretch justify-between">

            {variant.type===undefined && <InputField
                title= {i18n.t("addProduct.variantRow.variant_name")}
                placeholder= {i18n.t("addProduct.variantRow.example")} 
                value={Array.isArray(name)?getName(name): name}
                //onChange={onNameChangeHandler}
                className="w-30"
                disabled={true}
            />}

            {variant.type=="add" && <Controller
                control={control}
                rules={{ required: true }}
                name={`${variant.key}.name`}
                render={({field}) =>
                    <div>
                        <InputField
                            {...field}
                            title= {i18n.t("addProduct.variantRow.variant_name")}
                            placeholder={i18n.t("addProduct.variantRow.example")}
                            value={name}
                            onChange={onNameChangeHandler}
                            className="w-30"
                        />
                        <p className="mt-2 text-sm text-red-600">
                            {(errors[variant.key]?.name ) && name=="" && i18n.t("addProduct.variantRow.required_field")}
                        </p>
                    </div>
                }
            />}


            <div className="max-w-2xl mx-2">
                <label className="block text-sm font-medium text-gray-700">
                    SKUs
                </label>

                <div className="mt-1">
                    <Controller
                        name={`${variant.key}.skus`}
                        control={control}
                        rules={{ required: true }}
                        render={({field})=>
                            <CreatableSelect
                                {...field}
                                styles={customStyles}
                                components={components}
                                inputValue={skuInput}
                                isClearable
                                isMulti
                                menuIsOpen={false}
                                onChange={(tags) => {
                                    console.log(tags)
                                    onSkusChangeHandler(tags)
                                    setValue(`${variant.key}.skus`, tags)
                                }}
                                onInputChange={(val)=>setSkuInput(val)}
                                onKeyDown={handleKeyDown}
                                placeholder={i18n.t("addProduct.variantRow.type_sth")}
                                value={skus}
                            />
                        }
                    />
                    <p className="mt-2 text-sm text-red-600">
                        {(errors[variant.key]?.skus ) && skus.length==0 && i18n.t("addProduct.variantRow.required_field")}
                    </p>

                </div>
            </div>

            <Controller
                name={`${variant.key}.barCode`}
                control={control}
                rules={{ required: false }}
                render={({field}) =>
                    <div>
                        <InputField
                            //{...field}
                            labelElement={<label htmlFor="email" className="block text-sm font-medium text-gray-700 flex justify-between">
                                <span>{i18n.t("addProduct.variantRow.barCode")}</span>
                                <a className="cursor-pointer text-indigo-600" onClick={onDeleteHandler}>{i18n.t("addProduct.variantRow.delete")}</a>
                            </label>}
                            value={barCode}
                            onChange={(value) => {
                                console.log(`${variant.key}.barCode`, value)
                                setValue(`${variant.key}.barCode`, value)
                                onBarCodeHandler(value)
                            }}
                        />
                        <p className="mt-2 text-sm text-red-600">
                            {(errors[variant.key]?.barCode && barCode=="") && i18n.t("addProduct.variantRow.required_field")}
                        </p>
                    </div>
                }
            />
        </div>
    )
}