import { useContext } from "react";
import {BasicTable} from "../../../components";
import {EditableCell} from "../../../components/BasicTable/EditableCell";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../hooks/UserContext";

export const ProductsReadTable = ({products, updateData=null}) => {
    const { t, i18n} = useTranslation();
    const {user} = useContext(UserContext)

    const warehouse = user.current_store?.warehouses[0]

    const columns = [
        {
            Header: "addProduct.kitsTable.name",
            accessor: 'name'
        },
        {
            Header: "addProduct.kitsTable.sku",
            accessor: 'sku'
        },
        {
            Header:"addProduct.kitsTable.quantity",
            accessor: 'quantity',
            shrinkToContent: true
        },
        {
            Header: "addProduct.kitsTable.price_per_item",
            accessor: 'price',
            shrinkToContent: true
        }
    ]

    if(warehouse?.country !== 'BR')  {
        columns.splice(columns.length -1, 1);
    }

    return (
        <BasicTable
            columns={columns}
            data={products.map(product => {
                return {
                    name: product.name,
                    sku: <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-80">{product.sku}</span>,
                    quantity: product.quantity,
                    price: product.price_per_item
                }
            })}
            selectable={false}
            editable={false}
            updateData={updateData}
            emptyTableText= {i18n.t("addProduct.readTable.Loading_products")}
            showHeader
        />
    )
}