import axiosInstance from "../helpers/axiosInstance";

export const fetchReplenishments = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments`, { params })
    return res.data
}

export const fetchReplenishment = async (replenishmentId) => {
    if(replenishmentId===undefined){
        return null
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments/${replenishmentId}`)
    return res.data
}

export const enqueueReplenishmentsExport = async function (data) {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user-async-tasks/replenishments_export`, data)
    return res.data
}

export const getReplenishmentsExport = async function (jobId) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user-async-tasks/replenishments_export/${jobId}`)
    return res.data
}

export const fetchReplenishmentProducts = async (replenishmentId, params) => {
    if(replenishmentId===undefined){
        return null
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments/${replenishmentId}/products`, { params })
    return res.data
}

export const getInvoices = async (replenishmentId) => {
    if(replenishmentId===undefined){
        return null
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments/${replenishmentId}/invoices`)
    return res.data
}


export const deleteReplenishment = async (replenishmentId) => {
    console.log("ID",replenishmentId)
    const res = await axiosInstance.delete(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments/${replenishmentId}`)
    return res.data
}
export const cancelReplenishment = async (replenishmentId) => {
    console.log("ID",replenishmentId)
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments/${replenishmentId}/cancel`)
    return res.data
}


/**
 *
 * @param replenishment {{shippingMethod: {id: string, name: string}, shippingDate: string, numberShipping: string, replenishmentNumber: string, referenceName: string}}
 * @param products {[{id: integer, name: string, quantity: int}]}
 * @param shippingAddress {{status: string, firstName: string, lastName: string, phone: string, address: string, country: {id: string, name: string}, city: string, province: string, zipCode: string}}
 * @param billingAddress {{status: string, firstName: string, lastName: string, phone: string, address: string, country: {id: string, name: string}, city: string, province: string, zipCode: string}}
 * @param storeId {integer}
 * @returns {Promise<void>}
 */
export const saveReplenishment = async (
    replenishment,
    products,
    hasOptions,
    storeId,
    warehouseId,
    status
) => {
    const data = {
        store_id: storeId,
		warehouse_id: warehouseId,
        notes: replenishment.notes,
        referenceNumber: replenishment.referenceNumber,
        status:status,
        sell_ahead:hasOptions,
        created_at:replenishment.shippingDate,
		expected_arrived_at: replenishment.shippingArrivedDate,
        declared_value: replenishment.declaredValue,
        products: products.map(item => {
            return {
                id: item.product_id,
                quantity: item.quantity
            }
        })
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments`, data)
    return res.data
}

export const updateReplenishment = async (
    id,
    replenishment,
    products,
    hasOptions,
    storeId,
    warehouseId,
    deletedProducts
) => {
    const productsTemp = [...products, ...deletedProducts.map(item => {
        return {
            replenishment_products_id: item.replenishment_products_id,
            type: "delete"
        }
    })]
    const data = {
        store_id: storeId,
		warehouse_id: warehouseId,
        notes: replenishment.notes,
        sell_ahead:hasOptions,
        created_at:replenishment.shippingDate,
		expected_arrived_at: replenishment.shippingArrivedDate,
        declared_value: replenishment.declaredValue,
        products: productsTemp
    }
    
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments/${id}`, data)
    return res.data
}

export const requestedReplenishments = async (id,url) => {
    const data = {
        url: url
    }
    
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments/${id}/requested`,data)
    return res.data
}