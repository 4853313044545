import { useMemo } from "react";

import { AVAILABLE_ACTION_OPTIONS, AVAILABLE_ACTION_OPTIONS_FOR_BR, ALL_AVAILABLE_ACTION_OPTIONS, AVAILABLE_PRODUCT_INSERT_OPTIONS } from "../../utils/businessRuleActionUtils";

const useRuleActionSettingsValidator = (actionSettings, storeWarehouses) => {
    const areRuleActionSettingsValid = useMemo(() => {
        const isBrWarehouse = storeWarehouses.some((warehouse) => warehouse.country?.toUpperCase() === "BR")
        const availableOptions = isBrWarehouse ? Object.values(AVAILABLE_ACTION_OPTIONS_FOR_BR) : Object.values(AVAILABLE_ACTION_OPTIONS)
        const hasScannableProducts = actionSettings.productsToInsert ? actionSettings.productsToInsert.some((product) => product.is_scannable) : false

        let areValid = true

        if(!actionSettings){
            areValid = false
        } else if(!availableOptions.some((actionOption) => actionOption === actionSettings.actionOption)){
            areValid = false
        } else if (!actionSettings.productsToInsert || !Array.isArray(actionSettings.productsToInsert)){
            areValid = false
        } else if (actionSettings.actionOption === ALL_AVAILABLE_ACTION_OPTIONS.ADD_PRODUCT_OPTION && !Object.values(AVAILABLE_PRODUCT_INSERT_OPTIONS).some((productInsertOption) => productInsertOption === actionSettings.productInsertOption) ){
            areValid = false
        } else if (actionSettings.actionOption === ALL_AVAILABLE_ACTION_OPTIONS.ADD_INSERT_OPTION && !Object.values(AVAILABLE_PRODUCT_INSERT_OPTIONS).some((productInsertOption) => productInsertOption === actionSettings.productInsertOption) && hasScannableProducts ){
            areValid = false
        } else if (actionSettings.actionOption !== ALL_AVAILABLE_ACTION_OPTIONS.PAUSE_ORDER_OPTION && actionSettings.actionOption !== ALL_AVAILABLE_ACTION_OPTIONS.CANCEL_ORDER_OPTION && actionSettings.productsToInsert <= 0){
            areValid = false
        } else if(actionSettings.actionOption === ALL_AVAILABLE_ACTION_OPTIONS.ADD_SHIPPING_PACKAGE_OPTION && hasScannableProducts){
            areValid = false
        }

        return areValid
    },[actionSettings, storeWarehouses])

    return areRuleActionSettingsValid
}

export default useRuleActionSettingsValidator