import { createSlice } from "@reduxjs/toolkit";
const shortid = require('shortid')

const initialState = {
    product: {
        name: "",
        price: "",
        weight: "",
        billing_name: "",
        description: "",
        skus: [],
        barCode: "",
        isDropshipping: false,
        hasExpiration: false,
        freshness: null,
        bufferStock: null,
        isFragile: false,
        isScannable: true,
        type: "",
        origin: null,
        ncm_id:null,
        is_packageless: false,
        has_imei: false,
    },
    hasOptions: false,
    options: [
        {
            key: shortid.generate(),
            name: '',
            tags:[],
            isValid: false,
        }
    ],
    variants: [
        /*{
            key: shortid.generate(),
            name: "",
            skus: [],
            barCode: ""
        }*/
        // "Chamarra-M-Roja": { skus: "", barCode: "" }
    ],
    products: [
        /*
        {
            key: shortid.generate(),
            name: "",
            variant_name: "",
            skus: [],
            quantity: 1
        }
        * */
    ]
};


export const createProductSlice = createSlice({
    name: 'createProducts',
    initialState,
    reducers: {
        setProductData: (state, action) => {
            state.product = action.payload
        },
        addOption: (state, action) => {
            state.options.push({
                key: action.payload.key,
                name: action.payload.name,
                tags: action.payload.tags
            })
        },
        deleteOption: (state, action) => {
            state.options = state.options.filter((item) => item.key!==action.payload)
        },
        modifyOption: (state, action) => {
            state.options = state.options.map(item => {
                if(item.key===action.payload.key)
                    return action.payload
                return item
            })
        },
        setHasOptions: (state, action) => {
            state.hasOptions = action.payload
        },
        setVariants: (state, action) => {
            state.variants = action.payload
        },
        addEventVariant: (state, action) => {
            const { event } = action.payload
            const { name, data } = event

            if(name==="OptionNameChanged"){
                const { key, name } = data
                state.options = state.options.map(item => {
                    if(item.key===key)
                        return {
                            ...item,
                            name: name
                        }
                    return item
                })
            }
            if(name==="OptionTagWasAdded"){

            }
            if(name==="OptionTagWasDeleted"){

            }
        },
        addVariant: (state, action) => {
            state.variants.push(action.payload)
        },
        modifyVariant: (state, action) => {
            state.variants = state.variants.map(item => {
                if(item.key===action.payload.key)
                    return action.payload
                return item
            })
        },
        deleteVariant: (state, action) => {
            state.variants = state.variants.filter((item) => item.key!==action.payload)
        },

        addKitProducts: (state, action) => {
            if(Array.isArray(action.payload)){
                action.payload.forEach(item => {
                    state.products.push(item)
                })
            }
            else {
                state.products.push(action.payload)
            }
        },
        deleteKitProduct: (state, action) => {
            state.products = state.products.filter(item => item.key!==action.payload.key)
        },
        modifyKitProduct: (state, action) => {
            console.log("modifyKitProduct", action.payload)
            state.products = state.products.map(item => {
                if(item.key === action.payload.key)
                    return action.payload
                return item
            })
        },
        resetData: (state, action) => {
            state.product = initialState.product
            state.hasOptions = false
            state.options = initialState.options
            state.products = initialState.products
            state.variants = initialState.variants
        },
        setData: (state, action) => {
            state.product = action.payload.product
            state.hasOptions = action.payload.hasOptions
            state.options = action.payload.options
            state.products = action.payload.products
            state.variants = action.payload.variants
        }
    }
});

export const {
    addOption,
    deleteOption,
    modifyOption,
    setHasOptions,
    addVariant,
    modifyVariant,
    setProductData,
    deleteVariant,
    setVariants,
    addKitProducts,
    deleteKitProduct,
    modifyKitProduct,
    resetData,
    setData
} = createProductSlice.actions

export const productOptions = (state) => state.createProducts.options
export const productHasOptions = (state) => state.createProducts.hasOptions
export const productVariants = (state) => state.createProducts.variants
export const productData = (state) => state.createProducts.product
export const productsKit = (state) => state.createProducts.products

export default createProductSlice.reducer
