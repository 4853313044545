export const NOTIFICATION_SETTINGS_KEYS = new Set([
    "claims_email_notification",
    "product_expirations_email_notification",
    "replenishments_email_notification",
    "returns_email_notification",
    "shipping_incident_created_by_cubbo_email_notification"
]);

export const MAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const PACKING_MATERIAL_TYPES = {
    PROTECTION_MATERIAL: "PROTECTION_MATERIAL"
}
