import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import moment from "moment-timezone";

const ValidityStartSelector = ({validityStartDate, onUpdateValidityStartDate, timeZone}) => {
    const { t } = useTranslation()

    const localValidityStartDate = useMemo(() => {
        let createdValidityStartDate = null

        if(validityStartDate){
            const formattedDate = validityStartDate.toISOString()

            let parsedDate = moment(formattedDate).tz(timeZone)
            parsedDate = parsedDate.startOf("day")
            parsedDate = parsedDate.format("YYYY-MM-DD HH:mm:ss")

            createdValidityStartDate = new Date(parsedDate)
        }

        return createdValidityStartDate
    }, [validityStartDate, timeZone])

    const handleOnChangeValidityStart = (newValidityStart) => {
        let validityStartDateToUpdate = null

        if(newValidityStart){
            const validityYear = newValidityStart.getFullYear()
            const validityMonth = (newValidityStart.getMonth() + 1).toString().padStart(2, "0")
            const validityDate = newValidityStart.getDate().toString().padStart(2, "0")

            let parsedTodayDate = moment().tz(timeZone)
            parsedTodayDate = parsedTodayDate.startOf("day")
            parsedTodayDate = parsedTodayDate.format("YYYY-MM-DD")

            const parsedNewValidityStart = `${validityYear}-${validityMonth}-${validityDate}`

            if(parsedTodayDate !== parsedNewValidityStart){
                let formattedNewValidityDate = moment.tz(parsedNewValidityStart, timeZone)
                formattedNewValidityDate = formattedNewValidityDate.startOf("day")
                formattedNewValidityDate = formattedNewValidityDate.format("YYYY-MM-DD HH:mm:ss Z")

                validityStartDateToUpdate = new Date(formattedNewValidityDate)
            } else{
                validityStartDateToUpdate = null
            }
        }

        onUpdateValidityStartDate(validityStartDateToUpdate)
    }

    const calculateMinimumStartDate = () => {
        let formattedMinimumDate = moment().tz(timeZone)
        formattedMinimumDate = formattedMinimumDate.startOf("day")
        formattedMinimumDate = formattedMinimumDate.format("YYYY-MM-DD HH:mm:ss")

        const minimumStartDate = new Date(formattedMinimumDate)

        return minimumStartDate
    }

    return (
        <div className="flex gap-5 items-center overflow-hidden">
            <label className="shrink-0" htmlFor="validity_start">
                {t("settings.business_rule_creator.general_business_rule_settings.validity_start_field")}
            </label>

            <ReactDatePicker
                id="validity_start"
                className={"placeholder:text-black border rounded border-gray-300 cursor-pointer"}
                selected={localValidityStartDate}
                placeholderText={t("settings.business_rule_creator.general_business_rule_settings.validity_start_placeholder")}
                dateFormat="dd/MM/yy"
                onChange={handleOnChangeValidityStart}
                minDate={calculateMinimumStartDate()}
            />
        </div>
    )
}

export default ValidityStartSelector