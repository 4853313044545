import { Grid, Col } from "@tremor/react"
import { BaseCard } from "../../../../components/MetricsCards/BaseCard"
import { MetricsCardHeader } from "../../../../components/MetricsCards/MetricsCardHeader"
import { INTERVAL_DAY } from "../chartDataUtils"
import { useTranslation } from "react-i18next"
import { ClickToDoorAverage } from "./ClickToDoorAverage"
import { ClickToDoorByDays } from "./ClickToDoorByDays"
import { ClickToDoorHistoric } from "./ClickToDoorHistoric"
import { useEffect, useRef } from "react"
import useOnScreen from "../../../../hooks/useOnScreen"

export const ClickToDoor = ({ storeId = null, dateFilter = null, interval = INTERVAL_DAY }) => {
    const { t } = useTranslation()

    const cardRef = useRef(null)
    const cardIsOnScreen = useOnScreen(cardRef)
    useEffect(() => {
        if (cardIsOnScreen) window.analytics.track("Metrics - Views ClickToDoor")
    }, [cardIsOnScreen])

    return (
        <BaseCard className="py-6" ref={cardRef}>
            <MetricsCardHeader title={t("metrics.click_to_door.title")} subtitle={t("metrics.click_to_door.subtitle")} />
            <ClickToDoorAverage storeId={storeId} dateFilter={dateFilter} />
            <Grid numItemsSm={1} numItemsLg={3} className="w-full gap-10 lg:gap-x-20 py-5">
                <Col numColSpan={1}>
                    <ClickToDoorByDays storeId={storeId} dateFilter={dateFilter} />
                </Col>
                <Col numColSpanLg={2}>
                    <ClickToDoorHistoric storeId={storeId} dateFilter={dateFilter} interval={interval} />
                </Col>
            </Grid>
        </BaseCard>
    )
}
