import { useEffect, useState, useMemo, useContext } from "react"
import { useTranslation } from "react-i18next";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import moment from "moment-timezone";

import { BusinessRuleStepCard } from "../../components";
import ValidityStartSelector from "./ValidityStartSelector";
import ValidityEndSelector from "./ValidityEndSelector";
import AliasInput from "./AliasInput";
import useGeneralSettingsValidator from "./useGeneralSettingsValidator";
import { Button } from "../../../../../components";
import { UserContext } from "../../../../../hooks/UserContext";

const GeneralBusinessRuleSettings = ({generalSettings, onUpdateGeneralSettings, isPreviousStepCompleted, isCurrentStep, onCancelGeneralSettings, isEditing, onEdit, isCompleted}) => {
    const { t } = useTranslation()
    const { user } = useContext(UserContext)
    const [localGeneralSettings, setLocalGeneralSettings] = useState(generalSettings)

    useEffect(() => {
        setLocalGeneralSettings({...generalSettings})
    }, [generalSettings])

    const areGeneralSettingsValid = useGeneralSettingsValidator(localGeneralSettings, user.current_store.warehouses[0].time_zone)

    const completedStepDescription = useMemo(() => {
        let description = ""
        const timeZone = user.current_store.warehouses[0].time_zone

        let formattedStartDate = ""
        let formattedEndDate = ""

        if(generalSettings.validityStartDate){
            const startDateToParse = generalSettings.validityStartDate?.toISOString()

            formattedStartDate = moment(startDateToParse).tz(timeZone)
            formattedStartDate = formattedStartDate.format("DD/MM/YYYY")
        }

        if(generalSettings.validityEndDate){
            const endDateToParse = generalSettings.validityEndDate?.toISOString()

            formattedEndDate = moment(endDateToParse).tz(timeZone)
            formattedEndDate = formattedEndDate.format("DD/MM/YYYY")
        }

        if(generalSettings.alias && generalSettings.alias !== ""){
            description += `${generalSettings.alias}, `
        }

        if(!generalSettings.validityStartDate && generalSettings.withoutValidityEnd){
            description += t("settings.business_rule_creator.general_business_rule_settings.completed_step.from_today_with_no_limit")
        }
        else if(!generalSettings.validityStartDate && !generalSettings.withoutValidityEnd){
            const descriptionText = t("settings.business_rule_creator.general_business_rule_settings.completed_step.from_today_with_limit")
            description += descriptionText.replace("{END_DATE}", formattedEndDate)
        }
        else if(generalSettings.validityStartDate && generalSettings.withoutValidityEnd){
            const descriptionText = t("settings.business_rule_creator.general_business_rule_settings.completed_step.from_some_day_with_no_limit")
            description += descriptionText.replace("{START_DATE}", formattedStartDate)
        }
        else if(generalSettings.validityStartDate && !generalSettings.withoutValidityEnd){
            const descriptionText = t("settings.business_rule_creator.general_business_rule_settings.completed_step.from_some_day_with_limit")
            description += descriptionText.replace("{START_DATE}", formattedStartDate).replace("{END_DATE}", formattedEndDate)
        }

        return description
    }, [generalSettings, user, t])

    const validityErrorMessage = useMemo(() => {
        let errorMessage = ""
        const currentDate = new Date()

        if (!localGeneralSettings.validityEndDate && !localGeneralSettings.withoutValidityEnd){
            errorMessage = t("settings.business_rule_creator.general_business_rule_settings.validity_error_message.missing_validity_end")
        } else if (localGeneralSettings.validityStartDate && localGeneralSettings.validityEndDate && localGeneralSettings.validityStartDate > localGeneralSettings.validityEndDate){
            errorMessage = t("settings.business_rule_creator.general_business_rule_settings.validity_error_message.invalid_validity")
        } else if(!localGeneralSettings.validityStartDate && localGeneralSettings.validityEndDate && currentDate > localGeneralSettings.validityEndDate){
            errorMessage = t("settings.business_rule_creator.general_business_rule_settings.validity_error_message.invalid_validity")
        }

        return errorMessage
    }, [localGeneralSettings, t])

    const saveButtonDescription = useMemo(() => {
        let descriptionText = ""
        const isAliasInserted = localGeneralSettings.alias.length > 0 ? true : false

        if(isAliasInserted && validityErrorMessage === ""){
            descriptionText = t("settings.business_rule_creator.general_business_rule_settings.confirm_button")
        } else if (!isAliasInserted && validityErrorMessage !== ""){
            descriptionText = t("settings.business_rule_creator.general_business_rule_settings.confirm_button_missing_all_required")
        } else if (!isAliasInserted && validityErrorMessage === ""){
            descriptionText = t("settings.business_rule_creator.general_business_rule_settings.confirm_button_missing_only_alias")
        } else if (isAliasInserted && validityErrorMessage !== ""){
            descriptionText = t("settings.business_rule_creator.general_business_rule_settings.confirm_button_missing_only_validity")
        }

        return descriptionText
    }, [localGeneralSettings, validityErrorMessage, t])

    const handleOnSubmitGeneralSettings = () => {
        if(areGeneralSettingsValid){
            onUpdateGeneralSettings({...localGeneralSettings})
        }
    }

    const handleOnCancelGeneralSettings = () => {
        setLocalGeneralSettings({...generalSettings})
        onCancelGeneralSettings()
    }

    return (
        <div>
            <BusinessRuleStepCard
                title={t("settings.business_rule_creator.general_business_rule_settings.title")}
                description={!isCompleted ? t("settings.business_rule_creator.general_business_rule_settings.description") : ""}
                isCompleted={isCompleted}
                isValid={areGeneralSettingsValid}
                isEditing={isEditing}
                isCurrentStep={isCurrentStep}
                isPreviousStepCompleted={isPreviousStepCompleted}
                onEdit={onEdit}
                completedDescription={completedStepDescription}
            >
                {(isEditing || isCurrentStep) && (
                    <div className="flex flex-col p-5 gap-5">
                        <AliasInput
                            ruleAlias={localGeneralSettings.alias}
                            onRuleAliasUpdate={(newRuleAlias) => setLocalGeneralSettings({
                                ...localGeneralSettings,
                                alias: newRuleAlias
                            })}
                        />

                        <div className="mb-5">
                            <div className="flex gap-2">
                                <div className="font-semibold">
                                    <span>{t("settings.business_rule_creator.general_business_rule_settings.validity")}</span>
                                    <span className="text-red-500">{" *"}</span>
                                </div>

                                {validityErrorMessage === "" ? (
                                    <CheckCircleIcon className="h-6 text-green-600" />
                                ) : (
                                    <ExclamationCircleIcon className="h-6 text-red-600" />
                                )}
                            </div>

                            <div className="flex gap-6 flex-wrap">
                                <ValidityStartSelector
                                    validityStartDate={localGeneralSettings.validityStartDate}
                                    onUpdateValidityStartDate={(newValidityStartDate) => setLocalGeneralSettings({
                                        ...localGeneralSettings,
                                        validityStartDate: newValidityStartDate
                                    })}
                                    timeZone={user.current_store.warehouses[0].time_zone}
                                />

                                <ValidityEndSelector
                                    validityEndDateContent={{
                                        validityEndDate: localGeneralSettings.validityEndDate,
                                        withoutValidityEnd: localGeneralSettings.withoutValidityEnd
                                    }}
                                    onUpdateValidityEndDateContent={(newValidityEndDateContent) => setLocalGeneralSettings({
                                        ...localGeneralSettings,
                                        ...newValidityEndDateContent
                                    })}
                                    timeZone={user.current_store.warehouses[0].time_zone}
                                />
                            </div>

                            <div className="text-xs text-gray-400 mt-2">
                                {t("settings.business_rule_creator.general_business_rule_settings.validity_time_zone")}
                            </div>

                            {validityErrorMessage !== "" && (
                                <div className="text-red-500 mt-1">
                                    {validityErrorMessage}
                                </div>
                            )}
                        </div>

                        {!isCompleted ? (
                            <Button
                                type="primary"
                                className={`w-full ${areGeneralSettingsValid ? "" : "cursor-not-allowed"}`}
                                onClick={handleOnSubmitGeneralSettings}
                                disabled={!areGeneralSettingsValid}
                            >
                                {saveButtonDescription}
                            </Button>
                        ) : (
                            <div className="flex gap-5">
                                <Button
                                    type="cancel"
                                    className="w-full"
                                    onClick={handleOnCancelGeneralSettings}
                                >
                                    {t("settings.business_rule_creator.general_business_rule_settings.cancel_edit_button")}
                                </Button>

                                <Button
                                    type="primary"
                                    className={`w-full ${areGeneralSettingsValid ? "" : "cursor-not-allowed"}`}
                                    onClick={handleOnSubmitGeneralSettings}
                                    disabled={!areGeneralSettingsValid}
                                >
                                    {t("settings.business_rule_creator.general_business_rule_settings.confirm_edit_button")}
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </BusinessRuleStepCard>
        </div>
    )
}

export default GeneralBusinessRuleSettings