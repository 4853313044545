import { useMemo } from "react"
import moment from "moment-timezone";

export const ALIAS_MAX_LENGTH = 100
export const ALIAS_REGEX = /^.*$/

const useGeneralSettingsValidator = (generalSettings, timeZone) => {
    const areGeneralSettingsValid = useMemo(() => {
        let areValid = true

        let parsedTodayEndDate = moment().tz(timeZone)
        parsedTodayEndDate.endOf("day")
        parsedTodayEndDate = parsedTodayEndDate.format("YYYY-MM-DD HH:mm:ss Z")

        const todayEndDate = new Date(parsedTodayEndDate)
        const validityStartDate = generalSettings.validityStartDate ? new Date(generalSettings.validityStartDate) : null
        const validityEndDate = generalSettings.validityEndDate ? new Date(generalSettings.validityEndDate) : null

        if(!generalSettings){
            areValid = false
        } else if (typeof generalSettings.alias !== "string" || generalSettings.alias.length > ALIAS_MAX_LENGTH || !ALIAS_REGEX.test(generalSettings.alias) || generalSettings.alias.length <= 0){
            areValid = false
        } else if (typeof generalSettings.withoutValidityEnd !== "boolean"){
            areValid = false
        } else if (validityStartDate && !(validityStartDate instanceof Date)){
            areValid = false
        } else if (validityEndDate && !(validityEndDate instanceof Date)){
            areValid = false
        } else if (!generalSettings.validityEndDate && !generalSettings.withoutValidityEnd){
            areValid = false
        } else if (validityStartDate && validityEndDate && validityStartDate > validityEndDate){
            areValid = false
        } else if (!validityStartDate && validityEndDate && todayEndDate > validityEndDate) {
            areValid = false
        }

        return areValid
    }, [generalSettings, timeZone])

    return areGeneralSettingsValid
}

export default useGeneralSettingsValidator