import { useTranslation } from "react-i18next"

export function diff_minutes(dt2, dt1) {
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60);
    return Math.trunc(diff);
 }

export const FormattedDate = ({
    date, 
    shortDate = false, dayMonthYearDate = false, shortDayMonthYearDateTime = false, onlyDate = false
}) => {
    const {i18n} = useTranslation()

    const getShortDate = (value) => {
        let date = new Date(value)
        return date.toLocaleString(i18n.language, {year: "2-digit",
        month: "numeric",
        day: "2-digit", hour: '2-digit', minute:'2-digit'})
    }

    const getDayMonthYearDate = (value) => {
        let date = new Date(value)
        return date.toLocaleString(i18n.language, {year: "numeric",
        month: "long",
        day: "2-digit"})
    }

    const getOnlyDate= (value) => {
        let date = new Date(value)
        return date.toLocaleString(i18n.language, {year: "2-digit",
        month: "numeric",
        day: "2-digit"})
    }

    const getShortDayMonthYearDateTime = (value) => {
        let date = new Date(value)
        return date.toLocaleString(i18n.language, {
            year: "2-digit",
            month: "short",
            day: "2-digit", 
            hour: '2-digit', 
            minute:'2-digit'
        })
    }

    return (
        <>
            { date === null ? '-' :
                shortDate ? 
                    getShortDate(date)
                : onlyDate ?
                    getOnlyDate(date)
                :
                dayMonthYearDate ?
                    getDayMonthYearDate(date)
                :
                shortDayMonthYearDateTime ?
                    getShortDayMonthYearDateTime(date)
                :
                new Intl.DateTimeFormat(i18n.language, {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                    hour: 'numeric',
                    minute: 'numeric'
                }).format(new Date(date))
            }
        </>
    )
}