import {InputNumber} from "antd";
import {Button} from "../../components";
import {DamagedIcon} from "../../components/Icons/DamagedIcon";
import {TrashIcon} from "../../components/Icons/TrashIcon";
import  i18n from "../../translations/index";

const buildElementKey = (product, hideDamagedDetails) => `${product.id}${!hideDamagedDetails && product.damaged ? 'D' : ''}`

const ProductListItem = ({
    product,
    productIndex,
    onQuantityChange,
    onDamagedProductAddition,
    onProductRemoval,
    hideDamagedDetails = false
}) => {
    return (
        <tr
            className="text-gray-500"
            key={buildElementKey(product, hideDamagedDetails)}
        >
            <td>
                {product.label}
                {!hideDamagedDetails && product.damaged && (
                    <i className="text-red-500"> {i18n.t("returns.listItem.damaged_parts")}</i>
                )}
            </td>
            <td>{product.upc ? product.upc : "Sin UPC"}</td>
            <td>
                <span
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mx-1"
                >
                    {product.sku}
                </span>
            </td>
            <td>
                <InputNumber
                    min={0}
                    value={product.quantity}
                    onChange={(value) =>
                        onQuantityChange(value, productIndex)
                    }
                    keyboard={true}
                />
            </td>
            {!hideDamagedDetails && (
                <td>
                    {!product.damaged && (
                        <Button
                            type="danger"
                            className="px-2 has-tooltip"
                            dense
                            onClick={() => onDamagedProductAddition(productIndex)}
                        >
                        <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                            {i18n.t("returns.listItem.add_damaged_part")} 
                        </span>
                            <DamagedIcon className="text-white w-4 h-4" />
                        </Button>
                    )}
                </td>
            )}
            <td>
                <Button
                    className="px-2 has-tooltip ml-2"
                    dense
                    type="secondary"
                    onClick={() => onProductRemoval(productIndex)}
                >
                    <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                        {i18n.t("returns.listItem.remove_product")}
                    </span>
                    <TrashIcon className="w-4 h-4" />
                </Button>
            </td>
        </tr>
    )
};

export default ProductListItem;