import { LabelNumberIcon } from "../../Icons/OrderAttributes/LabelNumberIcon"
import { OrderBuyerIcon } from "../../Icons/OrderAttributes/OrderBuyerIcon"
import { OrderContainsSkuIcon } from "../../Icons/OrderAttributes/OrderContainsSkuIcon"
import { OrderDelayedIcon } from "../../Icons/OrderAttributes/OrderDelayedIcon"
import { OrderNumberIcon } from "../../Icons/OrderAttributes/OrderNumberIcon"
import { OrderSalesChannelIcon } from "../../Icons/OrderAttributes/OrderSalesChannelIcon"
import { OrderShippingDateIcon } from "../../Icons/OrderAttributes/OrderShippingDateIcon"
import { OrderShippingStatusIcon } from "../../Icons/OrderAttributes/OrderShippingStatusIcon"
import { OrderStatusIcon } from "../../Icons/OrderAttributes/OrderStatusIcon"
import { ShippingMethodIcon } from "../../Icons/OrderAttributes/ShippingMethodIcon"
import { OrderChannelCreatedAtIcon } from "../../Icons/OrderAttributes/OrderChannelCreatedAtIcon"
import { ShippingIncidentIcon } from "../../Icons/OrderAttributes/OrderShippingIncidentIcon"
import { EmailAtIcon } from "../../Icons/EmailIAtIcon"
import { AddSkuIcon } from "../../Icons/StoreBusinessRules/AddSkuIcon"
import { AliasIcon } from "../../Icons/StoreBusinessRules/AliasIcon"
import { CategoryIcon } from "../../Icons/StoreBusinessRules/CategoryIcon"
import { ContainsSkuIcon } from "../../Icons/StoreBusinessRules/ContainsSkuIcon"
import { StatusIcon } from "../../Icons/StoreBusinessRules/StatusIcon"
import { ValidityIcon } from "../../Icons/StoreBusinessRules/ValidityIcon"
import { OrderClickAndCollectIcon } from "../../Icons/OrderAttributes/OrderClickAndCollectIcon"

const ICON_CODES = {
    order_status: OrderStatusIcon,
    order_shipping_status: OrderShippingStatusIcon,
    order_number: OrderNumberIcon,
    shipping_label_number: LabelNumberIcon,
    order_sku: OrderContainsSkuIcon,
    sales_channel: OrderSalesChannelIcon,
    order_buyer: OrderBuyerIcon,
    order_shipping_date: OrderShippingDateIcon,
    order_delayed: OrderDelayedIcon,
    shipping_method: ShippingMethodIcon,
    order_channel_created_at: OrderChannelCreatedAtIcon,
    order_shipping_incident: ShippingIncidentIcon,
    order_click_and_collect: OrderClickAndCollectIcon,
    email_at: EmailAtIcon,
    //StoreBusinessRulesIcons
    add_sku: AddSkuIcon,
    alias: AliasIcon,
    category: CategoryIcon,
    contains_sku: ContainsSkuIcon,
    status: StatusIcon,
    expired: ValidityIcon
}

export const FilterIcon = ({ iconCode, ...otherProps }) => {
    return ICON_CODES[iconCode] ? ICON_CODES[iconCode](otherProps) : <></>
}
