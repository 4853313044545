import { TextWithBoldTags } from '../../../../utils/uiUtils';
import { PackingOptionsList } from '../components';
import { FRAGILE_TYPES } from '../packingOptionConstants';

const FragileOptionsSection = ({tabTitleWithTags, packingOptionsList, packingOptionsStatuses, onOptionChange}) => {
    return (
        <section>
            <PackingOptionsList
                packingType={FRAGILE_TYPES.FRAGILE_ONLY}
                packingOptionsList={packingOptionsList}
                packingOptionsStatuses={packingOptionsStatuses}
                onOptionChange={onOptionChange}
                headerComponent={
                    <div className="border rounded-t p-3 bg-gray-50">
                        <TextWithBoldTags textWithTags={tabTitleWithTags} />
                    </div>
                }
            />
        </section>
    )
}

export default FragileOptionsSection
