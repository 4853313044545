class Product {

    /**
     * Return skus for add and skus for delete
     *
     * @param skus {[{label: string, value: string}]}
     * @param product_codes {[{id: integer, sku: string}]}
     * @return skus_for_edit {[{id: integer, sku: string, type: string}]}
     */
    static getSkusForEdit(skus, product_codes){
        const skusForEdit = []
        skus.forEach(sku => {
            const filterSku = product_codes.filter(item=>item.sku === sku.value)
            if(filterSku.length===0){
                skusForEdit.push({
                    sku: sku.value,
                    type: "add"
                })
            }
        })
        
        product_codes.forEach(productCode => {
            const filterSku = skus.filter(sku =>{
                if(sku.value===productCode.sku)
                    return true
                return false
            })
            if(filterSku.length===0){
                skusForEdit.push({
                    id: productCode.id,
                    sku: productCode.sku,
                    type: "delete"
                })
            }
        })

        return skusForEdit
    }

    /**
     *
     * @param products {[{id: integer, product_id: integer, quantity: integer}]} if item contains id is for update, if not containg id is for add
     * @return {[{id: integer, product_id: integer, quantity: integer, tyep: string }]}
     */
    static getProductsForUpdate(products){
        return products.map(item=>{
            if("id" in item){
                return {
                    id: item.id,
                    quantity: item.quantity,
                    price_per_item: item.price_per_item,
                    type: "edit"
                }
            }
            return {
                product_id: item.product_id,
                price_per_item: item.price_per_item,
                quantity: item.quantity,
                type: "add"
            }
        })
    }

    /**
     *
     * @param variants {[{id: integer, skus: [string], barCode: string, edited: boolean, product_codes: [{id: integer, sku: string}]}]}
     */
    static getVariantsForUpdate(variants){
        return variants.filter(item => item.edited===true).map(item => {
            if(item.type===undefined){
                return {
                    id: item.id,
                    bar_code: item.barCode,
                    skus: Product.getSkusForEdit(item.skus, item.product_codes),
                    type: "edit"
                }
            }
            if (item.type==="add"){
                return {
                    name: item.name,
                    bar_code: item.barCode,
                    skus: item.skus.map(i=>{return {sku: i.value, type: "add"}}),
                    type: "add"
                }
            }
        })
    }

    static getType(parent, isKit){
        let type = "single"
        if(parent!=null && isKit===false){
            type = "variants"
        }
        if(parent==null && isKit===true){
            type = "kit"
        }
        return type
    }

}

export default Product