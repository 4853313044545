import {
    ActionMenu,
    BasicTable,
    getSkus,
    getChildsOutofStock,
    getStockContent,
    PageTopBar,
    PageView,
    // SearchInput,
    SelectMenu,
    SlidePanel,
    ConfirmDialog,
} from "../../components";
import { TableImage  } from "../../components/TableImage";
import {useQuery} from "react-query";
import fileDownload from 'js-file-download'
import {
    ImportProductsFromSheet,
    fetchProducts,
    importProducts,
    deleteProducts,
    enqueueProductsBulkCreation, fetchDuplicatedCodes
} from "../../services/productServices";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import {AddProductContainer} from "./AddProduct/AddProductContainer";
import {ListLotsContainer} from "./ListLotsContainer"
import {
    ADD_KIT,
    ADD_PRODUCT,
    EDIT_KIT,
    EDIT_PRODUCT,
    VIEW_LOTS,
    IMPORT_SHOPIFY_PRODUCTS,
    STORE_PATH,
    PRODUCT_BULK_CREATION_TRACKER
} from "../../navigation/constants";
import {resetData} from "../../redux/createProductSlice";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useMemo, useRef, useState, useEffect} from "react";
import { Loader } from "../../components/Loader";
import { DownloadInventory } from "../../components";
import { SelectColumnFilter } from "../../components/BasicTable/modules/ColumnFiltersUI";
import { ExpandibleRow } from "../../components/BasicTable/ExpandibleRow";
import {UserContext} from "../../hooks/UserContext";
import { FlatfileButton } from "@flatfile/react";
import { useTranslation } from 'react-i18next';
import {
    setProductsListPageSize
} from '../../redux/pageSizeSlice';
import { InventoryPill } from "../../components/Products/InventoryPill";
import { ProductTagPill } from "../../components/Products/ProductTagPill";
import {Dropdown, Tooltip, Menu} from "antd";
import { InformationCircleIcon } from "@heroicons/react/outline";
import {ChevronDownIcon} from "@heroicons/react/solid";


const primaryButton = {
    backgroundColor: "#327cfb",
    color: "white",
    border: "1px solid #00c6ff",
    padding: "6px 16px",
    fontSize: "15px",
    borderRadius: "8px",
    ":hover": {
      backgroundColor: "#0D93BA",
      border: "1px solid #0D93BA"
    }
}

const FLOAT_REGEX = "^([1-9]{1}[0-9]{0,}(.[0-9]{0,2})?|0(.(0[1-9]{1}|[1-9]{1}[0-9]{0,1}))|(.(0[1-9]{1}|[1-9]{1}[0-9]{0,1}))?)$"
const WEIGHT_REGEX = "^([1-9]{1}[0-9]{0,}(.[0-9]{0,10})?|0(.(0[1-9]{1}|[1-9]{1}[0-9]{0,10}))|(.(0[1-9]{1}|[1-9]{1}[0-9]{0,10}))?)$"

export const ListProductsContainer = () => {
    const { t,i18n} = useTranslation();
    const unavailableOptions = [
        {id: 'damaged', label: i18n.t(`inventory.listProducts.damaged`)},
        {id: 'expired', label: i18n.t(`inventory.listProducts.expiredLot`)},
        {id: 'holded', label: i18n.t(`inventory.listProducts.holdedLot`)},
        {id: 'reserved_by_returns', label: i18n.t(`inventory.listProducts.by_returns`)},
        {id: 'buffer_stock', label: i18n.t(`inventory.listProducts.by_buffer`)},
    ]
    const history = useHistory()
    const { url:storePath } = useRouteMatch({ path: STORE_PATH })
    const pageSize = useSelector((state) => state.pageSize.productsList)
    const dispatch = useDispatch()
    const {user} = useContext(UserContext)
    const [loadingButton, setLoadingButton] = useState(false)
    const [searchParams, setSearchParams] = useState({
        page: 1,
        per_page: pageSize,
        store_id: user.current_store?.id,
        include_stock: true
    })
    const tableRef = useRef()
    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
        refetch
    } = useQuery(['products', searchParams], () => fetchProducts(searchParams), { keepPreviousData: true }) 

    useEffect(() => {
        let newSearchParams = {...searchParams, store_id: user.current_store?.id}
        setSearchParams(newSearchParams)
    }, [user.current_store?.id])

    useEffect(() => {
        window.analytics.page('Inventory', 'List')
    }, [])

    const [dialog, setDialog] = useState({
        loading: false,
        open: false,
        title: "",
        description: "",
        errorMessage: "",
        type: null
    })

    const StockCell = ({value, row}) => {
        const sum = value?.value === null ? '-' : value.value
        const inWarehouse = value?.inWarehouse === null ? 0 : value?.inWarehouse
        const inPresale = value?.inPresale === null ? 0 : value?.inPresale
        const hidePills = value?.inWarehouse === null || value?.inPresale === null
        
        const sumClass = value?.redNumberLabels && (sum <= 0) ? "font-bold text-red-500" : "font-bold"
        const innerClassInWarehouse = inWarehouse === 0 ? "bg-gray-100 text-gray-400" : inWarehouse < 0 ? "bg-red-100 text-red-600" : "bg-indigo-100 text-indigo-600"
        const innerClassInPresale = inPresale === 0 ? "bg-gray-100 text-gray-400" : "bg-purple-100 text-purple-600"
        // const inReturns = value?.inReturns

        return (
            <div className="flex flex-row justify-between">
                <div className="flex items-center mr-2">
                    <div className={sumClass}>{sum}</div>
                </div>
                <div className="flex flex-col items-end">
                    <InventoryPill className={hidePills ? "mb-1 invisible" : "mb-1"} innerClassName={innerClassInWarehouse}>{inWarehouse} {t("inventory.listProducts.inWarehouse")}</InventoryPill>
                    <InventoryPill className={hidePills ? "invisible" : ""} innerClassName={innerClassInPresale}>{inPresale} {t("inventory.listProducts.inPresale")}</InventoryPill>
                    {/* { inReturns != null && inReturns != 0 && !hidePills &&
                        <InventoryPill className={hidePills ? "hidden" : "mt-1"} innerClassName="bg-pink-100 text-pink-800">{inReturns} {t("inventory.listProducts.inReturns")}</InventoryPill>
                    } */}
                </div>
            </div>
        )
    }

    const UnavailableStockCell = ({value, row}) => {
        const sum = value?.value === null ? '-' : value.value
        const damaged = value?.damaged === null ? 0 : value?.damaged
        const holded = value?.holded
        const expired = value?.expired
        const reservedByReturns = value?.reservedByReturns === null ? 0 : value?.reservedByReturns
        const buffer = value?.buffer
        const hidePills = value?.damaged === null && value?.reservedByReturns === null && value?.buffer === null
        
        const sumClass = sum > 0 ? "bg-indigo-100 text-indigo-600 w-full rounded-xl text-left" : "bg-gray-100 text-gray-400 w-full rounded-xl text-left"
        const innerClassDamaged = damaged === 0 ? "bg-gray-100 text-gray-400" : "bg-purple-100 text-purple-600"
        const innerClassReservedByReturns = reservedByReturns === 0 ? "bg-gray-100 text-gray-400" : "bg-purple-100 text-purple-600"
        const innerClassBuffer = buffer === 0 || buffer === null ? "bg-gray-100 text-gray-400" : "bg-purple-100 text-purple-600"
        // const inReturns = value?.inReturns
        
        return (
            (sum === '-' ? '-' : 
            <div className="">
                <div className={"flex flex-col items-start mr-2 rounded-xl border border-t-0 " + (sum > 0 ? "border-indigo-100" : "border-gray-100")}>
                    <InventoryPill className={sumClass}>{sum} {t("inventory.listProducts.inWarehouse")}</InventoryPill>
                    <span className="text-xs text-gray-400 ml-2 mt-1">{damaged} {t("inventory.listProducts.damaged")}</span>
                    <span className="text-xs text-gray-400 ml-2 mt-1">{reservedByReturns} {t("inventory.listProducts.by_returns")}</span>
                    {holded !== null && <span className="text-xs text-gray-400 ml-2 mt-1">{holded} {t("inventory.listProducts.byHoldedLot")}</span>}
                    {expired !== null && <span className="text-xs text-gray-400 ml-2 mt-1">{expired} {t("inventory.listProducts.byExpiredLot")}</span>}
                    {value?.buffer > 0 &&  
                        <span className="text-xs text-gray-400 mx-2 mt-1">{buffer} {t("inventory.listProducts.by_buffer")}
                            <Tooltip title={
                                <>
                                    {t("inventory.listProducts.buffer_tip.text")}&nbsp;
                                    <a target="_blank" href={t("inventory.listProducts.buffer_tip.buffer_tip_url")}>{t("inventory.listProducts.buffer_tip.buffer_tip_link")}</a>
                                </>
                            }>
                                <InformationCircleIcon className="inline w-4 h-4 ml-1 mb-1" />
                            </Tooltip>
                        </span>}
                </div>
            </div>)
        )
    }

    const columns = [
        {
            Header: "inventory.listProducts.image",
            accessor: 'image',
            disableFilters: true,
            disableSortBy: true,
            shrinkToContent: true,
            noPadding: true
                
        },
        {
            // Build our expander column
            id: 'name', // Make sure it has an ID
            accessor: 'name',
            Header: "inventory.listProducts.name",
            disableFilters: false,
            disableSortBy: false,
            disableWrap: true,
            Cell: ({ value, row }) =>
                // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                // to build the toggle for expanding a row
                row.canExpand ? (
                    <ExpandibleRow text={value} expanded={row.isExpanded} {...row.getToggleRowExpandedProps()}/>
                ) : (value)
        },
        {
            Header: 'SKU',
            accessor: 'sku',
            disableFilters: false,
            disableSortBy: true
        },
        {
            Header: 'UPC',
            accessor: 'bar_code',
            disableFilters: false,
            disableSortBy: true
        },
        {
            Header: "inventory.listProducts.available",
            accessor: 'stock.AVAILABLE',
            disableFilters: true,
            disableSortBy: false,
            shrinkToContent: true,
            Cell: StockCell
        },
        {
            Header: "inventory.listProducts.orders",
            accessor: 'stock.RESERVED_BY_ORDERS',
            disableFilters: true,
            disableSortBy: false,
            shrinkToContent: true,
            Cell: StockCell
        },
        {
            Header: "inventory.listProducts.unavailable",
            accessor: 'stock.UNAVAILABLE',
            shrinkToContent: true,
            searchAs: 'filter_unavailable',
            Cell: UnavailableStockCell,
            Filter: SelectColumnFilter,
            selectFilterOptions: unavailableOptions,
            disableFilters: false,
            disableSortBy: false,
        },
        // {
        //     Header: "Estado",
        //     accessor: "status",
        //     Filter: SelectColumnFilter,
        //     selectFilterOptions: [
        //         {id: 'active', label: 'Activo'}, 
        //         {id: 'depleted', label: 'Agotado'}, 
        //         {id: 'low_stock', label: 'Poca Existencia'}
        //     ],
        //     disableFilters: false,
        //     disableSortBy: false
        // },
        {
            Header: "",
            fetchingIndicator : true,
            accessor: 'actions',
            disableFilters: true,
            disableSortBy: true,
            shrinkToContent: true
        },
    ]

    function canProductBeDeleted(product){
        return product.stock.IN_WAREHOUSE === 0 || product.is_kit
    }

    function getImportProductsConfig() {

        if (warehouse.country ==='BR'){
            return [
                { label: i18n.t("inventory.listProducts.name"), key: "name", validators: [
                    { validate: "required" }
                ] },

                { label: "UPC", key: "upc", validators: [
                    { validate: "required" },
                    { validate: "unique" }
                ] },
                { label: "SKU", key: "sku", validators: [
                    { validate: "required" },
                    { validate: "unique" }
                ] },
                { label: "Precio", key: "price", validators: [
                        { validate: "required" },
                        {
                            validate: "regex_matches",
                            regex: FLOAT_REGEX,
                            error: i18n.t("inventory.listProducts.price_error")
                        }
                ]},
                { label: "Peso", key: "weight",
                    validators: [
                        {
                            validate: "required"
                        },
                        {
                            validate: "regex_matches",
                            regex: WEIGHT_REGEX,
                            error: i18n.t("inventory.listProducts.weight_error")
                        }]
                },
                { label: "Código NCM", key: "tax_code", validators: [
                    { validate: "required" }
                ]},
                { label: "Image URL", key: "image_url"},
                { label: i18n.t("inventory.listProducts.description"), key: "description"},
            ]
        } else {
            return [
                { label: i18n.t("inventory.listProducts.name"), key: "name", validators: [
                    { validate: "required" }
                ] },
                { label: "UPC", key: "upc", validators: [
                    { validate: "required" },
                    { validate: "unique" }
                ] },
                { label: "SKU", key: "sku", validators: [
                    { validate: "required" },
                    { validate: "unique" }
                ] },
                { label: i18n.t("inventory.listProducts.name_invoices"), key: "billing_name"},
                { label: i18n.t("inventory.listProducts.description"), key: "description"},
                { label: "Precio",
                    key: "price",
                    validators: [
                        {
                            validate: "regex_matches",
                            regex: FLOAT_REGEX,
                            error: i18n.t("inventory.listProducts.price_error")
                        }
                    ]
                },
                { label: "Image URL", key: "image_url"}
            ]
        }
    }

    const isFragile = (product) => {
        const is_any_child_fragile = product?.kit_childs?.find(child => child.child_product.is_fragile) || product.is_fragile
        return is_any_child_fragile
    }

    function getName(product) {
        return (
            <>
                { product.name }{ (product.is_dropshipping || product.is_kit || product.is_packageless || product.has_imei || isFragile(product) || !product.is_scannable) && <br/>}
                { product.is_dropshipping && <ProductTagPill className="bg-red-100 text-red-800 mr-2">{t("inventory.listProducts.dropshipping")}</ProductTagPill>}
                { product.is_kit && <ProductTagPill className="bg-blue-100 text-blue-800 mr-2">{t("inventory.listProducts.kit")}</ProductTagPill>}
                { product.is_packageless && <ProductTagPill className="bg-indigo-100 text-indigo-700 mr-2">{t("inventory.listProducts.packageless")}</ProductTagPill>}
                { product.has_imei && <ProductTagPill className="bg-indigo-100 text-indigo-700 mr-2">{t("inventory.listProducts.has_imei")}</ProductTagPill>}
                { isFragile(product) && <ProductTagPill className="bg-yellow-100 text-yellow-800 mr-2">{t("inventory.listProducts.fragile")}</ProductTagPill>}
                { !product.is_scannable && <ProductTagPill className="bg-green-100 text-green-600">{t("inventory.listProducts.promoInsert")}</ProductTagPill>}
            </>
        )
    }
    

    const onSortChange = (orderBy) => {
        // console.log("ON_ORDER_BY",orderBy)
        if (orderBy.length > 0) {
            setSearchParams({
                ...searchParams,
                order_by: orderBy[0].id, 
                order: orderBy[0].desc ? 'desc' : 'asc',
                page:1
            })
        }
        else {
            if ('order_by' in searchParams) {
                delete searchParams.order_by
                delete searchParams.order
                setSearchParams({...searchParams, page:1})
            }
        }
        tableRef.current.resetPagination()
    }
    
    const handleImportProducts = async (addedProducts) => {
        const batch = await enqueueProductsBulkCreation(user.current_store?.id, addedProducts)
        history.push(storePath + PRODUCT_BULK_CREATION_TRACKER.replace(':batch_id', batch.batch_id))

        return true
    }

    const onConfirmDialogHandler = async () => {
        setDialog({...dialog, loading: true})
        let errorOcurred = false
        window.analytics.track('Inventory - Deletes Product', {id: dialog.product.id})
        try {
          await deleteProducts({id: [dialog.product.id]})
        } catch (error) {
            errorOcurred = true
            const errorMessage = error.response.data?.code ? i18n.t(`inventory.listProducts.${error.response.data?.code}`) : error.response.data?.message || error.response.data?.error
            setDialog({...dialog,     
                errorMessage: errorMessage,
            })
        }
        if(!errorOcurred) setDialog({...dialog, loading: false, open: false})
        refetch()
    }
    
    const onFilterChange = (activeFilters) => {
        // console.log("ON_CHANGE_FILTER", activeFilters)
        columns.forEach(col => {
            if (searchParams[col.searchAs || col.accessor]) {
                delete searchParams[col.searchAs || col.accessor]
            }
        });
        let filters = []
        activeFilters.forEach(filter => {
            const searchAs = columns.find(col => col.accessor == filter.id).searchAs || filter.id
            filters[searchAs] = filter.value
        })
        tableRef.current.resetPagination()
        setSearchParams({...searchParams, ...filters, page:1})
        // console.log("searchParams",searchParams)
    }

    const getTableData = () => {
        return data?.products.map(product => {
            return {
                ...product,
                image:
                <div className='flex justify-center py-2'>
                    <TableImage 
                        thumbnail_image= {product.product_image_path?.thumbnail_path}
                        regular_image= {product.product_image_path?.path}
                    />
                </div>,
                name: getName(product),
                sku: getSkus(product.product_codes),
                bar_code: (product.bar_code),
                isParent: true,
                stock: getStockContent(product),
                actions: (
                    <ActionMenu className="float-right" items={[
                        {
                            title: i18n.t("inventory.listProducts.edit_product"),
                            clickHandler: () => {
                                dispatch(resetData())
                                if(product.is_kit===true){
                                    window.analytics.track('Inventory - Starts Editing Kit', {id: product.id})
                                    history.push( storePath + EDIT_KIT.replace(":id", product.id))
                                } else {
                                    window.analytics.track('Inventory - Starts Editing Product', {id: product.id})
                                    history.push( storePath + EDIT_PRODUCT.replace(":id", product.id))
                                }
                            }
                        },
                        {
                            title: i18n.t("lots.view_lots"),
                            clickHandler: () => {
                                window.analytics.track('Inventory - Views Lots', {id: product.id})
                                dispatch(resetData())
                                history.push( storePath + VIEW_LOTS.replace(":id", product.id))
                            }
                        },
                        {
                            disabled: !canProductBeDeleted(product),
                            tooltip: {
                                show: !canProductBeDeleted(product),
                                title: i18n.t("inventory.listProducts.product_has_stock"),
                                placement: 'left'
                            },
                            title: i18n.t("inventory.listProducts.delete_product"),
                            clickHandler: () => {
                                dispatch(resetData())
                                setDialog({...dialog,
                                    open: true,
                                    title: i18n.t("inventory.listProducts.delete_product"),
                                    description: i18n.t("inventory.listProducts.sure_to_delete") + product.name,
                                    type: "cancel",
                                    errorMessage: '',
                                    product: product
                                })
                            }
                        }
                    ]}/>
                )
            }
        })
    }

    const priceHook = function (values) {
        const updatedValues = []

        values.forEach(([price, index]) => {
            const updated_version = price.replace(/,/g, '')

            if (updated_version !== price)
                updatedValues.push([
                    { value: updated_version, info: [{ message: i18n.t("inventory.listProducts.updated_price"), level: "warning" }] },
                    index,
                ])
        })

        return updatedValues
    }

    const codesHook = async function (codes) {
        const hook_results = []

        const duplicatedCodes = await fetchDuplicatedCodes(
            codes.map(([code, index]) => code.trim()).filter(Boolean),
            user.current_store?.id
        )

        const noDuplicatesFound = !duplicatedCodes.duplicated_bar_codes.length && !duplicatedCodes.duplicated_skus.length

        if (noDuplicatesFound)
            return hook_results

        codes.forEach(([code, index]) => {
            let isBarCodeDuplicate = false

            if (duplicatedCodes.duplicated_bar_codes.includes(code)) {
                hook_results.push([
                    { info: [{ message: i18n.t("inventory.listProducts.duplicated_bar_code", { codigo: 'UPC' }), level: "error" }] },
                    index,
                ])

                isBarCodeDuplicate = true
            }

            if (!isBarCodeDuplicate && duplicatedCodes.duplicated_skus.includes(code)) {
                hook_results.push([
                    { info: [{ message: i18n.t("inventory.listProducts.duplicated_sku",  { codigo: 'SKU' }), level: "error" }] },
                    index,
                ])
            }

        })

        return hook_results
    }

    const memoizedTableData = useMemo(() => getTableData(), [data, t])

    const CreateProductsOptions = () => (
        <Menu>
            <Menu.Item>
                <FlatfileButton
                    licenseKey="8096b433-6f21-43de-ab84-c07472944581"
                    customer={{ userId: "12345" }}
                    settings={{
                        type: "Import Products",
                        fields: getImportProductsConfig(),
                        theme: {
                            buttons: {
                                primary: primaryButton
                            }
                        },
                        managed: true,
                        autoDetectHeaders: true,
                        title: i18n.t("inventory.listProducts.import_products"),
                        devMode: false
                    }}
                    onData={async (results) => {
                        await handleImportProducts(results.data)
                        return i18n.t("inventory.listProducts.imported");
                    }}
                    render={(importer, launch) => {
                        return <a className="cursor-pointer" onClick={() => {
                            window.analytics.track('Inventory - Starts Creating Product', {type: "BULK EXCEL"})
                            launch()
                        }}>{i18n.t("inventory.listProducts.import")}</a>
                    }}
                    fieldHooks={{
                        price: prices => priceHook(prices),
                        sku: skus => codesHook(skus),
                        upc: upcs => codesHook(upcs)
                    }}
                />
            </Menu.Item>
            <Menu.Item>
                <a className="cursor-pointer" onClick={() => {
                    window.analytics.track('Inventory - Starts Creating Product', {type: "PRODUCT FORM"})
                    dispatch(resetData())
                    history.push(storePath + ADD_PRODUCT)
                }
                }>
                    {i18n.t("inventory.listProducts.create_product")}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a className="cursor-pointer" onClick={() => {
                    window.analytics.track('Inventory - Starts Creating Kit', {type: "PRODUCT FORM"})
                    dispatch(resetData())
                    history.push(storePath + ADD_KIT)
                }
                }>
                    {i18n.t("inventory.listProducts.create_kit")}
                </a>
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            <ConfirmDialog
                open={dialog.open}
                setOpen={(value) => setDialog({...dialog, open: value}) }
                title={dialog.title}
                description={dialog.description}
                confirmLabel="Si"
                errorMessage={dialog.errorMessage}
                onConfirm={onConfirmDialogHandler}
                loading={dialog.loading}
            />
            <PageView
                topMenu={<PageTopBar>
                    <div className="text-lg font-semibold"><span className="text-gray-400">{ user.current_store ? user.current_store.name : i18n.t("inventory.listProducts.all_stores") } &rarr; </span>{i18n.t("inventory.listProducts.inventory")}</div>
                    { user.current_store &&
                        <div className="flex gap-5">
                            <DownloadInventory />

                            <Dropdown disabled={false} overlay={CreateProductsOptions} placement="bottomLeft" className="relative inline-block text-left">
                                <button className="ml-2 mt-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm whitespace-nowrap force-5px-border-radius">
                                    {i18n.t("inventory.listProducts.create_products")}
                                    <ChevronDownIcon
                                        className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                                        aria-hidden="true"
                                    />
                                </button>
                            </Dropdown>
                        </div>
                    }
                </PageTopBar>}
            >
                {!isPreviousData && isLoading ? (
                    <><Loader show={true}></Loader></>
                ) : isError ? (
                    <>Error: {error.message}</>
                ) : (
                    <BasicTable
                        labelToPaginator={i18n.t("inventory.listProducts.products")}
                        // titleOptions={{
                        //     titleText: 'Inventario',
                        //     subtitleText: 'Lista de productos e inventario',
                        //     buttonText: 'Nuevo Producto',
                        //     buttonOnClick: () => {console.log("TITLE BUTTON CLICKED!!")}
                        // }}
                        onFilterChange={onFilterChange}
                        onSortChange={onSortChange}
                        columns={columns}
                        showPaginator
                        showHeader
                        filterable
                        sortable
                        paginationMeta={data?.meta}
                        onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
                        onPageSizeChange= { (pageSize) => {
                            setSearchParams({...searchParams, per_page: pageSize, page: 1})
                            dispatch(setProductsListPageSize(pageSize))
                            tableRef.current.resetPagination()
                        }}
                        pagesSize = {searchParams.per_page}
                        showLoader={isFetching && isPreviousData}
                        isFetching = {isFetching}
                        data = {memoizedTableData}
                        emptyTableText= {i18n.t("inventory.listProducts.no_products_to_show")} 
                        ref = {tableRef}
                    />
                )}
            </PageView>
            <Switch>
                <Route exact path={STORE_PATH + ADD_PRODUCT}>
                    <SlidePanel title= {i18n.t("inventory.listProducts.create_product")} >
                        <AddProductContainer type="product"/>
                    </SlidePanel>
                </Route>
                <Route exact path={STORE_PATH + EDIT_PRODUCT}>
                    <SlidePanel title= {i18n.t("inventory.listProducts.edit_product")}>
                        <AddProductContainer type="product" edit={true}/>
                    </SlidePanel>
                </Route>
                <Route exact path={STORE_PATH + VIEW_LOTS}>
                    <SlidePanel title= {i18n.t("lots.view_lots")}>
                        <ListLotsContainer type="product" edit={true}/>
                    </SlidePanel>
                </Route>
                <Route exact path={STORE_PATH + ADD_KIT}>
                    <SlidePanel title= {i18n.t("inventory.listProducts.create_kit")}>
                        <AddProductContainer type="kit"/>
                    </SlidePanel>
                </Route>
                <Route exact path={STORE_PATH + EDIT_KIT}>
                    <SlidePanel title= {i18n.t("inventory.listProducts.see_kit")}> 
                        <AddProductContainer type="kit" edit={true}/>
                    </SlidePanel>
                </Route>
            </Switch>
        </>
    )
}