import { Grid, Col } from "@tremor/react"
import { BaseCard } from "../../../../components/MetricsCards/BaseCard"
import { MetricsCardHeader } from "../../../../components/MetricsCards/MetricsCardHeader"
import { INTERVAL_DAY } from "../chartDataUtils"
import { useTranslation } from "react-i18next"
import { OrdersSalesChannelHistoric } from "./OrdersSalesChannelHistoric"
import { OrdersSalesChannel } from "./OrdersSalesChannel"
import useOnScreen from "../../../../hooks/useOnScreen"
import { useEffect, useRef } from "react"

export const SalesChannel = ({ storeId = null, dateFilter = null, interval = INTERVAL_DAY }) => {
    const { t } = useTranslation()

    const cardRef = useRef(null)
    const cardIsOnScreen = useOnScreen(cardRef)
    useEffect(() => {
      if (cardIsOnScreen) window.analytics.track("Metrics - Views SalesChannels")
    }, [cardIsOnScreen])

    return (
        <BaseCard className="my-6" ref={cardRef}>
            <MetricsCardHeader
                title={t("metrics.sales_channel.title")}
                subtitle={t("metrics.sales_channel.subtitle")}
            />
            <Grid numItemsSm={1} numItemsLg={3} className="w-full gap-10 lg:gap-x-20 py-5">
                <Col numColSpan={1}>
                    <OrdersSalesChannel storeId={storeId} dateFilter={dateFilter} />
                </Col>
                <Col numColSpanLg={2}>
                    <OrdersSalesChannelHistoric storeId={storeId} dateFilter={dateFilter} interval={interval} />
                </Col>
            </Grid>
        </BaseCard>
    )
}
