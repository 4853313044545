/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import PropTypes from "prop-types";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const SelectInputShippingMethods = ({
    options= [{id: 0, shipping_name: "title", carrier_name:"subtitle"}],
    selectedInitial= 0,
    className= "",
    label,
    error = null,
    onChange = (value) => console.log("onChange", value),
    required = false
}) => {

    SelectInputShippingMethods.propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.any.isRequired,
            shipping_name: PropTypes.string.isRequired
        })).isRequired,
        label: PropTypes.string.isRequired,
        selectedInitial: PropTypes.shape({
            id: PropTypes.any,
            shipping_name: PropTypes.string
        }).isRequired,
        className: PropTypes.string,
        onChange: PropTypes.func
    }

    const [selected, setSelected] = useState(selectedInitial)

    const handleOnChange = (value) => {
        setSelected(value)
        onChange(value)
    }

    return (
        <div className={className}>
            <Listbox value={selected} onChange={handleOnChange} >
                {({ open }) => (
                    <>
                        <Listbox.Label className="block text-sm font-medium text-gray-700">{label}{required && <span className="text-red-500"> *</span>}</Listbox.Label>
                        <div className="mt-1 relative">
                            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <span className="block truncate">{selected.carrier_name} - {selected.shipping_name} - {selected.country}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options
                                    static
                                    className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                    {options.map((person) => (
                                        <Listbox.Option
                                            key={person.id}
                                            className={({ active }) =>
                                                classNames(
                                                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                    'cursor-default select-none relative py-2 pl-8 pr-4'
                                                )
                                            }
                                            value={person}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                    {person.carrier_name} - {person.shipping_name}  - {person.country}
                                                    </span>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-white' : 'text-indigo-600',
                                                                'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                                            )}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                        {error && <label className="text-red-400">{error}</label>}
                    </>
                )}
            </Listbox>
        </div>
    )
}