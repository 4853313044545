import React, { useMemo } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { getConfigurations, updateConfigurations,fetchStoreAccounts} from "../../services/storeServices"
import {useContext, useState, useEffect, useRef} from "react";
import { useTranslation } from 'react-i18next';
import { Button, ButtonLoader, Notification, InputField } from "../../components";
import { NOTIFICATION_SETTINGS_KEYS} from "./constants";
import {useForm, Controller} from "react-hook-form";
import { Tooltip, Select } from "antd"
import {useQuery} from "react-query";
import { UserContext } from "../../hooks/UserContext";
import { Loader } from "../../components/Loader";
import _ from "lodash";
import { use } from "i18next";

export const AlertsSettingsContainer = () => {
  const {register, handleSubmit,formState: { errors }} = useForm({})

  const { t } = useTranslation();
  const {user} = useContext(UserContext)
  const [errorNotification, setErrorNotification] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [loadingBundleBotton, setLoadingBundleBotton] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [configurationData, setConfigurationData] = useState({});
  const [emailNotificationsData, setEmailNotificationsData] = useState({});
  const [inputErrors, setInputErrors] = useState({});

  const {
    isLoading,
    isError,
    error,
    data,
    isPreviousData,
    refetch,
  } = useQuery(['store_notifications_configuration', user.current_store?.id], () => getConfigurations(user.current_store?.id, null, true), { keepPreviousData: true })
  
  const {
    isLoading: usersIsLoading,
    isError: usersIsError,
    error: usersError,
    data: usersData,
    isPreviousData: usersIsPreviousData,
    refetch: usersRefetch,
} = useQuery(['user_config', user.current_store?.id], () => fetchStoreAccounts(user.current_store?.id), { keepPreviousData: true })

  useEffect(() => {
    if(data != null){
      setInputErrors({})
      mappingUsers()
    }
  }, [data])

  const getUsersAcount = () =>{
    const all_emails_account = usersData?.users?.map(user => user.email) || []
    return all_emails_account
  }
  
  const configurations = useMemo(() => {
    let configuration = {}
    Object.keys(emailNotificationsData).forEach(key => {
      let new_data = buildEditedEmails(emailNotificationsData[key],getUsersAcount())
      if(JSON.stringify(new_data) != JSON.stringify(["ALL_USERS_IN_ACCOUNT"]) ){
        configuration[key]=new_data
      }
    });
  return configuration
  }, [emailNotificationsData])

  const mappingUsers = () =>{
    let preload_configuration_data =  Object.keys(data.email_notifications).reduce((resultado,key) => {
      if (NOTIFICATION_SETTINGS_KEYS.has(key)) {
        let notifications_configuration = data.email_notifications[key]
        let emails = []
        if (notifications_configuration == null ){
          emails = getUsersAcount()
        }else if(Array.isArray(notifications_configuration) && notifications_configuration.includes("ALL_USERS_IN_ACCOUNT")){
          notifications_configuration = notifications_configuration.filter(elemento => elemento !== "ALL_USERS_IN_ACCOUNT");
          emails = getUsersAcount().concat(notifications_configuration);
        }else{
          emails = notifications_configuration
        }
        resultado[key] = emails;
      }
      return resultado;
    }, {})
    Object.keys(data.email_notifications).sort()
    setEmailNotificationsData(preload_configuration_data)
  }

  const validData = () => {
    let was_edited = false
    Object.keys(emailNotificationsData).forEach(key => {
      if(JSON.stringify(data.email_notifications[key]) != JSON.stringify(configurations[key]) && configurations[key] ){
        was_edited = true
        return
      }
    })
    return !was_edited
  }

  function buildEditedEmails(configured_notification_emails, all_users_in_account) {
    var contieneTodos = all_users_in_account.every(function(elemento) {
      return configured_notification_emails.includes(elemento);
    });  
    if (contieneTodos) {
      var nuevoArray = ["ALL_USERS_IN_ACCOUNT"].concat(configured_notification_emails.filter(function(elemento) {
        return !all_users_in_account.includes(elemento);
      }));
      return nuevoArray;
    }
    return configured_notification_emails;
  }

  const onSubmit = async () => {
    updateConfigurations(user.current_store?.id, configurations).then((response)=>{
      setShowNotification(true)
      refetch()
    }).catch((e)=>{
      setErrorNotification(true)
    })
  }

  const validEmail = (email, key) => {
    let exReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let valid = exReg.test(email)
    setInputErrors({...inputErrors, [key]: !valid})
    return valid;
  }
  
  const handleChange = (value, key) => {
    let values = []
    let string_values = value.toString()
    if (string_values != '') {
      values = string_values.split(",");
    }
    values = values.filter(email => validEmail(email, key));
    setEmailNotificationsData({...emailNotificationsData, [key]: values})
  };

  return (
    <div className='relative p-10'>
      <Notification show={showNotification} 
        setShow={setShowNotification} 
        type="success" 
        title={t("settings.alerts_container.done")}/>
        <Notification 
        show={errorNotification} 
        setShow={setErrorNotification} 
        type="error" 
        title={t("settings.alerts_container.error")}/>
      <form action="#" method="PATCH" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <p className='text-3xl mb-0 font-bold text-blue-gray-900 '>{t("settings.alerts.title_sbr")}</p>
            <p className = "mt-5  mb-0  text-lg text-gray-500">{t("settings.alerts.sub_title")}</p>
          </div>
          <div>
            {/* <ButtonLoader type="secondary" className="mr-3 p-4 rounded-lg" disabled={validData()} onClick={() => resetNotificationsConfig()}>
              {t("settings.tracking_emails.cancel")}
            </ButtonLoader> */}
            <ButtonLoader className="" loading_text={t("settings.tax_fields.processing")} loading={loadingBundleBotton} disabled={validData()}>
              {t("settings.tax_fields.send_form")}
            </ButtonLoader>
          </div>
        </div>

        { !isPreviousData && isLoading && !data && !emailNotificationsData ? (
            <><Loader show={true}></Loader></>
        ): isError ? (
            <>Error: {error.message}</>
        ):(
          <div className="w-full">
            <div className="mt-10">
              <div className="mb-8">
                <div className="max-w-full min-w-min">

                  {Object.keys(emailNotificationsData).map(notification_type => {
                    return(
                      <div className="bg-white shadow sm:rounded-lg border-gray-200 rounded-2xl grid grid-row p-5 mb-5">
                        <p className = "mt-0 mb-3 text-lg font-bold text-gray-900"> {t(`settings.alerts_container.categories.${notification_type}.title`)} </p>
                        <p className = "mt-0 mb-2 text-sm font-regular text-gray-900">{t(`settings.alerts_container.categories.${notification_type}.label`)}</p>
                        
                        <Select
                          mode="tags"
                          placeholder={t("settings.alerts_container.place_holder")}
                          className="w-full"
                          suffixIcon={null}
                          tokenSeparators={[" "]}
                          value={emailNotificationsData[notification_type]}
                          onChange={(event)=>handleChange(event, notification_type)}
                          open={false}
                        />
                        {inputErrors[notification_type] && <p className="text-sm text-red-600">{t("settings.alerts_container.error_message_email")}</p>}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center justify-between">
          <div className="space-y-1">
          </div>
          <div>
            {/* <ButtonLoader type="secondary" className="mr-3 p-4 rounded-lg" disabled={validData()} onClick={() => resetNotificationsConfig()}>
              {t("settings.tracking_emails.cancel")}
            </ButtonLoader> */}
            <ButtonLoader className="" loading_text={t("settings.tax_fields.processing")} loading={loadingBundleBotton} disabled={validData()}>
              {t("settings.tax_fields.send_form")}
            </ButtonLoader>
          </div>
        </div>
      </form>
    </div>
  );
}
