import cubboLogo from "../../assets/logo-2.png"
import {useState, useRef} from "react";
import {useHistory, Link, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Notification} from "../../components";
import useAuth from "../../hooks/useAuth";
import { ROOT, FORGOT_PASSWORD, LOGIN, SHOPIFY_ONBOARDING } from "../../navigation/constants";
import {signUp} from "../../services/loginServices"
import { useTranslation } from 'react-i18next'

const LOWERCASE_REGEX = /[a-z]/
const UPPERCASE_REGEX = /[A-Z]/
const NUMBERS_REGEX = /[0-9]/
const SPECIAL_CHARS_REGEX = /[!@#$%^&*]/

export const SignUp = () => {

  const { t, i18n } = useTranslation(); 
  const {register, handleSubmit, watch, formState: { errors }} = useForm({})
  const [creatingLoader, setCreatingLoader] = useState(false)

  const password = useRef({});
  password.current = watch("password", "");

  const query_string = useLocation().search;
  const shopify_state = new URLSearchParams(query_string).get('shopify_state');

  const history = useHistory()

  const [showNotification, setShowNotification] = useState(false)

  const [loginUser, isLoading, isError] = useAuth()

  const onSubmit = (data) => {
      setCreatingLoader(true)
      const {email, password} = data
      signUp(data).then((user)=>{
        loginUser({email, password}).then((user)=>{
          setCreatingLoader(false)
          if (shopify_state) {
            history.push(SHOPIFY_ONBOARDING+query_string)
          }
          else if (user.current_store) {
              history.push("/")
          }
          else {
              history.push(ROOT)
          }
      }).catch((e)=>{
          setCreatingLoader(false)
          setShowNotification(true)
      })
      }).catch((e)=>{
          setCreatingLoader(false)
          setShowNotification(true)
      })
  }

  const validatePasswordStrength = (v) => {
    let passedTests = 0
    if (LOWERCASE_REGEX.test(v)) {
      passedTests += 1
    }
    if (UPPERCASE_REGEX.test(v)) {
      passedTests += 1
    }
    if (NUMBERS_REGEX.test(v)) {
      passedTests += 1
    }
    if (SPECIAL_CHARS_REGEX.test(v)) {
      passedTests += 1
    }
    // console.log("passedTests: ", passedTests)
    if (passedTests >= 3) {
      return true
    }
    return i18n.t("signup.pass_strength")
  }


  return (
          <div className="min-h-screen bg-light-blue-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={cubboLogo}
            alt="Workflow"
          />
          
        </div>
  
        <div className="mt-16 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h2 className="mt-3 mb-3 text-center text-2xl text-gray-900">{i18n.t("signup.title")}</h2>
          <p className="text-center text-gray-500 mb-12">{i18n.t("signup.subtitle")}</p>
          <form action="#" method="POST" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              
            <div>
              <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              {i18n.t("signup.country")}
              </label>
              <select
                id="country"
                name="country"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue="MX"
                {...register("country", {
                  required: i18n.t("signup.required_field"),
                })}
              >
                <option value="BR">Brasil</option>
                <option value="CO">Colombia</option>
                <option value="MX">México</option>
              </select>
            </div>

              <div>
                <label htmlFor="store_name" className="block text-sm font-medium text-gray-700">
                {i18n.t("signup.brand_name")}
                </label>
                <div className="mt-1">
                <input
                      name="store_name"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      {...register("store_name", {
                          required: i18n.t("signup.required_field"),
                      })}
                  />
                  <p className="mt-2 text-sm text-red-600">
                      {errors.store_name && errors.store_name.message}
                  </p>
                </div>
              </div>

              <div>
                <label htmlFor="user_name" className="block text-sm font-medium text-gray-700">
                {i18n.t("signup.user_name")}
                </label>
                <div className="mt-1">
                <input
                      name="user_name"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      {...register("user_name", {
                          required: i18n.t("signup.required_field"),
                      })}
                  />
                  <p className="mt-2 text-sm text-red-600">
                      {errors.user_name && errors.user_name.message}
                  </p>
                </div>
              </div>
              
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {i18n.t("signup.user_email")}
                </label>
                <div className="mt-1">
                <input
                      type="text"
                      name="email"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      {...register("email", {
                          required: i18n.t("signup.required_field"),
                          pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: i18n.t("signup.invalid_address"),
                        }
                      })}
                  />
                  <p className="mt-2 text-sm text-red-600">
                      {errors.email && errors.email.message}
                  </p>
                </div>
              </div>
  
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                {i18n.t("signup.user_pwd")}
                </label>
                <div className="mt-1">
                  <input
                      name="password"
                      type="password"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      {...register("password", {
                          required: i18n.t("signup.required_field"),
                          minLength: {
                            value: 8,
                            message: i18n.t("signup.pwd_characters")
                          },
                          validate: {
                            passwordStrength: v => validatePasswordStrength(v)
                          }
                      })}
                  />
                  <p className="mt-2 text-sm text-red-600">
                      {errors.password && errors.password.message}
                  </p>
              </div>
              </div>

              <div>
                <label htmlFor="confirm_password" className="block text-sm font-medium text-gray-700">
                {i18n.t("signup.confirm_pwd")}
                </label>
                <div className="mt-1">
                  <input
                      name="confirm_password"
                      type="password"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      {...register("confirm_password", {
                          required: i18n.t("signup.required_field"),
                          validate: value =>
                            value === password.current || i18n.t("signup.pwd_no_coincide")
                          })}
                  />
                  <p className="mt-2 text-sm text-red-600">
                      {errors.confirm_password && errors.confirm_password.message}
                  </p>
              </div>
              </div>
  
              <div>
                {/* <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Sign in
                </button> */}
                {creatingLoader ? (
                    i18n.t("signup.creating_account")
                ) : (
                    <>
                        <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {i18n.t("signup.button")}
                        </button>
                    </>
                )}
                {showNotification? (
                            <>
                                <Notification show={showNotification} setShow={setShowNotification} type="error" title="Error" description="Datos inválidos"/>
                            </>
                ) : null}
              </div>
            </form>
  
  
          </div>

          <div className="mt-16 items-center justify-between">
  
                <div className="text-sm underline">
                  <Link
                    to={LOGIN+query_string}
                    className="font-medium text-gray-50 hover:text-gray-500"
                    key={"web"+LOGIN}
                                      >
                      {i18n.t("signup.back_to_login")}
                    </Link>
                </div>
              </div>


        </div>
      </div>
  
  )
}