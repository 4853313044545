import axiosInstance from "../helpers/axiosInstance";

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const updateAccountType = async (id, data) => {

    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/accounts/${id}/update-type`, data)
    return res.data
}