import { useMemo, useEffect } from "react"
import { getOrdersStuckBeforePendingTotal } from "../../../../services/storeMetricsService"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { numberToString } from "../../../../helpers/internationalization"
import { formatPercentage } from "../../../../utils/StringUtils"
import { BarList } from "../../../../components/MetricsCards/BarList"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"
import { DEFAULT_QUERY_STALE_TIME } from "../chartDataUtils"


export const StuckOrdersByReason = ({ storeId = null, dateFilter = null, categories = [], dataChanged = () => {} }) => {
    const { i18n, t } = useTranslation()

    const {
        data,
        // isFetching,
        isLoading,
        // refetch
    } = useQuery(["stuck_orders_before_pending_total", storeId, dateFilter], () => getOrdersStuckBeforePendingTotal(storeId, dateFilter), {
        enabled: !!storeId && !!dateFilter && !!dateFilter.from && !!dateFilter.to,
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME, // after 1min, refetch if screen is focused
        // cacheTime: 1000 * 60 * 60 * 24, // 24h
    })

    useEffect(() => {
        dataChanged(data)
    }, [data])
    
    const stuckOrders = useMemo(() => {
        if (!data) return []
        const total = data.filter((d) => d["longest_duration_status"] != "UNKNOWN")?.reduce((acc, curr) => acc + curr.count, 0) || 0
        const stuckOrdersCategorized = []
        categories.forEach((category) => {
            const item = data.find((d) => d["longest_duration_status"] === category)
            const stuckOrdersForStatus = item?.count || 0
            const unformattedPercentage = total > 0 ? (stuckOrdersForStatus / total) * 100 : 0
            const stuckOrdersPercentage = formatPercentage(unformattedPercentage)

            stuckOrdersCategorized.push({
                key: `${t(`metrics.stuck_orders.statuses.${category}`)} (${stuckOrdersPercentage})`,
                value: stuckOrdersForStatus,
                name: t(`metrics.stuck_orders.statuses.${category}`)
            })
        })
        return stuckOrdersCategorized
    }, [data, i18n.language])

    return (
        <div className="h-fit">
            <MetricTitle title={t("metrics.stuck_orders.by_reason.title")}/>
            <BarList
                mainColumnLabel={t("metrics.stuck_orders.by_reason.main_column_label")}
                secondaryColumnLabel={t("metrics.stuck_orders.by_reason.secondary_column_label")}
                data={stuckOrders}
                valueFormatter={(n) => numberToString(n, i18n.language)}
                isLoading={isLoading}
                maxRowsLabel={t(`metrics.stuck_orders.statuses.${categories[categories.length - 1]}`)}
                maxRows={stuckOrders.length}
            />
        </div>
    )
}
