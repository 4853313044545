import axiosInstance from "../helpers/axiosInstance";

export const fetchIntegrationShippingMethods = async (id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/integration_shipping_methods/channel_integration/${id}`)
    return res.data
}
export const fetchIntegration = async (id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/${id}`)
    return res.data
}

export const fetchChannelIntegrationDetail = async (id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel-integrations/${id}/integration-shipping-methods`)
    return res.data
}

export const fetchStoreShippingGroups = async (storeId) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping-groups?store_id=${storeId}`)
    return res.data
}

export const fetchCubboShippingMethods = async () => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping_method`)
    return res.data
}

export const fetchStoreCubboShippingMethods = async (store_id, params = {}, addCombos = true) => { 
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${store_id}/shipping-methods`, { params })
    if (addCombos && (store_id == 24 || store_id == 9)) {
        res.data.push({
            capitalized_carrier: "COMBO",
            carrier_name: "COMBO",
            country: "MX",
            delivery_type: "Economy",
            extended_zone: true,
            id: 0,
            is_cod: false,
            po_box_dropoff: true,
            shipping_name: "Mejor Precio",
            shipping_type: "Mejor Precio"
        })

        res.data.push({
            capitalized_carrier: "COMBO",
            carrier_name: "COMBO",
            country: "MX",
            delivery_type: "Premium",
            extended_zone: true,
            id: 9000,
            is_cod: false,
            po_box_dropoff: true,
            shipping_name: "Mejor Servicio",
            shipping_type: "Mejor Servicio"
        })
    }
    return res.data
}

export const fetchStoreCubboShippingMethodsGroup = async (store_id, params = {}) => { 
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${store_id}/shipping-methods-by-carrier-name`, { params })
    return res.data
}

export const syncIntegrationsShippingMethods = async (id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/synchronize_shipping_methods/${id}`)
    return res.data
}
export const deleteMatchShippingMethods = async (id) => {
    const res = await axiosInstance.delete(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping_matches/${id}`)
    return res.data
}



/**
 *
 */
export const saveMatchShippingMethods = async (
    shipping_matches
) => {
    const data = {
        data:shipping_matches
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping_matches`, data)
    return res.data
}

export const assignShippingGroup = async (shippingGroupId, integrationShippingMethodId) => {
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/integration-shipping-method/${integrationShippingMethodId}/shipping-group`, {shipping_group_id: shippingGroupId})
    return res.data
}

export const updateShippingType = async (integrationShippingMethodId, shippingType) => {
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/integration-shipping-method/${integrationShippingMethodId}/shipping-type`, {shipping_type: shippingType})
    return res.data
}

export const createIntegrationShippingMethod = async (channelIntegrationId, integrationShippingMethodData) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel-integrations/${channelIntegrationId}/integration-shipping-methods`, integrationShippingMethodData)
    return res.data
}