import { Grid, Col } from "@tremor/react"
import { BaseCard } from "../../../../components/MetricsCards/BaseCard"
import { MetricsCardHeader } from "../../../../components/MetricsCards/MetricsCardHeader"
import { INTERVAL_DAY } from "../chartDataUtils"
import { useTranslation } from "react-i18next"
import { TopOrderDestinations } from "./TopOrderDestinations"
import { ShippingMethodsPercentage } from "./ShippingMethodsPercentage"
import { ShippingMethodsHistoric } from "./ShippingMethodsHistoric"
import { OrdersDeliveredOnTimeByStates } from "./OrdersDeliveredOnTimeByStates"
import { OrdersDeliveredOnTimeByCarrier } from "./OrdersDeliveredOnTimeByCarrier"
import { SelectMultipleCarriers } from "./SelectMultipleCarriers"
import { SelectMultipleCountryStates } from "./SelectMultipleCountryStates"
import { useEffect, useRef, useState } from "react"
import { CollectedToDoorAverage } from "./CollectedToDoorAverage"
import { MetricTitle } from "../../../../components/MetricsCards/MetricTitle"
import useOnScreen from "../../../../hooks/useOnScreen"
import { ShowSelectedFilters } from "../../../../components/MetricsCards/ShowSelectedFilters"
import { CollectedToDoorPercentage } from "./CollectedToDoorPercentage"
import { CollectedToDoorFirstDeliveryAttemptPercentage } from "./CollectedToDoorFirstDeliveryAttemptPercentage"

export const CollectedToDoorLayout = ({ storeId = null, dateFilter = null, interval = INTERVAL_DAY }) => {
    const { t } = useTranslation()

    const [selectedCarrierIds, setSelectedCarrierIds] = useState(null)
    const [selectedCarrierNames, setSelectedCarrierNames] = useState(null)
    const [selectedCountryStates, setSelectedCountryStates] = useState(null)

    const handleCarrierSelectChange = (carrierIdsArray, carrierNamesArray) => {
        if (carrierIdsArray === []) {
            setSelectedCarrierIds(null)
            window.analytics.track("Metrics - Selects CollectionToDoor filteredByCarriers", { carriers: null })
            return
        }
        setSelectedCarrierIds(carrierIdsArray)
        setSelectedCarrierNames(carrierNamesArray)
        window.analytics.track("Metrics - Selects CollectionToDoor filteredByCarriers", { carriers: carrierIdsArray })
    }

    const handleStatesSelectChange = (states) => {
        if (states === []) {
            setSelectedCountryStates(null)
            window.analytics.track("Metrics - Selects CollectionToDoor filteredByStates", { states: null })
            return
        }
        setSelectedCountryStates(states)
        window.analytics.track("Metrics - Selects CollectionToDoor filteredByStates", { states: states })
    }

    const cardRef = useRef(null)
    const cardIsOnScreen = useOnScreen(cardRef)
    useEffect(() => {
        if (cardIsOnScreen) window.analytics.track("Metrics - Views CollectionToDoor")
    }, [cardIsOnScreen])

    return (
        <BaseCard className="my-6" ref={cardRef}>
            <MetricsCardHeader title={t("metrics.collected_to_door.title")} subtitle={t("metrics.collected_to_door.subtitle")} />
            <CollectedToDoorAverage storeId={storeId} dateFilter={dateFilter} />
            <Grid numItemsSm={1} numItemsLg={3} className="w-full gap-10 lg:gap-x-20 mb-10">
                <Col numColSpan={1}>
                    <CollectedToDoorFirstDeliveryAttemptPercentage storeId={storeId} dateFilter={dateFilter} />
                </Col>
            </Grid>
            <Grid numItemsSm={1} numItemsLg={3} className="w-full gap-10 lg:gap-x-20 mb-10">
                <Col numColSpan={1}>
                    <CollectedToDoorPercentage storeId={storeId} dateFilter={dateFilter} />
                </Col>
            </Grid>
            
            <div className="w-full mb-3" />
            <Grid numItemsSm={1} numItemsLg={3} className="w-full gap-10 lg:gap-x-20 mb-10">
                <Col numColSpan={1}>
                    <MetricTitle title={t("metrics.top_national_destinations.title")} className="mb-5" />
                    <TopOrderDestinations storeId={storeId} dateFilter={dateFilter} />
                </Col>
                <Col numColSpanLg={2}>
                    <div className="lg:ml-5 mb-5 w-full">
                        <MetricTitle title={t("metrics.orders_delivered_on_time_by_states.title")} className="mb-5" />
                        <SelectMultipleCarriers storeId={storeId} onChange={handleCarrierSelectChange} />
                        <ShowSelectedFilters label={t("metrics.collected_to_door.select.selected_carriers")} selectedArray={selectedCarrierNames}/>
                    </div>
                    <OrdersDeliveredOnTimeByStates storeId={storeId} dateFilter={dateFilter} shippingMethodIds={selectedCarrierIds} />
                </Col>
                <Col numColSpanLg={1}>
                    <MetricTitle title={t("metrics.shipping_methods_total.title")} className="mb-5" />
                    <ShippingMethodsPercentage storeId={storeId} dateFilter={dateFilter} />
                </Col>
                <Col numColSpanLg={2}>
                    <div className="lg:ml-5 mb-5 w-full">
                        <MetricTitle title={t("metrics.orders_delivered_on_time_by_carrier.title")} className="mb-5" />
                        <SelectMultipleCountryStates storeId={storeId} onChange={handleStatesSelectChange} />
                        <ShowSelectedFilters label={t("metrics.collected_to_door.select.selected_states")} selectedArray={selectedCountryStates} isCapitalized={false}/>
                    </div>
                    <OrdersDeliveredOnTimeByCarrier storeId={storeId} dateFilter={dateFilter} stateName={selectedCountryStates} />
                </Col>
            </Grid>
            <MetricTitle title={t("metrics.shipping_methods_historic.title")} className="mb-5" />
            <ShippingMethodsHistoric storeId={storeId} dateFilter={dateFilter} interval={interval} />
        </BaseCard>
    )
}
