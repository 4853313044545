import { PackingOptionsList } from '../components';
import { FRAGILE_TYPES } from '../packingOptionConstants';
import { FragilePackingMaterialsList } from "../components";

const NonFragileOptionsSection = ({title, titleComplement, description, packingOptionsList, packingOptionsStatuses, onOptionChange, warehouseMaterialList, storePackingMaterialList, onPackingMaterialChange}) => {
    return (
        <section className="text-gray-900">
            <h2 className='mb-1 text-lg font-bold'>{title}</h2>
            <p className='mb-2'>{titleComplement}</p>

            <div className='flex flex-col gap-6'>
                <PackingOptionsList
                    packingType={FRAGILE_TYPES.NON_FRAGILE_ONLY}
                    packingOptionsList={packingOptionsList}
                    packingOptionsStatuses={packingOptionsStatuses}
                    onOptionChange={onOptionChange}
                    headerComponent={
                        <div className="border rounded-t p-3 bg-gray-50">
                            {description}
                        </div>
                    }
                />

                <FragilePackingMaterialsList
                    warehouseMaterialList={warehouseMaterialList}
                    storePackingMaterialList={storePackingMaterialList}
                    onPackingMaterialChange={onPackingMaterialChange}
                    forFragile={false}
                />
            </div>
        </section>
    )
}

export default NonFragileOptionsSection
