import {useState, useContext, useRef, useReducer, useMemo} from 'react';
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import {useQuery} from "react-query";
import {fetchReplenishments,deleteReplenishment, cancelReplenishment,requestedReplenishments, enqueueReplenishmentsExport, getReplenishmentsExport} from "../../services";
import {useDispatch, useSelector} from "react-redux";
import {
    DialogView,
    Button,
    PageView,
    SlidePanel,
    PageTopBar,
    SearchInput,
    SelectInput,
    ConfirmDialog,
    Notification,
} from "../../components";
import {CanlendlyView} from "./AddReplenishment/CanlendlyView";
import { ADD_REPLENISHMENT, EDIT_REPLENISHMENT, SHOW_REPLENISHMENT, SHOW_REPLENISHMENT_INFO, STORE_PATH, WORK_ORDERS} from '../../navigation/constants';
import {AddReplenishmentContainer} from "./AddReplenishment/addReplenishmentContainer";
import { ViewReplenishment } from './ViewReplenishment';
import { ViewReplenishmentInfo } from './ViewReplenishmentInfo';
import {PrintInvoicesListView} from "../integrations/PrintInvoicesListView"
import { Loader } from '../../components/Loader';
import { UserContext } from '../../hooks/UserContext';
import {resetData} from "../../redux/createReplenishmentSlice";
import { REPLENISHMENTS } from '../../navigation';
import { Pagination } from 'antd';
import { useTranslation } from "react-i18next";
import { setReplenishmentsListPageSize } from '../../redux/pageSizeSlice';
import { filterReducer, INITIAL_STATE } from "./filterReducer";
import ReactDatePicker from 'react-datepicker';
import { CheckCircleIcon, XIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { DownloadIcon } from "@heroicons/react/solid"
import { useEffect } from 'react';
import { filterIsPresent } from '../../utils/filter';

const pagesInterval = [
    { id: "10", name: '10'},
    { id: "25", name: '25'},
    { id: "50", name: '50'},
    { id: "100", name: '100'}
];

const REPLENISHMENT_TYPES = [
    { id: "presale", label: "replenishments.types.presale"},
    { id: "regular", label: "replenishments.types.regular"}
]

const REPLENISHMENT_STATUSES = [
    {
      status: "unknown",
      label: "statuses.replenishment_statuses.unknown",
      filterable: false
    },
    {
      status: "REQUESTED",
      label: "statuses.replenishment_statuses.requested",
    },
    {
      status: "CANCELED",
      label: "statuses.replenishment_statuses.canceled",
    },
    {
      status: "COMPLETED",
      label: "statuses.replenishment_statuses.completed",
    },
    {
      status: "WITHOUT_SCHEDULING",
      label: "statuses.replenishment_statuses.without_scheduling",
    },
    {
      status: "GENERATING_INVOICES",
      label: "statuses.replenishment_statuses.generating_invoices",
    },
    // # processing status for oms means processing and put-away
    {
      status: "PROCESSING",
      label: "statuses.replenishment_statuses.processing",
    },
    {
      status: "IN_PUTAWAY",
      label: "statuses.replenishment_statuses.processing",
      filterable: false
    },
    {
      status: "RECEIVED",
      label: "statuses.replenishment_statuses.received",
      classes: "border-blue-500 text-blue-500"
    },
  ];

 const statusFilters = REPLENISHMENT_STATUSES.filter(
    (status) => status.filterable !== false).map((status) => {
        return status.filterable === false
            ? null : { id: status.status, label: status.label}
        }
)
    
const headers = [
    { id: 'id', label: 'replenishments.header.id_type_notes'},
    { id: 'status', label: 'replenishments.header.status'},
    { id: 'pieces_summary', label: 'replenishments.header.pieces_summary'},
    { id: 'replenishment_summary', label: 'replenishments.header.replenishment_summary'},
]

const guide_es = "https://docs.google.com/document/d/1tvS22z7jWZAP4g_N6mAJqYOa7nerVh9z17LLlOx_4nU/edit#"
const guide_pt = "https://docs.google.com/document/d/160oE2UBFJ8c4757B_FX3d4GQqDmzioR4/edit"

export const ListReplenishmentsContainer = () => {
    const { t, i18n} = useTranslation();
    const history = useHistory()
    const { url:storePath } = useRouteMatch({ path: STORE_PATH })
    const dispatch = useDispatch()
    const pageSize = useSelector((state) => state.pageSize.replenishmentsList);
    const [openDialogPrintInvoices, setOpenDialogPrintInvoices] = useState(false)
    const [selectReplenishment, setSelectReplenishment] = useState(false)
    const [openDialogCalendly,setOpenDialogCalendly] = useState(false)
    const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState(false)
    const [replenishmentIdToCancel, setReplenishmentIdToCancel] = useState(null)
    const [loadingCancel, setLoadingCancel] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [resp,setResp]=useState(false)
    const {user} = useContext(UserContext)
    const [warehouse, setWarehouse] = useState(user.current_store?.warehouses[0])
    const initialQueryParams = {
        page: 1,
        store_id: user.current_store?.id,
        per_page: pageSize,
        load_history: true
    }
    const [queryParams, setQueryParams] = useState(initialQueryParams)

    // Export replenishments states
    const [exportDialogOpen, setExportDialogOpen] = useState(false)
    const [enqueueingExport, setEnqueueingExport] = useState(false)
    const [exportError, setExportError] = useState(null)
    const [exportNotificationOpen, setExportNotificationOpen] = useState(false)
    const [exportProgress, setExportProgress] = useState(0)
    const [exportFileUrl, setExportFileUrl] = useState(null)

    const wrapWithIndigoSpan = text => (<span className="text-indigo-600 text-base">{text}</span>)
    const replenishmentNumberToExportDescription = replenishmentId => (
        <>
            {i18n.t('replenishments.export_dialog.replenishment_id')}
            {wrapWithIndigoSpan(replenishmentId)}
        </>
    )
    const skuToExportDescription = skus => (
        <>
            {i18n.t('replenishments.export_dialog.sku')}
            {wrapWithIndigoSpan(skus)}
        </>
    )
    const statusToExportDescription = status => (
        <>
            {i18n.t('replenishments.export_dialog.status')}
            {wrapWithIndigoSpan(i18n.t(`statuses.replenishment_statuses.${status.toLowerCase()}`))}
        </>
    )
    const typeToExportDescription = type => (
        <>
            {i18n.t('replenishments.export_dialog.type')}
            {wrapWithIndigoSpan(i18n.t(`replenishments.types.${type}`))}
        </>
    )
    const completedDateToExportDescription = range => {
        const from_date = new Date(range['from']).toLocaleDateString('en-GB', 
            {year: "2-digit",
            month: "2-digit",
            day: "2-digit"
        })
        const to_date = new Date(range['to']).toLocaleDateString('en-GB', 
            {year: "2-digit",
            month: "2-digit",
            day: "2-digit"
        })
        return (
        <>
            {i18n.t('replenishments.export_dialog.completed_at')}
            {wrapWithIndigoSpan(from_date)}
            {i18n.t('replenishments.export_dialog.completed_at_join')}
            {wrapWithIndigoSpan(to_date)}
        </>)
    }
    
    const replenishmentsExportDescriptionBuilders = {
        'id': replenishmentNumberToExportDescription,
        'sku': skuToExportDescription,
        'status': statusToExportDescription,
        'type': typeToExportDescription,
        'completed_at': completedDateToExportDescription
    }
    const ExportDescription = () => {
        let filters = {...queryParams}
        if (!filters || !Object.keys(filters).length)
            return (<div className="text-sm text-gray-500">{i18n.t('replenishments.export_dialog.default_description')}</div>)
        
        filters =  Object.entries(filters).reduce((acc, [k, v]) => filterIsPresent(v) ? {...acc, [k]:v} : acc , {})
        const filtersDescriptions = Object.keys(filters)
            .filter(filterKey => replenishmentsExportDescriptionBuilders[filterKey] instanceof Function)
            .map(filterKey => replenishmentsExportDescriptionBuilders[filterKey](filters[filterKey]))
            
        if (filtersDescriptions.length) {
            return (
                <>
                    <div className="mt-3 text-base sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="text-gray-500 mb-3">{i18n.t('replenishments.export_dialog.intro')}</div>
                        <ul className="list-disc ml-6">
                            {filtersDescriptions.map((description, index) => <li key={index}>{description}</li>)}
                        </ul>
                    </div>
                </>
            )
        } else {
            return (<div>{i18n.t('replenishments.export_dialog.default_description')}</div>)
        }
    }
    const MemoizedExportDescription = useMemo(ExportDescription, [queryParams, t])
    const onConfirmExport = async function () {
        window.analytics.track('Replenishments - Starts Exporting')
        setEnqueueingExport(true)
        setExportFileUrl(null)

        const { job_id } = await enqueueReplenishmentsExport({
            ...queryParams,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })
        watchExportProgress(job_id)

        setExportDialogOpen(false)
        setExportNotificationOpen(true)
        setEnqueueingExport(false)
    }
    
    const watchExportProgress = function (job_id) {
        const intervalId = setInterval(async () => {
            const {progress_percentage: progressPercentage, download_url: downloadUrl} = await getReplenishmentsExport(job_id)
            if (progressPercentage < 100) {
                setExportProgress(progressPercentage)
            } else {
                setExportFileUrl(downloadUrl)
                setExportProgress(0)
                clearInterval(intervalId);
            }
        }, 1000)
    }

    // Notification Dialog
    const handleExportDownload = function () {
        window.analytics.track('Replenishments - Downloads Exported')
        setExportNotificationOpen(false)
        window.open(process.env.REACT_APP_PLATFORM_URL + exportFileUrl)
    }

    const exportNotificationBody = (
        <>
            <div className="w-0 flex-1 flex justify-between">
                {exportFileUrl &&
                    <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                    </div>
                }
                <div className="my-0 ml-2 w-0 flex-1 text-sm font-medium text-gray-900">
                    {!exportFileUrl ? <> {i18n.t('export_notification.generating')} </> 
                    : <> {i18n.t('export_notification.generated')} </>}
                </div>
                {!exportFileUrl &&
                    <div className="my-0 flex-shrink-0 text-base font-medium text-indigo-600">{exportProgress}%</div>
                }
                {exportFileUrl &&
                    <button
                        type="button"
                        className="ml-3 flex-shrink-0 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleExportDownload}
                    >
                        Descargar
                    </button>
                }
            </div>
        </>
    )

    const [filterDropdown, setDropdown] = useState(false);
    const [filtersState, dispatchFilter] = useReducer(filterReducer, INITIAL_STATE);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    function hideDropdown (e) {
        if (e.target.id != "btn-dropdown" && filterDropdown) {
            setDropdown(false)
        }
    }

    useEffect(() => {
        document.addEventListener("click", hideDropdown)
        return () => {
            document.removeEventListener("click", hideDropdown)
        }
    })

    useEffect(() => {
        window.analytics.page('Replenishments', 'List')
      }, [])
    

    const handlePaginationChange = (page) => {
        setQueryParams((prev) => ({...prev, page: page}))
        dispatch(setReplenishmentsListPageSize(pageSize))
    };

    const handlePageSizeChange = (page) => {
        setQueryParams((prev) => ({...prev, per_page: page.id, page: 1}))
        dispatch(setReplenishmentsListPageSize(page.id))
    }

    const onSearchHandler = (value, filter) => {
        switch (filter) {
            case "completed_at":
                const from = new Date(value[0])
                const to = new Date(value[1])
                setQueryParams((prev) => ({...prev, 
                    [filter]: {
                        from: from.toISOString(),
                        to: to.toISOString()
                    }, page: 1
                }))
                break
            default:
                setQueryParams((prev) => ({...prev, [filter]: value, page: 1}))
                break
        }
    }

    const handleOpenFilters = () => {
        setDropdown(!filterDropdown);
    };

    const handleFilterClick = (filter_key) => {
        window.analytics.track('Replenishments - Adds Filter From List', {filter_key: filter_key})
        setDropdown(!filterDropdown)
        dispatchFilter({ type: "OPEN", id: filter_key })
    }

    const handleResetFilter = (filter) => {
        dispatchFilter({ type: "CLOSE", id: filter })
        switch (filter) {
            case "completed_at":
                setQueryParams((prev) => ({...prev, [filter]: {}, page: 1}))
                setStartDate(null)
                setEndDate(null)
                break
            default:
                setQueryParams((prev) => ({...prev, [filter]: "", page: 1}))
                break
        }
    }

    const handleResetFilters = () => {
        dispatchFilter({ type: "RESET"})
        setQueryParams(initialQueryParams)
        setStartDate(null)
        setEndDate(null)
    }

    const addReplenishmentPanelRef = useRef(null)
    const editReplenishmentPanelRef = useRef(null)
    const showReplenishmentPanelRef = useRef(null)

    /*const handleDeleteReplenishmet = async (id) => {
        const response = await deleteReplenishment(id)
        refetch()
    }*/

    /* TODO: Export receipt to excel when status == COMPLETED */
    const handlerOnClickDetail = (replenishment) => {
        if(replenishment?.status != "COMPLETED") {
            history.push(storePath + SHOW_REPLENISHMENT_INFO.replace(':id', replenishment?.id))
        }
        else {
            history.push(storePath + SHOW_REPLENISHMENT.replace(':id', replenishment?.id))
        }
    }

    const handleCancelClick = (replenishmentId) => {
        setErrorMessage(null)
        setLoadingCancel(false)
        setOpenConfirmCancelDialog(true)
        setReplenishmentIdToCancel(replenishmentId)
    }
    
    const handleCancelReplenishmet = async (id) => {
        try {
            window.analytics.track('Replenishments - Cancels', {id: id})
            setLoadingCancel(true)
            const response = await cancelReplenishment(id)
            refetch()
            setOpenConfirmCancelDialog(false)
        } catch (error){
            if (error.response?.data?.error) {
                // console.log("api error: ", error)
                setErrorMessage(error.response.data.error)
                console.log("error response: ", error.response.data.error)
            }
            else { setErrorMessage(error) }
            setLoadingCancel(false)
            refetch()
        }
    }

    const handlerOnClickCita = (uri) => { window.open(uri) }
    const handlerOnClickReschedule = (uri) => {
        window.analytics.track('Replenishments - Reschedules')
        let scheduled_url = ''
        try {
            scheduled_url = new URL(uri)
        } catch (error) {
            return null
        }
        let event_id = scheduled_url.pathname.split('/')
        event_id = event_id[event_id.length-1]
        let url = new URL('https://calendly.com')
        url.pathname = `/reschedulings/${event_id}`
        let date = new Date
        url.searchParams.set('month', `${date.getFullYear()}-${('0'+(date.getMonth()+1)).slice(-2)}`)
        window.open(url)
    }

    const handleSaveCalendly = async (idReplenishment,data) => {
        try {
            window.analytics.track('Replenishments - Schedules', {id: idReplenishment})
            if(idReplenishment){
                const response = await requestedReplenishments(idReplenishment,data)
            }
            setOpenDialogCalendly(false)
            refetch()
        }
        catch { setOpenDialogCalendly(false) }
    }

    const closeSlidePanel = (ref) => {
        ref.current.closePanel()
        refetch()
    }

    const areAllProductsNonScannable = (replenishment_products) => {
        return replenishment_products.find((item) => !item?.product?.is_scannable) ? true : false
    }
    
    const { data, status, isLoading, isError, isPreviousData, isFetching, error, refetch } = useQuery(['replenishments', queryParams], () => fetchReplenishments(queryParams), { keepPreviousData: true })
    
    useEffect(() => {
        let newSearchParams = {...queryParams, store_id: user.current_store?.id}
        setQueryParams(newSearchParams)
    }, [user.current_store?.id])

    const renderStatusSection = (replenishment) => {
        const invoice_exists = !areAllProductsNonScannable(replenishment?.replenishment_products)
        let status = replenishment?.status
        if (replenishment?.invoices?.length == 0 && replenishment?.warehouse_requires_invoice &&
            ['WITHOUT_SCHEDULING', 'REQUESTED'].includes(replenishment?.status) && invoice_exists) {
            status = "GENERATING_INVOICES"
        }

        switch(status) {
            case "WITHOUT_SCHEDULING":
                return (
                <div className="max-w-9/10">
                    <div className="inline-block mr-2 w-2 h-2 bg-light-blue-300 rounded-full mb-0.5"></div>
                    <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                        {i18n.t("statuses.replenishment_statuses.without_scheduling")}
                    </div>
                    <div className="mt-3">
                        {i18n.t("replenishments.container.schedule_message")}
                    </div>
                    <div className="mt-3 cursor-pointer text-blue-500 hover:text-blue-400"
                        onClick={() => {
                            setOpenDialogCalendly(true)
                            setResp(replenishment)}}>
                        {i18n.t("replenishments.container.schedule_an_appointment")}
                    </div>
                </div>)
            case "GENERATING_INVOICES":
                return (
                    <div className="max-w-9/10">
                        <div className="inline-block mr-2 w-2 h-2 bg-purple-500 rounded-full mb-0.5"></div>
                        <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                            {i18n.t("statuses.replenishment_statuses.generating_invoices")}
                        </div>
                        { replenishment?.url_scheduled ? 
                        <div className='mt-3'>
                            <div className='flex flex-row flex-wrap mt-2 gap-x-5 gap-y-2'>
                                <div className="cursor-pointer text-blue-500 hover:text-blue-400"
                                    onClick={()=> handlerOnClickCita(replenishment?.url_scheduled)}>
                                    {i18n.t("replenishments.container.go_to_appointment")}
                                </div>
                                <div className="cursor-pointer text-blue-500 hover:text-blue-400"
                                    onClick={()=> handlerOnClickReschedule(replenishment?.url_scheduled)}>
                                    {i18n.t("replenishments.container.reschedule")}
                                </div>
                            </div>
                        </div>: <>
                            <div className="mt-3">
                                {i18n.t("replenishments.container.schedule_message")}
                                
                            </div>
                            <div className="mt-3 cursor-pointer text-blue-500 hover:text-blue-400"
                                onClick={() => {
                                    setOpenDialogCalendly(true)
                                    setResp(replenishment)}}>
                                {i18n.t("replenishments.container.schedule_an_appointment")}
                            </div> </>}
                    </div>)   
            case "REQUESTED":
                return (
                <div className="max-w-9/10">
                    <div className="inline-block mr-2 w-2 h-2 bg-yellow-400 rounded-full mb-0.5"></div>
                    <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                        {i18n.t("statuses.replenishment_statuses.requested")}
                    </div>
                    <div className='flex flex-row flex-wrap mt-2 gap-x-5 gap-y-2'>
                        <div className="cursor-pointer text-blue-500 hover:text-blue-400"
                            onClick={()=> handlerOnClickCita(replenishment?.url_scheduled)}>
                            {i18n.t("replenishments.container.go_to_appointment")}
                        </div>
                        <div className="cursor-pointer text-blue-500 hover:text-blue-400"
                            onClick={()=> handlerOnClickReschedule(replenishment?.url_scheduled)}>
                            {i18n.t("replenishments.container.reschedule")}
                        </div>
                    </div>
                </div>)
            case "RECEIVED":
                return (
                <div className="max-w-9/10">
                    <div className="inline-block mr-2 w-2 h-2 bg-indigo-600 rounded-full mb-0.5"></div>
                    <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                        {i18n.t("statuses.replenishment_statuses.received")}
                    </div>
                    {replenishment?.attended_appointment ? ( <>
                      <div className="mt-3">
                          {i18n.t("replenishments.container.we_received")}
                          {replenishment?.number_of_boxes}
                          {i18n.t("replenishments.container.received_bulks_SLA")}
                      </div>
                       <div className="mt-3">
                          {i18n.t("replenishments.container.received_SLA")}
                      </div></>) :
                      (<>
                      <div className="mt-3">
                          {i18n.t("replenishments.container.we_received")}
                          {replenishment?.number_of_boxes}
                          {i18n.t("replenishments.container.received_bulks_not_attended")}
                      </div>
                        <div className="mt-3">
                          {i18n.t("replenishments.container.received_not_attended")}
                      </div>
                      </>)
                    }
                </div>)
            case "PROCESSING":
                return (
                <div className="max-w-9/10">
                    <div className="inline-block mr-2 w-2 h-2 bg-gray-400 rounded-full mb-0.5"></div>
                    <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                        {i18n.t("statuses.replenishment_statuses.processing")}
                    </div>
                    <div className="mt-3">
                        {i18n.t("replenishments.container.processing_message")}
                    </div>
                    {!replenishment?.attended_appointment &&
                    <div className="mt-3">
                        <InformationCircleIcon className="inline mr-1 mb-0.5 h-4 w-4 text-red-400"/>
                        {i18n.t("replenishments.container.late_processing")}
                    </div>}
                </div>)
            case "IN_PUTAWAY":
                return (
                <div className="max-w-9/10">
                    <div className="inline-block mr-2 w-2 h-2 bg-gray-400 rounded-full mb-0.5"></div>
                    <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                        {i18n.t("statuses.replenishment_statuses.processing")}
                    </div>
                    <div className="mt-3">
                        {i18n.t("replenishments.container.processing_message")}
                    </div>
                    {!replenishment?.attended_appointment &&
                    <div className="mt-3 ">
                        <InformationCircleIcon className="inline mr-1 mb-0.5 h-4 w-4 text-red-400"/>
                        {i18n.t("replenishments.container.late_processing")}
                    </div>}

                </div>)
            case "COMPLETED":
                return (
                <div className="max-w-9/10">
                    {replenishment?.stored_quantity == replenishment?.replenishment_products.reduce((total, current) => 
                    total + current.expected_quantity, 0) ? <>
                    <div className="inline-block mr-2 w-2 h-2 bg-green-500 rounded-full mb-0.5"></div>
                    <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                        {i18n.t("statuses.replenishment_statuses.completed")}
                    </div> </>
                    :  <>
                    <div className="inline-block mr-2 w-2 h-2 bg-green-300 rounded-full mb-0.5"></div>
                    <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                        {i18n.t("statuses.replenishment_statuses.completed_with_differences")}
                    </div> </>
                    }
                    {replenishment?.labeled_product && replenishment?.segregated_product ? (
                        <div className="mt-3">
                            {i18n.t(`replenishments.container.completed_both`)}
                        </div> 
                    ) 
                    : replenishment?.labeled_product && !replenishment?.segregated_product ? (
                        <>
                        <div className="mt-3 text-red-400">
                            {i18n.t(`replenishments.container.completed_labeled`)}
                        </div>
                        <div className="mt-3">
                            {i18n.t(`replenishments.container.check`)}
                            <a href={`${warehouse?.country == "BR" ? guide_pt : guide_es}`}  
                                className='mx-1 inline cursor-pointer underline text-gray-500 ' 
                                target="_blank">
                                {i18n.t(`replenishments.container.here`)}
                            </a>
                            {i18n.t(`replenishments.container.how_to_send`)}
                        </div>
                        </>
                    )
                    : !replenishment?.labeled_product && replenishment?.segregated_product ? (
                        <>
                        <div className="mt-3 text-red-400">
                            {i18n.t(`replenishments.container.completed_segregated`)}
                        </div>
                        <div className="mt-3">
                            {i18n.t(`replenishments.container.check`)}
                            <a href={`${warehouse?.country == "BR" ? guide_pt : guide_es}`}  
                                className='mx-1 inline cursor-pointer underline text-gray-500 ' 
                                target="_blank">
                                {i18n.t(`replenishments.container.here`)}
                            </a>
                            {i18n.t(`replenishments.container.how_to_send`)}
                        </div> 
                        </>
                    )
                    : (
                        <>
                        <div className="mt-3 text-red-400">
                            {i18n.t(`replenishments.container.completed_both_wrong`)}
                        </div>
                        <div className="mt-3">
                            {i18n.t(`replenishments.container.check`)}
                            <a href={`${warehouse?.country == "BR" ? guide_pt : guide_es}`}   
                                className='mx-1 inline cursor-pointer underline text-gray-500 ' 
                                target="_blank">
                                {i18n.t(`replenishments.container.here`)}
                            </a>
                            {i18n.t(`replenishments.container.how_to_send`)}
                        </div> 
                        </>
                    )}
                </div>)
            case "CANCELED":
                return (
                <div className="max-w-9/10">
                    <div className="inline-block mr-2 w-2 h-2 bg-red-500 rounded-full mb-0.5"></div>
                    <div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                        {i18n.t("statuses.replenishment_statuses.canceled")}
                    </div>
                </div>)
            default:
                return (<div className="inline font-semibold text-gray-700 text-base 2xl:text-lg">
                    {replenishment?.status}</div>)
        }
    }

    const renderEditAndCancelReplenishment = (replenishment) => {
        return ['PROCESSING', 'COMPLETED', 'CANCELED'].includes(replenishment?.status) ? 
            null : (<>
                <div className='cursor-pointer text-blue-500 hover:text-blue-400 mt-5'
                    onClick={() => history.push(storePath + EDIT_REPLENISHMENT.replace(':id', replenishment?.id))}>
                    {i18n.t(`replenishments.container.edit_receipt`)}
                </div>
                <div className='cursor-pointer text-blue-500 hover:text-blue-400 mt-2'
                    onClick={() => handleCancelClick(replenishment?.id)}>
                    {i18n.t(`replenishments.container.cancel_receipt`)}
                </div>
            </>)
    }

    const timelinePoint = (status, date, last=false) => {
        return (
            <div className={`border-l border-gray-200 pl-3 ${last ? 'pb-2' : 'pb-4'}`}>
                <div className={`absolute w-2 h-2 rounded-full mt-1 -left-1 
                    ${last ? 'bg-gray-400' : 'bg-gray-300'}`}></div>
                <div className="leading-tight tracking-tight">
                    <div className='inline-block mr-1'>
                        {i18n.t(`statuses.replenishment_statuses.${status.toLowerCase()}`)}
                    </div>
                    {date && <>
                        <div className="inline-block mr-1">
                            {i18n.t(`replenishments.container.on_date`)}
                        </div>
                        {new Date(date).toLocaleDateString('en-GB', 
                            {year: "2-digit",
                            month: "2-digit",
                            day: "2-digit"})
                        }
                    </>}
                </div>
            </div>
        )
    }

    const renderTimelineSection = (replenishment) => {
        return (<div className="relative text-gray-700"> 
            {replenishment?.status == "WITHOUT_SCHEDULING" ? ( <>                    
                        {timelinePoint(replenishment?.status, replenishment?.created_at, true)}
                    </>)
            : replenishment?.status == "CANCELED" ? (<>
                        {timelinePoint(replenishment?.status, replenishment?.updated_at, true)} 
                    </>)
            : replenishment?.status == "REQUESTED" ? (<>
                        {timelinePoint("WITHOUT_SCHEDULING", replenishment?.created_at)}
                        {timelinePoint(replenishment?.status, replenishment?.updated_at, true)}
                    </>)
            : replenishment?.status == "RECEIVED" ? (<>
                        {timelinePoint("WITHOUT_SCHEDULING", replenishment?.created_at)}
                        {timelinePoint(replenishment?.status, replenishment?.arrived_at, true)}
                    </>)
            : ['PROCESSING', 'IN_PUTAWAY'].includes(replenishment?.status) ? (<>
                        {timelinePoint("WITHOUT_SCHEDULING", replenishment?.created_at)}
                        {timelinePoint("RECEIVED", replenishment?.arrived_at)}
                        {timelinePoint(replenishment?.status, replenishment?.updated_at, true)} 
                    </>) 
            : replenishment?.status == "COMPLETED" ? (<>
                        {timelinePoint("WITHOUT_SCHEDULING", replenishment?.created_at)}
                        {timelinePoint("RECEIVED", replenishment?.arrived_at)}
                        {timelinePoint(replenishment?.status, replenishment?.completed_at, true)}
                     </>) : ""
            }
            </div>)
    }

    const renderSummarySection = (replenishment) => {
        return (<div className='max-w-9/10'>
            {!['WITHOUT_SCHEDULING', 'REQUESTED', 'CANCELED'].includes(replenishment?.status) ? (
            <div className="relative text-gray-700">
                <div className="border-l border-gray-200 pb-4 pl-3">
                    <div className="absolute w-2 h-2 bg-gray-300 rounded-full mt-1.5 -left-1"></div>
                    <div className="inline mr-1">
                        {replenishment?.replenishment_products.reduce((total, current) => 
                    total + current.expected_quantity, 0)}    
                    </div>{i18n.t(`replenishments.container.expected`)}
                    {!replenishment?.sell_ahead  && ['RECEIVED', 'PROCESSING'].includes(replenishment?.status) &&
                     <div className="text-gray-500 leading-tight">
                        {i18n.t(`replenishments.container.not_ready_to_sell`)}
                    </div>
                    }
                    {replenishment?.sell_ahead && 
                        <div className="text-gray-500">
                            {i18n.t(`replenishments.container.ready_to_sell`)}
                        </div>
                    }
                </div>
                {['RECEIVED', 'PROCESSING'].includes(replenishment?.status) ? (
                    <div className="border-l border-gray-200 pb-2 pl-3">
                        <div className="absolute w-2 h-2 bg-gray-400 rounded-full mt-1.5 -left-1"></div>
                        {replenishment?.status == "RECEIVED" &&  
                        <>
                            {i18n.t(`replenishments.container.processing_pending`)}
                        </>}
                        {replenishment?.status == "PROCESSING" && 
                        <>
                            <div className="inline mr-1">
                                {replenishment?.received_quantity || 0} 
                            </div>{i18n.t(`replenishments.container.received`)} 
                            <div className='font-semibold inline ml-1'>
                                {i18n.t(`replenishments.container.counting`)}
                            </div>
                        </>}   
                    </div>) : (
                    <div className="border-l border-gray-200 pb-4 pl-3">
                        <div className="absolute w-2 h-2 bg-gray-300 rounded-full mt-1.5 -left-1"></div>
                        <div className="inline mr-1">
                            {replenishment?.received_quantity || 0}
                        </div>{i18n.t(`replenishments.container.received`)}
                        <div className='font-semibold inline ml-1'>
                            {i18n.t(`replenishments.container.finished_count`)} 
                        </div>
                        <div className="text-gray-500">
                            <div className={`${replenishment?.damaged_quantity ? 'text-red-400' : ''} inline`}>
                                <div className="inline mr-1 ">
                                    {replenishment?.damaged_quantity || 0}
                                </div>{i18n.t(`replenishments.container.damaged`)}
                            </div>
                        </div>
                    </div>)}
                {['PROCESSING','IN_PUTAWAY', 'COMPLETED'].includes(replenishment?.status) && 
                <div className="border-l border-gray-200 pb-2 pl-3">
                    <div className="absolute w-2 h-2 bg-gray-400 rounded-full mt-1.5 -left-1"></div>
                    <div className="inline mr-1">
                        {replenishment?.stored_quantity || 0}
                    </div>{i18n.t(`replenishments.container.stored`)}
                    {['PROCESSING','IN_PUTAWAY'].includes(replenishment?.status)? ( 
                    <div className='font-semibold inline ml-1'>
                        {i18n.t(`replenishments.container.storing`)}
                    </div>) 
                    : <div className='font-semibold inline ml-1'>
                        {i18n.t(`replenishments.container.finished_storing`)}
                    </div>
                    }
                    {replenishment?.stored_quantity && !replenishment?.sell_ahead &&
                    <div className="text-gray-500">
                        {i18n.t(`replenishments.container.ready_to_sell`)}
                    </div>}
                </div>
                }
                <div className='mt-2 cursor-pointer text-blue-500 hover:text-blue-400'
                    onClick={() => handlerOnClickDetail(replenishment)}>
                    {i18n.t(`replenishments.container.view_products`)}
                </div>
            </div>) : (
            <div className="text-gray-700">
                <div className="inline mr-1">
                    {replenishment?.replenishment_products.reduce((total, current) => 
                        total + current.expected_quantity, 0)}    
                </div>{i18n.t(`replenishments.container.expected`)}
                {replenishment?.sell_ahead && replenishment?.status != "CANCELED" ? (
                    <div className="mt-1 text-gray-500">
                        {i18n.t(`replenishments.container.ready_to_sell`)}
                    </div>
                )
                : replenishment?.status == "CANCELED" ? ""
                : ( <div className="mt-3 text-gray-500 leading-tight ">
                        {i18n.t(`replenishments.container.not_ready_to_sell`)}
                    </div> )
                }
                <div className='mt-3 cursor-pointer text-blue-500 hover:text-blue-400'
                    onClick={() => handlerOnClickDetail(replenishment)}>
                    {i18n.t(`replenishments.container.view_products`)}
                </div>
            </div>)}
        </div>)
    }

    return (
        <>
            <DialogView
                setOpen={setOpenDialogCalendly}
                open={openDialogCalendly}
            >
                <CanlendlyView
                        title= {i18n.t("replenishments.container.schedule_an_appointment")}
                        replesishmentData={resp}
                        user={user}
                        country={warehouse.country}
                        onSave={handleSaveCalendly}
                        onCancel={() => setOpenDialogCalendly(false)}
                    />
            </DialogView>
            <DialogView setOpen={setOpenDialogPrintInvoices} open={openDialogPrintInvoices}>
                <PrintInvoicesListView
                    title =  {t("invoicing_list.invoices")}
                    replenishment={selectReplenishment}
                    onCancel={() => setOpenDialogPrintInvoices(false)}
                />
            </DialogView>
            <DialogView
                setOpen={setOpenConfirmCancelDialog}
                open={openConfirmCancelDialog}
            >
                <div className="text-xl font-bold pb-2">{i18n.t("replenishments.container.cancel_receipt")}</div>
                <div className="pb-3"> {i18n.t("replenishments.container.cancel_inventory_receipt")}</div>
                { errorMessage &&
                    <div className="text-center text-red-500 pb-3">{errorMessage}</div>
                }
                <div className="flex flex-row">
                    <Button type="secondary" className="w-full" onClick={() => setOpenConfirmCancelDialog(false)} disabled={loadingCancel}>{i18n.t("replenishments.container.close")}</Button>
                    <Button className="ml-3 w-full" onClick={()=>{
                            handleCancelReplenishmet(replenishmentIdToCancel)
                            setReplenishmentIdToCancel(null)
                        }} 
                        disabled={loadingCancel|| errorMessage}>{i18n.t("replenishments.container.cancel_receipt_dialog")}</Button>
                </div>
            </DialogView>
            <ConfirmDialog
                open={exportDialogOpen}
                setOpen={ value => setExportDialogOpen(value) }
                title={i18n.t("replenishments.export_dialog.title")}
                description={MemoizedExportDescription}
                confirmLabel={i18n.t("replenishments.export_dialog.confirm")}
                cancelLabel={i18n.t("replenishments.export_dialog.cancel")}
                errorMessage={exportError}
                onConfirm={onConfirmExport}
                loading={enqueueingExport}
            />
            <Notification
                show={exportNotificationOpen}
                setShow={setExportNotificationOpen}
                customBody={exportNotificationBody}
                ephemeral={false}
            />
           <PageView
                topMenu={<PageTopBar>
                    <div className="text-lg font-semibold"><span className="text-gray-400">{ user.current_store ? user.current_store.name : i18n.t("replenishments.container.all_stores") } &rarr; </span>{i18n.t("replenishments.container.inventory_receipt")}</div>
                    <div className="items-center flex">
                    <Button className="mr-2" type='secondary' onClick={()=>{
                            dispatch(resetData())
                            history.push(storePath + WORK_ORDERS)}}>
                            {i18n.t("work_orders.button_navigation")}
                        </Button>
                        <Button onClick={()=>{
                            dispatch(resetData())
                            window.analytics.track('Replenishments - Starts Creating')
                            history.push(storePath + ADD_REPLENISHMENT)}}>
                            {i18n.t("replenishments.container.create_inventory_receipt")}
                        </Button>
                    </div>
                </PageTopBar>}
                childrenFullWidth={true}
                topMenuFullWidth={true}
            >
                {!isPreviousData && isLoading ? (
                    <><Loader show={true}></Loader></>
                ) : isError ? (
                    <>Error: {error.message}-{isError} - {error}</>
                ) : 

                    <>
                    {user.current_store?.warehouses[0]?.country === 'BR' &&
                        <>
                        {/* <div className="rounded-lg bg-blue-100 text-blue-600 p-7 mb-5 mt-5">
                            Consulta aquí los tiempos de entrega para que los regalos de <a href="https://cubbohelp.zendesk.com/hc/es/articles/11521061194125-Entregas-d%C3%ADa-de-reyes-2023" target="_blank">Reyes</a> lleguen a tiempo a tus compradores.
                        </div> */}
                        {/* <div className="rounded-lg bg-yellow-100 text-yellow-600 p-7 mb-10 mt-5">
                            Criação de Remessa inativa durante o período de transferência de estoque para filial Embú das Artes-SP. A funcionalidade retornará após a conclusão do processo (24/04); Pedimos desculpas pelo inconveniente, qualquer dúvida entre em contato com o nosso time de suporte. 
                        </div> */}
                        </>
                    }
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-full">
                        {/* Filter and paginator section */} 
                        <nav className="flex flex-row justify-between items-center rounded-t-lg border-gray-300 border-r border-l border-t bg-white py-2">
                            <div className='flex flex-row pl-1'>
                                <div className='pl-3 py-1'>
                                    <button id="btn-dropdown" className="border border-gray-300 shadow-sm
                                    rounded-md py-2 px-3 text-gray-700 font-medium hover:bg-gray-50" 
                                            onClick={handleOpenFilters}>
                                        <svg className="inline w-5 h-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                                        </svg>
                                        {i18n.t('replenishments.header.filter')}       
                                    </button>
                                    { filterDropdown && (
                                        <div className='border absolute z-10 mt-1 shadow divide-y bg-white rounded-md'>
                                            {filtersState.map((item) => (
                                                <div key={item.id} className="px-2 py-3 cursor-pointer hover:bg-gray-50"
                                                    onClick={() => handleFilterClick(item.id)}>
                                                    {i18n.t(`replenishments.search.${item.id}`)}
                                                </div>
                                            ))}
                                        </div>
                                        )
                                    }
                                </div>
                                {filtersState.some(item => item.open === true) &&
                                <div className='pl-3 py-1'>
                                    <button className="whitespace-nowrap border shadow-sm rounded-md 
                                        py-2 px-3 text-blue-500 font-medium hover:bg-indigo-700 hover:text-white" 
                                            onClick={handleResetFilters}>
                                        {i18n.t('replenishments.header.reset_filters')}
                                    </button>
                                </div>}
                                <div className='pl-3 py-1'>
                                    <button className="whitespace-nowrap border border-gray-300 shadow-sm
                                        rounded-md py-2 px-3 text-gray-600 font-medium hover:bg-gray-50" type="secondary" 
                                        onClick={() => setExportDialogOpen(true)}>
                                        <DownloadIcon
                                            className="inline mb-0.5 mr-1 h-4 w-4"
                                            aria-hidden="true"
                                        />
                                        {i18n.t("replenishments.container.export_button")}
                                    </button>
                                </div>
                            </div>
                            {/* Paginator */}
                            {data?.meta &&
                            <div className='pr-2'>
                                <SelectInput
                                    options={pagesInterval}
                                    selectedInitial={pagesInterval.find(option => option.id == queryParams.per_page) ?? pagesInterval[0]}
                                    className="inline-block text-sm px-4 sm:px-0"
                                    onChange={(page) => { handlePageSizeChange(page) }}
                                    value={pagesInterval.find(option => option.id == queryParams.per_page) ?? pagesInterval[0]}
                                />
                                <Pagination 
                                    className='inline'
                                    size="small"
                                    current={data?.meta.current_page}
                                    total={data?.meta?.total_count} 
                                    onChange={handlePaginationChange} 
                                    page={data?.meta.current_page}
                                    pageSize={pageSize}
                                    showSizeChanger={false}
                                />
                            </div>}
                        </nav>
                        {/* Filters section */}
                        {filtersState.some(item => item.open === true) && (
                            <div className="flex flex-wrap gap-x-3 gap-y-2 px-4 pb-2 border-r border-l 
                                border-gray-300 bg-white text-sm">
                                {filtersState.map((item) => (
                                    item.open && 
                                    <div key={item.id} className="bg-gray-100 rounded-lg">
                                        <div className="flex items-center m-1.5">
                                            {item.id === "id"  && <>
                                                <SearchInput onChange = {(e) => onSearchHandler(e, item.id)} debounce={250} 
                                                className="max-w-10 text-gray-400 mr-1" 
                                                placeholder={i18n.t(`replenishments.search.${item.id}`)}
                                                numericOnly
                                                />
                                                <XIcon className="flex-shrink-0 cursor-pointer text-gray-400 h-5 w-5"
                                                    onClick={() => handleResetFilter(item.id)} />                                              
                                            </>}
                                            {item.id === "sku"  && <>
                                                <SearchInput onChange = {(e) => onSearchHandler(e, item.id)} debounce={250} 
                                                className="max-w-10 text-gray-400 mr-1" 
                                                placeholder="SKU"
                                                />
                                                <XIcon className="flex-shrink-0 cursor-pointer text-gray-400 h-5 w-5"
                                                    onClick={() => handleResetFilter(item.id)} />                                              
                                            </>}
                                            {item.id === "type" && <>
                                                <select
                                                    className="mr-1 text-sm border-gray-300 rounded-md leading-5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white"
                                                    onChange={(e) => onSearchHandler(e.target.value, item.id)}>
                                                    <option value="">{i18n.t('replenishments.types.all')}</option>
                                                    {REPLENISHMENT_TYPES.map((option, index) => {
                                                        return <option key={index} value={option.id}>{i18n.t(option.label)}</option>
                                                    })}
                                                </select>
                                                <XIcon className="cursor-pointer text-gray-400 h-5 w-5"
                                                onClick={() => handleResetFilter(item.id)} />   
                                            </>}
                                            {item.id === "status" && <>
                                                <select
                                                    className="mr-1 text-sm border-gray-300 rounded-md leading-5 focus:outline-none focus:ring-2 
                                                        focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white"
                                                    onChange={(e) => onSearchHandler(e.target.value, item.id)}>
                                                    <option value="">{i18n.t('statuses.replenishment_statuses.select')}</option>
                                                    { statusFilters.map((status, index) => {
                                                        return <option key={index} value={status.id}>{i18n.t(status.label)}</option>
                                                    })}
                                                </select>
                                                <XIcon className="cursor-pointer text-gray-400 h-5 w-5"
                                                    onClick={() => handleResetFilter(item.id)} />   
                                                </>}
                                            {item.id === "completed_at" && <>
                                                <div className='flex flex-row items-center mr-1'>
                                                    <div className="mr-1.5">
                                                        {i18n.t('statuses.replenishment_statuses.completed')}
                                                    </div>
                                                    <ReactDatePicker
                                                        className="text-sm w-40 border-gray-300 rounded-md leading-5 focus:outline-none focus:ring-2
                                                            focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white"
                                                        dateFormat="dd/MM/yy" placeholderText={i18n.t('replenishments.search.from_to')}
                                                        selectsRange={true}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        onChange={(dates) => {
                                                            const [start, end] = dates;
                                                            setStartDate(start);
                                                            setEndDate(end);
                                                            if (start && end) onSearchHandler(dates, item.id)
                                                            else if (!start && !end ) {
                                                                setQueryParams((prev) => ({...prev, 'completed_at': ""}))
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <XIcon className="cursor-pointer text-gray-400 h-5 w-5"
                                                onClick={() => handleResetFilter(item.id)} />   
                                            </>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {/* Header section */}
                        <div className="border border-gray-300 bg-gray-50 flex justify-between px-4 py-2 mb-2">
                        {headers.map((item) => ( 
                            <div key={item.id}
                                className={`${ item.id == 'id' || item.id === 'replenishment_summary' ? 
                                    'w-1/6': 'w-1/3'} text-left font-semibold text-gray-700`}>{i18n.t(item.label)}
                            </div>
                        ))}
                        </div>
                        {/* Body section */}
                        <div className="flex flex-col flex-grow mt-5 gap-y-5 relative">
                            <Loader show={isFetching && isPreviousData} className="min-h-10"></Loader>
                            {data.replenishments.length == 0 && <>
                                <div className='flex justify-center text-gray-600 bg-gray-50 rounded-lg shadow p-5'>
                                    {i18n.t('replenishments.container.no_replenishments_to_show')}
                                </div>
                            </>}
                            {data.replenishments.map((replenishment) => (
                                <div key={replenishment?.id} 
                                    className="relative text-gray-500 bg-white rounded-lg shadow">
                                        <div className={`${replenishment?.sell_ahead ? 'bg-purple-200' : 'bg-blue-200'} absolute w-1.5 rounded-l-lg h-full`}></div>
                                    <div className="flex justify-between px-4 py-5">
                                        <div className="text-left w-1/6 pl-1">
                                            <div className='text-gray-700 inline 2xl:text-base max-w-9/10'> 
                                                <div className="inline font-semibold">#{replenishment?.id}</div> 
                                                {replenishment?.sell_ahead && 
                                                <div className='ml-1 text-purple-500 inline'>
                                                    | {i18n.t('replenishments.types.presale')} 
                                                </div>
                                                }
                                            </div>
                                            <div className='mt-2 leading-tight tracking-tight break-words max-w-9/10 pr-1'> 
                                                {replenishment?.notes} 
                                            </div>
                                            {replenishment?.invoices?.length > 0 &&
                                                <div className="mt-2 cursor-pointer text-blue-500 hover:text-blue-400"
                                                    onClick={() => {
                                                        setOpenDialogPrintInvoices(true)
                                                        setSelectReplenishment(replenishment)}}>
                                                    {i18n.t("replenishments.container.view_invoices")}
                                                </div>
                                            }
                                        </div>
                                        <div className="text-left w-1/3 text-sm 2xl:text-base">
                                            {renderStatusSection(replenishment)}
                                        </div>
                                        <div className="text-left w-1/3 text-sm 2xl:text-base">
                                            {renderSummarySection(replenishment)}
                                        </div>
                                        <div className="flex flex-col text-left w-1/6 text-sm 2xl:text-base">
                                            {renderTimelineSection(replenishment)}
                                            {renderEditAndCancelReplenishment(replenishment)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* Footer Navigation */}
                        <div className='mt-5 flex justify-between items-center px-2 rounded-lg border bg-white py-3'>
                            <div className="mx-1">
                                Total:  
                                <div className="inline mx-1 font-medium font-semibold">
                                    {data?.meta.total_count}
                                </div>
                                {i18n.t(`replenishments.footer_navigation.replenishments`)}
                            </div>
                            <div>
                            <SelectInput
                                options={pagesInterval}
                                selectedInitial={pagesInterval.find(option => option.id == queryParams.per_page) ?? pagesInterval[0]}
                                className="inline-block text-sm px-4 sm:px-0"
                                onChange={(page) => { handlePageSizeChange(page) }}
                                value={pagesInterval.find(option => option.id == queryParams.per_page) ?? pagesInterval[0]}
                            />
                            <Pagination 
                                className='inline'
                                size="small"
                                current={data?.meta.current_page}
                                total={data?.meta?.total_count} 
                                onChange={handlePaginationChange} 
                                page={data?.meta.current_page}
                                pageSize={pageSize}
                                showSizeChanger={false}
                            />
                            </div>
                        </div>
                    </div>
                    </>
                }
            </PageView>
            <Switch>
                <Route exact path={STORE_PATH + ADD_REPLENISHMENT}>
                    <SlidePanel title= {i18n.t("replenishments.container.create_inventory_receipt")} ref={addReplenishmentPanelRef} referrer={storePath + REPLENISHMENTS}>
                        <AddReplenishmentContainer onClose={()=>closeSlidePanel(addReplenishmentPanelRef)}/>
                    </SlidePanel>
                   
                </Route>
                <Route exact path={STORE_PATH + EDIT_REPLENISHMENT}>
                    <SlidePanel title={i18n.t("replenishments.container.edit_inventory_receipt")} ref={editReplenishmentPanelRef} referrer={storePath + REPLENISHMENTS}>
                        <AddReplenishmentContainer edit={true} onClose={()=>closeSlidePanel(editReplenishmentPanelRef)}/>
                    </SlidePanel>
                   
                </Route>
                <Route exact path={STORE_PATH + SHOW_REPLENISHMENT}>
                    <SlidePanel title={i18n.t("replenishments.container.completed_inventory_receipt")} ref={showReplenishmentPanelRef} referrer={storePath + REPLENISHMENTS}>
                        <ViewReplenishment onClose={()=>closeSlidePanel(showReplenishmentPanelRef)}/>
                    </SlidePanel>
                   
                </Route>

                <Route exact path={STORE_PATH + SHOW_REPLENISHMENT_INFO}>
                    <SlidePanel title={i18n.t("replenishments.container.see_replenishment")} ref={showReplenishmentPanelRef} referrer={storePath + REPLENISHMENTS}>
                        <ViewReplenishmentInfo onClose={()=>closeSlidePanel(showReplenishmentPanelRef)}/>
                    </SlidePanel>
                   
                </Route>
            </Switch>
        </>
    )
}