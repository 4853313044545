import axiosInstance from "../helpers/axiosInstance";

const getNcmData = (ncmData) => {
    const data = {
            ncm: ncmData.ncm,
            description: ncmData.description,
            cfop_tax_situation: ncmData.cfop_tax_situation.id,
            cfop_tax_situation_other_state: ncmData.cfop_tax_situation_other_state?.id,
            cfop_tax_situation_international: ncmData.cfop_tax_situation_international.id,
            cfop_sym_return_invoice_same_state: ncmData.cfop_sym_return_invoice_same_state.id,
            cfop_sym_return_invoice_other_state: ncmData.cfop_sym_return_invoice_other_state.id,
            cfop_sale_invoice_same_state: ncmData.cfop_sale_invoice_same_state.id,
            cfop_sale_invoice_other_state: ncmData.cfop_sale_invoice_other_state.id, 
            cfop_sale_invoice_international: ncmData.cfop_sale_invoice_international.id,
            cfop_donation_invoice_same_state: ncmData.cfop_donation_invoice_same_state.id, 
            cfop_donation_invoice_other_state: ncmData.cfop_donation_invoice_other_state.id, 
            cfop_donation_return_invoice_same_state: ncmData.cfop_donation_return_invoice_same_state.id, 
            cfop_donation_return_invoice_other_state: ncmData.cfop_donation_return_invoice_other_state.id, 
            cfop_replenishment_invoice_same_state: ncmData.cfop_replenishment_invoice_same_state, 
            cfop_replenishment_invoice_other_state: ncmData.cfop_replenishment_invoice_other_state,
            store_id: ncmData.store_id,
            cfop_replenishment_adjustment_invoice_same_state: ncmData.cfop_replenishment_adjustment_invoice_same_state, 
            cfop_replenishment_adjustment_invoice_other_state: ncmData.cfop_replenishment_adjustment_invoice_other_state, 
            cfop_return_invoice: ncmData.cfop_return_invoice.id, 
            icms_credit_tax_rate: ncmData.icms_credit_tax_rate,
            ipi_tax_situation: ncmData.ipi_tax_situation.id, 
            ipi_framing_code: ncmData.ipi_framing_code.id, 
            ipi_tax_rate: ncmData.ipi_tax_rate, 
            pis_tax_situation: ncmData.pis_tax_situation.id, 
            pis_tax_rate: ncmData.pis_tax_rate, 
            cofins_tax_situation: ncmData.cofins_tax_situation.id, 
            cofins_tax_rate: ncmData.cofins_tax_rate,
            exception_ncm_variant: ncmData.exception_ncm_variant
    }
    return data
}


export const fetchNcms = async (params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/ncm_configurations`, { params })
    return res.data
}


export const fetchNcm = async (id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/ncm_configurations/${id}`)
    return res.data
}


export const createNcm = async (ncmData) => {

    const data = {
        ...getNcmData(ncmData)
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/ncm_configurations`, data)
    return res.data
}


export const editNcm = async (id, ncmData) => {

    const data = {
        ...getNcmData(ncmData)
    }
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/ncm_configurations/${id}`, data)
    return res.data
}


