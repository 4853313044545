import {PageTopBar, PageView, SlidePanel} from "../../components";
import {useTranslation} from "react-i18next";
import {UserContext} from "../../hooks/UserContext";
import {useContext} from "react";
import {useRouteMatch} from "react-router-dom";
import {INVOICE_CNABS, INVOICE_CNABS_SHOW, INVOICE_ORDERS, INVOICE_REPLENISHMENTS, INVOICE_RETURNS, INVOICES, STORE_PATH} from "../../navigation/constants";
import { Redirect, Switch, Route } from "react-router-dom";
import {TopTabs} from "../../components/TopTabs";
import {OrderInvoicesContainer} from "./OrderInvoicesContainer";
import {ReplenishmentInvoicesContainer} from "./ReplenishmentInvoicesContainer";
import {ReturnInvoicesContainer} from "./ReturnInvoicesContainer";
import { CnabsContainer } from "./CnabsContainer";
import { ShowCnabContainer } from "./ShowCnabContainer";


export const InvoicesContainer  = function () {
    const {user} = useContext(UserContext)
    const { t } = useTranslation();
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })

    const OrderInvoices = () => <OrderInvoicesContainer />
    const ReplenishmentInvoices = () => <ReplenishmentInvoicesContainer />
    const ReturnInvoices = () => <ReturnInvoicesContainer />
    const CnabInvoices = () => <CnabsContainer />

    const tabsData = [
        { name: t('invoices.list.from_orders.tab_name'), href: storePath + INVOICE_ORDERS, component: OrderInvoices, current: true },
        { name: t('invoices.list.from_replenishments.tab_name'), href: storePath + INVOICE_REPLENISHMENTS, component: ReplenishmentInvoices },
        { name: t('invoices.list.from_returns.tab_name'), href: storePath + INVOICE_RETURNS, component: ReturnInvoices },
        { name: t('invoices.list.from_cnabs.tab_name'), href: storePath + INVOICE_CNABS, component: CnabInvoices }
    ]

    const topBar =
        <PageTopBar>
            <div className="text-lg font-semibold"><span className="text-gray-400">{ user.current_store.name } &rarr; </span>{t("invoices.invoices")}</div>
        </PageTopBar>

    return (
        <PageView
            topMenu={topBar}
            childrenFullWidth={true}
            topMenuFullWidth={true}
        >
            <TopTabs tabs={tabsData} />

            <Switch>
                <Route exact path={storePath + INVOICE_CNABS_SHOW}>
                    <SlidePanel title={t('cnabs.showContainer.title')} referrer={storePath + INVOICE_CNABS}>
                        <ShowCnabContainer />
                    </SlidePanel>
                </Route>
                <Route exact path={storePath + INVOICES}>
                    <Redirect from={storePath + INVOICES} to={storePath + INVOICE_ORDERS} />
                </Route>
            </Switch>
        </PageView>
    )
}