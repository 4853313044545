import { useTranslation } from "react-i18next"
import { CheckCircleIcon } from "@heroicons/react/outline"

import { Notification } from "./Notification"

export const ExportNotification = ({exportProgress, onDownloadFile, showNotification, handleOnShowNotification}) => {
    const { i18n } = useTranslation()

    return (
        <Notification
            show={showNotification}
            setShow={handleOnShowNotification}
            ephemeral={false}
            customBody={
                <div className="w-0 flex-1 flex justify-between">
                    {exportProgress === 100 &&
                        <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                        </div>
                    }
                    <p className="my-0 ml-2 w-0 flex-1 text-sm font-medium text-gray-900">
                        {exportProgress < 100 ? i18n.t('export_notification.generating') : i18n.t('export_notification.generated')}
                    </p>

                    {exportProgress < 100 ? (
                        <p className="my-0 flex-shrink-0 text-base font-medium text-indigo-600">{exportProgress}%</p>
                    ) : (
                        <button
                            type="button"
                            className="ml-3 flex-shrink-0 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => onDownloadFile()}
                        >
                            {i18n.t('export_notification.download')}
                        </button>
                    )}
                </div>
            }
        />
    )
}
