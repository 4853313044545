import cubboLogo from "../../assets/logo-2.png"
import {useState} from "react";
import {Disclosure, Transition, Menu} from "@headlessui/react";
import {useHistory, Link, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Fragment} from "react";
import {Notification} from "../../components";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from 'react-i18next';
import { ROOT, FORGOT_PASSWORD, SIGNUP, SHOPIFY_ONBOARDING } from "../../navigation/constants";

export const Login = () => {

    const { t, i18n } = useTranslation();

    const {register, handleSubmit, formState: { errors }} = useForm({})

    const history = useHistory()
    
    const query_string = useLocation().search;
    const shopify_state = new URLSearchParams(query_string).get('shopify_state');

    const urlEmail = useLocation().search;
    const url_email = new URLSearchParams(urlEmail).get('email');
    
    const languages = [
      { id: 8, name: i18n.t("topMenu.spanish")},
      { id: 9, name: i18n.t("topMenu.portuguese")},
    ]

    const [showNotification, setShowNotification] = useState(false)

    const [loginUser, isLoading, isError] = useAuth()

    const onSubmit = (data) => {
        const {email, password} = data
        loginUser({email, password}).then((user)=>{
            console.log(user)
            if (shopify_state) {
              history.push(SHOPIFY_ONBOARDING+query_string)
            }
            else if (user.current_store) {
                history.push("/")
            }
            else {
                history.push(ROOT)
            }
        }).catch((e)=>{
            setShowNotification(true)
        })
    }

    function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }

    const changeLanguage = (e, item) => {
      e.preventDefault()
      if(item.id === 8 ){
          i18n.changeLanguage("es")
      }
      if(item.id === 9) {
          i18n.changeLanguage("pt")
      }
    }

    const getLanguageImage = () => {
      return `https://cdn1.vectorstock.com/i/thumb-large/93/10/change-language-icon-isolated-on-blank-background-vector-38909310.jpg`
    }

    return (
        <div className="min-h-screen bg-light-blue-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <Menu as="div" className="ml-4 relative flex-shrink-0">
            {({ open }) => (
              <>
                  <div>
                      <Menu.Button>
                          {<>
                              <span className="sr-only">Change Languaje</span>
                              <img className="h-8 w-8 rounded-full bg-white" src={getLanguageImage()} alt="" />
                          </>}
                      </Menu.Button>
                  </div>
                  <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                  >
                      <Menu.Items
                          static
                          className="origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                          style={{zIndex:999}}
                      >
                          { languages.map((item) => (
                              <Menu.Item key={item.name}>
                                  {({ active }) => (
                                      <a
                                          href={item.href}
                                          className={classNames(
                                              active ? 'bg-gray-100' : '',
                                              'block px-4 py-2 text-sm text-gray-700'
                                          )}
                                          onClick={(e) => changeLanguage(e, item)}
                                      >
                                          {item.name}
                                      </a>
                                  )}
                              </Menu.Item>
                          ))}
                      </Menu.Items>
                  </Transition>
              </>
            )}
          </Menu>
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src={cubboLogo}
              alt="Workflow"
            />
            
          </div>
          <div className="mt-16 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <h2 className="mt-3 mb-3 text-center text-2xl text-gray-900"> {i18n.t("login.title")} </h2>
            <p className="text-center text-gray-500 mb-12"> {i18n.t("login.detail")}</p>
            <form action="#" method="POST" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {i18n.t("login.email")}
                  </label>
                  <div className="mt-1">
                  <input
                        name="email"
                        defaultValue= {url_email}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        {...register("email", {
                            required: i18n.t("login.required_field"),
                        })}
                    />
                    <p className="mt-2 text-sm text-red-600">
                        {errors.email && errors.email.message}
                    </p>
                  </div>
                </div>
    
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    {i18n.t("login.password")}
                  </label>
                  <div className="mt-1">
                    <input
                        name="password"
                        type="password"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        {...register("password", {
                            required: i18n.t("login.required_field"),
                        })}
                    />
                    <p className="mt-2 text-sm text-red-600">
                        {errors.password && errors.password.message}
                    </p>
                </div>
                </div>
    
                <div className="flex items-center justify-between">
    
                  <div className="text-sm">
                    <Link
                      to={FORGOT_PASSWORD}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                      key={"web"+FORGOT_PASSWORD}
                                        >
                        {i18n.t("login.recover_pwd")}
                     </Link>
                  </div>
                </div>
    
                <div>
                  {/* <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Sign in
                  </button> */}
                  {isLoading ? (
                      i18n.t("login.get_in")
                  ) : (
                      <>
                          {isError && showNotification? (
                              <>
                                  <Notification show={showNotification} setShow={setShowNotification} type="error" title="Error" description={i18n.t("login.error_message")}/>
                              </>
                          ) : null}
                          <button
                              type="submit"
                              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                              {i18n.t("login.button")}
                          </button>
                      </>
                  )}
                </div>
              </form>
    
    
            </div>
{/* 
            <div className="mt-16 items-center justify-between">
    
                  <div className="text-sm underline">
                    <Link
                      to={SIGNUP+query_string}
                      className="font-medium text-gray-50 hover:text-gray-500"
                      key={"web"+SIGNUP}
                                        >
                       {i18n.t("login.create_account")}
                     </Link>
                  </div>
            </div> */}

          </div>
        </div>
    
    )
}