import  i18n from "../../translations/index";

export const getReturnTypeDefinition = (type = "") => {
    let foundIndex = RETURN_TYPES.findIndex(def => def.type === type)
    if (foundIndex === -1) {
      return RETURN_TYPES[0]
    }
    else {
      return RETURN_TYPES[foundIndex]
    }
  }
  
export const RETURN_TYPES = [
    {
        type: "unknown",
        label: "returns.types.unknown",
        classes: "",
        filterable: false
    },
    {
        type: "SIMPLE_RETURN",
        label: "returns.types.return",
        classes: "",
        filterable: true
    },
    {
        type: "EXCHANGE",
        label: "returns.types.change",
        classes: "",
        filterable: true
    },
    {
        type: "IMMEDIATE_EXCHANGE",
        label: "returns.types.immediate_change",
        classes: "",
        filterable: true
    },
    {
        type: "RETURNED_BY_CARRIER",
        label: "returns.types.parcel",
        classes: "",
        filterable: true
    },
    {
        type: "TO_BE_DETERMINED",
        label: "returns.types.awaiting_review", 
        classes: "",
        filterable: true
    },
    {
        type: "UNIDENTIFIED",
        label: "returns.types.unidentified", 
        classes: "",
        filterable: true
    }
]