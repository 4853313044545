export const ORDER_STATUSES = [

  {
    status: "unknown",
    label: "statuses.order_statuses.unknown",
    classes: "",
    filterable: false
  },
  {
    status: "initial",
    label: "statuses.order_statuses.initial",
    classes: "bg-red-50 text-red-400",
  },
  {
    status: "empty",
    label: "statuses.order_statuses.empty",
    classes: "bg-red-50 text-red-400",
    tooltip: "statuses.tooltip.empty"
  },
  {
    status: "pending",
    label: "statuses.order_statuses.pending",
    classes: "bg-purple-50 text-purple-500",
  },
  {
    status: "processing",
    label: "statuses.order_statuses.processing",
    classes: "bg-gray-100 text-gray-500",
  },
  {
    status: "packing",
    label: "statuses.order_statuses.processing",
    classes: "bg-gray-100 text-gray-500",
    filterable: false
  },
  {
    status: "picking",
    label: "statuses.order_statuses.processing",
    classes: "bg-gray-100 text-gray-500",
    filterable: false
  },
  {
    status: "waiting_pick_up",
    label: "statuses.order_statuses.waiting_pick_up",
    classes: "bg-blue-50 text-blue-500",
    filterable: true
  },
  // Only for pick and ship 
  {
    status: "awaiting_pick_up",
    label: "statuses.order_statuses.awaiting_pick_up",
    classes: "bg-blue-50 text-blue-500",
    filterable: false
  },
  {
    status: "picked_up",
    label: "statuses.order_statuses.picked_up",
    classes: "bg-blue-50 text-blue-500",
    filterable: false
  },
  {
    status: "in_warehouse",
    label: "statuses.order_statuses.in_warehouse",
    classes: "bg-blue-50 text-blue-500",
    filterable: false
  },
  {
    status: "packed",
    label: "statuses.order_statuses.processing",
    classes: "bg-gray-100 text-gray-500",
    filterable: false
  },
  {
    status: "shipped",
    label: "statuses.order_statuses.shipped",
    classes: "bg-green-50 text-green-500",
  },
  {
    status: "returning",
    label: "statuses.order_statuses.returning",
    classes: "bg-purple-50 text-purple-500",
  },
  {
    status: "return_arrived",
    label: "statuses.order_statuses.return_arrived",
    classes: "bg-pink-50 text-pink-500",
  },
  {
    status: "returned",
    label: "statuses.order_statuses.returned",
    classes: "bg-yellow-50 text-yellow-600",
  },
  {
    status: "reentered",
    label: "statuses.order_statuses.reentered",
    classes: "bg-green-50 text-green-700",
  },
  {
    status: "canceled",
    label: "statuses.order_statuses.canceled",
    classes: "bg-red-50 text-red-500",
  },
  {
    status: "error",
    label: "statuses.order_statuses.error",
    classes: "bg-red-50 text-red-500",
  },
  {
    status: "entirely_dropshipping",
    label: "statuses.order_statuses.entirely_dropshipping",
    classes: "bg-red-50 text-red-500",
    tooltip: "statuses.tooltip.entirely_dropshipping"
  },
  {
    status: "backorder",
    label: "statuses.order_statuses.backorder",
    classes: "bg-red-50 text-red-400",
  },
  {
    status: "unpaid",
    label: "statuses.order_statuses.unpaid",
    classes: "bg-pink-50 text-pink-500",
  },
  {
    status: "cancelation_window",
    label: "statuses.order_statuses.cancelation_window",
    classes: "bg-blue-50 text-blue-500",
    tooltip: "statuses.tooltip.cancelation_window"
  },
  {
    status: "holded",
    label: "statuses.order_statuses.hold",
    classes: "bg-blue-50 text-blue-500"
  },
  {
    status: "interrupted",
    label: "statuses.order_statuses.interrupted",
    classes: "bg-yellow-50 text-yellow-700",
    tooltip: "statuses.tooltip.interrupted",
  }
];

export const TRACKING_STATUSES_DEFINITIONS = [
  {
    status: "unknown",
    label: "statuses.tracking_statuses.unknown",
    classes: "",
    filterable: false
  },
  {
    status: "shipment_not_defined",
    label: "statuses.tracking_statuses.unknown",
    classes: "",
    filterable: false
  },
  {
    status: "shipment_created",
    label: "statuses.tracking_statuses.unknown",
    // label: "Guía generada",
    classes: "bg-gray-100 text-gray-500",
    filterable: false
  },
  {
    status: "shipment_collected",
    label: "statuses.tracking_statuses.in_transit",
    // label: "Recolectado",
    classes: "bg-gray-100 text-gray-500",
    filterable: false
  },
  {
    status: "shipment_in_transit",
    label: "statuses.tracking_statuses.in_transit",
    classes: "bg-gray-100 text-gray-500",
  },
  {
    status: "shipment_delivered",
    label: "statuses.tracking_statuses.shipment_delivered",
    classes: "bg-green-50 text-green-400",
  },
  {
    status: "shipment_canceled",
    label: "statuses.tracking_statuses.shipment_canceled",
    classes: "bg-red-50 text-red-400"
  },
  {
    status: "shipment_out_for_delivery",
    label: "statuses.tracking_statuses.shipment_out_for_delivery",
    classes: "bg-blue-50 text-blue-500"
  },
  {
    status: "shipment_to_be_self_collected",
    label: "statuses.tracking_statuses.shipment_to_be_self_collected",
    classes: "bg-yellow-50 text-yellow-500"
  },
  {
    status: "shipment_returned",
    label: "statuses.tracking_statuses.shipment_with_incident",
    // label: "Retornado por paqueteria",
    classes: "bg-yellow-50 text-yellow-500",
    filterable: false
  },
  {
    status: "shipment_with_incident",
    label: "statuses.tracking_statuses.shipment_with_incident",
    classes: "bg-yellow-50 text-yellow-500"
  }
];

export const SHIPPING_INCIDENT_STATUSES = [
  {
    status: "PENDING",
    label: "orders.shipping_incident.status.PENDING",
  },
  {
    status: "IN_REVIEW",
    label: "orders.shipping_incident.status.IN_REVIEW",
  },
  {
    status: "RESOLVED",
    label: "orders.shipping_incident.status.RESOLVED",
  },
];

export const RETURNS_STATUSES = [
  {
    status: "unknown",
    label: "statuses.returns_statuses.unknow",
    classes: "",
    filterable: false
  },
  {
    status: "ARRIVED_AT_WAREHOUSE",
    label: "statuses.returns_statuses.arrived_at_warehouse",
    classes: "bg-pink-50 text-pink-500",
  },
  {
    status: "RECEIVED",
    label: "statuses.returns_statuses.received",
    classes: "bg-yellow-50 text-yellow-600",
  },
  {
    status: "REENTERED",
    label: "statuses.returns_statuses.reentered",
    classes: "bg-green-50 text-green-500",
  },
  {
    status: "REQUESTED",
    label: "statuses.returns_statuses.requested",
    classes: "bg-blue-50 text-blue-500",
  },
  {
    status: "PENDING",
    label: "statuses.returns_statuses.pending",
    classes: "bg-red-50 text-red-500",
  },
  {
    status: "CANCELLED",
    label: "statuses.returns_statuses.cancelled",
    classes: "bg-grey-50 text-grey-400",
  },
  {
    status: "ORDERED",
    label: "statuses.returns_statuses.ordered",
    classes: "bg-purple-50 text-purple-500",
  },
];

export const SHIPPING_INCIDENT_CATEGORIES = [
  {status: 'CANCELED', label: 'orders.shipping_incident.category.CANCELED'},
  {status: 'MISSING_REFERENCES', label: 'orders.shipping_incident.category.MISSING_REFERENCES'},
  {status: 'DELAYED', label: 'orders.shipping_incident.category.DELAYED'},
  {status: 'FALSE_DELIVERY', label: 'orders.shipping_incident.category.FALSE_DELIVERY'},
  {status: 'DAMAGED', label: 'orders.shipping_incident.category.DAMAGED'},
  {status: 'MISSING_PRODUCT', label: 'orders.shipping_incident.category.MISSING_PRODUCT'},
  {status: 'CHANGE_ADDRESS', label: 'orders.shipping_incident.category.CHANGE_ADDRESS'},
  {status: 'RESCHEDULE', label: 'orders.shipping_incident.category.RESCHEDULE'},
];