import axiosInstance from "../helpers/axiosInstance"

export const fetchStorePackingMaterials = async (warehouseId, storeId) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-packing-materials/store/${storeId}/warehouse/${warehouseId}`)
    return res.data
}

export const updateStorePackingMaterials = async (warehouseId, storeId, params) => {
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-packing-materials/store/${storeId}/warehouse/${warehouseId}`, params)
    return res.data
}
