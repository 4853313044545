import { useTranslation } from "react-i18next";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";

import { AVAILABLE_PRODUCT_INSERT_OPTIONS } from "../../utils/businessRuleActionUtils";

const ProductInsertOptions = ({productInsertOption, onSelectedProductInsertOption}) => {
    const { t } = useTranslation()

    const handleOnSelectProductInsertOption = (newSelectedProductInsertOption) => {
        const updatedProductInsertOption = newSelectedProductInsertOption === productInsertOption ? null : newSelectedProductInsertOption

        onSelectedProductInsertOption(updatedProductInsertOption)
    }

    return (
        <div>
            <div className="text-lg font-semibold bg-gray-100 p-5 flex gap-2">
                <span>
                    {t("settings.business_rule_creator.business_rule_action_settings.product_insert_options_title")}
                    <span className="text-red-500">{" *"}</span>
                </span>

                {!productInsertOption ? (
                    <ExclamationCircleIcon className="h-6 text-red-600" />
                ) : (
                    <CheckCircleIcon className="h-6 text-green-600" />
                )}
            </div>

            <div className="flex flex-col p-5 gap-5">
                {Object.values(AVAILABLE_PRODUCT_INSERT_OPTIONS).map((availableProductInsertOption) => (
                    (!productInsertOption || productInsertOption === availableProductInsertOption) && (
                        <label
                            key={availableProductInsertOption}
                            htmlFor={availableProductInsertOption}
                            className="border rounded cursor-pointer hover:bg-gray-50 flex justify-between gap-0.5 p-5"
                        >
                            <input
                                className="hidden"
                                type="checkbox"
                                value={availableProductInsertOption}
                                name={availableProductInsertOption}
                                id={availableProductInsertOption}
                                onChange={() => handleOnSelectProductInsertOption(availableProductInsertOption)}
                            />

                            <span className="flex flex-col">
                                <span className="text-base font-semibold">
                                    {t(`settings.business_rule_creator.business_rule_action_settings.product_insert_options.${availableProductInsertOption}.title`)}
                                </span>
                                <span className="text-sm">
                                    {t(`settings.business_rule_creator.business_rule_action_settings.product_insert_options.${availableProductInsertOption}.description`)}
                                </span>
                            </span>

                            {productInsertOption === availableProductInsertOption && (
                                <span className="font-normal text-blue-700 flex items-center text-lg mr-5">
                                    {t("settings.business_rule_creator.business_rule_action_settings.change_option")}
                                </span>
                            )}
                        </label>
                    )
                ))
                }
            </div>
        </div>
    )
}

export default ProductInsertOptions