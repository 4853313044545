/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect, useRef } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import PropTypes from "prop-types";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const ComboBox = ({
    options= [{id: 0, name: "title"}],
    selectedInitial = 0,
    className= "",
    label= "",
    value=null,
    required= false,
    onChange = (value) => console.log("onChange", value)
}) => {
    const comboBtn = useRef(null);
    ComboBox.propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.any.isRequired,
            name: PropTypes.string.isRequired
        })).isRequired,
        label: PropTypes.string,
        selectedInitial: PropTypes.shape({
            id: PropTypes.any.isRequired,
            name: PropTypes.string.isRequired
        }),
        className: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.shape({
            id: PropTypes.any.isRequired,
            name: PropTypes.string.isRequired
        })
    }

    const [selected, setSelected] = useState(selectedInitial)
    const [query, setQuery] = useState('')
    const onSearchInputFocus = () => comboBtn.current?.click()
    useEffect(() => {
        setSelected(value)
    }, [value])

    const handleOnChange = (value) => {
        setSelected(value)
        onChange(value)
    }

    const filteredOptions = query === ''
      ? options
      : options.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase())
        })

    return (
        <div className={className}>
            <Combobox value={selected} onChange={handleOnChange}>
                { label &&<Combobox.Label className="block text-sm font-medium text-gray-700">{label}{required && <span className="text-red-500"> *</span>}</Combobox.Label> }
                <div className="relative">
                    <Combobox.Input
                        className={"relative w-full bg-white pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm shadow-sm border rounded-md "+ ( required && !selected?.name ? ' border-red-400' : ' border-gray-400 ')}
                        displayValue={(person) => person.name}
                        onClick={onSearchInputFocus}
                        onChange={(event) => setQuery(event.target.value)}
                        />
                    <Combobox.Button ref={comboBtn} className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </Combobox.Button>
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}
                >
                    <Combobox.Options className="absolute z-10 mt-1 bg-white shadow-lg max-h-32 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {filteredOptions.length === 0 && query !== '' ? (
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                        Nothing found.
                        </div>
                    ) : (
                    filteredOptions.map((person) => (
                        <Combobox.Option
                            key={person.id}
                            className={({ active }) =>
                                classNames(
                                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-8 pr-2'
                                )
                            }
                            value={person}
                        >
                            {({ selected, active }) => (
                                <>
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                        {person.name}
                                    </span>

                                    {selected ? (
                                        <span
                                            className={classNames(
                                                active ? 'text-white' : 'text-indigo-600',
                                                'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                            )}
                                        >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    ) : null}
                                </>
                            )}
                        </Combobox.Option>
                        ))
                    )}
                    </Combobox.Options>
                </Transition>
            </Combobox>
        </div>
    )
}