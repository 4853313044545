import React, {useState} from "react";

export const InputQty = React.forwardRef(({value, onChange, title=null, labelElement=null, placeholder, onBlur, ...params}, ref) => {
    const { className } = params
    return (
        <div className={className}>
            { title && <label className="block text-sm font-medium text-gray-700">
                {title}
            </label>}
            {labelElement}
            <div >
                <input
                    ref={ref}
                    type="number"
                    className="focus:border-gray-100 block w-full border-transparent border-gray-100"
                    placeholder={placeholder}
                    onChange={(event) => {
                        if(onChange!=null){
                            onChange(event.target.value)
                        }
                    }}
                    onBlur={(e)=> onBlur(e)}
                    value={value}
                />
            </div>
        </div>
    )
})