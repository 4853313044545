import {Button} from "../../components";
import {useQuery,useQueryClient} from "react-query";
import {useState,useContext} from "react";
import { Loader } from "../../components/Loader";
import { getLocations,  updateLocation } from "../../services";
import {UserContext} from "../../hooks/UserContext";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useTranslation } from 'react-i18next';

export const LocationSelectView = ({
    title,
    channel,
    onSave,
    onCancel
}) => {
    const { t, i18n } = useTranslation(); 
    const { user } = useContext(UserContext)
    const [queryParams, setQueryParams] = useState({
        store_id: user.current_store?.id,
    })
    const [location, setLocation] = useState(channel.location)
    const [onlyReadThisLocationOrders, setOnlyReadThisLocationOrders] = useState(channel.config?.only_read_orders_from_location)
    const queryClient = useQueryClient()
    
    const {  data, status, isLoading, isError, isPreviousData, isFetching, error } = useQuery(['locations', channel?.id], () => getLocations(channel.id))

    const handleClickOnCancel = () => {
        if(onCancel){
            onCancel()
        }
    }

    const handleCheckboxChange = (index) =>{
        setLocation(index)

    }

    const handleClickOnSave = async () => {     
        
        let only_read_location_orders = undefined

        if (channel.channel_type_name === "vtex") {
            only_read_location_orders = onlyReadThisLocationOrders
        }

        const response = await updateLocation(
            channel.id,
            location,
            only_read_location_orders
        )
        await queryClient.invalidateQueries('locations')
        setTimeout(() => {
            onSave()
        }, 500);
    }


    return (
        <div className="">
            <div className="space-y-3 divide-y divide-gray-200">
            <div>
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
                </div>
            </div>
            
            <div>
            
                <div className="flow-root mt-6">
                    <ul className ="-my-5 divide-y divide-gray-200">
                    <li className="py-4">
                        {i18n.t("integrations.select_view.Select_store_location")}
                    </li>
                    
                    {!isPreviousData && isLoading ? (
                        <><Loader show={isLoading}></Loader></>
                    ) : isError ? (
                        <>Error: {error.message}</>
                    ) : !data.length? (
                        <><p className="text-center p-10">{i18n.t("integrations.select_view.name")}</p></>
                    ) :(
                        
                         
                        data.map((item, index)=>{
                            return (
                                
                                <li className="py-4" key={index}>
                                    <div className="flex items-center">
                                        <div className="flex-1 min-w-0 mx-2">
                                            <label className="text-sm font-semibold text-gray-900 truncate mb-2">
                                                {item.name || item.nome || item.warehouseName} - {item?.saleCountryList}
                                            </label>
                                        </div>
                                        <div className="flex-1 min-w-0 mx-2 text-center">
                                        {channel.channel_type_name == "shein" ? (
                                            <Checkbox
                                                name= {i18n.t("integrations.select_view.1")} 
                                                checked={item.warehouseCode == location}
                                                onChange={() => handleCheckboxChange(item.warehouseCode)}
                                                type="checkbox"
                                            />
                                        ):(

                                        <Checkbox
                                            name= {i18n.t("integrations.select_view.select")} 
                                            checked={item.id == location}
                                            onChange={() => handleCheckboxChange(item.id)}
                                            type="checkbox"
                                        />
                                        )}

                                        </div>
                                    </div>
                              </li>
                                
                                
                                
                            
                            )
                        })

                    )}
                    </ul>

                </div>
            </div>

            <div className="pt-4">
                {channel.channel_type_name == "vtex" &&
                    <div>
                        <input
                            className="input"
                            type="checkbox"
                            checked={onlyReadThisLocationOrders}
                            onChange={({target: {checked}}) => {
                                setOnlyReadThisLocationOrders(checked)
                        }}/>
                        <span className="ml-3">{i18n.t("integrations.select_view.only_read_location_orders")}</span>
                    </div>
                }
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">   
                    <Button type="secondary" onClick={handleClickOnCancel}>{i18n.t("integrations.select_view.cancel")}</Button>
                    <Button  onClick={handleClickOnSave}>
                        {i18n.t("integrations.select_view.save")}
                    </Button>
                </div>
            </div>
        </div>

       
        </div>
    )
}