import { PencilIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { CARD_STEPS } from '../utils/constants'

const SectionOption = ({ title = null, subtitle = null, status = CARD_STEPS.HIDDEN, onEdit = null}) => {
    const { t } = useTranslation()

    return (
        <div className={`flex flex-col border-solid border border-gray-400 mb-3 p-4 rounded-md relative ${status !== CARD_STEPS.COMPLETED ? "hover:bg-gray-100 cursor-pointer" : "cursor-not-allowed"}`}>
            {status === CARD_STEPS.COMPLETED && <div className="absolute"></div>}
            <span className="text-md text-gray-700">
                {title}
                {status === CARD_STEPS.COMPLETED && (
                    <PencilIcon className="cursor-pointer hover:text-gray-500 h-6 text-gray-600 relative float-right" onClick={onEdit}/>
                )}
            </span>
            <span className="text-sm text-gray-500">
                {subtitle}
            </span>
        </div>

    )
}

export default SectionOption