import React, { useEffect, useMemo, useState } from 'react'
import { DialogView } from '../DialogView'
import { Loader } from '../Loader'
import { Button } from '../Button'
import { cancelOrders, holdOrders, unholdOrders, markAsPayedOrders, fetchOrderCreateBulkTracker} from "../../services/orderServices";
import { useTranslation } from 'react-i18next'
import { CheckCircleIcon, ExclamationCircleIcon, QuestionMarkCircleIcon, XIcon } from '@heroicons/react/outline'
import { cloneDeep } from 'lodash'
import { Tooltip } from 'antd'
import { useRef } from 'react'
import Collapse from './Collapse';
import { Notification } from '../Notification';
import { Trans } from 'react-i18next'

export const ACTIONS = {
  CANCEL_ORDERS: 'CANCEL_ORDERS',
  HOLD_ORDERS: 'HOLD_ORDERS',
  UNHOLD_ORDERS: 'UNHOLD_ORDERS',
  MARK_AS_PAYED: "MARK_AS_PAYED"
}

// const ACTION_MODAL_STATES = {
//   CONFIRMATION: 'CONFIRMATION',

export const OrderActionModal = ({ open = false, action = null, orders, onCancel = () => {}, onSuccess = () => {}, actionParameters = {} }) => {
  const { i18n } = useTranslation()

  const [visible, setVisible] = useState(open)
  const [busy, setBusy] = useState(false)
  const [hasConfirmed, setHasConfirmed] = useState(false)
  const [unexpectedErrorMessage, setUnexpectedErrorMessage] = useState(null)
  const [ordersWithErrors, setOrdersWithErrors] = useState(null)

  const [notificationOpen, setNotificationOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isComplete, setIsComplete] = useState(false)
  const [failures, setFailures] = useState(0)
  const [total, setTotal] = useState(0)
  const [pending, setPending] = useState(0)

  const modalMainContainerRef = useRef(null)

  useEffect(() => {
    setVisible(open)
    if (open === true) {
      reset()
    }
  }, [open])

  const handleClose = () => {
    if (busy) return
    onCancel()
  }

  const reset = () => {
    setHasConfirmed(false)
    setUnexpectedErrorMessage(null)
    setOrdersWithErrors(null)
  }

  const onActionConfirm = async (ordersToProcess = null) => {
    setBusy(true)
    setUnexpectedErrorMessage(null)
    if (!ordersToProcess) ordersToProcess = orders
    try {
      switch (action) {
        case ACTIONS.CANCEL_ORDERS:
          await onCancelOrders(ordersToProcess.map(order => order.id))
          break
        case ACTIONS.HOLD_ORDERS:
          await onHoldOrders(ordersToProcess.map(order => order.id))
          break
        case ACTIONS.UNHOLD_ORDERS:
          await onUnholdOrders(ordersToProcess.map(order => order.id))
          break
        case ACTIONS.MARK_AS_PAYED:
          await onMarkAsPayedOrders(ordersToProcess.map(order => order.id))
          break
        default:
          console.warn('No action selected')
          break
      }
    } catch (error) {
      console.log(error)
      if (error.response?.status === 500) {
        setUnexpectedErrorMessage(error.response?.data?.error)
      } else if (error.response?.status === 422) {
        if(error.response?.data?.error){
          setUnexpectedErrorMessage(error.response?.data?.error)
        }else{
          handleErrors(error.response?.data?.errors)
        }
      } else {
        setUnexpectedErrorMessage(error.message)
      }
    }
    setBusy(false)
  }

  const handleErrors = errors => {
    // console.log(errors)
    const errorsThatAreStrings = errors.filter(error => typeof error === 'string')
    if (errorsThatAreStrings.length > 0) {
      setUnexpectedErrorMessage(errorsThatAreStrings[0])
      return
    }
    let newOrdersWithErrors = []
    let orderIdsWithError = errors.map(error => error.context?.order_id).filter(order_id => order_id !== undefined);
    if (orderIdsWithError.length){
      newOrdersWithErrors = orders.filter(order => orderIdsWithError.includes(order.id))
      newOrdersWithErrors = newOrdersWithErrors.map(order => {
        return {
          ...cloneDeep(order),
          error: errors.find(error => error.context?.order_id === order.id),
        }
      })
    }
    setHasConfirmed(true)
    setOrdersWithErrors(newOrdersWithErrors)
  }

  const cancelProgressNotificationBody = (
    <>
  
        <div className="w-0 flex-1 flex items-center justify-between">
          {!isComplete ? <>
            <div className="my-0 ml-2 w-0 flex-1 text-sm font-medium text-gray-900">
              {i18n.t('orders.action_bar.notification.canceling_orders')}
            </div>
            <p className="my-0 flex-shrink-0 text-base font-medium text-indigo-600">{total ? Math.round(((total-pending)/total) * 100) : 0}%</p>
          </> : <>
            <div className="flex">
              {failures !== 0 ? 
                <div className="flex-shrink-0">
                  <ExclamationCircleIcon className="flex-shrink-0 h-6 w-6 text-yellow-400" aria-hidden="true"/>
                </div>
                : <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
              }
              <p className='my-0 mx-4 text-sm font-medium text-gray-900'>
                <Trans i18nKey="orders.action_bar.notification.orders_canceled" values={{canceled: total - failures, total: total}} />
              </p>
            </div>
            <button className="items-center rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setNotificationOpen(false)}>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button> 
          </>}
        </div>
    </>
)

const watchCancelProgress = function (bulkId) {
  if (isComplete || failures || total || pending)
      return true

  const intervalId = setInterval(async () => {
      try {
          const {
              is_complete,
              failures: _failures,
              total: _total,
              pending: _pending
          } = await fetchOrderCreateBulkTracker(bulkId)

          setIsComplete(is_complete)
          setFailures(_failures)
          setTotal(_total)
          setPending(_pending)

          if (isLoading)
              setIsLoading(false)

          if (is_complete) {
              clearInterval(intervalId);
          }

      } catch (e) {
          clearInterval(intervalId);
          console.log(e)
      }
  }, 1000)
}

  const onCancelOrders = async orderIds => {
    let batch = await cancelOrders(orderIds)
    watchCancelProgress(batch.batch_id)
    setNotificationOpen(true)

    onSuccess()
  }
  const onHoldOrders = async orderIds => {
    let updatedOrders = await holdOrders(orderIds)
    onSuccess(updatedOrders)
  }
  const onUnholdOrders = async orderIds => {
    let updatedOrders = await unholdOrders(orderIds)
    onSuccess(updatedOrders)
  }
  const onMarkAsPayedOrders = async orderIds => {
    let updatedOrders = await markAsPayedOrders(orderIds)
    onSuccess(updatedOrders)
  }

  const ConfirmationTab = () => {
    const confirmationTitle = useMemo(() => {
      return i18n.t(`orders.action_bar.actions.${action}`)
    }, [action, i18n.language])
    const confirmationMessage = useMemo(() => {
        return i18n.t(`orders.action_bar.errors.error_modal.confirmations.${action}`, {order_count: orders?.length})
    }, [action, i18n.language])
    const warningOrdersWithPaidDifal = useMemo(() => {
      const filteredOrders = []
      orders?.forEach(order => {
        if (order.is_difal_paid) {
          filteredOrders.push(order.order_number)
        }
      })
      if (filteredOrders.length == 0) return null
      return i18n.t('orders.action_bar.errors.error_modal.confirmations.warning_cancel_with_difal', {orders: filteredOrders.join(', ')})
    }, [action, i18n.language])

    return (
      <div className="max-w-sm text-gray-500">
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
          <QuestionMarkCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <div className="text-lg leading-6 font-medium text-gray-900">{confirmationTitle}</div>
          <div className="mt-2">
            <div className="text-base">{confirmationMessage}</div>
          </div>
          { warningOrdersWithPaidDifal &&
            <div className="mt-2 text-sm text-yellow-600">
                {warningOrdersWithPaidDifal}
            </div>
          }
        </div>
        {unexpectedErrorMessage && <div className="text-red-500 pt-2 w-full text-center">{unexpectedErrorMessage}</div>}
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button onClick={() => handleClose()} type="secondary">
            {i18n.t('dialog.no')}
          </Button>
          <Button onClick={() => onActionConfirm()} type="primary">
            {i18n.t('dialog.yes')}
          </Button>
        </div>
      </div>
    )
  }

  const OmitErrorsConfirmationTab = () => {
    const validOrders = useMemo(() => orders.filter(order => !ordersWithErrors?.map(order => order.id).includes(order.id)), [ordersWithErrors])
    const ordersWithErrorsByCode = useMemo(
      () =>
        ordersWithErrors?.reduce((acc, order) => {
          acc[order.error?.code] = (acc[order.error?.code] || []).concat(order)
          return acc
        }, {}),
      [ordersWithErrors],
    )
    // console.log(ordersWithErrorsByCode)
    // const countTotalOrdersWithErrors = useMemo(() => {
    //   if (!ordersWithErrorsByCode) return []
    //   Object.values(ordersWithErrorsByCode).reduce((acc, orders) => acc + orders.length, 0)
    // }, [ordersWithErrorsByCode])

    return (
      <>
        <div className="font-semibold text-xl text-center">{i18n.t('orders.action_bar.errors.error_modal.title')}</div>
        {/* <div className="text-red-500 font-bold text-xl text-center">
          {i18n.t(`orders.action_bar.errors.error_modal.messages.action_not_possible`).replace('{quantity}', orders?.length).replace('{quantity_errors}', ordersWithErrors?.length)}
        </div> */}

        {/* <div className="max-h-60vh overflow-y-auto">
          {ordersWithErrors?.length > 0 && ordersWithErrors.map(order => <OrderErrorRow order={order} key={order.id} />)}
        </div> */}

        <table className="flex flex-col text-base mx-10 mt-8 mb-10">
          <thead className='pb-1.5 border-gray-800 border-b-[1px]'>
            <tr className="flex justify-between font-semibold">
              <td>{i18n.t('orders.action_bar.orders_selected')}</td>
              <td>{orders.length}</td>
            </tr>
          </thead>
          <tbody>
            <tr className='flex justify-between mt-2'>
              <td>{i18n.t(`orders.action_bar.errors.codes.valid.${action}`)}</td>
              <td>{validOrders?.length}</td>
            </tr>
            {ordersWithErrorsByCode &&
              Object.entries(ordersWithErrorsByCode)?.map(([errorCode, orders]) => {
                return (
                  <tr className='flex justify-between mt-2'>
                    <td>
                      <div className={`${['ORDER_SHIPPED','ORDER_CANT_BE_HOLD','ORDER_CANT_BE_UNHOLD','ORDER_CANT_MARK_PAID'].includes(errorCode) ? 
                        "text-red-500": ""} `}>
                        {i18n.t(`orders.action_bar.errors.codes.${errorCode}`)}
                      </div>
                      <Collapse 
                       show={i18n.t('orders.action_bar.errors.error_modal.collapse.show')}
                       hide={i18n.t('orders.action_bar.errors.error_modal.collapse.hide')}
                       description={['ORDER_SHIPPED','ORDER_CANT_BE_HOLD','ORDER_CANT_BE_UNHOLD','ORDER_CANT_MARK_PAID'].includes(errorCode) ? i18n.t('orders.action_bar.errors.error_modal.collapse.description') : ""}
                       children={
                        <div className="max-h-32 overflow-y-auto text-justify">
                          {orders?.map((order, index) => (
                            <span key={index}>
                              {order?.order_number}
                              {index !== orders.length - 1 && <>, </>}
                            </span>
                          ))}
                        </div>
                       }
                      />
                    </td>
                    <td className={`${['ORDER_SHIPPED','ORDER_CANT_BE_HOLD','ORDER_CANT_BE_UNHOLD','ORDER_CANT_MARK_PAID'].includes(errorCode) ? 
                        "text-red-500": ""} `}>{orders.length}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>

        {/* <div className="text-lg font-bold text-center">{i18n.t(`orders.action_bar.errors.error_modal.messages.omit_errors_and_submit_question`)}</div> */}
        <div className="flex justify-center mt-8">
          <div className="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <Button onClick={() => handleClose()} type="secondary">
              {i18n.t('dialog.cancel')}
            </Button>
            {validOrders?.length === 0 ? (
              <Button disabled type="primary">
                <Tooltip
                  title={i18n.t('orders.action_bar.errors.error_modal.messages.no_valid_elements_tooltip')}
                  getPopupContainer={() => modalMainContainerRef?.current}
                >
                  {i18n.t('orders.action_bar.errors.error_modal.confirmations.no_valid_elements')}
                </Tooltip>
              </Button>
            ) : (
              <Button onClick={() => onActionConfirm(validOrders)} type="primary">
                {i18n.t('orders.action_bar.errors.error_modal.confirmations.omit_errors_and_submit', {action: i18n.t(`orders.action_bar.errors.error_modal.action_type.${action}`)} )}
              </Button>
            )}
          </div>
        </div>
        {/* <div>
          <div className="mb-2 text-center font-semibold">
            {i18n.t(`orders.action_bar.errors.error_modal.messages.order_numbers_with_errors`)}
          </div>
          <div className="max-h-32 overflow-y-auto text-justify">
            {ordersWithErrors?.map((order, index) => (
              <span key={index}>
                {order?.order_number}
                {index !== ordersWithErrors.length - 1 && <>, </>}
              </span>
            ))}
          </div>
        </div> */}
      </>
    )
  }

  return (
    <>
    <DialogView open={visible} setOpen={handleClose}>
      {hasConfirmed ? <>
        <div className="relative md:w-[620px]" ref={modalMainContainerRef}>
          <Loader show={busy} className="-inset-2" />
          <OmitErrorsConfirmationTab />
        </div>
      </> : <>
        <div className="relative" ref={modalMainContainerRef}>
          <Loader show={busy} className="-inset-2" />
          <ConfirmationTab />
        </div>
      </>}
    </DialogView>
    <Notification
      show={notificationOpen}
      setShow={setNotificationOpen}
      customBody={cancelProgressNotificationBody}
      ephemeral={false}
    />
    </>
  )
}
