import PropTypes from "prop-types";

const getClassNames = (type, className, loading, props) => {
    let classes = ""
    if (props.disabled || loading) {
        // return `mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm ${className}`
        classes += `cursor-not-allowed opacity-60`
    }
    if(type === "primary"){
        classes += ` inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm `
    }
    else if(type === "secondary"){
        classes += ` mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm `
    }
    classes += className
    
    return classes
}

export const ButtonLoader = ({type = "primary", loading_text = "Cargando...", loading = false, className="", children, ...props}) => {

    ButtonLoader.propTypes = {
        type: PropTypes.oneOf(['primary', 'secondary'])
    }

    return (
        <button
            className={getClassNames(type, className, loading, props)}
            {...props}
        >
            {loading ?
             <span>{loading_text}</span>
            :
            <>{children}</>
            }
        </button>
    )
}